import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import { MuiTransition } from 'components/animations'
import { ICandidateAdvertisementResponse, setCandidateCreateOzgelecekFormField, useAppDispatch, useGetCandidateOzgeleceksQuery } from 'context'
import React, { useEffect, useState } from 'react'
import { routes } from 'routes'
import { OzgelecekFieldEnum, getNameOfEnum } from 'types'
import { enumFunctions } from 'utils'

import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import { useNavigate } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { Iconify } from 'components/iconify'


interface ICreateApplicationDialog {
    applyDialog: boolean,
    setApplyDialog: (e?: any) => void,
    title?: string,
    advertisement: any,
    onClick: (e?: any) => void,
    loading: boolean,
    button?: boolean,

    onUpdate: (e?: any) => void,
    onAddNew: (e?: any) => void,
}


export const CreateApplicationDialog = ({ onAddNew, onUpdate, button = true, applyDialog, setApplyDialog, title, advertisement, onClick, loading }: ICreateApplicationDialog) => {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { data: ozgeleceks } = useGetCandidateOzgeleceksQuery({})
    const [selectedOzgelecek, setSelectedOzgelecek] = useState<any>(null)

    let areaTestScore = selectedOzgelecek?.testResults?.areaTestScore
    let motivationTestScore = selectedOzgelecek?.testResults?.motivationTestScore
    
    useEffect(() => {
        const selected: any = ozgeleceks?.data?.find((item: any) => item?.field === advertisement?.field)
        setSelectedOzgelecek(selected)
    }, [advertisement, ozgeleceks])


    return (
        <div>

            {
                button && (
                    <Button color='primary'
                        variant='contained'
                        sx={{ px: 4, py: 2 }}
                        onClick={() => setApplyDialog(true)
                        }
                    >
                        <ThumbUpAltRoundedIcon sx={{ mr: 1 }} /> &nbsp; <Typography fontWeight={'bold'}>Başvur</Typography>
                    </Button>
                )
            }


            <Dialog
                open={!!applyDialog}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setApplyDialog(null)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <span style={{ textTransform: 'capitalize' }}>
                        {title ?? "İlan Başvurusu"}
                    </span>
                </DialogTitle>

                <DialogContent>
                    <Stack
                        direction="column"
                        gap={3}
                        sx={{
                            width: { xs: '100%', sm: '400px' },
                            py: 0
                        }}
                    >
                        {selectedOzgelecek && <Typography variant='body2'>{advertisement?.title?.[0]?.toUpperCase() + advertisement?.title?.slice(1)} isimli ilana başvurmak üzeresiniz. İlan kategorisine göre oluşturduğunuz özgelecek otomatik seçilmiştir.</Typography>}

                        {
                            selectedOzgelecek ? (
                                <Typography color="black" sx={{ fontWeight: 'bold' }}>
                                    Seçilen Özgelecek
                                </Typography>
                            ) : (
                                <Typography textAlign="center">
                                    <strong>{getNameOfEnum(OzgelecekFieldEnum, advertisement?.field)}</strong> alanında özgeleceğiniz bulunmadığı için başvuru yapamazsınız.<br /><br /> Yeni bir özgelecek oluşturmak için butona tıklayınız
                                </Typography>
                            )
                        }

                        {
                            selectedOzgelecek ? (
                                <Stack
                                    direction={{ xs: 'row' }}
                                    justifyContent="space-between"
                                    gap={2}
                                    sx={{
                                        border: '1px solid #ccc',
                                        width: '100%',
                                        p: 2,
                                    }}
                                >
                                    <Typography color="gray" sx={{ textTransform: 'capitalize' }}>
                                        {enumFunctions.findAndDisplayName(OzgelecekFieldEnum, advertisement?.field ?? "")}
                                    </Typography>

                                    {/* <Typography
                                        color="primary"
                                        fontSize={"small"}
                                        fontWeight={'bold'}

                                        sx={{ textTransform: 'capitalize', cursor: 'pointer' }}
                                        onClick={() => {
                                            onUpdate()
                                        }}
                                    >
                                        Güncelle
                                    </Typography> */}
                                </Stack>
                            ) : (
                                <>
                                    <Button
                                        variant='contained'
                                        color="secondary"
                                        onClick={() => {
                                            onAddNew()
                                        }}
                                    >
                                        Özgelecek Ekle
                                    </Button>
                                </>
                            )
                        }

                        {selectedOzgelecek && (areaTestScore < 60 || motivationTestScore < 60) && <Typography color="black" sx={{ fontStyle: "italic", textAlign: 'center' }}>
                            - Bu alandaki test sonucunuz 60 puandan düşük olduğu için başvuru yapamazsınız. -
                        </Typography>}
                    </Stack>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setApplyDialog(null)} color="primary" variant='outlined'>
                        Kapat
                    </Button>

                    <LoadingButton
                        color='primary'
                        variant='contained'
                        onClick={() => onClick({selectedOzgelecek: selectedOzgelecek})}
                        loading={loading}
                        disabled={!selectedOzgelecek || areaTestScore < 60 || motivationTestScore < 60}
                    >
                        Başvur
                    </LoadingButton>
                </DialogActions>
            </Dialog>


        </div >
    )
}
