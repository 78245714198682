import { Avatar, Box, Chip, Divider, IconButton, Skeleton, Stack, Typography } from '@mui/material'
import { useGetCompanyApplicationDetailsQuery } from 'context'
import { DashboardLayout, DashboardLayoutMobile } from 'layouts'
import moment from 'moment'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LanguageEnums, OzgelecekFieldEnum, PlaceOfWorkEnums, PositionLevelEnums, WorkTypeEnums } from 'types'
import { enumFunctions } from 'utils'

import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { routes } from 'routes'
import { CandidateAdvertisementDetailImage } from 'assets'



export const CompanyApplicationDetailPageMobile = () => {

    const { id } = useParams()
    const navigate = useNavigate()
    const { data: application, isLoading } = useGetCompanyApplicationDetailsQuery({ applicationId: id ?? "" })


    return (
        <DashboardLayoutMobile>


            <Stack direction="row" gap={3} alignItems="center" sx={{ mb: 5 }}>
                <IconButton onClick={() => navigate(routes.company.dashboard.applicationsMobile)}>
                    <ArrowBackIosNewRoundedIcon />
                </IconButton>
                <Typography variant='h1' sx={{ fontSize: { xs: '15px', md: '30px' } }}>
                    Başvuru Detayı
                </Typography>
            </Stack>


            <Box>
                {
                    isLoading ? (
                        <Box>
                            <Skeleton
                                key={id}
                                variant='rectangular'
                                sx={{
                                    p: 2,
                                    width: '100%',
                                    height: '150px'
                                }}
                            />

                            <Stack direction={{ xs: 'column', md: 'row' }} gap={3} mt={3} >

                                <Stack width={{ xs: '100%', md: '70%' }} gap={3} >
                                    <Skeleton
                                        variant='text'
                                        sx={{
                                            p: 2,
                                            width: '100%',
                                            height: '50px'
                                        }}
                                    />
                                    <Skeleton
                                        variant='text'
                                        sx={{
                                            p: 2,
                                            width: '100%',
                                            height: '50px'
                                        }}
                                    />
                                    <Skeleton
                                        variant='text'
                                        sx={{
                                            p: 2,
                                            width: '100%',
                                            height: '50px'
                                        }}
                                    />
                                    <Skeleton
                                        variant='text'
                                        sx={{
                                            p: 2,
                                            width: '100%',
                                            height: '50px'
                                        }}
                                    />
                                </Stack>

                                <Skeleton
                                    variant='rectangular'
                                    sx={{
                                        p: 2,
                                        width: '30%',
                                        height: '300px'
                                    }}
                                />

                            </Stack>
                        </Box>
                    ) :
                        (
                            <Stack direction={{ xs: 'column', md: 'column' }} gap={3} >

                                {/* ****************** advertisement header ***************** */}
                                <Box
                                    sx={{
                                        p: { xs: 2, md: 4 },
                                        backgroundImage: `url(${CandidateAdvertisementDetailImage})`,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'contain'
                                    }}
                                >
                                    <Stack
                                        direction={{ xs: 'column', md: 'row' }}
                                        // justifyContent={{ xs: 'start' }}
                                        alignItems={{ xs: 'center' }}
                                        gap={3}
                                        sx={{
                                            bgcolor: '#FFFFFFcc',
                                            borderWidth: '2px',
                                            borderColor: 'primary.lighter',
                                            borderStyle: 'solid',
                                            p: 2,
                                        }}
                                    >

                                        {/* <Box
                                            component="img"
                                            src="https://i.pinimg.com/474x/2f/2b/b5/2f2bb5ca49ad125ebe5e9db4f5b41608.jpg"
                                            alt=""
                                            sx={{
                                                width: { xs: '100px', md: '100px' },
                                                borderRadius: '50%',
                                            }}
                                        /> */}

                                        <Avatar
                                            src={application?.candidate?.personalInformation?.profileImageUrl}
                                            sx={{
                                                bgcolor: "primary.main",
                                                border: "1px solid",
                                                borderColor: "primary",
                                                width: { xs: '100px', md: '100px' },
                                                height: { xs: '100px', md: '100px' },

                                            }}
                                        >
                                            {application?.candidate?.personalInformation?.name?.slice(0, 1)}
                                        </Avatar>

                                        <Stack
                                            direction={{ xs: 'column', md: 'row' }}
                                            sx={{ flex: 1, }}
                                            gap={3}
                                        >

                                            <Stack direction="column" gap={1} flex={1} sx={{ textTransform: 'capitalize' }}>
                                                <Typography variant='h2' color={"primary.darker"} sx={{ fontSize: { xs: '15px', md: '26px' } }}>
                                                    {application?.candidate?.personalInformation?.name} &nbsp;
                                                    {application?.candidate?.personalInformation?.surname}
                                                </Typography>



                                                <Typography variant='h5'>
                                                    {application?.advertisement?.title} ·&nbsp;
                                                    <span style={{ color: '#000', fontWeight: 'bold' }}>
                                                        {enumFunctions.findAndDisplayName(OzgelecekFieldEnum, application?.advertisement?.field ?? "")}
                                                    </span>
                                                </Typography>
                                            </Stack>

                                            <Divider orientation="vertical" flexItem sx={{ my: 1 }} />

                                            <Stack justifyContent="center" alignItems="center">
                                                <Stack
                                                    direction="column"
                                                    gap={1}
                                                    justifyContent="space-between"
                                                    flexWrap="wrap"
                                                    sx={{ '&>*': { flex: 1 } }}
                                                >

                                                    {/* <CreateApplicationDialog
                                            loading={createApplicationLoading}
                                            onClick={handleCreateApplication}
                                            title={`Apply to ${advertisement?.title ?? "advertisement"}`}
                                            applyDialog={applyDialog}
                                            setApplyDialog={setApplyDialog}
                                            advertisement={advertisement}
                                        /> */}

                                                    {/* <Typography>
                                        {moment(advertisement?.createdAt ?? "").format('DD MMM YYYY')}
                                    </Typography>
                                    <Typography>
                                        {moment(advertisement?.updatedAt ?? "").format('DD MMM YYYY')}
                                    </Typography> */}

                                                </Stack>


                                                {/* <Typography>
                                    <span style={{ color: '#000', fontWeight: 'bold' }}>
                                        {enumFunctions.findAndDisplayName(LanguageEnums, advertisement?.language ?? "")} &nbsp;
                                    </span>
                                    {advertisement?.quota} kota
                                </Typography> */}

                                            </Stack>

                                        </Stack>

                                    </Stack>
                                </Box>


                                {/* ****************** advertisement body ***************** */}
                                <Stack direction={{ xs: 'column', md: 'row' }} gap={3}>

                                    {/* ****************** advertisement information ***************** */}
                                    <Stack
                                        gap={3}
                                        sx={{
                                            flex: 1,
                                        }}
                                    >

                                        <Box>
                                            <Typography variant='h5'>
                                                Açıklama
                                            </Typography>

                                            <Typography variant='body2' >
                                                {application?.advertisement?.description}
                                            </Typography>
                                        </Box>

                                        <Stack
                                            gap={1}
                                            sx={{
                                                '& p': {
                                                    color: '#5344d2',
                                                    fontWeight: 'bold',
                                                },
                                                '& span': {
                                                    color: '#000',
                                                    fontWeight: 'normal',
                                                },
                                            }}
                                        >

                                            <Typography variant='body2'>
                                                <span>Başlama Tarihi: </span>
                                                {moment(application?.advertisement?.createdAt).locale('tr').format('DD MMMM YYYY')}
                                            </Typography>

                                            <Typography variant='body2'>
                                                <span>Departman: </span>
                                                {application?.advertisement?.department}
                                            </Typography>

                                            <Typography variant='body2' >
                                                <span>Dil: </span>
                                                {enumFunctions.findAndDisplayName(LanguageEnums, application?.advertisement?.language ?? "")}
                                            </Typography>

                                            <Typography variant='body2'>
                                                <span>Kota: </span>
                                                {application?.advertisement?.quota}
                                            </Typography>

                                            <Typography variant='body2'>
                                                <span>Çalışma Şekli:</span> {enumFunctions.findAndDisplayName(PlaceOfWorkEnums, application?.advertisement?.place ?? "")}
                                            </Typography>
                                            <Typography variant='body2'>
                                                <span>Pozisyon:</span> {enumFunctions.findAndDisplayName(PositionLevelEnums, application?.advertisement?.positionLevel ?? "")}
                                            </Typography>
                                            <Typography variant='body2'>
                                                <span>İş Tipi:</span> {enumFunctions.findAndDisplayName(WorkTypeEnums, application?.advertisement?.workType ?? "")}
                                            </Typography>


                                        </Stack>

                                        <Divider orientation="horizontal" variant="fullWidth" flexItem />

                                        <Box>
                                            <Typography variant='h5'>
                                                Gereklilikler
                                            </Typography>

                                            <Stack
                                                gap={1}
                                                sx={{
                                                    '& p': {
                                                        color: '#5344d2',
                                                        fontWeight: 'bold',
                                                        // fontWeight: 'normal',

                                                    },
                                                    '& span': {
                                                        color: '#000',
                                                        fontWeight: 'normal',
                                                        // fontWeight: 'bold',

                                                    },
                                                }}
                                            >
                                                <Typography variant='body2' mt={2}>
                                                    <span>Sürücü Belgesi: </span>
                                                    {application?.advertisement?.requiredDriverLicense?.toUpperCase()}
                                                </Typography>
                                                <Typography variant='body2'>
                                                    <span>Bilmesi Gereken Diller: </span>
                                                    {/* {
                                                        application?.advertisement?.requiredLanguages?.map((item: any, index: number) =>
                                                            index === 0
                                                                ? enumFunctions.findAndDisplayName(LanguageEnums, item?.name ?? "")
                                                                : (', ' + enumFunctions.findAndDisplayName(LanguageEnums, item?.name ?? "")
                                                                ))
                                                    } */}
                                                </Typography>
                                                <Typography variant='body2' >
                                                    <span>Eğitim Seviyesi: </span>
                                                    {/* {application?.advertisement?.requiredEducationLevel?.map((item: any, index: number) => index === 0 ? item : (', ' + item))} */}
                                                </Typography>

                                                <Stack direction="row" spacing={1} flexWrap="wrap" alignItems={'center'}>

                                                    <Typography variant='body2' >
                                                        <span>Gerekli Özellikler: </span>

                                                    </Typography>

                                                    {
                                                        application?.advertisement?.requiredSkills?.map((skill) => {
                                                            return (
                                                                <Chip
                                                                    key={skill}
                                                                    label={skill}
                                                                    color="secondary"
                                                                    sx={{ textTransform: 'capitalize' }}
                                                                    style={{ borderRadius: 100 }}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </Stack>
                                            </Stack>
                                        </Box>

                                        <Divider orientation="horizontal" variant="fullWidth" flexItem />



                                        <Typography variant='body2'>
                                            <span>Oluşturulma Tarihi : </span>
                                            {moment(application?.advertisement?.createdAt).locale('tr').format('DD MMMM YYYY')}
                                        </Typography>


                                    </Stack>

                                    {/* ****************** company information ***************** */}
                                    <Stack
                                        sx={{
                                            bgcolor: '#f1f1f1AA',
                                            borderWidth: '1px',
                                            borderColor: 'primary.lighter',
                                            borderStyle: 'dashed',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            px: 3,
                                            py: 5,
                                            height: 'fit-content',
                                            width: { xs: '100%', md: '300px' },
                                        }}
                                    >

                                        <Box
                                            component="img"
                                            src="https://i.pinimg.com/474x/2f/2b/b5/2f2bb5ca49ad125ebe5e9db4f5b41608.jpg"
                                            alt=""

                                            sx={{
                                                width: { xs: '100px', md: '100px' },
                                                m: 'auto',
                                                borderRadius: '50%',
                                            }}
                                        />

                                        <Stack
                                            gap={2}
                                            sx={{
                                                mt: 5,
                                                flexDirection: 'column',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                '& span': {
                                                    fontWeight: 'bold',
                                                    color: '#000'
                                                }
                                            }}
                                        >

                                            <Typography variant='h3'>
                                                Company Name
                                            </Typography>

                                            <Typography variant='body1'>
                                                <span>Address : </span> District / City
                                            </Typography>

                                            <Typography variant='body1'>
                                                <span>Sector : </span> Marketing
                                            </Typography>

                                            <Typography variant='body1'>
                                                <span>Employee : </span> 10 - 50 Employees
                                            </Typography>

                                            <Typography variant='body1'>
                                                <span>Phone Number : </span> 555 555 5555
                                            </Typography>

                                            <Typography variant='body1'>
                                                <span>Website : </span> www.example.com
                                            </Typography>

                                        </Stack>

                                    </Stack>

                                </Stack>

                            </Stack>
                        )
                }
            </Box>


        </DashboardLayoutMobile>
    )
}
