import { HasId } from "interfaces/HasId"
import { IRadarHistory } from "interfaces/user/radarHistory/interface.radarHistory"

export interface ICompanyRadarHistoryResponse extends IRadarHistory {

}

export interface ICompanyRadarHistoryVariables extends HasId {
  
}

export const company_radarHistory = (variables: ICompanyRadarHistoryVariables) => {
  const data = JSON.stringify({
    query: `
      query company_radarHistory($id: ID!) {
        company_radarHistory(id: $id) {
          _id
          createdAt
          updatedAt
          candidate {
            _id
            personalInformation {
              name
              surname
              birthDate
              country
              city
              district
              nationality
              citizenship
              sex
              gender
              genderDetail
              address
              phoneNumber
              profileImageUrl
              backgroundImageUrl
              description
            }
            contact {
              phoneNumber
              phoneNumberVerified
              email
              emailVerified
            }
            education {
              highSchool
              higherEducation {
                higherEducationDegree
                name
                department
                gpa
                startDate
                graduationDate
              }
            }
            certificates {
              name
              type
              issuedDate
              validUntil
              certificateInstitution
            }
            driverLicense {
              status
              issueDate
              licenseType
            }
            languages {
              name
              reading
              writing
              listening
            }
            smoking
            scholarships {
              receivingDate
              type
              institution
            }
            participatedProjects {
              name
              institution
              level
              date
              details
            }
            workExperiencesInMonths
            computerKnowledgeLevel
            programmingLanguages {
              name
              level
            }
            hobbies
            candidateOnboardingStatus
            createdAt
            updatedAt
          }
          company {
            _id
          }
        }
      }
          `,
    variables: variables

  })

  return data

}