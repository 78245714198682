

export const reference_reference = ({ variables }: any) => {

    const data = JSON.stringify({
        query: `
        query Reference_reference($token: String!) {
            reference_reference(token: $token) {
              _id
              createdAt
              createdBy {
                _id
              }
              referenceExplanation
              referenceInformation {
                surname
                relationWithCandidate
                phoneNumber
                name
                jobTitle
                email
              }
              referenceStatus
              referenceTests {
                question {
                  _id
                }
                answer
              }
              updatedAt
            }
          }
        `,
        variables: {
            "token": variables?.token
        }
    })

    return data
}
