import { Helmet } from 'react-helmet-async';
import { CompactLayout } from 'layouts';
import RegisterVerifyContainer from './RegisterVerifyContainer';


export const RegisterVerifyPage = () => {
  return (
    <CompactLayout>
      <Helmet>
        <title> Verify Code </title>
      </Helmet>

      <RegisterVerifyContainer />

    </CompactLayout>
  );
}
