import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import tr from 'date-fns/locale/tr';

import ThemeProvider from 'minimal/theme';
import SnackbarProvider from 'minimal/components/snackbar/snackbar-provider';

import { AppRouter } from 'routes'
import { store } from 'context';


const Wrapper = () => {
    return (
        <HelmetProvider>
            <Provider store={store}>
                <ThemeProvider>
                    <SnackbarProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={tr} >
                            <AppRouter />
                        </LocalizationProvider>
                    </SnackbarProvider>
                </ThemeProvider>
            </Provider>
        </HelmetProvider>
    )
}

export default Wrapper