import { useEffect, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { useAppDispatch, setAdvertisementFormIndex, setAdvertisementId, useAppSelector, useLazyAdvertisementImagePresignedUrlListQuery, useCompanyCompleteAdvertisementOnboardingMutation } from 'context'
import { useNavigate, useParams } from 'react-router-dom'
import { Formik, useFormikContext } from 'formik'
import { Box, Button, IconButton, Skeleton, Stack, Typography } from '@mui/material'

import { DashboardLayout } from 'layouts'
// import { ICompanyCreateAdvertisementVariables } from 'types'
import { ICreateAdvertisementForm, useCompanyCreateAdvertisementFormValidation } from 'lib/form-validation'
import {
    ICompanyCreateAdvertisementVariables, ICompanyUpdateAdvertisementVariables, useCompanyCreateAdvertisementMutation, useCompanyUpdateAdvertisementMutation,
    useGetCompanyAdvertisementDetailQuery, useAdvertisementImagePresignedUrlListQuery, useLazyGetUserProfileImagePresignedUrlQuery, IAdvertisementImagePresignedUrlListResponse
} from 'context'
import {
    CreateAdvertisementFirst, CreateAdvertisementPreview,
    CreateAdvertisementLast, CreateAdvertisementOnboardingFirst, CreateAdvertisementOnboardingSecond, CreateAdvertisementOnboardingThird
} from 'sections'

import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { OzgelecekProgressBar } from 'components'
import { routes } from 'routes'
import { findImageContentType, saveImageAndGetUrl } from 'utils'





export const CompanyCreateAdvertisementPage = () => {


    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { device } = useAppSelector((state) => state?.auth)
    const [images, setImages] = useState<Array<any>>([])
    const { advertisementFormIndex, advertisementId: adId } = useAppSelector((state) => state.companyCreateAdvertisement)
    const { id } = useParams();

    const [updateAdvertisement, { isLoading: updateLoading }] = useCompanyUpdateAdvertisementMutation()
    const [createAdvertisement, { isLoading: createLoading }] = useCompanyCreateAdvertisementMutation()
    const { initialValues, validationSchema, setDefaultValues } = useCompanyCreateAdvertisementFormValidation({ values: null as any })
    const [completeAdvertisement, { isLoading: completeLoading }] = useCompanyCompleteAdvertisementOnboardingMutation()
    const [isUpdated, setIsUpdated] = useState(false);
    const { isFetching } = useGetCompanyAdvertisementDetailQuery({ advertisementId: id })

    const [getPresignUrl] = useLazyAdvertisementImagePresignedUrlListQuery()

    useEffect(() => {
        id && dispatch(setAdvertisementId(id))
    }, [id, dispatch])

    useEffect(() => {
        document.getElementById("on-kayit-basligi")?.scrollIntoView({ behavior: "smooth" });
    }, [advertisementFormIndex])

    // useEffect(() => {
    //     if (adId) {
    //         const newValues: ICompanyCreateAdvertisementVariables = {
    //             advertisementId: selectedAdvertisement?._id,
    //             title: selectedAdvertisement?.title,
    //             description: selectedAdvertisement?.description,
    //             field: selectedAdvertisement?.field === "" ? null : selectedAdvertisement?.field,
    //             department: selectedAdvertisement?.department,
    //             language: selectedAdvertisement?.language,
    //             place: selectedAdvertisement?.place,
    //             positionLevel: selectedAdvertisement?.positionLevel,
    //             productType: selectedAdvertisement?.productType,
    //             workType: selectedAdvertisement?.workType,
    //             extraProducts: selectedAdvertisement?.extraProducts,
    //             companyInfoVisible: selectedAdvertisement?.companyInfoVisible,
    //             replyNotification: selectedAdvertisement?.replyNotification,
    //             customReplyNotification: {
    //                 content: selectedAdvertisement?.customReplyNotification?.content,
    //                 subject: selectedAdvertisement?.customReplyNotification?.subject
    //             },
    //             images: selectedAdvertisement?.images,
    //             status: selectedAdvertisement?.status,
    //             startDate: selectedAdvertisement?.startDate,
    //             quota: selectedAdvertisement?.quota,
    //             quotaVisibleToApplicant: selectedAdvertisement?.quotaVisibleToApplicant,
    //             city: selectedAdvertisement?.city,
    //             district: selectedAdvertisement?.district,
    //             requiredSkills: selectedAdvertisement?.requiredSkills,
    //             requiredLanguages: selectedAdvertisement?.requiredLanguages,
    //             requiredEducationLevel: selectedAdvertisement?.requiredEducationLevel,
    //             requiredDriverLicense: selectedAdvertisement?.requiredDriverLicense,
    //             requiredAgeInterval: selectedAdvertisement?.requiredAgeInterval,
    //             militaryServiceRequired: selectedAdvertisement?.militaryServiceRequired
    //         }
    //         setDefaultValues(newValues)
    //     }
    // }, [selectedAdvertisement, adId, setDefaultValues])




    const handleSetImages = (images: any) => {
        setImages(images)
    }


    const handleCreateAdvertisement = async (values: ICompanyCreateAdvertisementVariables) => {

        try {

            const response: any = await createAdvertisement({
                ...values,
            })

            console.log(response?.data?.data?.company_createAdvertisement?._id)
            if (response?.data?.data?.company_createAdvertisement?._id) {
                dispatch(setAdvertisementId(response?.data?.data?.company_createAdvertisement?._id))
                dispatch(setAdvertisementFormIndex(advertisementFormIndex + 1))
            } else {
                enqueueSnackbar("İlan oluşturulamadı!", { variant: "error" })
            }

        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: "error" })
        }
    }



    const handleUpdateAdvertisement = async (values: ICompanyUpdateAdvertisementVariables) => {

        try {

            const newImages = [] as any
            let urls = [] as any
            if (images.length > 0) {
                const contentType = findImageContentType(images[0]);
                const presigneUrl = await getPresignUrl({ contentType: contentType, imageCount: images.length })
                console.log(presigneUrl?.data?.[0], 'presigneUrl')
                images.forEach(async (image, index) => {
                    urls.push(presigneUrl?.data ? presigneUrl?.data[index]?.url : "")
                    const isSaved = await saveImageAndGetUrl({
                        file: image,
                        presignUrl: presigneUrl?.data ? presigneUrl?.data[index]?.presignedUploadUrl : ""
                    })
                    newImages.push(isSaved)
                }
                )

                ////////////////
                const response: any = await updateAdvertisement({
                    advertisementId: adId,
                    ...values,
                    images: values.images?.concat(urls)
                })

                if (response?.data?.data?.company_updateAdvertisement) {
                    // enqueueSnackbar("Successully updated!")
                    advertisementFormIndex !== 5 && dispatch(setAdvertisementFormIndex(advertisementFormIndex + 1))
                    if (advertisementFormIndex === 5 && values?.status !== "pending" && values?.status !== "approved" && values?.status !== "pendingApproval") {
                        const resComplete: any = await completeAdvertisement({ advertisementId: adId })

                        if (resComplete?.data?.data?.company_completeAdvertisementOnboarding?.success) {
                            enqueueSnackbar("İlanınız başarıyla oluşturuldu!", { variant: "success" })
                            navigate(routes.company.dashboard.myAdvertisements)
                        } else if (resComplete?.data?.errors?.[0]?.message === "InsufficientStandardCredit") {
                            enqueueSnackbar("Yeterli krediniz bulunmamaktadır!", { variant: "error" })
                        } else {
                            // enqueueSnackbar("İşlem gerçekleştirilirken bir hata oluştu!", { variant: "error" })
                        }
                    } else if (advertisementFormIndex === 5 && values?.status === "pending") {
                        enqueueSnackbar("İlanınız başarıyla güncellendi!", { variant: "success" })
                        navigate(routes.company.dashboard.myAdvertisements)

                    }
                    setIsUpdated(true)
                } else {
                    enqueueSnackbar("Could not updated!", { variant: "error" })
                }
                ////////////////

            } else {
                ////////////////
                const response: any = await updateAdvertisement({
                    advertisementId: adId,
                    ...values,
                    images: values.images?.concat(urls)
                })

                console.log(values?.status, 'values?.status')

                if (response?.data?.data?.company_updateAdvertisement) {
                    advertisementFormIndex !== 5 && dispatch(setAdvertisementFormIndex(advertisementFormIndex + 1))

                    if (advertisementFormIndex === 5 && (values?.status !== "pending" && values?.status !== "pendingApproval" && values?.status !== "approved")) {
                        const resComplete: any = await completeAdvertisement({ advertisementId: adId })

                        if (resComplete?.data?.data?.company_completeAdvertisementOnboarding?.success) {
                            enqueueSnackbar("İlanınız başarıyla oluşturuldu!", { variant: "success" })
                            navigate(routes.company.dashboard.myAdvertisements)
                        } else if (resComplete?.data?.errors?.[0]?.message === "InsufficientStandardCredit") {
                            enqueueSnackbar("Yeterli krediniz bulunmamaktadır!", { variant: "error" })
                        } else {
                            // enqueueSnackbar("İşlem gerçekleştirilirken bir hata oluştu!", { variant: "error" })
                        }
                    } else if (advertisementFormIndex === 5 && values?.status === "pending") {
                        enqueueSnackbar("İlanınız başarıyla güncellendi!", { variant: "success" })
                        navigate(routes.company.dashboard.myAdvertisements)

                    }
                    setIsUpdated(true)
                } else {
                    enqueueSnackbar("Could not updated!", { variant: "error" })
                }
                //////////////////
            }
        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: "error" })
        }


    }


    return (
        <DashboardLayout>
            <div id="on-kayit-basligi"></div>
            <Stack direction="row" gap={1} alignItems="center" mb={5} justifyContent="space-between">
                <Stack direction="row" gap={1} alignItems="center" >
                    {!(device === "mobile" && !id) && <IconButton onClick={() => navigate(-1)}>
                        <ArrowBackIosNewRoundedIcon />
                    </IconButton>}

                    <Typography variant='h1'>
                        İlan {id ? 'Düzenle' : 'Oluştur'}
                    </Typography>

                </Stack>
            </Stack>

            {((!isFetching && id) || !id) ? <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    if (id ?? adId) {
                        handleUpdateAdvertisement(values)
                    } else {
                        handleCreateAdvertisement(values)
                    }
                }}
            >
                <CreateAdvertisementForm
                    setDefaultValues={setDefaultValues}
                    buttonLoading={updateLoading || createLoading}
                    images={images ?? []}
                    setImages={handleSetImages}
                    isUpdated={isUpdated}
                    setIsUpdated={setIsUpdated}
                />
            </Formik> :
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 3,
                        py: 5,
                    }}
                >
                    {[0, 1].map((_, index) => (
                        <Skeleton
                            key={index}
                            variant='rectangular'
                            sx={{
                                p: 2,
                                width: '100%',
                                height: '200px'
                            }}
                        />
                    ))}
                </Box>
            }
        </DashboardLayout>
    )
}


interface ICreateAdvertisementFormProps {
    buttonLoading: boolean
    images: Array<any>,
    setImages: (e: any) => void,
    isUpdated: boolean,
    setIsUpdated: (e: any) => void,
    setDefaultValues: (e: any) => void
}


const CreateAdvertisementForm = ({ buttonLoading, images, setImages, isUpdated, setIsUpdated, setDefaultValues }: ICreateAdvertisementFormProps) => {

    const { advertisementFormIndex, advertisementId } = useAppSelector((state) => state.companyCreateAdvertisement)
    const { setValues, setFieldValue } = useFormikContext<ICreateAdvertisementForm>()
    const dispatch = useAppDispatch()
    const { id } = useParams()

    const { data: selectedAdvertisement, isFetching } = useGetCompanyAdvertisementDetailQuery({ advertisementId: id })

    useEffect(() => {
        if (advertisementId && !isUpdated && id) {
            dispatch(setAdvertisementFormIndex(
                selectedAdvertisement?.status === "onboardingFirstCompleted" ? 1 :
                    selectedAdvertisement?.status === "onboardingSecondCompleted" ? 2 :
                        selectedAdvertisement?.status === "onboardingThirdCompleted" ? 3 :
                            selectedAdvertisement?.status === "onboardingForthCompleted" ? 4 : 0))
        }
    }, [advertisementId, dispatch, selectedAdvertisement, isUpdated, id])

    useEffect(() => {
        if (id) {
            if (selectedAdvertisement) {
                const newValues: ICompanyCreateAdvertisementVariables = {
                    advertisementId: selectedAdvertisement?._id,
                    title: selectedAdvertisement?.title,
                    description: selectedAdvertisement?.description,
                    field: selectedAdvertisement?.field === "" ? null : selectedAdvertisement?.field,
                    department: selectedAdvertisement?.department,
                    language: selectedAdvertisement?.language,
                    place: selectedAdvertisement?.place,
                    positionLevel: selectedAdvertisement?.positionLevel,
                    productType: selectedAdvertisement?.productType,
                    workType: selectedAdvertisement?.workType,
                    extraProducts: selectedAdvertisement?.extraProducts,
                    companyInfoVisible: selectedAdvertisement?.companyInfoVisible,
                    replyNotification: selectedAdvertisement?.replyNotification,
                    customReplyNotification: {
                        content: selectedAdvertisement?.customReplyNotification?.content,
                        subject: selectedAdvertisement?.customReplyNotification?.subject
                    },
                    images: selectedAdvertisement?.images,
                    status: selectedAdvertisement?.status,
                    startDate: selectedAdvertisement?.startDate,
                    quota: selectedAdvertisement?.quota,
                    quotaVisibleToApplicant: selectedAdvertisement?.quotaVisibleToApplicant,
                    locations: selectedAdvertisement?.locations,
                    requiredSkills: selectedAdvertisement?.requiredSkills,
                    requiredLanguages: selectedAdvertisement?.requiredLanguages,
                    requiredEducationLevel: selectedAdvertisement?.requiredEducationLevel,
                    requiredDriverLicense: selectedAdvertisement?.requiredDriverLicense,
                    requiredAgeInterval: selectedAdvertisement?.requiredAgeInterval,
                    militaryServiceRequired: selectedAdvertisement?.militaryServiceRequired
                }

                setValues(newValues as any)
            }
        }
        if (!id) {
            dispatch(setAdvertisementFormIndex(0))
            dispatch(setAdvertisementId(""))
        }
    }, [selectedAdvertisement])

    const renderForm = () => {
        switch (advertisementFormIndex) {
            case 0:
                return (<CreateAdvertisementFirst />)
            case 1:
                return (<CreateAdvertisementOnboardingFirst />)
            case 2:
                return (<CreateAdvertisementOnboardingSecond />)
            case 3:
                return (<CreateAdvertisementOnboardingThird />)
            case 4:
                return (
                    <CreateAdvertisementLast
                        buttonLoading={buttonLoading}
                        images={images ?? []}
                        setImages={setImages}
                    />
                )
            case 5:
                return (<CreateAdvertisementPreview />)

            default:
                return (<CreateAdvertisementFirst />)
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                py: 5,
            }}
        >
            {!isFetching && <OzgelecekProgressBar totalstep={4} currentstep={
                advertisementFormIndex === 0 ? 0 :
                    advertisementFormIndex === 1 ? 1 :
                        advertisementFormIndex === 2 ? 2 :
                            advertisementFormIndex === 3 ? 3 :
                                advertisementFormIndex === 4 ? 4 : 0
            } />}
            {renderForm()}
        </Box>
    )
}
