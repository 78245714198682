import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Grid, Stack, Typography } from '@mui/material';

import {
    useAppSelector,
    useAppDispatch,
    useGetCandidateOzgeleceksQuery,
    setCandidateCreateOzgelecekFormIndex,
    setCandidateCreateOzgelecekFormField,
} from 'context';

import { OzgelecekFieldsList } from 'types';
import { Iconify } from 'components';




export const OzgelecekFieldsForm = () => {


    const [searchParams] = useSearchParams();
    const field = searchParams.get("field")

    const dispatch = useAppDispatch()
    const [variables] = useState({
        filter: {
            field: null
        },
        pagination: {
            page: null,
            pageSize: null,
            sort: null
        }
    })

    const { formIndex, values } = useAppSelector(state => state.candidateCreateOzgelecek)
    const { data: ozgeleceks, isFetching } = useGetCandidateOzgeleceksQuery({ variables })

    const handleClick = useCallback((field: any) => {
        dispatch(setCandidateCreateOzgelecekFormField(field?.value))
    }, [dispatch])

    useEffect(() => {
        if (field && !values.field) {
            const isConsist = ozgeleceks?.data?.find((item: any) => item?.field === field)
            console.log({ field, isConsist });
            if (!isConsist) {
                handleClick({ value: field })
                dispatch(setCandidateCreateOzgelecekFormIndex(formIndex + 1))
            }
        }
    }, [dispatch, field, formIndex, handleClick, ozgeleceks?.data, values.field])



    return (
        <Box mt={5}>

            <Grid container spacing={3}  >


                {!isFetching && <Box bgcolor={"secondary.lighter"} p={4} mt={2} ml={3} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                    <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}><Iconify icon={"material-symbols:info-outline"} /></Box>
                    <Typography variant="body2" >
                        Bu sayfadan özgelecek oluşturmaya başlayabilirsiniz. Her alanda yalnızca bir özgelecek oluşturabilirsiniz.
                    </Typography></Box>}
                {
                    isFetching ? (
                        <Grid item xs={12}>
                            <Stack
                                alignItems="center"
                                justifyContent="center"
                                height="100%"
                                py="100px"
                            >
                                <CircularProgress color='primary' />
                            </Stack>
                        </Grid>
                    ) : (

                        OzgelecekFieldsList?.map((field: any, index: number) => {

                            const isConsist = ozgeleceks?.data?.find((item: any) => item?.field === field?.value)

                            return (
                                <Grid item xs={6} sm={4} lg={3} xl={2} key={index}>
                                    <Box

                                        sx={{
                                            px: 1,
                                            width: '100%',
                                            height: '60px',
                                            // width: { xs: '100%', sm: '300px' },
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            transitionDuration: '200ms',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            border: '2px solid',
                                            borderColor: "primary.lighter",
                                            fontSize: { xs: '13px', sm: '17px' },


                                            '&:hover': {
                                                boxShadow: '1px 1px 5px 0px #ccc',
                                                // transform: 'scale(1.05)'
                                            },

                                            bgcolor: values.field === field.value ? 'primary.main' : '#fff',
                                            color: values.field === field.value ? 'white' : '#222',

                                            ...(isConsist && {
                                                bgcolor: 'secondary.light',
                                                cursor: 'initial',
                                                boxShadow: 'none',

                                                '&:hover': {
                                                    // boxShadow: '1px 1px 2px 0px #f1f1f1',
                                                    // boxShadow: 'none',
                                                    // transform: 'scale(1)'
                                                }
                                            }),




                                        }}
                                        onClick={() => isConsist ? null : handleClick(field)}
                                    >
                                        {field?.name}
                                    </Box>
                                </Grid>
                            )
                        })

                    )
                }
            </Grid>

            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" gap={3} mt={2}>
                {/* <Button
                                variant='outlined'
                                color="primary"
                                onClick={handleBackStep}
                            >
                                Geri
                            </Button> */}

                <Button
                    variant='contained'
                    color="primary"
                    sx={{ pl: 5, pr: 4 }}
                    disabled={!values.field}


                    onClick={() => dispatch(setCandidateCreateOzgelecekFormIndex(formIndex + 1))}
                >
                    İleri

                    <Iconify icon="mi:next" sx={{ ml: 1, mt: 0.2 }} />
                </Button>
            </Stack>

        </Box>
    )
}
