import { useState } from "react";
import { Form, Formik, useFormikContext } from 'formik';
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";
import { Box, Card, Grid, Stack, Typography, Skeleton } from "@mui/material";

import { ICompanyInformation, NumberOfEmployeesList } from "types";
import { FormikDatePicker, FormikSelect, FormikTextField, FormikTextFieldPhone } from "components";
import {
  useGetCitiesQuery,
  company_updateCompany,
  useGetCompanyInformationQuery,
  useLazyGetUserProfileImagePresignedUrlQuery,
  useAppDispatch,
  getOnboardingStatus,
} from "context";
import { DashboardLayout } from "layouts";
import { companyUpdateCompanyFormValidation } from "lib/form-validation";
import { compressImage, findImageContentType, saveImageAndGetUrl } from "utils";
import { CandidateAdvertisementDetailImage, Company, User } from "assets";

import { Edit } from "@mui/icons-material"



export const CompanyCompanyInformationPage = () => {

  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const { data, isLoading, refetch } = useGetCompanyInformationQuery("")
  const [profileImage, setProfileImage] = useState<any>(null)
  const [getPresignUrl] = useLazyGetUserProfileImagePresignedUrlQuery()
  const { initialValues, validationSchema } = companyUpdateCompanyFormValidation({
    values: {
      personalName: data?.personalInformation?.name,
      personalSurname: data?.personalInformation?.surname,
      phoneNumber: data?.companyInformation?.phoneNumber,
      companyBrief: data?.companyInformation?.companyBrief,
      companyAddress: data?.companyInformation?.companyAddress,
      email: data?.contact?.email,
      companyName: data?.companyInformation?.companyName,
      city: data?.companyInformation?.city,
      district: data?.companyInformation?.district,
      taxOffice: data?.companyInformation?.taxOffice,
      taxNumber: data?.companyInformation?.taxNumber,
      profileImage: data?.companyInformation?.profileImage,
      sector: data?.companyInformation?.sector,
      foundationYear: data?.companyInformation?.foundationYear,
      numberOfEmployees: data?.companyInformation?.numberOfEmployees,

      website: data?.companyInformation?.website,
      linkedin: data?.companyInformation?.linkedin,
      instagram: data?.companyInformation?.instagram,
      facebook: data?.companyInformation?.facebook,
    }
  })


  console.log({ profileImage });
  const handleSubmit = async (values: ICompanyInformation) => {
    console.log({ values });
    setLoading(true)
    try {

      let url;

      if (profileImage) {
        const contentType = findImageContentType(profileImage)
        const presigneUrl = await getPresignUrl({ contentType: contentType ?? "" })
        console.log(presigneUrl, "presigneUrl")
        const isSaved = await saveImageAndGetUrl({
          file: profileImage,
          presignUrl: presigneUrl?.data?.profileImage?.presignedUploadUrl ?? ""
        })

        url = presigneUrl?.data?.profileImage?.url


        console.log({ isSaved, presigneUrl });
      }


      const response = await company_updateCompany({ variables: { ...values, profileImage: url } })
      if (response?.company_updateCompany?._id) {
        enqueueSnackbar("Bilgileriniz başarıyla güncellendi!")
        refetch()
        dispatch(getOnboardingStatus({ userType: "company" }))
      } else {
        enqueueSnackbar("Güncelleme sırasında bir hata oluştu!", { variant: "error" })
      }
    } catch (error) {
      console.log({ error });
      enqueueSnackbar("Güncelleme sırasında hata oluştu!", { variant: "error" })
    }
    setLoading(false)
  }

  // console.log({ data });

  return (
    <DashboardLayout>

      <Typography variant="h1" sx={{ my: 3 }}>
        Şirket Bilgilerim
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={(values, actions) => {
          handleSubmit(values)
        }}
      >
        <Form>

          {
            isLoading ? (
              <Stack spacing={3} sx={{ py: 3 }}>
                <Skeleton
                  variant='rectangular'
                  sx={{
                    p: 2,
                    width: '100%',
                    height: '200px'
                  }}
                />
                <Skeleton
                  variant='rectangular'
                  sx={{
                    p: 2,
                    width: '100%',
                    height: '100px'
                  }}
                />
                <Skeleton
                  variant='rectangular'
                  sx={{
                    p: 2,
                    width: '100%',
                    height: '100px'
                  }}
                />
              </Stack>
            ) : (
              <>
                <CompanyOnboardingForm
                  profileImage={profileImage}
                  setProfileImage={setProfileImage}
                />

                <Stack direction="row" justifyContent="end">
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ my: 3 }}
                    disabled={loading}
                    loading={loading}
                  >
                    Kaydet
                  </LoadingButton>
                </Stack>
              </>
            )
          }

        </Form>
      </Formik>

    </DashboardLayout>
  )
}



interface CompanyOnboardingFormProps {
  setProfileImage: (e?: any) => void,
  profileImage: any,
  // loading: boolean,
  // setFormDialog: (e?: any) => void,
}

const CompanyOnboardingForm = ({ profileImage, setProfileImage }: CompanyOnboardingFormProps) => {

  const { data: citiesData } = useGetCitiesQuery({ countryName: "Turkey" })
  const { values, handleSubmit } = useFormikContext<ICompanyInformation>()

  // const { data: countriesData, } = useGetCountriesQuery({})
  // const { data: citiesData } = useGetCitiesQuery(
  //     { countryName: values?.personalInformation?.country?.name ?? values?.personalInformation?.country },
  //     { skip: !values?.personalInformation?.country }
  // )

  const handleSetProfileImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log({ event });
    const file = (event.target as HTMLInputElement).files?.[0];
    const compressedImage = file && await compressImage(file, 0.5)
    console.log({ file, compressedImage });
    setProfileImage(compressedImage)
  }


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >

      <Card sx={{ width: '100%', }}>

        <Box>

          <Box
            sx={{
              backgroundImage: `url(${CandidateAdvertisementDetailImage})`,
              height: '200px',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          />

          <Box
            sx={{
              width: 120,
              height: 120,
              borderRadius: '50%',
              border: '7px solid',
              borderColor: '#fff',
              bgcolor: 'secondary.main',
              marginTop: '-60px',
              marginLeft: '20px',
              position: 'relative',
            }}
          >

            <Box
              component={"img"}
              src={
                profileImage
                  ? URL.createObjectURL(profileImage)
                  : values?.profileImage ? values?.profileImage : Company
              }
              sx={{
                bgcolor: 'secondary.main',
                overflow: 'hidden',
                borderRadius: '50%',
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}

            />


            <Box
              component="label"
              htmlFor="profile_image_input"
              sx={{
                width: 25,
                height: 25,
                p: 2,
                borderRadius: '50%',
                border: '1px solid black',
                position: 'absolute',
                bottom: 0,
                right: 0,
                bgcolor: 'secondary.light',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

                '&:hover': {
                  transform: 'scale(1.05)'
                },
                '&:active': {
                  transform: 'scale(1)'
                },
              }}
            >
              <Edit />
            </Box>

            <Box
              component="input"
              type="file"
              accept="image/png, image/jpeg"
              sx={{ display: 'none' }}
              id="profile_image_input"
              // value={profileImage}
              onChange={handleSetProfileImage}
            />
          </Box>
        </Box>

        <Grid container spacing={3} sx={{ p: 2, mt: 1 }}>

          <Grid item xs={12} sm={6}>
            <FormikTextField upperCase name="personalName" label="Yetkili Adı" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormikTextField upperCase name="personalSurname" label="Yetkili Soyadı" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormikTextField name="email" label="Email" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormikTextFieldPhone name="phoneNumber" label="Telefon Numarası" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormikTextField upperCase name="companyName" label="Şirket Adı" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormikTextField upperCase name="sector" label="Sektör" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormikTextField name="taxOffice" label="Vergi Dairesi" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormikTextField name="taxNumber" label="Vergi Numarası" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormikDatePicker name="foundationYear" label="Kuruluş Yılı" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormikSelect name="numberOfEmployees" label="Çalışan Sayısı" list={NumberOfEmployeesList} />
          </Grid>

          <Grid item xs={12} >
            <FormikTextField
              upperCase
              multiline
              rows={4}
              label="Şirket Hakkında"
              name="companyBrief"
            />
          </Grid>

          <Grid item xs={12} >
            <FormikTextField
              upperCase
              multiline
              rows={4}
              label="Şirket Adresi"
              name="companyAddress"
            />

          </Grid>
        </Grid>
      </Card>


      <Card sx={{ width: '100%', p: 2 }}>
        <Grid container spacing={3} >



          <Grid item xs={12} sm={6}>
            <FormikSelect
              name="city"
              label="İl"
              list={citiesData?.data ?? []}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* <FormikTextField upperCase name="district" label="İlçe" /> */}
            <FormikSelect
              name="district"
              label="İlçe"
              list={citiesData?.data?.find((item) => item.name === values.city)?.districts ?? []}
            />
          </Grid>
        </Grid>
      </Card>




      <Card sx={{ width: '100%', p: 2 }}>
        <Grid container spacing={3} >
          <Grid item xs={12} sm={6}>
            <FormikTextField name="facebook" label="Facebook" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormikTextField name="instagram" label="Instagram" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormikTextField name="linkedin" label="Linkedin" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormikTextField name="website" label="Website" />
          </Grid>
        </Grid>
      </Card>

    </Box>
  )
}