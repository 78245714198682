import { useFormikContext } from 'formik'
import { Button, Card, Grid, Stack, Typography, Divider, Switch } from '@mui/material'
import { useAppSelector, useAppDispatch, setAdvertisementFormIndex } from 'context'
import { ExtraProductList, ProductTypeList } from 'types'
import { ICreateAdvertisementForm } from 'lib/form-validation'
import { FormikCheckbox, FormikDatePicker, FormikSelect, FormikTextField, Iconify, FormikSwitch } from 'components'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'



export const CreateAdvertisementOnboardingFirst = () => {

    const { id } = useParams()
    const dispatch = useAppDispatch()
    const { setFieldValue, values, handleSubmit } = useFormikContext<ICreateAdvertisementForm>()
    const { advertisementId, advertisementFormIndex } = useAppSelector((state) => state.companyCreateAdvertisement)
    const [count, setCount] = useState(1)
    const [statusReady, setStatusReady] = useState(false)


    const handleNextStep = () => {
        console.log('adsf', values)
        setFieldValue("advertisementId", advertisementId);
        // ( values?.status !== "onboardingSecondCompleted" && values?.status !== "onboardingThirdCompleted" && values?.status !== "onboardingForthCompleted") && setFieldValue("status", "onboardingFirstCompleted")
        if ((values?.status === "pendingApproval")) setFieldValue("status", "pending")
        else if (values?.status === "approved") setFieldValue("status", "pendingApproval")
        else if (values?.status === "onboardingSecondCompleted") setFieldValue("status", "onboardingSecondCompleted")
        else if (values?.status === "onboardingThirdCompleted") setFieldValue("status", "onboardingThirdCompleted")
        else if (values?.status === "onboardingForthCompleted") setFieldValue("status", "onboardingForthCompleted")
        else setFieldValue("status", "onboardingFirstCompleted")

        setStatusReady(true)

    }
    const handleBackStep = () => {
        dispatch(setAdvertisementFormIndex(advertisementFormIndex - 1))
    }

    useEffect(() => {
        if (statusReady) {
            handleSubmit();
        }
    }, [statusReady])

    useEffect(() => {
        const extraProducts = [];
        if (values?.doping) {
            const dopingQuantity = values.dopingQuantity;
            for (let i = 0; i < dopingQuantity; i++) {
                extraProducts.push("doping");
            }
        }
        if (values?.emergent) {
            extraProducts.push("emergent");
        }
        if (values?.showcase) {
            extraProducts.push("showcase");
        }
        setFieldValue("extraProducts", extraProducts);
    }, [values?.doping, values?.showcase, values?.emergent, setFieldValue, values?.dopingQuantity]);


    useEffect(() => {
        if (values.extraProducts?.length > 0 && id && count === 1) {
            console.log(values?.extraProducts)
            const newValues = {
                ...values,
                doping: values.extraProducts.includes("doping"),
                dopingQuantity: values.extraProducts.filter((product: string) => product === "doping").length,
                emergent: values.extraProducts.includes("emergent"),
                showcase: values.extraProducts.includes("showcase")
            };

            console.log(values?.extraProducts.includes("doping"), 'azzz')
            setFieldValue("doping", values?.extraProducts.includes("doping"))
            setFieldValue("dopingQuantity", newValues.dopingQuantity);
            setFieldValue("emergent", newValues.emergent);
            setFieldValue("showcase", newValues.showcase);
            setCount(2)
        }
    }, [id, values])

    return (
        <Card sx={{ p: 3 }}>
            <Grid container spacing={3}>

                {/* <Grid item xs={12}>
                    <Typography variant='h3'>
                        İlan Bilgileri
                    </Typography>
                </Grid> */}

                <Grid item xs={12} sm={6}>
                    <FormikTextField upperCase name='title' label="İlan Başlığı / Pozisyon Adı*" />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormikSelect list={ProductTypeList} name='productType' label="Ürün*" disabled={values?.status === "pending" || values?.status === "pendingApproval" || values?.status === "approved"} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormikDatePicker name="startDate" label="Yayınlanma Tarihi*"
                        maxDate={new Date(new Date().setMonth(new Date().getMonth() + 2))}
                    />
                </Grid>

                <Grid item xs={12} sm={6} display={"flex"} flexDirection={"column"} gap={2}>
                    <FormikSelect
                        list={[{ value: 1, name: "1" }, { value: 3, name: "1-5" }, { value: 8, name: "5-10" }, { value: 15, name: "10-20" }, { value: 20, name: "20+" }]}
                        name='quota'
                        label="Alınacak Personel Sayısı*"
                    />
                    <FormikCheckbox name="quotaVisibleToApplicant" label="Alınacak personel sayısı adaylara gösterilsin mi?" />
                </Grid>


                {!(values?.status === "pending" || values?.status === "pendingApproval" || values?.status === "approved") && <Grid item xs={12} >
                    <Typography variant="subtitle1" color="textSecondary">Ekstra Özellikler</Typography>
                    <Divider />

                    <Typography mt={2} variant="subtitle1" color="primary" display={"flex"} alignItems={"center"} gap={2}><Iconify icon="ri:medicine-bottle-line" width={26} /> Doping ilan
                        <FormikSwitch name="doping" />
                        {values?.doping && <FormikSelect name="dopingQuantity" label={"Doping Miktarı"} sx={{ width: 115 }} size='small' disabled={!values?.doping}
                            list={[{ value: 1, name: "1" }, { value: 2, name: "2" }, { value: 3, name: "3" }, { value: 4, name: "4" }, { value: 5, name: "5" }]}
                        />}</Typography>
                    <Typography fontStyle={"italic"} variant="body2" color="textSecondary">Doping ilan kullanarak diğer ilanlardan daha önde ilanınızı yayınlatabilirsiniz.</Typography>

                    <Typography mt={4} variant="subtitle1" color="primary" display={"flex"} alignItems={"center"} gap={2}><Iconify icon="fluent:alert-urgent-16-regular" width={26} /> Acil ilan
                        <FormikSwitch name="emergent" />

                    </Typography>
                    <Typography fontStyle={"italic"} variant="body2" color="textSecondary">Acil ilan kullanarak adayların dikkatini daha çabuk çekebilirsiniz.</Typography>

                    <Typography mt={4} variant="subtitle1" color="primary" display={"flex"} alignItems={"center"} gap={2}><Iconify icon="material-symbols:photo-frame-outline" width={26} /> Vitrin ilan
                        <FormikSwitch name="showcase" />
                    </Typography>
                    <Typography fontStyle={"italic"} variant="body2" color="textSecondary">Vitrin ilan kullanarak ilanınızı daha fazla adaya gösterebilirsiniz.</Typography>
                </Grid>}

                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between">
                        {!id ? <Button
                            sx={{
                                width: 150,
                            }}
                            variant='outlined'
                            color='primary'
                            onClick={handleBackStep}
                        >
                            Geri
                        </Button> : <Stack />}

                        <Button
                            sx={{
                                width: 150,
                            }}
                            variant='contained'
                            onClick={handleNextStep}
                            color='primary'
                            disabled={
                                !values.title ||
                                !values.extraProducts ||
                                !values.productType ||
                                !values.quota ||
                                !values.startDate
                            }
                        >
                            İleri
                        </Button>
                    </Stack>
                </Grid>

            </Grid>
        </Card >
    )
}
