


export interface ICompanyAddCustomApplicationListItemsResponse {
    success: boolean
}

export interface ICompanyAddCustomApplicationListItemsVariables {
    customApplicationListId: string,
    customApplicationListItems: Array<{
        application: {
            _id: string
        }
    }>
}

export const company_addCustomApplicationListItems = (variables: ICompanyAddCustomApplicationListItemsVariables) => {

    const data = JSON.stringify({
        query: `
        mutation Company_addCustomApplicationListItems($customApplicationListId: ID!, $customApplicationListItems: [CustomApplicationListItemInput!]!) {
            company_addCustomApplicationListItems(customApplicationListId: $customApplicationListId, customApplicationListItems: $customApplicationListItems) {
              success
            }
          }
        `,
        variables: {
            "customApplicationListId": variables?.customApplicationListId ?? null,
            "customApplicationListItems": variables?.customApplicationListItems ?? []
        }
    })

    return data

}