

export interface ICandidateCompanyResponse {
    _id: string
    companyInformation: {
        address: string
        city: string
        companyAddress: string
        companyBrief: string
        companyName: string
        district: string
        email: string
        facebook: string
        foundationYear: string
        instagram: string
        linkedin: string
        numberOfEmployees: string
        phoneNumber: string
        profileImage: string
        sector: string
        website: string
    }
    createdAt: string
    updatedAt: string
}
export interface ICandidateCompanyVariables {
    companyId: any
}


export const candidate_company = (variables: ICandidateCompanyVariables) => {

    const data = JSON.stringify({
        query: `query Candidate_company($companyId: ID!) {
            candidate_company(companyId: $companyId) {
              _id
              companyInformation {
                city
                address
                companyAddress
                companyBrief
                companyName
                district
                email
                facebook
                foundationYear
                sector
                profileImage
                website
                numberOfEmployees
                linkedin
                instagram
                phoneNumber
              }
              createdAt
            }
          }`,
        variables: {
            "companyId": variables?.companyId
        }
    })


    return data
}