import { useFormikContext } from 'formik'
import { StandardTextFieldProps } from '@mui/material'
import { MuiTelInput, MuiTelInputInfo, MuiTelInputFlagElement } from 'mui-tel-input'


interface IFormikTextField extends StandardTextFieldProps {
    name: string,
}

export const FormikTextFieldPhone = ({ name = "", ...rest }: IFormikTextField) => {

    const { getFieldProps, getFieldMeta, setFieldValue } = useFormikContext<IFormikTextField | any>()

    // const [value, setValue] = useState<string>(name)

    const handleChange = (newValue: string, info: MuiTelInputInfo) => {
        setFieldValue(name, newValue)
        getFieldProps(name).onChange(newValue)
    }

    const error = !!(getFieldMeta(name).touched && getFieldMeta(name).error)
    const helperText = !!getFieldMeta(name).touched && getFieldMeta(name).error && `${getFieldMeta(name).error}`

    const unknownFlag: MuiTelInputFlagElement = null

    console.log({ error, helperText });

    return (
        <MuiTelInput
            {...getFieldProps(name)}
            // {...rest}
            name={name ?? ""}
            value={getFieldProps(name).value}
            onChange={handleChange}
            unknownFlagElement={unknownFlag}
            label={rest?.label ?? "Telefon Numarası"}
            defaultCountry='TR'
            fullWidth
            error={error}
            helperText={rest.helperText ?? helperText}
        />
    )
}