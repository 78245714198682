import IBaseResult from "interfaces/IBaseResult"

export interface IUserReadAllUserNotificationResponse extends IBaseResult {}

export interface IUserReadAllUserNotificationVariables { }


export const user_readAllUserNotification = () => {

  const data = JSON.stringify({
    query: `
      mutation user_readAllUserNotifications{
        user_readAllUserNotifications {
          success
        }
      }
    `,
    variables: {}

  })

  return data

}