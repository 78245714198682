import { Box, Button, Stack, Typography } from '@mui/material'
import { Iconify } from 'components'
import { useAppDispatch, setAdvertisementFormIndex } from 'context'

export const CreateAdvertisementFirst = () => {

    const dispatch = useAppDispatch()

    return (
        <Stack spacing={3}>

            <Box bgcolor={"secondary.lighter"} p={4} mt={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}>
                    <Iconify icon={"material-symbols:info-outline"} />
                </Box>
                <Typography variant="body2" >
                    Özgelecek vizyonuyla ilan vermek, işe alacağınız adayların geçmişlerinden ziyade gelecekte ilgili işte ne kadar yüksek motivasyonla ve ne kadar ilgiyle çalışacaklarına odaklanarak bir ilan oluşturmak demektir. Bu nedenle ilan içeriğinizde “… deneyimli, … tecrübeli” gibi ifadelere yer vermemeniz, bu bilgilendirmenin yerine “işyerimize … katkılarda bulunacak, işimizi … geliştirme isteğinde bulunan” gibi ibareler kullanmanızı rica ederiz.                 </Typography>
            </Box>

            <Stack direction="row" justifyContent="end">
                <Button
                    variant='contained'
                    color="primary"
                    onClick={() => dispatch(setAdvertisementFormIndex(1))}
                >
                    İleri
                </Button>
            </Stack>
        </Stack>
    )
}
