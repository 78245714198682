

export interface ICompanyCompleteAdvertisementOnboardingResponse {
    company_completeAdvertisementOnboarding: {
        success: boolean
    }
}
export interface ICompanyCompleteAdvertisementOnboardingVariables {
    advertisementId: any
}


export const company_completeAdvertisementOnboarding = (variables: ICompanyCompleteAdvertisementOnboardingVariables) => {

    const data = JSON.stringify({
        query: `mutation Company_completeAdvertisementOnboarding($advertisementId: ID!) {
            company_completeAdvertisementOnboarding(advertisementId: $advertisementId) {
              success
            }
          }`,
        variables: {
            "advertisementId": variables?.advertisementId ?? null
        }
    })

    return data

}