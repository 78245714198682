export type TCandidateCreateReferenceVariables = {
    variables: {
        referenceInformation: {
            email: string | null,
            jobTitle: string | null,
            name: string | null,
            phoneNumber: string | null,
            relationWithCandidate: string | null,
            surname: string | null
        }
    }
}

export const candidate_createReference = ({ variables }: TCandidateCreateReferenceVariables) => {

    const data = JSON.stringify({
        query: `
        mutation Candidate_createReference($referenceInput: ReferenceInput!) {
            candidate_createReference(referenceInput: $referenceInput) {
              token
            }
          }
        `,
        variables: {
            "referenceInput": {
                "referenceInformation": {
                    "email": variables?.referenceInformation?.email ?? null,
                    "jobTitle": variables?.referenceInformation?.jobTitle ?? null,
                    "name": variables?.referenceInformation?.name ?? null,
                    "phoneNumber": variables?.referenceInformation?.phoneNumber ?? null,
                    "relationWithCandidate": variables?.referenceInformation?.relationWithCandidate ?? null,
                    "surname": variables?.referenceInformation?.surname ?? null,
                }
            }

        }
    })

    return data
}