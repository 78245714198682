


export interface IAnonymousCreateInformationFormResponse {
    _id: string;
}

export interface IAnonymousCreateInformationFormVariables {
    email: string | null,
    name: string | null,
    phoneNumber: string | null,
    surname: string | null
}

export const anonymous_createInformationForm = (variables: IAnonymousCreateInformationFormVariables) => {
    console.log({ variables });
    const data = JSON.stringify({
        query: `
        mutation Anonymous_createInformationForm($informationFormInput: InformationFormInput!) {
            anonymous_createInformationForm(informationFormInput: $informationFormInput) {
              _id
            }
          }
        `,
        variables: {
            informationFormInput: { ...variables }
        }
    })

    return data

}