

export const candidate_updateCandidateOnboardingStatus = () => {

  const data = JSON.stringify({
    query: `
        mutation Candidate_updateCandidateOnboardingStatus($status: CandidateOnboardingStatus!) {
            candidate_updateCandidateOnboardingStatus(status: $status) {
              success
            }
          }
        `,
    variables: {
      "status": "completed"
    }

  })

  return data

}