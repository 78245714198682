import { LoadingButton } from '@mui/lab'
import { MuiTransition } from 'components/animations'
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'


interface IRejectApplicationDialog {
    applyDialog: any,
    setApplyDialog: (e?: any) => void,
    onClick: (e?: any) => void,
    loading: boolean,
    button?: boolean
}


export const RejectApplicationDialog = ({ button = true, applyDialog, setApplyDialog, onClick, loading }: IRejectApplicationDialog) => {

    return (
        <div>

            {
                button && (
                    <Button color='primary'
                        variant='contained'
                        sx={{ px: 4, py: 2 }}
                        onClick={() => setApplyDialog(true)
                        }
                    >
                        <ThumbUpAltRoundedIcon sx={{ mr: 1 }} /> &nbsp; <Typography fontWeight={'bold'}>Başvur</Typography>
                    </Button>
                )
            }


            <Dialog
                open={!!applyDialog}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setApplyDialog(null)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <span style={{ textTransform: 'capitalize' }}>
                        Başvuru İptali
                    </span>
                </DialogTitle>

                <DialogContent>
                    <Stack
                        direction="column"
                        gap={3}
                        sx={{
                            width: { xs: '100%', sm: '400px' },
                            py: 0
                        }}
                    >
                        <Typography variant='body2'>
                            {
                                applyDialog?.advertisement?.title?.[0]?.toUpperCase() +
                                applyDialog?.advertisement?.title?.slice(1)
                            } adlı ilanı iptal etmek istediğinize emin misiniz?
                        </Typography>

                        {/* {
                            selectedOzgelecek ? (
                                <Typography color="black" sx={{ fontWeight: 'bold' }}>
                                    Seçilen Özgelecek
                                </Typography>
                            ) : (
                                <Typography textAlign="center">
                                    Bu alanda özgeleceğiniz bulunmadığı için başvuru yapamazsınız.
                                    <br />
                                    Yeni bir özgelecek oluşturmak için butona tıklayınız
                                </Typography>
                            )
                        } */}

                        {/* {
                            selectedOzgelecek ? (
                                <Stack
                                    direction={{ xs: 'row' }}
                                    justifyContent="space-between"
                                    gap={2}
                                    sx={{
                                        border: '1px solid #ccc',
                                        width: '100%',
                                        p: 2,
                                    }}
                                >
                                    <Typography color="gray" sx={{ textTransform: 'capitalize' }}>
                                        {enumFunctions.findAndDisplayName(OzgelecekFieldEnum, advertisement?.field ?? "")}
                                    </Typography>

                                    <Typography
                                        color="primary"
                                        fontSize={"small"}
                                        fontWeight={'bold'}

                                        sx={{ textTransform: 'capitalize', cursor: 'pointer' }}
                                    >
                                        Güncelle
                                    </Typography>
                                </Stack>
                            ) : (
                                <>
                                    <Button
                                        variant='contained'
                                        color="secondary"
                                        onClick={() => {
                                            dispatch(setCandidateCreateOzgelecekFormField(advertisement?.field))
                                            navigate(
                                                // + '?field=' + advertisement?.field
                                            )
                                        }}
                                    >
                                        Özgelecek Ekle
                                    </Button>
                                </>
                            )
                        } */}



                    </Stack>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setApplyDialog(null)} color="primary" variant='outlined'>
                        Kapat
                    </Button>

                    <LoadingButton
                        color='primary'
                        variant='contained'
                        onClick={onClick}
                        loading={loading}
                        disabled={!applyDialog}
                    >
                        İptal Et
                    </LoadingButton>
                </DialogActions>
            </Dialog>


        </div>
    )
}
