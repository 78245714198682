

export interface ICompanyDocumentDownloadPresignedUrlResponse {
  company_companyDocumentDownloadPresignedUrl: string
}


export interface ICompanyDocumentDownloadPresignedUrlVariables {
  documentType: string
}



export const companyDocumentDownloadPresignedUrl = (variables: ICompanyDocumentDownloadPresignedUrlVariables) => {

  const data = JSON.stringify({
    query: `query Query($documentType: CompanyDocument!) {
      company_companyDocumentDownloadPresignedUrl(documentType: $documentType)
    }`,
    variables: {
      "documentType": variables?.documentType ?? null,
    }
  })

  return data
}