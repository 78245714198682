import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Stack, Typography } from '@mui/material'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


interface HelpCenterSectionProps {
    list: Array<any>
}



export const HelpCenterSection = ({ list }: HelpCenterSectionProps) => {

    const [expanded, setExpanded] = useState<any>(0);


    return (
        <Box>

            {
                list?.map((item: any, index: number) => {

                    return (
                        <Accordion
                            key={index}
                            expanded={expanded === index}
                            onChange={() => setExpanded((prev: any) => prev === index ? null : index)}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                <Typography sx={{ width: '10%', flexShrink: 0 }}>
                                    {index + 1}
                                </Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{item?.title}</Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                                <Typography>
                                   {item?.content}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }

        </Box>
    )
}
