

export interface ICandidateBackgroundImagePresignedUrlResponse {
  backgroundImage: {
    url: string
    presignedUploadUrl: string
    presignedThumbnailUpload: string
  }
}


export interface ICandidateBackgroundImagePresignedUrlVariables {
  contentType: string
}



export const candidateBackgroundImagePresignedUrl = (variables: ICandidateBackgroundImagePresignedUrlVariables) => {

  const data = JSON.stringify({
    query: `
        query UserPresignedUrls($contentType: ImageContentType!) {
            userPresignedUrls(contentType: $contentType) {
              backgroundImage {
                url
                presignedUploadUrl
                presignedThumbnailUpload
              }
            }
          }
        `,
    variables: {
      "contentType": variables?.contentType ?? null
    }
  })

  return data
}