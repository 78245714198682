

export interface ICompanyViewApplicationResponse {
    success: boolean
}
export interface ICompanyViewApplicationVariables {
    applicationId: string
}


export const company_viewApplication = (variables: ICompanyViewApplicationVariables) => {

    const data = JSON.stringify({
        query: `
        mutation Company_viewApplication($applicationId: ID!) {
            company_viewApplication(applicationId: $applicationId) {
              success
            }
          }
        `,
        variables: {
            "applicationId": variables?.applicationId ?? null
        }
    })

    return data

}