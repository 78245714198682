import { Box, CircularProgress, Stack } from '@mui/material'
import { Logo } from 'assets'
import { useAppSelector } from 'context'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes'

export const LoadingPage = () => {

    const { device, userType } = useAppSelector(state => state.auth)

    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const accessToken = urlParams.get('accessToken');
        const role = urlParams.get('role');


        // if (device === "mobile" && userType === "candidate") {
        //     navigate(routes.candidate.dashboard.mainMobile);
        // }
        // if (device === "mobile" && userType === "company") {
        //     navigate(routes.company.dashboard.mainMobile);
        // }

    }, []);



    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            height="100vh"
            gap={3}
        >

            <CircularProgress />

            <Box
                component="img"
                src={Logo}
                height={100}
            />

        </Stack>
    )
}
