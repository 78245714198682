



export interface ICompanyMakeApplicationBlackListedResponse {
    success: boolean
}
export interface ICompanyMakeApplicationBlackListedVariables {
    applicationId: string
}



export const company_makeApplicationBlackListed = (variables: ICompanyMakeApplicationBlackListedVariables) => {

    const data = JSON.stringify({
        query: `
        mutation Company_makeApplicationBlackListed($applicationId: ID!) {
            company_makeApplicationBlackListed(applicationId: $applicationId) {
              success
            }
          }
        `,
        variables: {
            "applicationId": variables?.applicationId ?? null
        }
    })

    return data

}