import { HasId } from "interfaces/HasId"

export type ICompanyCandidatePoolPriceResponse = number

export interface ICompanyCandidatePoolPriceVariables extends HasId {}


export const company_candidateFromPoolPrice = (variables: ICompanyCandidatePoolPriceVariables) => {
  const data = JSON.stringify({
    query: `
      query company_candidateFromPoolPrice($id: ID!) {
        company_candidateFromPoolPrice(candidateId: $id)
      }
          `,
    variables: variables

  })

  return data

}