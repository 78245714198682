import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AppBar, Box, Container, Drawer, Grid, IconButton, Menu, Stack, Toolbar } from '@mui/material'
import { logoutReducer, useAppDispatch, useAppSelector } from 'context'
import { useMediaQuery } from '@mui/material';
import NavDefault from "components/navigations/NavDefault";
// import { useNavData } from 'components/navigations/ConfigNavigations';
import { routes } from 'routes';
import { useLocales } from 'minimal/locales';
import { Badge } from '@mui/material';

import { Menu as MenuIcon } from '@mui/icons-material';
import { Logo } from 'assets';
import { NotificationDrawer } from 'sections';
import { Iconify } from 'components';

const token1 = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InJlZmVyZW5jZXIxQG1lc3V0LmNvbSIsInVzZXJJZCI6IjY1OGIwNTVjM2Y4OTZiNWYzNTA3MmZjYyIsImlhdCI6MTcwNDk4MjY2OCwiZXhwIjoxNzA1MjQxODY4fQ.N9DpljAAAQ0Ub7roNuutidQo30SNkPfqqfCrqOrKy24"
const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InJlZmVyZW5jZXIyQG1lc3V0LmNvbSIsInVzZXJJZCI6IjY1OGIwNTVjM2Y4OTZiNWYzNTA3MmZjYyIsImlhdCI6MTcwNDk4MzkyOSwiZXhwIjoxNzA1MjQzMTI5fQ.iIrTePP6nP7U_ikoG29Tlbyr9JRqLoVkWNHRaVBrlqU"


export const DashboardLayout = ({ children }: any) => {

    const { t } = useLocales();
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const [anchorEl, setAnchorEl] = useState(null);
    const { userType, isOnboarding } = useAppSelector(state => state.auth)
    const [mobileDrawer, setMobileDrawer] = useState(false)
    const { device } = useAppSelector(state => state.auth)

    const open = Boolean(anchorEl);

    // const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));
    // const navData = useNavData();
    // const isNavigationMini = false


    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleNavigation = (path = "/") => {
        navigate(path);
        handleClose();
        setAnchorEl(null);
    }


    const handleAccount = () => {
        handleNavigation('/my-account');
    }


    const handleLogout = () => {
        dispatch(logoutReducer({}))
        document.location.reload();
    }

    const handleSettings = () => {
        handleNavigation('/ayarlar');
    }





    const renderDashboardMenu = () => {

        switch (userType) {
            case "company":
                return ([
                    {
                        items: [
                            { title: t('Panel'), path: routes.company.dashboard.main },
                            { title: t('İlanlarım'), path: routes.company.dashboard.myAdvertisements },
                            // { title: t('İlan Oluştur'), path: routes.company.dashboard.createAdvertisement },
                            {
                                title: 'Şirket Bilgilerim', path: routes.company.dashboard.aboutCompany,
                                info: isOnboarding === "pendingRequest" ? <Badge badgeContent="!" color="primary" sx={{ ml: -2 }} /> :
                                    isOnboarding === "pendingApproval" ? <Badge badgeContent="!" color="secondary" sx={{ ml: -2 }} /> : null
                            },
                            { title: "Başvurular", path: routes.company.dashboard.applications },
                            { title: "Özgelecek Radar", path: routes.company.dashboard.radar },
                            { title: "Aday Havuzu", path: routes.company.dashboard.havuz },
                            { title: "Özgelecek Kredisi", path: routes.company.dashboard.credits },

                            {
                                title: "Özgelecek Keşif", path: routes.company.dashboard.conversionCreate
                            },
                        ]
                    },
                    {
                        subheader: 'Ayarlar',
                        items: [
                            { title: 'Ayarlar', path: routes.company.dashboard.settings },
                            {
                                title: 'Dönüşüm', path: routes.company.dashboard.donusum,
                                icon: <Iconify icon="mdi:account-convert" />
                            },
                            { title: 'Yardım Merkezi', path: routes.company.dashboard.helpCenter },
                        ]
                    }
                ])

            case "candidate":
                return ([
                    {
                        items: [
                            { title: t('Panel'), path: routes.candidate.dashboard.main },
                            { title: t('İlanlar'), path: routes.candidate.dashboard.advertisements },
                            { title: t('Özgeleceklerim'), path: routes.candidate.dashboard.ozgeleceklerim },
                            { title: t('Referanslarım'), path: routes.candidate.dashboard.myReferences },
                            { title: t('Başvurularım'), path: routes.candidate.dashboard.applications },
                        ]
                    },
                    {
                        subheader: 'Ayarlar',
                        items: [
                            { title: 'Ayarlar', path: routes.candidate.dashboard.settings },
                            { title: 'Yardım Merkezi', path: routes.candidate.dashboard.helpCenter },
                        ]
                    }
                ])

            default:
                return ([
                    {
                        items: [
                            { title: 'Panel', path: routes.anonymous.main },
                        ]
                    },
                ])
        }
    }

    if (device === "mobile") {
        return <Stack direction={{ xs: "column", md: "row" }} sx={{ height: '100%' }} >
            <Box sx={{ overflow: 'auto', width: '100%', height: '100%' }}>
                <Container sx={{ p: 2, position: 'relative', pb: 20 }}>
                    {children}
                </Container>
            </Box>
        </Stack>
    } else {
        return (
            <Stack
                direction={{ xs: "column", md: "row" }}
                sx={{
                    height: '100%',
                }}
            >

                {/* ******************   menü ********************** */}
                <>
                    {/* ******************   desktop navbar ********************** */}
                    <Box sx={{ height: '100%', display: { xs: 'none', md: 'block' } }}>
                        <NavDefault
                            open={open}
                            anchorEl={anchorEl}
                            navData={renderDashboardMenu()}
                            handleClose={handleClose}
                            handleClick={handleClick}
                            handleLogout={handleLogout}
                            handleAccount={handleAccount}
                            handleSettings={handleSettings}
                        />
                    </Box>



                    {/* ******************   mobile navbar ********************** */}
                    <AppBar position="relative" sx={{ display: { xs: 'block', md: 'none' }, boxShadow: '1px 1px 5px 0px #ccc', py: 1 }}>
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >

                            <IconButton
                                onClick={() => setMobileDrawer(true)}
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                            >
                                <MenuIcon />
                            </IconButton>

                            <Stack
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                    position: 'absolute',
                                    left: 0,
                                    right: 0,
                                    top: 0,
                                    bottom: 0,
                                    zIndex: -3,
                                }}
                            >
                                <Box
                                    component="img"
                                    src={Logo}
                                    sx={{
                                        width: '100px'
                                    }}
                                />
                            </Stack>

                            {/* <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                            <NotificationDrawer />
                        </Box> */}
                            <Box sx={{ display: { xs: 'block', md: 'none' } }} style={{ zIndex: 1000 }}>
                                <NotificationDrawer />
                            </Box>

                        </Toolbar>
                    </AppBar>

                    <Drawer
                        anchor="left"
                        open={mobileDrawer}
                        onClose={() => setMobileDrawer(false)}
                    >
                        <NavDefault
                            open={open}
                            anchorEl={anchorEl}
                            navData={renderDashboardMenu()}
                            handleClose={handleClose}
                            handleClick={handleClick}
                            handleLogout={handleLogout}
                            handleAccount={handleAccount}
                            handleSettings={handleSettings}
                        />
                    </Drawer>
                </>






                {/* ******************   main sections ********************** */}
                <Box sx={{ overflow: 'auto', width: '100%', }}>
                    <Container sx={{ p: 3, ml: 0, py: 8, pt: { xs: 2, sm: 10 }, position: 'relative' }}>

                        <Box sx={{ position: 'absolute', right: 20, top: 30, display: { xs: 'none', md: 'block' } }}>
                            <NotificationDrawer />
                        </Box>

                        {children}
                    </Container>
                </Box>

            </Stack>
        )
    }
}
