import HasFilter from "interfaces/HasFilter"
import { FilterOzgelecekForAdmin, IFilterCompanyCandidatePool } from "interfaces/user/ozgelecek/ozgelecek/IFilterOzgelecek"

export type ICompanyMatchingOzgelecekCountResponse = number

export interface ICompanyMatchingOzgelecekCountVariables extends HasFilter<IFilterCompanyCandidatePool> {}


export const company_matchingOzgelecekCount = (variables: ICompanyMatchingOzgelecekCountVariables) => {
  const data = JSON.stringify({
    query: `
      query company_matchingOzgelecekCount($filter: FilterOzgelecekForCompany) {
        company_matchingOzgelecekCount(filter: $filter)
      }
          `,
    variables: variables

  })

  return data

}