import HasFilter from "interfaces/HasFilter";
import HasPagination from "interfaces/HasPagination";
import IPaginatedListResult from "interfaces/IPaginatedListResult";
import { IFilterOrderByUser } from "interfaces/payment/order/IFilterOrder";
import IOrder from "interfaces/payment/order/IOrder";

export interface IUserOrdersResponse extends IPaginatedListResult<IOrder> {}

export interface IUserOrdersVariables  extends HasFilter<IFilterOrderByUser>, HasPagination {}

export const user_orders = (variables: IUserOrdersVariables) => {

  const data = JSON.stringify({
    query: `
      query user_orders($filter: FilterOrderByUserInput, $pagination: Pagination) {
        user_orders(filter: $filter, pagination: $pagination) {
          count
          data {
            _id
            createdAt
            orderStatus
            payment {
              paymentStatus
              data
              error
            }
            product {
              _id
              name
              type
              listUnitPrice
              targetRoles
              unitPrice
              amount
              price
              discount
              currency
              createdAt
              updatedAt
            }
            price {
              netTotal
              currency
            }
            user {
              _id
            }
            addresses {
              billingAddress {
                fullName
                companyName
                phoneNumber
                email
                country
                city
                district
                address1
                address2
                postcode
                taxIdentificationNumber
                taxOffice
              }
            }
            createdBy {
              _id
              role
              displayName
              displayImageUrl
            }
            errorData {
              code
              name
              message
              detail
            }
            updatedAt
            updatedBy {
              _id
              role
              displayName
              displayImageUrl
            }
          }
        }
      }
    `,
    variables: variables
  })

  return data

}