import { useFormikContext } from 'formik'
import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material'

import { ProgrammingLanguageForm, WorkExperienceInMonthComponent } from "../independed"
import { FormikCheckbox, FormikDatePicker, FormikSelect, FormikTextField } from 'components'
import { ComputerKnowledgeLevelsList, DriverLicenseStatusList, ICandidateInformationInput, ICandidatePersonalInformation } from 'types'




interface ICandidateAccountPersonalInformationFormProps {
    isButton?: boolean,
    onSubmit?: (e: ICandidatePersonalInformation) => any,
    defaultValues?: ICandidatePersonalInformation | undefined | null,
}

export const CandidateAccountAdditionalInformationForm = () => {

    const { values, setFieldValue, handleSubmit } = useFormikContext<ICandidateInformationInput>()


    return (
        <Card sx={{ width: '100%', p: 2 }}>
            <Grid container spacing={3} >

                <Grid item xs={12}>
                    <Typography variant="h5">
                        Ek Bilgiler
                    </Typography>
                </Grid>

                <Grid item xs={12} >
                    <WorkExperienceInMonthComponent
                        value={values.workExperiencesInMonths}
                        setValue={(value: number) => setFieldValue("workExperiencesInMonths", value)}
                    />
                </Grid>



                <Grid item xs={12} sm={6}>
                    <FormikSelect name="computerKnowledgeLevel" label="Bilgisayar bilgi Seviyesi" list={ComputerKnowledgeLevelsList} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormikSelect name="smoking"
                        label="Sigara Kullanımı"
                        list={[
                            { value: true, name: "Kullanıyorum" },
                            { value: false, name: "Kullanmıyorum" }
                        ]}
                    />
                </Grid>


                <Grid item xs={12}> </Grid>
                <Grid item xs={12}> </Grid>



                <Grid item xs={12}>
                    <ProgrammingLanguageForm
                        list={values?.programmingLanguages ?? []}
                        onSubmit={(programmingLanguage) => {
                            const isFind = values.programmingLanguages.find(item => item?.name === programmingLanguage?.name)
                            if (isFind) return
                            setFieldValue("programmingLanguages", [...values.programmingLanguages, programmingLanguage])
                        }}

                        onDeleteItem={(index) => {
                            const newList = values.programmingLanguages.filter((item, i) => i !== index)
                            console.log({ index });
                            setFieldValue("programmingLanguages", newList)

                        }}
                    />

                </Grid>






                <Grid item xs={12}> </Grid>
                <Grid item xs={12}> </Grid>

                <Grid item xs={12} sm={6}>
                    <FormikSelect
                        name="driverLicense.status"
                        label="Sürücü Ehliyeti*"
                        list={DriverLicenseStatusList}
                    />
                </Grid>

                <Grid item xs={12} sm={6}> </Grid>


                {
                    (values.driverLicense?.status === "exist") && (
                        <>
                            <Grid item xs={12} sm={6}>
                                <FormikDatePicker
                                    name="driverLicense.issueDate"
                                    label="Veriliş Tarihi"
                                    maxDate={new Date()}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormikTextField
                                    name="driverLicense.licenseType"
                                    label="Ehliyet Sınıfı"
                                />
                            </Grid>
                        </>
                    )
                }



                <Grid item xs={12} >
                    <Stack direction="row" justifyContent="end">
                        <Button
                            onClick={() => handleSubmit()}
                            variant="contained"
                            color="primary"
                        >
                            Güncelle
                        </Button>
                    </Stack>
                </Grid>

            </Grid>
        </Card >
    )
}
