import { useFormikContext } from 'formik'
import { TextField, StandardTextFieldProps } from '@mui/material'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.core.css';

import 'react-quill/dist/quill.snow.css';


interface IFormikTextField extends StandardTextFieldProps {
    name: string,
    upperCase?: boolean
}

export const FormikQuill = ({ name = "", upperCase = false, ...rest }: IFormikTextField) => {

    const { getFieldProps, getFieldMeta } = useFormikContext<IFormikTextField | any>()

    const error = !!(getFieldMeta(name).touched && getFieldMeta(name).error)
    const helperText = !!getFieldMeta(name).touched && getFieldMeta(name).error && `${getFieldMeta(name).error}`

    return (
        <ReactQuill
            placeholder='İş Tanımı ve Genel Nitelikler'
            theme="snow"
            value={getFieldProps(name).value}
            onChange={(value: any) => {
                getFieldProps(name).onChange({
                    target: {
                        name: name,
                        value: value
                    }
                })
            }}
            style={{ height: "160px", marginBottom: "40px" }}
        />
    )
}
