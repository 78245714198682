import { ExtraProductEnums, HigherEducationDegreeEnum, ProductTypeEnums, OzgelecekFieldEnum, PlaceOfWorkEnums, WorkTypeEnums, PositionLevelEnums, LanguageEnums, ILanguage } from "types"


export interface ICompanyCreateAdvertisementResponse {
    _id: string
}


export interface ICompanyCreateAdvertisementVariables {
    productType?: ProductTypeEnums | string,
    quota?: any,
    quotaVisibleToApplicant?: boolean | null,
    startDate?: string,
    title?: string | null,
    // extraProducts?: Array<string> | ExtraProductEnums[] | string[] | null,
    extraProducts?: any,
    status?: string | null,
    advertisementId?: any,
    field?: any,
    department?: string | null,
    language?: LanguageEnums | string | null,
    place?: PlaceOfWorkEnums | string | null,
    description?: string | null,
    workType?: WorkTypeEnums | string | null,
    positionLevel?: PositionLevelEnums | string | null,
    locations?: Array<{
        city: string,
        districts: string[]
    }> | null,
    requiredSkills?: Array<string> | string[] | null,
    requiredLanguages?: Array<ILanguage>,
    requiredEducationLevel?: Array<HigherEducationDegreeEnum> | null,
    requiredDriverLicense?: string | null,
    requiredAgeInterval?: any,
    militaryServiceRequired?: boolean | null,
    companyInfoVisible?: boolean | null,
    replyNotification?: string | null,
    customReplyNotification?: {
        content?: string | null,
        subject?: string | null,
    },
    images?: Array<string> | null


    // companyInfoVisible: boolean,
    // customReplyNotification: {
    //     content: string,
    //     subject: string,
    // },
    // onboardingSecond: {
    //     city: [string] | string | any,
    //     department: string,
    //     description: string,
    //     district: string,
    //     field: OzgelecekFieldEnum | string,
    //     language: string,
    //     place: PlaceOfWorkEnums | string,
    //     positionLevel: PositionLevelEnums | string,
    //     workType: WorkTypeEnums | string
    // },
    // onboardingThird: {
    //     requiredSkills: Array<string>,
    //     requiredLanguages: Array<ILanguage>,
    //     requiredEducationLevel: Array<HigherEducationDegreeEnum | string | number>,
    //     requiredDriverLicense: string,
    //     requiredAgeInterval: number | string,
    //     militaryServiceRequired: boolean | null
    // },
    // replyNotification: string,
    // images: Array<string>
}


export const company_createAdvertisement = (variables: ICompanyCreateAdvertisementVariables) => {

    console.log('!!!!!!!')

    const data = JSON.stringify({

        query: `mutation Company_createAdvertisement($advertisementInput: AdvertisementInput!) {
            company_createAdvertisement(advertisementInput: $advertisementInput) {
              _id
            }
          }`,

        variables: {
            "advertisementInput": {
                "productType": variables?.productType ?? "",
                "quota": variables?.quota ?? null,
                "quotaVisibleToApplicant": variables?.quotaVisibleToApplicant,
                "startDate": variables?.startDate ?? "",
                "title": variables?.title ?? null,
                "extraProducts": variables?.extraProducts ?? [],
                // "status": "onboardingFirstCompleted",
                // "field": variables?.field ?? null,
                // "department": variables?.department ?? null,
                // "language": variables?.language ?? null,
                // "place": variables?.place ?? null,
                // "description": variables?.description ?? null,
                // "workType": variables?.workType ?? null,
                // "positionLevel": variables?.positionLevel ?? null,
                // "city": variables?.city ?? null,
                // "district": variables?.district ?? null,



                // "requiredSkills": variables?.onboardingThird?.requiredSkills ?? null,
                // "requiredLanguages": variables?.onboardingThird?.requiredLanguages ?? null,
                // "requiredEducationLevel": variables?.onboardingThird?.requiredEducationLevel ?? null,
                // "requiredDriverLicense": variables?.onboardingThird?.requiredDriverLicense ?? null,
                // "requiredAgeInterval": variables?.onboardingThird?.requiredAgeInterval ?? null,
                // "militaryServiceRequired": variables?.onboardingThird?.militaryServiceRequired ?? null,

                // "companyInfoVisible": variables?.companyInfoVisible ?? false,
                // "replyNotification": variables?.replyNotification ?? null,
                // "customReplyNotification": {
                //     "content": variables?.customReplyNotification?.content ?? null,
                //     "subject": variables?.customReplyNotification?.subject ?? null,
                // },
                // "images": variables?.images ?? null,
            }
        }

    })

    return data

}