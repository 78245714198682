import { Box, FormLabel, IconButton, Stack, TextField, Typography } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { compressImage } from 'utils'

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { AnimatePresence, motion } from 'framer-motion';



type ImageUploadPropType = {
    imageValue?: string | Blob | MediaSource,
    imageValues?: Array<string | Blob | MediaSource>
    onChange: (e: any) => void,
    disabled?: boolean
    error: any,
    helperText: any,
    label?: string,
    multiple?: boolean,
    compressRate?: number,
    oldImages?: Array<string> | null,
    setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void
}

export const ImageUpload: FC<ImageUploadPropType> = ({ compressRate = 1, imageValue, imageValues, multiple, label, onChange, disabled, error, helperText, oldImages, setFieldValue }) => {

    const [file, setFile] = useState<any>(null);
    const [files, setFiles] = useState<Array<any>>([]);

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0] || null;
        const compressedImage = file ? await compressImage(file, compressRate) : null;
        if (multiple) {
            compressedImage && setFiles([...files, compressedImage]);
            onChange([...files, compressedImage]); // Call onChange directly
        } else {
            compressedImage && setFile(compressedImage);
            onChange(compressedImage); // Call onChange directly
        }
    };

    const handleDelete = (index: number) => {
        const newList = files?.filter((file, i) => i !== index);
        setFiles(newList);
        onChange(newList); // Call onChange directly
    };

    const renderImage = () => {
        if (multiple) {
            return (<>Görsel seçiniz.</>);
        } else if (!!file) {
            return (
                <Box
                    component="img"
                    src={imageValue ? (typeof imageValue === "string" ? imageValue : URL?.createObjectURL(imageValue)) : ""}
                    alt=""
                    style={{
                        objectFit: 'contain',
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                    }}
                    className={`object-cover w-full h-full p-1 ${disabled && "grayscale"} rounded-xl`}
                />
            );
        } else {
            return (<>Choose a file</>);
        }
    };

    return (
        <Box>
            <Typography variant='caption'>
                {label}
            </Typography>

            <Box
                sx={{
                    border: '1px dashed #c9cfd6',
                    p: 1,
                    position: 'relative',
                    height: '100px',
                    display: 'flex',
                    '&:hover': {
                        border: '1px solid #000',
                    }
                }}
            >
                <TextField
                    disabled={!!disabled}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        opacity: 0,
                        zIndex: -10,
                    }}
                    type="file"
                    onChange={handleFileChange}
                    id="image_upload_input"
                />

                <FormLabel
                    htmlFor="image_upload_input"
                    sx={{
                        cursor: disabled ? "cursor" : "pointer",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 1,
                        backgroundColor: '#f9f9f9',
                        height: '100%',
                        width: '100%',
                        overflow: 'hidden',

                        '&:hover': {
                            backgroundColor: '#f1f1f1',
                        }
                    }}
                >
                    {renderImage()}
                </FormLabel>

            </Box>

            {multiple && (
                <Stack direction={{ xs: 'column', sm: 'row' }} my={3} spacing={3}>
                    <AnimatePresence mode="popLayout" >
                        {!!oldImages?.length && oldImages?.map((image, index: number) => (
                            <motion.div
                                layout
                                initial={{ scale: 0.8, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1 }}
                                exit={{ scale: 0.8, opacity: 0 }}
                                transition={{ type: "spring" }}
                                key={index+1000}
                                style={{ position: 'relative' }}
                            >
                                <Box
                                    component="img"
                                    src={image}
                                    alt=""
                                    sx={{
                                        objectFit: 'contain',
                                        width: { xs: '100%', sm: '100px' },
                                        height: { xs: '100%', sm: '100px' },
                                        overflow: 'hidden',
                                        boxShadow: '1px 1px 5px 0px #ccc',
                                        borderRadius: '10px',
                                    }}
                                    className={`object-cover w-full h-full p-1 ${disabled && "grayscale"} rounded-xl`}
                                />
                                <IconButton
                                    color='error'
                                    onClick={() => {
                                        setFieldValue && setFieldValue("images", oldImages?.filter((_, i) => i !== index));
                                    }}
                                    sx={{
                                        position: 'absolute',
                                        top: -10,
                                        right: -10,
                                        bgcolor: 'primary.lighter',
                                        width: 20,
                                        height: 20,
                                    }}
                                >
                                    <CloseRoundedIcon sx={{ fontSize: 15 }} color='primary' />
                                </IconButton>
                            </motion.div>
                        ))}

                        {imageValues?.map((image, index: number) => (
                            <motion.div
                                layout
                                initial={{ scale: 0.8, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1 }}
                                exit={{ scale: 0.8, opacity: 0 }}
                                transition={{ type: "spring" }}
                                key={index}
                                style={{ position: 'relative' }}
                            >
                                <Box
                                    component="img"
                                    src={typeof image === "string" ? image : URL?.createObjectURL(image)}
                                    alt=""
                                    sx={{
                                        objectFit: 'contain',
                                        width: { xs: '100%', sm: '100px' },
                                        height: { xs: '100%', sm: '100px' },
                                        overflow: 'hidden',
                                        boxShadow: '1px 1px 5px 0px #ccc',
                                        borderRadius: '10px',
                                    }}
                                    className={`object-cover w-full h-full p-1 ${disabled && "grayscale"} rounded-xl`}
                                />
                                <IconButton
                                    color='error'
                                    onClick={() => handleDelete(index)}
                                    sx={{
                                        position: 'absolute',
                                        top: -10,
                                        right: -10,
                                        bgcolor: 'primary.lighter',
                                        width: 20,
                                        height: 20,
                                    }}
                                >
                                    <CloseRoundedIcon sx={{ fontSize: 15 }} color='primary' />
                                </IconButton>
                            </motion.div>
                        ))}
                    </AnimatePresence>
                </Stack>
            )}
        </Box>
    );
};
