import * as yup from "yup"
import { Formik } from 'formik'
import { Button, Card, Grid, Typography } from '@mui/material'

import { IReference } from 'types'
import { FormikTextField } from 'components'




const validationSchema = yup.object().shape({
    approved: yup.string().nullable(),
    // reading: yup.string().nullable(),
    // writing: yup.string().nullable(),
    // listening: yup.string().nullable(),
})


const defaultValues = {
    approved: "",
    // writing: "",
    // reading: "",
    // listening: "",
}


interface IReferenceFormProps {
    onSubmit: (e: IReference) => any
}
export const ReferencesFormIndepended = ({ onSubmit }: IReferenceFormProps) => {

    // return (
    //     <Formik
    //         initialValues={defaultValues}
    //         validationSchema={validationSchema}
    //         onSubmit={(values, { resetForm }) => {
    //             onSubmit(values)
    //             resetForm()
    //         }}
    //     >
    //         {
    //             ({ handleSubmit }) => {
    //                 return (
    //                     <Card sx={{ width: '100%', p: 2 }}>
    //                         <Grid container spacing={3} >

    //                             <Grid item xs={12}>
    //                                 <Typography variant="h5">
    //                                     Referances
    //                                 </Typography>
    //                             </Grid>

    //                             <Grid item xs={12} sm={6}>
    //                                 <FormikTextField
    //                                     name="approved"
    //                                     label="approved"
    //                                 />
    //                             </Grid>

    //                             <Grid item xs={12} >
    //                                 <Button
    //                                     onClick={() => handleSubmit()}
    //                                     variant="contained"
    //                                 >
    //                                     Submit
    //                                 </Button>
    //                             </Grid>
    //                         </Grid>
    //                     </Card>
    //                 )
    //             }
    //         }
    //     </Formik>
    // )
}
