
import { useEffect, useState } from 'react'
import { useAppSelector, useGetCompanyApplicationsQuery, ICompanyApplicationsVariables, useGetCompanyApplicationStatisticsQuery, useGetCompanyAdvertisementsQuery, useGetSelfUserQuery } from 'context'
import { Box, Divider, Grid, Table, Typography, TableBody, TableCell, TableContainer, Chip, TableRow, Paper, Skeleton, Stack, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { DashboardLayout } from 'layouts'
import { TotalJobs, Interviews, HappyGif, BusinessDealGif } from 'assets'
import { LogoCrop } from 'assets';
import { useNavigate } from 'react-router-dom'
import { enumFunctions } from 'utils'
import { ApplicationStatusEnums, OzgelecekFieldEnum } from 'types'
import moment from 'moment'
import { PieChart } from '@mui/x-charts/PieChart';
import { Square } from '@mui/icons-material'
import { Iconify } from 'components'
import { routes } from 'routes'

export const CompanyDashboardPage = () => {

    const { user, device } = useAppSelector(state => state.auth)
    const navigate = useNavigate();
    const { data: statistics, isFetching } = useGetCompanyApplicationStatisticsQuery({})
    const { data: advertisements } = useGetCompanyAdvertisementsQuery({} as any)
    const { data: userPayment } = useGetSelfUserQuery({})
    const [acilisDialog, setAcilisDialog] = useState(false)

    const [variables, setVariables] = useState<ICompanyApplicationsVariables>({
        filter: {
            candidateId: null,
            customApplicationsListIds: null,
            advertisementId: null,
        },
        pagination: {
            page: 1,
            pageSize: 10,
            sort: ""
        }
    })
    const { data: applications } = useGetCompanyApplicationsQuery(variables)
    console.log(statistics, 'statistics')

    useEffect(() => {
        if (!acilisDialog && !sessionStorage.getItem("homeDialog")) {
            sessionStorage.setItem("homeDialog", "true")
            setAcilisDialog(true)
        }
    }, []);

    return (
        <DashboardLayout>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant='h1'>
                    Panel
                </Typography>
                <Typography variant='body2' border={'1px solid #ddd'} p={1} sx={{ cursor: 'pointer' }}
                    onClick={() => device !== "mobile" ? navigate(routes.company?.dashboard?.credits) : null}>
                    <strong> Özgelecek Kredisi: {userPayment?.credit?.credit?.total}</strong><Iconify icon={"ph:coins-duotone"} sx={{ ml: 1, mb: -0.5, color: 'primary' }} />
                </Typography>
            </Stack>
            <Divider sx={{ my: 2 }} />
            <Typography variant='h3'>
                Merhaba {user?.name},
            </Typography>
            <Typography variant='body2' mt={1}>
                Bu sayfada özgeleceklerin ile başvurduğun ilanların özetini görütüleyebilirsiniz.
            </Typography>

            <Grid container spacing={2} mt={2} >

                <Grid item xs={12} md={6}   >
                    <Box
                        onClick={() => navigate('my-advertisements')}
                        sx={{
                            p: 2, border: '2px solid #ddd', backgroundImage: `url(${TotalJobs})`, backgroundSize: "30%",
                            backgroundRepeat: "no-repeat", borderRadius: 2, backgroundPosition: "right 10px bottom 0px",
                            cursor: 'pointer'
                        }}>
                        <Typography variant='h3'>
                            Toplam İlan Sayısı
                        </Typography>
                        <Typography fontSize={"48px"} fontWeight={700} mt={1}>
                            {advertisements?.count || 0}
                        </Typography>
                    </Box>
                    <Box
                        onClick={() => navigate('basvurular')}
                        sx={{
                            p: 2, border: '2px solid #ddd', borderRadius: 2, backgroundImage: `url(${Interviews})`, backgroundSize: "30%",
                            backgroundRepeat: "no-repeat", backgroundPosition: "right 10px bottom 0px", mt: 2, cursor: 'pointer'
                        }}>
                        <Typography variant='h3'>
                            Toplam Başvuru Sayısı
                        </Typography>
                        {!isFetching ? <Typography fontSize={"48px"} fontWeight={700} mt={1}>
                            {Number(statistics?.pendingAsBlacklistedCount) + Number(statistics?.pendingCount) + Number(statistics?.suitableCount) + Number(statistics?.unsuitableCount) + Number(statistics?.viewedCount)}
                        </Typography> : <Skeleton variant="text" width={100} height={80} />}
                    </Box>
                </Grid>

                <Grid item xs={12} md={6}   >
                    <Box sx={{ height: '100%', border: '2px solid #ddd', borderRadius: 2, p: 2 }}>
                        <Typography variant='h3' mb={2} textAlign={"center"}>
                            Başvuru Grafiği
                        </Typography>
                        <Box
                            marginLeft={{ xs: "auto", md: 0 }}
                            marginRight={{ xs: "auto", md: 0 }}
                        >
                            {/* <PieChart
                                height={250}
                                sx={{ ml: 0 }}
                                legend={{
                                    position: 'bottom' as any,
                                    direction: 'row' as any,
                                    hidden: true
                                }}
                                series={[
                                    {
                                        data: [
                                            { id: 1, value: Number(statistics?.suitableCount) || 1, label: 'Uygun Bulundu' },
                                            { id: 2, value: Number(statistics?.viewedCount) || 2, label: 'Görüntülendi' },
                                            { id: 3, value: Number(statistics?.pendingCount) || 1, label: 'İnceleniyor' },
                                            { id: 4, value: Number(statistics?.unsuitableCount) || 1, label: 'Uygun Bulunmadı' }
                                        ],
                                        innerRadius: 30,
                                        outerRadius: 100,
                                        paddingAngle: 5,
                                        cornerRadius: 5,
                                        endAngle: 300,
                                    }
                                ]}
                            /> */}
                            <PieChart
                                series={[
                                    {
                                        data: (statistics?.suitableCount || statistics?.viewedCount || statistics?.pendingCount || statistics?.unsuitableCount) ? [
                                            { id: 1, value: Number(statistics?.suitableCount) || 0, color: '#5344D2' },
                                            { id: 2, value: Number(statistics?.viewedCount) || 0, color: '#1C1579' },
                                            { id: 3, value: Number(statistics?.pendingCount) || 0, color: '#DFDAFC' },
                                            { id: 4, value: Number(statistics?.unsuitableCount) || 0, color: '#DDD' },
                                        ] : [
                                            { id: 1, value: 0, color: '#5344D2' },
                                            { id: 2, value: 1, color: '#1C1579' },
                                            { id: 3, value: 0, color: '#DFDAFC' },
                                            { id: 4, value: 0, color: '#DDD' },
                                        ],
                                        highlightScope: { faded: 'global', highlighted: 'item' },
                                        faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                    },
                                ]}
                                height={180}
                                sx={{
                                    width: '100%',
                                    ml: 10
                                }}
                            />
                            <Stack direction="row" justifyContent="center" mt={2} spacing={1}>
                                <Box sx={{ display: 'flex', alignItems: 'center', width: 160 }}>
                                    <Square sx={{ color: '#5344D2' }} />
                                    <Typography fontWeight={"bold"} fontSize={"small"}>
                                        Uygun Bulundu ({statistics?.suitableCount})
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', width: 160 }}>
                                    <Square sx={{ color: '#DFDAFC' }} />
                                    <Typography fontWeight={"bold"} fontSize={"small"}>
                                        İnceleniyor ({statistics?.pendingCount})
                                    </Typography>
                                </Box>
                            </Stack>
                            <Stack direction="row" justifyContent="center" mt={1} spacing={1}>

                                <Box sx={{ display: 'flex', alignItems: 'center', width: 160 }}>
                                    <Square sx={{ color: '#DDD' }} />
                                    <Typography fontWeight={"bold"} fontSize={"small"}>
                                        Uygun Bulunmadı ({statistics?.unsuitableCount})
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', width: 160 }}>
                                    <Square sx={{ color: '#1C1579' }} />
                                    <Typography fontWeight={"bold"} fontSize={"small"}>
                                        Görüntülendi ({statistics?.viewedCount})
                                    </Typography>
                                </Box>
                            </Stack>

                        </Box>
                    </Box>

                </Grid>

                <Grid item xs={12} md={12}>
                    <Box sx={{ p: 2, border: '2px solid #ddd', borderRadius: 2, width: '100%', height: '100%' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant='h3' mb={2}>
                                Son Başvurular
                            </Typography>
                            {device !== "mobile" && <Typography fontWeight={'bold'} mb={2} color="primary" sx={{ cursor: 'pointer' }} onClick={() => navigate('/basvurular')}>
                                Tümünü Gör
                            </Typography>}
                        </Box>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableBody>
                                    {applications?.data?.length > 0 ? applications?.data?.map((row: any) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" align='left'>
                                                <Box sx={{ display: 'flex', gap: 2 }}>
                                                    <Box
                                                        component="img"
                                                        src={LogoCrop}
                                                        sx={{ width: 30, height: 30, borderRadius: 2 }}
                                                    />
                                                    <Typography variant="body2">
                                                        {row.advertisement?.title}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="body2">
                                                    {enumFunctions.findAndDisplayName(OzgelecekFieldEnum, row?.advertisement?.field)}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Chip
                                                    label={enumFunctions.findAndDisplayName(ApplicationStatusEnums, row?.status)}
                                                    size='small'
                                                    sx={{ width: 130 }}
                                                    variant="outlined"
                                                    color={
                                                        row?.status === "pending" ? "warning"
                                                            : row?.status === "pendingAsBlacklisted" ? "default"
                                                                : row?.status === "suitable" ? "primary"
                                                                    : row?.status === "unsuitable" ? "error"
                                                                        : row?.status === "viewed" ? "info"
                                                                            : "info"
                                                    }
                                                    style={{ borderRadius: 100 }}
                                                /></TableCell>
                                            <TableCell align="left">{moment(row?.createdAt).format('DD MMMM YYYY')}</TableCell>
                                        </TableRow>
                                    )) :
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell colSpan={4} align={device !== "mobile" ? 'center' : 'left'}>
                                                <Typography variant="body2">
                                                    Henüz başvurunuz bulunmamaktadır.
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>

                </Grid>


            </Grid>

            <Dialog
                open={acilisDialog}
                onClose={() => setAcilisDialog(false)}
            >
                <DialogTitle style={{ textAlign: "center" }}>
                    Görüşleriniz bizim için çok değerli!
                </DialogTitle>
                <DialogContent sx={{
                    display: "flex", flexDirection: "column", alignItems: "center",
                    width: { xs: '100%', sm: '500px' },
                }}>
                    <img src={BusinessDealGif} alt="Welcome" style={{ width: "250px", height: "auto" }} />
                    <Typography variant="body1" textAlign={"center"}>
                        Sistemimizi geliştirmek ve çok daha iyi çözümler sunabilmek amacıyla lütfen gördüğünüz eksiklikleri veya talep ettiğiniz yenilikleri bize <a href="mailto:bilgi@ozgelecek.net" style={{ color: 'black' }}>bilgi@ozgelecek.net</a> adresinden bildirin!<br /><br />
                        Size ve diğer tüm iş ortaklarımıza en iyi işe alım sürecini yaşatmamız için destekçi olun!

                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            setAcilisDialog(false)
                        }}
                    >
                        Tamam
                    </Button>
                </DialogActions>
            </Dialog>

        </DashboardLayout >
    )
}
