import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography, Pagination, Skeleton } from '@mui/material'

import { routes } from 'routes'
import { DashboardLayout, DashboardLayoutMobile } from 'layouts'
import { CompanyAdvertisementCard, MuiTransition } from 'components'
import { ICompanyAdvertisementsVariables, useGetCompanyAdvertisementsQuery, useGetCompanyInformationQuery, setAdvertisementFormIndex, useAppDispatch } from 'context'
import { Iconify } from 'components'


export const CompanyAdvertisementsPageMobile = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const [selectedAdvertisement, setSelectedAdvertisement] = useState<any>(null)
    const [variables, setVariables] = useState<ICompanyAdvertisementsVariables>({
        filter: {
            filter: {
                cities: []
            },
            search: {
                all: ""
            }
        },
        pagination: {
            page: 1,
            pageSize: 5
        }
    })
    const { data: advertisements, isFetching } = useGetCompanyAdvertisementsQuery(variables)
    const { data: company } = useGetCompanyInformationQuery("")


    // useEffect(() => {
    //     dispatch(setAdvertisementFormIndex(0));
    // }, [dispatch])


    return (
        <DashboardLayoutMobile>

            <Stack direction="row" justifyContent="space-between">
                <Typography variant='h1'>
                    İlanlarım
                </Typography>

                {/* <Button
                    variant='contained'
                    color="primary"
                    onClick={() => navigate(routes.company.dashboard.createAdvertisementMobile)}
                >
                    İlan Oluştur
                </Button> */}
            </Stack>

            <Box
                sx={{
                    pb: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                }}
            >

                <Box bgcolor={"secondary.lighter"} p={2} mt={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                    {/* <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}>
                        <Iconify icon={"material-symbols:info-outline"} />
                    </Box> */}
                    <Typography variant="body2" >
                        İlanlarım sayfası üzerinden oluşturduğunuz ilanları listeleyebilir, yeni ilan ekleyebilir, güncelleyebilir ve silebilirsiniz.
                    </Typography>
                </Box>
                {
                    isFetching ? <Stack spacing={2} >
                        {Array.from({ length: variables?.pagination?.pageSize ?? 5 }).map((_, index) => (
                            <Skeleton
                                key={index}
                                variant='rectangular'
                                sx={{
                                    p: 2,
                                    width: '100%',
                                    height: '100px'
                                }}
                            />
                        ))
                        }

                    </Stack> : (
                        advertisements?.count ? <Stack flexDirection="column" spacing={2} alignItems={"center"} sx={{ width: '100%' }}>
                            {advertisements?.data?.map((advertisement: any, index: number) => {
                                return (
                                    <CompanyAdvertisementCard
                                        key={index}
                                        logo={company?.companyInformation?.profileImage ?? ""}
                                        advertisement={advertisement}
                                        onShow={() => {
                                            navigate(`${routes.company.dashboard.advertisementDetailsMobile}/${advertisement?._id}`)
                                        }}
                                        onEdit={() => {
                                            navigate(`${routes.company.dashboard.updateAdvertisementMobile}/${advertisement?._id}`)
                                        }}
                                    />
                                )
                            })}
                            {<Pagination
                                color="primary"
                                count={Math.ceil(advertisements?.count / (variables?.pagination?.pageSize ?? 1))}
                                page={variables?.pagination?.page ?? 1}
                                onChange={(e, page) => setVariables({ ...variables, pagination: { ...variables?.pagination, page } })}
                            />
                            }
                        </Stack> : (
                            <>
                                Hiçbir ilan bulunamadı.
                            </>
                        )
                    )

                }

            </Box>


            {/* <Dialog
                open={!!selectedAdvertisement}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setSelectedAdvertisement(null)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Advertisement Detail</DialogTitle>

                <DialogContent>


                    {
                        JSON.stringify(selectedAdvertisement)
                    }

                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setSelectedAdvertisement(null)}>
                        Close
                    </Button>

                    <Button onClick={() => navigate(routes.company.dashboard.updateAdvertisementMobile + "/" + selectedAdvertisement?._id)}>
                        update
                    </Button>
                </DialogActions>
            </Dialog> */}

        </DashboardLayoutMobile>
    )
}
