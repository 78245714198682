import { CircularProgress, Stack, Typography } from '@mui/material'

import { useAppDispatch, useAppSelector, logoutReducer } from 'context'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'


declare global {
    interface Window {
        ReactNativeWebView: any;
    }
}


export const LogoutPage = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        try {
            dispatch(logoutReducer({}))
            // window.ReactNativeWebView.postMessage("logout")
        } catch (error) {
            console.log(error)
        }

    }, [])

    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            height="100vh"
            gap={3}
        >

            <CircularProgress />

            <Typography variant="h3" textAlign={"center"}>Çıkış yapılıyor...</Typography>

        </Stack>
    )
}
