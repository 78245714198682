import { Close, Delete, Logout } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { Avatar, Box, Button, Card, Container, Dialog, DialogContent, Divider, Grid, IconButton, Stack, Typography, useMediaQuery } from '@mui/material'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import { FormikAutoComplete, FormikAutoComplete2, FormikAutoComplete4, FormikDatePicker, FormikSelect, FormikTextField, Iconify, MuiTransition } from 'components'
import { IAnonymousCreateConversionRequestFormVariables, IAnonymousCreateDiscoverFormVariables, useAnonymous_createConversionRequestFormMutation, useAnonymous_createDiscoverFormMutation, useGetCitiesQuery } from 'context'
import { LoadingButton } from '@mui/lab'

import { enqueueSnackbar } from 'notistack'
import { enumFunctions } from 'utils'
// import { HrDanismanlik, OzgelecekMain, Pattern, SearchAnimation } from 'assets'
import Lottie from 'lottie-react'
// import Slider from 'react-slick'

import { motion, AnimatePresence } from "framer-motion"
import { Key, useEffect, useState } from 'react'
import { DashboardLayout, DashboardLayoutMobile } from 'layouts'
import { AddNewItem, CandidateUpdateCandidateDialogs, MoreMenu } from 'pages'
import { ILanguage, LanguageLevelEnum } from 'types'
import { LanguageForm, ProgrammingLanguageForm } from 'sections'
// import { useAnonymous_createConversionRequestFormMutation } from 'context'


type FormDialog = {
    formType: "language" | "foreignLanguage" | "programmingLanguage",
    // formType: "header" | "about" | "personalInformation" | "additionalInformation" | "certificate" | "language" | "participatedProject" | "scholarship" | "programmingLanguage" | "higherEducation",
    formValue?: any,
    itemIndex?: number
}

export const CompanyConversionCreatePage = () => {

    const [createDiscover, { isLoading: createDiscoverLoading }] = useAnonymous_createDiscoverFormMutation()
    const [formDialog, setFormDialog] = useState<null | FormDialog>(null)
    const [loading, setLoading] = useState(false)

    const initialvalues: any = {
        position: "",
        workingType: "",
        estimatedStartDate: "",
        gender: "",
        ageInterval: "",
        numberOfEmployee: "",
        shiftNumber: "",
        disabledStatus: "",
        workDescription: "",
        minWage: "",
        maxWage: "",
        premium: "",
        bonus: "",
        travelExpenses: "",
        mealExpenses: "",
        education: "",
        foreignLanguage: [],
        workerMeetingDate: "",
        serviceRoute: "",
        disabledDesc: "",
        premiumDesc: "",
        bonusDesc: "",
        travelExpensesDesc: "",
        mealExpensesDesc: "",
        computerKnowledgeLevel: "",
        programmingLanguages: [],
    }

    const validationSchema = yup.object().shape({
        position: yup.string().required("Pozisyon adı zorunludur."),
        workingType: yup.string().required("Çalışma şekli zorunludur."),
        estimatedStartDate: yup.date().required("Tahmini başlangıç tarihi zorunludur."),
        gender: yup.string().required("Cinsiyet zorunludur."),
        ageInterval: yup.string().required("Yaş aralığı zorunludur."),
        numberOfEmployee: yup.string().required("İstihdam sayısı zorunludur."),
        shiftNumber: yup.string().required("Vardiya sistemi zorunludur."),
        disabledStatus: yup.string().required("Engellilik durumu zorunludur."),
        workDescription: yup.string().required("İş görev tanımı zorunludur."),
        minWage: yup.string().required("Minimum maaş zorunludur."),
        maxWage: yup.string().required("Maksimum maaş zorunludur."),
        premium: yup.string().required("Prim zorunludur."),
        bonus: yup.string().required("İkramiye zorunludur."),
        travelExpenses: yup.string().required("Ulaşım zorunludur."),
        mealExpenses: yup.string().required("Yemek zorunludur."),
        education: yup.array().required("Eğitim zorunludur."),
        foreignLanguage: yup.array().required("Yabancı dil zorunludur."),
        workerMeetingDate: yup.date().required("Görüşme tarihi zorunludur."),
        serviceRoute: yup.string().required("Servis güzergahı zorunludur."),
        disabledDesc: yup.string(),
        premiumDesc: yup.string(),
        bonusDesc: yup.string(),
        travelExpensesDesc: yup.string(),
        mealExpensesDesc: yup.string(),
        computerKnowledgeLevel: yup.string().required("Bilgisayar bilgisi zorunludur."),
        programmingLanguages: yup.array(),

    })
    const handleCreateConversion = async (values: any, resetForm: any) => {
        console.log(values)
        try {
            const response: any = await createDiscover({ ...values, education: values.education?.join(", ") })
            console.log({ response });

            if (response?.data) {
                enqueueSnackbar("Keşif talebiniz başarıyla oluşturuldu.")
                resetForm()
            } else {
                enqueueSnackbar("Oluşturulamadı.", { variant: 'warning' })
            }

        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti.", { variant: 'error' })
        }
    }



    return (
        <DashboardLayout>

            <Typography mb={3} variant='h1'>

                Özgelecek Keşif
            </Typography>

            <Box bgcolor={"secondary.lighter"} p={2} mt={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}>
                    <Iconify icon={"material-symbols:info-outline"} />
                </Box>
                <Typography variant="body2" >
                    Özgelecek, “Keşif” danışmanlığıyla işe alım operasyonlarınızı delege edebileceğiniz bir hizmeti beraberinde sunar! <br /><br />
                    Aşağıdaki formu doldurarak “Nasıl?” bir çalışan aradığınızı bize bildirin ve Keşif Danışmanlarımız sizler adına “Özgelecek vizyonuyla” aradığınız çalışanı bulsun. Özgelecek Keşif ile işe alım sürecinizdeki tüm operasyonları delege etmiş olarak; mülakatları yapılmış, görüşme notları oluşturmuş, uygun olduğunuz takvimde görüşmeniz organize edilerek süreciniz asiste edilmiş, özetle: “İşe Alıma Hazır” adaylara kavuşun!
                </Typography>
            </Box>

            <Card sx={{ p: 3, mt: 3 }}>

                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialvalues}
                    onSubmit={(values, { resetForm }) => {
                        handleCreateConversion(values, resetForm)
                    }}
                >
                    {
                        ({ handleSubmit, errors, values, setFieldValue }) => {
                            return (
                                <Stack spacing={3} >
                                    <CreateConversionFormContainer values={values} setFieldValue={setFieldValue} formDialog={formDialog}
                                        setFormDialog={setFormDialog} />
                                    <Stack direction="row" justifyContent="end">
                                        <LoadingButton
                                            color='primary'
                                            variant='contained'
                                            loading={createDiscoverLoading}
                                            disabled={createDiscoverLoading}
                                            onClick={() => handleSubmit()}
                                        >
                                            Oluştur
                                        </LoadingButton>
                                    </Stack>
                                </Stack>
                            )
                        }
                    }
                </Formik>
            </Card>
        </DashboardLayout>
    )
}



const CreateConversionFormContainer = ({ values, setFieldValue, formDialog, setFormDialog }: any) => {



    return (
        <Grid container spacing={3}>

            <Grid item xs={12} lg={6}>
                <FormikTextField
                    name="position"
                    label="Pozisyon Adı*"
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormikSelect
                    name="workingType"
                    label="Çalışma Şekli*"
                    list={[
                        { name: "Tam Zamanlı", value: "fullTime" },
                        { name: "Yarı Zamanlı", value: "partTime" },
                        { name: "Stajyer", value: "intern" },
                    ]}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormikDatePicker
                    name="estimatedStartDate"
                    label="Tahmini Başlangıç Tarihi*"
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormikSelect
                    name="gender"
                    label="Cinsiyet"
                    list={[
                        { name: "Erkek", value: "erkek" },
                        { name: "Kadın", value: "kadın" },
                        { name: "Fark etmez", value: "farketmez" },
                    ]}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormikSelect
                    name="ageInterval"
                    label="Yaş Aralığı*"
                    list={[
                        { name: "18-25", value: "18-25" },
                        { name: "25-35", value: "25-35" },
                        { name: "35-45", value: "35-45" },
                        { name: "45-55", value: "45-55" },
                        { name: "55+", value: "55+" },
                    ]}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormikTextField
                    name="numberOfEmployee"
                    label="İstihdam Sayısı*"
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormikSelect
                    name="shiftNumber"
                    label="Vardiya Sistemi*"
                    list={[
                        { name: "Tek Vardiya", value: "tek" },
                        { name: "Çift Vardiya", value: "cift" },
                        { name: "Üç Vardiya", value: "uc" },
                    ]}
                />
            </Grid>
            <Grid item xs={12} lg={values?.disabledStatus === "olabilir" ? 2 : 6}>
                <FormikSelect
                    name="disabledStatus"
                    label="Adayın Engellilik Durumu*"
                    list={[
                        { name: "Olabilir", value: "olabilir" },
                        { name: "Olmasın", value: "olmasin" },
                    ]}
                />
            </Grid>
            {values?.disabledStatus === "olabilir" && <Grid item xs={12} lg={4}>
                <FormikTextField
                    name="disabledDesc"
                    label="İşin izin verdiği engellilik durumundan bahsediniz"
                />
            </Grid>}
            <Grid item xs={12} lg={6}>
                <FormikTextField
                    name="workDescription"
                    label="İş Tanımı ve Genel Nitelikler*"
                />
            </Grid>
            {/* <Grid item xs={12} lg={6}>
                <FormikTextField
                    name="desiredFeatures"
                    label="Aranılan Özellikler*"
                />
            </Grid> */}
            <Grid item xs={6} lg={3}>
                <FormikTextField
                    name="minWage"
                    label="Maaş - Minimum*"
                />
            </Grid>
            <Grid item xs={6} lg={3}>
                <FormikTextField
                    name="maxWage"
                    label="Maaş - Maksimum*"
                />
            </Grid>
            <Grid item xs={12} lg={values?.premium === "var" ? 2 : 6}>
                <FormikSelect
                    name="premium"
                    label="Prim*"
                    list={[
                        { name: "Var", value: "var" },
                        { name: "Yok", value: "yok" },
                    ]}
                />
            </Grid>
            {
                values?.premium === "var" && <Grid item xs={12} lg={4}>
                    <FormikTextField
                        name="premiumDesc"
                        label="Prim Açıklaması"
                    />
                </Grid>
            }
            <Grid item xs={12} lg={values?.bonus === "var" ? 2 : 6}>
                <FormikSelect
                    name="bonus"
                    label="İkramiye*"
                    list={[
                        { name: "Var", value: "var" },
                        { name: "Yok", value: "yok" },
                    ]}
                />
            </Grid>
            {
                values?.bonus === "var" && <Grid item xs={12} lg={4}>
                    <FormikTextField
                        name="bonusDesc"
                        label="İkramiye Açıklaması"
                    />
                </Grid>
            }
            <Grid item xs={12} lg={values?.travelExpenses === "karsilaniyor" ? 2 : 6}>
                <FormikSelect
                    name="travelExpenses"
                    label="Ulaşım*"
                    list={[
                        { name: "Karşılanıyor", value: "karsilaniyor" },
                        { name: "Karşılanmıyor", value: "karsilanmiyor" },
                    ]}
                />
            </Grid>
            {
                values?.travelExpenses === "karsilaniyor" && <Grid item xs={12} lg={4}>
                    <FormikTextField
                        name="travelExpensesDesc"
                        label="Ulaşım Açıklaması"
                    />
                </Grid>
            }
            <Grid item xs={12} lg={values?.mealExpenses === "karsilaniyor" ? 2 : 6}>
                <FormikSelect
                    name="mealExpenses"
                    label="Yemek*"
                    list={[
                        { name: "Karşılanıyor", value: "karsilaniyor" },
                        { name: "Karşılanmıyor", value: "karsilanmiyor" },
                    ]}
                />
            </Grid>
            {
                values?.mealExpenses === "karsilaniyor" && <Grid item xs={12} lg={4}>
                    <FormikTextField
                        name="mealExpensesDesc"
                        label="Yemek Açıklaması"
                    />
                </Grid>
            }
            <Grid item xs={12} lg={6}>
                <FormikAutoComplete4
                    name="education"
                    label="Eğitim (Mezuniyet Derecesi)*"
                    list={[
                        { name: "Lise", value: "lise" },
                        { name: "Ön Lisans", value: "onlisans" },
                        { name: "Lisans", value: "lisans" },
                        { name: "Yüksek Lisans", value: "yukseklisans" },
                        { name: "Doktora", value: "doktora" },
                    ]}
                />
            </Grid>
            <Grid item xs={12} lg={values?.foreignLanguage.length > 0 ? 2 : 6}>
                <Box onClick={() => setFormDialog({ formType: "foreignLanguage" })}>
                    <FormikTextField
                        name=" "
                        label="Yabancı Dil*"
                    />
                </Box>
            </Grid>
            {values?.foreignLanguage?.length > 0 && <Grid item xs={12} lg={4} sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>

                {
                    values?.foreignLanguage?.map((language: ILanguage, index: Key | null | undefined) => {

                        return (
                            <Box mr={1} mb={1}>
                                <Button
                                    key={index}
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                        values.foreignLanguage.splice(index, 1)
                                        setFieldValue("foreignLanguage", values.foreignLanguage)
                                    }}
                                >
                                    {language.name} <Close sx={{ ml: 1 }} />
                                </Button>
                            </Box>
                        )

                    }
                    )
                }
            </Grid>
            }
            <Grid item xs={12} lg={6}>
                <FormikDatePicker
                    name="workerMeetingDate"
                    label="Görüşme Tarihi*"
                    minDate={new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormikTextField
                    name="serviceRoute"
                    label="Servis Güzergahı*"
                />
            </Grid>
            <Grid item xs={12} lg={(values?.computerKnowledgeLevel === "beginner" || values?.computerKnowledgeLevel === "intermediate" || values?.computerKnowledgeLevel === "advance") ? 2 : 6}>
                <FormikSelect
                    name="computerKnowledgeLevel"
                    label="Bilgisayar Bilgisi*"
                    list={[
                        { name: "Yok", value: "none" },
                        { name: "Başlangıç", value: "beginner" },
                        { name: "Orta", value: "intermediate" },
                        { name: "İleri", value: "advance" },
                    ]}
                />
            </Grid>
            {(values?.computerKnowledgeLevel === "beginner" || values?.computerKnowledgeLevel === "intermediate" || values?.computerKnowledgeLevel === "advance") && <Grid item xs={12} lg={2}>
                <Box onClick={() => setFormDialog({ formType: "programmingLanguage" })}>
                    <FormikTextField
                        name=" "
                        label="Programlama Dili"
                    />
                </Box>
            </Grid>}
            {(values?.computerKnowledgeLevel === "beginner" || values?.computerKnowledgeLevel === "intermediate" || values?.computerKnowledgeLevel === "advance") && <Grid item xs={12} lg={6} sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                {values?.programmingLanguages?.map((language: ILanguage, index: Key | null | undefined) => {

                    return (
                        <Box mr={1} mb={1}>
                            <Button
                                key={index}
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    values.programmingLanguages.splice(index, 1)
                                    setFieldValue("programmingLanguages", values.programmingLanguages)
                                }}
                            >
                                {language.name} <Close sx={{ ml: 1 }} />
                            </Button>
                        </Box>
                    )

                }
                )
                }
            </Grid>
            }








            <Dialog
                open={!!formDialog}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setFormDialog(null)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent sx={{ p: 0 }}>

                    {/* {
                        formDialog?.formType === "certificate" && (
                            <CertificateForm
                                initialValues={formDialog.formValue}
                                onSubmit={(certificate) => {
                                    if (formDialog.itemIndex || formDialog.itemIndex === 0) {
                                        values.certificates[formDialog.itemIndex] = certificate
                                        setFieldValue("certificates", [...values.certificates])
                                    } else {
                                        setFieldValue("certificates", [...values.certificates, certificate])
                                    }
                                    setFormDialog(null)
                                }}
                            />
                        )
                    } */}


                    {
                        formDialog?.formType === "foreignLanguage" && (
                            <LanguageForm
                                initialValues={formDialog.formValue}
                                onSubmit={(language) => {
                                    if (formDialog.itemIndex || formDialog.itemIndex === 0) {
                                        values.foreignLanguage[formDialog.itemIndex] = language
                                        setFieldValue("foreignLanguage", [...values.foreignLanguage])
                                    } else {
                                        setFieldValue("foreignLanguage", [...values.foreignLanguage, language])
                                    }
                                    setFormDialog(null)
                                }}
                            />
                        )
                    }


                    {/* {
                        formDialog?.formType === "participatedProject" && (
                            <ParticipatedProjectForm
                                initialValues={formDialog.formValue}
                                onSubmit={(participatedProject) => {
                                    if (formDialog.itemIndex || formDialog.itemIndex === 0) {
                                        values.participatedProjects[formDialog.itemIndex] = participatedProject
                                        setFieldValue("participatedProjects", [...values.participatedProjects])
                                    } else {
                                        setFieldValue("participatedProjects", [...values.participatedProjects, participatedProject])
                                    }
                                    setFormDialog(null)
                                }}
                            />
                        )
                    } */}


                    {/* {
                        formDialog?.formType === "scholarship" && (
                            <ScholarshipsForm
                                initialValues={formDialog.formValue}
                                onSubmit={(scholarship) => {
                                    if (formDialog.itemIndex || formDialog.itemIndex === 0) {
                                        values.scholarships[formDialog.itemIndex] = scholarship
                                        setFieldValue("scholarships", [...values.scholarships])
                                    } else {
                                        setFieldValue("scholarships", [...values.scholarships, scholarship])
                                    }
                                    setFormDialog(null)
                                }}
                            />
                        )
                    } */}


                    {
                        formDialog?.formType === "programmingLanguage" && (
                            <Card sx={{ p: 4 }}>
                                <ProgrammingLanguageForm
                                    onSubmit={(programmingLanguage) => {
                                        if (formDialog.itemIndex || formDialog.itemIndex === 0) {
                                            values.programmingLanguages[formDialog.itemIndex] = programmingLanguage
                                            setFieldValue("programmingLanguages", [...values.programmingLanguages])
                                        } else {
                                            setFieldValue("programmingLanguages", [...values.programmingLanguages, programmingLanguage])
                                        }
                                        setFormDialog(null)
                                    }} onDeleteItem={function (e?: any): void {
                                        throw new Error('Function not implemented.')
                                    }} list={[]}
                                />
                            </Card>
                        )
                    }


                    {/* {
                        formDialog?.formType === "higherEducation" && (
                            <HigherEducationForm
                                initialValues={formDialog.formValue}
                                onSubmit={(higherEducation) => {
                                    if (formDialog.itemIndex || formDialog.itemIndex === 0) {
                                        values.education.higherEducation[formDialog.itemIndex] = higherEducation
                                        setFieldValue("education.higherEducation", [...values.education.higherEducation])
                                    } else {
                                        setFieldValue("education.higherEducation", [...values.education.higherEducation, higherEducation])
                                    }
                                    setFormDialog(null)
                                }}
                            />
                        )
                    } */}


                </DialogContent>
            </Dialog>

        </Grid>
    )
}