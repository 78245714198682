

export interface ICompanyLogoImagePresignedUrlResponse {
  logoImage: {
    url: string
    presignedUploadUrl: string
    presignedThumbnailUpload: string
  }
}


export interface ICompanyLogoImagePresignedUrlVariables {
  // questionIndex: string
  organizationId: string,
  contentType: string
}



export const companyLogoImagePresignedUrl = (variables: ICompanyLogoImagePresignedUrlVariables) => {

  const data = JSON.stringify({
    query: `
        query OrganizationPresignedUrls($organizationId: ID!, $contentType: ImageContentType!) {
            organizationPresignedUrls(organizationId: $organizationId, contentType: $contentType) {
         
              logoImage {
                url
                presignedUploadUrl
                presignedThumbnailUpload
              }
            }
          }
        `,
    variables: {
      "organizationId": variables?.organizationId ?? null,
      "contentType": variables?.contentType ?? null,
    }
  })

  return data
}