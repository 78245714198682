import { Button, Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';

import { routes } from 'routes';
import { Iconify } from 'components';
import { PasswordIcon } from 'assets';
import { AuthLayout } from 'layouts'
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';



export const RegisterResendRequest = () => {

    const searchParams = useSearchParams()[0];
    const email = searchParams.get("email");
    const navigate = useNavigate()



    const handleResendCode = async () => {
        try {
    
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_URL}/auth/auth/resendRegistrationConfirmation`,
            headers: {
              'Content-Type': 'application/json'
            },
            data: JSON.stringify({ email })
          };
    
          const response = await axios.request(config)
    
          console.log(response.data)
          if (response?.data?.isSuccess) {
            enqueueSnackbar('Email başarılı bir şekilde gönderilmiştir.');
            navigate(routes.auth.registerSuccessfull)
          } else {
            enqueueSnackbar('Birşeyler yanlış gitti!', { variant: "error" })
          }
    
        } catch (error: any) {
          console.log({ error });
          enqueueSnackbar('Birşeyler yanlış gitti!', { variant: "error" })
        }
      }

    const renderHead = (
        <>
            <PasswordIcon sx={{ height: 96 }} />

            <Stack spacing={1} sx={{ my: 5, textAlign: "center" }} alignItems="center">
                <Typography variant="h3">Hesabınızı doğrulamanız gerekiyor.</Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary', my:5 }}>
                    Emailinize gelen linki tıklayarak hesabınızı doğrulayabilirsiniz. Eğer emailinizi almadıysanız, lütfen aşağıdaki butona tıklayarak tekrar gönderilmesini isteyin.
                </Typography>

                <Button variant="contained" color='primary' size="large" sx={{ width: 200 }} onClick={handleResendCode}>
                    Tekrar Gönder
                </Button>
            </Stack>


            <Link
                component={RouterLink}
                to={routes.auth.login}
                color="inherit"
                variant="subtitle2"
                sx={{
                    alignItems: 'center',
                    display: 'inline-flex',
                    mx: 'auto'
                }}
            >
                <Iconify icon="eva:arrow-ios-back-fill" width={16} />
                Geri dön
            </Link>
        </>
    );

    return (
        <AuthLayout>
            {renderHead}
        </AuthLayout>
    )
}
