import { Logout } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { Avatar, Box, Button, Card, Container, Divider, Grid, IconButton, Stack, Typography, useMediaQuery } from '@mui/material'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import { FormikDatePicker, FormikSelect, FormikTextField, Iconify } from 'components'
import { IAnonymousCreateConversionRequestFormVariables, IAnonymousCreateDiscoverFormVariables, useAnonymous_createConversionRequestFormMutation, useAnonymous_createDiscoverFormMutation, useGetCitiesQuery } from 'context'
import { LoadingButton } from '@mui/lab'

import { enqueueSnackbar } from 'notistack'
import { enumFunctions } from 'utils'
// import { HrDanismanlik, OzgelecekMain, Pattern, SearchAnimation } from 'assets'
import Lottie from 'lottie-react'
// import Slider from 'react-slick'

import { motion, AnimatePresence } from "framer-motion"
import { useState } from 'react'
import { DashboardLayout, DashboardLayoutMobile } from 'layouts'
// import { useAnonymous_createConversionRequestFormMutation } from 'context'



export const CompanyConversionCreatePageMobile = () => {

    const [createDiscover, { isLoading: createDiscoverLoading }] = useAnonymous_createDiscoverFormMutation()


    const initialvalues: any = {
        position: "",
        workingType: "",
        estimatedStartDate: "",
        gender: "",
        ageInterval: "",
        numberOfEmployee: "",
        shiftNumber: "",
        disabledStatus: "",
        workDescription: "",
        desiredFeatures: "",
        wage: "",
        premium: "",
        bonus: "",
        travelExpenses: "",
        mealExpenses: "",
        education: "",
        foreignLanguage: "",
        workerMeetingDate: "",
        serviceRoute: "",
    }

    const validationSchema = yup.object().shape({
        position: yup.string().required("Pozisyon adı zorunludur."),
        workingType: yup.string().required("Çalışma şekli zorunludur."),
        estimatedStartDate: yup.date(),
        gender: yup.string(),
        ageInterval: yup.string(),
        numberOfEmployee: yup.string(),
        shiftNumber: yup.string(),
        disabledStatus: yup.string(),
        workDescription: yup.string(),
        desiredFeatures: yup.string(),
        wage: yup.string(),
        premium: yup.string(),
        bonus: yup.string(),
        travelExpenses: yup.string(),
        mealExpenses: yup.string(),
        education: yup.string(),
        foreignLanguage: yup.string(),
        workerMeetingDate: yup.date(),
        serviceRoute: yup.string(),

    })
    const handleCreateConversion = async (values: any, resetForm: any) => {
        console.log(values)
        try {
            const response: any = await createDiscover(values)
            console.log({ response });

            if (response?.data) {
                enqueueSnackbar("Keşif talebiniz başarıyla oluşturuldu.")
                resetForm()
            } else {
                enqueueSnackbar("Oluşturulamadı.", { variant: 'warning' })
            }

        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti.", { variant: 'error' })
        }
    }

    return (
        <DashboardLayoutMobile>

            <Typography mb={3} variant='h1'>
               Özgelecek Keşif
            </Typography>

            <Box bgcolor={"secondary.lighter"} p={2} mt={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                {/* <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}>
                    <Iconify icon={"material-symbols:info-outline"} />
                </Box> */}
                <Typography variant="body2" >
                    Özgelecek, “Keşif” danışmanlığıyla işe alım operasyonlarınızı delege edebileceğiniz bir hizmeti beraberinde sunar! <br /><br />
                    Aşağıdaki formu doldurarak “Nasıl?” bir çalışan aradığınızı bize bildirin ve Keşif Danışmanlarımız sizler adına “Özgelecek vizyonuyla” aradığınız çalışanı bulsun. Özgelecek Keşif ile işe alım sürecinizdeki tüm operasyonları delege etmiş olarak; mülakatları yapılmış, görüşme notları oluşturmuş, uygun olduğunuz takvimde görüşmeniz organize edilerek süreciniz asiste edilmiş, özetle: “İşe Alıma Hazır” adaylara kavuşun!
                </Typography>
            </Box>

            <Card sx={{ p: 3, mt: 3 }}>

                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialvalues}
                    onSubmit={(values, {resetForm}) => {
                        handleCreateConversion(values, resetForm)
                    }}
                >
                    {
                        ({ handleSubmit, errors }) => {
                            return (
                                <Stack spacing={3} >
                                    <CreateConversionFormContainer />
                                    <Stack direction="row" justifyContent="end">
                                        <LoadingButton
                                            color='primary'
                                            variant='contained'
                                            loading={createDiscoverLoading}
                                            disabled={createDiscoverLoading}
                                            onClick={() => handleSubmit()}
                                        >
                                            Oluştur
                                        </LoadingButton>
                                    </Stack>
                                </Stack>
                            )
                        }
                    }
                </Formik>
            </Card>
        </DashboardLayoutMobile>
    )
}



const CreateConversionFormContainer = () => {

    return (
        <Grid container spacing={3}>

            <Grid item xs={12} lg={6}>
                <FormikTextField
                    name="position"
                    label="Pozisyon Adı*"
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormikSelect
                    name="workingType"
                    label="Çalışma Şekli*"
                    list={[
                        { name: "Tam Zamanlı", value: "fullTime" },
                        { name: "Yarı Zamanlı", value: "partTime" },
                        { name: "Stajyer", value: "intern" },
                    ]}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormikDatePicker
                    name="estimatedStartDate"
                    label="Tahmini Başlangıç Tarihi"
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormikSelect
                    name="gender"
                    label="Cinsiyet"
                    list={[
                        { name: "Erkek", value: "erkek" },
                        { name: "Kadın", value: "kadın" },
                        { name: "Diğer", value: "diğer" },
                    ]}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormikSelect
                    name="ageInterval"
                    label="Yaş Aralığı"
                    list={[
                        { name: "18-25", value: "18-25" },
                        { name: "25-35", value: "25-35" },
                        { name: "35-45", value: "35-45" },
                        { name: "45-55", value: "45-55" },
                        { name: "55+", value: "55+" },
                    ]}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormikTextField
                    name="numberOfEmployee"
                    label="İstihdam Sayısı"
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormikSelect
                    name="shiftNumber"
                    label="Vardiya Sistemi"
                    list={[
                        { name: "Gündüz", value: "gunduz" },
                        { name: "Gece", value: "gece" },
                        { name: "3 Vardiya", value: "3vardiya" },
                    ]}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormikSelect
                    name="disabledStatus"
                    label="Engellilik Durumu"
                    list={[
                        { name: "Var", value: "var" },
                        { name: "Yok", value: "yok" },
                    ]}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormikTextField
                    name="workDescription"
                    label="İş Görev Tanımı"
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormikTextField
                    name="desiredFeatures"
                    label="Aranılan Özellikler"
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormikTextField
                    name="wage"
                    label="Maaş (Aralık)"
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormikSelect
                    name="premium"
                    label="Prim"
                    list={[
                        { name: "Var", value: "var" },
                        { name: "Yok", value: "yok" },
                    ]}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormikSelect
                    name="bonus"
                    label="İkramiye"
                    list={[
                        { name: "Var", value: "var" },
                        { name: "Yok", value: "yok" },
                    ]}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormikSelect
                    name="travelExpenses"
                    label="Ulaşım"
                    list={[
                        { name: "Karşılanıyor", value: "karsilaniyor" },
                        { name: "Karşılanmıyor", value: "karsilanmiyor" },
                    ]}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormikSelect
                    name="mealExpenses"
                    label="Yemek"
                    list={[
                        { name: "Karşılanıyor", value: "karsilaniyor" },
                        { name: "Karşılanmıyor", value: "karsilanmiyor" },
                    ]}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormikSelect
                    name="education"
                    label="Eğitim (Mezuniyet Derecesi)"
                    list={[
                        { name: "Lise", value: "lise" },
                        { name: "Ön Lisans", value: "onlisans" },
                        { name: "Lisans", value: "lisans" },
                        { name: "Yüksek Lisans", value: "yukseklisans" },
                        { name: "Doktora", value: "doktora" },
                    ]}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormikTextField
                    name="foreignLanguage"
                    label="Yabancı Dil (Dil adı, derecesi)"
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormikDatePicker
                    name="workerMeetingDate"
                    label="Görüşme Tarihi"
                    minDate={new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormikTextField
                    name="serviceRoute"
                    label="Servis Güzergahı"
                />
            </Grid>




        </Grid>
    )
}