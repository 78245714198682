import { Route } from 'react-router-dom'

import {
    LoginPage,
    RegisterPage,
    ResetPasswordPage,
    RegisterVerifyPage,
    ForgotPasswordPage,
    RegisterSuccessfullPage,
    ForgotPasswordSuccessfullPage,
    RegisterResendRequest,
} from 'pages';
import { routes } from './routes';



export const AuthRouter = () => {
    return (
        <>
            <Route path="/" element={<LoginPage />} />
            <Route path={routes.auth.login} element={<LoginPage />} />
            <Route path={routes.auth.forgotPassword} element={<ForgotPasswordPage />} />
            <Route path={routes.auth.forgotPasswordSuccessfull} element={<ForgotPasswordSuccessfullPage />} />
            <Route path={routes.auth.resetPassword} element={<ResetPasswordPage />} />

            <Route path={routes.auth.register} element={<RegisterPage />} />
            <Route path={routes.auth.registerSuccessfull} element={<RegisterSuccessfullPage />} />
            <Route path={routes.auth.registerVerfiy} element={<RegisterVerifyPage />} />
            <Route path={routes.auth.registerResendRequest} element={<RegisterResendRequest />} />

            <Route path="*" element={<LoginPage />} />
        </>
    )
}
