export const candidate_referenceQuestions = ({ variables }: any) => {

    const data = JSON.stringify({
        query: `
            query Candidate_referenceQuestions($filter: FilterReferenceQuestionInputForUser, $pagination: PaginationWithReferenceQuestionSort) {
                candidate_referenceQuestions(filter: $filter, pagination: $pagination) {
                count
                data {
                    updatedAt
                    index
                    createdAt
                    content
                    _id
                }
                }
            }
        `,
        variables: {
            "filter": {
                "index": variables?.filter?.index ?? null,
            },
            "pagination": {
                "page": variables?.pagination?.page ?? null,
                "pageSize": variables?.pagination?.pageSize ?? null,
                "sort": variables?.pagination?.sort ?? null,
            }
        }
    })

    return data
}
