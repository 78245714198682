import {
    Tab,
    Tabs,
    Stack,
    MenuItem,
    TextField,
    Typography,
    Card,
    Box
} from '@mui/material'
import { useState } from 'react'
import { Iconify } from 'components'
import {

    ICompanyApplicationsVariables,
    useGetCompanyApplicationsQuery,
    useGetCompanyAdvertisementsQuery,
    useGetCompanyCustomApplicationListsQuery,
} from 'context'

import { DashboardLayout, DashboardLayoutMobile } from 'layouts'
import { CompanyApplicationsCustomListActions, CompanyApplicationsTable } from 'sections'





export const CompanyApplicationsPageMobile = () => {

    const [customListDrawer, setCustomListDrawer] = useState(false)
    const [variables, setVariables] = useState<ICompanyApplicationsVariables>({
        filter: {
            candidateId: null,
            customApplicationsListIds: null,
            advertisementId: null,
        },
        pagination: {
            page: 1,
            pageSize: 10,
            sort: ""
        }
    })

    const { data: companyApplications } = useGetCompanyApplicationsQuery(variables)
    const { data: customApplicationList } = useGetCompanyCustomApplicationListsQuery({})
    const { data: advertisements } = useGetCompanyAdvertisementsQuery({ filter: { filter: { cities: [] }, search: { all: "" } } })


    return (
        <DashboardLayoutMobile>

            <Stack direction="row" justifyContent="space-between" spacing={3} alignItems="center">
                <Typography variant='h1'>
                    Başvurular
                </Typography>
            </Stack>

            <Box bgcolor={"secondary.lighter"} p={2} mt={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                {/* <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}>
                    <Iconify icon={"material-symbols:info-outline"} />
                </Box> */}
                <Typography variant="body2" >
                    Bu sayfa üzerinden şirketinize yapılan başvuruları inceleyebilir, adayları değerlendirebilir, listeler oluşturabilir ve adayları bu listelere ekleyebilirsiniz.
                </Typography>
            </Box>

            <Card sx={{ width: '100%', p: { xs: 1, sm: 2, md: 3 }, my: 3 }}>
                <Stack spacing={3}>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} justifyContent="space-between" alignItems="center">

                        <TextField
                            disabled={!advertisements?.data}
                            select={true}
                            label="İlan"
                            value={variables?.filter?.advertisementId ?? "all"}
                            onChange={(e: any) => {

                                setVariables((prevState: any) => ({
                                    ...prevState,
                                    filter: {
                                        ...prevState,
                                        customApplicationsListIds: null,
                                        advertisementId: e?.target?.value !== "all" ? e.target.value : null
                                    }
                                }))
                            }}
                            fullWidth
                            defaultValue={variables?.filter?.advertisementId ?? "all"}
                        // error={error}
                        // helperText="Açıklama"
                        // sx={{ width: { xs: '100%', sm: '300px' }, flex: '0 0 300px' }}
                        >

                            <MenuItem
                                value={"all"}
                            >
                                Hepsi
                            </MenuItem>

                            {
                                (advertisements?.data ?? [])?.map((option, index) => (
                                    <MenuItem
                                        key={index}
                                        value={option?._id}
                                    >
                                        {option?.title}
                                    </MenuItem>
                                ))
                            }
                        </TextField>


                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={3}
                            justifyContent="space-between"
                            alignItems="center"
                            // maxWidth={{ xs: 320, sm: 480 }}

                            sx={{
                                maxWidth: { xs: 280, sm: 600 }
                            }}
                        >

                            <Tabs
                                value={variables?.filter?.customApplicationsListIds ?? ""}
                                onChange={(e, value) => {
                                    // console.log({ e, value });
                                    setVariables((prevState: any) => ({
                                        ...prevState,
                                        filter: {
                                            ...prevState?.filter,
                                            customApplicationsListIds: value ? value : null,
                                            advertisementId: null
                                        }
                                    }))
                                }}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"

                            // textColor="primary"
                            // indicatorColor="primary"
                            >
                                <Tab label="Hepsi" value="" />

                                {
                                    customApplicationList?.data?.map((item: any, index: number) => {

                                        return (
                                            <Tab key={index} label={item?.name} value={item?._id} />
                                        )
                                    })
                                }

                            </Tabs>

                            <CompanyApplicationsCustomListActions
                                customListDrawer={customListDrawer}
                                setCustomListDrawer={setCustomListDrawer}
                                companyApplications={companyApplications ?? null}
                            />
                        </Stack>
                    </Stack>

                    <CompanyApplicationsTable
                        setCustomListDrawer={() => setCustomListDrawer(true)}
                        pagination={variables.pagination}
                        setPagination={(pagination) => setVariables((prevState: any) => ({ ...prevState, pagination }))}
                        data={companyApplications ?? null}
                    />

                </Stack>
            </Card>


        </DashboardLayoutMobile>
    )
}
