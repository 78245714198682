import { Button, Fab, IconButton } from '@mui/material'

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { LoadingButton } from '@mui/lab';


interface IResponsiveButton {
    title: string,
    onClick?: () => void,
    loading?: boolean
}

export const ResponsiveButton = ({ title, onClick, loading }: IResponsiveButton) => {

    return (
        <>
            <LoadingButton
                loading={loading}
                onClick={onClick}
                variant='contained'
                color='primary'
                sx={{
                    display: { xs: 'none', sm: 'initial' }
                }}
            >
                {title}
            </LoadingButton>

            <Fab
                //  variant='contained'
                onClick={onClick}
                color='primary'
                sx={{
                    display: { xs: 'flex', sm: 'none' },
                    position: 'absolute',
                    bottom: 100,
                    right: 20,
                }}
            >
                <AddRoundedIcon />
            </Fab>

        </>
    )
}
