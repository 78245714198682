import { Helmet } from 'react-helmet-async';
import { AuthLayout } from 'layouts';
import { RegisterContainer } from './RegisterContainer';


export const RegisterPage = () => {

  return (
    <AuthLayout>
      <Helmet>
        <title> Register </title>
      </Helmet>
      <RegisterContainer />
    </AuthLayout>
  );
}
