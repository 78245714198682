import HasFilter from "interfaces/HasFilter"
import HasPagination from "interfaces/HasPagination"
import IPaginatedListResult from "interfaces/IPaginatedListResult"
import { ICity } from "interfaces/user/parameter/interfaces/geo/ICity"
import { IFilterParameter } from "interfaces/user/parameter/interfaces/parameter/IFilterParameter"

export interface IAnonymousCitiesResponse extends IPaginatedListResult<ICity> {}

export interface IAnonymousCitiesVariables extends HasFilter<IFilterParameter>, HasPagination { countryName: string }


export const anonymous_cities = (variables?: IAnonymousCitiesVariables) => {
  const data = JSON.stringify({
    query: `
        query anonymous_cities($countryName: String!, $filter: FilterParameterInput, $pagination: Pagination) {
          anonymous_cities(countryName: $countryName, filter: $filter, pagination: $pagination) {
            data {
              name
              code
              districts
            }
            count
          }
        }
        `,
    variables: {
      countryName: variables?.countryName ?? null,

      filter: {
        "name": variables?.filter?.name ?? null
      },
      pagination: {
        "page": variables?.pagination?.page ?? null,
        "pageSize": variables?.pagination?.pageSize ?? null,
        "sort": variables?.pagination?.sort ?? null
      }
    }
  })

  return data
}
