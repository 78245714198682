import { Box, Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { visuallyHidden } from '@mui/utils';


type Order = 'asc' | 'desc';


export interface ICustomTableHeadCell {
    disablePadding: boolean;
    // id: string | "keyof Data";
    id: string | "keyof Data";
    label: string;
    numeric: boolean;
}

interface CustomTableHeaderProps {
    numSelected?: number;
    onRequestSort?: (event: React.MouseEvent<unknown>, property: string | "keyof Data") => void;
    onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order?: Order;
    orderBy?: string;
    rowCount: number;
    headCells: Array<ICustomTableHeadCell>
    checkBox?: boolean;
}

export const CustomTableHeader = ({ onSelectAllClick, order, orderBy, numSelected = 0, rowCount = 0, onRequestSort, headCells, checkBox }: CustomTableHeaderProps) => {



    const createSortHandler = (property: string | "keyof Data") => (event: React.MouseEvent<unknown>) => {
        // onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>

                {
                    checkBox && (<TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell>)
                }


                {
                    headCells.map((headCell: any) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                            sx={{ pl: 2 }}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                hideSortIcon={true}
                            >
                                {headCell.label}
                                {
                                    orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null
                                }
                            </TableSortLabel>
                        </TableCell>
                    ))
                }

            </TableRow>
        </TableHead>
    );
}