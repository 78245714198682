

export type TReferenceRejectReferenceInformationMutationVariables = {
  variables: {
    token: string
  }
}


export const reference_rejectReferenceInformation = ({ variables }: TReferenceRejectReferenceInformationMutationVariables) => {

  const data = JSON.stringify({
    query: `
        mutation Reference_rejectReferenceInformation($token: String!) {
            reference_rejectReferenceInformation(token: $token) {
              success
            }
          }
        `,
    variables: {
      "token": variables?.token
    }
  })

  return data

}