
import moment from 'moment'
import { useEffect, useState } from 'react'
import Chart from 'react-apexcharts';
import { useNavigate } from 'react-router-dom'
import { Box, Divider, Grid, Table, Typography, TableBody, TableCell, TableContainer, Chip, TableRow, Paper, Stack, Skeleton, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import { CareerGif, HappyGif, LogoCrop, WritingGif } from 'assets';
import { enumFunctions } from 'utils'
import { DashboardLayout } from 'layouts'
import { TotalJobs, Interviews } from 'assets'
import { ApplicationStatusEnums, OzgelecekFieldEnum } from 'types'
import { ICandidateApplicationsItem, useAppSelector, useGetCandidateApplicationsQuery, useGetCandidateApplicationStatisticsQuery } from 'context'
import { PieChart } from '@mui/x-charts/PieChart';
import { Square } from '@mui/icons-material';



export const CandidateDashboardPage = () => {

    const navigate = useNavigate();
    const seriesData = [10, (20 + 44), 30];
    const { user, device } = useAppSelector(state => state.auth)
    const { data: statistics, isFetching } = useGetCandidateApplicationStatisticsQuery({})
    const chartLabels = ['Uygun Bulundu', 'Görüntülendi', "İnceleniyor"];
    const labeledSeries = chartLabels.map((label, index) => `${label} (${seriesData[index]})`);
    const { data: applications, isLoading } = useGetCandidateApplicationsQuery({ pagination: { page: 1, pageSize: 5 } })
    const [acilisDialog, setAcilisDialog] = useState(false)

    useEffect(() => {
        if (!acilisDialog && !sessionStorage.getItem("homeDialog")) {
            sessionStorage.setItem("homeDialog", "true")
            setAcilisDialog(true)
        }
    }, []);


    return (
        <DashboardLayout>

            {device !== "mobile" && <Typography variant='h1'>
                Panel
            </Typography>}

            <Divider sx={{ my: 2 }} />

            <Typography variant='h3'>
                Merhaba {user?.name},
            </Typography>

            <Typography variant='body2' mt={1}>
                Bu sayfada özgeleceklerin ile başvurduğun ilanların özetini görütüleyebilirsiniz.
            </Typography>

            <Grid container spacing={2} mt={2} >

                <Grid item xs={12} md={6}   >
                    <Box
                        sx={{
                            p: 2, border: '2px solid #ddd', backgroundImage: `url(${TotalJobs})`, backgroundSize: "30%",
                            backgroundRepeat: "no-repeat", borderRadius: 2, backgroundPosition: "right 10px bottom 0px"
                        }}
                    >
                        <Typography variant='h3'>
                            Toplam Başvuru Sayısı
                        </Typography>
                        <Typography fontSize={"48px"} fontWeight={700} mt={1}>
                            {Number(statistics?.pendingCount) + Number(statistics?.suitableCount) + Number(statistics?.unsuitableCount) + Number(statistics?.viewedCount)}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            p: 2, border: '2px solid #ddd', borderRadius: 2, backgroundImage: `url(${Interviews})`, backgroundSize: "30%",
                            backgroundRepeat: "no-repeat", backgroundPosition: "right 10px bottom 0px", mt: 2
                        }}
                    >
                        <Typography variant='h3'>
                            Alınan Görüntülenme
                        </Typography>
                        <Typography fontSize={"48px"} fontWeight={700} mt={1}>
                            {Number(statistics?.viewedCount)}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6}   >
                    <Box sx={{ height: '100%', border: '2px solid #ddd', borderRadius: 2, p: 2 }}>
                        <Typography variant='h3' mb={2} textAlign={"center"}>
                            Başvuru Grafiği
                        </Typography>

                        <Box
                            marginLeft={{ xs: "auto", md: 0 }}
                            marginRight={{ xs: "auto", md: 0 }}
                        >
                            {/* <Chart options={chartData.chartOptions}
                                series={chartData.series} type="donut"
                                name="Başvuru Grafiği" height={chartData.height} /> */}
                            <PieChart
                                series={[
                                    {
                                        data: (statistics?.suitableCount || statistics?.viewedCount || statistics?.pendingCount || statistics?.unsuitableCount) ? [
                                            { id: 1, value: Number(statistics?.suitableCount) || 0, color: '#5344D2' },
                                            { id: 2, value: Number(statistics?.viewedCount) || 0, color: '#1C1579' },
                                            { id: 3, value: (Number(statistics?.pendingCount) + Number(statistics?.unsuitableCount)) || 0, color: '#DFDAFC' },
                                        ] : [
                                            { id: 1, value: 0, color: '#5344D2' },
                                            { id: 2, value: 1, color: '#1C1579' },
                                            { id: 3, value: 0, color: '#DFDAFC' },
                                        ],
                                        highlightScope: { faded: 'global', highlighted: 'item' },
                                        faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                    },
                                ]}
                                height={180}
                                sx={{
                                    width: '100%',
                                    ml: 10
                                }}
                            />
                            <Stack direction="row" justifyContent="center" mt={2} gap={1}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Square sx={{ color: '#5344D2' }} />
                                    <Typography fontWeight={"bold"} fontSize={"small"}>
                                        Uygun Bulundu ({statistics?.suitableCount})
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Square sx={{ color: '#DFDAFC' }} />
                                    <Typography fontWeight={"bold"} fontSize={"small"}>
                                        İnceleniyor ({statistics?.pendingCount})
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Square sx={{ color: '#1C1579' }} />
                                    <Typography fontWeight={"bold"} fontSize={"small"}>
                                        Görüntülendi ({statistics?.viewedCount})
                                    </Typography>
                                </Box>
                            </Stack>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} md={12}>
                    <Box sx={{ p: 2, border: '2px solid #ddd', borderRadius: 2, width: '100%', height: '100%' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant='h3' mb={2}>
                                Son Başvurularım
                            </Typography>
                            {device !== "mobile" && <Typography fontWeight={'bold'} mb={2} color="primary" sx={{ cursor: 'pointer' }} onClick={() => {
                                navigate('/basvurularim')
                            }}>
                                Tümünü Gör
                            </Typography>}
                        </Box>

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableBody>
                                    {
                                        !isLoading ? (

                                            applications && applications?.count > 0 ?

                                                applications?.data?.map((row: ICandidateApplicationsItem) => (
                                                    <TableRow
                                                        hover
                                                        key={row._id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row" align='left'>
                                                            <Box sx={{ display: 'flex', gap: 2 }}>
                                                                <Box
                                                                    component="img"
                                                                    src={row?.advertisement?.createdBy?.companyInformation?.profileImage ?? LogoCrop}
                                                                    width={50}
                                                                    height={50}
                                                                    borderRadius={"25px"}
                                                                    sx={{ objectFit: 'contain' }}
                                                                />
                                                                <Stack>
                                                                    <Typography variant="body2" color="black" fontWeight="bold" textTransform="capitalize">
                                                                        {row.advertisement?.title}
                                                                    </Typography>
                                                                    <Typography variant="body2" color="GrayText">
                                                                        {row?.advertisement?.createdBy?.companyInformation?.companyName}
                                                                    </Typography>
                                                                </Stack>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Typography variant="body2">
                                                                {enumFunctions.findAndDisplayName(OzgelecekFieldEnum, row?.advertisement?.field)}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Chip
                                                                label={enumFunctions.findAndDisplayName(ApplicationStatusEnums, row?.status)}
                                                                size='small'
                                                                sx={{ width: 130 }}
                                                                variant="outlined"
                                                                color={
                                                                    row?.status === "pending" ? "warning"
                                                                        : row?.status === "pendingAsBlacklisted" ? "default"
                                                                            : row?.status === "suitable" ? "success"
                                                                                : row?.status === "unsuitable" ? "error"
                                                                                    : row?.status === "viewed" ? "primary"
                                                                                        : "info"
                                                                }
                                                                style={{ borderRadius: 100 }}
                                                            /></TableCell>
                                                        <TableCell align="left">{moment(row?.createdAt).format('DD MMMM YYYY')}</TableCell>
                                                    </TableRow>
                                                )) :
                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell colSpan={4} align={device !== "mobile" ? 'center' : 'left'}>
                                                        <Typography variant="body2">
                                                            Henüz başvurunuz bulunmamaktadır.
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>

                                        ) : (
                                            <Stack gap={3}>
                                                {
                                                    [1, 2, 3]?.map((item) => {

                                                        return (
                                                            <Skeleton variant='rectangular' height={30} key={item} />
                                                        )
                                                    })
                                                }
                                            </Stack>

                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>

                </Grid>


            </Grid>

            <Dialog
                open={acilisDialog}
                onClose={() => setAcilisDialog(false)}
            >
                <DialogTitle style={{ textAlign: "center" }}>
                    Görüşlerin bizim için çok değerli!
                </DialogTitle>
                <DialogContent sx={{
                    display: "flex", flexDirection: "column", alignItems: "center",
                    width: { xs: '100%', sm: '500px' },
                }}>
                    <img src={WritingGif} alt="Writing" style={{ width: "250px", height: "auto" }} />
                    <Typography variant="body1" textAlign={"center"}>
                        Sistemimizi geliştirmek ve çok daha iyi çözümler sunabilmek amacıyla lütfen gördüğün eksiklikleri bize <a href='mailto:bilgi@ozgelecek.net' style={{ color: 'black' }}>bilgi@ozgelecek.net</a> adresinden bildir, sana ve diğer tüm iş arayanlara en iyi işe alım sürecini yaşatmamız için destekçi ol!

                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            setAcilisDialog(false)
                        }}
                    >
                        Tamam
                    </Button>
                </DialogActions>
            </Dialog>

        </DashboardLayout >
    )
}
