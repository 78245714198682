import { MutationVariablesPaginationSortEnums } from "types"

export interface ICompanyReferenceItem {
  _id: string
  createdAt: string
  createdBy: {
    _id: string
  }
  referenceExplanation: string
  referenceStatus: string
  referenceTests: {
    question: {
      _id: string
    }
    answer: string
  }
  updatedAt: string
  referenceInformation: {
    email: string
    jobTitle: string
    name: string
    phoneNumber: string
    relationWithCandidate: string
    surname: string
  }
}
export interface ICompanyReferencesResponse {
  count: number
  data: Array<ICompanyReferenceItem>
}

export interface ICompanyReferencesVariables {
  candidateId: string,
  pagination: {
    page: number | null
    pageSize: number | null
    sort?: MutationVariablesPaginationSortEnums | string
  }
}

export const company_references = (variables?: ICompanyReferencesVariables | null) => {

  const data = JSON.stringify({
    query: `
        query company_references($candidateId: ID!, $pagination: Pagination) {
            company_references(candidateId: $candidateId, pagination: $pagination) {
              count
              data {
                _id
                createdAt
                createdBy {
                  _id
                }
                referenceExplanation
                referenceStatus
                referenceTests {
                  question {
                    _id
                  }
                  answer
                }
                updatedAt
                referenceInformation {
                  email
                  jobTitle
                  name
                  phoneNumber
                  relationWithCandidate
                  surname
                }
              }
            }
          }
        `,
    variables: variables
  })

  return data
}
