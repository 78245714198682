import { LoadingButton } from '@mui/lab'
import { Grid, IconButton, Typography } from '@mui/material'
import { FormikTextField } from 'components'
import { Formik } from 'formik'
import React, { useState } from 'react'

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from 'axios'
import { enqueueSnackbar } from 'notistack'
import { getAccessToken } from 'utils'
import { logoutReducer, useAppDispatch } from 'context'


export const AccountSection = () => {

    const [visibility, setVisibility] = useState<Array<string>>([])
    const dispatch = useAppDispatch()


    const handleLogout = () => {
        dispatch(logoutReducer({}))
        document.location.reload();
    }

    const initialValues = {
        currentPassword: '',
        newPassword: "101010Q.qq"
    }

    const handleChangeVisibility = (value: string) => {
        const isFind = visibility?.find((item) => item === value)
        if (isFind) {
            setVisibility(visibility?.filter((item) => item !== value))
        } else {
            setVisibility([...visibility, value])
        }

    }

    const handleChangePassword = async (values: any, setErrors: any, resetForm: any) => {

        if (values?.currentPassword === values?.newPassword) {
            setErrors({ newPassword: "Güncel şifreden farklı olmalı!" })
            return
        }
        try {
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: process.env.REACT_APP_BASE_URL + '/auth/auth/resetpassword',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getAccessToken(),
                },
                data: JSON.stringify({
                    oldPassword: values?.currentPassword,
                    password: "101010Q.qq"
                })
            };

            const response = await axios.request(config)

            if (response.status === 200) {
                enqueueSnackbar("Hesabınız silinmiştir. Sizleri yeniden aramızda görmekten mutluluk duyarız.", { variant: 'success' })
                setTimeout(() => {
                    handleLogout()
                }, 1500);
            } else {
                enqueueSnackbar("Hesabınız silinirken bir sorun oluştu.", { variant: 'warning' })
            }


        } catch (error: any) {
            console.log({ error });

            if (error?.response?.data?.message === "WrongPassword") {
                enqueueSnackbar("Şifreniz hatalı. Lütfen kontrol ediniz.", { variant: 'error' })
            } else {
                enqueueSnackbar("Birşeyler yanlış gitti!", { variant: 'error' })
            }
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setErrors, resetForm }) => {
                console.log({ values });
                handleChangePassword(values, setErrors, resetForm)
            }}
        >
            {
                ({ errors, handleSubmit, isValid, values }) => {


                    return (
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <Typography variant='h3'>
                                    Hesap Silme
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant='body1'>
                                    Hesabınızı sildiğinizde tüm bilgileriniz silinecektir ve geri dönüşü olmayacaktır.
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormikTextField
                                    name="currentPassword"
                                    label="Geçerli Şifreniz*"
                                    type={visibility.includes("currentPassword") ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton onClick={() => handleChangeVisibility("currentPassword")}>
                                                {
                                                    visibility.includes("currentPassword") ? <VisibilityIcon /> : <VisibilityOffIcon />
                                                }
                                            </IconButton>
                                        )
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} sx={{ display: { xs: 'none', sm: 'initial' } }}></Grid>



                            <Grid item xs={12} display="flex" justifyContent="start">
                                <LoadingButton
                                    variant='contained'
                                    color='primary'
                                    onClick={() => handleSubmit()}
                                    disabled={!(values.currentPassword)}
                                >
                                    Hesabımı Sil
                                </LoadingButton>
                            </Grid>


                        </Grid>
                    )
                }
            }
        </Formik>
    )
}
