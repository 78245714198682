import HasTimeStamp from "interfaces/HasTimeStamp";
import { HasOptionalUserAudit } from "interfaces/HasUserAudit";
import IEntity from "interfaces/IEntity";
import { Role } from "types/_role";


export interface IUserCreateOrderResponse {
  user_createOrderForUserWithIyzicoCheckoutFormPayment: {
    order: IEntity;
    paymentResult: {
      status: string; // "success",
      locale: string; // "tr",
      conversationId: string; // "65c942a3b71aab9df13fe72d",
      checkoutFormContent: string;
      paymentPageUrl: string; // "https://sandbox-cpp.iyzipay.com?token=5cdc53c3-668f-4f51-b3ce-a27b834c524e&lang=tr",
      payWithIyzicoPageUrl: string; // "https://sandbox-ode.iyzico.com/?token=5cdc53c3-668f-4f51-b3ce-a27b834c524e&lang=tr",
      token: string; // "5cdc53c3-668f-4f51-b3ce-a27b834c524e",
      tokenExpireTime: number; //1800,
      errorCode: string; // null,
      errorMessage: string; // null,
      errorGroup: string; // null,
      systemTime: number; // 1707688612069
    };
  };
}

export interface IUserCreateOrderVariables {
  orderInput: {
    product: IEntity
  },
  paymentInput: {
    paymentChannel: string
  },
}


export const user_createOrder = (variables: IUserCreateOrderVariables) => {

  const data = JSON.stringify({
    query: `
          mutation user_createOrderForUserWithIyzicoCheckoutFormPayment(
            $orderInput: CreateOrderWithCheckoutFormInput!
            $paymentInput: InitiateCheckoutFormInput
          ) {
            user_createOrderForUserWithIyzicoCheckoutFormPayment(
              orderInput: $orderInput
              paymentInput: $paymentInput
            ) {
              order {
                _id
              }
              paymentResult {
                status
                locale
                conversationId
                checkoutFormContent
                paymentPageUrl
                payWithIyzicoPageUrl
                token
                tokenExpireTime
                errorCode
                errorMessage
                errorGroup
                systemTime
              }
            }
          }
        `,
    variables: variables
  })

  return data
}
