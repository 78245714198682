


export interface ICompanyApplicationsByCustomListIdVariables {
  customApplicationListId: string,
  pagination: {
    page: number,
    pageSize: number,
    sort: string
  }
}

export const company_customApplicationListById = (variables: ICompanyApplicationsByCustomListIdVariables) => {

  const data = JSON.stringify({
    query: `
        query Company_customApplicationListById($customApplicationListId: ID!, $pagination: Pagination) {
          company_customApplicationListById(customApplicationListId: $customApplicationListId, pagination: $pagination) {
            _id
            name
            description
            applications {
              count
              data {
                _id
                application {
                  _id
                  advertisement {
                    _id
                    title
                    status
                    productType
                    extraProduct
                    startDate
                    quota
                    quotaVisibleToApplicant
                    field
                    department
                    language
                    description
                    place
                    workType
                    city
                    district
                    positionLevel
                    requiredAgeInterval
                    requiredEducationLevel
                    requiredSkills
                    requiredLanguages {
                      name
                      reading
                      writing
                      listening
                    }
                    militaryServiceRequired
                    requiredDriverLicense
                    companyInfoVisible
                    images
                    replyNotification
                    customReplyNotification {
                      subject
                      content
                    }
                    createdAt
                    updatedAt
                  }
                  candidate {
                    _id
                    role
                    displayName
                    displayImageUrl
                  }
                  createdAt
                  ozgelecek {
                    _id
                    candidate {
                      _id
                    }
                    createdAt
                    field
                    ozgelecekTest {
                      first {
                        video
                        text
                      }
                      second {
                        text
                        video
                      }
                    }
                    references {
                      _id
                    }
                    testResults {
                      areaTestResults {
                        question {
                          _id
                        }
                        answer
                      }
                      motivationTestResults {
                        answer
                        question {
                          _id
                        }
                      }
                    }
                    updatedAt
                  }
                  status
                  updatedAt
                }
              }
            }
            createdBy {
              _id
            }
            updatedBy {
              _id
            }
            createdAt
            updatedAt
          }
        }
        `,
    variables: {
      "customApplicationListId": variables?.customApplicationListId,
      "pagination": {
        "page": variables?.pagination?.page,
        "pageSize": variables?.pagination?.pageSize,
        "sort": variables?.pagination?.sort
      }
    }
  })


  return data

}