import React, { useEffect, useState } from 'react';
import { MenuItem, StandardTextFieldProps, TextField } from '@mui/material';

interface ISelectProps extends StandardTextFieldProps {
    list: any[];
    defaultValue?: any;
    onChange?: (value: any) => void;
}

export const FormikSelect2: React.FC<ISelectProps> = ({ list, name, label, defaultValue, onChange, ...rest }) => {
    const [selectedValue, setSelectedValue] = useState(defaultValue || '');

    useEffect(() => {
        if (list.length > 0 && !defaultValue) {
            setSelectedValue(list[0].value ?? list[0].name);
        }
    }, [list, defaultValue]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setSelectedValue(value);
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <TextField
            select
            label={label}
            value={selectedValue}
            fullWidth
            onChange={handleChange}
            {...rest}
        >
            {list.map((option) => (
                <MenuItem key={option.value ?? option.name} value={option.value ?? option.name}>
                    {option.name}
                </MenuItem>
            ))}
        </TextField>
    );
};
