



export interface ICompanyMakeApplicationUnsuitableResponse {
    success: boolean
}
export interface ICompanyMakeApplicationUnsuitableVariables {
    applicationId: string
}


export const company_makeApplicationUnsuitable = (variables: ICompanyMakeApplicationUnsuitableVariables) => {

    const data = JSON.stringify({
        query: `
        mutation Company_makeApplicationUnsuitable($applicationId: ID!) {
            company_makeApplicationUnsuitable(applicationId: $applicationId) {
              success
            }
          }
        `,
        variables: {
            "applicationId": variables?.applicationId ?? null
        }
    })

    return data

}