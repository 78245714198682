import { LoadingButton } from '@mui/lab'
import { useFormikContext } from 'formik'
import { Button, Card, Divider, Grid, Stack, Typography } from '@mui/material'

import { ICreateAdvertisementForm } from 'lib/form-validation'
import { FormikCheckbox, FormikSelect, FormikTextField, FormikUpload, ImageUpload } from 'components'
import { useEffect, useState } from 'react'
import { compressImage } from 'utils'
import { useAppDispatch, useAppSelector, setAdvertisementFormIndex } from 'context'



interface ICreateAdvertisementFormProps {
    buttonLoading: boolean
    images: Array<any>,
    setImages: (e: any) => void,
}

export const CreateAdvertisementLast = ({ buttonLoading, images, setImages }: ICreateAdvertisementFormProps) => {

    const dispatch = useAppDispatch()
    const { setFieldValue, values, handleSubmit } = useFormikContext<ICreateAdvertisementForm>()
    const { advertisementId, advertisementFormIndex } = useAppSelector((state) => state.companyCreateAdvertisement)
    const [statusReady, setStatusReady] = useState(false)

    const handleCreate = () => {
        setFieldValue("advertisementId", advertisementId);
        if ((values?.status === "pendingApproval")) setFieldValue("status", "pending")
        else if (values?.status === "approved") setFieldValue("status", "pendingApproval")
        else setFieldValue("status", "onboardingForthCompleted")

        setStatusReady(true)
    }
    const handleBackStep = () => {
        dispatch(setAdvertisementFormIndex(advertisementFormIndex - 1))
    }

    useEffect(() => {
        if (statusReady) {
            handleSubmit();
        }
    }, [statusReady])

    if (!values?.field) {
        return null
    } return (
        <Card sx={{ p: 3 }}>


            <Grid container spacing={3}>

                <Grid item xs={12} sm={6}>
                    <FormikSelect
                        name='companyInfoVisible'
                        label="Şirket Bilgileriniz Gizlensin mi?"
                        list={[{ value: true, name: "Hayır" }, { value: false, name: "Evet" }]}
                    />
                </Grid>
                <Grid item xs={12} sm={6} />
                <Grid item xs={12} sm={6}>
                    <FormikSelect
                        name='replyNotification'
                        label="İlana başvuranlara gönderilecek otomatik mesaj"
                        list={[
                            { value: "custom", name: "- Kendim farklı bir metin yazacağım. -" },
                            { value: "Başvurunuz alınmıştır. Teşekkür ederiz.", name: "Başvurunuz alınmıştır. Teşekkür ederiz." },
                            { value: "Başvurunuz alınmıştır metni2.", name: "Başvurunuz alınmıştır metni 2." },
                            { value: "Başvurunuz alınmıştır metni3.", name: "Başvurunuz alınmıştır metni 3." }]}
                    />
                </Grid>

                {values.replyNotification === "custom" && <Grid item xs={12} >
                    <FormikTextField name='customReplyNotification.subject' label="Konu Başlığı" upperCase />
                </Grid>}


                {values.replyNotification === "custom" && <Grid item xs={12}>
                    <FormikTextField
                        multiline
                        upperCase
                        minRows={4} name='customReplyNotification.content' label="Mesaj İçeriği" />
                </Grid>}



                <Grid item xs={12}>
                    <Divider />
                    <Typography fontWeight={"bold"} fontSize={"small"} color={"#666"} sx={{ p: 2 }}>
                        İlan Görselleri
                    </Typography>
                    <ImageUpload
                        imageValue={images[0] ?? images}
                        imageValues={images}
                        oldImages={values.images}
                        setFieldValue={setFieldValue}
                        onChange={(files) => {
                            setImages(files)
                        }}
                        compressRate={0.7}
                        multiple
                        error={undefined}
                        helperText={undefined}
                    />
                </Grid>






                <Grid item xs={12}>

                    <Stack direction="row" justifyContent="space-between">
                        <Button
                            sx={{
                                width: 150,
                            }}
                            color='primary'
                            variant='outlined'
                            onClick={handleBackStep}
                        >
                            Geri
                        </Button>

                        <LoadingButton
                            sx={{
                                width: 150,
                            }}
                            color='primary'
                            variant='contained'
                            onClick={handleCreate}
                            loading={buttonLoading}
                            disabled={buttonLoading}
                        >
                            {false ? "Update" : "İleri"}
                        </LoadingButton>
                    </Stack>
                </Grid>


            </Grid>
        </Card>
    )
}
