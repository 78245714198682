import { ApplicationStatusEnums } from "types"


export interface ICompanyApplicationsItem {
  _id: string
  updatedAt: string
  status: ApplicationStatusEnums | string
  createdAt: string

  candidate: {
    updatedAt: string
    personalInformation: {
      name: string
      profileImageUrl: string
      surname: string
    }
    createdAt: string
    _id: string
  }

  advertisement: {
    _id: string
    createdAt: string
    createdBy: {
      _id: string
    }
    description: string
    field: string
    title: string
    updatedAt: string
  }
}

export interface ICompanyApplicationsResponse {
  count: number,
  data: any
}


export interface ICompanyApplicationsVariables {
  filter: {
    candidateId: string | null
    advertisementId: string | null,
    customApplicationsListIds: string | null
  },
  pagination: {
    page: number,
    pageSize: number,
    sort: string
  }
}




export const company_applications = (variables: ICompanyApplicationsVariables) => {

  const data = JSON.stringify({
    query: `
    query Company_applications($filter: FilterApplicationForCompanyInput, $pagination: Pagination) {
      company_applications(filter: $filter, pagination: $pagination) {
        count
        data {
          _id
          updatedAt
          status
          createdAt
          candidate {
            updatedAt
            personalInformation {
              name
              profileImageUrl
              surname
            }
            createdAt
            _id
          }
          advertisement {
            _id
            createdAt
            createdBy {
              _id
            }
            description
            field
            title
            updatedAt
          }
        }
      }
    }
        `,
    variables: {
      "filter": {
        "candidateId": variables?.filter?.candidateId ?? null,
        "advertisementId": variables?.filter?.advertisementId ?? null,
        "customApplicationsListIds": variables?.filter?.customApplicationsListIds ?? null
      },
      "pagination": {
        "page": variables?.pagination?.page ?? null,
        "pageSize": variables?.pagination?.pageSize ?? null,
        "sort": variables?.pagination?.sort ?? null
      }
    }
  })


  return data

}