import { useState, useRef, useEffect } from 'react'
import { Typography, Box, Divider, Stack, Button, Skeleton, Dialog, DialogContent, DialogTitle, DialogActions, CircularProgress } from '@mui/material'
import { DashboardLayout } from 'layouts'
import { Iconify } from 'components'
import { useGetUserProductsQuery, useGetSelfUserQuery, useUserCreateOrderMutation, IUserCreateOrderResponse, useUserUpdateOrderMutation, IUserUpdateOrderResponse, useGetUserOrderQuery, useLazyGetUserOrderQuery } from 'context'
import { AuthLayoutBackgroundImage, FailureAnimation, HappyGif } from 'assets'
import { IGraphqlResponse } from 'interfaces/IGraphqlResponse'
import { HasData } from 'interfaces/HasData'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import Lottie from 'lottie-react'
import { OkAnimation } from 'assets'

export const CompanyCreditsPage = () => {

    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    const { data: products, isLoading } = useGetUserProductsQuery({ filter: { name: "" } })

    const [orderId, setOrderId] = useState("")
    const [getUserOrder, { data: userOrder }] = useLazyGetUserOrderQuery()
    const { data: user, refetch: refetchUser } = useGetSelfUserQuery({})
    const [createOrder] = useUserCreateOrderMutation()
    const [updateOrder] = useUserUpdateOrderMutation()
    const [iyzicoUrl, setIyzicoUrl] = useState("")
    const gridIframe = useRef(null);
    const [paymentResult, setPaymentResult] = useState("")
    const [acilisDialog, setAcilisDialog] = useState(false)


    const handleBuyCredit = async (productId: any) => {
        try {
            const response = await createOrder({
                "orderInput": {
                    "product": {
                        "_id": productId
                    }
                },
                "paymentInput": {
                    "paymentChannel": "WEB"
                }
            }) as HasData<IGraphqlResponse<IUserCreateOrderResponse>>


            if (response?.data?.errors?.[0]?.message.includes("BillingInfo")) {
                enqueueSnackbar("Lütfen fatura bilgilerinizi doldurunuz.", { variant: "warning" })
                navigate("/ayarlar?tab=fatura-bilgileri")
            } else if (response?.data?.errors?.[0]?.message.includes("email")) {
                enqueueSnackbar("Lütfen email adresinizi doğru şekilde giriniz.", { variant: "warning" })
                navigate("/ayarlar?tab=fatura-bilgileri")
            } else if (response?.data?.errors?.[0]?.message) {
                enqueueSnackbar("Lütfen bilgilerinizi doğru şekilde giriniz.", { variant: "error" })
                navigate("/ayarlar?tab=fatura-bilgileri")
            } else if (response?.data?.data?.user_createOrderForUserWithIyzicoCheckoutFormPayment?.paymentResult?.status === "success") {
                setOrderId(response?.data?.data?.user_createOrderForUserWithIyzicoCheckoutFormPayment?.order?._id)
                setIyzicoUrl(response?.data?.data?.user_createOrderForUserWithIyzicoCheckoutFormPayment?.paymentResult?.paymentPageUrl)
                // window.location.href = response?.data?.data?.user_createOrderForUserWithIyzicoCheckoutFormPayment?.paymentResult?.paymentPageUrl
            } else {
                enqueueSnackbar("Ödeme işlemi sırasında bir hata oluştu", { variant: "error" })
            }
        }
        catch (e) {
            console.log(e, "error")
        }
    }
    // console.log(orderId)
    // let i = 0;

    // const handleIframeLoad = async (event: any) => {
    //     i = i + 1;

    //     if (i === 2) {
    //         const iframe = event.target;
    //         console.log('iframe', iframe)

    //         setIyzicoUrl("")

    //         const fetchData = async () => {
    //             setPaymentResult("waiting");

    //             const delay = (ms: any) => new Promise(res => setTimeout(res, ms));
    //             await delay(3000);

    //             refetch().then((res) => {
    //                 console.log(res, "res")

    //                 if (res?.data?.orderStatus === 'completed') {
    //                     setPaymentResult("completed");
    //                 } else {
    //                     const res = updateOrder({ orderId: orderId }) as HasData<IGraphqlResponse<IUserUpdateOrderResponse>>
    //                     const orderStatus = res.data?.data?.user_updateOrderAfterIyzicoCheckoutFormPayment?.paymentResult?.paymentStatus;
    //                     if (orderStatus === 'completed') {
    //                         setPaymentResult("completed");
    //                     } else {
    //                         setPaymentResult("waitingLonger");
    //                     }
    //                 }
    //             })
    //         };

    //         fetchData();



    //         i = 0;
    //         return () => setPaymentResult("");


    //     }
    // };

    useEffect(() => {
        if (orderId) {

            getUserOrder({ orderId: orderId })

            const interval = setInterval(() => {
                getUserOrder({ orderId: orderId }).then((res) => {
                    if (res?.data?.payment?.paymentStatus === "success") {
                        setPaymentResult("success");
                        setOrderId("")
                        setIyzicoUrl("")
                        clearInterval(interval);
                    } else if (res?.data?.payment?.paymentStatus === "failure") {
                        setPaymentResult("failure");
                        setOrderId("")
                        setIyzicoUrl("")
                        clearInterval(interval);
                    }
                }).catch((err) => {
                    console.log(err, "err")
                })
            }, 5000);

            return () => clearInterval(interval);
        }
    }, [orderId]);

    // useEffect(() => {
    //     if (userOrder?.payment?.paymentStatus === "success") {
    //         setPaymentResult("completed");
    //         setOrderId("")
    //         setPaymentResult("")
    //     } else if (userOrder?.payment?.paymentStatus === "failure") {
    //         setPaymentResult("aborted");
    //         setOrderId("")
    //         setPaymentResult("")
    //     }
    // }, [userOrder]);

    useEffect(() => {
        if (!acilisDialog && !sessionStorage.getItem("creditDialog")) {
            sessionStorage.setItem("creditDialog", "true")
            setAcilisDialog(true)
        }
    }, []);


    return (
        <DashboardLayout>

            <Typography variant='h1'>
                Özgelecek Kredisi
            </Typography>

            <Box bgcolor={"secondary.lighter"} p={4} mt={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}>
                    <Iconify icon={"material-symbols:info-outline"} />
                </Box>
                <Typography variant="body2" >
                    Tek satın alım ile tüm ürünleri kullanın! İlan, aday havuzu, doping gibi ürünlere ayrı ayrı ücret ödemeyin!

                </Typography>
            </Box>

            <Box p={2} bgcolor={"secondary.lighter"} mt={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                <Typography fontSize={20}>
                    <strong> Toplam Kredi: {user?.credit?.credit?.total}</strong><Iconify icon={"ph:coins-duotone"} sx={{ ml: 1 }} />
                </Typography>
            </Box>

            {!isLoading ? <Stack gap={3} mt={2} direction={{ xs: "column", lg: "row" }} justifyContent={"space-between"}>
                <StyledBox>
                    {/* <Typography variant='h3'>
                        {products?.data?.[0]?.name}
                    </Typography> */}
                    <Box flexDirection={"row"} gap={1} alignItems={"center"} justifyContent={"center"} display={"flex"} alignContent={"center"} >
                        <Typography fontSize={"15px"} color={"primary"} fontWeight={"bold"}>
                            &nbsp;
                        </Typography>
                    </Box>
                    <Box flexDirection={"row"} gap={1} alignItems={"center"} justifyContent={"center"} display={"flex"} alignContent={"center"} >
                        <Typography fontSize={"45px"} color={"primary"} fontWeight={"bold"}>
                            50
                        </Typography>
                        <Typography fontSize={"20px"} color={"primary"} fontWeight={"bold"}> kredi</Typography>
                    </Box>
                    <Divider sx={{ width: "100%", mt: 2 }} />
                    <Typography fontSize={"12px"} textAlign={"center"} mt={2} color={"text.secondary"}>
                        <strong> Bu Özgelecek Kredi Paketiyle;</strong><br />
                        • 1 adet 30 gün ilan verebilir, <br />
                        • 2 adet 14 gün ilan verebilir, <br />
                        • Aday havuzundan 8 adet Özgelecek görüntüleyebilir,<br />
                        • Pozisyonunuza uygun 10 nitelikli aday çıkaran Özgelecek Radar sistemini 1 adet kullanabilirsiniz!<br /><br />
                        Veya dilerseniz ilanlarınıza ek Vitrin, Sponsorlu ve Acil ilan ürünleri alabilirisiniz!
                    </Typography>
                    <Divider sx={{ width: "100%", mt: 2 }} />
                    <Stack direction={"row"} gap={1} alignItems={"center"} justifyContent={"center"} display={"flex"}>
                        <Typography fontSize={"20px"} mt={3} sx={{ textDecoration: "line-through" }}    >
                            2160 ₺
                        </Typography>
                        <Typography fontSize={"16px"} paddingTop={3} fontWeight={"bold"}>yerine</Typography>
                    </Stack>
                    <Stack direction={"row"} gap={1} alignItems={"center"} justifyContent={"center"} display={"flex"}>
                        <Typography fontSize={"20px"}    >
                            %20 indirimle
                        </Typography>
                    </Stack>
                    <Stack direction={"row"} gap={1} alignItems={"center"} justifyContent={"center"} display={"flex"}>
                        <Typography fontSize={"30px"} mt={1} fontWeight={"bold"}>
                            {products?.data?.[0]?.priceWithoutVAT} ₺
                        </Typography>
                        <Typography fontSize={"10px"} paddingTop={1} fontWeight={"bold"}>+KDV</Typography>
                    </Stack>
                    <Divider sx={{ width: "100%", mt: 2 }} />
                    <Button variant={"contained"} color={"primary"} fullWidth sx={{ mt: 3 }}
                        onClick={() => handleBuyCredit(products?.data?.[0]?._id)}
                    >Satın Al</Button>


                </StyledBox>

                <StyledBox>
                    {/* <Typography variant='h3'>
                        {products?.data?.[1]?.name}
                    </Typography> */}
                    <Box flexDirection={"row"} gap={1} alignItems={"center"} justifyContent={"center"} display={"flex"} alignContent={"center"} >
                        <Typography fontSize={"15px"} color={"primary"} fontWeight={"bold"}>
                            100 + 10 kredi hediye
                        </Typography>
                    </Box>
                    <Box flexDirection={"row"} gap={1} alignItems={"center"} justifyContent={"center"} display={"flex"} alignContent={"center"} >
                        <Typography fontSize={"45px"} color={"primary"} fontWeight={"bold"}>
                            110
                        </Typography>
                        <Typography fontSize={"20px"} color={"primary"} fontWeight={"bold"}> kredi</Typography>
                    </Box>
                    <Divider sx={{ width: "100%", mt: 2 }} />
                    <Typography fontSize={"12px"} textAlign={"center"} mt={2} color={"text.secondary"}>
                        <strong> Bu Özgelecek Kredi Paketiyle;</strong><br />
                        •  3 adet 30 gün ilan verebilir, <br />
                        • 6 adet 14 gün ilan verebilir, <br />
                        • Aday havuzundan 18 adet Özgelecek görüntüleyebilir,<br />
                        • Pozisyonunuza uygun 10 nitelikli aday çıkaran Özgelecek Radar sistemini 3 adet kullanabilirsiniz! <br /><br />
                        Veya dilerseniz ilanlarınıza ek Vitrin, Sponsorlu ve Acil ilan ürünleri alabilirisiniz!
                    </Typography>
                    <Divider sx={{ width: "100%", mt: 2 }} />
                    <Stack direction={"row"} gap={1} alignItems={"center"} justifyContent={"center"} display={"flex"}>
                        <Typography fontSize={"20px"} mt={3} sx={{ textDecoration: "line-through" }}    >
                            3720 ₺
                        </Typography>
                        <Typography fontSize={"16px"} paddingTop={3} fontWeight={"bold"}>yerine</Typography>
                    </Stack>
                    <Stack direction={"row"} gap={1} alignItems={"center"} justifyContent={"center"} display={"flex"}>
                        <Typography fontSize={"20px"}    >
                            %20 indirimle
                        </Typography>
                    </Stack>
                    <Stack direction={"row"} gap={1} alignItems={"center"} justifyContent={"center"} display={"flex"}>
                        <Typography fontSize={"30px"} mt={1} fontWeight={"bold"}>
                            {products?.data?.[1]?.priceWithoutVAT} ₺
                        </Typography>
                        <Typography fontSize={"10px"} paddingTop={1} fontWeight={"bold"}>+KDV</Typography>
                    </Stack>
                    <Divider sx={{ width: "100%", mt: 2 }} />
                    <Button variant={"contained"} color={"primary"} fullWidth sx={{ mt: 3 }}
                        onClick={() => handleBuyCredit(products?.data?.[1]?._id)}
                    >Satın Al</Button>
                </StyledBox>

                <StyledBox>
                    {/* <Typography variant='h3'>
                        {products?.data?.[1]?.name}
                    </Typography> */}
                    <Box flexDirection={"row"} gap={1} alignItems={"center"} justifyContent={"center"} display={"flex"} alignContent={"center"} >
                        <Typography fontSize={"15px"} color={"primary"} fontWeight={"bold"}>
                            150 + 15 kredi hediye
                        </Typography>
                    </Box>
                    <Box flexDirection={"row"} gap={1} alignItems={"center"} justifyContent={"center"} display={"flex"} alignContent={"center"} >
                        <Typography fontSize={"45px"} color={"primary"} fontWeight={"bold"}>
                            165
                        </Typography>
                        <Typography fontSize={"20px"} color={"primary"} fontWeight={"bold"}> kredi</Typography>
                    </Box>
                    <Divider sx={{ width: "100%", mt: 2 }} />
                    <Typography fontSize={"12px"} textAlign={"center"} mt={2} color={"text.secondary"}>
                        <strong> Bu Özgelecek Kredi Paketiyle;</strong><br />
                        •  5 adet 30 gün ilan verebilir, <br />
                        • 9 adet 14 gün ilan verebilir, <br />
                        • Aday havuzundan 27 adet Özgelecek görüntüleyebilir,<br />
                        • Pozisyonunuza uygun 10 nitelikli aday çıkaran Özgelecek Radar sistemini 4 adet kullanabilirsiniz!  <br /><br />
                        Veya dilerseniz ilanlarınıza ek Vitrin, Sponsorlu ve Acil ilan ürünleri alabilirisiniz!
                    </Typography>
                    <Divider sx={{ width: "100%", mt: 2 }} />
                    <Stack direction={"row"} gap={1} alignItems={"center"} justifyContent={"center"} display={"flex"}>
                        <Typography fontSize={"20px"} mt={3} sx={{ textDecoration: "line-through" }}    >
                            4950 ₺
                        </Typography>
                        <Typography fontSize={"16px"} paddingTop={3} fontWeight={"bold"}>yerine</Typography>
                    </Stack>
                    <Stack direction={"row"} gap={1} alignItems={"center"} justifyContent={"center"} display={"flex"}>
                        <Typography fontSize={"20px"}    >
                            %20 indirimle
                        </Typography>
                    </Stack>
                    <Stack direction={"row"} gap={1} alignItems={"center"} justifyContent={"center"} display={"flex"}>
                        <Typography fontSize={"30px"} mt={1} fontWeight={"bold"}>
                            {products?.data?.[2]?.priceWithoutVAT} ₺
                        </Typography>
                        <Typography fontSize={"10px"} paddingTop={1} fontWeight={"bold"}>+KDV</Typography>
                    </Stack>
                    <Divider sx={{ width: "100%", mt: 2 }} />
                    <Button variant={"contained"} color={"primary"} fullWidth sx={{ mt: 3 }}
                        onClick={() => handleBuyCredit(products?.data?.[2]?._id)}
                    >Satın Al</Button>
                </StyledBox>

                <StyledBox>
                    <Box flexDirection={"row"} gap={1} alignItems={"center"} justifyContent={"center"} display={"flex"} alignContent={"center"} >
                        <Typography fontSize={"15px"} color={"primary"} fontWeight={"bold"}>
                            200 + 40 kredi hediye
                        </Typography>
                    </Box>
                    <Box flexDirection={"row"} gap={1} alignItems={"center"} justifyContent={"center"} display={"flex"} alignContent={"center"} >
                        <Typography fontSize={"45px"} color={"primary"} fontWeight={"bold"}>
                            240
                        </Typography>
                        <Typography fontSize={"20px"} color={"primary"} fontWeight={"bold"}> kredi</Typography>
                    </Box>
                    <Divider sx={{ width: "100%", mt: 2 }} />
                    <Typography fontSize={"12px"} textAlign={"center"} mt={2} color={"text.secondary"}>
                        <strong> Bu Özgelecek Kredi Paketiyle;</strong><br />
                        •     8 adet 30 gün ilan verebilir, <br />
                        •13 adet 14 gün ilan verebilir, <br />
                        • Aday havuzundan 40 adet Özgelecek görüntüleyebilir,<br />
                        • Pozisyonunuza uygun 10 nitelikli aday çıkaran Özgelecek Radar sistemini 6 adet kullanabilirsiniz!   <br /><br />
                        Veya dilerseniz ilanlarınıza ek Vitrin, Sponsorlu ve Acil ilan ürünleri alabilirisiniz!
                    </Typography>
                    <Divider sx={{ width: "100%", mt: 2 }} />
                    <Stack direction={"row"} gap={1} alignItems={"center"} justifyContent={"center"} display={"flex"}>
                        <Typography fontSize={"20px"} mt={3} sx={{ textDecoration: "line-through" }}    >
                            6335 ₺
                        </Typography>
                        <Typography fontSize={"16px"} paddingTop={3} fontWeight={"bold"}>yerine</Typography>
                    </Stack>
                    <Stack direction={"row"} gap={1} alignItems={"center"} justifyContent={"center"} display={"flex"}>
                        <Typography fontSize={"20px"}    >
                            %20 indirimle
                        </Typography>
                    </Stack>
                    <Stack direction={"row"} gap={1} alignItems={"center"} justifyContent={"center"} display={"flex"}>
                        <Typography fontSize={"30px"} mt={1} fontWeight={"bold"}>
                            {products?.data?.[3]?.priceWithoutVAT} ₺
                        </Typography>
                        <Typography fontSize={"10px"} paddingTop={1} fontWeight={"bold"}>+KDV</Typography>
                    </Stack>
                    <Divider sx={{ width: "100%", mt: 2 }} />
                    <Button variant={"contained"} color={"primary"} fullWidth sx={{ mt: 3 }}
                        onClick={() => handleBuyCredit(products?.data?.[3]?._id)}
                    >Satın Al</Button>
                </StyledBox>




            </Stack> :
                <Stack gap={3} mt={2} direction={{ xs: "column", lg: "row" }} justifyContent={"space-between"}>
                    <Skeleton variant="rectangular" width={"100%"} height={300} />
                    <Skeleton variant="rectangular" width={"100%"} height={300} />
                    <Skeleton variant="rectangular" width={"100%"} height={300} />
                    <Skeleton variant="rectangular" width={"100%"} height={300} />
                </Stack>
            }

            <Box bgcolor={"secondary.lighter"} p={4} mt={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>


                <Typography variant="body2" >
                    <strong>Not: </strong> Kredi paket içeriğindeki harcama hesaplamaları, tek bir ürün kalemi harcaması yapılışına göre gerçekleştirilmiştir.

                </Typography>
            </Box>


            <Dialog open={iyzicoUrl !== ""} onClose={() => setIyzicoUrl("")} aria-labelledby="form-dialog-title" maxWidth="md" >
                <DialogTitle>
                    Iyzico ile Ödeme
                </DialogTitle>
                <DialogContent style={{ width: '500px', height: '500px', overflowY: 'hidden', padding: 0 }}>
                    <div style={{ width: '100%', height: '100%', }}>
                        <iframe
                            allowFullScreen={true}
                            referrerPolicy='origin'
                            ref={gridIframe}
                            // dangerouslySetInnerHTML={{ __html: iyzicoContent }}
                            allow='autoplay *; fullscreen *; encrypted-media *; picture-in-picture *; geolocation *; microphone *; camera *; midi *'
                            width={'100%'}
                            height={'100%'}
                            style={{ width: '100%', height: '100%', border: 'none' }}
                            title="iyzico"
                            src={iyzicoUrl + "&iframe=true"}
                        // referrerPolicy="origin"
                        // onLoad={handleIframeLoad}
                        />
                    </div>
                </DialogContent>
            </Dialog>


            <Dialog open={!!paymentResult} onClose={() => {
                refetchUser()
                setPaymentResult("")
            }} aria-labelledby="form-dialog-title"  >
                <DialogTitle>
                    Ödeme Sonucu
                </DialogTitle>
                <DialogContent sx={{
                    width: { xs: '100%', sm: '500px' },
                    height: '300px',
                    padding: { xs: 5, sm: 0 },
                    display: "flex", flexDirection: "column",
                    justifyContent: "flex-start", alignItems: "center"
                }}>
                    {(paymentResult !== "success" && paymentResult === "waiting") && <>
                        <Typography variant="h6" textAlign={"center"} mb={5}>Lütfen bekleyiniz...</Typography>
                        <CircularProgress />
                    </>}
                    {paymentResult === "success" && <>
                        <Typography variant="h6" textAlign={"center"} mb={5}>Ödeme işlemi başarıyla tamamlandı</Typography>
                        <Lottie
                            animationData={OkAnimation}
                            style={{ width: '60%', height: '60%' }}
                            autoplay={true}
                            loop={false}
                        />
                    </>}
                    {paymentResult === "failure" && <>
                        <Typography variant="h6" textAlign={"center"} mb={5}>Ödeme işleminiz sırasında bir hata oluştu. Lütfen tekrar deneyiniz.</Typography>
                        <Lottie
                            animationData={FailureAnimation}
                            style={{ width: '60%', height: '60%' }}
                            autoplay={true}
                            loop={false}
                        />
                    </>}
                    {paymentResult === "waitingLonger" && <>
                        <Typography variant="h6" textAlign={"center"} mb={5}>Ödeme işleminiz beklenenden uzun sürdü. Lütfen sayfayı yenileyerek hesabınızı kontrol ediniz.</Typography>
                    </>}
                    {/* {paymentResult === 'aborted' && paymentResult && <Lottie
                        animationData={PaymentFailed}
                        style={{ width: '60%', height: '60%' }}
                        autoplay={true}
                        loop={false}
                    />} */}
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='primary' onClick={() => {
                        refetchUser()
                        setPaymentResult("")
                    }}>
                        Kapat
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={acilisDialog}
                onClose={() => setAcilisDialog(false)}
            >
                <DialogTitle style={{ textAlign: "center" }}>
                    BAYRAM KAMPANYASI DEVAM EDİYOR! 🎉
                </DialogTitle>
                <DialogContent sx={{
                    display: "flex", flexDirection: "column", alignItems: "center",
                    width: { xs: '100%', sm: '500px' },
                }}>
                    <img src={HappyGif} alt="Welcome" style={{ width: "250px", height: "auto" }} />
                    <Typography variant="body1" textAlign={"center"}>
                        1 Ağustos 2024 tarihine kadar alacağınız her krediye aldığınız kadar kredi hediye!<br /><br />
                        Üstelik bonuslarıyla birlikte!<br /><br />
                        Şimdi size en uygun kredi paketini seçin ve yıl sonuna kadar dilediğiniz gibi harcayın!<br /><br />
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            setAcilisDialog(false)
                        }}
                    >
                        Tamam
                    </Button>
                </DialogActions>
            </Dialog>

        </DashboardLayout>
    )
}


const StyledBox = (props: any) => {
    return (
        <Box
            sx={{
                // backgroundImage: `url(${AuthLayoutBackgroundImage})`, backgroundSize: "cover",
                bgcolor: "secondary.lighter",
                transition: "all 0.3s ease-in-out",
                cursor: "pointer",
                ':hover': {
                    boxShadow: "0px 0px 10px 3px #5344D255",
                }
            }}
            p={4}
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            border={"3px solid #e0e0e0"}
            {...props}
        >
            {props.children}
        </Box>
    )
}   
