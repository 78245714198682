import HasFilter from "interfaces/HasFilter"
import HasPagination from "interfaces/HasPagination"
import IPaginatedListResult from "interfaces/IPaginatedListResult"
import { IUserNotification } from "interfaces/notifications/notification/userNotification/IUserNotification"
import { IFilterUserNotificationsForUser } from "interfaces/notifications/notification/userNotification/IUserNotificationFilter"

export interface IUserUserNotificationsResponse extends IPaginatedListResult<IUserNotification> {}

export interface IUserUserNotificationsVariables extends HasFilter<IFilterUserNotificationsForUser>, HasPagination { }


export const user_userNotifications = (variables: IUserUserNotificationsVariables) => {

  const data = JSON.stringify({
    query: `
      query user_userNotifications($filter: FilterUserNotificationsForUserInput, $pagination: Pagination) {
        user_userNotifications(filter: $filter, pagination: $pagination) {
          count
          data {
            _id
            uid
            user {
              _id
             # role
              displayName
              displayImageUrl
            }
            notificationType
            notificationPreview {
              name
              subject
              content
              data {
                uid
                targetId
                target
              }
            }
            isRead
            createdAt
            updatedAt
          }
        }
      }
    `,
    variables: variables

  })

  return data

}