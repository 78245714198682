import { useState } from 'react'
import { Box, Button, Card, IconButton, Stack, TextField, Typography } from '@mui/material'
import {
    useAppDispatch,
    useAppSelector,
    useGetUserOzgelecekQuestionsQuery,
    setCandidateCreateOzgelecekFormIndex,
    setCandidateCreateOzgelecekOzgelecekTestIndex,
} from 'context'

import ReplyAllRoundedIcon from '@mui/icons-material/ReplyAllRounded';
import { Iconify } from 'components';



export const OzgelecekTestForm = ({ ozgelecekTest, setOzgelecekTest, isLoading }: any) => {


    const dispatch = useAppDispatch()
    const [text, setText] = useState("")
    const [video, setVideo] = useState<any>("")
    const [answerType, setAnswerType] = useState("")
    const { data: questions } = useGetUserOzgelecekQuestionsQuery({})
    const { formIndex, ozgelecekTestIndex } = useAppSelector(state => state.candidateCreateOzgelecek)



    const handleSetAnswer = (data: any) => {
        console.log({ data });

        if (ozgelecekTestIndex >= 2) {
            if (ozgelecekTest?.first?.text) {
                setOzgelecekTest((prevState: any) => ({ ...prevState, second: data }))
            } else {
                setOzgelecekTest((prevState: any) => ({ ...prevState, first: data }))
            }
            return
        }
        const newItem = ozgelecekTestIndex === 0 ? { first: data } : { second: data }
        setOzgelecekTest((prevState: any) => ({ ...prevState, ...newItem }))
    }


    const handleNext = (e: any) => {

        // dispatch(setCandidateCreateOzgelecekFormOzgelecekTest({
        //     ...(text && { "text": text }),
        //     ...(video && { "video": getBase64(video) }),
        // }))

        const data = (answerType === "text")
            ? (!!text ? { text } : null)
            : (!!video ? { video } : null)
        handleSetAnswer(data)

        setText("")
        setVideo("")
        setAnswerType("")

        if (ozgelecekTestIndex === 0) {
            dispatch(setCandidateCreateOzgelecekOzgelecekTestIndex(ozgelecekTestIndex + 1))
        } else if (ozgelecekTestIndex === 1) {

            if (!!ozgelecekTest?.first && (!!video || !!text)) {
                console.log("ikiside var");
                dispatch(setCandidateCreateOzgelecekFormIndex(formIndex + 1))
            } else if (!!ozgelecekTest?.first?.video || !!video) {
                console.log("en az bir video var");
                dispatch(setCandidateCreateOzgelecekFormIndex(formIndex + 1))
            } else {
                console.log("3. soruyu sor");
                setAnswerType("video")
                dispatch(setCandidateCreateOzgelecekOzgelecekTestIndex(ozgelecekTestIndex + 1))
            }

        } else {
            dispatch(setCandidateCreateOzgelecekFormIndex(formIndex + 1))
        }

    }


    const setPrevQuestion = () => {
        if (ozgelecekTestIndex !== 0) {
            setText("")
            setVideo("")
            setAnswerType("")
            dispatch(setCandidateCreateOzgelecekOzgelecekTestIndex(ozgelecekTestIndex - 1))
        }
    }


    // console.log({ ozgelecekTestIndex });


    return (
        <Box>

            {/* <Typography variant='h2' mb={1} mt={1} ml={3} textAlign={"center"}>
                Ozgelecek Test
            </Typography> */}
            <Box bgcolor={"secondary.lighter"} p={4} mt={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}>
                    <Iconify icon={"material-symbols:info-outline"} />
                </Box>
                <Typography variant="body2" >
                    Bu bölümde kendiniz hakkında bazı sorular cevaplayacaksınız. Soruları metin veya video ile cevaplayabilirsiniz. <br /><br /><strong>Dikkat! </strong><br/>- Video için önerilen süre 50 saniyedir. <br/>-Aydınlık ve sessiz bir yerde çekim yapmanızı öneririz. <br/>-Yükleme yapacağınız video'nun dosya uzantısı .mp4 olmalıdır.
                </Typography>
            </Box>

            <Card sx={{ p: 3, my: 3, }} >
                <Typography fontWeight={'bold'} >
                    {ozgelecekTestIndex + 1} - {
                        ozgelecekTestIndex < 2
                            ? questions?.data[ozgelecekTestIndex]?.content
                            : "Kendiniz hakkında bir video yükleyiniz. Gelecekte neler yapmak istediğinizi ve yapmak istediklerinize karşı motivasyon kaynaklarınızı açıklayın. "
                    }
                </Typography>
            </Card>


            <Card sx={{ p: 3, my: 3, }} >

                <Stack direction="row" justifyContent="space-between" sx={{ mb: 2, }}>
                    <Typography fontWeight="bold">
                        Cevap
                    </Typography>

                    <IconButton
                        onClick={() => {
                            setText("")
                            setVideo("")
                            setAnswerType("")
                        }}
                    >
                        <ReplyAllRoundedIcon />
                    </IconButton>
                </Stack>



                {
                    !answerType ? (
                        <Stack direction="row" spacing={3}>
                            {
                                ozgelecekTestIndex < 2 ? (
                                    [{ value: "text", name: "Yazıyla" }, { value: "video", name: "Videoyla" }]?.map((item, index) => {

                                        return (
                                            <Box
                                                onClick={() => setAnswerType(item?.value)}
                                                key={item?.value}
                                                sx={{

                                                    transitionDuration: '200ms',
                                                    cursor: 'pointer',
                                                    p: 3,
                                                    width: { xs: '100%', sm: '100%' },
                                                    textAlign: 'center',
                                                    border: '2px solid',

                                                    borderColor: "primary.light",
                                                    fontSize: { xs: '13px', sm: '17px' },
                                                    display: 'flex',
                                                    flexDirection: { xs: 'column', sm: 'row' },
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    gap: 1,
                                                    '&:hover': {
                                                        boxShadow: '1px 1px 5px 0px #ccc',
                                                    }
                                                }}
                                            >
                                                {item?.value === "text" && <Iconify icon="line-md:text-box" sx={{ width: "20px", height: "30px" }} />}
                                                {item?.value === "video" && <Iconify icon="line-md:upload-outline" sx={{ width: "23px", height: "30px" }} />}
                                                <Typography > {item?.name}</Typography>
                                            </Box>
                                        )
                                    })
                                ) : (
                                    ["video"]?.map((value, index) => {

                                        return (
                                            <Box
                                                onClick={() => setAnswerType(value)}
                                                key={value}
                                                sx={{

                                                    boxShadow: '1px 1px 5px 0px #ddd',
                                                    transitionDuration: '200ms',
                                                    cursor: 'pointer',
                                                    p: 3,
                                                    width: { xs: '100%', sm: '100%' },
                                                    textAlign: 'center',

                                                    '&:hover': {
                                                        boxShadow: '1px 1px 5px 0px #bbb',
                                                        transform: 'scale(1.01)',
                                                    }
                                                }}
                                            >
                                                {value}
                                            </Box>
                                        )
                                    })
                                )
                            }

                        </Stack>
                    ) : (
                        <>
                            {
                                answerType === "text" ? (
                                    <>
                                        <TextField
                                            fullWidth
                                            multiline
                                            name="question"
                                            label="Cevaınızı buraya yazınız."
                                            minRows={4}
                                            value={text}
                                            onChange={(e) => {
                                                if (e.target.value?.length > 500) {
                                                    setText(e.target.value?.slice(0, 500))
                                                    return
                                                }
                                                setText(e.target.value)
                                            }}
                                            helperText={`${text?.length} / 500 karakter.`}
                                        />
                                    </>
                                ) : (
                                    <Stack gap={3}>
                                        <Box sx={{ width: '100%' }}>
                                            <Box
                                                component="label"
                                                htmlFor='ozgelecek_test_upload_video'

                                                sx={{
                                                    border: '1px solid #ccc',
                                                    p: 3,
                                                    width: '100%',
                                                    cursor: 'pointer',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    transitionDuration: '300ms',
                                                    bgcolor: '#f1f1f1',

                                                    '&:hover': {
                                                        transform: 'scale(1.02)'
                                                    }
                                                }}
                                            >
                                                Video seç
                                            </Box>

                                            <Box
                                                component="input"
                                                accept='video/mp4'
                                                type="file"
                                                id="ozgelecek_test_upload_video"
                                                // value={video}
                                                onChange={(e) => setVideo(e?.target?.files?.[0])}
                                                sx={{
                                                    display: 'none'
                                                }}
                                            />
                                        </Box>

                                        {video ? (<video src={URL.createObjectURL(video)} width="100%" autoPlay controls />) : null}

                                    </Stack>
                                )
                            }


                        </>
                    )
                }
            </Card>


            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" gap={3} sx={{ my: 5 }}>
                <Button
                    variant='outlined'
                    color="primary"
                    onClick={setPrevQuestion}
                    disabled={ozgelecekTestIndex === 0}
                >
                    Geri
                </Button>

                <Button
                    variant='contained'
                    color="primary"
                    sx={{ pl: 5, pr: 4 }}
                    onClick={handleNext}
                    disabled={(ozgelecekTestIndex === 2) && !video}
                >
                    {!text && !video ? (ozgelecekTestIndex >= 2 ? "İleri" : "Atla") : "İleri"}

                    <Iconify icon="mi:next" sx={{ ml: 1, mt: 0.2 }} />
                </Button>
            </Stack>


        </Box>
    )
}
