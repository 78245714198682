import { Avatar, Badge, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, SwipeableDrawer, Tab, Tabs, Typography, useMediaQuery } from '@mui/material'
import { Iconify } from 'components'
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { Global } from '@emotion/react';

import MarkunreadIcon from '@mui/icons-material/Markunread';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import { useLazyGetUserUserNotificationsQuery, useUserReadAllUserNotificationsMutation, useUserReadUserNotificationMutation } from 'context';
import moment from 'moment';

interface INotificationDrawerProps {
    window?: () => Window;
}
 

export const NotificationDrawer = ({ window }: INotificationDrawerProps) => {

    const [getUserNotifications, { data: notifications }] = useLazyGetUserUserNotificationsQuery();
    const [readNotification] = useUserReadUserNotificationMutation();
    const [readAllNotifications] = useUserReadAllUserNotificationsMutation();
    const smUp = useMediaQuery("(min-width:600px)")
    const [notificationDrawer, setNotificationDrawer] = useState(false)
    const [selectedNotification, setSelectedNotification] = useState<any>(null)
    const [notificationCount, setNotificationCount] = useState(0)
    const [notReadNotifications, setNotReadNotifications] = useState<any>([])
    const [readAllDialog, setReadAllDialog] = useState(false)

    const [notificationsTab, setNotificationsTab] = React.useState(1);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setNotificationsTab(newValue);
    };

    const container = window !== undefined ? () => window().document.body : undefined;
    const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

    const handleClickNotification = (notification: any) => {

        console.log(notification?.uid, 'notification')
        setSelectedNotification(notification)
        if (!notification?.isRead) {
            readNotification({ uid: notification?.uid })
        }
    }

    const handleReadAll = () => {
        readAllNotifications({})
    }


    useEffect(() => {

        getUserNotifications({});
        const interval = setInterval(() => {
            getUserNotifications({})
        }, 15000);
        return () => clearInterval(interval);
    }, [getUserNotifications]);

    useEffect(() => {
        const notReadNotifications = notifications?.data?.filter((item: any) => !item?.isRead)
        setNotReadNotifications(notReadNotifications)
        setNotificationCount(notReadNotifications?.length ?? 0)
    }, [notifications])


    const NotificationCard = ({ item }: any) => {

        return <Stack
            onClick={() => handleClickNotification(item)}
            direction="row"
            alignItems="start"
            p={1}
            spacing={1}
            // borderBottom={index !== 11 ? "1px solid #d1d1d1" : "none"}
            sx={{
                opacity: item?.isRead ? 0.5 : 1,
                cursor: 'pointer',
                '&:hover': {
                    bgcolor: '#f1f1f1'
                }
            }}
        >
            <Avatar>
                <MarkunreadIcon />
            </Avatar>
            <Stack flex={1}>
                <Typography fontWeight="bold" fontSize={14} display="flex" justifyContent="space-between">
                    {item?.notificationPreview?.subject}  <span style={{ color: 'GrayText', fontSize: 12, fontWeight: 'normal' }}>{moment(item?.createdAt).fromNow()}</span>
                </Typography>
                <Typography fontSize={10}>
                    {item?.notificationPreview?.content}
                </Typography>
            </Stack>
        </Stack>
    }

    const renderNotifications = () => {

        return (
            <Stack>

                <Tabs
                    value={notificationsTab}
                    onChange={handleChange}
                    aria-label="icon position tabs example"
                    textColor="primary"
                    indicatorColor="primary"
                    sx={{
                        px: 2,
                        pt: 2,
                    }}
                >
                    <Tab icon={<MarkunreadIcon />} iconPosition="start" label="Hepsi" />
                    <Tab icon={<MarkEmailUnreadIcon />} iconPosition='start' label="Okunmayanlar" />
                </Tabs>

                <Stack my={3} >
                    {notificationsTab === 0 ? (
                        notifications?.data && notifications?.data?.length > 0 ? notifications?.data?.map((item: any, index: number) => {
                            return <NotificationCard item={item} key={index} />
                        }) : <Typography variant="body2" color="textSecondary" textAlign="center" p={2}>Henüz bir bildiriminiz bulunmamaktadır.</Typography>
                    ) : (
                        notReadNotifications && notReadNotifications?.length > 0 ? notReadNotifications?.map((item: any, index: number) => {
                            return <NotificationCard item={item} key={index} />
                        }) : <Typography variant="body2" color="textSecondary" textAlign="center" p={2}>Okunmamış bir bildiriminiz bulunmamaktadır.</Typography>
                    )}



                </Stack>

            </Stack>
        )
    }


    return (
        <Box>

            <Badge color="primary" badgeContent={notificationCount} max={9}>
                <IconButton
                    onClick={() => setNotificationDrawer(true)}
                    sx={{
                        bgcolor: 'secondary.light',
                        color: 'primary.main'
                    }}
                >
                    <Iconify icon="ic:twotone-notification-important" />
                </IconButton>
            </Badge>

            <SwipeableDrawer
                container={container}
                anchor={smUp ? "right" : "bottom"}
                open={notificationDrawer}
                onClose={() => setNotificationDrawer(false)}
                onOpen={() => setNotificationDrawer(true)}

                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}

                swipeAreaWidth={56}
                // disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,

                }}

            >
                <Box sx={{ height: '100%', minHeight: smUp ? '100%' : '50vh', }}>

                    {
                        smUp ? (
                            <Stack width={{ xs: '100%', sm: '300px' }} height="100%" bgcolor="white" overflow="auto">
                                {renderNotifications()}

                                <Button variant='outlined' color='primary' sx={{ position: 'absolute', bgcolor:'white', bottom: 20, width: '90%', left: '5%' }} onClick={() => setReadAllDialog(true)}>
                                    <Iconify icon="ic:baseline-done-all" sx={{ mr: 1 }} />
                                    Tümünü Okundu İşaretle
                                </Button>
                            </Stack>
                        ) : (
                            <Stack
                                borderRadius={8}
                                sx={{
                                    minHeight: '50vh',
                                    maxHeight: '70vh',
                                }}
                            >

                                <Stack
                                    alignItems="center"
                                    justifyContent="center"
                                    py={2}
                                    bgcolor="#f1f1f1"
                                >
                                    <Box
                                        sx={{
                                            width: 50,
                                            height: 10,
                                            backgroundColor: grey[500],
                                            borderRadius: '10px'
                                        }}
                                    />
                                </Stack>


                                <Stack
                                    sx={{
                                        pt: 2,
                                        height: '100%',
                                        overflow: 'auto',
                                        bgcolor: '#fff'
                                    }}
                                >
                                    {renderNotifications()}
                                    <Button variant='outlined' color='primary' sx={{ position: 'absolute', bgcolor:'white', bottom: 20, width: '90%', left: '5%' }}>
                                        <Iconify icon="ic:baseline-done-all" sx={{ mr: 1 }} />
                                        Tümünü Okundu İşaretle
                                    </Button>
                                </Stack>
                            </Stack>
                        )
                    }

                </Box>
            </SwipeableDrawer>

            <Dialog open={!!selectedNotification} onClose={() => setSelectedNotification(null)}>
                <DialogTitle>
                    {selectedNotification?.notificationPreview?.subject}
                </DialogTitle>
                <DialogContent>
                    <Typography fontStyle={"italic"} variant="body2" color="textSecondary">
                        {selectedNotification?.notificationPreview?.content}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSelectedNotification(null)} color="primary" variant='outlined'>
                        Kapat
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={readAllDialog} onClose={() => setReadAllDialog(false)}>
                <DialogTitle>
                    Tümünü Okundu İşaretle
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body2" color="textSecondary">
                        Tüm bildirimleri okundu olarak işaretlemek istediğinize emin misiniz?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setReadAllDialog(false)} color="primary" variant='outlined'>
                        İptal
                    </Button>
                    <Button onClick={() => {
                        handleReadAll()
                        setReadAllDialog(false)
                    }} color="primary" variant='contained'>
                        İşaretle
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>
    )
}
