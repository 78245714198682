import { Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { routes } from 'routes';
import { Iconify } from 'components';
import { PasswordIcon } from 'assets';
import { AuthLayout } from 'layouts'



export const RegisterSuccessfullPage = () => {

    const renderHead = (
        <>
            <PasswordIcon sx={{ height: 96 }} />

            <Stack spacing={1} sx={{ my: 5, textAlign: "center" }} alignItems="center">
                <Typography variant="h3">Kayıt Başarılı</Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Emailinize gelen linki tıklayarak hesabınızı aktif edebilirsiniz.
                </Typography>
            </Stack>


            <Link
                component={RouterLink}
                to={routes.auth.login}
                color="inherit"
                variant="subtitle2"
                sx={{
                    alignItems: 'center',
                    display: 'inline-flex',
                    mx: 'auto'
                }}
            >
                <Iconify icon="eva:arrow-ios-back-fill" width={16} />
                Geri dön
            </Link>
        </>
    );

    return (
        <AuthLayout>
            {renderHead}
        </AuthLayout>
    )
}
