import { enumFunctions } from "utils";

export enum PaginationSortEnums {
    createdAtDesc = "createdAtDesc",
    createdAtAsc = "createdAtAsc",
    startDateDesc = "startDateDesc",
    startDateAsc = "startDateAsc",
    quotaDesc = "quotaDesc",
    quotaAsc = "quotaAsc",
}

export enum ExtraProductEnums {
    doping = "Doping İlan",
    emergent = "Acil İlan",
    showcase = "Vitrin İlan"
}

export enum PlaceOfWorkEnums {
    inOffice = "İş Yerinde",
    remote = "Uzaktan",
    hybrid = "Hibrit",
}

export enum QuotaEnums {
    q1 = "1",
    q3 = "1-5",
    q8 = "5-10",
    q15 = "10-20",
    q20 = "20+"
}

export enum PositionLevelEnums {
    seniorManager = "Kıdemli Yönetici",
    midLevelManager = "Orta Düzey Yönetici",
    expert = "Uzman",
    assistantExpert = "Yardımcı Uzman",
    beginner = "Başlangıç Seviyesi",
    intern = "Stajyer",
    blueCollar = "Mavi Yaka Çalışan",
    freelancer = "Serbest Çalışan",
}

export enum AdvertisementStatusEnums {
    onboardingFirstCompleted = "onboardingFirstCompleted",
    onboardingSecondCompleted = "onboardingSecondCompleted",
    onboardingThirdCompleted = "onboardingThirdCompleted",
    pendingApproval = "pendingApproval",
    approved = "approved",
    rejected = "rejected",
    revision = "revision",
}

export enum ProductTypeEnums {
    day15 = "15 Gün",
    day30 = "30 Gün"
}

export enum WorkTypeEnums {
    fullTime = "Tam Zamanlı",
    halfTime = "Yarı Zamanlı",
    projectBased = "Proje Bazlı",
    free = "Serbest Çalışan",
}

export enum LanguageEnums {
    en = "İngilizce",
    fr = "Fransızca",
    de = "Almanca",
    it = "İtalyanca",
    pt = "Portekizce",
    ru = "Rusca",
    es = "İspanyolca",
    tr = "Türkçe",
}

export enum MutationVariablesPaginationSortEnums {
    createdAtDesc = "Yeniden Eskiye",
    createdAtAsc = "Eskiden Yeniye",
    startDateDesc = "Başlama Tarihi Yeniden Eskiye",
    startDateAsc = "Başlama Tarihi Eskiden Yeniye",
    // quotaDesc = "Kota azalan",
    // quotaAsc = "Kota artan",
}

export enum ApplicationStatusEnums {
    pending = "Beklemede",
    pendingAsBlacklisted = "Kara Listede Bekliyor",
    viewed = "Görüntülendi",
    suitable = "Uygun",
    unsuitable = "Uygun Değil",
}

export const ExtraProductList = enumFunctions.objectlist(ExtraProductEnums)
export const ProductTypeList = enumFunctions.objectlist(ProductTypeEnums)
export const PlaceOfWorkList = enumFunctions.objectlist(PlaceOfWorkEnums)
export const PositionLevelList = enumFunctions.objectlist(PositionLevelEnums)
export const WorkTypeList = enumFunctions.objectlist(WorkTypeEnums)
export const LanguageList = enumFunctions.objectlist(LanguageEnums)
export const MutationVariablesPaginationSortList = enumFunctions.objectlist(MutationVariablesPaginationSortEnums)
export const ApplicationStatusList = enumFunctions.objectlist(ApplicationStatusEnums)