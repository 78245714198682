import { Route, Routes } from 'react-router-dom'

import {
    LoadingPage,
    OzgelecekHomePage,
} from 'pages';
import { routes } from './routes';



export const AnonymousRouter = () => {
    return (
        <>
            <Route path='*' element={<LoadingPage />} />;
            {/* <Route path="/" element={<OzgelecekHomePage />} />
            <Route path="*" element={<OzgelecekHomePage />} /> */}
        </>
    )
}
