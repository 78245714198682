import axios from 'axios';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Link, Stack, IconButton, Typography, InputAdornment, InputLabel, Box } from '@mui/material';

import { routes } from 'routes';
import { loginReducer, useAppDispatch } from 'context';
import { useBoolean } from 'minimal/hooks/use-boolean';
import { RHFTextField, FormProvider, Iconify } from 'components';



const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Geçerli bir email adresi giriniz!')
  // .required('Email gerekli!')
  ,
  password: Yup.string()
  // .matches(/(?=.*[!'^+%.,/()&=?\-_*])/, "En az bir özel karakter içermeli!")
  // .matches(/(?=.*[a-z])/, "En az bir küçük harf içermeli!")
  // .matches(/(?=.*[A-Z])/, "En az bir büyük harf içermeli!")
  // .matches(/(?=.*[0-9])/, "En az bir rakam içermeli!")
  // .min(4, "En az 4 karakter olmalı!")
  // .required("Gerekli"),
});


export const LoginContainer = () => {

  const password = useBoolean()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const path = useLocation().pathname

  console.log(path)

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'all',
  });

  const { handleSubmit, formState: { isSubmitting } } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/auth/auth/login`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(data)
      };

      const response = await axios.request(config)

      console.log({ response });

      if (response?.data?.isSuccess) {
        enqueueSnackbar("Giriş başarılı!", { variant: "success" })
        dispatch(loginReducer(response.data))
        path?.includes("ilanlar") && navigate(path)
      } else {
        enqueueSnackbar("Email onayı gerekiyor!", {
          variant: "info"
        })
      }


    } catch (error: any) {
      console.log(error?.response?.data?.message);

      if (error?.response?.data?.message === "RegistrationConfirmationIsRequired") {
        navigate(routes.auth.registerResendRequest + `?email=${data.email}`)
        enqueueSnackbar("Lütfen emailinize gelen linke tıklayarak doğrulamayı tamamlayınız!", {
          variant: "warning"
        })
        // setTimeout(() => {
        //   navigate(routes.auth.registerVerfiy)
        // }, 1000)
      }
      else if (error?.response?.data?.errors?.[0]?.devMessage === "Invalid Password") {
        enqueueSnackbar("Email adresi ve şifrenizi kontrol ediniz!", { variant: "warning" });
      }
      else if (error?.response?.data?.errors?.[0]?.devMessage?.includes("User not found with email")) {
        enqueueSnackbar("Email adresi ve şifrenizi kontrol ediniz!", { variant: "warning" });
      }
      else {
        enqueueSnackbar("Birşeyler yanlış gitti!", {
          variant: "warning"
        })
      }
    }
  });

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5 }}>
      <Typography variant="h4">Giriş Yap</Typography>

      <Stack direction="row" spacing={0.5}>
        <Typography variant="body2">Yeni Kullanıcı?</Typography>

        <Link component={RouterLink} to={routes.auth.register} variant="subtitle2" >
          Hesap Oluştur
        </Link>
      </Stack>
    </Stack>
  );

  const renderForm = (
    <Stack spacing={2.5} flex={1} flexDirection={"column"} justifyContent={"center"} height={{ md: "80vh" }} px={{ xs: 0, sm: 3, lg: 10 }}>

      <Typography variant="h1" textAlign={"center"}>Giriş Yap</Typography>
      <Box>
        <InputLabel>Email</InputLabel>
        <RHFTextField name="email" placeholder="Emailinizi giriniz" />
      </Box>

      <Box>
        <InputLabel>Şifre</InputLabel>
        <RHFTextField
          name="password"
          placeholder="Şifrenizi giriniz"
          type={password.value ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end">
                  <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Link
        component={RouterLink}
        to={routes.auth.forgotPassword}
        variant="body2"
        color="inherit"
        underline="always"
        sx={{ alignSelf: 'flex-end' }}
      >
        Şifremi Unuttum?
      </Link>

      <LoadingButton
        fullWidth
        color="primary"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Giriş Yap
      </LoadingButton>
      <Stack direction="row" spacing={0.5} justifyContent={"center"}>
        <Typography variant="body2"> Hesabınız yok mu? </Typography>

        <Link to={routes.auth.register + `?redirect=${path}`}
          component={RouterLink} variant="subtitle2">
          Kayıt Ol
        </Link>
      </Stack>
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {/* {renderHead} */}
      {renderForm}

    </FormProvider>
  );
}
