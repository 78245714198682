import * as yup from "yup"
import { useState } from "react";
import { ICandidateInformationInput } from "types"
import dayjs from "dayjs";



export const useCandidateUpdateCandidateFormValidation = ({ values }: { values?: ICandidateInformationInput }) => {

    const [defaultValues, setDefaultValues] = useState<ICandidateInformationInput | any>(values);


    const validationSchema = yup.object().shape({
        personalInformation: yup.object().shape({
            name: yup.string().required("İsim alanı zorunludur"),
            surname: yup.string().required("Soyisim alanı zorunludur"),
            phoneNumber: yup.string().min(17, "Telefon numarası yanlış!.").max(17, "Telefon numarası yanlış!.").required("Telefon numarası alanı zorunludur"),
            birthDate: yup.date().required("Doğum tarihi alanı zorunludur").max(dayjs().subtract(15, 'year').toDate(), "En az 15 yaşında olmalısınız"),
            country: yup.lazy(value => {
                switch (typeof value) {
                    case 'object':
                        return yup.object().required("Ülke alanı zorunludur");
                    case 'string':
                        return yup.string().required("Ülke alanı zorunludur")
                    default:
                        return yup.mixed().required("Ülke alanı zorunludur");
                }
            }),
            city: yup.lazy(value => {
                switch (typeof value) {
                    case 'object':
                        return yup.object().required("Şehir alanı zorunludur");
                    case 'string':
                        return yup.string().required("Şehir alanı zorunludur")
                    default:
                        return yup.mixed().required("Şehir alanı zorunludur");
                }
            }),
            district: yup.string().required("İlçe alanı zorunludur"),
            address: yup.string().nullable(),
            sex: yup.string().required("Cinsiyet alanı zorunludur"),
            gender: yup.string().nullable(),
            genderDetail: yup.string().nullable(),
            nationality: yup.lazy(value => {
                switch (typeof value) {
                    case 'object':
                        return yup.object().required("Uyruk alanı zorunludur");
                    case 'string':
                        return yup.string().required("Uyruk alanı zorunludur")
                    default:
                        return yup.mixed().required("Uyruk alanı zorunludur");
                }
            }),
            citizenship: yup.string().nullable(),





            description: yup.string().nullable(),
            profileImageUrl: yup.lazy(value => {
                switch (typeof value) {
                    case 'object':
                        return yup.object().nullable(); // schema for object
                    case 'string':
                        return yup.string().nullable()// schema for string
                    default:
                        return yup.mixed().nullable(); // here you can decide what is the default
                }
            }),
            backgroundImageUrl: yup.lazy(value => {
                switch (typeof value) {
                    case 'object':
                        return yup.object().nullable(); // schema for object
                    case 'string':
                        return yup.string().nullable()// schema for string
                    default:
                        return yup.mixed().nullable(); // here you can decide what is the default
                }
            }),
        }),
        driverLicense: yup.object().shape({
            status: yup.string().required("Sürücü ehliyeti alanı zorunludur"),
            issueDate: yup.date().nullable()
                .when("status", (status, schema) => {

                    if (status[0] === "exist") {
                        return schema.required("Sürücü ehliyeti tarihi alanı zorunludur")
                    }
                    return schema
                }),
            licenseType: yup.string().nullable()
                .when("status", (status, schema) => {

                    if (status[0] === "exist") {
                        return schema.required("Sürücü ehliyeti tipi alanı zorunludur")
                    }
                    return schema
                })
        }),
        education: yup.object().shape({
            highSchool: yup.array().nullable(),
            higherEducation: yup.array().nullable(),
        }),
        certificates: yup.array().nullable(),
        languages: yup.array().required("Dil alanı zorunludur"),
        smoking: yup.bool().nullable(),
        scholarships: yup.array().nullable(),
        participatedProjects: yup.array().nullable(),
        computerKnowledgeLevel: yup.string().nullable(),
        workExperiencesInMonths: yup.string().required("İş deneyimi alanı zorunludur"),
        hobbies: yup.array().required("Hobiler alanı zorunludur"),
    })

    const initialValues: ICandidateInformationInput = {

        onboardingFormStep: values?.onboardingFormStep ?? 1,

        hobbies: values?.hobbies ?? [],
        languages: values?.languages ?? [],
        references: values?.references ?? [],
        certificates: values?.certificates ?? [],
        scholarships: values?.scholarships ?? [],
        participatedProjects: values?.participatedProjects ?? [],
        programmingLanguages: values?.programmingLanguages ?? [],

        driverLicense: {
            issueDate: values?.driverLicense?.issueDate ?? null,
            licenseType: values?.driverLicense?.licenseType ?? "",
            status: values?.driverLicense?.status ?? ""
        },

        education: {
            highSchool: values?.education?.highSchool ?? [],
            higherEducation: values?.education?.higherEducation ?? [],
        },

        personalInformation: {
            name: values?.personalInformation?.name ?? "",
            surname: values?.personalInformation?.surname ?? "",
            phoneNumber: values?.personalInformation?.phoneNumber ?? "",
            birthDate: values?.personalInformation?.birthDate ?? null,
            description: values?.personalInformation?.description ?? "",

            backgroundImageUrl: values?.personalInformation?.backgroundImageUrl ?? null,
            profileImageUrl: values?.personalInformation?.profileImageUrl ?? null,

            sex: values?.personalInformation?.sex ?? "",
            gender: values?.personalInformation?.gender ?? "notSpecified",
            genderDetail: values?.personalInformation?.genderDetail ?? "",

            city: values?.personalInformation?.city ?? "",
            country: values?.personalInformation?.country ?? "",
            district: values?.personalInformation?.district ?? "",
            nationality: values?.personalInformation?.nationality ?? "",
            address: values?.personalInformation?.address ?? "",
            citizenship: values?.personalInformation?.citizenship ?? ""
        },

        smoking: values?.smoking ?? false,
        workExperiencesInMonths: values?.workExperiencesInMonths ?? 0,
        computerKnowledgeLevel: values?.computerKnowledgeLevel ?? "",

    }

    return ({
        validationSchema,
        initialValues,
        defaultValues,
        setDefaultValues
    })
}

