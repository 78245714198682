import * as yup from "yup"
import { Formik } from 'formik'
import { AnimatePresence, motion } from "framer-motion";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material'





const validationSchema = yup.object().shape({
    highSchool: yup.string().nullable(),
})


const defaultValues = {
    highSchool: "",
}


interface IHighSchoolsFormProps {
    onSubmit: (e: string) => any,
    onDeleteItem: (e: any) => void,
    list: Array<string>,
    type: string
}


export const HighSchoolsForm = ({ onSubmit, list, onDeleteItem, type }: IHighSchoolsFormProps) => {


    return <>


        <Formik
            initialValues={defaultValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
                onSubmit(values.highSchool)
                resetForm()
            }}
        >
            {
                ({ handleSubmit, values, setFieldValue }) => {
                    return (
                        <Grid container spacing={3} >

                            {type !== "account-page" && <Grid item xs={12}>
                                <Typography variant="h3">
                                    Lise Bilgileri
                                </Typography>
                            </Grid>}

                            <Grid item sx={{ flex: 1 }}>
                                <Stack direction="row" gap={2} alignContent="stretch">

                                    <input
                                        placeholder="Lise adı"
                                        value={values.highSchool}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setFieldValue("highSchool", value.charAt(0).toUpperCase() + value.slice(1))
                                        }}
                                        style={{
                                            height: "50px",
                                            padding: '10px 20px',
                                            flex: 1,
                                            outline: 'none',
                                            border: "1px solid #ccc",
                                        }}
                                    />

                                    <Button
                                        onClick={() => handleSubmit()}
                                        color="primary"
                                        variant="outlined"
                                        sx={{
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        Ekle
                                    </Button>

                                </Stack>
                            </Grid>

                        </Grid>
                    )
                }
            }
        </Formik>

        <Stack direction="row" gap={2} sx={{ mt: 3 }} flexWrap="wrap">
            <AnimatePresence mode="popLayout" >
                {
                    list.map((highSchool, index) => {

                        return (
                            <motion.div

                                layout
                                initial={{ scale: 0.8, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1 }}
                                exit={{ scale: 0.8, opacity: 0 }}
                                transition={{ type: "spring" }}
                                key={highSchool}
                                style={{ position: 'relative' }}
                            >

                                <Button variant="contained" color="secondary" >
                                    {highSchool}
                                </Button>

                                <Box
                                    onClick={() => onDeleteItem(index)}
                                    sx={{
                                        bgcolor: 'primary.main',
                                        position: 'absolute',
                                        top: "-8px",
                                        right: "-8px",
                                        borderRadius: '100%',

                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',

                                        '&:hover': {
                                            transform: 'scale(1.05)'
                                        },
                                        '&:active': {
                                            transform: 'scale(1)'
                                        },
                                    }}
                                >
                                    <CloseRoundedIcon sx={{ width: '20px', height: '20px', color: '#fff' }} />
                                </Box>

                            </motion.div>

                        )
                    })
                }

            </AnimatePresence>
        </Stack>
    </>

    // {/* <Stack
    //             direction="row"
    //             gap={2}
    //             sx={{
    //                 mt: 3,
    //             }}
    //         >
    //             {
    //                 list.map((highSchool, index) => {

    //                     return (
    //                         <Box key={highSchool} sx={{ position: 'relative' }} >

    //                             <Button variant="contained">
    //                                 {highSchool}
    //                             </Button>

    //                             <Box
    //                                 onClick={() => onDeleteItem(index)}
    //                                 sx={{
    //                                     bgcolor: '#961650',
    //                                     position: 'absolute',
    //                                     top: "-8px",
    //                                     right: "-8px",
    //                                     borderRadius: '100%',

    //                                     display: 'flex',
    //                                     alignItems: 'center',
    //                                     justifyContent: 'center',
    //                                     cursor: 'pointer',

    //                                     '&:hover': {
    //                                         transform: 'scale(1.05)'
    //                                     },
    //                                     '&:active': {
    //                                         transform: 'scale(1)'
    //                                     },
    //                                 }}
    //                             >
    //                                 <CloseRoundedIcon sx={{ width: '20px', height: '20px', color: '#fff' }} />
    //                             </Box>

    //                         </Box>
    //                     )
    //                 })
    //             }
    //         </Stack> */}

}
