import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Skeleton, Stack, Typography } from '@mui/material';

import { routes } from 'routes';
import { DashboardLayout } from 'layouts'
import { Iconify, MuiTransition, OzgelecekCard } from 'components';
import { resetCandidateCreateOzgelecekForm, useAppDispatch, useAppSelector, useGetCandidateOzgeleceksQuery } from 'context';
import moment from 'moment';



export const CandidateOzgeleceksPage = () => {

    const { device } = useAppSelector((state: any) => state?.auth)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [variables, setVariables] = useState({
        filter: {
            field: null
        },
        pagination: {
            page: null,
            pageSize: null,
            sort: null
        }
    })
    const [ozgelecekDeleteDialog, setOzgelecekDeleteDialog] = useState<any>(null)
    const { data: ozgeleceks, isFetching } = useGetCandidateOzgeleceksQuery({ variables })
    const [ozgelecekUpdateDialog, setOzgelecekUpdateDialog] = useState<any>(null)

    return (
        <DashboardLayout>

            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant='h1'>
                    Özgeleceklerim
                </Typography>
                {device !== "mobile" && <Button variant='contained' color="primary" onClick={() => {
                    dispatch(resetCandidateCreateOzgelecekForm({}))
                    navigate(routes.candidate.dashboard.ozgelecekCreate)
                }}>
                    Özgelecek Oluştur
                </Button>}
            </Stack>

            <Box bgcolor={"secondary.lighter"} p={4} mt={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}><Iconify icon={"material-symbols:info-outline"} /></Box>
                <Typography variant="body2" >
                    Bu sayfada özgeleceklerinizi görüntüleyebilir, yeni özgelecek oluşturabilir, özgeleceklerinizi düzenleyip silebilirsiniz.
                    (Özgeleceklerinizi düzenlemek veya silmek için oluşturulma tarihinden en az 3 ay geçmesi gerekmektedir.)
                </Typography>
            </Box>


            <Grid container spacing={3} sx={{ mt: 3 }}>

                {
                    isFetching ? (

                        [1, 2, 3, 4]?.map((item) => {

                            return (
                                <Grid key={item} item xs={12} sm={6} lg={4} xl={3}  >
                                    <Skeleton

                                        variant='rectangular'
                                        sx={{
                                            p: 2,
                                            width: '100%',
                                            height: '300px'
                                        }}
                                    />
                                </Grid>
                            )
                        })

                    ) : (
                        Number(ozgeleceks?.count) > 0 ? (

                            ozgeleceks?.data?.map((item, index) => {

                                return (
                                    <Grid item xs={12} sm={6} lg={4} xl={3} key={index}>
                                        <OzgelecekCard
                                            ozgelecek={item}
                                            onDelete={() => setOzgelecekDeleteDialog(item)}
                                            onUpdate={() => setOzgelecekUpdateDialog(item)}
                                        />
                                    </Grid>
                                )
                            })

                        ) : (
                            <Grid item xs={12} >
                                Henüz bir özgelecek oluşturulmadı.
                            </Grid>
                        )

                    )
                }

            </Grid>


            {/* ***************  UPDATE ************** */}
            <Dialog
                open={!!ozgelecekUpdateDialog}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setOzgelecekUpdateDialog(null)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Özgelecek Güncelleme</DialogTitle>

                <DialogContent>
                    <Typography
                        sx={{
                            '& span': {
                                color: 'error.main',
                                fontWeight: 'bold',
                            }
                        }}
                    >
                        <span>
                            {moment(new Date(ozgelecekUpdateDialog?.updatedAt)).add(3, 'months').format('DD MMMM YYYY')}
                        </span> tarihinde önce güncellenemez.
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={() => setOzgelecekUpdateDialog(null)}
                        variant='outlined'
                        color="primary"
                    >
                        Kapat
                    </Button>

                    {/* <Button
                        onClick={() => {
                            dispatch(resetCandidateCreateOzgelecekForm({}))
                            navigate(routes.candidate.dashboard.ozgelecekUpdate + "?field=" + ozgelecekUpdateDialog?.field + "&id=" + ozgelecekUpdateDialog._id)
                        }}
                        variant='contained'
                        color="primary"
                    >
                        Güncelle
                    </Button> */}
                </DialogActions>
            </Dialog>




            {/* ***************  DELETE ************** */}
            <Dialog
                open={!!ozgelecekDeleteDialog}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setOzgelecekDeleteDialog(null)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Özgelecek Silme</DialogTitle>

                <DialogContent>
                    <Typography
                        sx={{
                            '& span': {
                                color: 'error.main',
                                fontWeight: 'bold',
                            }
                        }}
                    >
                        <span>
                            {moment(new Date(ozgelecekDeleteDialog?.updatedAt)).add(3, 'months').format('DD MMMM YYYY')}
                        </span> tarihinde önce silinemez.
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={() => {
                            setOzgelecekDeleteDialog(null)
                        }}
                        variant='outlined'
                        color="primary"
                    >
                        Kapat
                    </Button>

                    {/* 
                    <Button 
                    onClick={handleCreateReference}
                      variant='contained'
                        color="primary"
                    >
                        Create
                    </Button> 
                    */}
                </DialogActions>
            </Dialog>


        </DashboardLayout>
    )
}
