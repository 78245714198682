
import { Box, Container, Stack } from '@mui/material'
import { IAuthState, getOnboardingStatus, useAppDispatch, useAppSelector } from 'context'
import { useEffect } from 'react'
import { routes } from 'routes'

export const DashboardLayoutMobile = ({ children }: any) => {


    // const dispatch = useAppDispatch()
    // const { userType, isOnboarding, }: IAuthState = useAppSelector(state => state?.auth)


    // useEffect(() => {
    //     if (userType) {
    //         dispatch(getOnboardingStatus({ userType }))
    //     }
    // }, [dispatch, userType])


    // let count = 1;
    // useEffect(() => {
    //     if (isOnboarding !== 'completed' && count === 1) {
    //         children.props.navigate(routes.candidate.onboarding.mainMobile)
    //         count = 2
    //     }
    // }, [isOnboarding, userType])


    return (
        <Stack direction={{ xs: "column", md: "row" }} sx={{ height: '100%' }} >


            {/* ******************   menü ********************** */}
            <>
                {/* <Box sx={{ height: '100%', display: { xs: 'none', md: 'block' } }}>
                    <NavDefault
                        open={open}
                        anchorEl={anchorEl}
                        navData={renderDashboardMenu()}
                        handleClose={handleClose}
                        handleClick={handleClick}
                        handleLogout={handleLogout}
                        handleAccount={handleAccount}
                        handleSettings={handleSettings}
                    />
                </Box>



                <AppBar position="relative" sx={{ display: { xs: 'block', md: 'none' }, boxShadow: '1px 1px 5px 0px #ccc', py: 1 }}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >

                        <IconButton
                            onClick={() => setMobileDrawer(true)}
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>

                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                zIndex: -3,
                            }}
                        >
                            <Box
                                component="img"
                                src={Logo}
                                sx={{
                                    width: '100px'
                                }}
                            />
                        </Stack>

                   
                        <Box sx={{ display: { xs: 'block', md: 'none' } }} style={{ zIndex: 1000 }}>
                            <NotificationDrawer />
                        </Box>

                    </Toolbar>
                </AppBar>

                <Drawer
                    anchor="left"
                    open={mobileDrawer}
                    onClose={() => setMobileDrawer(false)}
                >
                    <NavDefault
                        open={open}
                        anchorEl={anchorEl}
                        navData={renderDashboardMenu()}
                        handleClose={handleClose}
                        handleClick={handleClick}
                        handleLogout={handleLogout}
                        handleAccount={handleAccount}
                        handleSettings={handleSettings}
                    />
                </Drawer> */}
            </>


            {/* ******************   main sections ********************** */}
            <Box sx={{ overflow: 'auto', width: '100%', height: '100%' }}>
                <Container sx={{ p: 2, position: 'relative', pb: 20 }}>

                    {/* <Box sx={{ position: 'absolute', right: 20, top: 30, display: { xs: 'none', md: 'block' } }}>
                        <NotificationDrawer />
                    </Box> */}

                    {children}
                </Container>
            </Box>

        </Stack>
    )

}
