export const user_parameters = ({ variables }: any) => {

    const data = JSON.stringify({
        query: `
        query User_parameters($pagination: Pagination, $filter: FilterParameterInput) {
            user_parameters(pagination: $pagination, filter: $filter) {
              universityDepartments {
                data {
                  name
                }
                count
              }
              universities {
                data {
                  name
                }
                count
              }
              positions {
                count
                data {
                  name
                }
              }
              languages {
                data {
                  name
                  metadata {
                    code
                    nativeName
                  }
                }
                count
              }
              languageLevels {
                data {
                  name
                }
                count
              }
              industries {
                data {
                  name
                }
                count
              }
              highSchoolDepartments {
                data {
                  name
                }
                count
              }
              companyDepartments {
                count
                data {
                  name
                }
              }
            }
          }
        `,
        variables: {
            filter: {
                "name": variables?.filter?.name ?? null
            },
            pagination: {
                "page": variables?.pagination?.page ?? null,
                "pageSize": variables?.pagination?.pageSize ?? null,
                "sort": variables?.pagination?.sort ?? null
            }
        }
    })

    return data
}
