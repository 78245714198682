// import PropTypes from 'prop-types';
import { FC, ReactElement } from 'react';
import { FormProvider as Form } from 'react-hook-form';


type PropTypes = {
  children: ReactElement | any,
  methods: any,
  onSubmit: (e: any) => void,
}

// ----------------------------------------------------------------------

export const FormProvider: FC<PropTypes> = ({ children, onSubmit, methods }) => {
  return (
    <Form {...methods}>
      <form onSubmit={onSubmit}>{children}</form>
    </Form>
  );
}

// FormProvider.propTypes = {
//   children: PropTypes.node,
//   methods: PropTypes.object,
//   onSubmit: PropTypes.func,
// };
