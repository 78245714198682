
export interface ICompanyApplicationStatisticsResponse {
  viewedCount: number;
  unsuitableCount: number;
  suitableCount: number;
  pendingCount: number;
  pendingAsBlacklistedCount: number;
}


export const company_applicationStatistics = () => {

  const data = JSON.stringify({
    query: `query Company_applicationStatistics {
      company_applicationStatistics {
        viewedCount
        unsuitableCount
        suitableCount
        pendingCount
        pendingAsBlacklistedCount
      }
    }`
  })


  return data

}