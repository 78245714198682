import HasTimeStamp from "interfaces/HasTimeStamp";
import { HasOptionalUserAudit } from "interfaces/HasUserAudit";
import IEntity from "interfaces/IEntity";
import { Role } from "types/_role";


export interface ISelfUserResponse extends IEntity, HasTimeStamp, HasOptionalUserAudit {
    role: Role,
    credit: {
      credit: {
        total: number
        usable: number
      }
    }
    registeredBy?: IEntity;
    billingInfo?: {
      buyer: {
        name: string;
        surname: string;
        identityNumber: string;
        city: string;
        country: string;
        email: string;
        gsmNumber?: string;
        ip: string;
        registrationAddress: string;
        zipCode?: string;
        registrationDate?: string;
        lastLoginDate?: string;
      };
      billingAddress: {
        fullName: string;
        companyName?: string;
        phoneNumber?: string;
        email: string;
        country: string;
        city: string;
        district?: string;
        address1: string;
        address2?: string;
        postcode: string;
        taxIdentificationNumber?: string;
        taxOffice?: string;
      };
    }
}

export interface ISelfUserVariables { }


export const self_user = (variables: ISelfUserVariables) => {

    const data = JSON.stringify({
        query: `
          query self_user {
            self_user {
              _id
              role
              displayName
              displayImageUrl
              credit {
                credit {
                  total
                  usable
                  locked
                  totalSpent
                }
              }
              billingInfo {
                buyer {
                  name
                  surname
                  identityNumber
                  city
                  country
                  email
                  gsmNumber
                  ip
                  registrationAddress
                  zipCode
                  registrationDate
                  lastLoginDate
                }
                billingAddress {
                  fullName
                  companyName
                  phoneNumber
                  email
                  country
                  city
                  district
                  address1
                  address2
                  postcode
                  taxIdentificationNumber
                  taxOffice
                }
              }
              registeredBy {
                _id
              }
              createdBy {
                _id
              }
              updatedBy {
                _id
              }
              createdAt
              updatedAt
            }
          }
        `,
        variables: variables
    })

    return data
}
