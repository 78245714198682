import { IAuthState } from 'context';
import { TMicroService } from 'types';
import { AUTH_SLICE_LOCALSTORAGE_NAME } from 'utils';



export const getAccessToken = () => {
    const localAuthInfo: IAuthState = JSON.parse(localStorage.getItem(AUTH_SLICE_LOCALSTORAGE_NAME) ?? "{}")
    const accessToken = localAuthInfo?.accessToken ?? ""
    return accessToken
}


interface IGraphlAxiosConfig {
    microservice?: TMicroService,
    data: any
}

export const graphqlAxiosConfig = ({ microservice = "user", data }: IGraphlAxiosConfig) => {
    return ({
        method: "post",
        baseURL: `${process.env.REACT_APP_BASE_URL}/${microservice}/graphql`,
        headers: {
            'Authorization': 'Bearer ' + getAccessToken(),
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },
        data
    })
}