import { IUserNotification } from "interfaces/notifications/notification/userNotification/IUserNotification"

export interface IUserUserNotificationResponse extends IUserNotification {}

export interface IUserUserNotificationVariables { uid: string }


export const user_userNotification = (variables: IUserUserNotificationVariables) => {

  const data = JSON.stringify({
    query: `
      query user_userNotification($uid: String!) {
        user_userNotification(uid: $uid) {
          _id
          uid
          user {
            _id
           # role
            displayName
            displayImageUrl
          }
          notificationType
          notificationPreview {
            name
            subject
            content
            data {
              uid
              targetId
              target
            }
          }
          isRead
          createdAt
          updatedAt
        }
      }
    `,
    variables: variables

  })

  return data

}