import { Route } from 'react-router-dom'
import { ReferencerMainPage } from 'pages'
import { routes } from './routes'



export const referencerRouter = () => {

    return (
        <>
        
            {/* <Route path={routes.referencer.main} element={<ReferencerMainPage />} /> */}
        </>
    )
}
