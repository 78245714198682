import { useFormikContext } from 'formik'
import { useParams } from 'react-router-dom'
import { FC, useEffect, useState } from 'react'
import { Button, Card, Divider, Grid, Skeleton, Stack, Typography, Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'


import {
    useAppSelector,
    useGetSelfUserQuery,
    useGetCompanyInformationQuery,
    useLazyGetAdvertisementCostQuery,
    useGetCompanyAdvertisementDetailQuery,
    useLazyGetCompanyMatchingOzgelecekPriceQuery,
    useLazyGetCompanyMatchingOzgelecekCountQuery,
    ICompanyMatchingOzgeleceksVariables,
} from 'context'
import { useNavigate } from 'react-router-dom'
import { Iconify } from 'components'
import { routes } from 'routes';
import { CompanyAdvertisementCard } from 'components'
import { FormikSelect2, FormikSwitch2 } from 'components'
import { ICreateAdvertisementForm } from 'lib/form-validation'



interface ICreateAdvertisementFormProps {
    // buttonLoading: boolean
    // images: Array<any>,
    // onEdit: (e: any) => void
    // onShow: (e: any) => void
}

export const CreateAdvertisementPreview: FC<ICreateAdvertisementFormProps> = () => {

    const { id } = useParams()
    const { device } = useAppSelector(state => state.auth)

    const navigate = useNavigate()
    const { setFieldValue, values, handleSubmit } = useFormikContext<ICreateAdvertisementForm>()
    const { advertisementId } = useAppSelector((state) => state.companyCreateAdvertisement)
    const { data: company } = useGetCompanyInformationQuery({});
    const [getCompanyMatchingOzgelecekCount, { isFetching: isFetchingCount }] = useLazyGetCompanyMatchingOzgelecekCountQuery()
    const { data: advertisement } = useGetCompanyAdvertisementDetailQuery({ advertisementId: advertisementId ?? "" })
    const { data: user } = useGetSelfUserQuery({})
    const [getCost, { isFetching: isFetchingCost }] = useLazyGetAdvertisementCostQuery()
    const [cost, setCost] = useState(0)
    const [doping, setDoping] = useState(false)
    const [dopingQuantity, setDopingQuantity] = useState(0)
    const [emergent, setEmergent] = useState(false)
    const [showcase, setShowcase] = useState(false)
    const [getCompanyMatchingOzgelecekPrice, { isFetching: isFetchingPrice }] = useLazyGetCompanyMatchingOzgelecekPriceQuery()
    const [ozgelecekPrice, setOzgelecekPrice] = useState<number | null>(null)
    const [ozgelecekCount, setOzgelecekCount] = useState<number | null>(null)
    const [statusReady, setStatusReady] = useState(false)


    const [variables, setVariables] = useState<ICompanyMatchingOzgeleceksVariables>(
        {
            filter: {
                field: null,
                motivationTestResults: null,
                languages: null,
                higherEducationDegree: null,
                candidateSex: null,
                candidateCity: null,
                areaTestResults: null,
            },
            pagination: {
                page: 1,
                pageSize: 10
            }
        }
    )

    const handleCreate = () => {
        setFieldValue("advertisementId", advertisementId);
        if ((values?.status === "pendingApproval")) setFieldValue("status", "pending")
        else if (values?.status === "approved") setFieldValue("status", "pendingApproval")
        else setFieldValue("status", "onboardingForthCompleted")

        setStatusReady(true)
    }

    useEffect(() => {
        if (statusReady) {
            handleSubmit();
        }
    }, [statusReady])

    // const handleExtraProductChange = () => {
    //     const extraProducts = [];
    //     if (doping) {
    //         for (let i = 0; i < dopingQuantity; i++) {
    //             extraProducts.push("doping");
    //         }
    //     }
    //     if (emergent) {
    //         extraProducts.push("emergent");
    //     }
    //     if (showcase) {
    //         extraProducts.push("showcase");
    //     }
    //     setFieldValue("extraProducts", extraProducts);

    //     // handleSubmit();
    // }

    const handleMatchingsClick = async () => {
        const ozgelecekCount = await getCompanyMatchingOzgelecekCount(variables);
        const ozgelecekPrice = await getCompanyMatchingOzgelecekPrice({});


        if (ozgelecekPrice) {
            console.log(ozgelecekPrice)
            setOzgelecekPrice(ozgelecekPrice?.data ?? null)
        }
        if (ozgelecekCount) {
            setOzgelecekCount(ozgelecekCount?.data ?? null)
        }
    }

    const handleMatchingOzgelecek = () => {
        setFieldValue("status", "pendingApproval")
        handleSubmit();
        navigate(routes.company.dashboard.radar,
            {
                state: { field: values.field }
            }
        )
    }

    useEffect(() => {
        if (values.extraProducts?.length > 0 && id) {
            console.log(values?.extraProducts)

            const newValues = {
                ...values,
                doping: values.extraProducts.includes("doping"),
                dopingQuantity: values.extraProducts.filter((product: string) => product === "doping").length,
                emergent: values.extraProducts.includes("emergent"),
                showcase: values.extraProducts.includes("showcase")
            };

            console.log(values?.extraProducts.includes("doping"), 'azzz')
            setDoping(values?.extraProducts.includes("doping"))
            setDopingQuantity(newValues.dopingQuantity);
            setEmergent(newValues.emergent);
            setShowcase(newValues.showcase);
        }
    }, [id, values])


    useEffect(() => {

        (async () => {
            if (values?.extraProducts && values?.productType) {
                const cost = await getCost({ productType: values?.productType, extraProductTypes: values?.extraProducts, onlyForExtraProduct: false })

                setCost(cost?.data?.price ?? 0)
            }
        })();

    }, [values?.productType, values?.extraProducts, getCost])

    return (
        <Card sx={{ p: 3 }}>


            <Grid container spacing={3}>


                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">İlan Önizlemesi</Typography>
                        <Stack direction={"column"} spacing={1} alignItems="flex-end">
                            <Button color='primary' variant='contained'
                                onClick={handleCreate}>
                                İlanı {(values?.status === "pending" || values?.status === "pendingApproval" || values?.status === "approved") ? "Güncelle" : "Yayınla"} </Button>
                        </Stack>
                    </Stack>



                    {(values?.status !== "pending" && values?.status !== "pendingApproval" && values?.status !== "approved") ? (!isFetchingCost ? <Typography fontSize={"12px"} textAlign={"right"} fontWeight={"bold"} color="textSecondary" sx={{ mt: 2 }}>Kullanılacak Kredi : {cost} / Toplam Kredi : {user?.credit?.credit?.total}</Typography> :
                        <Skeleton variant="text" sx={{ textAlign: "right" }} width={200} height={20} />) : <br />}
                    <Divider sx={{ mb: 4, }} />

                    {/* <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} mb={2}>
                        <Box bgcolor={"secondary.lighter"} px={3} py={1}
                            onClick={handleMatchingsClick}
                            sx={{
                                cursor: "pointer",
                                animation: 'shadowPulse 2s infinite',
                                '@keyframes shadowPulse': {
                                    '0%': {
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.04)',
                                        transform: 'scale(1)'
                                    },
                                    '50%': {
                                        boxShadow: '0 8px 12px rgba(0, 0, 0, 0.15)',
                                        transform: 'scale(1.01)'
                                    },
                                    '100%': {
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.04)',
                                        transform: 'scale(1)'
                                    },
                                },
                            }}>
                            <Typography variant="subtitle2" textAlign={"center"}>İlanınızla eşleşen adaylar bulundu. 5 tanesini hemen görüntüleyebilirsiniz.</Typography>
                        </Box>
                    </Grid> */}

                    <CompanyAdvertisementCard
                        key={1}
                        logo={company?.companyInformation?.profileImage ?? ""}
                        advertisement={advertisement}
                        type="preview"
                        onShow={() => { }}
                        onEdit={() => { }}
                    />

                    {device !== "mobile" ? <Button color='primary' variant='outlined' sx={{ mt: 4 }}
                        onClick={() => window.location.pathname = routes.company.dashboard.updateAdvertisement + "/" + advertisementId}>
                        Geri Dön</Button> :
                        <Button color='primary' variant='outlined' sx={{ mt: 4 }}
                            onClick={() => navigate(routes.company.dashboard.updateAdvertisementMobile + "/" + advertisementId)}>
                            Geri Dön</Button>}

                </Grid>



                {/* <Grid item xs={12} >
                    <Typography variant="subtitle1" color="textSecondary">Ekstra Özellikler</Typography>
                    <Divider />

                    <Typography mt={2} variant="subtitle1" color="primary" display={"flex"} alignItems={"center"} gap={2}><Iconify icon="ri:medicine-bottle-line" width={26} /> Doping ilan
                        <FormikSwitch2 onChange={(checked: boolean) => setDoping(checked) as any} checked={doping} />

                        {doping && <FormikSelect2 name="dopingQuantity" label={"Doping Miktarı"} sx={{ width: 115 }} size='small' disabled={!doping}
                            defaultValue={dopingQuantity} onChange={(value: number) => setDopingQuantity(value) as any}
                            list={[{ value: 1, name: "1" }, { value: 2, name: "2" }, { value: 3, name: "3" }, { value: 4, name: "4" }, { value: 5, name: "5" }]}
                        />}</Typography>
                    <Typography fontStyle={"italic"} variant="body2" color="textSecondary">Doping ilan kullanarak diğer ilanlardan daha önde ilanınızı yayınlatabilirsiniz.</Typography>

                    <Typography mt={4} variant="subtitle1" color="primary" display={"flex"} alignItems={"center"} gap={2}><Iconify icon="fluent:alert-urgent-16-regular" width={26} /> Acil ilan
                        <FormikSwitch2 onChange={(checked: boolean) => setEmergent(checked) as any} checked={emergent} />

                    </Typography>
                    <Typography fontStyle={"italic"} variant="body2" color="textSecondary">Acil ilan kullanarak adayların dikkatini daha çabuk çekebilirsiniz.</Typography>

                    <Typography mt={4} variant="subtitle1" color="primary" display={"flex"} alignItems={"center"} gap={2}><Iconify icon="material-symbols:photo-frame-outline" width={26} /> Vitrin ilan
                        <FormikSwitch2 onChange={(checked: boolean) => setShowcase(checked) as any} checked={showcase} />
                    </Typography>
                    <Typography fontStyle={"italic"} variant="body2" color="textSecondary">Vitrin ilan kullanarak ilanınızı daha fazla adaya gösterebilirsiniz.</Typography>

                    <Button sx={{ mt: 4 }} color='primary' variant='outlined'
                        onClick={handleExtraProductChange}>Ekstra Ürünleri Güncelle</Button>
                </Grid> */}




            </Grid>

            <Dialog open={!!ozgelecekPrice} onClose={() => {
                setOzgelecekCount(null)
                setOzgelecekPrice(null)
            }}>
                <DialogTitle id="simple-dialog-title">Eşleşen adayların kilidini açmak istiyor musunuz?</DialogTitle>
                <DialogContent >
                    {ozgelecekCount && ozgelecekCount > 4 ? <Typography fontSize={"medium"} mb={2}>
                        Kriterlere uygun adaylardan <strong>10 tanesini</strong> hemen görüntüleyebilirsiniz. Bu işlem için <strong>{ozgelecekPrice}</strong> kredi kullanılacaktır.
                    </Typography> :
                        <Typography fontSize={"medium"} mb={2}>
                            Kriterlere uygun yeterli sayıda aday bulunamamıştır. Lütfen kriterleri değiştirerek tekrar deneyiniz.
                        </Typography>}


                </DialogContent>
                <DialogActions sx={{ justifyContent: "space-between" }}>
                    <Typography fontSize={"small"} fontWeight={"light"} mt={2} >
                        *Toplam Krediniz : <strong>{user?.credit?.credit?.total ?? 0}</strong>
                    </Typography>
                    <Stack direction={"row"} spacing={2}>
                        <Button onClick={() => {
                            setOzgelecekCount(null)
                            setOzgelecekPrice(null)
                        }}
                            color="primary" variant='outlined'>
                            İptal
                        </Button>
                        <Button onClick={() => {
                            handleMatchingOzgelecek()
                            setOzgelecekCount(null)
                            setOzgelecekPrice(null)
                        }} color="primary"
                            disabled={false}
                            variant='contained'>
                            {"Onayla"}
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>

        </Card >
    )
}
