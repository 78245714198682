
export const enumFunctions = {

    objectlist: function objectlist(e: Object): Object[] {
        const values = Object.entries(e).map((item: any) => ({
            value: item[0],
            name: item[1]
        }));
        return values;
    },

    findAndDisplayName: (e: Object, value: string) => {
        const newObject = Object.entries(e).map((item: any) => ({
            value: item[0],
            name: item[1]
        }));

        const data = newObject.find((item: any) => item.value === value)
        return data?.name
    },


    /**
     *
     * @param e an object with type `enum`
     * @returns string enumeration values of enum `e`
     */
    values: function values(e: Object): string[] {
        const values = Object.keys(e).map((item: string) => {
            return (e as any)[item];
        });
        return values;
    },


    contains: function contains(e: Object, type: string): boolean {
        return (Object as any).values(e).includes(type);
    },

    index: function index(e: Object, status: string): number {
        return (Object as any).values(e).indexOf(status);
    },

    valueCount: function valueCount(e: Object): number {
        return (Object as any).values(e).length;
    },

    next: function next<T>(e: Object, index: number): T | undefined {
        if (index >= this.valueCount(e) - 1) {
            return undefined;
        }
        return (Object as any).values(e).at(index + 1);
    },
}