import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import { Box, Button, Chip, Divider, LinearProgress, Stack, Typography } from '@mui/material'

import { LogoCrop } from 'assets';
import { enumFunctions } from 'utils';
import { ICandidateAdvertisementsItem } from 'context';
import { OzgelecekFieldEnum, PlaceOfWorkEnums, PositionLevelEnums, WorkTypeEnums } from 'types';
import { LocalFireDepartment } from '@mui/icons-material';

interface ICandidateAdvertisementCardProps {
    advertisement: ICandidateAdvertisementsItem,
    onApply: (e?: any) => void,
    onReject?: (e?: any) => void,
    onRevise?: (e?: any) => void,
    onClick?: (e?: any) => void,
}

export const CandidateAdvertisementCard = ({ advertisement, onApply, onClick, onReject }: ICandidateAdvertisementCardProps) => {



    return (
        <Stack
            direction={{ xs: 'column', sm: "row" }}
            spacing={{ xs: 3, sm: 2 }}
            onClick={onClick}
            // alignItems={{ xs: 'center', sm: "initial" }}

            sx={{
                border: '1px solid #d6ddeb',
                p: 3,
                backgroundColor: '#fff',
                transition: 'all 0.3s ease',
                cursor: 'pointer',
                ":hover": {
                    boxShadow: '0 0 10px 0 #d6ddeb',
                },
            }}
        >

            <Box
                component="img"
                src={advertisement?.companyInfoVisible ?
                    advertisement?.createdBy?.companyInformation?.profileImage ?? 'https://cdn-icons-png.flaticon.com/512/5650/5650380.png' :
                    LogoCrop}
                sx={{
                    width: '60px',
                    height: '60px',
                    borderRadius: '50%',
                    objectFit: 'contain',
                    backgroundColor: '#ddd',
                    m: 'auto',
                }}
            />

            <Stack direction="column" spacing={1} flex={1} sx={{ textTransform: 'capitalize' }} textAlign={{ xs: 'center', sm: "initial" }}>
                <Stack direction="row" spacing={1}>
                    <Typography variant='subtitle1' color={'primary.darker'}>
                        {advertisement.title}
                    </Typography>
                    {advertisement?.extraProducts?.includes("emergent") &&
                        <Box ml={1} borderRadius={"20px"} bgcolor={"primary.lighter"} sx={{ boxShadow: 2 }}>
                            <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} p={1}>
                                <Typography fontSize={"small"} fontWeight={"bold"} color="primary.dark">
                                    Acil
                                </Typography>
                                <LocalFireDepartment sx={{ ml: 0.2, fontSize: '18px', color: "primary.dark" }} />
                            </Stack>
                        </Box>
                    }
                </Stack>

                <Typography variant='body1'>
                    <span style={{ color: '#000', fontWeight: 'bold' }}>
                        {enumFunctions.findAndDisplayName(OzgelecekFieldEnum, advertisement.field)}
                    </span> ·&nbsp;
                    {advertisement.locations?.map((location, index) => (
                        <span key={index}>
                            {location.city} / {location.districts.join(', ')}
                        </span>
                    ))}
                </Typography>

                <Stack direction="row" spacing={1} mt={1} flexWrap="wrap" justifyContent={{ xs: 'center', sm: "initial" }}>
                    <Chip label={enumFunctions.findAndDisplayName(PlaceOfWorkEnums, advertisement.place)} color="secondary" variant='filled' style={{ borderRadius: 100 }} sx={{ color: 'primary.main' }} />

                    <Divider orientation="vertical" variant="fullWidth" flexItem />

                    <Chip label={enumFunctions.findAndDisplayName(PositionLevelEnums, advertisement.positionLevel)} color="warning" variant="outlined" style={{ borderRadius: 100 }} />
                    <Chip label={enumFunctions.findAndDisplayName(WorkTypeEnums, advertisement.workType)} color="success" variant="outlined" style={{ borderRadius: 100 }} />
                </Stack>

            </Stack>


            <Stack direction="column" spacing={1} justifyContent="space-between">

                <Stack
                    direction="column"
                    spacing={1}
                    justifyContent="space-between"
                    flexWrap="wrap"
                    mb={1}
                    sx={{ '&>*': { flex: 1 } }}
                >
                    {
                        advertisement?.isApplied ? (
                            <Button color='error' variant='contained' sx={{ py: 1, px: 2 }}
                                onClick={onReject}
                                disabled
                            >
                                <ThumbUpAltRoundedIcon fontSize='small' sx={{ mr: 1 }} /> Başvuru yapıldı
                            </Button>
                        ) : (
                            <Button color='primary' variant='contained' sx={{ py: 1, px: 2 }}
                                onClick={onApply}
                            >
                                <ThumbUpAltRoundedIcon fontSize='small' sx={{ mr: 1 }} /> Başvur
                            </Button>
                        )
                    }
                </Stack>

                {/* {advertisement.quota <= 5 && <LinearProgress variant="determinate" value={(4 / 10) * 100} />}
                {advertisement.quota > 5 && advertisement.quota <= 10 && <LinearProgress variant="determinate" value={(5 / 10) * 100} />}
                {advertisement.quota > 10 && advertisement.quota <= 20 && <LinearProgress variant="determinate" value={(6 / 10) * 100} />}
                {advertisement.quota > 20 && advertisement.quota <= 30 && <LinearProgress variant="determinate" value={(7 / 10) * 100} />}
                {advertisement.quota > 30 && advertisement.quota <= 50 && <LinearProgress variant="determinate" value={(8 / 10) * 100} />}
                {advertisement.quota > 50 && <LinearProgress variant="determinate" value={(9 / 10) * 100} />} */}

                {!!advertisement?.quotaVisibleToApplicant && <LinearProgress variant="determinate" value={(10 / 10) * 100} />}
                {!!advertisement?.quotaVisibleToApplicant && <Typography fontWeight={'bold'} >
                    Kontenjan: {advertisement.quota}
                </Typography>}

            </Stack>

        </Stack>
    )
}
