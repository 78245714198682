import * as yup from "yup"
import { ICompanyInformation } from "types"



export const companyUpdateCompanyFormValidation = ({ values }: { values?: ICompanyInformation }) => {
    // console.log({ values });

    const validationSchema = yup.object().shape({

        personalName: yup.string().nullable().required("Ad zorunludur"),
        personalSurname: yup.string().nullable().required("Soyad zorunludur"),
        phoneNumber: yup.string().nullable().required("Telefon numarası zorunludur"),
        email: yup.string().required("Email adresi zorunludur").email("Geçerli bir email adresi giriniz"),
        companyName: yup.string().nullable().required("Firma adı zorunludur"),
        city : yup.string().nullable().required("Şehir zorunludur"),
        district: yup.string().nullable(),
        taxOffice: yup.string().nullable(),
        taxNumber: yup.string().nullable(),
        profileImage: yup.string().nullable(),
        sector: yup.string().nullable(),
        companyAddress: yup.string().nullable(),
        foundationYear: yup.string().nullable(),
        numberOfEmployees: yup.string().nullable(),
        website: yup.string().nullable().matches(/^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-]*)*$/, "Geçerli bir web sitesi adresi giriniz"),
        linkedin: yup.string().nullable().matches(/linkedin.com/, "Geçerli bir linkedin adresi giriniz"),
        instagram: yup.string().nullable().matches(/instagram.com/, "Geçerli bir instagram adresi giriniz"),
        facebook: yup.string().nullable().matches(/facebook.com/, "Geçerli bir facebook adresi giriniz"),
        companyBrief: yup.string().nullable(),

    })

    const initialValues: ICompanyInformation = {
        personalName: values?.personalName ?? "",
        personalSurname: values?.personalSurname ?? "",
        phoneNumber: values?.phoneNumber ?? "",
        email: values?.email ?? "",
        companyName: values?.companyName ?? "",
        city: values?.city ?? "",
        district: values?.district ?? "",
        taxOffice: values?.taxOffice ?? "",
        taxNumber: values?.taxNumber ?? "",
        companyBrief: values?.companyBrief ?? "",
    
        profileImage: values?.profileImage ?? "",
        sector: values?.sector ?? "",
        companyAddress: values?.companyAddress ?? "",
        foundationYear: values?.foundationYear ?? null,
        numberOfEmployees: values?.numberOfEmployees ?? null,

        website: values?.website ?? "",
        linkedin: values?.linkedin ?? "",
        instagram: values?.instagram ?? "",
        facebook: values?.facebook ?? "",
    }

    return ({
        validationSchema,
        initialValues,
    })
}

