

export const routes = {
    auth: {
        // register: "/register",
        // registerSuccessfull: "/register-successfull",
        // registerVerfiy: "/confirmation",

        // login: "/login",
        // forgotPassword: "/forgot-password",
        // forgotPasswordSuccessfull: "/forgot-password-successfull",
        // resetPassword: "/reset-password",
        register: "/kayit-ol",
        registerSuccessfull: "/kayit-basarili",
        registerVerfiy: "/dogrulama",

        login: "/giris-yap",
        forgotPassword: "/sifremi-unuttum",
        forgotPasswordSuccessfull: "/sifremi-unuttum-basarili",
        resetPassword: "/sifre-sifirlama",
        registerResendRequest: "/dogrulama-istek",

    },
    candidate: {
        onboarding: {
            main: "/onboarding",
            mainMobile: "/onboarding-m",
        },
        dashboard: {
            main: "/",
            myAccount: "/my-account",
            myReferences: "/my-references",
            aboutCompany: "/company",

            ozgeleceklerim: "/ozgeleceklerim",
            ozgelecekCreate: "/create-ozgelecek",
            ozgelecekUpdate: "/update-ozgelecek",

            advertisements: "/ilanlar",
            advertisementDetails: "/ilanlar",

            applications: "/basvurularim",

            helpCenter: "/yardim-merkezi",
            settings: "/ayarlar",

            indir: "/indir",
            logout: "/logout",


            // *********************** mobile ******************************
            mainMobile: "/-m",
            myAccountMobile: "/my-account-m",
            myReferencesMobile: "/my-references-m",
            aboutCompanyMobile: "/company-m",
            ozgeleceklerimMobile: "/ozgeleceklerim-m",
            ozgelecekCreateMobile: "/create-ozgelecek-m",
            ozgelecekUpdateMobile: "/update-ozgelecek-m",
            advertisementsMobile: "/ilanlar-m",
            advertisementDetailsMobile: "/ilanlar-m",
            applicationsMobile: "/basvurularim-m",
            helpCenterMobile: "/yardim-merkezi-m",
            settingsMobile: "/ayarlar-m",

        },
    },
    company: {
        onboarding: {
            main: "/onboarding",
            mainMobile: "/onboarding-m",
        },
        dashboard: {
            main: "/",
            myCompany: '/my-company',
            aboutCompany: "/sirket-hakkinda",
            myAdvertisements: '/my-advertisements',
            advertisementDetails: "/ilan-detayi",
            createAdvertisement: '/create-advertisement',
            updateAdvertisement: "/update-advertisement",
            applications: "/basvurular",
            applicationDetails: '/basvuru-detayi',
            radar: "/radar",
            havuz: "/aday-havuzu",
            credits: "/kredilerim",
            helpCenter: "/yardim-merkezi",
            settings: "/ayarlar",
            conversionCreate: "/kesif",
            donusum: "https://donusum.ozgelecek.net",
            logout: "/logout",

            // *********************** mobile ******************************
            mainMobile: "/-m",
            myCompanyMobile: '/my-company-m',
            aboutCompanyMobile: "/sirket-hakkinda-m",
            myAdvertisementsMobile: '/my-advertisements-m',
            advertisementDetailsMobile: "/ilan-detayi-m",
            createAdvertisementMobile: '/create-advertisement-m',
            updateAdvertisementMobile: "/update-advertisement-m",
            applicationsMobile: "/basvurular-m",
            applicationDetailsMobile: '/basvuru-detayi-m',
            radarMobile: "/radar-m",
            havuzMobile: "/aday-havuzu-m",
            creditsMobile: "/kredilerim-m",
            helpCenterMobile: "/yardim-merkezi-m",
            settingsMobile: "/ayarlar-m",
            conversionCreateMobile: "/kesif-m",


        },
    },
    common: {
        notificationsMobile: '/notifications',
    },
    referencer: {
        main: "/referencer"
    },
    anonymous: {
        main: "/"
    }
}