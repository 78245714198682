import { notificationApi } from "../notificationApi";
import { IUserReadAllUserNotificationResponse, IUserReadAllUserNotificationVariables, IUserReadUserNotificationResponse, IUserReadUserNotificationVariables, user_readAllUserNotification, user_readUserNotification } from "./mutations";
import { IUserUserNotificationResponse, IUserUserNotificationVariables, IUserUserNotificationsResponse, IUserUserNotificationsVariables, user_userNotification, user_userNotifications } from "./queries";


const commonUserApi = notificationApi.injectEndpoints({
    endpoints: (builder) => ({

        // #################### User Queries #################
        
        getUserUserNotification: builder.query<IUserUserNotificationResponse, IUserUserNotificationVariables>({
            query: (variables: IUserUserNotificationVariables) => {
                return ({
                    url: "",
                    body: user_userNotification(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.user_userNotification
            },
            providesTags: ["user_userNotification"]

        }),
        getUserUserNotifications: builder.query<IUserUserNotificationsResponse, IUserUserNotificationsVariables>({
            query: (variables: IUserUserNotificationsVariables) => {
                return ({
                    url: "",
                    body: user_userNotifications(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.user_userNotifications
            },
            providesTags: ["user_userNotifications"]

        }),


        // #################### User Mutations #################

        userReadAllUserNotifications: builder.mutation<IUserReadAllUserNotificationResponse, IUserReadAllUserNotificationVariables>({
            query: () => {
                return ({
                    url: "",
                    body: user_readAllUserNotification()
                })
            },
            transformResponse: (response: any) => {
                return response.data.user_readAllUserNotification
            },
            invalidatesTags: ["user_userNotification", "user_userNotifications"]
        }),
        userReadUserNotification: builder.mutation<IUserReadUserNotificationResponse, IUserReadUserNotificationVariables>({
            query: (variables: IUserReadUserNotificationVariables) => {
                return ({
                    url: "",
                    body: user_readUserNotification(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.user_readUserNotification
            },
            invalidatesTags: ["user_userNotification", "user_userNotifications"]
        }),
    }),
})


export const {
    // ########## queries ###############
    useGetUserUserNotificationQuery,
    useLazyGetUserUserNotificationQuery,
    
    useGetUserUserNotificationsQuery,
    useLazyGetUserUserNotificationsQuery,


    // ########## mutation ###############
    useUserReadUserNotificationMutation,
    useUserReadAllUserNotificationsMutation

} = commonUserApi