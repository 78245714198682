import { Box, Button, Pagination, Skeleton, Typography } from '@mui/material'
import { CandidateApplicationCard, Iconify, RejectApplicationDialog } from 'components'
import { ICandidateApplicationsVariables, useCandidateRemoveApplicationMutation, useGetCandidateApplicationsQuery } from 'context'
import { DashboardLayout } from 'layouts'
import { enqueueSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes'

export const CandidateApplicationsPage = () => {

    const navigate = useNavigate()
    const [rejectApplicationDialog, setRejectApplicationDialog] = useState<any>(null)

    const [variables, setVariables] = useState<ICandidateApplicationsVariables>({
        pagination: {
            page: 1,
            pageSize: 5,
        }
    })
    const { data: applications, isFetching } = useGetCandidateApplicationsQuery(variables)
    const [candidateRemoveApplication, { isLoading: rejectApplicationLoading }] = useCandidateRemoveApplicationMutation()


    const handlePageChange = (e: React.ChangeEvent<unknown>, value: number) => {
        setVariables((prevState: ICandidateApplicationsVariables) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: value
            }
        }))
    }

    const handleReject = async (item: any) => {
        try {
            const response: any = await candidateRemoveApplication({ applicationId: rejectApplicationDialog?._id })
            console.log({ response });
            if (response?.data?.success) {
                enqueueSnackbar("Başarılı şekilde iptal edildi!")
                setRejectApplicationDialog(null)
            } else {
                enqueueSnackbar("Birşeyler yanlış gitti", { variant: 'info' })
            }
        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti", { variant: 'error' })
        }
    }



    return (
        <DashboardLayout>

            <Typography variant='h1' mb={5}>
                Başvurularım
            </Typography>

            <Box bgcolor={"secondary.lighter"} p={4} mt={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}>
                    <Iconify icon={"material-symbols:info-outline"} />
                </Box>
                <Typography variant="body2" >
                    Bu sayfadan ilanlara yaptığınız başvuruları görüntüleyebilirsiniz. Dilerseniz başvurunuzu iptal edebilirsiniz.
                </Typography>
            </Box>


            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    flexDirection: 'column',
                    flex: 1,
                    mt: 5,
                }}
            >
                {
                    isFetching ? (
                        <>
                            {
                                [1, 2, 3]?.map((item) => {

                                    return (
                                        <Skeleton
                                            key={item}
                                            variant='rectangular'
                                            sx={{
                                                p: 2,
                                                width: '100%',
                                                height: '150px'
                                            }}
                                        />
                                    )
                                })
                            }
                        </>
                    ) : (

                        applications && (applications?.count > 0) ? (
                            <>
                                {
                                    applications?.data?.map((item: any, index: any) => {

                                        return (
                                            <CandidateApplicationCard
                                                key={index}
                                                application={item}
                                                onClick={() => navigate('/ilanlar/' + item?.advertisement?._id)}
                                                onReject={() => setRejectApplicationDialog(item)}
                                            // onRevise={() => null}
                                            />
                                        )
                                    })
                                }

                                {
                                    Math.ceil(applications?.count / variables?.pagination?.pageSize) > 1 ? (
                                        <Pagination
                                            count={Math.ceil(applications?.count / variables?.pagination?.pageSize)}
                                            color="primary"
                                            page={variables?.pagination?.page}
                                            onChange={handlePageChange}
                                            sx={{
                                                m: 'auto',
                                                my: 5
                                            }}
                                        />
                                    ) : null
                                }
                            </>
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    my: 5,
                                    gap: 3
                                }}
                            >
                                <Typography>
                                    Henüz başvurunuz bulunmamaktadır.
                                </Typography>

                                <Button
                                    onClick={() => navigate(routes.candidate.dashboard.advertisements)}
                                >
                                    İlanlara Git
                                </Button>
                            </Box>
                        )

                    )
                }
            </Box>


            <RejectApplicationDialog
                button={false}
                loading={rejectApplicationLoading}
                onClick={handleReject}
                applyDialog={rejectApplicationDialog}
                setApplyDialog={setRejectApplicationDialog}
            />

        </DashboardLayout>
    )
}
