import { Box, Typography } from '@mui/material'
import { Iconify } from 'components'
import { DashboardLayout, DashboardLayoutMobile } from 'layouts'
import React from 'react'
import { HelpCenterSection } from 'sections'

export const CandidateHelpCenterPageMobile = () => {
    return (
        <DashboardLayoutMobile>

            <Typography variant='h1' mb={3}>
                Yardım Merkezi
            </Typography>


            <Box bgcolor={"secondary.lighter"} p={2} mb={5} mt={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                {/* <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}>
                    <Iconify icon={"material-symbols:info-outline"} />
                </Box> */}
                <Typography variant="body2" >
                    Bu sayfada özgelecek sistemi ile ilgili kafanıza takılan sorulara cevaplar bulabilirsiniz.
                    Farklı bir sorunuz olursa <span style={{ fontWeight: 'bold', color: 'purple' }}>iletisim@ozgelecek.net</span>&nbsp;
                    mail adresi üzerinden iletişime geçebilirsiniz.
                </Typography>
            </Box>



            <HelpCenterSection list={[]} />


        </DashboardLayoutMobile>
    )
}
