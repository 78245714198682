


export interface ICompanyRemoveCustomApplicationListResponse {
    success: boolean
}
export interface ICompanyRemoveCustomApplicationListVariables {
    customApplicationListId: string
}


export const company_removeCustomApplicationList = (variables: ICompanyRemoveCustomApplicationListVariables) => {

    const data = JSON.stringify({
        query: `
        mutation Company_removeCustomApplicationList($customApplicationListId: ID!) {
            company_removeCustomApplicationList(customApplicationListId: $customApplicationListId) {
              success
            }
          }
        `,
        variables: {
            "customApplicationListId": variables?.customApplicationListId ?? null
        }
    })

    return data

}