import moment from 'moment';
import { Delete, Edit, RemoveRedEye } from '@mui/icons-material';
import { Box, Button, Chip, Divider, LinearProgress, Stack, Typography } from '@mui/material'

import { enumFunctions } from 'utils';
import { ExtraProductEnums, OzgelecekFieldEnum, PositionLevelEnums, ProductTypeEnums, WorkTypeEnums, getNameOfEnum } from 'types';


interface ICompanyAdvertisementCardProps {
    advertisement: any,
    logo: string,
    type?: "preview",
    onShow: (event?: any) => void
    onEdit: (event?: any) => void
    onDelete?: (event?: any) => void
    onExtra?: (event?: any) => void
}

export const CompanyAdvertisementCard = ({ advertisement, logo, type, onEdit, onShow, onDelete, onExtra }: ICompanyAdvertisementCardProps) => {

    return (
        <Stack
            direction={{ xs: 'column', sm: "row" }}
            spacing={{ xs: 3, sm: 2 }}
            sx={{
                width: '100%',
                opacity:
                    advertisement?.status === "approved" || type === "preview" ? 1 :
                        advertisement?.status === "pendingApproval" || type === "preview" ? 1 :
                            0.7,
                border: '1px solid #d6ddeb',
                p: 3,
                backgroundColor: '#fff',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 2
            }}
        >
            <Box
                component="img"
                src={logo ?? 'https://cdn-icons-png.flaticon.com/512/5650/5650380.png'}
                sx={{
                    width: '80px',
                    height: '80px',
                    borderRadius: '40px',
                    objectFit: 'contain',
                    borderWidth: '3px',
                    borderColor: '#ddd',
                    borderStyle: 'solid'
                }}
            />


            <Stack direction="column" spacing={1} flex={1} textAlign={{ xs: 'center', sm: 'initial' }}>
                <Stack flexDirection={"row"} alignItems="center" justifyContent={{ xs: 'center', sm: 'initial' }} gap={1} flexWrap={"wrap"}>
                    <Typography
                        variant='subtitle1'
                        sx={{
                            textTransform: 'capitalize',
                            color: '#000',

                        }}
                    >
                        {advertisement?.title}
                    </Typography>

                    <Chip
                        label={
                            advertisement?.status === "onboardingFirstCompleted" ? "Taslak" :
                                advertisement?.status === "onboardingSecondCompleted" ? "Taslak" :
                                    advertisement?.status === "onboardingThirdCompleted" ? "Taslak" :
                                        advertisement?.status === "onboardingForthCompleted" ? "Taslak" :
                                            advertisement?.status === "pendingApproval" ? "İncelemede" :
                                                advertisement?.status === "approved" ? "Yayında" :
                                                    advertisement?.status === "rejected" ? "Reddedildi" :
                                                        advertisement?.status === "revision" ? "Revizyon" :
                                                            advertisement?.status === "onboardingRejected" ? "Reddedildi" : "Taslak"
                        }
                        color={
                            advertisement?.status === "onboardingFirstCompleted" ? "default" :
                                advertisement?.status === "onboardingSecondCompleted" ? "default" :
                                    advertisement?.status === "onboardingThirdCompleted" ? "default" :
                                        advertisement?.status === "onboardingForthCompleted" ? "default" :
                                            advertisement?.status === "pendingApproval" ? "warning" :
                                                advertisement?.status === "approved" ? "success" :
                                                    advertisement?.status === "rejected" ? "error" :
                                                        advertisement?.status === "revision" ? "info" :
                                                            advertisement?.status === "onboardingRejected" ? "default" : "default"
                        }
                        variant="outlined"
                        size='small'
                        style={{ borderRadius: 100 }}
                    />

                    {
                        advertisement?.extraProducts && (
                            Object.entries(
                                advertisement.extraProducts.reduce((acc: any, item: any) => {
                                    acc[item] = (acc[item] || 0) + 1;
                                    return acc;
                                }, {})
                            ).map(([item, count], index) => (
                                <Chip
                                    key={index}
                                    label={`${enumFunctions.findAndDisplayName(ExtraProductEnums, item)} ${Number(count) > 1 ? `x${count}` : ''}`}
                                    color="primary"
                                    variant="outlined"
                                    size='small'
                                    style={{ borderRadius: 100 }}
                                />
                            ))
                        )
                    }

                </Stack>

                <Typography fontSize={14} color={"#333"} >
                    <strong>
                        {enumFunctions.findAndDisplayName(OzgelecekFieldEnum, advertisement?.field)}
                    </strong>
                    {advertisement?.department ? ", " + advertisement?.department : ""} {advertisement?.city ? " · " + advertisement?.city : ""} {advertisement?.district ? " · " + advertisement?.district : ""}
                </Typography>

                <Stack direction="row" spacing={1} flexWrap="wrap" justifyContent={{ xs: 'center', sm: 'initial' }}>
                    <Chip
                        label={enumFunctions.findAndDisplayName(ProductTypeEnums, advertisement?.productType)}
                        color="primary"
                        style={{ borderRadius: 100 }}
                    />

                    <Divider orientation="vertical" variant="fullWidth" flexItem />

                    {
                        advertisement?.workType && (
                            <Chip
                                label={enumFunctions.findAndDisplayName(WorkTypeEnums, advertisement?.workType)}
                                color="primary"
                                variant="outlined"
                                style={{ borderRadius: 100 }}
                            />
                        )
                    }

                    {
                        advertisement?.positionLevel && (
                            <Chip label={enumFunctions.findAndDisplayName(PositionLevelEnums, advertisement?.positionLevel)}
                                color="success"
                                variant="outlined"
                                style={{ borderRadius: 100 }}
                            />
                        )
                    }

                </Stack>
            </Stack>


            <Stack direction="column" spacing={1} justifyContent="space-between">
                <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    flexWrap="wrap"
                    sx={{ '&>*': { flex: 1 } }}
                >




                    <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} gap={1} width={'200px'}>
                        {
                            advertisement?.status === "approved" && (
                                <Button
                                    color='primary'
                                    sx={{ bgcolor: '#5344d233', flexDirection: 'column', alignItems: 'center', width: '100%' }}
                                    onClick={() => {
                                        onShow()
                                    }}
                                >
                                    <RemoveRedEye /> Görüntüle
                                </Button>
                            )
                        }
                        {
                            advertisement?.status !== ("approved" || "pendingApproval") && type !== "preview" && (
                                <Button
                                    color='primary'
                                    sx={{ bgcolor: '#5344d233', flexDirection: 'column', alignItems: 'center', width: '100%' }}
                                    onClick={() => {
                                        onEdit()
                                    }}
                                >
                                    <Edit /> Düzenle
                                </Button>
                            )
                        }
                        {
                            (advertisement?.status !== ("pendingApproval") && advertisement?.status !== "approved") && (
                                <Button
                                    color='error'
                                    variant='outlined'
                                    sx={{ flexDirection: 'column', alignItems: 'center', width: '100%' }}
                                    onClick={() => {
                                        onDelete && onDelete();
                                    }}
                                >
                                    <Delete /> Sil
                                </Button>
                            )
                        }
                        {
                            (advertisement?.status === ("pendingApproval") || advertisement?.status === "approved") && type !== "preview" && (
                                <Button
                                    color='info'
                                    variant='outlined'
                                    sx={{ flexDirection: 'column', alignItems: 'center', width: '100%' }}
                                    onClick={() => {
                                        onExtra && onExtra();
                                    }}
                                >
                                    Ekstra Ürün
                                </Button>
                            )
                        }
                    </Stack>


                </Stack>
                <Stack direction={"row"} width={"150px"} flexWrap={"wrap"}>

                </Stack>

                <LinearProgress variant="determinate" value={(10 / 10) * 100} />

                <Typography fontSize={"small"} >
                    İlan Tarihi:<strong> {moment(advertisement?.startDate).format('DD MMM YYYY')}</strong>
                </Typography>

            </Stack>
        </Stack>
    )
}
