import { ICandidateInformationInput } from "types";


export type TCandidateUpdateCandidateVariables = {
    variables: ICandidateInformationInput
}


export const candidate_updateCandidate = ({ variables }: TCandidateUpdateCandidateVariables) => {

    const data = JSON.stringify({
        query: `
        mutation Candidate_updateCandidate($updateCandidateInput: UpdateCandidateInput!) {
            candidate_updateCandidate(updateCandidateInput: $updateCandidateInput) {
              _id
            }
          }
        `,
        variables: {
            "updateCandidateInput": {
                "certificates": variables.certificates,
                "languages": variables.languages,
                "participatedProjects": variables.participatedProjects,
                "scholarships": variables.scholarships,
                "programmingLanguages": variables.programmingLanguages,
                "hobbies": variables.hobbies,


                "driverLicense": {
                    "issueDate": variables.driverLicense?.issueDate,
                    "licenseType": variables.driverLicense?.licenseType,
                    "status": variables.driverLicense?.status
                },

                "education": {
                    "highSchool": variables.education?.highSchool ?? null,
                    "higherEducation": variables.education?.higherEducation ?? null,
                },

                "personalInformation": {
                    "address": variables.personalInformation?.address ?? null,
                    "backgroundImageUrl": variables.personalInformation?.backgroundImageUrl ?? null,
                    "birthDate": variables.personalInformation?.birthDate ?? null,
                    "city": variables.personalInformation?.city ?? null,
                    "country": variables.personalInformation?.country ?? null,
                    "description": variables.personalInformation?.description ?? null,
                    "district": variables.personalInformation?.district ?? null,
                    "name": variables.personalInformation?.name ?? null,
                    "nationality": variables.personalInformation?.nationality ?? null,
                    "phoneNumber": variables.personalInformation?.phoneNumber ?? null,
                    "profileImageUrl": variables.personalInformation?.profileImageUrl ?? null,
                    "surname": variables.personalInformation?.surname ?? null,
                    "citizenship": variables.personalInformation?.citizenship ?? null,


                    "sex": variables.personalInformation?.sex ?? null,
                    "gender": variables.personalInformation?.gender ?? null,
                    "genderDetail": variables.personalInformation?.genderDetail ?? null,
                },

                "smoking": variables.smoking,
                "workExperiencesInMonths": variables.workExperiencesInMonths,
                "computerKnowledgeLevel": variables?.computerKnowledgeLevel ?? null,
            }
        }

    })

    return data

}