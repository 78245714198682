import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Box, Button, IconButton, Stack, Typography } from '@mui/material'

import {
    useAppSelector,
    useAppDispatch,
    setCandidateCreateOzgelecekFormIndex,
    resetCandidateCreateOzgelecekForm,
    useGetUserProfileImagePresignedUrlQuery,
    useLazyCandidateTestVideoUploadPresignedUrlQuery,
} from 'context';

import {
    OzgelecekTestForm,
    OzgelecekFieldsForm,
    OzgelecekSelectReferanceForm,
    OzgelecekCreateUpdateFinishSection,
    OzgelecekFieldAndMotivationTestForm,
} from 'sections';

import { routes } from 'routes'
import { DashboardLayout, DashboardLayoutMobile } from 'layouts'
import { OzgelecekProgressBar } from 'components';
import { useCandidateUpdateOzgelecekMutation } from 'context'


import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { useState } from 'react';
import { saveVideoAndGetUrl } from 'utils';
import { enqueueSnackbar } from 'notistack';


export const CandidateOzgelecekUpdatePageMobile = () => {


    // const { field } = useParams()
    const [searchParams] = useSearchParams();
    const field = searchParams.get('field'); // "testCode"
    const id = searchParams.get('id'); // "testCode"

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false)

    const { formIndex } = useAppSelector((state) => state.candidateCreateOzgelecek)
    const [candidate_updateOzgelecek, { isLoading }] = useCandidateUpdateOzgelecekMutation()
    const ozgelecekForm = useAppSelector((state) => state.candidateCreateOzgelecek)
    const [ozgelecekTest, setOzgelecekTest] = useState<any>()

    const [getPresignedUrl, { data }] = useLazyCandidateTestVideoUploadPresignedUrlQuery()


    const handleUpdateOzgelecek = async (values: any) => {


        setLoading(true)
        let newOzgelecekTest = {
            first: { ...ozgelecekTest?.first },
            second: { ...ozgelecekTest?.second },
        }
        try {
            if (ozgelecekTest?.first?.video) {
                const presignUrlResponse = await getPresignedUrl({ questionIndex: 1, ozgelecekField: ozgelecekForm.values.field })
                let uploadReaponse = await saveVideoAndGetUrl({ file: ozgelecekTest?.first?.video, presignUrl: presignUrlResponse?.data?.presignedUploadUrl ?? "" })
                console.log({ presignUrlResponse, uploadReaponse });
                newOzgelecekTest.first = { video: presignUrlResponse?.data?.fileName }
            }

            if (ozgelecekTest?.second?.video) {
                const presignUrlResponse = await getPresignedUrl({ questionIndex: 1, ozgelecekField: ozgelecekForm.values.field })
                let uploadReaponse = await saveVideoAndGetUrl({ file: ozgelecekTest?.second?.video, presignUrl: presignUrlResponse?.data?.presignedUploadUrl ?? "" })
                console.log({ presignUrlResponse, uploadReaponse });

                newOzgelecekTest.second = { video: presignUrlResponse?.data?.fileName }
            }

            console.log({ newOzgelecekTest });
            const response: any = await candidate_updateOzgelecek({
                ozgelecekId: id ?? "",

                ozgelecekTest: newOzgelecekTest,
                testResults: values.testResults

            })

            console.log({ response });

            if (response?.data?.data?.candidate_updateOzgelecek) {
                enqueueSnackbar("Özgelecek başarılı bir şekilde oluşturuldu!")
                sessionStorage.removeItem("candidateCreateOzgecekForm")
                navigate(routes.candidate.dashboard.ozgeleceklerimMobile)
            } else {
                enqueueSnackbar("Birşeyler yanlış gitti!", { variant: 'error' })
            }
        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: 'error' })
        }

        setLoading(false)

    }
    // console.log({ field });
    const renderForms = () => {
        switch (formIndex) {
            // case 1:
            //     return <OzgelecekFieldsForm />;

            case 1:
                return <OzgelecekFieldAndMotivationTestForm field={field} questionType="area" title="Field Test" />;

            case 2:
                return <OzgelecekFieldAndMotivationTestForm field={field} questionType="motivation" title="Motivation Test" />;

            case 3:
                return <OzgelecekTestForm isLoading={isLoading || loading} ozgelecekTest={ozgelecekTest} setOzgelecekTest={setOzgelecekTest} />;

            // case 5:
            //     return <OzgelecekSelectReferanceForm />;

            case 4:
                return <OzgelecekCreateUpdateFinishSection isLoading={isLoading || loading} onSubmit={handleUpdateOzgelecek} />;

            default:
                return <OzgelecekFieldsForm />;
        }
    }




    return (
        <DashboardLayoutMobile>

            <Stack direction="row" gap={1} alignItems="center" mb={5} justifyContent="space-between">
                <Stack direction="row" gap={1} alignItems="center" >
                    <IconButton onClick={() => navigate(routes.candidate.dashboard.ozgeleceklerimMobile)}>
                        <ArrowBackIosNewRoundedIcon />
                    </IconButton>

                    <Typography variant='h1'>
                        Update Özgelecek
                    </Typography>
                </Stack>

                <Button variant='contained' color="error" onClick={() => dispatch(resetCandidateCreateOzgelecekForm({}))}>
                    Reset Test
                </Button>
            </Stack>

            <OzgelecekProgressBar totalstep={4} currentstep={ozgelecekForm.formIndex} />

            <Box sx={{ my: 5 }}>
                {renderForms()}
            </Box>

        </DashboardLayoutMobile>
    )
}



