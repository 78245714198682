import { routes } from './routes'
import { Route } from 'react-router-dom'
import { TOnboardingStatus } from 'types'

import {
    Indir,
    LoadingPage,
    CandidateAccountPage,
    CandidateSettingsPage,
    CandidateDashboardPage,
    CandidateHelpCenterPage,
    CandidateOnboardingPage,
    CandidateReferancesPage,
    CandidateOzgeleceksPage,
    CandidateApplicationsPage,
    CandidateAboutCompanyPage,
    CandidateAdvertisementsPage,
    CandidateOzgelecekUpdatePage,
    CandidateOzgelecekCreatePage,
    CandidateAdvertisementDetailsPage,

    CandidateAccountPageMobile,
    CandidateSettingsPageMobile,
    CandidateDashboardPageMobile,
    CandidateHelpCenterPageMobile,
    CandidateReferancesPageMobile,
    CandidateOzgeleceksPageMobile,
    CandidateApplicationsPageMobile,
    CandidateAboutCompanyPageMobile,
    CandidateAdvertisementsPageMobile,
    CandidateOzgelecekUpdatePageMobile,
    CandidateOzgelecekCreatePageMobile,
    CandidateAdvertisementDetailsPageMobile,
    NotificationsPageMobile,
    CandidateOnboardingPageMobile,
    LogoutPage,
} from 'pages'


export const candidateDashboardRouter = () => {
    return (
        <>
            <Route path={routes.candidate.dashboard.main} element={<CandidateDashboardPage />} />
            <Route path={routes.candidate.dashboard.myAccount} element={<CandidateAccountPage />} />
            <Route path={routes.candidate.dashboard.myReferences} element={<CandidateReferancesPage />} />
            <Route path={routes.candidate.dashboard.aboutCompany + "/:id"} element={<CandidateAboutCompanyPage />} />

            <Route path={routes.candidate.dashboard.ozgeleceklerim} element={<CandidateOzgeleceksPage />} />
            <Route path={routes.candidate.dashboard.ozgelecekCreate} element={<CandidateOzgelecekCreatePage />} />
            {/* <Route path={routes.candidate.dashboard.ozgelecekUpdate + "/:id"} element={<CandidateOzgelecekCreatePage />} /> */}
            <Route path={routes.candidate.dashboard.ozgelecekUpdate} element={<CandidateOzgelecekUpdatePage />} />
            <Route path={routes.candidate.dashboard.advertisements} element={<CandidateAdvertisementsPage />} />
            <Route path={routes.candidate.dashboard.advertisementDetails + "/:id"} element={<CandidateAdvertisementDetailsPage />} />
            <Route path={routes.candidate.dashboard.applications} element={<CandidateApplicationsPage />} />
            <Route path={routes.candidate.dashboard.settings} element={<CandidateSettingsPage />} />
            <Route path={routes.candidate.dashboard.helpCenter} element={<CandidateHelpCenterPage />} />
            <Route path={routes.candidate.dashboard.indir} element={<Indir />} />



            {/* ********************* for mobile ********************** */}
            {/* ******************************************************* */}
            <Route path={routes.candidate.dashboard.mainMobile} element={<CandidateDashboardPageMobile />} />
            <Route path={routes.candidate.dashboard.myAccountMobile} element={<CandidateAccountPageMobile />} />
            <Route path={routes.candidate.dashboard.myReferencesMobile} element={<CandidateReferancesPageMobile />} />
            <Route path={routes.candidate.dashboard.aboutCompanyMobile + "/:id"} element={<CandidateAboutCompanyPageMobile />} />

            <Route path={routes.candidate.dashboard.ozgeleceklerimMobile} element={<CandidateOzgeleceksPageMobile />} />
            <Route path={routes.candidate.dashboard.ozgelecekCreateMobile} element={<CandidateOzgelecekCreatePageMobile />} />
            {/* <Route path={routes.candidate.dashboard.ozgelecekUpdateMobile + "/:id"} element={<CandidateOzgelecekCreatePageMobile />} /> */}
            <Route path={routes.candidate.dashboard.ozgelecekUpdateMobile} element={<CandidateOzgelecekUpdatePageMobile />} />
            <Route path={routes.candidate.dashboard.advertisementsMobile} element={<CandidateAdvertisementsPageMobile />} />
            <Route path={routes.candidate.dashboard.advertisementDetailsMobile + "/:id"} element={<CandidateAdvertisementDetailsPageMobile />} />
            <Route path={routes.candidate.dashboard.applicationsMobile} element={<CandidateApplicationsPageMobile />} />
            <Route path={routes.candidate.dashboard.settingsMobile} element={<CandidateSettingsPageMobile />} />
            <Route path={routes.candidate.dashboard.helpCenterMobile} element={<CandidateHelpCenterPageMobile />} />

            <Route path={routes.common.notificationsMobile} element={<NotificationsPageMobile />} />
            <Route path={routes.candidate.onboarding.mainMobile} element={<CandidateOnboardingPageMobile />} />
            <Route path={routes.candidate.dashboard.logout} element={<LogoutPage />} />

            <Route path="*" element={<CandidateDashboardPage />} />
        </>
    )
}

export const candidateOnboardingRouter = () => {
    return (
        <>
            <Route path="/" element={<CandidateOnboardingPage />} />
            <Route path={routes.candidate.onboarding.main} element={<CandidateOnboardingPage />} />
            <Route path={routes.candidate.onboarding.mainMobile} element={<CandidateOnboardingPageMobile />} />
            <Route path="*" element={<CandidateOnboardingPage />} />
        </>
    )
}

export const candidateRouter = (isOnboarding: TOnboardingStatus) => {

    // console.log({ isOnboarding });
    switch (isOnboarding) {
        case "completed":
            // return candidateOnboardingRouter();
            return candidateDashboardRouter();
        case "notCompleted":
            return candidateOnboardingRouter();
        default:
            return <Route path='*' element={<LoadingPage />} />;
    }
}