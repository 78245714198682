


export interface ICompanyRequestApprovalResponse {
    success: boolean
}
export interface ICompanyRequestApprovalVariables {
    imzaSirkuleri: string,
    vergiLevhasi: string
}


export const company_requestApproval = (variables: ICompanyRequestApprovalVariables) => {

    const data = JSON.stringify({
        query: `mutation Company_requestApproval($input: UpdateCompanyDocumentsInput) {
            company_requestApproval(input: $input) {
              success
            }
          } `,
        variables: {
            "input": {
              "imzaSirkuleri": variables.imzaSirkuleri ?? null,
              "vergiLevhasi": variables.vergiLevhasi ?? null,
            }
          }
    })

    return data

}