
import { Box, Chip, Dialog, DialogContent, Divider, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material'


import { ICandidateOzgeleceksItem, useAppSelector, useGetCandidateInformationQuery, useLazyGetCandidateInformationQuery } from 'context';
import { DriverLicenseStatusEnum, LanguageLevelEnum, OzgelecekFieldEnum, SexEnum, getNameOfEnum } from 'types';
import { enumFunctions, upperCasedString } from 'utils';
import { Logo, LogoCrop, User } from 'assets';
import { Page, Text, View, Document, StyleSheet, Image, Font, Link, pdf } from '@react-pdf/renderer';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RefreshIcon from '@mui/icons-material/Refresh';

import { useEffect, useState } from 'react';
import moment from 'moment';
import { Download } from '@mui/icons-material';
import { PDFViewer } from '@react-pdf/renderer';
import HigherEducationDegree from 'enums/user/user/education/HigherEducationDegree';

Font.register({
    family: "RobotoLight",
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
});

Font.register({
    family: "Roboto",
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
});

Font.register({
    family: "RobotoBold",
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
});


interface IOzgelecekCardProps {
    ozgelecek: ICandidateOzgeleceksItem,
    onDelete: () => void,
    onUpdate: () => void,
}

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#fefefe',
        padding: 20,
    },
    container: {
        width: "100%",
        height: "900px",
        margin: 10,
        padding: 10,
        position: 'relative',
        backgroundColor: 'red'
    },
    title: {
        fontSize: 24,
        fontFamily: 'RobotoBold',
        textAlign: 'center'
    },
    subtitle: {
        fontSize: 18,
        fontFamily: 'RobotoBold',
        marginTop: 20
    },
    text: {
        fontSize: 16,
        fontWeight: 'bold',

        fontFamily: 'Roboto'
    },
    text2: {
        fontSize: 14,
        fontWeight: 'bold',
        fontFamily: 'Roboto'
    }
});


export const OzgelecekCard = ({ ozgelecek, onDelete, onUpdate }: IOzgelecekCardProps) => {

    console.log(ozgelecek)

    const [showPdf, setShowPdf] = useState(false);
    const [imgPng, setImgPng] = useState<string | null>(null);

    const { data, isLoading } = useGetCandidateInformationQuery({})


    const convertJpgToPng = (jpgUrl: string): Promise<Blob> => {
        return new Promise((resolve, reject) => {
            const img = new window.Image();
            img.crossOrigin = 'Anonymous'; // This is important for cross-origin images
            img.src = jpgUrl;

            img.onload = () => {
                try {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');

                    if (ctx) {
                        ctx.drawImage(img, 0, 0);
                        canvas.toBlob((blob) => {
                            if (blob) {
                                resolve(blob);
                            } else {
                                reject(new Error('Failed to convert image to PNG'));
                            }
                        }, 'image/png');
                    } else {
                        reject(new Error('Failed to get canvas context'));
                    }
                } catch (error) {
                    reject(error);
                }
            };

            img.onerror = (error) => {
                reject(new Error('Failed to load image'));
            };
        });
    };

    useEffect(() => {
        const fetchImage = async () => {
            try {
                // Fetch the image URL from the API
                const imageUrl = data?.personalInformation?.profileImageUrl;

                // Convert the image URL to a PNG blob
                const pngBlob = await convertJpgToPng(imageUrl);

                // Convert the PNG blob to a URL and set it in the state
                const pngUrl = URL.createObjectURL(pngBlob);
                setImgPng(pngUrl);
            } catch (error) {
                console.error('Error fetching or converting image:', error);
            }
        };

        fetchImage();
    }, [data]);



    console.log(imgPng)

    const MyDocument = () => {
        return data && imgPng && <Document>
            <Page size="A4" style={styles.page}>

                <Image src={Logo} style={{ width: 120, height: 120, objectFit: "contain", position: 'absolute', bottom: 0, right: 40 }} />
                <View style={{ width: 70, height: 70, objectFit: "contain", position: 'absolute', top: 75, right: 75, padding: 1 }}>
                    <Image src={imgPng ?? User} style={{ width: 73, height: 73, objectFit: "contain" }} />
                </View>
                <Text style={styles.title}>{data?.personalInformation?.name} {data?.personalInformation?.surname} | Özgelecek</Text>

                <Text style={styles.subtitle}>Kişisel Bilgiler</Text>
                <Text style={styles.text2}>Adı Soyadı: {data?.personalInformation?.name} {data?.personalInformation?.surname}</Text>
                <Text style={styles.text2}>E-posta: {data?.contact?.email} </Text>
                <Text style={styles.text2}>Telefon: {data?.personalInformation?.phoneNumber} </Text>
                <Text style={styles.text2}>Doğum Tarihi: {moment(data?.personalInformation?.birthDate).format('DD MMMM YYYY')} </Text>
                <Text style={styles.text2}>Cinsiyet: {getNameOfEnum(SexEnum, data?.personalInformation?.sex)} </Text>
                <Text style={styles.text2}>Uyruk:  {data?.personalInformation?.nationality} </Text>
                <Text style={styles.text2}>Farklı Vatandaşlık: {data?.personalInformation?.citizenship} </Text>
                <Text style={styles.text2}>Adres: {data?.personalInformation?.district}, {data?.personalInformation?.city} | {data?.personalInformation?.address} </Text>
                <Text style={styles.text2}>Sigara Kullanımı: {data?.smoking ? "Kullanıyor" : "Kullanmıyor"} </Text>
                <Text style={styles.text2}>Hakkında: {data?.personalInformation?.description} </Text><br />

                <Text style={styles.subtitle}>Eğitim Bilgileri</Text>
                <Text style={styles.text2}>Lise: {data?.education?.highSchool?.join(', ')} </Text>
                <Text style={styles.text2}>Yükseköğrenim: {data?.education?.higherEducation?.map((degree: any) => `${degree?.name} - ${degree?.department} - ${getNameOfEnum(HigherEducationDegree, degree?.higherEducationDegree)} - ${degree?.gpa}`)?.join(' | ')} </Text><br />

                <Text style={styles.subtitle}>Sertifikalar</Text>
                {data?.certificates?.map((certificate: any, index: number) => (
                    <Text key={index} style={styles.text2}>{certificate?.name}, {certificate?.type} - {moment(certificate?.issuedDate).format("MM.YYYY")} - {moment(certificate?.validUntil).format("MM.YYYY")} - {certificate?.certificateInstitution}</Text>
                ))}<br />

                <Text style={styles.subtitle}>Bildiği Diller</Text>
                {data?.languages?.map((language: any, index: number) => (
                    <Text key={index} style={styles.text2}>{language?.name?.toUpperCase()}: Okuma: {getNameOfEnum(LanguageLevelEnum, language?.reading)}, Yazma: {getNameOfEnum(LanguageLevelEnum, language?.writing)}, Dinleme: {getNameOfEnum(LanguageLevelEnum, language?.listening)}</Text>

                ))}<br />

                <Text style={styles.subtitle}>Sürücü Belgesi</Text>
                <Text style={styles.text2}>{getNameOfEnum(DriverLicenseStatusEnum, data?.driverLicense?.status)}, {data?.driverLicense?.licenseType} sınıfı, {moment(data?.driverLicense?.issueDate).format("MM.YYYY")}</Text><br />


                <Text style={styles.subtitle}>Hobileri</Text>
                {data?.hobbies?.map((hobby: any, index: number) => (
                    <Text key={index} style={styles.text2}>{hobby}</Text>
                ))}<br />


                <Text style={styles.subtitle}>Özgelecek Bilgileri</Text>
                <Text style={styles.text2}>Alan: {upperCasedString(enumFunctions.findAndDisplayName(OzgelecekFieldEnum, ozgelecek?.field))}</Text>
                <Text style={styles.text2}>Alan Testi Sonucu: {ozgelecek?.testResults?.areaTestScore}</Text>
                <Text style={styles.text2}>Motivasyon Testi Sonucu: {ozgelecek?.testResults?.motivationTestScore}</Text>
                {ozgelecek?.ozgelecekTest?.first?.video && <Link src={`https://ozgelecek.fra1.digitaloceanspaces.com/${ozgelecek?.ozgelecekTest?.first?.video}`}>Video indir</Link>}
                {ozgelecek?.ozgelecekTest?.first?.text && <Text style={styles.text2}>{ozgelecek?.ozgelecekTest?.first?.text}</Text>}
                {ozgelecek?.ozgelecekTest?.second?.video && <Link style={styles.text2} src={`https://ozgelecek.fra1.digitaloceanspaces.com/${ozgelecek?.ozgelecekTest?.second?.video}`}>Video indir</Link>}
                {ozgelecek?.ozgelecekTest?.second?.text && <Text style={styles.text2}>{ozgelecek?.ozgelecekTest?.second?.text}</Text>}






            </Page>
        </Document>

    }

    const handleDownload = async () => {
        // setShowPdf(true)

        if (data && imgPng) {
            const blob = await pdf(<MyDocument />).toBlob();
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'ozgelecek.pdf';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        }
    }

    return (
        <Stack
            direction={{ xs: 'column' }}
            spacing={{ xs: 3, sm: 2 }}
            sx={{
                border: '1px solid #d6ddeb',
                p: 3,
                backgroundColor: '#fff',
                height: '100%',
                position: 'relative',
            }}
        >

            <Stack direction="row" justifyContent="space-between" gap={3} alignItems="start">

                <Box
                    component={"img"}
                    src={LogoCrop}
                    alt="logo"
                    sx={{
                        width: 50,
                        height: 50,
                    }}
                />

                <OzgelecekItemMoreMenu onDelete={onDelete} onUpdate={onUpdate} handleDownload={handleDownload} />
                {
                    showPdf && (
                        <Dialog open={true} onClose={() => setShowPdf(false)}>
                            <DialogContent sx={{ width: "600px", height: "900px" }}>
                                <PDFViewer width="100%" height="100%">
                                    <MyDocument />
                                </PDFViewer>
                            </DialogContent>
                        </Dialog>
                    )
                }

            </Stack>

            <Stack direction="column" spacing={1} flex={1}>
                <Typography variant='h5' height={60}>
                    {upperCasedString(enumFunctions.findAndDisplayName(OzgelecekFieldEnum, ozgelecek?.field))}
                </Typography>


                <Stack direction="row" spacing={1} flexWrap="wrap">
                    <Typography variant='body1' flex={1}>
                        Alan Testi Sonucu
                    </Typography>
                    <Divider orientation="vertical" variant="fullWidth" flexItem />
                    <Chip label={ozgelecek?.testResults?.areaTestScore} color="primary" variant="outlined" />
                </Stack>

                <Stack direction="row" spacing={1} flexWrap="wrap">
                    <Typography variant='body1' flex={1}>
                        Motivasyon Testi Sonucu
                    </Typography>
                    <Divider orientation="vertical" variant="fullWidth" flexItem />
                    <Chip label={ozgelecek?.testResults?.motivationTestScore} color="success" variant="outlined" />
                </Stack>


                {/* <Stack direction="row" spacing={1} flexWrap="wrap">
                    <Chip label="Full Time" color="primary" />
                    <Divider orientation="vertical" variant="fullWidth" flexItem />

                    <Chip label="Marketing" color="primary" variant="outlined" />
                    <Chip label="Design" color="success" variant="outlined" />
                </Stack> */}
            </Stack>

            <Stack direction="row" spacing={1} flexWrap="wrap">
                <Typography variant='body2' flex={1}>
                    Oluşturulma Tarihi : <span>
                        {moment(ozgelecek.updatedAt).format('DD MMMM YYYY')}
                    </span>
                </Typography>
            </Stack>

        </Stack>
    )
}

const OzgelecekItemMoreMenu = ({ onDelete, onUpdate, handleDownload }: any) => {

    const { device } = useAppSelector(state => state.auth)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        onDelete()
        handleClose()
    }

    const handleUpdate = () => {
        onUpdate()
        handleClose()
    }


    return (
        <>

            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                    // position: 'absolute',
                }}
            >
                <MoreHorizIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {/* <MenuItem onClick={() => handleDownload()} sx={{ color: 'primary.main' }}>
                    <Download />&nbsp;
                    İndir
                </MenuItem> */}
                {device !== "mobile" && <MenuItem onClick={handleDownload} sx={{ color: 'primary.main' }}>
                    <Download />&nbsp;
                    İndir
                </MenuItem>}
                <MenuItem onClick={handleUpdate} sx={{ color: 'success.main' }}>
                    <RefreshIcon />&nbsp;
                    Güncelle
                </MenuItem>
                <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
                    <DeleteForeverIcon /> &nbsp;
                    Sil
                </MenuItem>
            </Menu>
        </>
    )
}