import IBaseResult from "interfaces/IBaseResult";
import { IBillingInfo } from "interfaces/user/user/IBillingInfo";


export interface ISelfUpdateUserBillingInfoResponse {
  self_updateUserBillingInfo: {
    success: boolean;
  };
}

export interface ISelfUpdateUserBillingInfoVariables extends IBaseResult {
  ip: string,
  buyer: {
    name?: string | null,
    surname?: string,
    email?: string,
    country?: string,
    city?: string,
    identityNumber?: string,
    ip?: string,
    registrationAddress?: string,
  },
  billingAddress: {
    companyName?: string,
    fullName?: string,
    address1?: string,
    address2?: string,
    taxOffice?: string,
    taxIdentificationNumber?: string,
    taxNumber?: string,
    postcode?: string,
    phone?: string,
    email?: string,
    country?: string,
    city?: string,
    district?: string,
  }
}


export const self_updateUserBillingInfo = (variables: ISelfUpdateUserBillingInfoVariables) => {

  const data = JSON.stringify({
    query: `
          mutation self_updateUserBillingInfo($input: BillingInfoInput!) {
            self_updateUserBillingInfo(input: $input) {
              success
            }
          }
        `,
    variables: {
      "input": {
        "buyer": {
          "name": variables.buyer.name ?? "",
          "surname": variables.buyer.surname ?? "",
          "email": variables.buyer.email ?? "",
          "country": variables.buyer.country ?? "",
          "city": variables.buyer.city ?? "",
          "identityNumber": variables.buyer.identityNumber ?? "",
          "ip": variables.ip ?? "",
          "registrationAddress": variables.billingAddress.address1 ?? ""
        },
        "billingAddress": {
          "address1": variables.billingAddress.address1 ?? "",
          "email": variables.buyer.email ?? "",
          "fullName": variables.buyer.name + " " + variables.buyer.surname ?? "",
          "taxOffice": variables.billingAddress.taxOffice ?? "",
          "taxIdentificationNumber": variables.billingAddress.taxIdentificationNumber ?? "",
          "companyName": variables.billingAddress.companyName ?? "",
          "postcode": variables.billingAddress.postcode ?? "",
          "country": variables.buyer.country ?? "",
          "city": variables.buyer.city ?? "",
        }
      }
    }
  })

  return data
}
