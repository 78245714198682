import { useEffect, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { Box, Button, Card, Checkbox, Chip, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'

import {
    useAppDispatch,
    useAppSelector,
    ICandidateReferenceItem,
    ICandidateRefencesVariables,
    useGetCandidateReferencesQuery,
    useCandidateCreateReferenceMutation,
    setCandidateCreateOzgelecekFormIndex,
    setCandidateCreateOzgelecekFormReferences,
} from 'context'
import { LoadingButton } from '@mui/lab'
import { enumFunctions } from 'utils'
import { CandidateReferenceStatusEnums } from 'types'
import { ReferenceForm } from '../personalInformation'
import { CustomTableHeader, ICustomTableHeadCell } from 'components'
import { Iconify } from 'components'


export const OzgelecekSelectReferanceForm = ({ onSubmit, isLoading }: { onSubmit: (e: any) => void, isLoading: boolean }) => {

    const dispatch = useAppDispatch()
    const [selected, setSelected] = useState<readonly string[]>([]);
    const [candidate_createReference] = useCandidateCreateReferenceMutation()
    // const { formIndex } = useAppSelector(state => state.candidateCreateOzgelecek)

    const { values } = useAppSelector(state => state.candidateCreateOzgelecek)

    useEffect(() => {
        dispatch(setCandidateCreateOzgelecekFormReferences(selected))
    }, [dispatch, selected])


    const handleCreateReference = async (values: any) => {

        try {

            const response: any = await candidate_createReference({ variables: values })
            console.log({ response });

            if (response?.data?.candidate_createReference?.token) {
                enqueueSnackbar("Successfully Create Reference!")
            } else {
                response?.data?.errors?.forEach((error: any) => {
                    if (error.message === "Reference already created") {
                        enqueueSnackbar("Reference already created", { variant: 'error' })
                    } else {
                        enqueueSnackbar(error?.message, { variant: 'error' })
                    }
                })
            }

        } catch (error: any) {

            console.log({ error });
            error?.data?.errors?.forEach((error: any) => {
                if (error.message === "Reference already created") {
                    enqueueSnackbar("Reference already created", { variant: 'error' })
                } else {
                    enqueueSnackbar(error?.message, { variant: 'error' })
                }
            })

        }


    }


    // const handleNextStep = () => {
    //     selected?.forEach((item) => {
    //         dispatch(setCandidateCreateOzgelecekFormReferences(item))
    //     })
    //     dispatch(setCandidateCreateOzgelecekFormIndex(formIndex + 1))
    // }



    return (
        <Box>

            <Box bgcolor={"secondary.lighter"} p={4} mt={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}><Iconify icon={"material-symbols:info-outline"} /></Box>
                <Typography variant="body2" >
                    Bu sayfada referanslarınızı seçebilirsiniz. En az 1 referans seçmeniz gerekmektedir.
                </Typography>
            </Box>

            <Stack direction="row" justifyContent="space-between" mt={2} alignItems="center">
                <Typography variant='h2'>
                    Referanslar
                </Typography>

                <ReferenceForm
                    onSubmit={handleCreateReference}
                />
            </Stack>

            <CandidateReferencersTable selected={selected} setSelected={setSelected} />

            <Stack direction="row" justifyContent="flex-end">
                {/* <Button
                    sx={{ pl: 5, pr: 4 }}
                    variant='outlined'
                    color="primary"

                    onClick={() => {
                        dispatch(setCandidateCreateOzgelecekFormIndex(formIndex - 1))
                    }}
                >
                    Geri
                </Button> */}

                <LoadingButton
                    variant="contained"
                    color="primary"
                    sx={{ pl: 5, pr: 5 }}
                    onClick={() => onSubmit(values)}
                    disabled={!values?.references?.length || isLoading}
                    loading={isLoading}
                >
                    <Iconify icon="ep:finished" sx={{ mr: 1, mt: 0.2 }} />
                    Tamamla
                </LoadingButton>

                {/* <Button
                    variant='contained'
                    color="primary"
                    sx={{ pl: 5, pr: 4 }}
                    disabled={!selected?.length}
                    onClick={handleNextStep}
                >
                    İleri
                    <Iconify icon="mi:next" sx={{ ml: 1, mt: 0.2 }} />
                </Button> */}
            </Stack>
        </Box>
    )
}



const CandidateReferencersTable = ({ selected, setSelected }: any) => {

    const [variables] = useState<ICandidateRefencesVariables>({
        filter: {
            email: "",
            referenceStatus: null,
        },
        pagination: {
            pageSize: 5,
            page: 1,
            sort: ""
        }
    })

    const { data: references } = useGetCandidateReferencesQuery({ ...variables })



    const headCells: ICustomTableHeadCell[] = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Ad Soyad',
        },
        {
            id: 'email',
            numeric: false,
            disablePadding: false,
            label: 'Email',
        },
        {
            id: 'jobTitle',
            numeric: false,
            disablePadding: false,
            label: 'Meslek / Ünvan',
        },
        {
            id: 'phone',
            numeric: false,
            disablePadding: false,
            label: 'Telefon No.',
        },
        {
            id: 'relation',
            numeric: false,
            disablePadding: false,
            label: 'Yakınlığı',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Durumu',
        },
    ];



    console.log({ selected });
    const handleSelectOne = (event: React.MouseEvent<unknown>, id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event?.target?.checked) {
            const newSelected = references?.data?.map((n: ICandidateReferenceItem) => n?._id);
            setSelected(newSelected ?? []);
            return;
        }
        setSelected([]);
    };



    const isSelected = (id: string) => selected?.indexOf(id) !== -1;

    // const emptyRows = (variables?.pagination?.page ?? 1) > 0
    //     ? (variables?.pagination?.pageSize ?? 10) - (references?.data?.length ?? 0)
    //     : 0;

    // console.log({ variables });



    // const handleChangePage = (event: unknown, newPage: number) => {
    //     setVariables((prevState: ICandidateRefencesVariables) => ({
    //         ...prevState,
    //         pagination: {
    //             ...prevState?.pagination,
    //             page: newPage + 1
    //         }
    //     }))
    // };

    // const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     console.log({ event });

    //     setVariables((prevState: ICandidateRefencesVariables) => ({
    //         ...prevState,
    //         pagination: {
    //             ...prevState?.pagination,
    //             pageSize: Number(event?.target?.value)
    //         }
    //     }))
    // };



    return (
        <Card sx={{ width: '100%', p: { xs: 1, sm: 2, md: 3 }, my: 3 }}>
            <TableContainer>
                <Table
                    // sx={{ width: '100%' }}
                    aria-labelledby="tableTitle"
                    size='medium'
                >

                    <CustomTableHeader
                        checkBox
                        headCells={headCells}
                        numSelected={selected?.length}
                        onSelectAllClick={handleSelectAllClick}
                        rowCount={references?.data?.length ?? 0}
                    // order={order}
                    // orderBy={orderBy}
                    // onRequestSort={handleRequestSort}
                    />

                    <TableBody>
                        {references?.data?.map((row: ICandidateReferenceItem, index: number) => {
                            const isItemSelected = isSelected(row?._id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow
                                    key={row?._id ?? index}

                                    hover
                                    onClick={(event) => handleSelectOne(event, row?._id)}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    selected={isItemSelected}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            checked={isItemSelected}
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell component="th" id={labelId} scope="row" padding="none" sx={{ pl: 2 }}>
                                        {row?.referenceInformation?.name} {row?.referenceInformation?.surname}
                                    </TableCell>
                                    <TableCell align="left">{row?.referenceInformation?.email}</TableCell>
                                    <TableCell align="left">{row?.referenceInformation?.jobTitle}</TableCell>
                                    <TableCell align="left">{row?.referenceInformation?.phoneNumber}</TableCell>
                                    <TableCell align="left">{row?.referenceInformation?.relationWithCandidate}</TableCell>
                                    <TableCell align="left">
                                        <Chip
                                            label={enumFunctions.findAndDisplayName(CandidateReferenceStatusEnums, row?.referenceStatus)}
                                            color={
                                                row?.referenceStatus === "pending"
                                                    ? "success"
                                                    : "warning"
                                            }
                                            variant="outlined"
                                            style={{ borderRadius: 100 }}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })
                        }

                        {/* {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )} */}
                    </TableBody>



                </Table>
            </TableContainer>

            {/* <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 20]} // içindeki numaraları dropdown olarak gösteriyor
                count={references?.count ?? 0} // toplam eleman sayısı
                rowsPerPage={variables.pagination.pageSize ?? 1} // sayfadaki eleman sayısı
                page={(variables.pagination.page ?? 1) - 1} // kaçıncı sayfa olduğunu gösteriyor, 0 ilk sayfa

                labelRowsPerPage="Sayfa başına gösterim"
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}

        </Card>
    )
}
