

export interface ISelfCompanyResponse {
    _id: string
    personalInformation: {
        description: string,
        name: string,
        phoneNumber: string,
        surname: string,
    }
    contact: {
        email: string,
        emailVerified: string,
        phoneNumber: string,
        phoneNumberVerified: string,
    }

    companyOnboardingStatus: string,
    createdAt: string,
    updatedAt: string,

    companyInformation: {
        city: string,
        email: string,
        facebook: string,
        foundationYear: string,
        district: string,
        companyBrief: string,
        companyName: string,
        companyAddress: string,
        instagram: string,
        linkedin: string,
        numberOfEmployees: string,
        phoneNumber: string,
        profileImage: string,
        sector: string,
        taxNumber: string,
        taxOffice: string,
        website: string,
    }
}

export interface ISelfCompanyVariables { }


export const self_company = (variables: ISelfCompanyVariables) => {

    const data = JSON.stringify({
        query: `
            query Self_company {
                self_company {
                _id
                personalInformation {
                    description
                    name
                    phoneNumber
                    surname
                }
                contact {
                    email
                    emailVerified
                    phoneNumber
                    phoneNumberVerified
                }
            
                companyOnboardingStatus
                createdAt
                updatedAt
                companyInformation {
                    city
                    email
                    facebook
                    foundationYear
                    district
                    companyBrief
                    companyName
                    companyAddress
                    instagram
                    linkedin
                    numberOfEmployees
                    phoneNumber
                    profileImage
                    sector
                    taxNumber
                    taxOffice
                    website
                }
                }
            }
        `,
        variables: {}
    })

    return data
}
