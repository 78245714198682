import { useFormikContext } from 'formik'
import { Button, Card, Grid, Typography } from '@mui/material'

import { ICandidateInformationInput } from 'types'
import { FormikTextField } from 'components'





interface ICandidateAccountAboutFormProps {

}


export const CandidateAccountAboutForm = () => {

    const { handleSubmit } = useFormikContext<ICandidateInformationInput>()



    return (
        <Card sx={{ width: '100%', p: 3 }}>
            <Grid container spacing={3} >

                <Grid item xs={12}>
                    <Typography variant="h5">
                        Hakkımda
                    </Typography>
                </Grid>



                <Grid item xs={12}>
                    <FormikTextField
                        multiline
                        minRows={4}
                        name="personalInformation.description"
                        label="Hakkımda"
                    />
                </Grid>


                <Grid item xs={12} textAlign="right" >
                    <Button
                        onClick={() => handleSubmit()}
                        variant="contained"
                        color="primary"
                    >
                        Güncelle
                    </Button>
                </Grid>
            </Grid>
        </Card>
    )
}
