import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Autocomplete, Box, StandardTextFieldProps, TextField } from '@mui/material';
import React, { useState } from 'react';


interface IFormikTextField extends StandardTextFieldProps {
    name: string;
    list: any[];
}


// input [{name, value}] output [name] 
export const FormikAutoComplete = ({ name = "", list, ...rest }: IFormikTextField) => {
    const { getFieldMeta, setFieldValue, getFieldProps } = useFormikContext<IFormikTextField | any>();
    const error = !!(getFieldMeta(name).touched && getFieldMeta(name).error);
    const helperText = !!getFieldMeta(name).touched && getFieldMeta(name).error && `${getFieldMeta(name).error}`;
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

    console.log(list)
    useEffect(() => {
        const defaultValue = getFieldProps(name).value;
        if (defaultValue) {
            setSelectedOptions(defaultValue);
        }
    }, [getFieldProps, name]);

    return (
        <Autocomplete
            multiple
            options={list}
            getOptionLabel={(option) => option?.name || ''}
            value={selectedOptions.map(optionValue => list.find(option => option.value === optionValue))}
            onChange={(_, newValues) => {
                if (newValues) {
                    setSelectedOptions(newValues.map(option => option?.value || ''));
                    setFieldValue(name, newValues.map(option => option?.value || ''));
                }
            }}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {option?.name || ''}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={rest.label}
                    error={error}
                    helperText={helperText}
                    {...rest}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                    }}
                />
            )}
        />
    );
};

// input [name] output [name] 
export const FormikAutoComplete2 = ({ name = "", list, ...rest }: IFormikTextField) => {
    const { getFieldMeta, setFieldValue, getFieldProps } = useFormikContext<IFormikTextField | any>();
    const error = !!(getFieldMeta(name).touched && getFieldMeta(name).error);
    const helperText = !!getFieldMeta(name).touched && getFieldMeta(name).error && `${getFieldMeta(name).error}`;
    const [selectedOptions, setSelectedOptions] = useState<any[]>([]);

    useEffect(() => {
        const defaultValue = getFieldProps(name).value;
        if (defaultValue) {
            setSelectedOptions(defaultValue);
        }
    }, [getFieldProps, name]);

    console.log(selectedOptions)

    return (
        <Autocomplete
            multiple
            options={list}
            value={selectedOptions}
            onChange={(_, newValues) => {
                setSelectedOptions(newValues);
                setFieldValue(name, newValues);
            }}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {option}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={rest.label}
                    error={error}
                    helperText={helperText}
                    {...rest}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                    }}
                />
            )}
            defaultValue={selectedOptions}
        />
    );
};


// ilçe seçimi için
// input [{name, value}] output [name] 

export const FormikAutoComplete3 = ({ name = "", list, ...rest }: IFormikTextField) => {
    const { getFieldMeta, setFieldValue, getFieldProps } = useFormikContext<IFormikTextField | any>();
    const error = !!(getFieldMeta(name).touched && getFieldMeta(name).error);
    const helperText = !!getFieldMeta(name).touched && getFieldMeta(name).error && `${getFieldMeta(name).error}`;
    const [selectedOptions, setSelectedOptions] = useState<any[]>([]);

    useEffect(() => {
        const defaultValue = getFieldProps(name).value;
        if (defaultValue) {
            setSelectedOptions(defaultValue);
        }
    }, [getFieldProps, name]);

    return (
        <Autocomplete
            multiple
            options={list}
            value={selectedOptions}
            onChange={(_, newValues) => {
                setSelectedOptions(newValues);
                setFieldValue(name, newValues);
            }}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {option}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={rest.label}
                    error={error}
                    helperText={helperText}
                    {...rest}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                    }}
                />
            )}
            defaultValue={selectedOptions}
        />
    );
};



// şehir seçimi için
// input [{name, value}] output [name]
export const FormikAutoComplete4 = ({ name = "", list, ...rest }: IFormikTextField) => {
    const { getFieldMeta, setFieldValue, getFieldProps } = useFormikContext<IFormikTextField | any>();
    const error = !!(getFieldMeta(name).touched && getFieldMeta(name).error);
    const helperText = !!getFieldMeta(name).touched && getFieldMeta(name).error && `${getFieldMeta(name).error}`;
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

    useEffect(() => {
        const defaultValue = getFieldProps(name).value;
        if (defaultValue) {
            setSelectedOptions(defaultValue);
        }
    }, [getFieldProps, name]);

    return (
        <Autocomplete
            multiple
            options={list}
            getOptionLabel={(option) => option?.name || 'z'}
            value={selectedOptions.map(optionValue => list.find(option => option.name === optionValue))}
            onChange={(_, newValues) => {
                if (newValues) {
                    setSelectedOptions(newValues.map(option => option?.name || ''));
                    setFieldValue(name, newValues.map(option => option?.name || ''));
                }
            }}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {option?.name || ''}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={rest.label}
                    error={error}
                    helperText={helperText}
                    {...rest}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                    }}
                />
            )}
        />
    );
};