

export interface ICandidateApplicationsItem {
  _id: string
  advertisement: {
    _id: string
    createdBy: {
      companyInformation: {
        profileImage: string
        companyName: string
        city: string
        sector: string
      }
    }
    updatedAt: string
    title: string
    startDate: string
    quota: number
    field: string
    description: string
    department: string
    createdAt: string
  }
  createdAt: string
  ozgelecek: {
    field: string
  }
  status: string
  updatedAt: string
}

export interface ICandidateApplicationsResponse {
  count: number
  data: Array<ICandidateApplicationsItem>
}

export interface ICandidateApplicationsVariables {
  filter?: {
    // candidateId: string,
    companyId: string,
    ozgelecekId: string,
    statuses: string
  },
  pagination: {
    page: number,
    pageSize: number,
    sort?: string
  }
}



export const candidate_applications = (variables: ICandidateApplicationsVariables) => {

  const data = JSON.stringify({

    query: `
    query Candidate_applications($filter: FilterApplicationForCandidateInput, $pagination: Pagination) {
      candidate_applications(filter: $filter, pagination: $pagination) {
        count
        data {
          _id
          advertisement {
            _id
            createdBy {
              companyInformation {
                profileImage
                companyName
                city
                sector
              }
            }
            updatedAt
            title
            startDate
            quota
            field
            description
            department
            createdAt
          }
          createdAt
          ozgelecek {
            field
          }
          status
          updatedAt
          
        }
      }
    }
        `,

    variables: {
      "filter": {
        // "candidateId": variables?.filter?.candidateId ?? null,
        "companyId": variables?.filter?.companyId ?? null,
        "ozgelecekId": variables?.filter?.ozgelecekId ?? null,
        "statuses": variables?.filter?.statuses ?? null
      },
      "pagination": {
        "page": variables?.pagination?.page ?? null,
        "pageSize": variables?.pagination?.pageSize ?? null,
        "sort": variables?.pagination?.sort ?? null
      }
    }

  })


  return data

}  