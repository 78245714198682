import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Skeleton, Stack, Typography } from '@mui/material'
import { CandidateAdvertisementDetailImage } from 'assets'
import { useGetCompanyAdvertisementDetailQuery, useGetCompanyInformationQuery, useLazyGetAdvertisementCostQuery, useGetAdvertisementCostQuery, useCompanyAddExtraProductToAdvertisementMutation } from 'context'
import { DashboardLayout, DashboardLayoutMobile } from 'layouts'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ExtraProductEnums, LanguageEnums, LanguageLevelEnum, OzgelecekFieldEnum, PlaceOfWorkEnums, PositionLevelEnums, WorkTypeEnums, getNameOfEnum } from 'types'
import { enumFunctions } from 'utils'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { routes } from 'routes'
import EducationLevel from 'enums/user/user/education/EducationLevel'
import { FormikSelect2, FormikSwitch2, Iconify } from 'components'
import { enqueueSnackbar } from 'notistack'
import { Edit } from '@mui/icons-material'
import HTMLReactParser from 'html-react-parser'



export const CompanyAdvertisementDetailPageMobile = () => {

    const { id } = useParams()
    const navigate = useNavigate()
    const { data: advertisement, isLoading } = useGetCompanyAdvertisementDetailQuery({ advertisementId: id ?? "" })
    const { data: company } = useGetCompanyInformationQuery("")
    const [doping, setDoping] = useState(false)
    const [dopingQuantity, setDopingQuantity] = useState(0)
    const [emergent, setEmergent] = useState(false)
    const [showcase, setShowcase] = useState(false)
    const [extraProducts, setExtraProducts] = useState([] as string[])
    const [extraDialog, setExtraDialog] = useState(false)
    const [getCost, { isFetching: isFetchingCost }] = useLazyGetAdvertisementCostQuery()
    const [cost, setCost] = useState(0)
    const [addExtraProductToAdvertisement] = useCompanyAddExtraProductToAdvertisementMutation()
    const [editWarningDialog, setEditWarningDialog] = useState(false)

    const handleExtraProductChange = () => {
        const products = [];
        if (doping) {
            for (let i = 0; i < dopingQuantity; i++) {
                products.push("doping");
            }
        }
        if (emergent) {
            products.push("emergent");
        }
        if (showcase) {
            products.push("showcase");
        }

        setExtraProducts(products) as any;

        (async () => {
            if (extraProducts && advertisement?.productType) {
                const cost = await getCost({ productType: advertisement?.productType, extraProductTypes: products, onlyForExtraProduct: true })

                setCost(cost?.data?.price ?? 0)
                setExtraDialog(true)
            }
        })();

        // handleSubmit();
    }

    const handleExtraProductSubmit = async () => {
        const response = await addExtraProductToAdvertisement({ advertisementId: id ?? "", extraProducts: extraProducts }) as any

        console.log(response?.data?.data?.company_addExtraProductToAdvertisement?.success, 'response')
        if (response?.data?.data?.company_addExtraProductToAdvertisement?.success) {
            enqueueSnackbar("Ekstra özellikler başarıyla eklendi!", { variant: 'success' })
            setExtraDialog(false)
        }
    }

    return (
        <DashboardLayoutMobile>

            <Stack direction="row" gap={3} alignItems="center" justifyContent={"space-between"} sx={{ mb: 5 }}>
                <Stack direction="row" gap={3} alignItems="center">
                    <IconButton onClick={() => navigate(-1)}>
                        <ArrowBackIosNewRoundedIcon />
                    </IconButton>
                    <Typography variant='h1' sx={{ fontSize: { xs: '15px', md: '30px' } }}>
                        İlan Detayı
                    </Typography>
                </Stack>

                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setEditWarningDialog(true)}
                >
                    <Edit sx={{ mr: 2 }} />
                    Düzenle
                </Button>
            </Stack>


            <Box>
                {
                    isLoading ? (
                        <Box>
                            <Skeleton
                                key={id}
                                variant='rectangular'
                                sx={{
                                    p: 2,
                                    width: '100%',
                                    height: '150px'
                                }}
                            />

                            <Stack direction={{ xs: 'column', md: 'row' }} gap={3} mt={3} >

                                <Stack width={{ xs: '100%', md: '70%' }} gap={3} >
                                    <Skeleton
                                        variant='text'
                                        sx={{
                                            p: 2,
                                            width: '100%',
                                            height: '50px'
                                        }}
                                    />
                                    <Skeleton
                                        variant='text'
                                        sx={{
                                            p: 2,
                                            width: '100%',
                                            height: '50px'
                                        }}
                                    />
                                    <Skeleton
                                        variant='text'
                                        sx={{
                                            p: 2,
                                            width: '100%',
                                            height: '50px'
                                        }}
                                    />
                                    <Skeleton
                                        variant='text'
                                        sx={{
                                            p: 2,
                                            width: '100%',
                                            height: '50px'
                                        }}
                                    />
                                </Stack>

                                <Skeleton
                                    variant='rectangular'
                                    sx={{
                                        p: 2,
                                        width: '30%',
                                        height: '300px'
                                    }}
                                />

                            </Stack>
                        </Box>
                    ) :
                        (
                            <Stack direction={{ xs: 'column', md: 'column' }} gap={3} >

                                {/* ****************** advertisement header ***************** */}
                                <Box
                                    sx={{
                                        p: { xs: 2, md: 4 },
                                        backgroundImage: `url(${CandidateAdvertisementDetailImage})`,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'contain'
                                    }}
                                >
                                    <Stack
                                        direction={{ xs: 'column', md: 'row' }}
                                        // justifyContent={{ xs: 'start' }}
                                        alignItems={{ xs: 'center' }}
                                        gap={3}
                                        sx={{
                                            bgcolor: '#FFFFFFcc',
                                            borderWidth: '2px',
                                            borderColor: 'primary.lighter',
                                            borderStyle: 'solid',
                                            p: 2,
                                        }}
                                    >

                                        <Box
                                            component="img"
                                            src={company?.companyInformation?.profileImage ?? "https://i.pinimg.com/474x/2f/2b/b5/2f2bb5ca49ad125ebe5e9db4f5b41608.jpg"}
                                            alt=""
                                            sx={{
                                                width: { xs: '100px', md: '100px' },
                                                height: { xs: '100px', md: '100px' },
                                                borderRadius: '50%',
                                            }}
                                        />

                                        <Stack
                                            direction={{ xs: 'column', md: 'row' }}
                                            sx={{ flex: 1, }}
                                            gap={3}
                                        >

                                            <Stack direction="column" gap={1} flex={1} sx={{ textTransform: 'capitalize' }}>
                                                <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
                                                    <Stack direction="row" gap={1} alignItems="center" flexWrap={"wrap"}>
                                                        <Typography variant='h2' color={"primary.darker"} sx={{ fontSize: { xs: '15px', md: '26px' } }}>
                                                            {advertisement?.title}
                                                        </Typography>
                                                        {advertisement?.extraProducts && Object.entries(advertisement.extraProducts.reduce((acc: any, item: any) => {
                                                            acc[item] = (acc[item] || 0) + 1;
                                                            return acc;
                                                        }, {})).map(([item, count], index) => (
                                                            <Chip
                                                                key={index}
                                                                label={`${enumFunctions.findAndDisplayName(ExtraProductEnums, item)} ${Number(count) > 1 ? `x${count}` : ''}`}
                                                                color="primary"
                                                                variant="outlined"
                                                                size='small'
                                                                style={{ borderRadius: 100 }}
                                                            />
                                                        ))}
                                                    </Stack>
                                                    <Stack direction={"column"} gap={2}>
                                                        <Chip
                                                            label={
                                                                advertisement?.status === "onboardingFirstCompleted" ? "Taslak" :
                                                                    advertisement?.status === "onboardingSecondCompleted" ? "Taslak" :
                                                                        advertisement?.status === "onboardingThirdCompleted" ? "Taslak" :
                                                                            advertisement?.status === "onboardingForthCompleted" ? "Taslak" :
                                                                                advertisement?.status === "pendingApproval" ? "İncelemede" :
                                                                                    advertisement?.status === "approved" ? "Yayında" :
                                                                                        advertisement?.status === "rejected" ? "Reddedildi" :
                                                                                            advertisement?.status === "revision" ? "Revizyon" :
                                                                                                advertisement?.status === "onboardingRejected" ? "Reddedildi" : "Taslak"}
                                                            color={
                                                                advertisement?.status === "onboardingFirstCompleted" ? "default" :
                                                                    advertisement?.status === "onboardingSecondCompleted" ? "default" :
                                                                        advertisement?.status === "onboardingThirdCompleted" ? "default" :
                                                                            advertisement?.status === "onboardingForthCompleted" ? "default" :
                                                                                advertisement?.status === "pendingApproval" ? "warning" :
                                                                                    advertisement?.status === "approved" ? "success" :
                                                                                        advertisement?.status === "rejected" ? "error" :
                                                                                            advertisement?.status === "revision" ? "info" :
                                                                                                advertisement?.status === "onboardingRejected" ? "default" : "default"}
                                                            variant="filled"
                                                            size='medium' style={{ borderRadius: 100, padding: 5 }} />
                                                    </Stack>
                                                </Stack>
                                                <Typography variant='body1'>
                                                    <span style={{ color: '#000', fontWeight: 'bold' }}>
                                                        {enumFunctions.findAndDisplayName(OzgelecekFieldEnum, advertisement?.field ?? "")}
                                                    </span> ·&nbsp;
                                                    {/* {advertisement?.locations?.map((location, index) => (
                                                        <span key={index}>
                                                            {location.city} {index !== advertisement.locations.length - 1 && ','}
                                                        </span>
                                                    ))} */}
                                                </Typography>
                                            </Stack>

                                            <Divider orientation="vertical" flexItem sx={{ my: 1 }} />

                                            <Stack justifyContent="center" alignItems="center">
                                                <Stack
                                                    direction="column"
                                                    gap={1}
                                                    justifyContent="space-between"
                                                    flexWrap="wrap"
                                                    sx={{ '&>*': { flex: 1 } }}
                                                >

                                                    {/* <CreateApplicationDialog
                                            loading={createApplicationLoading}
                                            onClick={handleCreateApplication}
                                            title={`Apply to ${advertisement?.title ?? "advertisement"}`}
                                            applyDialog={applyDialog}
                                            setApplyDialog={setApplyDialog}
                                            advertisement={advertisement}
                                        /> */}

                                                    {/* <Typography>
                                        {moment(advertisement?.createdAt ?? "").format('DD MMM YYYY')}
                                    </Typography>
                                    <Typography>
                                        {moment(advertisement?.updatedAt ?? "").format('DD MMM YYYY')}
                                    </Typography> */}

                                                </Stack>


                                                {/* <Typography>
                                    <span style={{ color: '#000', fontWeight: 'bold' }}>
                                        {enumFunctions.findAndDisplayName(LanguageEnums, advertisement?.language ?? "")} &nbsp;
                                    </span>
                                    {advertisement?.quota} kota
                                </Typography> */}

                                            </Stack>

                                        </Stack>

                                    </Stack>
                                </Box>


                                {(((advertisement?.extraProducts?.filter(word => word === "doping").length || 0) < 5) || !advertisement?.extraProducts?.includes("emergent") || !advertisement?.extraProducts?.includes("showcase")) &&
                                    <Grid item xs={12} bgcolor={"secondary.lighter"} p={3} >
                                        <Typography variant="subtitle1" color="black">Ekstra Özellik Ekle</Typography>

                                        {(advertisement?.extraProducts?.filter(word => word === "doping").length || 0) < 5 && <Typography mt={2} variant="subtitle1" color="primary" display={"flex"} alignItems={"center"} gap={2}><Iconify icon="ri:medicine-bottle-line" width={26} /> Doping ilan
                                            <FormikSwitch2 onChange={(checked: boolean) => setDoping(checked) as any} checked={doping} />

                                            {doping && <FormikSelect2 name="dopingQuantity" label={"Doping Miktarı"} sx={{ width: 115 }} size='small' disabled={!doping}
                                                defaultValue={dopingQuantity} onChange={(value: number) => setDopingQuantity(value) as any}
                                                list={
                                                    (advertisement?.extraProducts?.filter(word => word === "doping").length || 0) === 1 ? [{ value: 1, name: "1" }, { value: 2, name: "2" }, { value: 3, name: "3" }, { value: 4, name: "4" }] :
                                                        (advertisement?.extraProducts?.filter(word => word === "doping").length || 0) === 2 ? [{ value: 1, name: "1" }, { value: 2, name: "2" }, { value: 3, name: "3" }] :
                                                            (advertisement?.extraProducts?.filter(word => word === "doping").length || 0) === 3 ? [{ value: 1, name: "1" }, { value: 2, name: "2" }] :
                                                                (advertisement?.extraProducts?.filter(word => word === "doping").length || 0) === 4 ? [{ value: 1, name: "1" }] :
                                                                    (advertisement?.extraProducts?.filter(word => word === "doping").length || 0) >= 5 ? [] :
                                                                        [{ value: 1, name: "1" }, { value: 2, name: "2" }, { value: 3, name: "3" }, { value: 4, name: "4" }, { value: 5, name: "5" }]
                                                }
                                            />}</Typography>}

                                        {(advertisement?.extraProducts?.filter(word => word === "doping").length || 0) < 5 && <Typography fontStyle={"italic"} variant="body2" color="textSecondary">Doping ilan kullanarak diğer ilanlardan daha önde ilanınızı yayınlatabilirsiniz.</Typography>}

                                        {!advertisement?.extraProducts?.includes("emergent") && <Typography mt={4} variant="subtitle1" color="primary" display={"flex"} alignItems={"center"} gap={2}><Iconify icon="fluent:alert-urgent-16-regular" width={26} /> Acil ilan
                                            <FormikSwitch2 onChange={(checked: boolean) => setEmergent(checked) as any} checked={emergent} />
                                        </Typography>}
                                        {!advertisement?.extraProducts?.includes("emergent") && <Typography fontStyle={"italic"} variant="body2" color="textSecondary">Acil ilan kullanarak adayların dikkatini daha çabuk çekebilirsiniz.</Typography>}

                                        {!advertisement?.extraProducts?.includes("showcase") && <Typography mt={4} variant="subtitle1" color="primary" display={"flex"} alignItems={"center"} gap={2}><Iconify icon="material-symbols:photo-frame-outline" width={26} /> Vitrin ilan
                                            <FormikSwitch2 onChange={(checked: boolean) => setShowcase(checked) as any} checked={showcase} />
                                        </Typography>}
                                        {!advertisement?.extraProducts?.includes("showcase") && <Typography fontStyle={"italic"} variant="body2" color="textSecondary">Vitrin ilan kullanarak ilanınızı daha fazla adaya gösterebilirsiniz.</Typography>}

                                        <Button sx={{ mt: 4 }} color='primary' variant='contained'
                                            onClick={handleExtraProductChange}>Ekstra Özellik Ekle</Button>
                                    </Grid>}
                                {/* ****************** advertisement body ***************** */}
                                <Stack direction={{ xs: 'column', md: 'row' }} gap={3}>

                                    {/* ****************** advertisement information ***************** */}
                                    <Stack
                                        gap={3}
                                        sx={{
                                            flex: 1,
                                        }}
                                    >

                                        <Box>
                                            <Typography variant='h5'>
                                                Açıklama
                                            </Typography>

                                            <Typography variant='body2' >
                                                {HTMLReactParser(advertisement?.description ?? "")}
                                            </Typography>
                                        </Box>

                                        <Stack
                                            gap={1}
                                            sx={{
                                                '& p': {
                                                    color: '#5344d2',
                                                    fontWeight: 'bold',
                                                },
                                                '& span': {
                                                    color: '#000',
                                                    fontWeight: 'normal',
                                                },
                                            }}
                                        >

                                            <Typography variant='body2'>
                                                <span>Başlama Tarihi: </span>
                                                {moment(advertisement?.createdAt).locale('tr').format('DD MMMM YYYY')}
                                            </Typography>

                                            <Typography variant='body2'>
                                                <span>Departman: </span>
                                                {advertisement?.department}
                                            </Typography>

                                            <Typography variant='body2' >
                                                <span>Dil: </span>
                                                {enumFunctions.findAndDisplayName(LanguageEnums, advertisement?.language ?? "")}
                                            </Typography>

                                            <Typography variant='body2'>
                                                <span>Kota: </span>
                                                {advertisement?.quota}
                                            </Typography>

                                            <Typography variant='body2'>
                                                <span>Çalışma Şekli:</span> {enumFunctions.findAndDisplayName(PlaceOfWorkEnums, advertisement?.place ?? "")}
                                            </Typography>
                                            <Typography variant='body2'>
                                                <span>Pozisyon:</span> {enumFunctions.findAndDisplayName(PositionLevelEnums, advertisement?.positionLevel ?? "")}
                                            </Typography>
                                            <Typography variant='body2'>
                                                <span>İş Tipi:</span> {enumFunctions.findAndDisplayName(WorkTypeEnums, advertisement?.workType ?? "")}
                                            </Typography>


                                        </Stack>

                                        {/* <Divider orientation="horizontal" variant="fullWidth" flexItem /> */}






                                        <Typography variant='body2'>
                                            <span>Oluşturulma Tarihi : </span>
                                            {moment(advertisement?.createdAt).locale('tr').format('DD MMMM YYYY')}
                                        </Typography>


                                    </Stack>

                                    <Divider orientation="vertical" variant="fullWidth" flexItem />
                                    {/* ****************** company information ***************** */}
                                    {/* <Stack
                                        sx={{
                                            bgcolor: '#f1f1f1AA',
                                            borderWidth: '1px',
                                            borderColor: 'primary.lighter',
                                            borderStyle: 'dashed',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            px: 3,
                                            py: 5,
                                            height: 'fit-content',
                                            width: { xs: '100%', md: '300px' },
                                        }}
                                    >

                                        <Box
                                            component="img"
                                            src="https://i.pinimg.com/474x/2f/2b/b5/2f2bb5ca49ad125ebe5e9db4f5b41608.jpg"
                                            alt=""

                                            sx={{
                                                width: { xs: '100px', md: '100px' },
                                                m: 'auto',
                                                borderRadius: '50%',
                                            }}
                                        />

                                        <Stack
                                            gap={2}
                                            sx={{
                                                mt: 5,
                                                flexDirection: 'column',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                '& span': {
                                                    fontWeight: 'bold',
                                                    color: '#000'
                                                }
                                            }}
                                        >

                                            <Typography variant='h3'>
                                                Company Name
                                            </Typography>

                                            <Typography variant='body1'>
                                                <span>Address : </span> District / City
                                            </Typography>

                                            <Typography variant='body1'>
                                                <span>Sector : </span> Marketing
                                            </Typography>

                                            <Typography variant='body1'>
                                                <span>Employee : </span> 10 - 50 Employees
                                            </Typography>

                                            <Typography variant='body1'>
                                                <span>Phone Number : </span> 555 555 5555
                                            </Typography>

                                            <Typography variant='body1'>
                                                <span>Website : </span> www.example.com
                                            </Typography>

                                        </Stack>

                                    </Stack> */}

                                    <Stack
                                        gap={3}
                                        sx={{
                                            flex: 1,
                                        }}>
                                        <Box>
                                            <Typography variant='h5'>
                                                Gereklilikler
                                            </Typography>

                                            <Stack
                                                gap={1}
                                                sx={{
                                                    '& p': {
                                                        color: '#5344d2',
                                                        fontWeight: 'bold',
                                                        // fontWeight: 'normal',

                                                    },
                                                    '& span': {
                                                        color: '#000',
                                                        fontWeight: 'normal',
                                                        // fontWeight: 'bold',

                                                    },
                                                }}
                                            >
                                                <Typography variant='body2' mt={2}>
                                                    <span>Sürücü Belgesi: </span>
                                                    {advertisement?.requiredDriverLicense?.toUpperCase()}
                                                </Typography>
                                                <Typography variant='body2'>
                                                    <span>Bilmesi Gereken Diller: </span>
                                                    {
                                                        advertisement?.requiredLanguages?.map((item: any, index: number) => {
                                                            return <Typography key={index} fontSize={"small"} >
                                                                {item.name} :
                                                                <i>
                                                                    {"Okuma: " + getNameOfEnum(LanguageLevelEnum, item.reading) +
                                                                        " | Yazma: " + getNameOfEnum(LanguageLevelEnum, item.writing) +
                                                                        " | Dinleme: " + getNameOfEnum(LanguageLevelEnum, item.listening)}
                                                                </i>
                                                            </Typography>
                                                        })
                                                    }
                                                </Typography>
                                                <Typography variant='body2' >
                                                    <span>Eğitim Seviyesi: </span>
                                                    {advertisement?.requiredEducationLevel?.map((item: any, index: number) => index === 0 ? enumFunctions.findAndDisplayName(EducationLevel, item) : (', ' + enumFunctions.findAndDisplayName(EducationLevel, item)))}
                                                    {/* educationLevels.map(level => enumFunctions.findAndDisplayName(EducationLevel, level)) */}
                                                </Typography>

                                                <Stack direction="row" spacing={1} flexWrap="wrap" alignItems={'center'}>

                                                    <Typography variant='body2' >
                                                        <span>Gerekli Özellikler: </span>

                                                    </Typography>

                                                    {
                                                        advertisement?.requiredSkills?.map((skill) => {
                                                            return (
                                                                <Chip
                                                                    key={skill}
                                                                    label={skill}
                                                                    color="secondary"
                                                                    sx={{ textTransform: 'capitalize' }}
                                                                    style={{ borderRadius: 100 }}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    </Stack>

                                </Stack>
                            </Stack>

                        )
                }
            </Box>

            <Dialog open={extraDialog} onClose={() => setExtraDialog(false)}>
                <DialogTitle>
                    Ekstra Özellikler
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body2">
                        Eklemek istediğiniz ekstra özellik kredi tutarı : <strong>{cost}</strong>. Onaylıyor musunuz?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={() => setExtraDialog(false)}>Vazgeç</Button>
                    <Button variant="contained" color="primary" onClick={handleExtraProductSubmit}>Onayla</Button>
                </DialogActions>
            </Dialog>


            <Dialog open={editWarningDialog} onClose={() => setEditWarningDialog(false)}>
                <DialogTitle>
                    Düzenleme Uyarısı
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body2">
                        İlanı düzenlemek istediğinizde ilanın yayından kalkacağını ve tekrar onay sürecine gireceğini unutmayın. Devam etmek istiyor musunuz?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={() => setEditWarningDialog(false)}>Vazgeç</Button>
                    <Button variant="contained" color="primary" onClick={() => {
                        navigate(`${routes.company.dashboard.updateAdvertisementMobile}/${advertisement?._id}`)
                    }}>Devam Et</Button>
                </DialogActions>
            </Dialog>


        </DashboardLayoutMobile >
    )
}
