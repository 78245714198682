import { Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Skeleton, Stack, TableHead, Typography } from '@mui/material'
import { ICustomTableHeadCell, MuiTransition, CustomTable, CustomTableHeader, Iconify } from 'components'
import { ICandidateRefencesVariables, ICandidateReferenceItem, useCandidateCreateReferenceMutation, useGetCandidateReferencesQuery } from 'context'
import { DashboardLayout } from 'layouts'
import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import { ReferenceForm } from 'sections'


import {
    Table,
    Paper,
    Switch,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    FormControlLabel,
    Card,
} from '@mui/material';
import { enumFunctions } from 'utils'
import { CandidateReferenceStatusEnums } from 'types'



export const CandidateReferancesPage = () => {



    const [candidate_createReference, { data, isLoading, isSuccess, isError }] = useCandidateCreateReferenceMutation()
    // const [selectedReference, setSelectedReference] = useState(null)

    const handleCreateReference = async (values: any) => {

        try {

            const response: any = await candidate_createReference({ variables: values })
            console.log({ response });

            if (response?.data?.candidate_createReference?.token) {
                enqueueSnackbar("Successfully Create Reference!")
            } else {
                response?.data?.errors?.forEach((error: any) => {
                    if (error.message === "Reference already created") {
                        enqueueSnackbar("Reference already created", { variant: 'error' })
                    } else if (error?.message === "Maximum reference count exceeded") {
                        enqueueSnackbar("Maksimum referans sayısına ulaşıldı", { variant: 'error' })
                    } else {
                        enqueueSnackbar(error?.message, { variant: 'error' })
                    }
                })
            }

        } catch (error: any) {

            console.log({ error });
            error?.data?.errors?.forEach((error: any) => {
                if (error.message === "Reference already created") {
                    enqueueSnackbar("Reference already created", { variant: 'error' })
                } else if (error?.message === "Maximum reference count exceeded") {
                    enqueueSnackbar("Maksimum referans sayısına ulaşıldı", { variant: 'error' })
                } else {
                    enqueueSnackbar(error?.message, { variant: 'error' })
                }
            })

        }


    }



    return (
        <DashboardLayout>

            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={6}>
                <Typography variant='h1'>
                    Referanslarım
                </Typography>
                <ReferenceForm
                    loading={isLoading}
                    onSubmit={handleCreateReference}
                />
            </Stack>

            <Box bgcolor={"secondary.lighter"} p={4} mt={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}>
                    <Iconify icon={"material-symbols:info-outline"} />
                </Box>
                <Typography variant="body2" >
                    Bu sayfada referanslarınızı görebilir ve referans ekleyebilirsiniz. Eklediğiniz referansları özgelecek oluştururken kullanabilirsiniz. Referans onayı gerçekleştiğinde ilanlara başvurabilirsiniz.
                    (Maksimum 5 referans eklenebilir)
                </Typography>
            </Box>



            <CandidateReferencersTable />
            {/* {
                isFetching ? (
                    <>
                        Loading...
                    </>
                ) : (
                    <Grid container spacing={3} sx={{ mt: 3 }}>
                        {
                            references?.data?.map((item: any, index: any) => {
                                // console.log({ item });
                                return (
                                    <Grid item key={index} >

                                        <Box
                                            onClick={() => setSelectedReference(item)}
                                            sx={{
                                                boxShadow: '1px 1px 5px 0px #ddd',
                                                p: 2,
                                                cursor: 'pointer',
                                                transitionDuration: '200ms',

                                                '&:hover': {
                                                    transform: 'scale(1.05)',
                                                    boxShadow: '1px 1px 10px 0px #ccc',

                                                }
                                            }}
                                        >
                                            {item?.referenceInformation?.email}
                                        </Box>

                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                )
            } */}





            {/* <Dialog
                open={!!selectedReference}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setSelectedReference(null)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Reference Detail</DialogTitle>

                <DialogContent>


                    {
                        JSON.stringify(selectedReference)
                    }

                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setSelectedReference(null)}>
                        Close
                    </Button>

                    <Button onClick={handleCreateReference}>
                        Create
                    </Button>
                </DialogActions>
            </Dialog> */}

        </DashboardLayout>
    )
}


const CandidateReferencersTable = () => {

    const [variables, setVariables] = useState<ICandidateRefencesVariables>({
        filter: {
            email: "",
            referenceStatus: null,
        },
        pagination: {
            pageSize: 5,
            page: 1,
            sort: ""
        }
    })

    const { data: references, isLoading } = useGetCandidateReferencesQuery({ ...variables })



    const headCells: ICustomTableHeadCell[] = [
        {
            id: 'idx',
            numeric: false,
            disablePadding: true,
            label: '#',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Ad Soyad',
        },
        {
            id: 'email',
            numeric: false,
            disablePadding: false,
            label: 'Email',
        },
        {
            id: 'jobTitle',
            numeric: false,
            disablePadding: false,
            label: 'Meslek / Ünvan',
        },
        {
            id: 'phone',
            numeric: false,
            disablePadding: false,
            label: 'Telefon No.',
        },
        {
            id: 'relation',
            numeric: false,
            disablePadding: false,
            label: 'Yakınlığı',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Durumu',
        },
    ];


    const [selected, setSelected] = useState<readonly number[]>([]);


    const handleSelectOne = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event?.target?.checked) {
            const newSelected = references?.data?.map((n: any) => n?._id);
            setSelected(newSelected ?? []);
            return;
        }
        setSelected([]);
    };




    const isSelected = (id: number) => selected?.indexOf(id) !== -1;

    const emptyRows = (variables?.pagination?.page ?? 1) > 0
        ? (variables?.pagination?.pageSize ?? 10) - (references?.data?.length ?? 0)
        : 0;

    // console.log({ variables });



    // const handleChangePage = (event: unknown, newPage: number) => {
    //     setVariables((prevState: ICandidateRefencesVariables) => ({
    //         ...prevState,
    //         pagination: {
    //             ...prevState?.pagination,
    //             page: newPage + 1
    //         }
    //     }))
    // };

    // const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     console.log({ event });

    //     setVariables((prevState: ICandidateRefencesVariables) => ({
    //         ...prevState,
    //         pagination: {
    //             ...prevState?.pagination,
    //             pageSize: Number(event?.target?.value)
    //         }
    //     }))
    // };

    return (
        <Card sx={{ width: '100%', p: { xs: 1, sm: 2, md: 3 }, my: 3 }}>
            <TableContainer>
                <Table
                    // sx={{ width: '100%' }}
                    aria-labelledby="tableTitle"
                    size='medium'
                >

                    <CustomTableHeader
                        headCells={headCells}
                        numSelected={selected.length}
                        onSelectAllClick={handleSelectAllClick}
                        rowCount={references?.data.length ?? 0}
                    // order={order}
                    // orderBy={orderBy}
                    // onRequestSort={handleRequestSort}
                    />

                    <TableBody>
                        {

                            isLoading ? (
                                [1, 2, 3]?.map((item) => {

                                    return (
                                        <TableRow key={item}>
                                            <TableCell colSpan={headCells?.length}>
                                                <Skeleton variant='rectangular' height={60} />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            ) : (
                                references?.data?.map((row: ICandidateReferenceItem, index: number) => {
                                    const isItemSelected = isSelected(index);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            key={row?._id ?? index}

                                            hover
                                            onClick={(event) => handleSelectOne(event, index)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            selected={isItemSelected}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            {/* <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            checked={isItemSelected}
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                        />
                                    </TableCell> */}
                                            <TableCell component="th" scope="row">{index + 1}</TableCell>
                                            <TableCell component="th" id={labelId} scope="row" padding="none" sx={{ pl: 2 }}>
                                                {row?.referenceInformation?.name} {row?.referenceInformation?.surname}
                                            </TableCell>
                                            <TableCell align="left">{row?.referenceInformation?.email}</TableCell>
                                            <TableCell align="left">{row?.referenceInformation?.jobTitle}</TableCell>
                                            <TableCell align="left">{row?.referenceInformation?.phoneNumber}</TableCell>
                                            <TableCell align="left">{row?.referenceInformation?.relationWithCandidate}</TableCell>
                                            <TableCell align="left">
                                                <Chip
                                                    label={enumFunctions.findAndDisplayName(CandidateReferenceStatusEnums, row?.referenceStatus)}
                                                    color={
                                                        row?.referenceStatus === "referenceApproved"
                                                            ? "success"
                                                            : (row?.referenceStatus === "informationRejected" || row?.referenceStatus === "referenceRejected") ? "error" :
                                                                "info"
                                                    }
                                                    variant="outlined"
                                                    style={{ borderRadius: 100 }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            )

                        }

                        {/* {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )} */}
                    </TableBody>



                </Table>
            </TableContainer>

            {/* <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 20]} // içindeki numaraları dropdown olarak gösteriyor
                count={references?.count ?? 0} // toplam eleman sayısı
                rowsPerPage={variables.pagination.pageSize ?? 1} // sayfadaki eleman sayısı
                page={(variables.pagination.page ?? 1) - 1} // kaçıncı sayfa olduğunu gösteriyor, 0 ilk sayfa

                labelRowsPerPage="Sayfa başına gösterim"
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}

        </Card>
    )
}
