import {
  Box,
  Card,
  Menu,
  Grid,
  Stack,
  Button,
  Dialog,
  SxProps,
  MenuItem,
  Typography,
  IconButton,
  DialogContent,
  LinearProgress,
  CircularProgress
} from "@mui/material";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { Form, Formik, useFormikContext } from 'formik';

import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Lottie from "lottie-react";
import { OkAnimation } from "assets";
import {
  SexsList,
  ILanguage,
  GendersList,
  IScholarship,
  ICertificate,
  IHigherEducation,
  LanguageLevelEnum,
  IProgrammingLanguage,
  IParticipatedProjects,
  DriverLicenseStatusList,
  ICandidateInformationInput,
  ComputerKnowledgeLevelsList,
} from "types";

import {
  HobbiesForm,
  LanguageForm,
  HighSchoolsForm,
  CertificateForm,
  ScholarshipsForm,
  HigherEducationForm,
  ParticipatedProjectForm,
  ProgrammingLanguageForm,
  WorkExperienceInMonthComponent,
} from "sections";

import {
  useAppSelector,
  useGetCitiesQuery,
  useGetCountriesQuery,
  useCandidateUpdateCandidateMutation,
  useCandidateUpdateCandidateOnboardingStatusMutation,
} from "context";

import { enumFunctions } from "utils";
import { OnboardingLayout } from 'layouts';
import { useCandidateUpdateCandidateFormValidation } from "lib/form-validation";
import { FormikDatePicker, FormikSelect, FormikSelectCountry, FormikTextField, FormikTextFieldPhone, Iconify, MuiTransition } from "components";
import dayjs from "dayjs";
import { routes } from "routes";





export const CandidateOnboardingPageMobile = () => {

  const localValues = sessionStorage.getItem("candidateOnboardingInfo")
    ? JSON.parse(sessionStorage.getItem("candidateOnboardingInfo") ?? "")
    : null

  const { formIndex } = useAppSelector(state => state.candidateCreateOzgelecek)
  const [updateCandidate, { isLoading }] = useCandidateUpdateCandidateMutation()
  const { validationSchema, initialValues } = useCandidateUpdateCandidateFormValidation({ values: localValues })
  const [updateCandidateOnboardingStatus, { isLoading: isStatusLoading }] = useCandidateUpdateCandidateOnboardingStatusMutation({})



  const handleSubmit = async (values: ICandidateInformationInput, setFieldValue: any) => {

    try {

      const response: any = await updateCandidate({ variables: values })
      console.log({ response });

      if (response?.data?.candidate_updateCandidate?._id) {

        await updateCandidateOnboardingStatus({}).then((res) => {
          console.log({ res });

          if ('data' in res && res.data?.candidate_updateCandidateOnboardingStatus?.success) {
            setFieldValue("onboardingFormStep", ((values.onboardingFormStep ?? 0) + 1))
          } else {
            enqueueSnackbar("Successfully created candidate, but status could not updated!", { variant: "info" })
          }
        })

      } else {
        enqueueSnackbar("Kayıt yapılamadı!", { variant: "error" })
      }

    } catch (error) {
      console.log({ error });
      enqueueSnackbar("Something went wrong!", { variant: "error" })
    }

  }


  return (
    <div style={{marginTop: 10, padding: 10}}>

      <Typography variant="h3" color="primary.darker" textAlign={"center"} mb={2}>
        Ön Kayıt
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setFieldValue }) => {
          handleSubmit(values, setFieldValue)
        }}
      >
        {
          () => {
            return (
              <Form>
                <CandidateUpdateCandidateForm isLoading={isLoading} />
              </Form>
            )
          }
        }
      </Formik>
    </div >
  )
}





const CandidateUpdateCandidateForm = ({ isLoading }: { isLoading: boolean }) => {


  const { values, setFieldValue } = useFormikContext<ICandidateInformationInput>()
  const formDialog = values?.formDialog
  const setFormDialog = (data: any) => {
    setFieldValue("formDialog", data)
  }

  useEffect(() => {
    sessionStorage.setItem("candidateOnboardingInfo", JSON.stringify(values))
  }, [values])


  const renderText = () => {
    switch (values.onboardingFormStep) {
      case 1:
        return "Kişisel Bilgiler (1/2)"

      case 2:
        return "Kişisel Bilgiler (2/2)"

      case 3:
        return "Kariyer Bilgileri (1/2)"

      case 4:
        return "Kariyer Bilgileri (2/2)"

      case 5:
        return "Kişisel Bilgiler (1/2)"

      default:
        return "";
    }
  }

  const renderForm = () => {

    switch (values.onboardingFormStep) {
      case 1:
        return <OnboardingForm1 />

      case 2:
        return <OnboardingForm2 />

      case 3:
        return <OnboardingForm3 setFormDialog={setFormDialog} />

      case 4:
        return <OnboardingForm4 setFormDialog={setFormDialog} isLoading={isLoading} />

      case 5:
        return <OnboardingForm5 />

      default:
        return null;
    }
  }


  return (
    <Box>

      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mb: 3, }}>

        {
          (values?.onboardingFormStep ?? 5) >= 5 ? null : (
            <Box display={"flex"} flexDirection={"row"} alignItems={"flex-end"} justifyContent={"space-between"} width={"100%"} mb={2}>
              <Typography variant="h6" color="primary.darker">
                {renderText()}
              </Typography>

              <Typography variant="h6" color="primary.darker" mr={1}>
                {`${values.onboardingFormStep}/4`}
              </Typography>
            </Box>
          )
        }

        <Box sx={{ width: '100%' }}>
          <LinearProgress
            sx={{ height: 10 }}
            variant="determinate"
            value={
              (values?.onboardingFormStep ?? 5) >= 5 ? 100 : ((values.onboardingFormStep ?? 0) / 4) * 100
            }
          />
        </Box>

      </Box>

      <Card sx={{ width: '100%', p: 2 }}>
        {renderForm()}
      </Card>


      <Dialog
        open={!!formDialog}
        TransitionComponent={MuiTransition}
        keepMounted
        onClose={() => setFormDialog(null)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent sx={{ p: 0 }}>

          {
            formDialog?.formType === "certificate" && (
              <CertificateForm
                initialValues={formDialog.formValue}
                onSubmit={(certificate) => {
                  if (formDialog.itemIndex || formDialog.itemIndex === 0) {
                    values.certificates[formDialog.itemIndex] = certificate
                    setFieldValue("certificates", [...values.certificates])
                  } else {
                    setFieldValue("certificates", [...values.certificates, certificate])
                  }
                  setFormDialog(null)
                }}
              />
            )
          }


          {
            formDialog?.formType === "language" && (
              <LanguageForm
                initialValues={formDialog.formValue}
                onSubmit={(language) => {
                  if (formDialog.itemIndex || formDialog.itemIndex === 0) {
                    values.languages[formDialog.itemIndex] = language
                    setFieldValue("languages", [...values.languages])
                  } else {
                    setFieldValue("languages", [...values.languages, language])
                  }
                  setFormDialog(null)
                }}
              />
            )
          }


          {
            formDialog?.formType === "participatedProject" && (
              <ParticipatedProjectForm
                initialValues={formDialog.formValue}
                onSubmit={(participatedProject) => {
                  if (formDialog.itemIndex || formDialog.itemIndex === 0) {
                    values.participatedProjects[formDialog.itemIndex] = participatedProject
                    setFieldValue("participatedProjects", [...values.participatedProjects])
                  } else {
                    setFieldValue("participatedProjects", [...values.participatedProjects, participatedProject])
                  }
                  setFormDialog(null)
                }}
              />
            )
          }


          {
            formDialog?.formType === "scholarship" && (
              <ScholarshipsForm
                initialValues={formDialog.formValue}
                onSubmit={(scholarship) => {
                  if (formDialog.itemIndex || formDialog.itemIndex === 0) {
                    values.scholarships[formDialog.itemIndex] = scholarship
                    setFieldValue("scholarships", [...values.scholarships])
                  } else {
                    setFieldValue("scholarships", [...values.scholarships, scholarship])
                  }
                  setFormDialog(null)
                }}
              />
            )
          }


          {/* {
            formDialog?.formType === "programmingLanguage" && (
              <ProgrammingLanguageForm
                list={values.programmingLanguages ?? []}
                onSubmit={(programmingLanguage) => {
                  setFieldValue("programmingLanguages", [...values.programmingLanguages, programmingLanguage])
                }}

                onDeleteItem={(index) => {
                  const newList = [...values.programmingLanguages]
                  newList.filter((item, i) => i !== index)
                  setFieldValue("programmingLanguages", newList)

                }}
              />
            )
          } */}


          {
            formDialog?.formType === "higherEducation" && (
              <HigherEducationForm
                initialValues={formDialog.formValue}
                onSubmit={(higherEducation) => {
                  if (formDialog.itemIndex || formDialog.itemIndex === 0) {
                    values.education.higherEducation[formDialog.itemIndex] = higherEducation
                    setFieldValue("education.higherEducation", [...values.education.higherEducation])
                  } else {
                    setFieldValue("education.higherEducation", [...values.education.higherEducation, higherEducation])
                  }
                  setFormDialog(null)
                }}
              />
            )
          }


        </DialogContent>
      </Dialog>

    </Box>

  )
}



const AddNewItem = ({ onClick, sx }: { onClick: () => void, sx: SxProps }) => {

  return (
    <Box
      onClick={onClick}
      sx={{
        p: 2,
        border: '2px dashed #ccc',
        cursor: 'pointer',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        transitionDuration: '200ms',

        '&:hover': {
          boxShadow: '0px 0px 10px 0px #00000022',
        },

        ...sx,
      }}
    >
      <AddIcon />
      <Typography variant="body1" color="text.secondary" mt={1}>
        Ekle
      </Typography>
    </Box>
  )
}

interface IMoreMenu {
  onUpdate?: () => void,
  onDelete?: () => void,
}

const MoreMenu = ({ onUpdate, onDelete }: IMoreMenu) => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        <MoreHorizIcon />
      </IconButton>

      {(onDelete || onUpdate) && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >

          {
            onUpdate && (
              <MenuItem
                onClick={() => {
                  onUpdate()
                  handleClose()
                }}
                sx={{ justifyContent: 'space-between' }}
              >
                <Typography variant="body2" color="text.black">
                  Değiştir
                </Typography>
                <Iconify icon="mdi:pencil" sx={{ color: 'info.main', ml: 1 }} />
              </MenuItem>
            )
          }

          {
            onDelete && (
              <MenuItem
                onClick={() => {
                  onDelete()
                  handleClose()
                }}
                sx={{ justifyContent: 'space-between' }}
              >
                <Typography variant="body2" color="text.black">
                  Sil
                </Typography>
                <Iconify icon="mdi:delete" sx={{ color: 'error.main', ml: 1 }} />
              </MenuItem>
            )
          }



        </Menu>
      )}
    </>
  )
}






//----------------------------------------------------------------
//--------------------- form progress ----------------------------
//----------------------------------------------------------------

const OnboardingForm1 = () => {

  const { values, errors, setFieldValue,
    // getFieldHelpers, getFieldMeta, getFieldProps 
  } = useFormikContext<ICandidateInformationInput>()
  const { data: countriesData, } = useGetCountriesQuery({})
  const { data: citiesData } = useGetCitiesQuery(
    { countryName: values?.personalInformation?.country?.name ?? values?.personalInformation?.country },
    { skip: !values?.personalInformation?.country }
  )


  // console.log({
  //   helpers: getFieldHelpers("personalInformation.name"),
  //   meta: getFieldMeta("personalInformation.name"),
  //   props: getFieldProps("personalInformation.name"),
  // });


  return (
    <Grid container spacing={3} >

      <Grid item xs={12} sm={6}>
        <FormikTextField
          name="personalInformation.name"
          label="İsim*"
          upperCase
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormikTextField
          name="personalInformation.surname"
          label="Soyisim*"
          upperCase
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormikTextFieldPhone
          name="personalInformation.phoneNumber"
          label="Telefon Numarası*"
        />
      </Grid>



      {/* <Grid item xs={12}>
          <FormikTextField
            multiline
            minRows={4}
            name="personalInformation.description"
            label="Description"
          />
        </Grid> */}


      {/* <Grid item xs={12} sm={6}>
          <FormikUpload
            name="personalInformation.backgroundImageUrl"
            label="Background Image"
          />
        </Grid> */}

      {/* <Grid item xs={12} sm={6}>
          <FormikUpload
            name="personalInformation.profileImageUrl"
            label="Profile Image"
          />
        </Grid> */}



      {/* <Grid item xs={12} sm={6}>
          <FormikSelect
            name="personalInformation.sex"
            label="Sex"
            list={SexsList}
          />
        </Grid> */}

      {/* <Grid item xs={12} sm={6}>
          <FormikSelect
            name="personalInformation.gender"
            label="Gender"
            list={GendersList}
          />
        </Grid> */}

      {/* {
          defaultValues?.gender === "specified" && (
            <Grid item xs={12}>
              <FormikTextField
                multiline
                minRows={4}
                name="personalInformation.genderDetail"
                label="Gender Detail"
              />
            </Grid>
          )
        } */}

      {/* <Grid item xs={12} sm={6}>
          <FormikSelectCountry
            name="personalInformation.nationality"
            label="Nationality"
            list={countriesData?.data ?? []}
          />
        </Grid> */}


      <Grid item xs={12} sm={6}>
        <FormikSelectCountry
          name="personalInformation.country"
          label="Ülke*"
          list={countriesData?.data ?? []}
        />
      </Grid>


      <Grid item xs={12} sm={6}>
        <FormikSelect
          name="personalInformation.city"
          label="Şehir*"
          list={citiesData?.data ?? []}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        {/* <FormikTextField
          name="personalInformation.district"
          label="İlçe*"
          upperCase
        /> */}
        <FormikSelect
          name="personalInformation.district"
          label="İlçe"
          list={citiesData?.data?.find((item) => item.name === values.personalInformation?.city)?.districts ?? []}
        />
      </Grid>

      {/* <Grid item xs={12} sm={6}>
          <FormikTextField
            name="personalInformation.citizenship"
            label="Citizenship"
          />
        </Grid> */}


      <Grid item xs={12} >
        <FormikTextField
          multiline
          minRows={4}
          name="personalInformation.address"
          label="Adres"
          upperCase
        />
      </Grid>





      <Grid item xs={12} justifyContent={"flex-end"} display={"flex"}>
        <Button
          sx={{ px: 5 }}
          onClick={() => setFieldValue("onboardingFormStep", ((values.onboardingFormStep ?? 0) + 1))}
          variant="contained"
          color="primary"
          disabled={Boolean(values.onboardingFormStep === 1 &&
            (!!errors.personalInformation?.name || !values.personalInformation?.name)) ||
            (!!errors.personalInformation?.surname || !values.personalInformation?.surname) ||
            (!!errors.personalInformation?.phoneNumber || !values.personalInformation?.phoneNumber) ||
            (!!errors.personalInformation?.country || !values.personalInformation?.country) ||
            (!!errors.personalInformation?.city || !values.personalInformation?.city) ||
            (!!errors.personalInformation?.district || !values.personalInformation?.district)
          }
        >
          İleri
        </Button>
      </Grid>

    </Grid>
  )
}


const OnboardingForm2 = () => {

  const { values, errors, setFieldValue } = useFormikContext<ICandidateInformationInput>()
  const { data: countriesData, } = useGetCountriesQuery({})

  return (
    <Grid container spacing={3} >

      <Grid item xs={12} sm={6}>
        <FormikSelect
          name="personalInformation.sex"
          label="Cinsiyet*"
          list={SexsList}
        />
      </Grid>

      {/* <Grid item xs={12} sm={6}>
        <FormikSelect
          name="personalInformation.gender"
          label="Cinsel Yönelim"
          list={GendersList}
          defaultValue="notSpecified"
        />
      </Grid> */}


      {/* {
        values?.personalInformation?.gender === "specified" && (
          <Grid item xs={12} justifyContent={"flex-end"} display={"flex"}>

            <FormikTextField
              name="personalInformation.genderDetail"
              label="Cinsel Yönelim Detayı"
              upperCase
            />

          </Grid>
        )
      } */}



      <Grid item xs={12} sm={6}>
        <FormikSelectCountry
          name="personalInformation.nationality"
          label="Uyruk*"
          list={countriesData?.data ?? []}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormikTextField
          name="personalInformation.citizenship"
          label="Farklı Vatandaşlık"
          upperCase
        />
      </Grid>


      <Grid item xs={12} sm={6}>
        <FormikSelect
          name="smoking"
          label="Sigara Kullanımı"
          list={[
            { value: true, name: "Kullanıyorum" },
            { value: false, name: "Kullanmıyorum" }
          ]}
        />
      </Grid>


      <Grid item xs={12} sm={6}>
        <FormikDatePicker
          name="personalInformation.birthDate"
          label="Doğum Tarihi*"
          maxDate={dayjs().subtract(18, 'year').toDate()}
        />
      </Grid>



      <Grid item xs={12} sm={6}>
        <FormikSelect
          name="driverLicense.status"
          label="Sürücü Ehliyeti*"
          list={DriverLicenseStatusList}
        />
      </Grid>

      {/* <Grid item xs={12} sm={6}></Grid> */}


      {
        (values.driverLicense?.status === "exist") && (
          <>
            <Grid item xs={12} sm={6}>
              <FormikDatePicker
                name="driverLicense.issueDate"
                label="Veriliş Tarihi*"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormikTextField
                name="driverLicense.licenseType"
                label="Ehliyet Sınıfı*"
                upperCase
              />
            </Grid>
          </>
        )
      }


      <Grid item xs={12} justifyContent={"space-between"} display={"flex"}>

        <Button
          sx={{ px: 5 }}
          onClick={() => setFieldValue("onboardingFormStep", ((values.onboardingFormStep ?? 0) - 1))}
          variant="outlined"
          color="primary"
        >
          Geri
        </Button>

        <Button
          sx={{ px: 5 }}
          onClick={() => {
            setFieldValue("onboardingFormStep", ((values.onboardingFormStep ?? 0) + 1))
          }}
          variant="contained"
          color="primary"
          disabled={Boolean(

            (values.onboardingFormStep === 2) && (

              (!!errors.personalInformation?.sex || !values.personalInformation?.sex) ||
              (!!errors.personalInformation?.nationality || !values.personalInformation?.nationality) ||
              (!!errors.personalInformation?.birthDate || !values.personalInformation?.birthDate) ||
              (!values.driverLicense?.status) || (
                values.driverLicense?.status === "exist" && (
                  (!!errors.driverLicense?.issueDate || !values.driverLicense?.issueDate) ||
                  (!!errors.driverLicense?.licenseType || !values.driverLicense?.licenseType)
                )
              )

            )

          )}
        >
          İleri
        </Button>

      </Grid>

    </Grid>
  )
}


const OnboardingForm3 = ({ setFormDialog }: any) => {

  const { values, setFieldValue, } = useFormikContext<ICandidateInformationInput>()


  return (
    <Grid container spacing={5} >

      <Grid item xs={12}>
        <HighSchoolsForm
          type="onboarding-page"
          list={values?.education.highSchool}

          onSubmit={(highSchool) => {
            const isFind = values.education.highSchool.find(item => item === highSchool)
            if (isFind) return
            setFieldValue("education.highSchool", [...values.education.highSchool, highSchool])
          }}

          onDeleteItem={(index) => {
            values?.education.highSchool.splice(index, 1)
            setFieldValue("education.highSchool", [...values.education.highSchool])
          }}
        />
      </Grid>


      <Grid item xs={12}>
        {/* ************  higher educations ************* */}
        <Typography variant="h3" sx={{ mb: 2 }}>
          Üniversite Bilgileri*
        </Typography>

        <Grid container spacing={2}>
          {
            values?.education?.higherEducation?.map((higherEducation: IHigherEducation, index) => {

              return (
                <Grid item xs={12} sm={6} md={4} key={index} >
                  <Box
                    sx={{
                      p: 2,
                      height: '100%',
                      border: '2px solid #9C8FF1',
                      position: 'relative',
                      flex: '1 1 300px',

                      transitionDuration: '200ms',
                      '&:hover': {
                        boxShadow: '0px 0px 10px 0px #9C8FF166',
                      },

                    }}
                  >

                    <Iconify
                      icon="mdi:university"
                      width={25}
                      sx={{
                        color: 'primary.main',
                      }}
                    />

                    <Typography variant="h6">
                      {higherEducation.name}
                    </Typography>

                    <Typography variant="body2">
                      {higherEducation.department}
                    </Typography>


                    <MoreMenu

                      onUpdate={() => {
                        setFormDialog({
                          formType: "higherEducation",
                          formValue: higherEducation,
                          itemIndex: index
                        })
                      }}

                      onDelete={() => {
                        values.education.higherEducation.splice(index, 1)
                        setFieldValue("education.higherEducation", values.education.higherEducation)
                      }}

                    />

                  </Box>
                </Grid>
              )
            })
          }

          <Grid item xs={12} sm={6} md={4} >
            <AddNewItem onClick={() => setFormDialog({ formType: "higherEducation" })} sx={{ height: '100%' }} />
          </Grid>

        </Grid>
      </Grid>


      <Grid item xs={12}>
        {/* ************  languages ************* */}
        <Typography variant="h3" sx={{ mb: 2 }}>
          Dil Bilgileri*
        </Typography>

        <Grid container spacing={2}>
          {
            values.languages.map((language: ILanguage, index) => {

              return (
                <Grid item xs={12} sm={6} md={4} key={index} >
                  <Box
                    sx={{
                      p: 2,
                      height: '100%',
                      border: '2px solid #9C8FF1',
                      position: 'relative',
                      flex: '1 1 300px',

                      transitionDuration: '200ms',
                      '&:hover': {
                        boxShadow: '0px 0px 10px 0px #9C8FF166',
                      },
                    }}
                  >

                    <Iconify
                      icon="entypo:language"
                      width={25}
                      sx={{
                        color: 'primary.main',
                      }}
                    />

                    <Typography variant="h5">
                      {language.name}
                    </Typography>

                    <Typography fontSize={"small"} lineHeight={1.5}>
                      <Iconify icon={"iconoir:headset-solid"} width={17} sx={{ mb: -0.5, }} /> : {enumFunctions.findAndDisplayName(LanguageLevelEnum, language.listening)},
                      <Iconify icon={"icon-park-outline:write"} width={17} sx={{ mb: -0.5, ml: 1 }} /> :{enumFunctions.findAndDisplayName(LanguageLevelEnum, language.writing)},
                      <Iconify icon={"ant-design:read-outlined"} width={17} sx={{ mb: -0.5, ml: 1 }} />  : {enumFunctions.findAndDisplayName(LanguageLevelEnum, language.reading)}
                    </Typography>



                    <MoreMenu
                      onUpdate={() => {
                        setFormDialog({
                          formType: "language",
                          formValue: language,
                          itemIndex: index
                        })
                      }}
                      onDelete={() => {
                        values.languages.splice(index, 1)
                        setFieldValue("languages", values.languages)
                      }}
                    />

                  </Box>
                </Grid>
              )
            })
          }

          <Grid item xs={12} sm={6} md={4} >
            <AddNewItem onClick={() => setFormDialog({ formType: "language" })} sx={{ height: '100%' }} />
          </Grid>


        </Grid>
      </Grid>


      <Grid item xs={12}>
        {/* ************  certificates ************* */}
        <Typography variant="h3" sx={{ mb: 2 }}>
          Kurs / Sertifika Bilgileri
        </Typography>

        <Grid container spacing={2}>

          {
            values.certificates.map((certificate: ICertificate, index) => {

              return (

                <Grid item xs={12} sm={6} md={4} key={`${certificate.name}-${index}`} >
                  <Box
                    sx={{
                      p: 2,
                      height: '100%',
                      border: '2px solid #9C8FF1',
                      position: 'relative',
                      flex: '1 1 300px',

                      transitionDuration: '200ms',
                      '&:hover': {
                        boxShadow: '0px 0px 10px 0px #9C8FF166',
                      },
                    }}
                  >

                    <Iconify
                      icon="mdi:certificate"
                      width={25}
                      sx={{
                        color: 'primary.main',
                      }}
                    />

                    <Typography variant="h6">
                      {certificate.name}
                    </Typography>

                    <Typography fontSize={"small"}>
                      {certificate.certificateInstitution}
                    </Typography>
                    {/* 
                    <Typography variant="body1" color="#999">
                      {certificate.type}
                    </Typography>

                    <Typography>
                      {moment(certificate.issuedDate).format("DD MMM YYYY")} - {moment(certificate.validUntil).format("DD MMM YYYY")}
                    </Typography> */}

                    <MoreMenu
                      onUpdate={() => {
                        setFormDialog({
                          formType: "certificate",
                          formValue: certificate,
                          itemIndex: index
                        })
                      }}
                      onDelete={() => {
                        values.certificates.splice(index, 1)
                        setFieldValue("certificates", [...values.certificates])
                      }}
                    />

                  </Box>
                </Grid>
              )
            })
          }

          <Grid item xs={12} sm={6} md={4}  >
            <AddNewItem onClick={() => setFormDialog({ formType: "certificate" })} sx={{ height: '100%' }} />
          </Grid>

        </Grid>



      </Grid>


      <Grid item xs={12} justifyContent={"space-between"} display={"flex"}>

        <Button
          sx={{ px: 5 }}
          onClick={() => {
            setFieldValue("onboardingFormStep", ((values.onboardingFormStep ?? 0) - 1))
          }}
          variant="outlined"
          color="primary"
        >
          Geri
        </Button>

        <Button
          sx={{ px: 5 }}
          onClick={() => {
            setFieldValue("onboardingFormStep", ((values.onboardingFormStep ?? 0) + 1))
          }}
          variant="contained"
          color="primary"
          disabled={Boolean(values.onboardingFormStep === 3 &&
            values.education.highSchool.length === 0) ||
            values.education.higherEducation.length === 0 ||
            values.languages.length === 0
          }
        >
          İleri
        </Button>

      </Grid>

    </Grid>
  )
}


const OnboardingForm4 = ({ setFormDialog, isLoading }: any) => {

  const { values, setFieldValue, handleSubmit } = useFormikContext<ICandidateInformationInput>()

  return (
    <Grid container spacing={5}>

      {/* ************  scholarships ************* */}
      <Grid item xs={12}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Aldığı Burslar
        </Typography>

        <Grid container spacing={2}>
          {
            values.scholarships.map((scholarship: IScholarship, index) => {

              return (
                <Grid item xs={12} sm={6} md={4} key={index} >
                  <Box
                    sx={{
                      p: 2,
                      height: '100%',
                      border: '2px solid #9C8FF1',
                      position: 'relative',
                      flex: '1 1 300px',

                      transitionDuration: '200ms',
                      '&:hover': {
                        boxShadow: '0px 0px 10px 0px #9C8FF166',
                      },
                    }}
                  >

                    <Iconify
                      icon="mdi:hand-extended"
                      width={25}
                      sx={{
                        color: 'primary.main',
                      }}
                    />
                    <Typography variant="h6">
                      {scholarship.institution}
                    </Typography>

                    <Typography variant="body1">
                      {scholarship.type}
                    </Typography>

                    {/* <Typography>
                      {moment(scholarship.receivingDate).format("DD MMM YYYY")}
                    </Typography> */}

                    <MoreMenu
                      onUpdate={() => {
                        setFormDialog({
                          formType: "scholarship",
                          formValue: scholarship,
                          itemIndex: index
                        })
                      }}
                      onDelete={() => {
                        values.scholarships.splice(index, 1)
                        setFieldValue("scholarships", values.scholarships)

                      }}
                    />

                  </Box>
                </Grid>
              )
            })
          }

          <Grid item xs={12} sm={6} md={4} >
            <AddNewItem onClick={() => setFormDialog({ formType: "scholarship" })} sx={{ flex: '1 1 300px', height: '100%', minHeight: '120px' }} />
          </Grid>

        </Grid>
      </Grid>


      {/* ************ participated projects ************* */}
      <Grid item xs={12}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Katıldığı Projeler
        </Typography>

        <Grid container spacing={2}>
          {
            values.participatedProjects.map((participatedProject: IParticipatedProjects, index) => {

              return (
                <Grid item xs={12} sm={6} md={4} key={index} >
                  <Box
                    sx={{
                      p: 2,
                      height: '100%',
                      border: '2px solid #9C8FF1',
                      position: 'relative',
                      flex: '1 1 300px',

                      transitionDuration: '200ms',
                      '&:hover': {
                        boxShadow: '0px 0px 10px 0px #9C8FF166',
                      },
                    }}
                  >

                    <Iconify
                      icon="fluent:communication-20-filled"
                      width={25}
                      sx={{
                        color: 'primary.main',
                      }}
                    />

                    <Typography variant="h6">
                      {participatedProject.name}
                    </Typography>


                    <Typography variant="body1" color="#999">
                      {participatedProject.institution}
                    </Typography>


                    <MoreMenu
                      onUpdate={() => {
                        setFormDialog({
                          formType: "participatedProject",
                          formValue: participatedProject,
                          itemIndex: index
                        })
                      }}
                      onDelete={() => {
                        values.participatedProjects.splice(index, 1)
                        setFieldValue("participatedProjects", values.participatedProjects)

                      }}
                    />

                  </Box>
                </Grid>
              )
            })
          }

          <Grid item xs={12} sm={6} md={4}>
            <AddNewItem onClick={() => setFormDialog({ formType: "participatedProject" })} sx={{ flex: '1 1 300px', height: '100%', minHeight: '120px' }} />
          </Grid>

        </Grid>
      </Grid>


      {/* ************  programming languages ************* */}
      <Grid item xs={12}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Bilgisayar Bilgisi*
        </Typography>

        <Grid item xs={12} sm={6}>
          <FormikSelect
            // sx={{ mb: 3 }}
            name="computerKnowledgeLevel"
            label="Bilgisayar Bilgisi*"
            list={ComputerKnowledgeLevelsList} />
        </Grid>

        {
          (values.computerKnowledgeLevel === "beginner" || values.computerKnowledgeLevel === "intermediate" || values.computerKnowledgeLevel === "advance") && (
            <Box sx={{ mt: 3 }}>
              {/* <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                Kullandığınız Programlar
              </Typography> */}

              <ProgrammingLanguageForm
                list={values.programmingLanguages ?? []}
                onSubmit={(programmingLanguage) => {
                  setFieldValue("programmingLanguages", [...values.programmingLanguages, programmingLanguage])
                }}

                onDeleteItem={(index) => {
                  const newList = [...values.programmingLanguages]
                  newList.filter((item, i) => i !== index)
                  setFieldValue("programmingLanguages", newList)

                }}
              />


              {/* <Grid container spacing={2}>
                {
                  values.programmingLanguages.map((programmingLanguage: IProgrammingLanguage, index) => {

                    return (
                      <Grid item xs={6} sm={4} md={3} xl={2} key={index} >
                        <Box
                          sx={{
                            p: 2,
                            height: '100%',
                            border: '2px solid #9C8FF1',
                            position: 'relative',
                            flex: '1 1 300px',

                            transitionDuration: '200ms',
                            '&:hover': {
                              boxShadow: '0px 0px 10px 0px #9C8FF166',
                            },
                          }}
                        >

                          <Iconify
                            icon="icon-park-solid:setting-computer"
                            width={25}
                            sx={{
                              color: 'primary.main',
                            }}
                          />

                          <Typography variant="h5">
                            {programmingLanguage.name}
                          </Typography>

                          <Typography variant="body1">
                      {programmingLanguage.level}
                    </Typography>


                          <MoreMenu

                            onUpdate={() => {
                              setFormDialog({
                                formType: "programmingLanguage",
                                formValue: programmingLanguage,
                                itemIndex: index
                              })
                            }}

                            onDelete={() => {
                              values.programmingLanguages.splice(index, 1)
                              setFieldValue("programmingLanguages", values.programmingLanguages)

                            }}

                          />

                        </Box>
                      </Grid>
                    )
                  })
                }

                <Grid item xs={12} sm={6} md={4} xl={3} >
                  <AddNewItem onClick={() => setFormDialog({ formType: "programmingLanguage" })} sx={{ flex: '1 1 300px', height: '100%', minHeight: '80px' }} />
                </Grid>

              </Grid> */}
            </Box>
          )
        }
      </Grid>


      {/* ************  hobbies ************* */}
      <Grid item xs={12}>
        <HobbiesForm
          type="onboarding-page"
          list={values?.hobbies}

          onSubmit={(hobby) => {
            const isFind = values.hobbies.find(item => item === hobby)
            if (isFind) return
            setFieldValue("hobbies", [...values.hobbies, hobby])
          }}

          onDeleteItem={(index) => {
            values?.hobbies.splice(index, 1)
            console.log(values.hobbies, index);
            setFieldValue("hobbies", [...values.hobbies])
          }}
        />
      </Grid>


      <Grid item xs={12}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          İş Hayatı Geçmişi*
        </Typography>

        <Grid item xs={12} sm={6}>
          {/* <FormikTextField
                name="workExperiencesInMonths"
                label="Work Experiences in Months"
                type="number"
                sx={{ height: "100%" }}
              /> */}

          <WorkExperienceInMonthComponent
            value={values.workExperiencesInMonths}
            setValue={(value: number) => setFieldValue("workExperiencesInMonths", value)}
          />
        </Grid>
      </Grid>


      <Grid item xs={12} justifyContent={"space-between"} display={"flex"}>

        <Button
          sx={{ px: 5 }}
          onClick={() => setFieldValue("onboardingFormStep", ((values.onboardingFormStep ?? 0) - 1))}
          variant="outlined"
          color="primary"
        >
          Geri
        </Button>

        <Button
          sx={{ pr: 3, pl: 2 }}
          onClick={() => handleSubmit()}
          variant="contained"
          color="primary"
          disabled={

            (Boolean(values.onboardingFormStep === 4 &&
              !values.computerKnowledgeLevel) ||
              values.hobbies.length === 0) || isLoading

          }
        >
          <Iconify icon="typcn:tick" sx={{ mr: 1 }} />
          {isLoading ? <CircularProgress size={20} /> :
            "Bitir"}
        </Button>

      </Grid>

    </Grid>
  )
}


const OnboardingForm5 = () => {

  const { values, setFieldValue } = useFormikContext<ICandidateInformationInput>()

  const handleFinish = () => {
    sessionStorage.removeItem("candidateOnboardingInfo")
    window.location.href = "/"
  }
  return (
    <Grid item xs={12} >


      <Stack gap={3} alignItems="center">
        {/* <Button
          onClick={() => setFieldValue("onboardingFormStep", ((values.onboardingFormStep ?? 0) - 1))}
          variant="outlined"
          color="primary"
        >
          Prev
        </Button> */}

        <Box bgcolor={"secondary.lighter"} p={4} mt={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
          <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}>
            <Iconify icon={"material-symbols:info-outline"} />
          </Box>
          <Typography variant="body2" >
            Kayıt sürecini başarılı bir şekilde bitirdiniz.
            Referans ekleyebilirsiniz,
            eklediğiniz referansları özgelecek oluştururken kullanabilirsiniz,
            ve referans onayı gerçekleştiğinde ilanlara başvurabilirsiniz.
          </Typography>
        </Box>

        <Box width={200} height={200} display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Lottie animationData={OkAnimation} loop={false} width={100} />
        </Box>

        <Typography variant="h6" color="primary.darker" textAlign={"center"}>
          Ön Kayıt süreciniz tamamlandı. <br /><br />Özgelecek oluşturmak ve ilanlara başvurabilmek için referans eklemeyi unutmayınız.
        </Typography>



        <Stack direction={"row"} gap={2} mt={2}>
          <Button variant="outlined" color="primary"
            sx={{ width: 130 }}
            onClick={() => {
              window.location.pathname = "/-m"
            }}>Panele Git</Button>
          <Button variant="contained" color="primary"
            sx={{ width: 130 }}
            onClick={() => {
              window.location.pathname = "/my-references-m"
            }}>Referans Ekle</Button>
        </Stack>
      </Stack>
    </Grid>
  )
}