import { IInsertDiscoverForm } from "interfaces/user/discoverForm/IDiscoverForm";

export interface IAnonymousCreateDiscoverFormResponse {
  _id: string;
}

export interface IAnonymousCreateDiscoverFormVariables {
  input: IInsertDiscoverForm 
}

export const anonymous_createDiscoverForm = (
  variables: IAnonymousCreateDiscoverFormVariables
) => {
  const data = JSON.stringify({
    query: `mutation Anonymous_createDiscoverForm($discoverFormInput: DiscoverFormInput!) {
      anonymous_createDiscoverForm(discoverFormInput: $discoverFormInput) {
        _id
      }
    }`,
    variables: {
      discoverFormInput: variables
    }
  });

  return data;
};
