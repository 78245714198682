import React from 'react';
import { useFormikContext } from 'formik';
import { Switch, FormControlLabel, FormHelperText } from '@mui/material';

interface IFormikSwitchProps {
    name: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void; 
}

export const FormikSwitch: React.FC<IFormikSwitchProps> = ({ name, onChange = () => null }) => {
    const { getFieldProps, getFieldMeta } = useFormikContext();

    const error = getFieldMeta(name).touched && getFieldMeta(name).error;
    const helperText = error && getFieldMeta(name).error;

    return (
        <div>
            <FormControlLabel
                control={
                    <Switch
                        {...getFieldProps(name)}
                        onChange={(e) => {
                            getFieldProps(name).onChange(e);
                            onChange(e);
                        }}
                        checked={getFieldProps(name).value ?? false}
                    />
                }
                label={""}
            />
            {(error || helperText) && (
                <FormHelperText error={!!error}>
                    {error ? error : helperText}
                </FormHelperText>
            )}
        </div>
    );
};
