


export interface ICompanyRemoveAdvertisementResponse {
    success: boolean
}
export interface ICompanyRemoveAdvertisementVariables {
    advertisementId: string
}


export const company_removeAdvertisement = (variables: ICompanyRemoveAdvertisementVariables) => {

    const data = JSON.stringify({
        query: `mutation Company_removeAdvertisement($advertisementId: ID!) {
            company_removeAdvertisement(advertisementId: $advertisementId) {
              success
            }
          }`,
        variables: {
            "advertisementId": variables?.advertisementId ?? null
        }
    })

    return data

}