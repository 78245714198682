import {
  PlaceOfWorkEnums,
  ExtraProductEnums,
  PositionLevelEnums,
  PaginationSortEnums,
  OzgelecekFieldEnum,
  AdvertisementStatusEnums,
  HigherEducationDegreeEnum,
} from "types"


export interface ICompanyAdvertisementsItem {
  _id: string
  companyInfoVisible: boolean
  createdAt: string
  customReplyNotification: {
    subject: string
    content: string
  }
  department: string
  description: string
  locations: Array<{
    city: string
    districts: Array<string>
  }>
  extraProducts: Array<string>
  doping: boolean
  dopingQuantity: number
  showcase: boolean
  emergent: boolean
  field: string
  images: Array<any>
  language: string
  militaryServiceRequired: boolean
  place: string
  positionLevel: string
  productType: string
  quota: string
  quotaVisibleToApplicant: boolean
  replyNotification: string
  requiredAgeInterval: string
  requiredDriverLicense: string
  requiredEducationLevel: Array<any>
  requiredLanguages: Array<{
    listening: string
    name: string
    reading: string
    writing: string
  }>
  requiredSkills: Array<any>
  startDate: string
  status: string
  title: string
  updatedAt: string
  workType: string
}


export interface ICompanyAdvertisementsResponse {
  count: number,
  data: Array<ICompanyAdvertisementsItem>
}


export interface ICompanyAdvertisementsVariables {

  pagination?: {
    page?: number | null,
    pageSize?: number | null,
    sort?: PaginationSortEnums
  },
  filter: {

    search: {
      all: string,
      cities?: Array<string> | null,
      department?: string | null,
      description?: string | null,
      districts?: Array<string> | null,
      title?: string | null
    },

    filter?: {
      cities?: Array<string>,
      department?: string,
      description?: string,
      districts?: Array<string>,
      extraProducts?: ExtraProductEnums,
      field?: OzgelecekFieldEnum,
      language?: string,
      militaryServiceRequired?: Boolean,
      place?: PlaceOfWorkEnums,
      positionLevel?: PositionLevelEnums,

      productType?: string,
      requiredAgeInterval?: number | null,
      requiredDriverLicense?: string,

      requiredEducationLevel?: Array<HigherEducationDegreeEnum>,
      requiredLanguages?: Array<{ name: string }>,
      requiredSkills?: Array<string>,
      startDate?: string,
      status?: AdvertisementStatusEnums,
      workType?: string

    }
  },


}

export const company_advertisements = (variables: ICompanyAdvertisementsVariables) => {

  const data = JSON.stringify({
    query: `
    query Company_advertisements($filter: FilterAdvertisementOptionForCompany, $pagination: PaginationWithAdvertisementSort) {
      company_advertisements(filter: $filter, pagination: $pagination) {
              count
              data {
                _id
                companyInfoVisible
                createdAt
                customReplyNotification {
                  subject
                  content
                }
                locations {
                  city
                  districts
                }
                department
                description
                extraProducts
                field
                images
                language
                militaryServiceRequired
                place
                positionLevel
                productType
                quota
                quotaVisibleToApplicant
                replyNotification
                requiredAgeInterval
                requiredDriverLicense
                requiredEducationLevel
                requiredLanguages {
                  listening
                  name
                  reading
                  writing
                }
                requiredSkills
                startDate
                status
                title
                updatedAt
                workType
              }
            }
          }
        `,
    variables: {
      "pagination": {
        "page": variables?.pagination?.page ?? null,
        "pageSize": variables?.pagination?.pageSize ?? null,
        "sort": variables?.pagination?.sort ?? null
      },
      "filter": {

        "search": {
          "cities": variables?.filter?.search?.cities ?? null,
          "department": variables?.filter?.search?.cities ?? null,
          "description": variables?.filter?.search?.cities ?? null,
          "districts": variables?.filter?.search?.cities ?? null,
          "title": variables?.filter?.search?.cities ?? null
        },
        "filter": {
          "cities": variables?.filter?.filter?.cities ?? [],
          "department": variables?.filter?.filter?.department ?? null,
          "description": variables?.filter?.filter?.description ?? null,
          "districts": variables?.filter?.filter?.districts ?? [],
          "extraProducts": variables?.filter?.filter?.extraProducts ?? null,
          "field": variables?.filter?.filter?.field ?? null,
          "language": variables?.filter?.filter?.language ?? null,
          "militaryServiceRequired": variables?.filter?.filter?.militaryServiceRequired ?? null,
          "place": variables?.filter?.filter?.place ?? null,
          "positionLevel": variables?.filter?.filter?.positionLevel ?? null,
          "productType": variables?.filter?.filter?.productType ?? null,
          "requiredAgeInterval": variables?.filter?.filter?.requiredAgeInterval ?? null,
          "requiredDriverLicense": variables?.filter?.filter?.requiredDriverLicense ?? null,
          "requiredEducationLevel": variables?.filter?.filter?.requiredEducationLevel ?? null,
          // "requiredLanguages": variables?.filter?.filter?.requiredLanguages ?? [],
          "requiredSkills": variables?.filter?.filter?.requiredSkills ?? null,
          "startDate": variables?.filter?.filter?.startDate ?? null,
          "status": variables?.filter?.filter?.status ?? null,
          "workType": variables?.filter?.filter?.workType ?? null
        }
      }

    }
  })

  return data

}