// import PropTypes from 'prop-types';
// @mui
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import { FC } from 'react';
import { Box, SxProps } from '@mui/material';
import { Logo } from 'assets';
//
// import { HeaderSimple as Header } from '../_common';

// ----------------------------------------------------------------------

export const CompactLayout: FC<any> = ({ children }) => {

  const RenderLogo = ({ width, sx }: { width?: any, sx?: SxProps }) => {
    return (
      <Box
        sx={{
          mb: 3,
          width: width,
          ...sx,

        }}
      >
        <img src={Logo} alt="logo" style={{ width: "70%" }} />
      </Box>
    )
  }

  return (
    <Container component="main">

      <Stack
        sx={{
          py: 12,
          m: 'auto',
          maxWidth: 400,
          minHeight: '100vh',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <RenderLogo />

        {children}
      </Stack>
    </Container>
  );
}

// CompactLayout.propTypes = {
//   children: PropTypes.node,
// };
