import { useEffect, useState } from "react";
import { Form, Formik } from 'formik';
import { LoadingButton } from "@mui/lab";
import { Box, Card, Container, Grid, Typography, LinearProgress, Divider, Stack, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { Company, OkAnimation, WelcomeGif } from "assets";
import { ICompanyInformation, SexsList, NumberOfEmployeesList } from "types";
import { enqueueSnackbar } from "notistack";
import { FormikDatePicker, FormikTextField, FormikSelect, FormikTextFieldPhone } from "components";
import { companyUpdateCompanyFormValidation } from "lib/form-validation";
import { OnboardingLayout } from "layouts";
import {
  IAuthState,
  company_updateCompany,
  getOnboardingStatus,
  useAppDispatch,
  useAppSelector,
  useGetCitiesQuery,
  useLazyGetUserProfileImagePresignedUrlQuery,
  useGetCompanyInformationQuery,
  company_onboard,
} from "context";
import { compressImage, findImageContentType, saveImageAndGetUrl } from "utils";
import { Iconify } from "components";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";


export const CompanyOnboardingPage = () => {

  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const { userType }: IAuthState = useAppSelector(state => state?.auth)
  const { data, isLoading, refetch } = useGetCompanyInformationQuery("")
  const { initialValues, validationSchema } = companyUpdateCompanyFormValidation({})
  const [getPresignUrl] = useLazyGetUserProfileImagePresignedUrlQuery()
  const [formIndex, setFormIndex] = useState(1)
  const [profileImage, setProfileImage] = useState<File | null>(null)
  const [acilisDialog, setAcilisDialog] = useState(true)

  const { device } = useAppSelector(state => state.auth)

  // useEffect(() => {
  //   if (device === "mobile") {
  //     navigate(routes.company.onboarding.mainMobile)
  //   }
  // }, [device])

  const handleSubmit = async (values: ICompanyInformation) => {
    setLoading(true)
    let url;
    try {

      if (profileImage) {
        const contentType = findImageContentType(profileImage)
        const presigneUrl = await getPresignUrl({ contentType: contentType ?? "" })
        const isSaved = await saveImageAndGetUrl({
          file: profileImage,
          presignUrl: presigneUrl?.data?.profileImage?.presignedUploadUrl ?? ""
        })

        url = presigneUrl?.data?.profileImage?.url
        console.log({ isSaved, presigneUrl });
      }

      const response = await company_updateCompany({ variables: { ...values, profileImage: url } })
      if (response?.company_updateCompany?._id) {
        enqueueSnackbar("Ön Kayıt başarıyla tamamlandı!", { variant: "success" })
        setFormIndex(4)
        await company_onboard()
        // dispatch(getOnboardingStatus({ userType }))
      } else {
        enqueueSnackbar("Lütfen sayfayı yenileyerek bilgilerin tamamını doldurduğunuzdan emin olunuz!", { variant: "error" })
      }
    } catch (error) {
      console.log({ error });
      enqueueSnackbar("Birşeyler yanlış gitti!", { variant: "error" })
    }
    setLoading(false)
  }

  return <OnboardingLayout>

    <Container>
      <div id="on-kayit-basligi"></div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          handleSubmit(values)
        }}
      >
        {({ values, errors }) =>
        (
          <Form>

            <CompanyOnboardingForm formIndex={formIndex} setFormIndex={setFormIndex} values={values} profileImage={profileImage} setProfileImage={setProfileImage} />

            {formIndex === 3 && <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>

              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                sx={{ my: 3, px: 5 }}
                disabled={loading || !(values?.email) || !(values?.phoneNumber) || values?.phoneNumber === "+90"}
                loading={loading}
              >
                Kaydet
              </LoadingButton>
            </Grid>}

          </Form>
        )}
      </Formik>

    </Container>


    <Dialog
      open={acilisDialog}
      onClose={() => setAcilisDialog(false)}
    >
      {/* <DialogTitle>
        <Typography variant="h6" color="primary.main" textAlign={"center"}>
          Hoşgeldiniz!
        </Typography>
      </DialogTitle> */}
      <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <img src={WelcomeGif} alt="Welcome" style={{ width: "250px", height: "auto" }} />
        <Typography variant="body1" textAlign={"center"}>
          Özgelecek vizyonunu paylaşan değerli iş ortağımız,<br /> <strong>Özgelecek’e Hoş Geldiniz!</strong><br /><br />
          Sizlerin “aradığınız çalışana” ulaşmanız için sürekli bir çalışma içerisindeyiz. Ancak yine bizim gözümüzden kaçan, sizin “bunu da yapın” dediğiniz bir konu olursa her zaman destek olacağız!<br /><br />
          Bize yazmaktan hiç çekinmeyin: <a href="mailto:bilgi@ozgelecek.net" style={{ color: 'black' }}>bilgi@ozgelecek.net</a>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setAcilisDialog(false)
          }}
        >
          Tamam
        </Button>
      </DialogActions>
    </Dialog>


  </OnboardingLayout>
}


const CompanyOnboardingForm = ({ formIndex, setFormIndex, values, profileImage, setProfileImage }:
  { formIndex: number, setFormIndex: (index: number) => void, values: ICompanyInformation, profileImage: File | null, setProfileImage: (e?: any) => void }) => {

  const { device } = useAppSelector(state => state.auth)
  const { data: citiesData } = useGetCitiesQuery({ countryName: "Turkey" })

  const handleSetProfileImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log({ event });
    const file = (event.target as HTMLInputElement).files?.[0];
    const compressedImage = file && await compressImage(file, 0.8)
    console.log({ file, compressedImage });
    console.log(file?.type)
    setProfileImage(compressedImage)
  }

  useEffect(() => {
    document.getElementById("on-kayit-basligi")?.scrollIntoView({ behavior: "smooth" });
  }, [formIndex])

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >

      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mb: 3, }}>

        <Box display={"flex"} flexDirection={"row"} alignItems={"flex-end"} justifyContent={"space-between"} width={"100%"} mb={2}>
          <Typography variant="h6" color="primary.darker">
            {formIndex === 1 && "Firma Bilgileri"}
            {formIndex === 2 && "Firma Bilgileri"}
            {formIndex === 3 && "Firma Bilgileri"}
          </Typography>

          {formIndex !== 4 && <Typography variant="h6" color="primary.darker" mr={1}>
            {`${formIndex}/3`}
          </Typography>}
        </Box>

        <Box sx={{ width: '100%' }}>
          <LinearProgress sx={{ height: 10 }} variant="determinate" value={((formIndex ? formIndex === 4 ? 3 : 1 : 1) / 3) * 100} />
        </Box>

      </Box>

      {formIndex === 1 && <>
        <Card sx={{ width: '100%', p: 3 }}>
          <Grid container spacing={3} >
            <Grid item xs={12} sm={6}>
              <FormikTextField name="personalName" label="Yetkili Adı*" upperCase />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormikTextField name="personalSurname" label="Yetkili Soyadı*" upperCase />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormikTextFieldPhone
                label="Telefon Numarası*"
                fullWidth
                name="phoneNumber"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormikTextField name="email" label="E-posta Adresi*" />
            </Grid>

            <Grid item xs={12}>
              <FormikTextField fullWidth name="companyName" label="Firma Adı*" upperCase />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormikSelect
                name="city"
                label="Şehir*"
                list={citiesData?.data ?? []}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              {/* <FormikTextField fullWidth name="district" label="İlçe*" upperCase /> */}
              <FormikSelect
                name="district"
                label="İlçe"
                list={citiesData?.data?.find((item) => item.name === values.city)?.districts ?? []}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormikTextField fullWidth name="taxOffice" label="Vergi Dairesi*" />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormikTextField fullWidth name="taxNumber" label="Vergi Numarası*" />
            </Grid>
          </Grid>

        </Card>

        <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
          <LoadingButton
            sx={{ px: 5 }}
            variant="contained"
            color="primary"
            disabled={!(values?.personalName && values?.personalSurname && values?.email && values?.phoneNumber && values?.companyName && values?.city && values?.district && values?.taxOffice && values?.taxNumber)}
            onClick={() => {
              setFormIndex(2)
              company_updateCompany({ variables: { ...values } })
            }}
          >
            İleri
          </LoadingButton>
        </Grid>
      </>}

      {formIndex === 2 && <>

        <Box bgcolor={"secondary.lighter"} px={3} py={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
          <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}><Iconify icon={"material-symbols:info-outline"} /></Box>
          <Typography variant="body2" >
            Şirket bilgilerinizin en az %60'ını doldurduğunuzda, profiliniz tamamlanmış sayılacaktır.
          </Typography>
        </Box>
        <Card sx={{ width: '100%', p: 3 }}>
          <Grid container spacing={3} >
            <Grid item xs={12} sm={6}>
              <Box
                component="label"
                htmlFor="profile_image_input"
                sx={{
                  display: 'flex',
                  height: '100%',
                  flexDirection: { xs: 'column' },
                  justifyContent: 'center',
                  alignItems: 'center',
                  p: 1,
                  gap: { xs: 1 },
                  cursor: 'pointer',
                  border: '1px dashed #ccc',
                }}
              >

                <Box
                  component={"img"}
                  src={
                    profileImage
                      ? URL.createObjectURL(profileImage)
                      : Company
                  }
                  sx={{
                    width: 90,
                    height: 90,
                    bgcolor: 'white',
                    overflow: 'hidden',
                    borderRadius: '50%',
                    border: '3px solid #ccc',
                    objectFit: 'contain',
                  }}

                />
                <Typography fontSize={"small"}>
                  Şirket Logosu Yükle
                </Typography>
                <Typography fontSize={"12px"} color={"primary"} textAlign={"center"}>
                  (Png veya Jpg formatında bir resim yükleyiniz.)
                </Typography>
              </Box>
              <Box
                component="input"
                type="file"
                accept="image/png, image/jpeg"
                sx={{ display: 'none' }}
                id="profile_image_input"
                // value={profileImage}
                onChange={handleSetProfileImage}
              />
            </Grid>
            <Grid item xs={12} sm={6} display="flex" flexDirection={"column"} gap={3}>
              <FormikTextField fullWidth name="sector" label="Sektör" upperCase />
              <FormikDatePicker name="foundationYear" label="Kuruluş Yılı" />
            </Grid>
            <Grid item xs={12} sm={6} display="flex" flexDirection={"column"} justifyContent={"space-between"} gap={{ xs: 3, sm: 0 }}>
              <FormikSelect
                name="numberOfEmployees"
                label="Çalışan Sayısı"
                list={NumberOfEmployeesList ?? []}
              />
              <FormikTextField
                fullWidth
                multiline
                rows={2}
                label="Açık Adres"
                name="companyAddress"
                upperCase
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikTextField
                fullWidth
                multiline
                rows={5}
                label="Şirket Hakkında"
                name="companyBrief"
                upperCase
              />
            </Grid>
          </Grid>
        </Card>

        <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
          <LoadingButton
            sx={{ px: 5 }}
            variant="outlined"
            color="primary"
            onClick={() => {
              setFormIndex(1)
            }}
          >
            Geri
          </LoadingButton>

          <LoadingButton
            sx={{ px: 5 }}
            variant="contained"
            color="primary"
            onClick={() => {
              setFormIndex(3)
            }}
          >
            İleri
          </LoadingButton>
        </Grid>
      </>}

      {
        formIndex === 3 && <>
          <Box bgcolor={"secondary.lighter"} px={3} py={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
            <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}><Iconify icon={"material-symbols:info-outline"} /></Box>
            <Typography variant="body2" >
              Şirket bilgilerinizin en az %60'ını doldurduğunuzda, profiliniz tamamlanmış sayılacaktır.
            </Typography>
          </Box>
          <Card sx={{ width: '100%', py: 3 }}>

            <Grid container spacing={3} px={3} >

              <Grid item xs={12} sm={6}>
                <FormikTextField fullWidth name="website" label="Web Sitesi" />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormikTextField fullWidth name="facebook" label="Facebook" />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormikTextField fullWidth name="instagram" label="Instagram" />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormikTextField fullWidth name="linkedin" label="Linkedin" />
              </Grid>
            </Grid>
          </Card>

        </>
      }

      {
        formIndex === 4 && <>
          <Card sx={{ width: '100%', p: 3, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 2 }}>

            <Box width={200} height={200} display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <Lottie animationData={OkAnimation} loop={false} width={100} />
            </Box>

            <Typography variant="h6" color="primary.darker" textAlign={"center"}>
              Ön Kayıt süreciniz tamamlandı. <br /><br />İlan oluşturmanız için vergi levhası ve imza sirkülerini yüklemeniz gerekmektedir.
            </Typography>

            {device !== "mobile" && <Stack direction={"row"} gap={2} mt={2}>
              <Button variant="outlined" color="primary"
                sx={{ width: 130 }}
                onClick={() => {
                  window.location.pathname = "/-m"
                }}>Panele Git</Button>
              <Button variant="contained" color="primary"
                sx={{ width: 130 }}
                onClick={() => {
                  window.location.pathname = "/ayarlar-m?tab=sirket-dogrulama"
                }}>Belgeleri Yükle</Button>
            </Stack>}
          </Card>

        </>
      }

    </Box >
  )
}