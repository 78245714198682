import { Helmet } from 'react-helmet-async';
import { LoginContainer } from './LoginContainer';
import { AuthLayout } from 'layouts';


export const LoginPage = () => {

  return (
    <AuthLayout>
      <Helmet>
        <title> Login </title>
      </Helmet>
      <LoginContainer />
    </AuthLayout>
  );
}
