import React from 'react';
// import ReactDOM from 'react-dom';
import { PDFViewer } from '@react-pdf/renderer';
import Dokuman from './Dokuman';

export const Indir = ({ Document }: any) => (
  <PDFViewer width="100%" height="100%">
    {Document}
  </PDFViewer>
);
// ReactDOM.render(<Indir />, document.getElementById('root'));

