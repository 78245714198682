// import PropTypes from 'prop-types';
import { FC } from 'react';
import TextField from '@mui/material/TextField';
import { useFormContext, Controller } from 'react-hook-form';


type PropTypes = {
  name: string,
  helperText?: string | any,
  type?: string,
  [x: string]: any;
}

// ----------------------------------------------------------------------


export const RHFTextField: FC<PropTypes> = ({ name, helperText, type, ...other }) => {

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          type={type}
          value={type === 'number' && field.value === 0 ? '' : field.value}
          onChange={(event) => {
            if (type === 'number') {
              field.onChange(Number(event.target.value));
            } else {
              field.onChange(event.target.value);
            }
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        />
      )}
    />
  );
}

// RHFTextField.propTypes = {
//   helperText: PropTypes.object,
//   name: PropTypes.string,
//   type: PropTypes.string,
// };
