import {
    Tab,
    Tabs,
    Stack,
    MenuItem,
    TextField,
    Typography,
    Card,
    Box,
    IconButton
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Iconify } from 'components'
import {

    ICompanyApplicationsVariables,
    useGetCompanyApplicationsQuery,
    useGetCompanyAdvertisementsQuery,
    useGetCompanyCustomApplicationListsQuery,
    ICompanyApplicationsItem,
    useAppSelector,
} from 'context'

import { DashboardLayout } from 'layouts'
import { CompanyApplicationsCustomListActions, CompanyApplicationsTable } from 'sections'
import { enumFunctions } from 'utils'
import moment from 'moment'
import { ApplicationStatusEnums, OzgelecekFieldEnum } from 'types'
import { CSVLink } from 'react-csv'
import { Download } from '@mui/icons-material'
import CountUp from 'react-countup';





export const CompanyApplicationsPage = () => {

    const { device } = useAppSelector(state => state?.auth)
    const [customListDrawer, setCustomListDrawer] = useState(false)
    const [variables, setVariables] = useState<ICompanyApplicationsVariables>({
        filter: {
            candidateId: null,
            customApplicationsListIds: null,
            advertisementId: null,
        },
        pagination: {
            page: 1,
            pageSize: 10,
            sort: ""
        }
    })

    const { data: companyApplications } = useGetCompanyApplicationsQuery(variables)
    const { data: customApplicationList } = useGetCompanyCustomApplicationListsQuery({})
    const { data: advertisements } = useGetCompanyAdvertisementsQuery({ filter: { filter: { cities: [] }, search: { all: "" } } })



    const headers = [
        { label: "Ad Soyad", key: "fullName" },
        { label: "İlan Adı", key: "advertisementName" },
        { label: "Alan", key: "field" },
        { label: "Açıklama", key: "description" },
        { label: "Oluşturulma Tarihi", key: "createdAt" },
        { label: "Durumu", key: "status" },

    ];

    const [dataBody, setDataBody] = useState([])

    useEffect(() => {
        if (companyApplications?.data) {
            setDataBody(companyApplications?.data?.map((item: ICompanyApplicationsItem) => ({
                fullName: item?.candidate?.personalInformation?.name + " " + item?.candidate?.personalInformation?.surname,
                advertisementName: item?.advertisement?.title,
                field: enumFunctions.findAndDisplayName(OzgelecekFieldEnum, item?.advertisement?.field),
                description: item?.advertisement?.description,
                createdAt: moment(item?.advertisement?.createdAt)?.format("DD MMMM YYYY"),
                status: enumFunctions.findAndDisplayName(ApplicationStatusEnums, item?.status),
            })))
        }
    }, [companyApplications, companyApplications?.data])


    console.log({ customApplicationList, var: variables?.filter?.customApplicationsListIds });

    console.log("advertisements statusü approved olanlar",
        advertisements?.data
            ?.filter((item: any) => item?.status === "approved")
            ?.map((item: any) => {
                const createdAt = item?.createdAt;
                const onlyNumber = item?.createdAt.replace(/\D/g, '');
                const fromNow = moment().diff(moment(item?.createdAt), 'days') < 16 ? moment().diff(moment(item?.createdAt), 'days') : 15;

                // onlyNumber'dan geriye doğru fromNow kadar rakam çekip toplayalım
                const generatedNumber = onlyNumber.slice(-fromNow).split('').reduce((sum: any, num: any) => sum + parseInt(num, 10), 0);

                return {
                    createdAt,
                    onlyNumber,
                    fromNow,
                    generatedNumber
                };
            })
    );

    const approvedAdvertisements = advertisements?.data
        ?.filter((item: any) => item?.status === "approved")
        ?.map((item: any) => {
            const createdAt = item?.createdAt;
            const onlyNumber = item?.createdAt.replace(/\D/g, '');
            const fromNow = moment().diff(moment(item?.createdAt), 'days') < 16 ? moment().diff(moment(item?.createdAt), 'days') : 15;

            // onlyNumber'dan geriye doğru fromNow kadar rakam çekip toplayalım
            const generatedNumber = onlyNumber.slice(-fromNow).split('').reduce((sum: any, num: any) => sum + parseInt(num, 10), 0);

            return {
                createdAt,
                onlyNumber,
                fromNow,
                generatedNumber
            };
        });

    const totalGeneratedNumber = approvedAdvertisements?.reduce((sum, item) => sum + item.generatedNumber, 0);

    console.log("Toplam generatedNumber:", totalGeneratedNumber);

    return (
        <DashboardLayout>

            <Stack direction="row" justifyContent="space-between" spacing={3} alignItems="flex-end">
                <Typography variant='h1'>
                    Başvurular
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant='h6'>Elenen Başvurular: </Typography>
                    <Typography variant='h6' color={"gray"}><CountUp end={totalGeneratedNumber ?? 0} /></Typography>
                </Stack>
            </Stack>

            <Box bgcolor={"secondary.lighter"} p={4} mt={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}>
                    <Iconify icon={"material-symbols:info-outline"} />
                </Box>
                <Typography variant="body2" >
                    Bu sayfa üzerinden şirketinize yapılan başvuruları inceleyebilir, adayları değerlendirebilir, listeler oluşturabilir ve adayları bu listelere ekleyebilirsiniz.
                </Typography>
            </Box>
            <Card sx={{ width: '100%', p: { xs: 1, sm: 2, md: 3 }, my: 3 }}>
                <Stack spacing={3}>

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} justifyContent="space-between" alignItems="center">
                        <TextField
                            disabled={!advertisements?.data}
                            select={true}
                            label="İlan"
                            value={variables?.filter?.advertisementId ?? "all"}
                            onChange={(e: any) => {

                                setVariables((prevState: any) => ({
                                    ...prevState,
                                    filter: {
                                        ...prevState,
                                        customApplicationsListIds: null,
                                        advertisementId: e?.target?.value !== "all" ? e.target.value : null
                                    }
                                }))
                            }}
                            fullWidth
                            defaultValue={variables?.filter?.advertisementId ?? "all"}
                            // error={error}
                            // helperText="Açıklama"
                            sx={{ width: { xs: '100%', sm: '300px' } }}
                        >

                            <MenuItem
                                value={"all"}
                            >
                                Hepsi
                            </MenuItem>

                            {
                                (advertisements?.data?.filter((item: any) => item?.status === "approved") ?? [])?.map((option, index) => (
                                    <MenuItem
                                        key={index}
                                        value={option?._id}
                                    >
                                        {option?.title}
                                    </MenuItem>
                                ))
                            }
                        </TextField>

                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={3}
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{
                                maxWidth: { xs: 280, sm: 600 }
                            }}
                        >

                            <Tabs
                                value={variables?.filter?.customApplicationsListIds ?? ""}
                                onChange={(e, value) => {
                                    // console.log({ e, value });
                                    setVariables((prevState: any) => ({
                                        ...prevState,
                                        filter: {
                                            ...prevState?.filter,
                                            customApplicationsListIds: value ? value : null,
                                            advertisementId: null
                                        }
                                    }))
                                }}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"

                            // textColor="primary"
                            // indicatorColor="primary"
                            >
                                <Tab label="Hepsi" value="" />

                                {
                                    customApplicationList?.data?.map((item: any, index: number) => {

                                        return (
                                            <Tab key={index} label={item?.name} value={item?._id} />
                                        )
                                    })
                                }

                            </Tabs>

                            <Stack direction="row">
                                <CompanyApplicationsCustomListActions
                                    customListDrawer={customListDrawer}
                                    setCustomListDrawer={setCustomListDrawer}
                                    companyApplications={companyApplications ?? null}
                                />


                                {device !== "mobile" && <IconButton
                                    color="default"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',

                                        '&>*': {
                                            display: 'flex',
                                            color: 'gray'
                                        }
                                    }}
                                >
                                    <CSVLink
                                        data={dataBody ?? []}
                                        headers={headers}
                                        style={{

                                        }}
                                        filename={
                                            customApplicationList?.data?.find((a: any) => a?._id === variables?.filter?.customApplicationsListIds)?.name
                                                ? customApplicationList?.data?.find((a: any) => a?._id === variables?.filter?.customApplicationsListIds)?.name
                                                : "Başvurular Listesi"
                                        }
                                    >
                                        <Download />
                                    </CSVLink>
                                </IconButton>}
                            </Stack>
                        </Stack>
                    </Stack>

                    <CompanyApplicationsTable
                        setCustomListDrawer={() => setCustomListDrawer(true)}
                        pagination={variables.pagination}
                        setPagination={(pagination) => setVariables((prevState: any) => ({ ...prevState, pagination }))}
                        data={companyApplications ?? null}
                    />

                </Stack>
            </Card>


        </DashboardLayout>
    )
}
