import { useEffect, useState } from 'react'
import { Pending } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { FormControlLabel, FormGroup, Grid, Stack, Typography, Box, CircularProgress } from '@mui/material'
import { useAppSelector, useLazyCompanyDocumentUploadPresignedUrlQuery, useAppDispatch, useCompanyRequestApprovalMutation, useLazyCompanyDocumentDownloadPresignedUrlQuery, getOnboardingStatus } from 'context'
import { savePdfAndGetUrl } from "utils";
import { enqueueSnackbar } from 'notistack'


export const CompanyVerificationSection = () => {


    const { isOnboarding } = useAppSelector(state => state?.auth)
    const dispatch = useAppDispatch()
    const [getPresignUrl] = useLazyCompanyDocumentUploadPresignedUrlQuery()
    const [getDownloadPresignUrl] = useLazyCompanyDocumentDownloadPresignedUrlQuery()
    const [taxFile, setTaxFile] = useState<File | null>(null)
    const [signatureFile, setSignatureFile] = useState<File | null>(null)
    const [requestApproval, { isLoading: isRequestApprovalLoading }] = useCompanyRequestApprovalMutation()
    const [loading, setLoading] = useState(false)

    const handleSubmit = async () => {
        setLoading(true)
        try {
            let fileName
            let fileName2
            if (taxFile && signatureFile) {
                const presigneUrl = await getPresignUrl({ documentType: "vergiLevhasi" })
                const presignedUrl2 = await getPresignUrl({ documentType: "imzaSirkuleri" })
                const isSaved = await savePdfAndGetUrl({
                    file: taxFile,
                    presignUrl: presigneUrl?.data?.presignedUploadUrl ?? ""
                })
                const isSaved2 = await savePdfAndGetUrl({
                    file: signatureFile,
                    presignUrl: presignedUrl2?.data?.presignedUploadUrl ?? ""
                })

                fileName = presigneUrl?.data?.fileName
                fileName2 = presignedUrl2?.data?.fileName

                console.log({ isSaved, isSaved2, fileName });

                const response: any = await requestApproval({
                    imzaSirkuleri: fileName as string,
                    vergiLevhasi: fileName2 as string
                })

                console.log(response, "response")
                if (response.data?.data?.company_requestApproval?.success) {
                    console.log("Success")
                    setLoading(false)
                    enqueueSnackbar("Doğrulama isteği başarıyla gönderildi", { variant: "success" })
                    dispatch(getOnboardingStatus({ userType: "company" }))
                } else {
                    console.log("Failed")
                    setLoading(false)
                    enqueueSnackbar("Doğrulama isteği gönderilirken bir hata oluştu", { variant: "error" })
                }
            }
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const handleDownloadTaxFile = async () => {
        try {
            const presignedUrl = await getDownloadPresignUrl({ documentType: "vergiLevhasi" })
            if (presignedUrl) {
                console.log(presignedUrl, "presignedUrl")
                window.open(presignedUrl?.data?.company_companyDocumentDownloadPresignedUrl, "_blank")
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleDownloadSignatureFile = async () => {
        try {
            const presignedUrl = await getDownloadPresignUrl({ documentType: "imzaSirkuleri" })
            if (presignedUrl?.data?.company_companyDocumentDownloadPresignedUrl) {
                window.open(presignedUrl?.data?.company_companyDocumentDownloadPresignedUrl, "_blank")
            }
        } catch (error) {
            console.log(error)
        }
    }


    // dispatch(getOnboardingStatus({ userType: "company" }))

    return (
        <Grid container spacing={3}>

            <Grid item xs={12}>
                <Typography variant='h3'>
                    Şirket Doğrulama
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant='body1'>
                    Şirketinizin doğrulama durumunu buradan takip edebilirsiniz. Şirketinizin doğrulama durumu onaylandığında ilanlara başvurabilirsiniz.
                </Typography>
                <Box p={2} bgcolor={"secondary.lighter"} mt={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                    <Typography fontSize={20}>
                        <strong>Hesap Durumu:</strong> <i>{isOnboarding === "pendingRequest" ? "Pasif" :
                            isOnboarding === "pendingApproval" ? "Onay Bekliyor" :
                                isOnboarding === "approved" ? "Onaylandı" :
                                    isOnboarding === "rejected" ? "Reddedildi" : "Bekleniyor"}</i>
                    </Typography>
                </Box>
                <Box my={3} display={"flex"} alignItems={"center"}>
                    <Typography variant='body1' mr={2}>
                        Vergi Levhası :
                    </Typography>
                    {(isOnboarding === "pendingRequest" || isOnboarding === "rejected") ? <Box component={"input"} type={"file"}
                        accept='.pdf'
                        onChange={(e) => setTaxFile(e.target.files?.[0] ?? null)} /> :
                        <Typography fontWeight={"bold"} color={"primary"} onClick={handleDownloadTaxFile} sx={{ cursor: "pointer" }}>
                            Vergi levhasını indir
                        </Typography>}
                </Box>

                <Box my={3} display={"flex"} alignItems={"center"}>
                    <Typography variant='body1' mr={2}>
                        İmza Sirküsü :
                    </Typography>
                    {(isOnboarding === "pendingRequest" || isOnboarding === "rejected") ? <Box component={"input"}
                        type={"file"}
                        accept='.pdf'
                        onChange={(e) => setSignatureFile(e.target.files?.[0] ?? null)} /> :
                        <Typography fontWeight={"bold"} color={"primary"} onClick={handleDownloadSignatureFile} sx={{ cursor: "pointer" }}>
                            İmza sirküsünü indir
                        </Typography>}
                </Box>


            </Grid>

            <Grid item xs={12} display="flex" justifyContent="start" >
                {(isOnboarding === "pendingRequest" || isOnboarding === "rejected") && <LoadingButton
                    variant='contained'
                    color='primary'
                    onClick={handleSubmit}
                    disabled={!taxFile || !signatureFile || isRequestApprovalLoading || loading}
                >
                    {isRequestApprovalLoading || loading ? <CircularProgress size={24} /> : "Kaydet"}
                </LoadingButton>}
            </Grid>


        </Grid>
    )
}
