import { useEffect, useState } from "react"
import { enqueueSnackbar } from "notistack"
import { DeleteOutline, Download } from "@mui/icons-material";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded';
import { Button, Dialog, DialogContent, IconButton, Stack, TextField, Typography } from "@mui/material"

import {
    useGetCompanyCustomApplicationListsQuery,
    useCompanyCreateCustomApplicationListMutation,
    useCompanyRemoveCustomApplicationListMutation,
    ICompanyApplicationsResponse,
    ICompanyApplicationsItem,
} from "context"
import { MuiTransition } from "components";

import { CSVLink, CSVDownload } from "react-csv";
import { enumFunctions } from "utils";
import { AdvertisementStatusEnums, ApplicationStatusEnums } from "types";
import moment from "moment";



interface ICompanyApplicationsCustomListActionsProps {
    customListDrawer: any,
    setCustomListDrawer: (e?: any) => void,
    companyApplications: ICompanyApplicationsResponse | null
}

export const CompanyApplicationsCustomListActions = ({ customListDrawer, setCustomListDrawer, companyApplications }: ICompanyApplicationsCustomListActionsProps) => {

    const [settingsDrawer, setSettingsDrawer] = useState(false)
    const [data, setData] = useState({ name: "", description: "." })
    const [createCustomApplicationList] = useCompanyCreateCustomApplicationListMutation()
    const [removeFromCustomApplicationList] = useCompanyRemoveCustomApplicationListMutation()
    const { data: customApplicationList } = useGetCompanyCustomApplicationListsQuery({})



    const handleAddNewCustomApplicationList = async () => {

        if (data.name === "" || data.description === "") return

        try {

            const response: any = await createCustomApplicationList({
                customApplicationListInput: {
                    name: data?.name,
                    description: data?.description
                }
            })

            console.log({ response });
            if (response?.data?.data?.company_createCustomApplicationList?._id) {
                enqueueSnackbar("Başarılı bir şekilde oluşturuldu!")
                setData({
                    name: "",
                    description: "",
                })
                setCustomListDrawer(false)
            } else {
                enqueueSnackbar("Liste oluşturulamadı!", { variant: 'warning' })
            }


        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: 'error' })
        }
    }


    const handleRemoveFromCustomApplicationList = async (listId: string) => {


        try {

            const response: any = await removeFromCustomApplicationList({ customApplicationListId: listId })

            console.log({ response });
            if (response?.data) {
                enqueueSnackbar("Başarılı bir şekilde silindi!")
                setCustomListDrawer(false)
            } else {
                enqueueSnackbar("Liste silinemedi!", { variant: 'warning' })
            }


        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: 'error' })
        }
    }






    return (
        <>
            <Stack direction="row">
                {
                    customApplicationList?.count < 5 && (
                        <IconButton
                            color="success"
                            onClick={() => setCustomListDrawer(true)}
                        >
                            <PlaylistAddRoundedIcon />
                        </IconButton>
                    )
                }

                <IconButton
                    color="error"
                    onClick={() => setSettingsDrawer(true)}
                >
                    <SettingsOutlinedIcon />
                </IconButton>




            </Stack>

            <Dialog
                fullWidth
                open={!!customListDrawer}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setCustomListDrawer(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <Stack
                        spacing={3}
                        py={3}
                        minWidth={{ xs: "100%", sm: '400px' }}
                    >

                        <Typography variant='h3'>
                            Yeni Liste Oluştur
                        </Typography>

                        <TextField
                            label="Liste Adı"
                            value={data?.name}
                            onChange={(e) => setData((prevState) => ({ ...prevState, name: e?.target?.value }))}
                        />

                        {/* <TextField
                            multiline
                            minRows={4}
                            label="Açıklama"
                            value={data?.description}
                            onChange={(e) => setData((prevState) => ({ ...prevState, description: e?.target?.value }))}
                        /> */}

                        <Stack direction="row" justifyContent="space-between">
                            <Button
                                onClick={() => setCustomListDrawer(false)}
                                variant='outlined'
                                color="primary"
                            >
                                Kapat
                            </Button>

                            <Button
                                onClick={handleAddNewCustomApplicationList}
                                variant='contained'
                                color="primary"
                            >
                                Oluştur
                            </Button>
                        </Stack>
                    </Stack>

                </DialogContent>
            </Dialog>


            <Dialog
                fullWidth
                open={!!settingsDrawer}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setSettingsDrawer(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <Stack
                        spacing={3}
                        py={3}
                        minWidth={{ xs: "100%", sm: '400px' }}
                    >

                        <Typography variant='h3'>
                            Ayarlar
                        </Typography>

                        <Stack mb={2} spacing={2}>
                            {
                                customApplicationList?.data?.map((item: any, index: number) => {

                                    return (
                                        <Stack key={index} direction="row" spacing={2}>
                                            <Typography
                                                sx={{
                                                    border: '1px solid #ccc',
                                                    flex: 1,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    px: 3
                                                }}
                                            >
                                                {item?.name}
                                            </Typography>

                                            <Button
                                                onClick={() => handleRemoveFromCustomApplicationList(item?._id)}
                                                variant="outlined"
                                                color="error"
                                            >
                                                <DeleteOutline sx={{ mr: 1 }} />
                                                Sil
                                            </Button>
                                        </Stack>
                                    )
                                })
                            }
                        </Stack>



                        <Stack direction="row" justifyContent="end">
                            <Button
                                onClick={() => setSettingsDrawer(false)}
                                variant='outlined'
                                color="primary"
                            >
                                Kapat
                            </Button>

                            {/* <Button
                                onClick={handleAddNewCustomApplicationList}
                                variant='contained'
                                color="primary"
                            >
                                Oluştur
                            </Button> */}
                        </Stack>
                    </Stack>

                </DialogContent>
            </Dialog>
        </>
    )
}