import { createSlice } from "@reduxjs/toolkit";


export interface ICandidateCreateOzgelecek {
    formIndex: number;
    questionIndex: number;
    ozgelecekTestIndex: number;
    values: {
        field: string,
        references: Array<{ _id: string }>,

        testResults: {
            areaTestResults: Array<{
                answer: number,
                question: {
                    _id: string
                }
            }>,
            motivationTestResults: Array<{
                answer: number,
                question: {
                    _id: string
                }
            }>,
        },

        ozgelecekTest: {
            first: {
                text?: string | null,
                video?: string | null
            },
            second: {
                text?: string | null,
                video?: string | null
            }
        }
    }
}

const initialState: ICandidateCreateOzgelecek = {
    formIndex: 1,
    questionIndex: 1,
    ozgelecekTestIndex: 0,
    values: {
        field: "",
        ozgelecekTest: {
            first: {
                text: "",
                video: ""
            },
            second: {
                text: "",
                video: ""
            }
        },
        references: [],
        testResults: {
            areaTestResults: [],
            motivationTestResults: []
        }
    }
}


const localInitialValues: ICandidateCreateOzgelecek = sessionStorage.getItem("candidateCreateOzgecekForm")
    ? JSON.parse(sessionStorage.getItem("candidateCreateOzgecekForm") ?? "")
    : initialState


const setInitialStateToSessionStorage = (state: any) => {
    sessionStorage.setItem("candidateCreateOzgecekForm", JSON.stringify(state))
}

const { actions, reducer } = createSlice({
    name: 'candidateCreateOzgecek',
    initialState: localInitialValues,
    reducers: {
        resetCandidateCreateOzgelecekForm: (state, action) => {
            state.formIndex = initialState.formIndex
            state.ozgelecekTestIndex = initialState.ozgelecekTestIndex
            state.questionIndex = initialState.questionIndex
            state.values = initialState.values

            setInitialStateToSessionStorage(state)
        },

        setCandidateCreateOzgelecekFormIndex: (state, action) => {
            state.formIndex = action.payload
            setInitialStateToSessionStorage(state)
        },

        setCandidateCreateOzgelecekQuestionIndex: (state, action) => {
            state.questionIndex = action.payload
            setInitialStateToSessionStorage(state)
        },

        setCandidateCreateOzgelecekOzgelecekTestIndex: (state, action) => {
            state.ozgelecekTestIndex = action.payload
            setInitialStateToSessionStorage(state)
        },

        setCandidateCreateOzgelecekFormField: (state, action) => {
            state.values.field = action.payload
            setInitialStateToSessionStorage(state)
        },

        setCandidateCreateOzgelecekFormFieldTestResults: (state, action) => {

            const findedItem = state.values.testResults.areaTestResults?.find((item: any) => item?.question?._id === action.payload?.question?._id)

            if (findedItem) {
                findedItem.answer = action.payload?.answer
            } else {
                state.values.testResults.areaTestResults.push({
                    answer: action.payload?.answer,
                    question: {
                        _id: action.payload?.question?._id
                    }
                })
            }
            setInitialStateToSessionStorage(state)
        },

        setCandidateCreateOzgelecekFormMotivationTestResults: (state, action) => {

            const findedItem = state.values.testResults.motivationTestResults?.find((item: any) => item?.question?._id === action.payload?.question?._id)

            if (findedItem) {
                findedItem.answer = action.payload?.answer
            } else {
                state.values.testResults.motivationTestResults.push({
                    answer: action.payload?.answer,
                    question: {
                        _id: action.payload?.question?._id
                    }
                })
            }

            setInitialStateToSessionStorage(state)
        },

        setCandidateCreateOzgelecekFormOzgelecekTest: (state, action) => {

            console.log({ action });
            if (state.ozgelecekTestIndex === 1) {
                state.values.ozgelecekTest.first = action.payload
            } else {
                state.values.ozgelecekTest.first = action.payload
            }

            setInitialStateToSessionStorage(state)
        },

        setCandidateCreateOzgelecekFormReferences: (state, action) => {

            // const findedItem = state.values.references?.find((item: any) => item?._id === action.payload)

            // console.log({ action: action.payload, findedItem });
            // if (findedItem) {
            //     const newReferences = state.values.references.filter((item: any) => item?._id !== action.payload)
            //     state.values.references = newReferences
            // } else {
            //     // state.values.references = []
            //     state.values.references.push({ _id: action.payload })
            // }

            state.values.references = action.payload
            setInitialStateToSessionStorage(state)
        },
    }
})

export const {
    resetCandidateCreateOzgelecekForm,

    setCandidateCreateOzgelecekFormIndex,
    setCandidateCreateOzgelecekQuestionIndex,
    setCandidateCreateOzgelecekOzgelecekTestIndex,

    setCandidateCreateOzgelecekFormField,
    setCandidateCreateOzgelecekFormFieldTestResults,
    setCandidateCreateOzgelecekFormMotivationTestResults,
    setCandidateCreateOzgelecekFormOzgelecekTest,
    setCandidateCreateOzgelecekFormReferences,
} = actions

export const candidateCreateOzgelecekSlice = reducer

