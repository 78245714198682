

export interface ICandidateTestVideoUploadPresignedUrlResponse {
    presignedThumbnailUpload: string
    presignedUploadUrl: string
    fileName: string
}


export interface ICandidateTestVideoUploadPresignedUrlVariables {
    questionIndex: number
    ozgelecekField: string | null | undefined | any
}



export const candidateTestVideoUploadPresignedUrl = (variables: ICandidateTestVideoUploadPresignedUrlVariables) => {

    const data = JSON.stringify({
        query: `query CandidateTestVideoUploadPresignedUrl($ozgelecekField: String!, $questionIndex: Int!) {
            candidateTestVideoUploadPresignedUrl(ozgelecekField: $ozgelecekField, questionIndex: $questionIndex) {
              fileName
              presignedUploadUrl
            }
          }`,
        variables: variables
    })

    return data
}