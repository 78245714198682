import * as yup from "yup"
import { useEffect } from "react"
import { Formik, useFormikContext } from 'formik'
import { Button, Card, Grid, Stack, Typography } from '@mui/material'

import { useGetCitiesQuery, useGetCountriesQuery } from "context"
import { GendersList, ICandidateInformationInput, ICandidatePersonalInformation, SexsList } from 'types'
import { FormikDatePicker, FormikSelect, FormikSelectCountry, FormikTextField, FormikTextFieldPhone } from 'components'
import dayjs from "dayjs"






interface ICandidateAccountPersonalInformationFormProps {
    isButton?: boolean,
    onSubmit?: (e: ICandidatePersonalInformation) => any,
    defaultValues?: ICandidatePersonalInformation | undefined | null,
}

export const CandidateAccountPersonalInformationForm = ({ onSubmit, defaultValues, isButton }: ICandidateAccountPersonalInformationFormProps) => {

    const { values, setFieldValue, handleSubmit } = useFormikContext<ICandidateInformationInput>()
    const { data: countriesData, } = useGetCountriesQuery({})
    const { data: citiesData } = useGetCitiesQuery({ countryName: "Turkey" })
    // const { data: citiesData } = useGetCitiesQuery(
    //     { countryName: values?.personalInformation?.country?.name ?? values?.personalInformation?.country },
    //     { skip: !values?.personalInformation?.country }
    // )

    // useEffect(() => {
    //     if (values?.personalInformation?.country?.name) {
    //         setFieldValue("personalInformation.city", null)
    //     }
    // }, [setFieldValue, values?.personalInformation?.country?.name])


    // console.log({ parameters });






    return (
        <Card sx={{ width: '100%', p: 2 }}>

            <Grid container spacing={3} >

                <Grid item xs={12} mb={2}>
                    <Typography variant="h5">
                        Kişisel Bilgiler
                    </Typography>
                </Grid>

                {/* <Grid item xs={12} sm={6}>
                    <FormikTextField
                        name="personalInformation.name"
                        label="Name"
                    />
                </Grid> */}

                {/* <Grid item xs={12} sm={6}>
                    <FormikTextField
                        name="personalInformation.surname"
                        label="Surname"
                    />
                </Grid> */}

                <Grid item xs={12} sm={6}>
                    <FormikTextFieldPhone
                        name="personalInformation.phoneNumber"
                        label="Telefon Numarası"
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormikDatePicker
                        name="personalInformation.birthDate"
                        label="Doğum Tarihi"
                        maxDate={(new Date() as any - 12 * 31556952000) as unknown as Date}
                    />
                </Grid>


                {/* <Grid item xs={12}>
                    <FormikTextField
                        multiline
                        minRows={4}
                        name="personalInformation.description"
                        label="Description"
                    />
                </Grid> */}


                {/* <Grid item xs={12} sm={6}>
                    <FormikUpload
                        name="personalInformation.backgroundImageUrl"
                        label="Background Image"
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormikUpload
                        name="personalInformation.profileImageUrl"
                        label="Profile Image"
                    />
                </Grid> */}



                <Grid item xs={12} sm={6}>
                    <FormikSelect
                        name="personalInformation.sex"
                        label="Cinsiyet"
                        list={SexsList}
                    />
                </Grid>

                {/* <Grid item xs={12} sm={6}>
                    <FormikSelect
                        name="personalInformation.gender"
                        label="Cinsel Yönelim"
                        list={GendersList}
                    />
                </Grid> */}

                {/* {
                    values?.personalInformation?.gender === "specified" && (
                        <Grid item xs={12} justifyContent={"flex-end"} display={"flex"}>

                            <FormikTextField
                                name="personalInformation.genderDetail"
                                label="Cinsel Yönelim Detayı"
                            />

                        </Grid>
                    )
                } */}


                {/* {
                    defaultValues?.gender === "specified" && (
                        <Grid item xs={12}>
                            <FormikTextField
                                multiline
                                minRows={4}
                                name="personalInformation.genderDetail"
                                label="Gender Detail"
                            />
                        </Grid>
                    )
                } */}

                <Grid item xs={12} sm={6}>
                    <FormikSelectCountry
                        name="personalInformation.nationality"
                        label="Uyruk"
                        list={countriesData?.data ?? []}
                    />
                </Grid>


                {/* <Grid item xs={12} sm={6}>
                        <FormikSelectCountry
                            name="personalInformation.country"
                            label="Country"
                            list={countriesData?.data ?? []}
                        />
                    </Grid> */}


                {/* <Grid item xs={12} sm={6}>
                        <FormikSelect
                            name="personalInformation.city"
                            label="City"
                            list={citiesData?.data ?? []}
                        />
                    </Grid> */}

                <Grid item xs={12} sm={6}>
                    {/* <FormikTextField
                        name="personalInformation.city"
                        label="İl"
                    /> */}
                    <FormikSelect
                        name="personalInformation.city"
                        label="City"
                        list={citiesData?.data ?? []}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    {/* <FormikTextField
                        name="personalInformation.districts"
                        label="İlçe"
                    /> */}
                    <FormikSelect
                        name="personalInformation.districts"
                        label="İlçe"
                        list={citiesData?.data?.find((item) => item.name === values.personalInformation?.city)?.districts ?? []}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormikTextField
                        name="personalInformation.citizenship"
                        label="Farklı Vatandaşlık"
                    />
                </Grid>





                <Grid item xs={12} >
                    <FormikTextField
                        multiline
                        minRows={4}
                        name="personalInformation.address"
                        label="Adres"
                    />
                </Grid>





                <Grid item xs={12} >
                    <Stack direction="row" justifyContent="end">
                        <Button
                            onClick={() => handleSubmit()}
                            variant="contained"
                            color="primary"
                        >
                            Güncelle
                        </Button>
                    </Stack>
                </Grid>

            </Grid>
        </Card>
    )
}
