import { createSlice } from "@reduxjs/toolkit";


export interface ICompanyCreateAdvertisement {
    advertisementFormIndex: number;
    advertisementId: string;
}

const initialState: ICompanyCreateAdvertisement = {
    advertisementFormIndex: 0,
    advertisementId: "",
}



const { actions, reducer } = createSlice({
    name: 'candidateCreateOzgecek',
    initialState: initialState,
    reducers: {
        setAdvertisementFormIndex: (state, action) => {
            state.advertisementFormIndex = action.payload
        },
        setAdvertisementId: (state, action) => {
            state.advertisementId = action.payload
        }
    }
})

export const {
    setAdvertisementFormIndex, setAdvertisementId
} = actions

export const companyCreateAdvertisementSlice = reducer

