
import { Box, Stack, Typography, Button, Container, } from '@mui/material'

import { Iconify } from 'components';
import { logoutReducer, useAppDispatch, useAppSelector } from 'context'
import { Logo, NavbarPattern, Onboarding1 } from "assets";





export const OnboardingLayout = ({ children }: any) => {

    const dispatch: any = useAppDispatch()

    const { userType, device } = useAppSelector(state => state.auth)

    const handleLogout = () => {
        dispatch(logoutReducer({}))
        document.location.reload();
    }


    return (
        <Stack direction="row" sx={{ height: '100%', }} >

            <Stack
                direction="column"
                justifyContent='space-between'
                sx={{
                    width: { xs: '300px', lg: '560px' },
                    backgroundSize: 'cover',
                    backgroundColor: '#F8F8FD',
                    borderRight: '2px solid #dedede',
                    backgroundImage: `url(${NavbarPattern})`,
                    minWidth: '360px',
                    maxWidth: '560px',
                    display: { xs: 'none', md: 'flex' },
                    overflow: 'auto',
                }}
            >
                <Box sx={{ mb: 2, mt: 6, ml: 6, justifyContent: 'left', display: 'flex' }}>
                    <img height={"60px"} src={Logo} alt="InternIF Logo" />
                </Box>

                <Box sx={{ ml: 6, mb: 2, mr: 10 }}>
                    {userType === "candidate" && <Typography variant="h2" sx={{ fontWeight: 700, color: 'primary.darker' }}>
                        Özgeleceğiniz ile geleceğinize yön verin!
                    </Typography>}
                    {userType === "company" && <Typography variant="h2" sx={{ fontWeight: 700, color: 'primary.darker' }}>
                        Özgelecek ile aradığınız çalışana kavuşmak üzeresiniz!
                    </Typography>}
                    {userType === "candidate" && <Typography fontSize={"19px"} sx={{ pt: 3, fontWeight: 500, color: 'primary.darker' }} textAlign={"justify"}>
                        Özgelecek oluşturmanız için birkaç adım kaldı. <br /><br /> Geleceğinizi şekillendirmenizi sağlayacak bu sistem içerisinde verdiğiniz yanıtların <strong>büyük bir içtenlikle</strong> olduğundan ve yanıtların <strong>doğru</strong> olduğundan lütfen emin olun.
                    </Typography>}
                    {userType === "company" && <Typography fontSize={"19px"} sx={{ pt: 3, fontWeight: 500, color: 'primary.darker' }} textAlign={"justify"}>
                        Özgelecek vizyonuyla tanışmaya attığınız ilk adım için teşekkür ederiz!<br /><br />
                        Türkiye’nin en inovatif ve en vizyoner insan kaynakları teknolojisi olarak; sizlere etkin, verimli, mutlu, motivasyonu ve iş alanına ilgisi yüksek adayları <strong>“ölçümleyerek”</strong> sunuyoruz.<br /><br />
                        Özgelecek, aradığınız çalışana ulaşma yolculuğunuzda daima yanınızda olacak!<br />
                    </Typography>}
                </Box>

                <Box sx={{ ml: 6, mb: 2, mr: 10, display: 'flex', justifyContent: 'center' }}>
                    <img src={Onboarding1} alt="onboarding-1" width={"70%"} />
                </Box>

            </Stack>


            <Container sx={{ p: { xs: 2, sm: 3 }, pr: { xl: 15 }, pt: 5, ml: 0, overflow: 'auto', flex: 1, }}>

                <Box
                    component="img"
                    src={Logo}
                    height={90}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        m: 'auto',
                        my: 3,
                    }}
                />

                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mt: 3 }} flexWrap="wrap" gap={3}>
                    <Typography variant="h1" sx={{ fontWeight: 700, color: 'primary.dark' }}>
                        Ön Kayıt
                    </Typography>

                    {device !== "mobile" && <Button variant={"text"} size='small' color={"primary"} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        onClick={() => handleLogout()}>
                        Çıkış Yap <Iconify icon={"mdi:logout"} sx={{ ml: 1 }} />
                    </Button>}
                </Stack>

                <Box sx={{ py: 3 }}>
                    {children}
                </Box>

            </Container>

        </Stack >
    )

}
