import { useEffect, useState } from 'react'
import {
    Typography, Box, Stack, TextField, MenuItem, Button, TableContainer, Table, TableBody, TableRow, TableCell, TableHead,
    Paper, Dialog, DialogTitle, DialogContent, DialogActions, Avatar, Divider, Pagination
} from '@mui/material'
import { DashboardLayout } from 'layouts'
import { CompanyCandidateCard, Iconify } from 'components'
import { DriverLicenseStatusEnum, GenderEnum, LanguageEnums, LanguageLevelEnum, OzgelecekFieldEnum, SexEnum, getNameOfEnum, getValueOfEnum } from 'types'
import {
    ICompanyCandidatePoolResponse, ICompanyCandidatePoolVariables, useLazyGetCompanyCandidatePoolQuery, useLazyGetCompanyCandidatePoolPriceQuery,
    useGetSelfUserQuery, useLazyGetCompanyCandidateQuery, useGetCitiesQuery,
    useGetCompanyRadarHistoriesQuery
} from 'context'
import HigherEducationDegree from "enums/user/user/education/HigherEducationDegree";
import { LockOutlined, RemoveRedEyeOutlined } from '@mui/icons-material'
import moment from 'moment'
import { enqueueSnackbar } from 'notistack'

export const CompanyHavuzPage = () => {

    const [getCompanyCandidatePool] = useLazyGetCompanyCandidatePoolQuery()
    const [getPoolPrice] = useLazyGetCompanyCandidatePoolPriceQuery()
    const [getCompanyCandidate] = useLazyGetCompanyCandidateQuery()
    const [pool, setPool] = useState<ICompanyCandidatePoolResponse | null>(null)
    const { data: user } = useGetSelfUserQuery({})
    const { data: citiesData } = useGetCitiesQuery({ countryName: "Turkey" })
    const [radarHistory, setRadarHistory] = useState<any[]>([])

    const [poolPrice, setPoolPrice] = useState<number | null>(null)
    const [candidateDetail, setCandidateDetail] = useState<any | null>(null)
    const [candidateId, setCandidateId] = useState<string | null>(null)
    const [poolId, setPoolId] = useState<string | null>(null)
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)

    const { data: poolData, } = useGetCompanyRadarHistoriesQuery({
        filter: {
            field: null,
        },
        pagination: {
            page: page,
            pageSize: pageSize,
            sort: ""
        }
    })

    const [variables, setVariables] = useState<ICompanyCandidatePoolVariables>(
        {
            filter: {
                field: null,
                motivationTestResults: null,
                languages: null,
                higherEducationDegree: null,
                candidateSex: null,
                candidateCity: null,
                areaTestResults: null,
                createdAtDateFilter: {
                    from: null,
                    to: null
                }
            },
            pagination: {
                page: 1,
                pageSize: 10
            }
        }
    )



    const handleFindCandidates = async ({ newValues }: { newValues?: any } = {}) => {
        const candidatePool = await getCompanyCandidatePool(newValues ?? variables);

        if (candidatePool?.data) {
            setPool(candidatePool?.data ?? null)
        }
    }


    const handlePoolPrice = async (id: string) => {
        const poolPrice = await getPoolPrice({ id: id });
        setPoolPrice(poolPrice?.data ?? null)
    }

    const handleCandidateDetail = async (id: string) => {
        const candidate = await getCompanyCandidate({ id: id });

        if (candidate?.data?.errors?.[0]?.message?.includes("InsufficientStandardCredit")) {
            enqueueSnackbar("Yeterli krediniz bulunmamaktadır.", { variant: "error" })
        } else {
            // enqueueSnackbar("Adayın kilidi başarıyla açıldı.", { variant: "success" })
            setCandidateDetail(candidate?.data?.data?.company_candidate ?? null)
            handleFindCandidates()
            setRadarHistory([{ _id: "1" }])
        }
    }

    const handleCandidateDetail2 = async (id: string) => {
        const candidate = await getCompanyCandidate({ id: id });

        setCandidateDetail(candidate?.data?.data?.company_candidate ?? null)
        setRadarHistory([{ _id: "1" }])

    }

    const handleCandidatePreview = (candidate: any) => {
        setRadarHistory([])
        setCandidateDetail(candidate)
    }


    return (
        <DashboardLayout>

            <Typography variant='h1'>
                Aday Havuzu
            </Typography>

            <Box bgcolor={"secondary.lighter"} p={4} mt={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}>
                    <Iconify icon={"material-symbols:info-outline"} />
                </Box>
                <Typography variant="body2" >
                    Aday Havuzu sistemi ile aşağıdaki filtreleri kullanarak adayları gizli bir şekilde listeyebilirsiniz. Bilgilerini merak ettiğiniz adayların kilidini açarak onlarla iletişime geçebilirsiniz.
                </Typography>
            </Box>

            <Stack
                direction={{ md: 'row', sm: 'column' }}
                gap={1}
                sx={{
                    height: 'auto',
                    mt: 3,
                }}
            >

                <TextField
                    select
                    label="Alan*"
                    fullWidth
                    value={variables.filter?.field ?? ""}
                    onChange={(e) => setVariables({
                        ...variables,
                        filter: {
                            ...variables.filter,
                            field: e.target.value as string
                        }
                    })}
                >
                    {Object.values(OzgelecekFieldEnum)?.map((value) => (
                        <MenuItem key={value} value={getValueOfEnum(OzgelecekFieldEnum, value)}>
                            {value}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    select
                    label="Cinsiyet"
                    fullWidth
                    value={variables.filter?.candidateSex ?? ""}
                    onChange={(e) => setVariables({
                        ...variables,
                        filter: {
                            ...variables.filter,
                            candidateSex: e.target.value as string
                        }
                    })}
                >
                    {Object.values(SexEnum)?.map((value) => (
                        <MenuItem key={value} value={getValueOfEnum(SexEnum, value)}>
                            {value}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    select
                    label="Eğitim Durumu"
                    fullWidth
                    value={variables.filter?.higherEducationDegree ?? ""}
                    onChange={(e) => setVariables({
                        ...variables,
                        filter: {
                            ...variables.filter,
                            higherEducationDegree: e.target.value as string
                        }
                    })}
                >
                    {Object.values(HigherEducationDegree)?.map((value) => (
                        <MenuItem key={value} value={getValueOfEnum(HigherEducationDegree, value)}>
                            {value}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    select
                    label="Dil"
                    fullWidth
                    value={variables.filter?.languages ?? ""}
                    onChange={(e) => setVariables({
                        ...variables,
                        filter: {
                            ...variables.filter,
                            languages: [e.target?.value as string]
                        }
                    })}
                >
                    {Object.values(LanguageEnums)?.map((value) => (
                        <MenuItem key={value} value={getValueOfEnum(LanguageEnums, value)}>
                            {value}
                        </MenuItem>
                    ))}
                </TextField>
            </Stack>
            <Stack
                direction={{ md: 'row', sm: 'column' }}
                gap={1}
                sx={{
                    height: 'auto',
                    mt: 3,
                }}
            >

                <TextField
                    select
                    label="Şehir"
                    fullWidth
                    value={variables.filter?.candidateCity ?? ""}
                    onChange={(e) => setVariables({
                        ...variables,
                        filter: {
                            ...variables.filter,
                            candidateCity: e.target.value as string
                        }
                    })}
                >
                    {citiesData?.data?.map((city, index) => (
                        <MenuItem key={index} value={city?.name}>
                            {city?.name}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    label="Alan Testi Puanı"
                    fullWidth
                    placeholder='Kaç puandan yüksek olmalı?'
                    type='number'
                    value={variables.filter?.areaTestResults ?? ""}
                    onChange={(e) => setVariables({
                        ...variables,
                        filter: {
                            ...variables.filter,
                            areaTestResults: Number(e.target.value)
                        }
                    })}
                />

                <TextField
                    label="Motivasyon Testi Puanı"
                    fullWidth
                    placeholder='Kaç puandan yüksek olmalı?'
                    type='number'
                    value={variables.filter?.motivationTestResults ?? ""}
                    onChange={(e) => setVariables({
                        ...variables,
                        filter: {
                            ...variables.filter,
                            motivationTestResults: Number(e.target.value)
                        }
                    })}
                />


                <Button
                    onClick={() => handleFindCandidates()}
                    variant='contained'
                    disabled={!variables.filter?.field}
                    color="primary"
                    sx={{
                        width: '100%',
                    }}
                >
                    Adayları Bul
                </Button>
            </Stack>

            <Stack mt={3}>
                <TableContainer component={Paper}>

                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Adı ve Soyadı</TableCell>
                                <TableCell align="center">Cinsiyet</TableCell>
                                <TableCell align="center">Eğitim Durumu</TableCell>
                                <TableCell align="center">İlçe / Şehir</TableCell>
                                <TableCell align="center">Alan / Puanlar </TableCell>
                                <TableCell align="center">Aksiyon</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pool?.data ? pool?.data?.length > 0 ?
                                pool?.data?.map((row, index) => (
                                    <TableRow
                                        key={row._id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.candidate?.personalInformation?.name} {row.candidate?.personalInformation?.surname}
                                        </TableCell>
                                        <TableCell align="center">{getNameOfEnum(SexEnum, row?.candidate?.personalInformation?.sex ?? "")}</TableCell>
                                        <TableCell align="center">{row.candidate?.education?.higherEducation ? row.candidate?.education?.higherEducation
                                            ?.map(degree => getNameOfEnum(HigherEducationDegree, degree?.higherEducationDegree))
                                            .filter(Boolean)
                                            ?.join(', ') : ""}</TableCell>
                                        <TableCell align="center">{row.candidate?.personalInformation?.district} / {row.candidate?.personalInformation?.city}</TableCell>
                                        <TableCell align="center">{getNameOfEnum(OzgelecekFieldEnum, String(row?.field) ?? "")} / {row.testResults?.areaTestScore} & {row.testResults?.motivationTestScore}</TableCell>
                                        <TableCell align="center">
                                            {/* {row?.radarHistory?.length > 0 ?  */}
                                            <Button
                                                variant='outlined'
                                                color='primary'
                                                onClick={() => {
                                                    if (row?.radarHistory?.length > 0) {
                                                        handleCandidateDetail(row.candidate?._id ?? "");
                                                        setRadarHistory(row.radarHistory)
                                                    } else {
                                                        handleCandidatePreview(row?.candidate)
                                                        setPoolId(row._id ?? "")
                                                    }
                                                }}
                                            >
                                                Detay <RemoveRedEyeOutlined sx={{ ml: 1 }} />
                                            </Button>
                                            {/* :
                                                <Button
                                                    variant='outlined'
                                                    color='error'
                                                    onClick={() => {
                                                        handlePoolPrice(row._id ?? "")
                                                        setCandidateId(row.candidate?._id ?? "")
                                                    }}
                                                >
                                                    Kilit Aç  <LockOutlined sx={{ ml: 1 }} />
                                                </Button>} */}
                                        </TableCell>
                                    </TableRow>
                                ))
                                :
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        Kriterlere uygun bir aday bulunamadı.
                                    </TableCell>
                                </TableRow>
                                :
                                <TableRow>
                                    <TableCell colSpan={7} align="center" sx={{ paddingTop: 4 }}>
                                        Listeyi görüntüleyebilmek için lütfen filtreleme yapınız.
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>

                    <Stack direction={"row"} justifyContent={"center"} mt={2}>
                        <Pagination
                            color="primary"
                            count={Math.ceil((pool?.count || 0) / (variables?.pagination?.pageSize ?? 1))}
                            page={variables?.pagination?.page ?? 1}
                            onChange={(e, page) => {
                                handleFindCandidates({ newValues: { ...variables, pagination: { ...variables?.pagination, page } } })
                                setVariables({ ...variables, pagination: { ...variables?.pagination, page } })
                            }}
                        />
                    </Stack>
                </TableContainer>

            </Stack>

            <Divider sx={{ mt: 6 }}>
                <Typography variant='h5'>
                    Önceden Açılmış Adaylar
                </Typography>
            </Divider>
            <Stack mt={3}>
                <TableContainer component={Paper}>

                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Adı ve Soyadı</TableCell>
                                <TableCell align="center">İlçe / Şehir</TableCell>
                                <TableCell align="center">Aksiyon</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {poolData?.data ? poolData?.data?.length > 0 ?
                                poolData?.data?.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {pageSize ? (page - 1) * pageSize + index + 1 : index + 1}
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 1 }}>
                                            <Avatar alt={row.candidate?.personalInformation?.name} src={row.candidate?.personalInformation?.profileImageUrl} sx={{ width: 40, height: 40 }} />
                                            {row.candidate?.personalInformation?.name} {row.candidate?.personalInformation?.surname}
                                        </TableCell>
                                        <TableCell align="center">{row.candidate?.personalInformation?.district} / {row.candidate?.personalInformation?.city}</TableCell>
                                        <TableCell align="center">
                                            <Button
                                                variant='outlined'
                                                color='primary'
                                                onClick={() => {
                                                    handleCandidateDetail2(row.candidate?._id ?? "")
                                                }}
                                            >
                                                Detay <RemoveRedEyeOutlined sx={{ ml: 1 }} />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                                :
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        Kriterlere uygun bir aday bulunamadı.
                                    </TableCell>
                                </TableRow>
                                :
                                <TableRow>
                                    <TableCell colSpan={7} align="center" sx={{ paddingTop: 4 }}>
                                        Listeyi görüntüleyebilmek için lütfen filtreleme yapınız.
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>

                    <Stack direction={"row"} justifyContent={"center"} mt={2}>
                        <Pagination
                            color="primary"
                            count={Math.ceil((poolData?.count || 0) / (pageSize ?? 1))}
                            page={page ?? 1}
                            onChange={(e, page) =>
                                setPage(page)
                            }
                        />
                    </Stack>
                </TableContainer>

            </Stack>

            <Dialog open={!!poolPrice} onClose={() => {
                setPoolPrice(null)
            }}>
                <DialogTitle id="simple-dialog-title">Bu adayın kilidini açmak istiyor musunuz?</DialogTitle>
                <DialogContent >
                    <Typography fontSize={"medium"} mb={2}>
                        Bu işlem için <strong>{poolPrice}</strong> kredi kullanılacaktır.
                    </Typography>


                </DialogContent>
                <DialogActions sx={{ justifyContent: "space-between" }}>
                    <Typography fontSize={"small"} fontWeight={"light"} mt={2} >
                        *Toplam Krediniz : <strong>{user?.credit?.credit?.total ?? 0}</strong>
                    </Typography>
                    <Stack direction={"row"} spacing={2}>
                        <Button onClick={() => {
                            setCandidateId(null)
                            setPoolPrice(null)
                        }}
                            color="primary" variant='outlined'>
                            İptal
                        </Button>
                        <Button onClick={() => {
                            handleCandidateDetail(candidateId ?? "")
                            setPoolPrice(null)
                        }} color="primary" variant='contained'>
                            Onayla
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>

            <Dialog open={!!candidateDetail} onClose={() => {
                setCandidateDetail(null)
                setCandidateId(null)
            }}>
                <DialogTitle id="simple-dialog-title">Aday Detayı</DialogTitle>
                <DialogContent sx={{ width: { xs: '100%', sm: "600px" } }}>
                    <CompanyCandidateCard candidateDetail={candidateDetail} radarHistory={radarHistory} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setCandidateDetail(null)
                        setCandidateId(null)
                    }}
                        color="primary" variant='outlined'>
                        Kapat
                    </Button>
                    {
                        radarHistory?.length === 0 && <Button
                            variant='outlined'
                            color='error'
                            onClick={() => {
                                handlePoolPrice(poolId ?? "")
                                setCandidateId(candidateDetail?._id ?? "")
                            }}
                        >
                            Kilit Aç  <LockOutlined sx={{ ml: 1 }} />
                        </Button>
                    }
                </DialogActions>
            </Dialog>






        </DashboardLayout >
    )
}
