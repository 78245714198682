import HasTimeStamp from "interfaces/HasTimeStamp";
import { HasOptionalUserAudit } from "interfaces/HasUserAudit";
import IEntity from "interfaces/IEntity";
import { Role } from "types/_role";


export interface IUserUpdateOrderResponse {
  user_updateOrderAfterIyzicoCheckoutFormPayment: {
    order: IEntity;
    paymentResult: {
      paymentStatus: string;
    }
  }
}

export interface IUserUpdateOrderVariables {
  orderId: any
}


export const user_updateOrder = (variables: IUserUpdateOrderVariables) => {

  console.log(variables, "variables")
  const data = JSON.stringify({
    query: `mutation User_updateOrderAfterIyzicoCheckoutFormPayment($orderId: ID!) {
      user_updateOrderAfterIyzicoCheckoutFormPayment(orderId: $orderId) {
        paymentResult {
          paymentStatus
        }
        order {
          _id
        }
      }
    }`,
    variables: {
      "orderId": variables.orderId
    }
  })

  return data
}
