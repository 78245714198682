

// export type TCandidateCreateApplicationMutationResponse = {
//     reference_rejectReference: {
//         success: boolean
//     }
//   }

export type TCandidateCreateApplicationVariables = {
    advertisement: {
        _id: string
    },
    ozgelecek: {
        _id: string
    }

}



export const candidate_createApplication = (variables: TCandidateCreateApplicationVariables) => {

    const data = JSON.stringify({
        query: `
        mutation Candidate_createApplication($applicationInput: ApplicationInput!) {
            candidate_createApplication(applicationInput: $applicationInput) {
              _id
            }
          }
          `,
        variables: {
            "applicationInput": {
                "advertisement": {
                    "_id": variables?.advertisement?._id
                },
                "ozgelecek": {
                    "_id": variables?.ozgelecek?._id
                }
            }
        }
    })

    return data

}