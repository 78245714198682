import { Box, Card, Tab, Tabs, Typography } from '@mui/material'
import { CustomTabPanel, Iconify } from 'components'
import { DashboardLayout } from 'layouts'
import React from 'react'
import { useSearchParams } from 'react-router-dom';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import SettingsIcon from '@mui/icons-material/Settings';
import ArticleIcon from '@mui/icons-material/Article';
import { ChangePasswordSection, NotificationSettingsSection, AggrementsSection, CompanyVerificationSection } from 'sections';
import { BusinessCenter, Check, CheckCircle, Edit, Notifications } from '@mui/icons-material';
import { useFormik } from 'formik';
import { InvoiceInfoSection } from 'sections/common/settings/InvoiceInfoSection';




export const CompanySettingsPage = () => {

    const [searchParams] = useSearchParams();
    const tab = searchParams.get('tab');
    const [value, setValue] = React.useState(tab === "sifre" ? 0 : tab === "sirket-dogrulama" ? 1 : tab === "fatura-bilgileri" ? 2 : tab === "bildirim" ? 3 : 0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };







    return (
        <DashboardLayout>

            <Typography variant='h1'>
                Ayarlar
            </Typography>


            {/* <Box bgcolor={"secondary.lighter"} p={4} mt={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}>
                    <Iconify icon={"material-symbols:info-outline"} />
                </Box>
                <Typography variant="body2" >
                    Bu sayfada referanslarınızı görebilir ve referans ekleyebilirsiniz. Eklediğiniz referansları özgelecek oluştururken kullanabilirsiniz. Referans onayı gerçekleştiğinde ilanlara başvurabilirsiniz.
                    (Maksimum 5 referans eklenebilir)
                </Typography>
            </Box> */}


            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="icon position tabs example"
                textColor="primary"
                indicatorColor="primary"
                defaultValue={tab === "sifre" ? 0 : tab === "sirket-dogrulama" ? 1 : tab === "fatura-bilgileri" ? 2 : tab === "bildirim" ? 3 : 4}
                sx={{
                    my: 3
                }}
            >
                <Tab icon={<LockPersonIcon />} iconPosition="start" label="Şifre" />
                <Tab icon={<CheckCircle />} iconPosition="start" label="Şirket Doğrulama" />
                <Tab icon={<Edit />} iconPosition="start" label="Fatura Bilgileri" />
                <Tab icon={<Notifications />} iconPosition='start' label="Bildirim" />
                <Tab icon={<ArticleIcon />} iconPosition="start" label="Sözleşmeler" />
            </Tabs>


            <CustomTabPanel value={value} index={0}>
                <ChangePasswordSection />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
                <CompanyVerificationSection />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
                <InvoiceInfoSection />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={3}>
                <NotificationSettingsSection />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={4}>
                <AggrementsSection />
            </CustomTabPanel>

        </DashboardLayout>
    )
}




