
export interface ICandidateApplicationStatisticsResponse {
  pendingCount: number;
  suitableCount: number;
  unsuitableCount: number;
  viewedCount: number;
}


export const candidate_applicationStatistics = () => {

  const data = JSON.stringify({
    query: `query Candidate_applicationStatistics {
      candidate_applicationStatistics {
        pendingCount
        suitableCount
        unsuitableCount
        viewedCount
      }
    }`
  })


  return data

}