import React, { useState, useEffect } from 'react';
import { Switch, FormControlLabel, FormHelperText } from '@mui/material';

interface ISwitchProps {
    checked?: boolean;
    onChange?: (checked: boolean) => void;
}

export const FormikSwitch2: React.FC<ISwitchProps> = ({ checked: initialChecked = false, onChange = () => null }) => {
    const [checked, setChecked] = useState(initialChecked);

    useEffect(() => {
        setChecked(initialChecked);
    }, [initialChecked]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        setChecked(isChecked);
        onChange(isChecked);
    };

    return (
        <div>
            <FormControlLabel
                control={
                    <Switch
                        onChange={handleChange}
                        checked={checked}
                    />
                }
                label={""}
            />
        </div>
    );
};
