import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography, Pagination, Skeleton, Divider } from '@mui/material'

import { routes } from 'routes'
import { DashboardLayout } from 'layouts'
import { CompanyAdvertisementCard, FormikSelect, FormikSelect2, FormikSwitch, FormikSwitch2, MuiTransition } from 'components'
import { ICompanyAdvertisementsVariables, useGetCompanyAdvertisementsQuery, useGetCompanyInformationQuery, setAdvertisementFormIndex, useAppDispatch, useLazyGetAdvertisementCostQuery, useGetSelfUserQuery, useCompanyAddExtraProductToAdvertisementMutation, useAppSelector, IAuthState } from 'context'
import { Iconify } from 'components'
import { useCompanyRemoveAdvertisementMutation } from 'context'
import { enqueueSnackbar } from 'notistack'
import { useFormik, useFormikContext } from 'formik'

export const CompanyAdvertisementsPage = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const { device } = useAppSelector((state: any) => state?.auth)
    const [selectedAdvertisement, setSelectedAdvertisement] = useState<any>(null)
    const [selectedCardForDelete, setSelectedCardForDelete] = useState<any>(null)
    const [selectedCardForExtra, setSelectedCardForExtra] = useState<any>(null)
    const [removeAdvertisement] = useCompanyRemoveAdvertisementMutation()
    const { data: user } = useGetSelfUserQuery({})
    const [addExtraProductToAdvertisement] = useCompanyAddExtraProductToAdvertisementMutation()
    const [doping, setDoping] = useState(false)
    const [dopingQuantity, setDopingQuantity] = useState(1)
    const [showcase, setShowcase] = useState(false)
    const [emergent, setEmergent] = useState(false)
    const [getCost, { isFetching: isFetchingCost }] = useLazyGetAdvertisementCostQuery()
    const [products, setProducts] = useState<string[]>([])
    const [cost, setCost] = useState<number>(0)
    const [variables, setVariables] = useState<ICompanyAdvertisementsVariables>({
        filter: {
            filter: {
                cities: []
            },
            search: {
                all: ""
            }
        },
        pagination: {
            page: 1,
            pageSize: 5
        }
    })
    const { data: advertisements, isFetching } = useGetCompanyAdvertisementsQuery(variables)
    const { data: company } = useGetCompanyInformationQuery("")

    const handleDelete = (id: string) => {
        removeAdvertisement({ advertisementId: id })
            .then((res: any) => {
                setSelectedCardForDelete(null)
                setVariables({ ...variables })
                if (res?.data?.data?.company_removeAdvertisement?.success) {
                    enqueueSnackbar("İlan başarıyla silindi.", { variant: 'success' })
                } else {
                    enqueueSnackbar("İlan silinirken bir hata oluştu.", { variant: 'error' })
                }
            })
            .catch(() => {
                setSelectedCardForDelete(null)
                enqueueSnackbar("İlan silinirken bir hata oluştu.", { variant: 'error' })
            })
    }

    const handleExtraProductSubmit = async () => {
        const response = await addExtraProductToAdvertisement({ advertisementId: selectedCardForExtra?._id ?? "", extraProducts: products }) as any

        if (response?.data?.data?.company_addExtraProductToAdvertisement?.success) {
            enqueueSnackbar("Ekstra özellikler başarıyla eklendi!", { variant: 'success' })
            setSelectedCardForExtra(null)
            setProducts([])
            setDoping(false)
            setEmergent(false)
            setShowcase(false)
            setDopingQuantity(1)
        }
    }




    useEffect(() => {
        setProducts(doping ? Array(dopingQuantity).fill("doping").concat(emergent ? ["emergent"] : [], showcase ? ["showcase"] : []) : (emergent ? ["emergent"] : []).concat(showcase ? ["showcase"] : []));

        if (selectedCardForExtra && (doping || emergent || showcase)) {
            getCost({
                productType: selectedCardForExtra?.productType,
                extraProductTypes: doping ? Array(dopingQuantity).fill("doping").concat(emergent ? ["emergent"] : [], showcase ? ["showcase"] : []) : (emergent ? ["emergent"] : []).concat(showcase ? ["showcase"] : []),
                onlyForExtraProduct: true
            }).then(res => {
                if (res) {
                    setCost(res.data?.price ?? 0)
                }
            })
        }
    }, [selectedCardForExtra, doping, dopingQuantity, showcase, emergent])



    return (
        <DashboardLayout>

            <Stack direction="row" justifyContent="space-between">
                <Typography variant='h1'>
                    İlanlarım
                </Typography>

                {device !== "mobile" && <Button
                    variant='contained'
                    color="primary"
                    onClick={() => navigate(routes.company.dashboard.createAdvertisement)}
                >
                    İlan Oluştur
                </Button>}
            </Stack>

            <Box
                sx={{
                    py: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                }}
            >

                <Box bgcolor={"secondary.lighter"} p={4} mt={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                    <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}>
                        <Iconify icon={"material-symbols:info-outline"} />
                    </Box>
                    <Typography variant="body2" >
                        İlanlarım sayfası üzerinden oluşturduğunuz ilanları listeleyebilir, yeni ilan ekleyebilir, güncelleyebilir ve silebilirsiniz.
                    </Typography>
                </Box>
                {
                    isFetching ? <Stack spacing={2} >
                        {Array.from({ length: variables?.pagination?.pageSize ?? 5 }).map((_, index) => (
                            <Skeleton
                                key={index}
                                variant='rectangular'
                                sx={{
                                    p: 2,
                                    width: '100%',
                                    height: '100px'
                                }}
                            />
                        ))
                        }

                    </Stack> : (
                        advertisements?.count ? <Stack flexDirection="column" spacing={2} alignItems={"center"} sx={{ width: '100%' }}>
                            {advertisements?.data?.map((advertisement: any, index: number) => {
                                return (
                                    <CompanyAdvertisementCard
                                        key={index}
                                        logo={company?.companyInformation?.profileImage ?? ""}
                                        advertisement={advertisement}
                                        onShow={() => {
                                            navigate(`${routes.company.dashboard.advertisementDetails}/${advertisement?._id}`)
                                        }}
                                        onEdit={() => {
                                            navigate(`${routes.company.dashboard.updateAdvertisement}/${advertisement?._id}`)
                                        }}
                                        onDelete={() => {
                                            setSelectedCardForDelete(advertisement)
                                        }}
                                        onExtra={() => {
                                            setSelectedCardForExtra(advertisement)
                                        }}
                                    />
                                )
                            })}
                            {<Pagination
                                color="primary"
                                count={Math.ceil(advertisements?.count / (variables?.pagination?.pageSize ?? 1))}
                                page={variables?.pagination?.page ?? 1}
                                onChange={(e, page) => setVariables({ ...variables, pagination: { ...variables?.pagination, page } })}
                            />
                            }
                        </Stack> : (
                            <>
                                Hiçbir ilan bulunamadı.
                            </>
                        )
                    )

                }

            </Box>


            {/* <Dialog
                open={!!selectedAdvertisement}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setSelectedAdvertisement(null)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Advertisement Detail</DialogTitle>

                <DialogContent>


                    {
                        JSON.stringify(selectedAdvertisement)
                    }

                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setSelectedAdvertisement(null)}>
                        Close
                    </Button>

                    <Button onClick={() => navigate(routes.company.dashboard.updateAdvertisement + "/" + selectedAdvertisement?._id)}>
                        update
                    </Button>
                </DialogActions>
            </Dialog> */}

            <Dialog
                open={!!selectedCardForDelete}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setSelectedCardForDelete(null)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>İlanı Sil</DialogTitle>

                <DialogContent>
                    <Typography>
                        {selectedCardForDelete?.title} başlıklı ilanı silmek istediğinize emin misiniz?
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setSelectedCardForDelete(null)}>
                        İptal
                    </Button>

                    <Button
                        variant='contained'
                        onClick={() => {
                            handleDelete(selectedCardForDelete?._id)
                        }}
                        color="primary"
                    >
                        Sil
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={!!selectedCardForExtra}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => {
                    setSelectedCardForExtra(null)
                    setProducts([])
                    setDoping(false)
                    setEmergent(false)
                    setShowcase(false)
                    setDopingQuantity(1)
                }}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Ekstra Ürün Ekle</DialogTitle>

                <DialogContent>
                    <Typography mt={2} variant="subtitle1" color="primary" display={"flex"} alignItems={"center"} gap={2}><Iconify icon="ri:medicine-bottle-line" width={26} /> Doping ilan
                        <FormikSwitch2 onChange={(checked: boolean) => setDoping(checked) as any} checked={doping} />

                        {doping && <FormikSelect2 name="dopingQuantity" label={"Doping Miktarı"} sx={{ width: 115 }} size='small' disabled={!doping}
                            defaultValue={dopingQuantity} onChange={(value: number) => setDopingQuantity(value) as any}
                            list={[{ value: 1, name: "1" }, { value: 2, name: "2" }, { value: 3, name: "3" }, { value: 4, name: "4" }, { value: 5, name: "5" }]}
                        />}</Typography>
                    <Typography fontStyle={"italic"} variant="body2" color="textSecondary">Doping ilan kullanarak diğer ilanlardan daha önde ilanınızı yayınlatabilirsiniz.</Typography>

                    <Typography mt={4} variant="subtitle1" color="primary" display={"flex"} alignItems={"center"} gap={2}><Iconify icon="fluent:alert-urgent-16-regular" width={26} /> Acil ilan
                        <FormikSwitch2 onChange={(checked: boolean) => setEmergent(checked) as any} checked={emergent} />
                    </Typography>

                    <Typography fontStyle={"italic"} variant="body2" color="textSecondary">Acil ilan kullanarak adayların dikkatini daha çabuk çekebilirsiniz.</Typography>

                    <Typography mt={4} variant="subtitle1" color="primary" display={"flex"} alignItems={"center"} gap={2}><Iconify icon="material-symbols:photo-frame-outline" width={26} /> Vitrin ilan
                        <FormikSwitch2 onChange={(checked: boolean) => setShowcase(checked) as any} checked={showcase} />
                    </Typography>
                    <Typography fontStyle={"italic"} variant="body2" color="textSecondary">Vitrin ilan kullanarak ilanınızı daha fazla adaya gösterebilirsiniz.</Typography>

                    <Divider sx={{ mt: 3 }} />

                    {(!isFetchingCost ? <Typography fontSize={"12px"} textAlign={"left"} fontWeight={"bold"} color="textSecondary" sx={{ mt: 3 }}>Kullanılacak Kredi : {cost} / Toplam Kredi : {user?.credit?.credit?.total}</Typography> :
                        <Skeleton variant="text" sx={{ textAlign: "right" }} width={200} height={20} />)}  <br />
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={() => {
                            setSelectedCardForExtra(null)
                            setProducts([])
                            setDoping(false)
                            setEmergent(false)
                            setShowcase(false)
                            setDopingQuantity(1)
                        }}>
                        Kapat
                    </Button>
                    <Button
                        variant='contained'
                        onClick={() => {
                            handleExtraProductSubmit()
                        }
                        }
                        color="primary"
                    >
                        Ekstra Ürün Al
                    </Button>

                </DialogActions>
            </Dialog>

        </DashboardLayout>
    )
}
