
export interface IUserTestQuestionsItem {
  _id: string
  content: string
  createdAt: string
  title: string
  isReverseTest: string
  field: string
  index: number
  questionType: string
  updatedAt: string
}

export interface IUserTestQuestionsResponse {
  count: number,
  data: Array<IUserTestQuestionsItem>
}
export interface IUserTestQuestionsVariables {
  filter?: {
    field?: string | null,
    index?: number | null,
    isReverseTest?: string | null,
    questionType?: string | null,
    title?: string | null
  },
  pagination?: {
    page?: string | null,
    pageSize?: string | null,
    sort?: any | null
  }
}


export const user_testQuestions = (variables: IUserTestQuestionsVariables) => {

  const data = JSON.stringify({
    query: `
        query User_testQuestions($pagination: PaginationWithTestQuestionSort, $filter: FilterTestQuestionInputForUser) {
            user_testQuestions(pagination: $pagination, filter: $filter) {
              count
              data {
                _id
                content
                createdAt
                title
                isReverseTest
                field
                index
                questionType
                updatedAt
              }
            }
          }
        `,
    variables: {
      filter: {
        "field": variables?.filter?.field ?? null,
        "index": variables?.filter?.index ?? null,
        "questionType": variables?.filter?.questionType ?? null,
      },
      pagination: {
        "page": variables?.pagination?.page ?? null,
        "pageSize": variables?.pagination?.pageSize ?? null,
        "sort": variables?.pagination?.sort ?? null
      }
    }
  })

  return data
}
