import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Button, IconButton, Stack, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

import {
    useAppSelector,
    useAppDispatch,
    resetCandidateCreateOzgelecekForm,
    useCandidateCreateOzgelecekMutation,
    setCandidateCreateOzgelecekFormIndex,
    setCandidateCreateOzgelecekOzgelecekTestIndex,
    useCandidateTestVideoUploadPresignedUrlQuery,
    useLazyCandidateTestVideoUploadPresignedUrlQuery,
    setCandidateCreateOzgelecekFormField,
} from 'context';

import {
    OzgelecekTestForm,
    OzgelecekFieldsForm,
    OzgelecekSelectReferanceForm,
    OzgelecekCreateUpdateFinishSection,
    OzgelecekFieldAndMotivationTestForm,
} from 'sections';

import { routes } from 'routes'
import { DashboardLayout, DashboardLayoutMobile } from 'layouts'
import { OzgelecekProgressBar, MuiTransition } from 'components';
import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { saveVideoAndGetUrl } from 'utils';
import { CareerGif } from 'assets';



export const CandidateOzgelecekCreatePageMobile = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false)
    const [ozgelecekTest, setOzgelecekTest] = useState<any>()
    const ozgelecekForm = useAppSelector((state) => state.candidateCreateOzgelecek)
    const [candidate_createOzgelecek, { isLoading }] = useCandidateCreateOzgelecekMutation({})
    const [endTimeDialog, setEndTimeDialog] = useState<any>(null)
    const [acilisDialog, setAcilisDialog] = useState(true)
    const [getPresignedUrl, { data }] = useLazyCandidateTestVideoUploadPresignedUrlQuery()

    const selectedField = location?.state?.field
    const selectedAdvertisementId = location?.state?.advertisementId

    console.log(selectedAdvertisementId, "selectedAdvertisementId")

    useEffect(() => {
        console.log(selectedField, "selectedField")
        if (selectedField && ozgelecekForm.formIndex === 1) {
            dispatch(setCandidateCreateOzgelecekFormIndex(2))
            dispatch(setCandidateCreateOzgelecekFormField(selectedField))
        }
    }, [selectedField, dispatch, ozgelecekForm])

    useEffect(() => {
        dispatch(resetCandidateCreateOzgelecekForm({}))
        // if (ozgelecekForm.formIndex >= 4) {
        //     dispatch(setCandidateCreateOzgelecekFormIndex(4))
        //     dispatch(setCandidateCreateOzgelecekOzgelecekTestIndex(0))
        // }
    }, [])

    const handleCreateOzgelecek = async (values: any) => {
        setLoading(true)
        let newOzgelecekTest = {
            first: { ...ozgelecekTest?.first },
            second: { ...ozgelecekTest?.second },
        }
        try {
            if (ozgelecekTest?.first?.video) {
                const presignUrlResponse = await getPresignedUrl({ questionIndex: 0, ozgelecekField: selectedField ?? ozgelecekForm.values.field })
                let uploadReaponse = await saveVideoAndGetUrl({ file: ozgelecekTest?.first?.video, presignUrl: presignUrlResponse?.data?.presignedUploadUrl ?? "" })
                console.log({ presignUrlResponse, uploadReaponse });
                newOzgelecekTest.first = { video: presignUrlResponse?.data?.fileName }
            }

            if (ozgelecekTest?.second?.video) {
                const presignUrlResponse = await getPresignedUrl({ questionIndex: 1, ozgelecekField: selectedField ?? ozgelecekForm.values.field })
                let uploadReaponse = await saveVideoAndGetUrl({ file: ozgelecekTest?.second?.video, presignUrl: presignUrlResponse?.data?.presignedUploadUrl ?? "" })
                console.log({ presignUrlResponse, uploadReaponse });

                newOzgelecekTest.second = { video: presignUrlResponse?.data?.fileName }
            }

            // console.log({ newOzgelecekTest });
            const response: any = await candidate_createOzgelecek({
                variables: {
                    field: values.field,
                    ozgelecekTest: newOzgelecekTest,
                    references: values.references?.map((item: any) => ({ _id: item })),
                    testResults: values.testResults
                }
            })

            console.log({ response });

            if (response?.data?.data?.candidate_createOzgelecek?._id) {
                enqueueSnackbar("Özgelecek başarılı bir şekilde oluşturuldu!")
                sessionStorage.removeItem("candidateCreateOzgecekForm")
                selectedAdvertisementId ? navigate(routes.candidate.dashboard.advertisementDetails + `/${selectedAdvertisementId}`) : navigate(routes.candidate.dashboard.ozgeleceklerim)
                // navigate(routes.candidate.dashboard.ozgeleceklerimMobile)
            } else {
                enqueueSnackbar("Birşeyler yanlış gitti!", { variant: 'error' })
            }
        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: 'error' })
        }

        setLoading(false)

    }

    const renderForms = () => {
        switch (ozgelecekForm.formIndex) {
            case 1:
                return <OzgelecekFieldsForm />;

            case 2:
                return <OzgelecekFieldAndMotivationTestForm field={selectedField ?? ozgelecekForm.values.field} questionType="area" title="Alan Testi" endTimeDialog={endTimeDialog} setEndTimeDialog={setEndTimeDialog} />;

            case 3:
                return <OzgelecekFieldAndMotivationTestForm field={ozgelecekForm.values.field} questionType="motivation" title="Motivasyon Testi" endTimeDialog={endTimeDialog} setEndTimeDialog={setEndTimeDialog} />;

            case 4:
                return <OzgelecekTestForm ozgelecekTest={ozgelecekTest} setOzgelecekTest={setOzgelecekTest} />;

            case 5:
                return <OzgelecekSelectReferanceForm isLoading={isLoading || loading} onSubmit={handleCreateOzgelecek} />;

            case 6:
                return <OzgelecekCreateUpdateFinishSection isLoading={isLoading || loading} onSubmit={handleCreateOzgelecek} />;

            default:
                return <OzgelecekFieldsForm />;
        }
    }

    // console.log({ ozgelecekTest, ozgelecekForm });
    return (
        <DashboardLayoutMobile>

            <Stack direction="row" gap={1} alignItems="center" mb={5} justifyContent="space-between">
                <Stack direction="row" gap={1} alignItems="center" >
                    <IconButton onClick={() => navigate(routes.candidate.dashboard.ozgeleceklerimMobile)}>
                        <ArrowBackIosNewRoundedIcon />
                    </IconButton>

                    <Typography variant='h1'>
                        Özgelecek Oluştur
                    </Typography>
                </Stack>

                {/* <Button variant='contained' color="error" onClick={() => dispatch(resetCandidateCreateOzgelecekForm({}))}>
                    Reset Test
                </Button> */}
            </Stack>

            <OzgelecekProgressBar totalstep={5} currentstep={ozgelecekForm.formIndex} />

            <Box>
                {renderForms()}
            </Box>

            <Dialog
                open={!!endTimeDialog}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => {
                    setEndTimeDialog(null)
                    window.location.pathname = "/ozgeleceklerim"
                }}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Süreniz sona erdi</DialogTitle>

                <DialogContent>
                    <Typography>
                        Süreniz sona erdi, lütfen testi tamamlamak için tekrar deneyin.
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => {
                        setEndTimeDialog(null)
                        window.location.pathname = "/ozgeleceklerim"
                    }}>
                        Tamam
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={acilisDialog}
                onClose={() => setAcilisDialog(false)}
            >
                <DialogTitle style={{ textAlign: "center" }}>
                    Özgelecek Vizyonunu Paylaşan Değerli Adayımız,
                </DialogTitle>
                <DialogContent sx={{
                    display: "flex", flexDirection: "column", alignItems: "center",
                    width: { xs: '100%', sm: '500px' },
                }}>
                    <img src={CareerGif} alt="Welcome" style={{ width: "250px", height: "auto" }} />
                    <Typography variant="body1" textAlign={"center"}>
                        Sorularını yanıtlarken seni “gerçekten yansıtan” yanıtlar vermen gerektiğini,<br /><br />
                        Hayallerine uygun iş fırsatını yakalayabilmen için vereceğin yanıtların tutarlı bir şekilde seni yansıtması gerektiğini unutma!<br /><br />
                        Eğer yeterli puanı alamayıp iş başvurusu gerçekleştiremezsen, lütfen denemekten vazgeçme!<br /><br />
                        Özgelecek, herkesin istediği işi yapmasındaki yegane yol olarak hep burda olacak!


                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            setAcilisDialog(false)
                        }}
                    >
                        Tamam
                    </Button>
                </DialogActions>
            </Dialog>

        </DashboardLayoutMobile>
    )
}