import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded';
import { AddCircle, Flag, RemoveCircle, WarningRounded } from "@mui/icons-material";
import { Avatar, Button, Chip, Dialog, DialogContent, Box, Stack, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography, DialogTitle, DialogActions, Skeleton } from "@mui/material";

import {
    ICompanyApplicationsItem,
    ICompanyApplicationsResponse,
    useLazyGetCompanyCandidateQuery,
    useCompanyViewApplicationMutation,
    useGetCompanyCustomApplicationListsQuery,
    useLazyGetCompanyApplicationDetailsQuery,
    useCompanyMakeApplicationSuitableMutation,
    useCompanyMakeApplicationUnsuitableMutation,
    useCompanyMakeApplicationBlackListedMutation,
    useCompanyAddCustomApplicationListItemsMutation,
    useCompanyRemoveCustomApplicationListItemsMutation,
} from "context";

import { enumFunctions } from "utils";
import { ApplicationStatusEnums, OzgelecekFieldEnum, getNameOfEnum } from "types";
import { CompanyCandidateCard, CustomTableHeader, ICustomTableHeadCell, MuiTransition } from "components";





interface CompanyApplicationsTableProps {
    data: ICompanyApplicationsResponse | null,
    pagination: any,
    setPagination: (e?: any) => void
    setCustomListDrawer: (e?: any) => void
}
export const CompanyApplicationsTable = ({ data, pagination, setPagination, setCustomListDrawer }: CompanyApplicationsTableProps) => {

    const headCells: ICustomTableHeadCell[] = [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: '#',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Ad Soyad',
        },
        // {
        //     id: 'email',
        //     numeric: false,
        //     disablePadding: false,
        //     label: 'Email',
        // },
        {
            id: 'jobTitle',
            numeric: false,
            disablePadding: false,
            label: 'Başvurulan İlan',
        },
        {
            id: 'field',
            numeric: false,
            disablePadding: false,
            label: 'Alan',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Durumu',
        },
        {
            id: 'actions',
            numeric: false,
            disablePadding: false,
            label: '',
        },
    ];



    const handleChangePage = (event: unknown, newPage: number) => {
        setPagination((prevState: any) => ({ ...prevState, page: newPage }))
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPagination((prevState: any) => ({ ...prevState, pageSize: Number(event?.target?.value) }))
    };


    return (
        < >
            <TableContainer>
                <Table
                    aria-labelledby="tableTitle"
                    size='medium'
                >

                    <CustomTableHeader headCells={headCells} rowCount={data?.data?.length ?? 0} />

                    <TableBody>
                        {
                            data?.count ? (
                                data?.data?.map((row: ICompanyApplicationsItem, index: number) => {

                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            key={row?._id ?? index}
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            sx={{ cursor: 'pointer' }}
                                        >

                                            <TableCell align='center'>
                                                {index + 1}
                                            </TableCell>


                                            <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <Avatar
                                                        sx={{ bgcolor: "primary.main", border: "1px solid", borderColor: "primary" }}
                                                        src={row?.candidate?.personalInformation?.profileImageUrl}
                                                    >
                                                        {row?.candidate?.personalInformation?.name?.slice(0, 1)}
                                                    </Avatar>
                                                    <Typography>
                                                        {row?.candidate?.personalInformation?.name} &nbsp;
                                                        {row?.candidate?.personalInformation?.surname}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>

                                            <TableCell align="left">{row?.advertisement?.title}</TableCell>
                                            <TableCell align="left">{getNameOfEnum(OzgelecekFieldEnum, row?.advertisement?.field)}</TableCell>

                                            <TableCell align="left">
                                                <Chip
                                                    label={enumFunctions.findAndDisplayName(ApplicationStatusEnums, row?.status)}
                                                    color={
                                                        row?.status === "pending" ? "warning"
                                                            : row?.status === "pendingAsBlacklisted" ? "default"
                                                                : row?.status === "suitable" ? "primary"
                                                                    : row?.status === "unsuitable" ? "error"
                                                                        : row?.status === "viewed" ? "info"
                                                                            : "secondary"
                                                    }
                                                    variant="outlined"
                                                    style={{ borderRadius: 100 }}
                                                />
                                            </TableCell>

                                            <TableCell align="left">
                                                <TableItemActions application={row} onOpenNewList={setCustomListDrawer} />
                                            </TableCell>



                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        <Stack
                                            justifyContent="center"
                                            alignItems="center"
                                            py={5}
                                            fontSize="16px"
                                        >
                                            Henüz başvuru bulunmamaktadır.
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            )
                        }

                    </TableBody>


                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 20]} // içindeki numaraları dropdown olarak gösteriyor
                count={data?.count ?? 0} // toplam eleman sayısı
                rowsPerPage={pagination.pageSize ?? 1} // sayfadaki eleman sayısı
                page={(pagination.page ?? 1) - 1} // kaçıncı sayfa olduğunu gösteriyor, 0 ilk sayfa

                labelRowsPerPage="Sayfa başına gösterim"
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

        </>
    )
}



interface ITableItemActionsProps {
    application: any,
    onOpenNewList: (e?: any) => void
}

const TableItemActions = ({ application, onOpenNewList }: ITableItemActionsProps) => {

    const [blackListDialog, setBlackListDialog] = useState(false)
    const [getCompanyCandidate] = useLazyGetCompanyCandidateQuery()
    const [tableActionsDialog, setTableActionsDialog] = useState(false)
    const [candidateDetail, setCandidateDetail] = useState<any | null>(null)
    const [selectedAdvertisement, setSelectedAdvertisement] = useState<any | null>(null)
    const [fetchApplicationDetail, { data: selectedApplication }] = useLazyGetCompanyApplicationDetailsQuery()


    useEffect(() => {
        if (tableActionsDialog) {
            fetchApplicationDetail({ applicationId: application?._id })
        }
    }, [application?._id, fetchApplicationDetail, tableActionsDialog])



    const { data: customApplicationList } = useGetCompanyCustomApplicationListsQuery({})

    const [addItemToList] = useCompanyAddCustomApplicationListItemsMutation()
    const [removeItemFromList] = useCompanyRemoveCustomApplicationListItemsMutation()

    const handleAddItemToList = async (listId: string) => {

        try {
            // if (application?.status !== "pending") return

            const response: any = await addItemToList({
                customApplicationListId: listId,
                customApplicationListItems: [
                    {
                        "application": {
                            "_id": application?._id
                        }
                    }
                ]
            })

            if (response?.data) {
                enqueueSnackbar("Listeye eklendi!")
            } else {
                enqueueSnackbar("Listeden çıkarıldı", { variant: "warning" })
            }
        } catch (error) {
            console.log({ error });
            enqueueSnackbar("birşeyler yanlış gitti!", { variant: "error" })
        }

    }

    const handleRemoveItemFromList = async (listId: string) => {

        try {
            // if (application?.status !== "pending") return

            const response: any = await removeItemFromList({
                customApplicationListId: listId ?? "",
                customApplicationListItems: [
                    {
                        "_id": application?._id
                    }
                ]
            })

            console.log({ response });
            if (response?.data?.data?.company_removeCustomApplicationListItems?.success) {
                enqueueSnackbar("Listeden çıkarıldı!")
            } else {
                enqueueSnackbar("Listeden çıkarılamadı!", { variant: "warning" })
            }
        } catch (error) {
            console.log({ error });
            enqueueSnackbar("birşeyler yanlış gitti!", { variant: "error" })
        }

    }


    const [makeView] = useCompanyViewApplicationMutation()
    const [makeSuitable] = useCompanyMakeApplicationSuitableMutation()
    const [makeUnsuitable] = useCompanyMakeApplicationUnsuitableMutation()
    const [makeBlackListed] = useCompanyMakeApplicationBlackListedMutation()


    const handleMakeView = async () => {

        try {
            if (application?.status !== "pending") return

            const response: any = await makeView({ applicationId: application?._id ?? "" })

            if (response?.data) {
                // enqueueSnackbar("Başvuru görüldü!")
            } else {
                enqueueSnackbar("Birşeyler yanlış gitti!", { variant: "warning" })
            }
        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: "error" })
        }

    }


    const handleMakeSuitable = async () => {
        try {
            const response: any = await makeSuitable({ applicationId: application?._id ?? "" })

            if (response?.data) {
                enqueueSnackbar("Başvuru uygun  yapıldı!")
            } else {
                enqueueSnackbar("Başvuru uygun  yapıldı!", { variant: "warning" })
            }
        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: "error" })
        }
    }


    const handleMakeUnsuitable = async () => {
        try {
            const response: any = await makeUnsuitable({ applicationId: application?._id ?? "" })

            if (response?.data) {
                enqueueSnackbar("Başvuru uygun değil yapıldı!")
            } else {
                enqueueSnackbar("Başvuru uygun değil yapılamadı!", { variant: "warning" })
            }
        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: "error" })
        }
    }


    const handleMakeBlackListed = async () => {
        try {
            const response: any = await makeBlackListed({ applicationId: application?._id ?? "" })

            if (response?.data) {
                enqueueSnackbar("Başvuru kara listeye alındı!")
            } else {
                enqueueSnackbar("Birşeyler yanlış gitti!", { variant: "warning" })
            }
        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti!", { variant: "error" })
        }
    }


    const handleCandidateDetail = async (id: string, advertisement: any) => {
        const candidate = await getCompanyCandidate({ id: id, advertisementId: advertisement?._id })
        setCandidateDetail(candidate?.data?.data?.company_candidate ?? null)
        setSelectedAdvertisement(advertisement)
    }



    return (
        <Stack direction="row" alignItems="center" justifyContent="end" spacing={1}>

            <Stack direction={"column"} spacing={1}>
                <Button
                    sx={{ width: 95 }}
                    size="small"
                    variant="outlined"
                    onClick={async () => {
                        await handleMakeView()
                        await handleCandidateDetail(application?.candidate?._id, application?.advertisement)
                    }}
                >
                    <VisibilityRoundedIcon sx={{ width: 15, mr: 1 }} />
                    Detay
                </Button>

                <Button
                    sx={{ width: 95 }}
                    size="small"
                    variant="outlined"
                    onClick={(e) => {
                        handleMakeView()
                        setTableActionsDialog(true)
                    }}
                >
                    <PlaylistAddRoundedIcon sx={{ width: 15, mr: 1 }} />
                    Liste
                </Button>
            </Stack>

            <Stack direction={"column"} spacing={1}>
                <Button
                    sx={{ width: 95 }}
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={() => {
                        handleMakeSuitable()
                    }}
                >
                    Uygun
                </Button>

                <Button
                    sx={{ width: 95 }}
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => {
                        handleMakeUnsuitable()
                    }}
                >
                    Uygun Değil
                </Button>
            </Stack>


            <Dialog
                fullWidth
                open={!!tableActionsDialog}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setTableActionsDialog(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    {
                        selectedApplication ? (
                            <Stack
                                spacing={3}
                                py={3}
                                minWidth={{ xs: "100%", sm: '400px' }}
                            >

                                <Typography variant='h3'>
                                    Listeye ekle
                                </Typography>

                                <Stack mb={2} spacing={2}>
                                    {
                                        customApplicationList?.data?.map((item: any, index: number) => {

                                            const selected = selectedApplication?.customApplicationLists?.find((app: any) => app?._id === item?._id);

                                            return (
                                                <Stack key={index} direction="row" spacing={2}>
                                                    <Typography
                                                        sx={{
                                                            border: '1px solid #ccc',
                                                            flex: 1,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            px: 3
                                                        }}
                                                        fontSize={"small"}
                                                        fontWeight={"bold"}
                                                    >
                                                        {item?.name}
                                                    </Typography>

                                                    {selected ? (
                                                        <Button
                                                            onClick={() => handleRemoveItemFromList(item?._id)}
                                                            variant="outlined"
                                                            color="error"
                                                            sx={{ width: 80 }}
                                                        >
                                                            <RemoveCircle sx={{ mr: 1 }} />
                                                            Çıkar
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            onClick={() => handleAddItemToList(item?._id)}
                                                            variant="outlined"
                                                            color="success"
                                                            sx={{ width: 80 }}
                                                        >
                                                            <AddCircle sx={{ mr: 1 }} />
                                                            Ekle
                                                        </Button>
                                                    )


                                                    }
                                                </Stack>
                                            )
                                        })
                                    }

                                    <Button
                                        onClick={() => {
                                            setTableActionsDialog(false)
                                            onOpenNewList()
                                        }}
                                    >
                                        Yeni Liste Oluştur
                                    </Button>
                                </Stack>

                                <Box sx={{ borderTop: "1px solid #ccc", pt: 1, borderBottom: "1px solid #ccc", pb: 1 }}>
                                    <Button
                                        variant='contained'
                                        size="small"
                                        fullWidth
                                        color="inherit"
                                        onClick={() => {
                                            setBlackListDialog(true)
                                            setTableActionsDialog(false)
                                        }}
                                    >
                                        Kara Listeye Al
                                        <Flag sx={{ ml: 1 }} />
                                    </Button>
                                </Box>

                                <Stack direction="row" justifyContent="end">
                                    <Button
                                        onClick={() => setTableActionsDialog(false)}
                                        variant='outlined'
                                        color="primary"
                                    >
                                        Kapat
                                    </Button>
                                </Stack>

                            </Stack>
                        ) : (
                            <Stack
                                spacing={3}
                                py={3}
                                minWidth={{ xs: "100%", sm: '400px' }}
                            >

                                <Skeleton variant="text" />

                                <Stack mb={2} spacing={2}>
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                </Stack>
                            </Stack>
                        )
                    }
                </DialogContent>
            </Dialog>


            <Dialog
                fullWidth
                open={blackListDialog}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setBlackListDialog(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    Dikkat <WarningRounded sx={{ mb: -0.6 }} />
                </DialogTitle>
                <DialogContent>
                    <Stack
                        spacing={3}
                        py={3}
                        minWidth={{ xs: "100%", sm: '400px' }}
                    >
                        <Typography textAlign={"justify"}>
                            Bir kullanıcıyı kara listeye almak, bundan sonra hiçbir ilanınızı göremeyeceği ve başvuru yapamayacağı anlamına gelir! <br /><br />Kara listeye almak istediğinize emin misiniz?
                        </Typography>

                    </Stack>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={() => setBlackListDialog(false)}
                        variant='outlined'
                        color="inherit"
                    >
                        Vazgeç
                    </Button>
                    <Button
                        onClick={() => {
                            handleMakeBlackListed()
                            setBlackListDialog(false)
                        }}
                        variant='contained'
                        color="inherit"
                    >
                        Kara Listeye Al
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog
                fullWidth
                open={!!candidateDetail}
                onClose={() => {
                    setCandidateDetail(null)
                }}
            >
                <DialogTitle id="simple-dialog-title">Aday Detayı</DialogTitle>
                <DialogContent sx={{ width: { xs: '100%', sm: "600px" } }}>
                    <CompanyCandidateCard candidateDetail={candidateDetail} selectedAdvertisement={selectedAdvertisement} />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setCandidateDetail(null)
                        }}
                        color="primary"
                        variant='outlined'
                    >
                        Kapat
                    </Button>
                </DialogActions>
            </Dialog>

        </Stack>
    )
}