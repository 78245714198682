import axios from 'axios';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Typography, Link, Stack, Box, InputLabel } from '@mui/material';

import { routes } from 'routes';
import { PasswordIcon } from 'assets';
import { RHFTextField, Iconify, FormProvider } from 'components';


// ----------------------------------------------------------------------
const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().required('Gerekli').email('Geçerli bir email adresi giriniz!'),
});



export const ForgotPasswordContainer = () => {

  const navigate = useNavigate()

  const methods = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
    defaultValues: {
      email: '',
    },
  });

  const { handleSubmit, formState: { isSubmitting } } = methods;

  const onSubmit = handleSubmit(async (data) => {

    try {

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/auth/auth/requestresetpassword`,
        headers: {
          'x-auth-token': '',
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({ email: data.email })
      };

      const response = await axios.request(config)

      console.log(response.data)


      if (response?.data?.isSuccess) {
        sessionStorage.setItem('email-recovery', data.email);
        enqueueSnackbar("Başarılı bir şekilde gönderilmiştir!")
        setTimeout(() => {
          navigate(routes.auth.forgotPasswordSuccessfull)
        }, 1000)
      } else {
        enqueueSnackbar("Kod gönderilemedi!", {
          variant: "info"
        })
      }

    } catch (error: any) {
      console.error(error)

      if (error?.response?.data?.message === "EmailIsNotRegistered") {
        enqueueSnackbar("Yanlış email girdiniz!", {
          variant: 'error'
        })
      }
    }
  });

  const renderForm = (
    <Stack spacing={3} alignItems="center">

      <Box sx={{ width: '100%', textAlign: 'start' }}>
        <InputLabel>Email</InputLabel>
        <RHFTextField name="email" placeholder="Emailinizi giriniz" />
      </Box>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Gönder
      </LoadingButton>

      <Link
        component={RouterLink}
        to={routes.auth.login}
        color="inherit"
        variant="subtitle2"
        sx={{
          alignItems: 'center',
          display: 'inline-flex',
        }}
      >
        <Iconify icon="eva:arrow-ios-back-fill" width={16} />
        Geri dön
      </Link>
    </Stack>
  );

  const renderHead = (
    <>
      <PasswordIcon sx={{ height: 96 }} />

      <Stack spacing={1} sx={{ my: 5 }}>
        <Typography variant="h3">Şifreni mi Unuttun?</Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Lütfen hesabınıza ait email adresinizi giriniz. Emailinize gelen link ile şifrenizi sıfırlayabilirsiniz.
        </Typography>
      </Stack>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {renderHead}

      {renderForm}
    </FormProvider>
  );
}
