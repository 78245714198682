import { useState, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { Box, Button, Card, Dialog, DialogContent, Divider, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, OutlinedInput, Select, Stack, SxProps, Typography } from '@mui/material'

import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useAppDispatch, useAppSelector, setAdvertisementFormIndex } from 'context';
import { enumFunctions } from 'utils'
import { LanguageForm, SkillsForm } from 'sections'
import { ICreateAdvertisementForm } from 'lib/form-validation'
import { HigherEducationDegreesList, ILanguage, LanguageEnums, LanguageLevelEnum } from 'types'
import { FormikCheckbox, FormikTextField, Iconify, MuiTransition, FormikAutoComplete, FormikSelect } from 'components'



type FormDialog = {
    formType: "header" | "about" | "personalInformation" | "additionalInformation" | "certificate" | "language" | "participatedProject" | "scholarship" | "programmingLanguage" | "higherEducation",
    formValue?: any,
    itemIndex?: number
}


export const CreateAdvertisementOnboardingThird = () => {

    const dispatch = useAppDispatch()
    const [formDialog, setFormDialog] = useState<null | FormDialog>(null)
    const { setFieldValue, values, handleSubmit } = useFormikContext<ICreateAdvertisementForm>()
    const { advertisementFormIndex, advertisementId } = useAppSelector((state) => state.companyCreateAdvertisement)
    const [statusReady, setStatusReady] = useState(false)

    const handleNextStep = () => {
        setFieldValue("advertisementId", advertisementId);
        if ((values?.status === "pendingApproval")) setFieldValue("status", "pending")
        else if (values?.status === "approved") setFieldValue("status", "pendingApproval")
        else if (values?.status === "onboardingForthCompleted") setFieldValue("status", "onboardingForthCompleted")
        else setFieldValue("status", "onboardingThirdCompleted")

        setStatusReady(true)
    }
    const handleBackStep = () => {
        dispatch(setAdvertisementFormIndex(advertisementFormIndex - 1))
    }

    useEffect(() => {
        if (statusReady) {
            handleSubmit();
        }
    }, [statusReady])

    if (!values?.field) {
        return null
    } else return (
        <Card sx={{ p: 3 }}>

            <Grid container spacing={3}>

                {/* <Grid item xs={12}>
                    <Typography variant='h3'>
                        Onboarding Third
                    </Typography>
                </Grid> */}

                <Grid item xs={12} sm={6}>
                    {/* <FormikTextField name='requiredAgeInterval' label="Adayın Yaş Aralığı" type='number' /> */}
                    <FormikAutoComplete
                        name="requiredAgeInterval"
                        label="Adayın Yaş Aralığı"
                        list={[
                            { value: 1, name: "16-18" },
                            { value: 2, name: "18-25" },
                            { value: 3, name: "25-30" },
                            { value: 4, name: "30-35" },
                            { value: 5, name: "35-40" },
                            { value: 6, name: "40+" },
                        ]}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormikTextField label="Sürücü Belgesi" name='requiredDriverLicense' upperCase />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormikSelect
                        name='militaryServiceRequired'
                        label="Askerlik Durumu"
                        list={[
                            { value: true, name: "Tamamlamış Olsun" },
                            { value: false, name: "Önemli Değil" }
                        ]}
                    />
                </Grid>


                <Grid item xs={12} sm={6}>
                    {/* <FormControl fullWidth>
                        <InputLabel id="demo-multiple-name-label">Eğitim seviyesi</InputLabel>
                        <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            multiple
                            label="Education Level"
                            value={values?..requiredEducationLevel}
                            onChange={(e) => {
                                setFieldValue("requiredEducationLevel", e?.target?.value)
                            }}
                            input={<OutlinedInput label="Education Level" />}

                        >
                            {
                                HigherEducationDegreesList?.map((item: any) => {

                                    return (
                                        <MenuItem
                                            key={item?.name}
                                            value={item?.value}
                                        // style={getStyles(name, personName, theme)}
                                        >
                                            {item?.name}
                                        </MenuItem>
                                    )

                                })

                            }
                        </Select>
                    </FormControl> */}
                    <FormikAutoComplete name="requiredEducationLevel" label="Eğitim Seviyesi" list={HigherEducationDegreesList} />
                </Grid>


                <Grid item xs={12} >
                    <Divider />
                    <Stack direction="column" gap={1} position={"relative"} >
                        <Typography fontWeight={"bold"} fontSize={"small"} color={"#666"} sx={{ p: 2 }}>
                            Yetenenekler ve Beceriler
                        </Typography>
                        <SkillsForm
                            list={values?.requiredSkills || []}
                            onSubmit={(e: any) => setFieldValue("requiredSkills", [...values.requiredSkills ?? [], e])}
                            onDeleteItem={(e) => {
                                const newList = values.requiredSkills?.filter((item, index) => index !== e)
                                setFieldValue("requiredSkills", newList)
                            }}
                        />
                    </Stack>

                </Grid>

                <Grid item xs={12} >
                    <Divider />
                    <Stack direction="column" gap={1} position={"relative"} mb={2} >
                        <Typography fontWeight={"bold"} fontSize={"small"} color={"#666"} sx={{ p: 2 }}>
                            Bilmesi Gereken Diller
                        </Typography>

                        <Grid container spacing={2} gap={2} px={2} py={1} pl={4} >
                            {
                                values.requiredLanguages?.map((language: ILanguage, index: number) => {
                                    return (
                                        <Box
                                            key={index}
                                            sx={{
                                                width: 130,
                                                height: 50,
                                                display: 'flex',
                                                pl: 2,
                                                pr: 1,
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                border: '1px solid #ddd',
                                                transitionDuration: '200ms',
                                                '&:hover': {
                                                    boxShadow: '0px 0px 10px 0px #9C8FF166',
                                                },
                                            }}
                                        >
                                            <Typography fontSize={"small"} fontWeight={"bold"}>{language.name}</Typography>
                                            <IconButton onClick={() => {
                                                const newList = values.requiredLanguages?.filter((item, i) => i !== index)
                                                setFieldValue("requiredLanguages", newList)
                                            }}>
                                                <Iconify icon={"mdi:delete"} />
                                            </IconButton>
                                        </Box>
                                    )
                                })
                            }


                            <AddNewItem onClick={() => setFormDialog({ formType: "language" })} />

                        </Grid>
                    </Stack>

                    <Divider />
                </Grid>



                <Grid item xs={12}>
                    <Stack direction="row" spacing={2} justifyContent="space-between">
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={handleBackStep}
                            sx={{
                                width: 150,
                            }}
                        >
                            Geri
                        </Button>

                        <Button
                            color="primary"
                            variant='contained'
                            onClick={handleNextStep}
                            sx={{
                                width: 150,
                            }}
                        >
                            İleri
                        </Button>
                    </Stack>
                </Grid>


            </Grid>

            <Dialog
                open={!!formDialog}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setFormDialog(null)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent sx={{ p: 0 }}>
                    {
                        formDialog?.formType === "language" && (
                            <LanguageForm
                                initialValues={formDialog.formValue}
                                onSubmit={(language) => {
                                    if (formDialog.itemIndex || formDialog.itemIndex === 0) {
                                        const newList = values.requiredLanguages ? [...values.requiredLanguages] : []
                                        newList[formDialog.itemIndex] = language

                                        console.log({ language, formDialog });
                                        setFieldValue(
                                            "requiredLanguages",
                                            [...newList]
                                        )
                                    } else {
                                        setFieldValue(
                                            "requiredLanguages",
                                            values.requiredLanguages ? [...values.requiredLanguages, language] : [language]
                                        )
                                    }
                                    setFormDialog(null)

                                }}
                            />
                        )
                    }
                </DialogContent>
            </Dialog>

        </Card>
    )
}



const AddNewItem = ({ onClick }: { onClick: () => void }) => {

    return (
        <Box
            onClick={onClick}
            sx={{
                border: '1px dashed #ccc',
                cursor: 'pointer',
                width: 130,
                height: 50,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transitionDuration: '200ms',

                '&:hover': {
                    transform: 'scale(1.02)'
                },
            }}
        >
            <AddIcon />
        </Box>
    )
}