import { LoadingButton } from '@mui/lab'
import { Grid, IconButton, Typography } from '@mui/material'
import { FormikTextField } from 'components'
import { Formik } from 'formik'
import React, { useState } from 'react'

import * as yup from 'yup'

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from 'axios'
import { enqueueSnackbar } from 'notistack'
import { getAccessToken } from 'utils'


const passwordCheck = yup.string().min(8, "En az 8 karakter içermeli")
    .matches(/^(?=.*[a-z])/, 'Şifreniz küçük harf, büyük harf, sayı ve sembol içermelidir.')
    .matches(/^(?=.*[A-Z])/, 'Şifreniz küçük harf, büyük harf, sayı ve sembol içermelidir.')
    .matches(/^(?=.*[0-9])/, 'Şifreniz küçük harf, büyük harf, sayı ve sembol içermelidir.')
    .matches(/^(?=.*[!@#%&?.,+%-_])/, 'Şifreniz küçük harf, büyük harf, sayı ve sembol içermelidir.')
    .matches(/(?=.*[!'^+%.,/()&=?\-_*])/, 'Şifreniz küçük harf, büyük harf, sayı ve sembol içermelidir.')
    .required('Gerekli')

const validationSchema = yup.object().shape({
    currentPassword: passwordCheck,
    newPassword: passwordCheck,
    newPasswordConfirm: yup.string().oneOf([yup.ref('newPassword'), "null"], 'Yeni şifre ile aynı olmalı.').required("Gerekli"),
})



export const ChangePasswordSection = () => {

    const [visibility, setVisibility] = useState<Array<string>>([])

    const initialValues = {
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
    }

    const handleChangeVisibility = (value: string) => {
        const isFind = visibility?.find((item) => item === value)
        if (isFind) {
            setVisibility(visibility?.filter((item) => item !== value))
        } else {
            setVisibility([...visibility, value])
        }

    }

    const handleChangePassword = async (values: any, setErrors: any, resetForm: any) => {

        if (values?.currentPassword === values?.newPassword) {
            setErrors({ newPassword: "Güncel şifreden farklı olmalı!" })
            return
        }
        try {
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: process.env.REACT_APP_BASE_URL + '/auth/auth/resetpassword',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getAccessToken(),
                },
                data: JSON.stringify({
                    oldPassword: values?.currentPassword,
                    password: values?.newPassword
                })
            };

            const response = await axios.request(config)

            if (response.status === 200) {
                enqueueSnackbar("Şifre başarılı bir şekilde değiştirilmiştir!")
                resetForm()
            } else {
                enqueueSnackbar("Şifre değiştirilemedi!", { variant: 'warning' })
            }


        } catch (error: any) {
            console.log({ error });

            if (error?.response?.data?.message === "WrongPassword") {
                enqueueSnackbar("Yanlış şifre!", { variant: 'error' })
            } else {
                enqueueSnackbar("Birşeyler yanlış gitti!", { variant: 'error' })
            }
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setErrors, resetForm }) => {
                console.log({ values });
                handleChangePassword(values, setErrors, resetForm)
            }}
        >
            {
                ({ errors, handleSubmit, isValid }) => {


                    return (
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <Typography variant='h3'>
                                    Şifre Değiştirme
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormikTextField
                                    name="currentPassword"
                                    label="Güncel şifre*"
                                    type={visibility.includes("currentPassword") ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton onClick={() => handleChangeVisibility("currentPassword")}>
                                                {
                                                    visibility.includes("currentPassword") ? <VisibilityIcon /> : <VisibilityOffIcon />
                                                }
                                            </IconButton>
                                        )
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} sx={{ display: { xs: 'none', sm: 'initial' } }}></Grid>

                            <Grid item xs={12} sm={6}>
                                <FormikTextField
                                    name="newPassword"
                                    label="Yeni şifre*"
                                    type={visibility.includes("newPassword") ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton onClick={() => handleChangeVisibility("newPassword")}>
                                                {
                                                    visibility.includes("newPassword") ? <VisibilityIcon /> : <VisibilityOffIcon />
                                                }
                                            </IconButton>
                                        )
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} sx={{ display: { xs: 'none', sm: 'initial' } }}></Grid>

                            <Grid item xs={12} sm={6}>
                                <FormikTextField
                                    name="newPasswordConfirm"
                                    label="Yeni şifre onayla*"

                                    type={visibility.includes("newPasswordConfirm") ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton onClick={() => handleChangeVisibility("newPasswordConfirm")}>
                                                {
                                                    visibility.includes("newPasswordConfirm") ? <VisibilityIcon /> : <VisibilityOffIcon />
                                                }
                                            </IconButton>
                                        )
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} display="flex" justifyContent="start">
                                <LoadingButton
                                    variant='contained'
                                    color='primary'
                                    onClick={() => handleSubmit()}
                                    disabled={!isValid}
                                >
                                    Değiştir
                                </LoadingButton>
                            </Grid>


                        </Grid>
                    )
                }
            }
        </Formik>
    )
}
