import moment from "moment";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { Form, Formik, useFormikContext } from 'formik';
import { Dialog, DialogContent, IconButton, Menu, MenuItem, SxProps, Typography, Stack, Box, Grid, Skeleton } from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import {
  SexEnum,
  ILanguage,
  IScholarship,
  ICertificate,
  getNameOfEnum,
  IHigherEducation,
  LanguageLevelEnum,
  IParticipatedProjects,
  ComputerKnowledgeLevelEnum,
  ICandidateInformationInput,
} from "types";

import { DashboardLayoutMobile } from "layouts";
import { Iconify, MuiTransition } from "components";
import { CandidateAdvertisementDetailImage, User } from 'assets'
import { findImageContentType, saveImageAndGetUrl } from "utils";
import { useCandidateUpdateCandidateFormValidation } from "lib/form-validation";
import {
  useAppDispatch,
  getOnboardingStatus,
  useGetCandidateInformationQuery,
  useCandidateUpdateCandidateMutation,
  useLazyGetUserProfileImagePresignedUrlQuery,
} from "context"

import {
  HobbiesForm,
  LanguageForm,
  HighSchoolsForm,
  CertificateForm,
  ScholarshipsForm,
  HigherEducationForm,
  ParticipatedProjectForm,
  CandidateAccountAboutForm,
  CandidateAccountHeaderForm,
  CandidateAccountPersonalInformationForm,
  CandidateAccountAdditionalInformationForm,
} from "sections";



type FormDialog = {
  formType: "header" | "about" | "personalInformation" | "additionalInformation" | "certificate" | "language" | "participatedProject" | "scholarship" | "programmingLanguage" | "higherEducation",
  formValue?: any,
  itemIndex?: number
}


export const CandidateAccountPageMobile = () => {

  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [profileImage, setProfileImage] = useState<any>(null)
  const { data, isLoading } = useGetCandidateInformationQuery({})
  const [formDialog, setFormDialog] = useState<null | FormDialog>(null)
  const [getPresignUrl] = useLazyGetUserProfileImagePresignedUrlQuery()
  const [updateCandidate, { isSuccess }] = useCandidateUpdateCandidateMutation()


  const { validationSchema, initialValues } = useCandidateUpdateCandidateFormValidation({
    values: {
      ...data,
      ...data?.contact,
    }
  })


  useEffect(() => {
    setFormDialog(null)
  }, [isSuccess])


  const handleSubmit = async (values: ICandidateInformationInput) => {
    setLoading(true)
    try {
      let url;

      if (profileImage) {
        const contentType = findImageContentType(profileImage)
        const presigneUrl = await getPresignUrl({ contentType: contentType })
        console.log(presigneUrl, "presigneUrl")
        const isSaved = await saveImageAndGetUrl({
          file: profileImage,
          presignUrl: presigneUrl?.data?.profileImage?.presignedUploadUrl ?? ""
        })
        url = presigneUrl?.data?.profileImage?.url
      }

      const response: any = await updateCandidate({
        variables: {
          ...values,
          personalInformation: {
            ...values.personalInformation,
            profileImageUrl: url ?? values.personalInformation?.profileImageUrl
          }
        }
      })

      if (response?.data?.candidate_updateCandidate?._id) {
        enqueueSnackbar("Başarılı bir şekilde güncellendi!")
        dispatch(getOnboardingStatus({ userType: "candidate" })).then(res => {
          console.log({ res }, "onboarding status");
          setLoading(false)
          setProfileImage(null)
        })
      } else {
        enqueueSnackbar("Güncelleme yapılamadı!", { variant: "error" })
        setLoading(false)
        setProfileImage(null)
      }

    } catch (error) {
      console.log({ error });
      enqueueSnackbar("Güncelleme yapılamadı!", { variant: "error" })
      setLoading(false)
      setProfileImage(null)
    }

  }


  const handleSubmitWhenDeletOrUpdateItem = async (newValues: ICandidateInformationInput) => {
    setLoading(true)
    try {
      const response: any = await updateCandidate({
        variables: newValues
      })


      console.log({ response });

      if (response?.data?.candidate_updateCandidate?._id) {
        enqueueSnackbar("Başarılı bir şekilde güncellendi!")
        setLoading(false)

      } else {
        enqueueSnackbar("Güncelleme yapılamadı!", { variant: "error" })
        setLoading(false)
      }

    } catch (error) {
      console.log({ error });
      enqueueSnackbar("Güncelleme yapılamadı!", { variant: "error" })
      setLoading(false)
    }
  }


  return (
    <DashboardLayoutMobile>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {
          ({ values, setFieldValue }) => {

            return (
              <Form>
                {
                  isLoading ? (
                    <Stack
                      flexDirection={{ xs: "column", md: "row" }}
                      gap={3}
                      sx={{ my: 5, }}
                    >

                      <Stack
                        direction="column"
                        gap={1}
                        flex={1}
                      >

                        {/* *********************** Profile Header ********************** */}
                        <Stack direction="column" gap={1} border={"1px solid #ddd"} position={"relative"} >

                          {/* <EditButton onClick={() => setFormDialog({ formType: "header" })} /> */}
                          <Box
                            sx={{
                              backgroundImage: `url(${CandidateAdvertisementDetailImage})`,
                              height: '100px',
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat',
                            }}
                          />

                          <Stack direction={"row"}>
                            <Skeleton variant="circular" width={150} height={150} />

                            <Stack direction={"column"} sx={{ ml: 3, gap: 1 }}>
                              <Skeleton variant="text" />
                              <Skeleton variant="text" />
                            </Stack>

                          </Stack>
                        </Stack>


                        {/* ***************** Hakkımda ******************** */}
                        <Stack direction="column" gap={1} border={"1px solid #ddd"} position={"relative"} >
                          <Typography variant="h6" sx={{ p: 2 }}>
                            Hakkımda
                          </Typography>

                          <EditButton onClick={() => setFormDialog({ formType: "about" })} />

                          <Stack direction={"column"} sx={{ p: 2 }}>
                            <Skeleton variant="rectangular" height={200} />
                          </Stack>
                        </Stack>


                        {/* ***************** bilinen diller ******************** */}
                        <Stack direction="column" gap={1} border={"1px solid #ddd"} position={"relative"} >
                          <Typography variant="h6" sx={{ p: 2 }}>
                            Bilinen Diller
                          </Typography>

                          <Grid container spacing={2} p={2}>
                            {
                              [1, 2, 3].map((item, index) => {

                                return (
                                  <Grid item xs={6} sm={4} key={index} >
                                    <Skeleton variant="rectangular" height={150} />
                                  </Grid>
                                )
                              })
                            }

                            <Grid item xs={6} sm={4} >
                              <AddNewItem onClick={() => setFormDialog({ formType: "language" })} sx={{ height: '100%' }} />
                            </Grid>


                          </Grid>
                        </Stack>


                        {/* ***************** eğitim bilgileri  ******************** */}
                        <Stack direction="column" gap={1} border={"1px solid #ddd"} position={"relative"} p={2}>
                          <Typography variant="h6" sx={{ pb: 2 }}>
                            Eğitim Bilgileri
                          </Typography>

                          <Typography variant="body2" fontWeight={700}>
                            Ortaöğretim
                          </Typography>

                          {/* <HighSchoolsForm
                            type="account-page"
                            list={values?.education.highSchool}

                            onSubmit={(highSchool) => {
                              const isFind = values.education.highSchool.find(item => item === highSchool)
                              if (isFind) return
                              handleSubmitWhenDeletOrUpdateItem({
                                ...values,
                                education: {
                                  ...values.education,
                                  highSchool: [...values.education.highSchool, highSchool]
                                }
                              })
                            }}

                            onDeleteItem={(index) => {
                              const newList = values?.education.highSchool.filter((item, i) => i !== index)
                              handleSubmitWhenDeletOrUpdateItem({
                                ...values,
                                education: {
                                  ...values.education,
                                  highSchool: newList
                                }
                              })
                            }}
                          /> */}

                          <Typography variant="body2" fontWeight={700} pt={2}>
                            Yükseköğretim
                          </Typography>
                          {
                            [1, 2, 3].map((item, index) => {

                              return (
                                <Grid item xs={6} sm={4} key={index} >
                                  <Skeleton variant="rectangular" height={150} />
                                </Grid>
                              )
                            })
                          }
                        </Stack>


                        {/* ***************** kurs sertifika bilgileri  ******************** */}
                        <Stack direction="column" gap={1} border={"1px solid #ddd"} position={"relative"} >
                          <Typography variant="h6" sx={{ p: 2 }}>
                            Kurs / Sertifika Bilgileri
                          </Typography>

                          <Grid container spacing={2} p={2}>

                            {
                              [1, 2, 3].map((item, index) => {

                                return (
                                  <Grid item xs={6} sm={4} key={index} >
                                    <Skeleton variant="rectangular" height={150} />
                                  </Grid>
                                )
                              })
                            }

                            <Grid item xs={12} sm={6} md={4}  >
                              <AddNewItem onClick={() => setFormDialog({ formType: "certificate" })} sx={{ height: '100%' }} />
                            </Grid>

                          </Grid>

                        </Stack>

                        {/* ***************** katıldığı  projeler  ******************** */}
                        <Stack direction="column" gap={1} border={"1px solid #ddd"} position={"relative"} >
                          <Typography variant="h6" sx={{ p: 2 }}>
                            Katıldığı Projeler
                          </Typography>
                          <Grid container spacing={2} p={2}>
                            {
                              [1, 2, 3].map((item, index) => {

                                return (
                                  <Grid item xs={6} sm={4} key={index} >
                                    <Skeleton variant="rectangular" height={150} />
                                  </Grid>
                                )
                              })
                            }

                            <Grid item xs={12} sm={6} md={4} xl={3} >
                              <AddNewItem onClick={() => setFormDialog({ formType: "participatedProject" })} sx={{ flex: '1 1 300px', height: '100%', minHeight: '120px' }} />
                            </Grid>

                          </Grid>

                        </Stack>

                        {/* ***************** aldığı burslar  ******************** */}
                        <Stack direction="column" gap={1} border={"1px solid #ddd"} position={"relative"} >
                          <Typography variant="h6" sx={{ p: 2 }}>
                            Aldığı Burslar
                          </Typography>

                          <Grid container spacing={2} p={2}>
                            {
                              [1, 2, 3].map((item, index) => {

                                return (
                                  <Grid item xs={6} sm={4} key={index} >
                                    <Skeleton variant="rectangular" height={150} />
                                  </Grid>
                                )
                              })
                            }

                            <Grid item xs={12} sm={6} md={4} xl={3} >
                              <AddNewItem onClick={() => setFormDialog({ formType: "scholarship" })} sx={{ flex: '1 1 300px', height: '100%', minHeight: '100px' }} />
                            </Grid>

                          </Grid>
                        </Stack>

                        {/* ***************** hobiler   ******************** */}
                        <Stack direction="column" gap={1} border={"1px solid #ddd"} position={"relative"} >
                          <Typography variant="h6" sx={{ p: 2 }}>
                            Hobiler ve İlgi Alanları
                          </Typography>

                          <Box sx={{ p: 2 }}>
                            {/* <HobbiesForm

                              type="account-page"
                              list={values?.hobbies}

                              onSubmit={(hobby) => {
                                const isFind = values.hobbies.find(item => item === hobby)
                                if (isFind) return

                                handleSubmitWhenDeletOrUpdateItem({
                                  ...values,
                                  hobbies: [...values.hobbies, hobby]
                                })

                              }}

                              onDeleteItem={(index) => {
                                // values?.hobbies.splice(index, 1)
                                // console.log(values.hobbies, index);
                                // setFieldValue("hobbies", [...values.hobbies])

                                const newList = values?.hobbies.filter((item, i) => i !== index)
                                handleSubmitWhenDeletOrUpdateItem({
                                  ...values,
                                  hobbies: newList
                                })

                              }}
                            /> */}
                          </Box>

                        </Stack>


                      </Stack>


                      <Stack
                        direction="column"
                        gap={1}
                        sx={{
                          display: { xs: 'flex', md: 'flex' },
                          height: 'auto',
                          width: { xs: '100%', sm: '200px', lg: '300px', }
                        }}
                      >


                        {/* **************** Kişisel Bilgileri ***************** */}
                        <Stack direction="column" gap={1} border={"1px solid #ddd"} position={"relative"} >
                          <Typography variant="h6" sx={{ p: 2 }}>
                            Kişisel Bilgiler
                          </Typography>

                          <EditButton onClick={() => setFormDialog({ formType: "personalInformation" })} />

                          <Stack direction={"column"} sx={{ p: 2 }}>

                            <>
                              <Stack direction={"row"} gap={1} alignItems={"center"}>
                                <Iconify icon={"basil:gmail-outline"} sx={{ width: 24, color: "GrayText" }} />
                                <Typography variant="body2" color="GrayText" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                  Email
                                </Typography>
                              </Stack>

                              <Stack direction={"row"} gap={1} alignItems={"center"}>
                                <Box width={24} />
                                <Skeleton variant="text" />
                              </Stack>

                            </>


                            {/* {
                              data?.personalInformation?.phoneNumber && (
                                <>
                                  <Stack direction={"row"} gap={1} alignItems={"center"} mt={2}>
                                    <Iconify icon={"basil:mobile-phone-outline"} sx={{ width: 24, color: "GrayText" }} />
                                    <Typography variant="body2" color="GrayText" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                      Telefon
                                    </Typography>
                                  </Stack>
                                  <Stack direction={"row"} gap={1} alignItems={"center"}>
                                    <Box width={24} />
                                    <Skeleton variant="text" />
                                  </Stack>
                                </>
                              )
                            } */}

                            <>
                              <Stack direction={"row"} gap={1} alignItems={"center"} mt={2}>
                                <Iconify icon="basil:calendar-outline" sx={{ width: 24, color: "GrayText" }} />
                                <Typography variant="body2" color="GrayText" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                  Doğum Tarihi
                                </Typography>
                              </Stack>

                              <Stack direction={"row"} gap={1} alignItems={"center"}>
                                <Box width={24} />
                                <Skeleton variant="text" />
                              </Stack>
                            </>

                            <>
                              <Stack direction={"row"} gap={1} alignItems={"center"} mt={2}>
                                <Iconify icon="ph:gender-intersex" sx={{ width: 24, color: "GrayText" }} />
                                <Typography variant="body2" color="GrayText" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                  Cinsiyet
                                </Typography>
                              </Stack>

                              <Stack direction={"row"} gap={1} alignItems={"center"}>
                                <Box width={24} />
                                <Skeleton variant="text" />
                              </Stack>

                              {/* {
                                data?.personalInformation?.gender === "specified" && (
                                  <Stack direction={"row"} gap={1} alignItems={"center"}>
                                    <Box width={24} />
                                    <Skeleton variant="text" />
                                  </Stack>
                                )
                              } */}
                            </>

                            <>
                              <Stack direction={"row"} gap={1} alignItems={"center"} mt={2}>
                                <Iconify icon="mingcute:world-2-line" sx={{ width: 24, color: "GrayText" }} />
                                <Typography variant="body2" color="GrayText" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                  Uyruk
                                </Typography>
                              </Stack>

                              <Stack direction={"row"} gap={1} alignItems={"center"}>
                                <Box width={24} />
                                <Skeleton variant="text" />
                              </Stack>

                              {/* {
                                data?.personalInformation?.citizenship && (
                                  <Stack direction={"row"} gap={1} alignItems={"center"}>
                                    <Box width={24} />
                                    <Typography variant="body2" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                      Farklı Vatandaşlık: {data?.personalInformation?.citizenship}
                                    </Typography>
                                  </Stack>
                                )
                              } */}
                            </>

                            <>
                              <Stack direction={"row"} gap={1} alignItems={"center"} mt={2}>
                                <Iconify icon="basil:location-outline" sx={{ width: 24, color: "GrayText" }} />
                                <Typography variant="body2" color="GrayText" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                  Adres
                                </Typography>
                              </Stack>

                              <Stack direction={"row"} gap={1} alignItems={"center"}>
                                <Box width={24} />
                                <Skeleton variant="text" />
                              </Stack>

                              <Stack direction={"row"} gap={1} alignItems={"center"}>
                                <Box width={24} />
                                <Skeleton variant="text" />
                              </Stack>
                            </>


                          </Stack>
                        </Stack>



                        {/* ******************* Ek Bilgiler ****************** */}
                        <Stack direction="column" gap={1} border={"1px solid #ddd"} position={"relative"} >
                          <Typography variant="h6" sx={{ p: 2 }}>
                            Ek Bilgiler
                          </Typography>

                          <EditButton onClick={() => setFormDialog({ formType: "additionalInformation" })} />

                          <Stack direction={"column"} sx={{ p: 2 }}>

                            {/* {
                              data?.workExperiencesInMonths && (
                                <>
                                  <Stack direction={"row"} gap={1} alignItems={"center"} >
                                    <Iconify icon="bx:bx-time-five" sx={{ width: 24, color: "GrayText" }} />
                                    <Typography variant="body2" color="GrayText" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                      İş Deneyimi
                                    </Typography>
                                  </Stack>

                                  <Stack direction={"row"} gap={1} alignItems={"center"} mb={2}>
                                    <Box width={24} />
                                    <Typography variant="body2" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                      {data?.workExperiencesInMonths} Ay
                                    </Typography>
                                  </Stack>
                                </>
                              )
                            } */}

                            {/* {
                              data?.computerKnowledgeLevel && (
                                <>
                                  <Stack direction={"row"} gap={1} alignItems={"center"} >
                                    <Iconify icon="bx:bx-desktop" sx={{ width: 24, color: "GrayText" }} />
                                    <Typography variant="body2" color="GrayText" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                      Bilgisayar Bilgisi
                                    </Typography>
                                  </Stack>

                                  <Stack direction={"row"} gap={1} alignItems={"center"}>
                                    <Box width={24} />
                                    <Typography variant="body2" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                      {getNameOfEnum(ComputerKnowledgeLevelEnum, data?.computerKnowledgeLevel)} seviye
                                    </Typography>
                                  </Stack>

                                  <Stack direction={"row"} gap={1} alignItems={"center"} mb={2}>
                                    <Box width={24} />
                                    <Typography variant="body2" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                      Programlar : {data?.programmingLanguages?.map((item: any) => {
                                        return ` ${item?.name} (${item.level})`
                                      }
                                      ).join(", ")}
                                    </Typography>
                                  </Stack>
                                </>
                              )
                            } */}

                            <>
                              <Stack direction={"row"} gap={1} alignItems={"center"} >
                                <Iconify icon="mingcute:car-line" sx={{ width: 24, color: "GrayText" }} />
                                <Typography variant="body2" color="GrayText" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                  Sürücü Belgesi
                                </Typography>
                              </Stack>

                              <Stack direction={"row"} gap={1} alignItems={"center"} mb={2}>
                                <Box width={24} />
                                <Skeleton variant="text" />
                              </Stack>

                              {/* {
                                data?.driverLicense?.status === "exist" && (
                                  <Stack direction={"row"} gap={1} alignItems={"center"} mb={2}>
                                    <Box width={24} />
                                    <Typography variant="body2" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                      Ehliyet Sınıfı: {data?.driverLicense?.licenseType
                                        //?.classes?.join(", ")
                                      }
                                    </Typography>
                                  </Stack>
                                )
                              } */}
                            </>

                            <>
                              <Stack direction={"row"} gap={1} alignItems={"center"} >
                                <Iconify icon="healthicons:smoking-outline" sx={{ width: 24, color: "GrayText" }} />
                                <Typography variant="body2" color="GrayText" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                  Sigara Kullanımı
                                </Typography>
                              </Stack>

                              <Stack direction={"row"} gap={1} alignItems={"center"} mb={2}>
                                <Box width={24} />
                                <Skeleton variant="text" />
                              </Stack>
                            </>

                          </Stack>
                        </Stack>

                      </Stack>

                      <CandidateUpdateCandidateDialogs
                        formDialog={formDialog}
                        setFormDialog={setFormDialog}
                        profileImage={profileImage}
                        setProfileImage={setProfileImage}
                        handleSubmitWhenDeletOrUpdateItem={handleSubmitWhenDeletOrUpdateItem}
                        loading={loading}
                      />

                    </Stack>
                  ) : (
                    <Stack
                      flexDirection={{ xs: "column", md: "row" }}
                      gap={3}
                      sx={{ my: 5, }}
                    >

                      <Stack
                        direction="column"
                        gap={1}
                        flex={1}
                      >

                        {/* *********************** Profile Header ********************** */}
                        <Stack direction="column" gap={1} border={"1px solid #ddd"} position={"relative"} >

                          <EditButton onClick={() => setFormDialog({ formType: "header" })} />
                          <Box
                            sx={{
                              backgroundImage: `url(${CandidateAdvertisementDetailImage})`,
                              height: '100px',
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat',
                            }}
                          />

                          <Stack direction={"row"}>
                            <Box
                              component={"img"}
                              src={profileImage ?? data?.personalInformation?.profileImageUrl ?? User}
                              alt="profile"
                              sx={{
                                width: 120,
                                height: 120,
                                objectFit: 'cover',
                                borderRadius: '50%',
                                border: '7px solid',
                                borderColor: '#fff',
                                marginTop: '-60px',
                                marginLeft: '20px',
                              }}
                            />
                            <Stack direction={"column"} sx={{ ml: 3, gap: 1 }}>
                              <Typography variant="h4" >
                                {data?.personalInformation?.name} {data?.personalInformation?.surname}
                              </Typography>
                              <Typography variant="body1" mb={2} color="GrayText">
                                {data?.personalInformation?.country}, {data?.personalInformation?.city}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>


                        {/* ***************** Hakkımda ******************** */}
                        <Stack direction="column" gap={1} border={"1px solid #ddd"} position={"relative"} >
                          <Typography variant="h6" sx={{ p: 2 }}>
                            Hakkımda
                          </Typography>

                          <EditButton onClick={() => setFormDialog({ formType: "about" })} />

                          <Stack direction={"column"} sx={{ p: 2 }}>
                            <Typography variant="body2" color="GrayText">
                              {data?.personalInformation?.description ?? 'Görüntülenecek bir yazı bulunamadı!'}
                            </Typography>
                          </Stack>
                        </Stack>


                        {/* ***************** bilinen diller ******************** */}
                        <Stack direction="column" gap={1} border={"1px solid #ddd"} position={"relative"} >
                          <Typography variant="h6" sx={{ p: 2 }}>
                            Bilinen Diller
                          </Typography>

                          <Grid container spacing={2} p={2}>
                            {
                              values.languages.map((language: ILanguage, index) => {

                                return (
                                  <Grid item xs={6} sm={4} key={index} >
                                    <Box
                                      sx={{
                                        p: 2,
                                        height: '100%',
                                        border: '2px solid #ddd',
                                        position: 'relative',
                                        flex: '1 1 300px',

                                        transitionDuration: '200ms',
                                        '&:hover': {
                                          boxShadow: '0px 0px 10px 0px #9C8FF166',
                                        },
                                      }}
                                    >

                                      <Iconify
                                        icon="entypo:language"
                                        width={25}
                                        sx={{
                                          color: 'primary.main',
                                        }}
                                      />

                                      <Typography variant="h5">
                                        {language?.name}
                                      </Typography>

                                      <Typography variant="body2">
                                        Dinleme : {getNameOfEnum(LanguageLevelEnum, language?.listening)}
                                      </Typography>
                                      <Typography variant="body2">
                                        Okuma : {getNameOfEnum(LanguageLevelEnum, language?.reading)}
                                      </Typography>
                                      <Typography variant="body2">
                                        Yazma : {getNameOfEnum(LanguageLevelEnum, language?.writing)}
                                      </Typography>

                                      <MoreMenu
                                        onUpdate={() => {
                                          setFormDialog({
                                            formType: "language",
                                            formValue: language,
                                            itemIndex: index
                                          })
                                        }}
                                        onDelete={() => {
                                          const newList = values?.languages.filter((item, i) => i !== index)
                                          handleSubmitWhenDeletOrUpdateItem({
                                            ...values,
                                            languages: newList
                                          })
                                        }}
                                      />

                                    </Box>
                                  </Grid>
                                )
                              })
                            }

                            <Grid item xs={6} sm={4} >
                              <AddNewItem onClick={() => setFormDialog({ formType: "language" })} sx={{ height: '100%' }} />
                            </Grid>


                          </Grid>
                        </Stack>


                        {/* ***************** eğitim bilgileri  ******************** */}
                        <Stack direction="column" gap={1} border={"1px solid #ddd"} position={"relative"} p={2}>
                          <Typography variant="h6" sx={{ pb: 2 }}>
                            Eğitim Bilgileri
                          </Typography>

                          <Typography variant="body2" fontWeight={700}>
                            Ortaöğretim
                          </Typography>

                          <HighSchoolsForm
                            type="account-page"
                            list={values?.education.highSchool}

                            onSubmit={(highSchool) => {
                              const isFind = values.education.highSchool.find(item => item === highSchool)
                              if (isFind) return
                              handleSubmitWhenDeletOrUpdateItem({
                                ...values,
                                education: {
                                  ...values.education,
                                  highSchool: [...values.education.highSchool, highSchool]
                                }
                              })
                            }}

                            onDeleteItem={(index) => {
                              const newList = values?.education.highSchool.filter((item, i) => i !== index)
                              handleSubmitWhenDeletOrUpdateItem({
                                ...values,
                                education: {
                                  ...values.education,
                                  highSchool: newList
                                }
                              })
                            }}
                          />

                          <Typography variant="body2" fontWeight={700} pt={2}>
                            Yükseköğretim
                          </Typography>
                          <Grid container spacing={2} >
                            {
                              values?.education?.higherEducation?.map((higherEducation: IHigherEducation, index) => {

                                return (
                                  <Grid item xs={12} sm={6} md={4} key={index} >
                                    <Box
                                      sx={{
                                        p: 2,
                                        height: '100%',
                                        border: '2px solid #ddd',
                                        position: 'relative',
                                        flex: '1 1 300px',

                                        transitionDuration: '200ms',
                                        '&:hover': {
                                          boxShadow: '0px 0px 10px 0px #9C8FF166',
                                        },

                                      }}
                                    >

                                      <Iconify
                                        icon="mdi:university"
                                        width={25}
                                        sx={{
                                          color: 'primary.main',
                                        }}
                                      />

                                      <Typography variant="h6">
                                        {higherEducation?.name}
                                      </Typography>

                                      <Typography variant="body2">
                                        {higherEducation?.department}
                                      </Typography>


                                      <MoreMenu

                                        onUpdate={() => {
                                          setFormDialog({
                                            formType: "higherEducation",
                                            formValue: higherEducation,
                                            itemIndex: index
                                          })
                                        }}

                                        onDelete={() => {
                                          // values.education.higherEducation.splice(index, 1)
                                          // setFieldValue("education.higherEducation", values.education.higherEducation)
                                          const newList = values?.education.higherEducation.filter((item, i) => i !== index)
                                          handleSubmitWhenDeletOrUpdateItem({
                                            ...values,
                                            education: {
                                              ...values.education,
                                              higherEducation: newList
                                            }
                                          })
                                        }}

                                      />

                                    </Box>
                                  </Grid>
                                )
                              })
                            }

                            <Grid item xs={12} sm={6} md={4} >
                              <AddNewItem onClick={() => setFormDialog({ formType: "higherEducation" })} sx={{ height: '100%' }} />
                            </Grid>

                          </Grid>
                        </Stack>


                        {/* ***************** kurs sertifika bilgileri  ******************** */}
                        <Stack direction="column" gap={1} border={"1px solid #ddd"} position={"relative"} >
                          <Typography variant="h6" sx={{ p: 2 }}>
                            Kurs / Sertifika Bilgileri
                          </Typography>

                          <Grid container spacing={2} p={2}>

                            {
                              values.certificates.map((certificate: ICertificate, index) => {

                                return (

                                  <Grid item xs={12} sm={6} md={4} key={`${certificate?.name}-${index}`} >
                                    <Box
                                      sx={{
                                        p: 2,
                                        height: '100%',
                                        border: '2px solid #ddd',
                                        position: 'relative',
                                        flex: '1 1 300px',

                                        transitionDuration: '200ms',
                                        '&:hover': {
                                          boxShadow: '0px 0px 10px 0px #9C8FF166',
                                        },
                                      }}
                                    >

                                      <Iconify
                                        icon="mdi:certificate"
                                        width={25}
                                        sx={{
                                          color: 'primary.main',
                                        }}
                                      />

                                      <Typography variant="h6">
                                        {certificate?.name}
                                      </Typography>

                                      <Typography variant="body1">
                                        {certificate?.certificateInstitution}
                                      </Typography>
                                      {/* 
                    <Typography variant="body1" color="#999">
                      {certificate.type}
                    </Typography>

                    <Typography>
                      {moment(certificate.issuedDate).format("DD MMM YYYY")} - {moment(certificate.validUntil).format("DD MMM YYYY")}
                    </Typography> */}

                                      <MoreMenu
                                        onUpdate={() => {
                                          setFormDialog({
                                            formType: "certificate",
                                            formValue: certificate,
                                            itemIndex: index
                                          })
                                        }}
                                        onDelete={() => {
                                          // values.certificates.splice(index, 1)
                                          // setFieldValue("certificates", [...values.certificates])
                                          const newList = values?.certificates.filter((item, i) => i !== index)
                                          handleSubmitWhenDeletOrUpdateItem({
                                            ...values,
                                            certificates: newList
                                          })
                                        }}
                                      />

                                    </Box>
                                  </Grid>
                                )
                              })
                            }

                            <Grid item xs={12} sm={6} md={4}  >
                              <AddNewItem onClick={() => setFormDialog({ formType: "certificate" })} sx={{ height: '100%' }} />
                            </Grid>

                          </Grid>

                        </Stack>

                        {/* ***************** katıldığı  projeler  ******************** */}
                        <Stack direction="column" gap={1} border={"1px solid #ddd"} position={"relative"} >
                          <Typography variant="h6" sx={{ p: 2 }}>
                            Katıldığı Projeler
                          </Typography>
                          <Grid container spacing={2} p={2}>
                            {
                              values.participatedProjects.map((participatedProject: IParticipatedProjects, index) => {

                                return (
                                  <Grid item xs={12} sm={6} md={4} xl={3} key={index} >
                                    <Box
                                      sx={{
                                        p: 2,
                                        height: '100%',
                                        border: '2px solid #ddd',
                                        position: 'relative',
                                        flex: '1 1 300px',

                                        transitionDuration: '200ms',
                                        '&:hover': {
                                          boxShadow: '0px 0px 10px 0px #9C8FF166',
                                        },
                                      }}
                                    >

                                      <Iconify
                                        icon="fluent:communication-20-filled"
                                        width={25}
                                        sx={{
                                          color: 'primary.main',
                                        }}
                                      />

                                      <Typography variant="h6">
                                        {participatedProject?.name}
                                      </Typography>


                                      <Typography variant="body1" color="#999">
                                        {participatedProject?.institution}
                                      </Typography>


                                      <MoreMenu
                                        onUpdate={() => {
                                          setFormDialog({
                                            formType: "participatedProject",
                                            formValue: participatedProject,
                                            itemIndex: index
                                          })
                                        }}
                                        onDelete={() => {
                                          // values.participatedProjects.splice(index, 1)
                                          // setFieldValue("participatedProjects", values.participatedProjects)
                                          const newList = values?.participatedProjects.filter((item, i) => i !== index)
                                          handleSubmitWhenDeletOrUpdateItem({
                                            ...values,
                                            participatedProjects: newList
                                          })
                                        }}
                                      />

                                    </Box>
                                  </Grid>
                                )
                              })
                            }

                            <Grid item xs={12} sm={6} md={4} xl={3} >
                              <AddNewItem onClick={() => setFormDialog({ formType: "participatedProject" })} sx={{ flex: '1 1 300px', height: '100%', minHeight: '120px' }} />
                            </Grid>

                          </Grid>

                        </Stack>

                        {/* ***************** aldığı burslar  ******************** */}
                        <Stack direction="column" gap={1} border={"1px solid #ddd"} position={"relative"} >
                          <Typography variant="h6" sx={{ p: 2 }}>
                            Aldığı Burslar
                          </Typography>

                          <Grid container spacing={2} p={2}>
                            {
                              values.scholarships.map((scholarship: IScholarship, index) => {

                                return (
                                  <Grid item xs={12} sm={6} md={4} xl={3} key={index} >
                                    <Box
                                      sx={{
                                        p: 2,
                                        height: '100%',
                                        border: '2px solid #ddd',
                                        position: 'relative',
                                        flex: '1 1 300px',

                                        transitionDuration: '200ms',
                                        '&:hover': {
                                          boxShadow: '0px 0px 10px 0px #9C8FF166',
                                        },
                                      }}
                                    >

                                      <Iconify
                                        icon="mdi:hand-extended"
                                        width={25}
                                        sx={{
                                          color: 'primary.main',
                                        }}
                                      />
                                      <Typography variant="h6">
                                        {scholarship.institution}
                                      </Typography>

                                      <Typography variant="body1">
                                        {scholarship.type}
                                      </Typography>

                                      {/* <Typography>
                      {moment(scholarship.receivingDate).format("DD MMM YYYY")}
                    </Typography> */}

                                      <MoreMenu
                                        onUpdate={() => {
                                          setFormDialog({
                                            formType: "scholarship",
                                            formValue: scholarship,
                                            itemIndex: index
                                          })
                                        }}
                                        onDelete={() => {
                                          // values.scholarships.splice(index, 1)
                                          // setFieldValue("scholarships", values.scholarships)
                                          const newList = values?.scholarships.filter((item, i) => i !== index)
                                          handleSubmitWhenDeletOrUpdateItem({
                                            ...values,
                                            scholarships: newList
                                          })

                                        }}
                                      />

                                    </Box>
                                  </Grid>
                                )
                              })
                            }

                            <Grid item xs={12} sm={6} md={4} xl={3} >
                              <AddNewItem onClick={() => setFormDialog({ formType: "scholarship" })} sx={{ flex: '1 1 300px', height: '100%', minHeight: '100px' }} />
                            </Grid>

                          </Grid>
                        </Stack>

                        {/* ***************** hobiler   ******************** */}
                        <Stack direction="column" gap={1} border={"1px solid #ddd"} position={"relative"} >
                          <Typography variant="h6" sx={{ p: 2 }}>
                            Hobiler ve İlgi Alanları
                          </Typography>

                          <Box sx={{ p: 2 }}>
                            <HobbiesForm

                              type="account-page"
                              list={values?.hobbies}

                              onSubmit={(hobby) => {
                                const isFind = values.hobbies.find(item => item === hobby)
                                if (isFind) return

                                handleSubmitWhenDeletOrUpdateItem({
                                  ...values,
                                  hobbies: [...values.hobbies, hobby]
                                })

                              }}

                              onDeleteItem={(index) => {
                                // values?.hobbies.splice(index, 1)
                                // console.log(values.hobbies, index);
                                // setFieldValue("hobbies", [...values.hobbies])

                                const newList = values?.hobbies.filter((item, i) => i !== index)
                                handleSubmitWhenDeletOrUpdateItem({
                                  ...values,
                                  hobbies: newList
                                })

                              }}
                            />
                          </Box>

                        </Stack>


                      </Stack>


                      <Stack
                        direction="column"
                        gap={1}
                        sx={{
                          display: { xs: 'flex', md: 'flex' },
                          height: 'auto',
                          width: { xs: '100%', sm: '200px', lg: '300px', }
                        }}
                      >


                        {/* **************** Kişisel Bilgileri ***************** */}
                        <Stack direction="column" gap={1} border={"1px solid #ddd"} position={"relative"} >
                          <Typography variant="h6" sx={{ p: 2 }}>
                            Kişisel Bilgiler
                          </Typography>

                          <EditButton onClick={() => setFormDialog({ formType: "personalInformation" })} />

                          <Stack direction={"column"} sx={{ p: 2 }}>

                            <>
                              <Stack direction={"row"} gap={1} alignItems={"center"}>
                                <Iconify icon={"basil:gmail-outline"} sx={{ width: 24, color: "GrayText" }} />
                                <Typography variant="body2" color="GrayText" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                  Email
                                </Typography>
                              </Stack>

                              <Stack direction={"row"} gap={1} alignItems={"center"}>
                                <Box width={24} />
                                <Typography variant="body2" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                  {data?.contact?.email}
                                </Typography>
                              </Stack>

                            </>


                            {
                              data?.personalInformation?.phoneNumber && (
                                <>
                                  <Stack direction={"row"} gap={1} alignItems={"center"} mt={2}>
                                    <Iconify icon={"basil:mobile-phone-outline"} sx={{ width: 24, color: "GrayText" }} />
                                    <Typography variant="body2" color="GrayText" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                      Telefon
                                    </Typography>
                                  </Stack>
                                  <Stack direction={"row"} gap={1} alignItems={"center"}>
                                    <Box width={24} />
                                    <Typography variant="body2" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                      {data?.personalInformation?.phoneNumber}
                                    </Typography>
                                  </Stack>
                                </>
                              )
                            }

                            <>
                              <Stack direction={"row"} gap={1} alignItems={"center"} mt={2}>
                                <Iconify icon="basil:calendar-outline" sx={{ width: 24, color: "GrayText" }} />
                                <Typography variant="body2" color="GrayText" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                  Doğum Tarihi
                                </Typography>
                              </Stack>

                              <Stack direction={"row"} gap={1} alignItems={"center"}>
                                <Box width={24} />
                                <Typography variant="body2" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                  {moment(data?.personalInformation?.birthDate).format("DD MMMM YYYY")}
                                </Typography>
                              </Stack>
                            </>

                            <>
                              <Stack direction={"row"} gap={1} alignItems={"center"} mt={2}>
                                <Iconify icon="ph:gender-intersex" sx={{ width: 24, color: "GrayText" }} />
                                <Typography variant="body2" color="GrayText" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                  Cinsiyet
                                </Typography>
                              </Stack>

                              <Stack direction={"row"} gap={1} alignItems={"center"}>
                                <Box width={24} />
                                <Typography variant="body2" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                  {getNameOfEnum(SexEnum, data?.personalInformation?.sex)}
                                </Typography>
                              </Stack>

                              {/* {
                                data?.personalInformation?.gender === "specified" && (
                                  <Stack direction={"row"} gap={1} alignItems={"center"}>
                                    <Box width={24} />
                                    <Typography variant="body2" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                      Cinsel Yönelim: {data?.personalInformation?.genderDetail}
                                    </Typography>
                                  </Stack>
                                )
                              } */}
                            </>

                            <>
                              <Stack direction={"row"} gap={1} alignItems={"center"} mt={2}>
                                <Iconify icon="mingcute:world-2-line" sx={{ width: 24, color: "GrayText" }} />
                                <Typography variant="body2" color="GrayText" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                  Uyruk
                                </Typography>
                              </Stack>

                              <Stack direction={"row"} gap={1} alignItems={"center"}>
                                <Box width={24} />
                                <Typography variant="body2" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                  {data?.personalInformation?.nationality}
                                </Typography>
                              </Stack>

                              {
                                data?.personalInformation?.citizenship && (
                                  <Stack direction={"row"} gap={1} alignItems={"center"}>
                                    <Box width={24} />
                                    <Typography variant="body2" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                      Farklı Vatandaşlık: {data?.personalInformation?.citizenship}
                                    </Typography>
                                  </Stack>
                                )
                              }
                            </>

                            <>
                              <Stack direction={"row"} gap={1} alignItems={"center"} mt={2}>
                                <Iconify icon="basil:location-outline" sx={{ width: 24, color: "GrayText" }} />
                                <Typography variant="body2" color="GrayText" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                  Adres
                                </Typography>
                              </Stack>

                              <Stack direction={"row"} gap={1} alignItems={"center"}>
                                <Box width={24} />
                                <Typography variant="body2" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                  {data?.personalInformation?.country}, {data?.personalInformation?.city}, {data?.personalInformation?.district}
                                </Typography>
                              </Stack>

                              <Stack direction={"row"} gap={1} alignItems={"center"}>
                                <Box width={24} />
                                <Typography variant="body2" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                  {data?.personalInformation?.address}
                                </Typography>
                              </Stack>
                            </>


                          </Stack>
                        </Stack>



                        {/* ******************* Ek Bilgiler ****************** */}
                        <Stack direction="column" gap={1} border={"1px solid #ddd"} position={"relative"} >
                          <Typography variant="h6" sx={{ p: 2 }}>
                            Ek Bilgiler
                          </Typography>

                          <EditButton onClick={() => setFormDialog({ formType: "additionalInformation" })} />

                          <Stack direction={"column"} sx={{ p: 2 }}>

                            {
                              data?.workExperiencesInMonths && (
                                <>
                                  <Stack direction={"row"} gap={1} alignItems={"center"} >
                                    <Iconify icon="bx:bx-time-five" sx={{ width: 24, color: "GrayText" }} />
                                    <Typography variant="body2" color="GrayText" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                      İş Deneyimi
                                    </Typography>
                                  </Stack>

                                  <Stack direction={"row"} gap={1} alignItems={"center"} mb={2}>
                                    <Box width={24} />
                                    <Typography variant="body2" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                      {data?.workExperiencesInMonths} Ay
                                    </Typography>
                                  </Stack>
                                </>
                              )
                            }

                            {
                              data?.computerKnowledgeLevel && (
                                <>
                                  <Stack direction={"row"} gap={1} alignItems={"center"} >
                                    <Iconify icon="bx:bx-desktop" sx={{ width: 24, color: "GrayText" }} />
                                    <Typography variant="body2" color="GrayText" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                      Bilgisayar Bilgisi
                                    </Typography>
                                  </Stack>

                                  <Stack direction={"row"} gap={1} alignItems={"center"}>
                                    <Box width={24} />
                                    <Typography variant="body2" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                      {getNameOfEnum(ComputerKnowledgeLevelEnum, data?.computerKnowledgeLevel)} seviye
                                    </Typography>
                                  </Stack>

                                  <Stack direction={"row"} gap={1} alignItems={"center"} mb={2}>
                                    <Box width={24} />
                                    <Typography variant="body2" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                      Programlar : {data?.programmingLanguages?.map((item: any) => {
                                        return ` ${item?.name} (${item.level})`
                                      }
                                      ).join(", ")}
                                    </Typography>
                                  </Stack>
                                </>
                              )
                            }

                            <>
                              <Stack direction={"row"} gap={1} alignItems={"center"} >
                                <Iconify icon="mingcute:car-line" sx={{ width: 24, color: "GrayText" }} />
                                <Typography variant="body2" color="GrayText" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                  Sürücü Belgesi
                                </Typography>
                              </Stack>

                              <Stack direction={"row"} gap={1} alignItems={"center"} mb={2}>
                                <Box width={24} />
                                <Typography variant="body2" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                  {data?.driverLicense?.status === "exist" ? "Var" : "Yok"}
                                </Typography>
                              </Stack>

                              {
                                data?.driverLicense?.status === "exist" && (
                                  <Stack direction={"row"} gap={1} alignItems={"center"} mb={2}>
                                    <Box width={24} />
                                    <Typography variant="body2" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                      Ehliyet Sınıfı: {data?.driverLicense?.licenseType
                                        //?.classes?.join(", ")
                                      }
                                    </Typography>
                                  </Stack>
                                )
                              }
                            </>

                            <>
                              <Stack direction={"row"} gap={1} alignItems={"center"} >
                                <Iconify icon="healthicons:smoking-outline" sx={{ width: 24, color: "GrayText" }} />
                                <Typography variant="body2" color="GrayText" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                  Sigara Kullanımı
                                </Typography>
                              </Stack>

                              <Stack direction={"row"} gap={1} alignItems={"center"} mb={2}>
                                <Box width={24} />
                                <Typography variant="body2" alignItems={"center"} justifyContent={"flex-start"} display={"flex"}>
                                  {data?.smoking ? "Evet" : "Hayır"}
                                </Typography>
                              </Stack>
                            </>

                          </Stack>
                        </Stack>

                      </Stack>

                      <CandidateUpdateCandidateDialogs
                        formDialog={formDialog}
                        setFormDialog={setFormDialog}
                        profileImage={profileImage}
                        setProfileImage={setProfileImage}
                        handleSubmitWhenDeletOrUpdateItem={handleSubmitWhenDeletOrUpdateItem}
                        loading={loading}
                      />

                    </Stack>
                  )
                }
              </Form>
            )
          }
        }
      </Formik>

    </DashboardLayoutMobile >
  )
}



interface ICandidateUpdateCandidateDialogs {
  formDialog: any,
  setFormDialog: (e?: any) => void,
  setProfileImage: (e?: any) => void,
  handleSubmitWhenDeletOrUpdateItem: (e?: any) => void,
  profileImage: any,
  loading: boolean
}

const CandidateUpdateCandidateDialogs = ({ formDialog, setFormDialog, profileImage, setProfileImage, handleSubmitWhenDeletOrUpdateItem, loading }: ICandidateUpdateCandidateDialogs) => {

  const { values } = useFormikContext<ICandidateInformationInput>()

  useEffect(() => {
    sessionStorage.setItem("candidateOnboardingInfo", JSON.stringify(values))
  }, [values])


  return (
    <Dialog
      open={!!formDialog}
      TransitionComponent={MuiTransition}
      keepMounted
      onClose={() => setFormDialog(null)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent sx={{ p: 0 }}>

        {
          formDialog?.formType === "header" && (
            <CandidateAccountHeaderForm profileImage={profileImage} setProfileImage={setProfileImage} loading={loading} setFormDialog={setFormDialog} />
          )
        }

        {
          formDialog?.formType === "about" && (
            <CandidateAccountAboutForm />
          )
        }

        {
          formDialog?.formType === "personalInformation" && (
            <CandidateAccountPersonalInformationForm />
          )
        }

        {
          formDialog?.formType === "additionalInformation" && (
            <CandidateAccountAdditionalInformationForm />
          )
        }



        {
          formDialog?.formType === "language" && (
            <LanguageForm
              initialValues={formDialog.formValue}
              onSubmit={(language) => {
                if (formDialog.itemIndex || formDialog.itemIndex === 0) {
                  const newList = [...values.languages]
                  newList[formDialog.itemIndex] = language
                  handleSubmitWhenDeletOrUpdateItem({
                    ...values,
                    languages: newList
                  })
                } else {
                  handleSubmitWhenDeletOrUpdateItem({
                    ...values,
                    languages: [...values.languages, language]
                  })
                }
                setFormDialog(null)

              }}
            />
          )
        }


        {
          formDialog?.formType === "higherEducation" && (
            <HigherEducationForm
              initialValues={formDialog.formValue}
              onSubmit={(higherEducation) => {
                if (formDialog.itemIndex || formDialog.itemIndex === 0) {
                  const newList = [...values.education.higherEducation]
                  newList[formDialog.itemIndex] = higherEducation
                  handleSubmitWhenDeletOrUpdateItem({
                    ...values,
                    education: {
                      ...values.education,
                      higherEducation: newList
                    }
                  })

                } else {
                  handleSubmitWhenDeletOrUpdateItem({
                    ...values,
                    education: {
                      ...values.education,
                      higherEducation: [...values.education.higherEducation, higherEducation]
                    }
                  })
                }
                setFormDialog(null)
              }}
            />
          )
        }



        {
          formDialog?.formType === "certificate" && (
            <CertificateForm
              initialValues={formDialog.formValue}
              onSubmit={(certificate) => {
                if (formDialog.itemIndex || formDialog.itemIndex === 0) {
                  const newList = [...values.certificates]
                  newList[formDialog.itemIndex] = certificate
                  handleSubmitWhenDeletOrUpdateItem({
                    ...values,
                    certificates: newList
                  })
                } else {
                  handleSubmitWhenDeletOrUpdateItem({
                    ...values,
                    certificates: [...values.certificates, certificate]
                  })
                }
                setFormDialog(null)
              }}
            />
          )
        }



        {
          formDialog?.formType === "participatedProject" && (
            <ParticipatedProjectForm
              initialValues={formDialog.formValue}
              onSubmit={(participatedProject) => {
                if (formDialog.itemIndex || formDialog.itemIndex === 0) {
                  const newList = [...values.participatedProjects]
                  newList[formDialog.itemIndex] = participatedProject
                  handleSubmitWhenDeletOrUpdateItem({
                    ...values,
                    participatedProjects: newList
                  })
                } else {

                  handleSubmitWhenDeletOrUpdateItem({
                    ...values,
                    participatedProjects: [...values.participatedProjects, participatedProject]
                  })
                }
                setFormDialog(null)
              }}
            />
          )
        }


        {
          formDialog?.formType === "scholarship" && (
            <ScholarshipsForm
              initialValues={formDialog.formValue}
              onSubmit={(scholarship) => {
                if (formDialog.itemIndex || formDialog.itemIndex === 0) {
                  const newList = [...values.scholarships]
                  newList[formDialog.itemIndex] = scholarship
                  handleSubmitWhenDeletOrUpdateItem({
                    ...values,
                    scholarships: newList
                  })
                } else {
                  handleSubmitWhenDeletOrUpdateItem({
                    ...values,
                    scholarships: [...values.scholarships, scholarship]
                  })
                }
                setFormDialog(null)
              }}
            />
          )
        }

      </DialogContent>
    </Dialog>
  )
}



const AddNewItem = ({ onClick, sx }: { onClick: () => void, sx: SxProps }) => {

  return (
    <Box
      onClick={onClick}
      sx={{
        p: 2,
        border: '1px dashed #ccc',
        cursor: 'pointer',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transitionDuration: '200ms',

        '&:hover': {
          transform: 'scale(1.02)'
        },

        ...sx,
      }}
    >
      <AddIcon />
    </Box>
  )
}

interface IMoreMenu {
  onUpdate?: () => void,
  onDelete?: () => void,
}

const MoreMenu = ({ onUpdate, onDelete }: IMoreMenu) => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        <MoreHorizIcon />
      </IconButton>

      {(onDelete || onUpdate) && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {
            onUpdate && (
              <MenuItem
                onClick={() => {
                  onUpdate()
                  handleClose()
                }}
                sx={{ justifyContent: 'space-between' }}
              >
                <Typography variant="body2" color="text.black">
                  Değiştir
                </Typography>
                <Iconify icon="mdi:pencil" sx={{ color: 'info.main', ml: 1 }} />
              </MenuItem>
            )
          }
          {
            onDelete && (
              <MenuItem
                onClick={() => {
                  onDelete()
                  handleClose()
                }}
                sx={{ justifyContent: 'space-between' }}
              >

                <Typography variant="body2" color="text.black">
                  Sil
                </Typography>
                <Iconify icon="mdi:delete" sx={{ color: 'error.main', ml: 1 }} />
              </MenuItem>
            )
          }



        </Menu>
      )}
    </>
  )
}

const EditButton = ({ onClick }: { onClick: () => void }) => {

  return (
    <Box
      onClick={onClick}
      position={'absolute'}
      top={15}
      right={15}
      border={"1px solid #ddd"}
      width={30}
      height={30}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Iconify icon={"basil:edit-outline"} sx={{ width: 20, height: 20, cursor: 'pointer', color: 'primary.dark' }} />
    </Box>
  )
}


