export const user_ozgelecekQuestions = ({ variables }: any) => {

  

  const data = JSON.stringify({
    query: `
        query User_ozgelecekQuestions {
          user_ozgelecekQuestions {
            count
            data {
              _id
              content
              createdAt
              index
              updatedAt
            }
          }
        }
        `,
    variables: {}
  })

  return data
}
