import React from 'react';
// import { useAppSelector } from 'context';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { LoginImage } from 'assets';


const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#F0F0F0'
  },
  container: {
    margin: 10,
    padding: 10,
  },
  text: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'blue',
  }
});
const MyDocument = () => {
  // const { user } = useAppSelector(state => state.auth)


  return (

    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <Text style={styles.text}>Simple PDF Example</Text>
          <Image src={LoginImage} style={{ width: 100, height: 100 }} />
        </View>
        <View style={styles.container}>
          <Text>How would you like modify.</Text>
          <Text>user?.name</Text>
        </View>
      </Page>
    </Document>
  )
};

export default MyDocument;

