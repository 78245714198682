import HasPagination from "interfaces/HasPagination"
import IPaginatedListResult from "interfaces/IPaginatedListResult"
import { IRadarHistory } from "interfaces/user/radarHistory/interface.radarHistory"

export interface ICompanyRadarHistoriesResponse extends IPaginatedListResult<IRadarHistory> { }

interface ICompanyCandidatePoolVariables
  extends HasPagination {}

export const company_radarHistories = (variables: ICompanyCandidatePoolVariables) => {
  const data = JSON.stringify({
    query: `
      query company_radarHistories($pagination: Pagination) {
        company_radarHistories(pagination: $pagination) {
          count
          data {
            _id
            candidate {
              _id
              personalInformation {
                name
                surname
                country
                city
                district
                profileImageUrl
              }
              createdAt
              updatedAt
            }
            company {
              _id
            }
            createdAt
            updatedAt
          }
        }
      }
          `,
    variables: variables

  })

  return data

}