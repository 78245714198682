
export interface IAdvertisementImageData {
  url: string;
  presignedUploadUrl: string;
}

export interface IAdvertisementImagePresignedUrlListResponse extends Array<IAdvertisementImageData> {}


export interface IAdvertisementImagePresignedUrlListVariables {
  imageCount: number
  contentType: string
}


export const advertisementImagePresignedUrlList = (variables: IAdvertisementImagePresignedUrlListVariables) => {

  const data = JSON.stringify({
    query: `query AdvertisementImagePresignedUrlList($contentType: ImageContentType!, $imageCount: Int!) {
      advertisementImagePresignedUrlList(contentType: $contentType, imageCount: $imageCount) {
        url
        presignedUploadUrl
      }
    }`,
    variables: {
      "contentType": variables.contentType,
      "imageCount": variables.imageCount
    }
  })

  return data;
}