import HasFilter from "interfaces/HasFilter";
import { HasId } from "interfaces/HasId"
import HasPagination from "interfaces/HasPagination";
import IPaginatedListResult from "interfaces/IPaginatedListResult";
import { IFilterCompanyCandidatePool } from "interfaces/user/ozgelecek/ozgelecek/IFilterOzgelecek";
import { IOzgelecek, IOzgelecekForCandidatePool } from "interfaces/user/ozgelecek/ozgelecek/IOzgelecek";

export interface ICompanyMatchingOzgeleceksResponse extends IPaginatedListResult<IOzgelecekForCandidatePool> { }

export interface ICompanyMatchingOzgeleceksVariables extends HasPagination, HasFilter<IFilterCompanyCandidatePool> { }


export const company_matchingOzgeleceks = (variables: ICompanyMatchingOzgeleceksVariables) => {
  const data = JSON.stringify({
    query: `
      query company_matchingOzgeleceks($filter: FilterOzgelecekForCompany) {
        company_matchingOzgeleceks(filter: $filter) {
          _id
          candidate {
            _id
            personalInformation {
              name
              surname
              phoneNumber
              sex
              city
              district
            }
          }
          
          field
          testResults {
            areaTestScore
            motivationTestScore
            areaTestResults {
              question {
                _id
              }
              answer
            }
            motivationTestResults {
              answer
            }
          }
          ozgelecekTest {
            first {
              video
              text
            }
            second {
              video
              text
            }
          }
          references {
            _id
          }
          createdAt
          updatedAt
        }
      }
          `,
    variables: variables

  })

  return data

}