import { Helmet } from 'react-helmet-async';
import { CompactLayout } from 'layouts';
import { ResetPasswordContainer } from './ResetPasswordContainer';


export const ResetPasswordPage = () => {
  return (
    <CompactLayout>
      <Helmet>
        <title> New Password </title>
      </Helmet>
      <ResetPasswordContainer />
    </CompactLayout>
  );
}
