import { getOnboardingStatus, loginReducer2, useAppDispatch, useAppSelector } from 'context';
import { useEffect } from 'react';
import { BrowserRouter, Routes } from 'react-router-dom';
import { AnonymousRouter, AuthRouter, candidateRouter, companyRouter } from 'routes';
import { referencerRouter } from 'routes/ReferencerRouter';
import { TOnboardingStatus, TUserType } from 'types'

interface IAuthState {
    userType: TUserType,
    isAuth: boolean,
    isOnboarding: TOnboardingStatus,
    device?: string
}

export function AppRouter() {

    const dispatch = useAppDispatch()
    const {
        userType,
        isOnboarding,
        device
    }: IAuthState = useAppSelector(state => state?.auth)

    console.log(userType, 'userType', isOnboarding, 'isOnboarding')



    // const userType: TUserType = "company";
    // const isOnboarding: TOnboardingStatus = "completed";

    // console.log({
    //     userType,
    //     isOnboarding,
    // });


    //mobile settings
    let count = 1;

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const accessToken = urlParams.get('accessToken');
        const role = urlParams.get('role');

        if (accessToken && !device && count === 1) {
            dispatch(loginReducer2({
                data: {
                    accessToken: accessToken,
                    role: role
                }
            }))

            count = 2
        }
    }, [dispatch]);





    ///



    useEffect(() => {
        if (userType) {
            dispatch(getOnboardingStatus({ userType }))
        }
    }, [dispatch, userType])


    const renderUserTypeRouter = () => {

        switch (userType) {
            case "candidate":
                return candidateRouter(isOnboarding);
            case "company":
                return companyRouter(isOnboarding);
            default:
                return AuthRouter()
        }

    }


    return (
        <BrowserRouter>
            <Routes>
                {referencerRouter()}
                {isOnboarding !== "error" && renderUserTypeRouter()}
                {AnonymousRouter()}
            </Routes>
        </BrowserRouter>
    );
}
