import { HasId } from "interfaces/HasId"

export type ICompanyMatchingOzgelecekPriceResponse = any

export interface ICompanyMatchingOzgelecekPriceVariables {}


export const company_matchingOzgelecekPrice = (variables: ICompanyMatchingOzgelecekPriceVariables) => {
  const data = JSON.stringify({
    query: `
      query company_matchingSystemUsagePrice{
        company_matchingSystemUsagePrice
      }
          `,
    variables: variables

  })

  return data

}