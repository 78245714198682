import { useState, useEffect } from "react"
import { Box, Button, Card, Grid, Pagination, Stack, Typography, CircularProgress } from "@mui/material"
import { Iconify } from "components"
import {
    useAppSelector,
    useAppDispatch,
    useGetUserTestQuestionsQuery,
    setCandidateCreateOzgelecekFormIndex,
    setCandidateCreateOzgelecekQuestionIndex,
    setCandidateCreateOzgelecekFormFieldTestResults,
    setCandidateCreateOzgelecekFormMotivationTestResults,
    IUserTestQuestionsItem,
} from "context"
import { OzgelecekFieldEnum, getNameOfEnum } from "types"



export const OzgelecekFieldAndMotivationTestForm = ({ questionType = "area", title, field, setEndTimeDialog }: any) => {

    const dispatch = useAppDispatch()
    const [testStarted, setTestStarted] = useState(false)
    const { values, questionIndex, formIndex } = useAppSelector(state => state.candidateCreateOzgelecek)
    const [timer, setTimer] = useState(300);


    const { data: questions, isLoading, isFetching } = useGetUserTestQuestionsQuery({
        filter: {
            "field": field,
            "questionType": questionType,
        },
        pagination : {
            "sort": "indexInc"
        }

    })


    // console.log(formIndex)
    // console.log(questionIndex)
    const handleFinishTest = () => {
        dispatch(setCandidateCreateOzgelecekFormIndex(formIndex + 1))
        dispatch(setCandidateCreateOzgelecekQuestionIndex(1))
    }


    // const handleBackStep = () => {
    //     dispatch(setCandidateCreateOzgelecekQuestionIndex(questions.count))
    //     dispatch(setCandidateCreateOzgelecekFormIndex(formIndex - 1))
    // }

    const handleStartTest = async () => {
        setTestStarted(true)
    }

    useEffect(() => {
        if (testStarted) {
            const intervalId = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer > 0) {
                        if ((questionIndex === 26 && formIndex === 2) || (questionIndex === 26 && formIndex === 3)) {
                            return 300;
                        }
                        return prevTimer - 1;
                    } else {
                        setEndTimeDialog(true)
                        clearInterval(intervalId);
                        return prevTimer;
                    }
                });
            }, 1000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [testStarted, setEndTimeDialog, questionIndex, formIndex]);


    return (
        <Box>

            <Typography fontSize={"medium"} fontWeight={"bold"} mb={1} mt={1} textAlign={"center"}>
                <i>{getNameOfEnum(OzgelecekFieldEnum, field)}</i>
            </Typography>
            <Typography variant='h2' mb={1} mt={1} textAlign={"center"}>
                {questionIndex === 26 && formIndex === 2 ? "Motivasyon Testi" : title}
            </Typography>
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} mt={2} >
                <Typography variant='h6' textAlign={"center"} width={100} bgcolor={"primary.main"} color={"white"} py={0.5} px={3} >
                    {Math.floor(timer / 60)}:{timer % 60 < 10 ? '0' : ''}{timer % 60}
                </Typography>
            </Box>
            {
                (isLoading || isFetching) ? (
                    <Grid item xs={12}>
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            height="100%"
                            py="100px"
                        >
                            <CircularProgress color='primary' />
                        </Stack>
                    </Grid>
                ) : !!testStarted ? (
                    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                        {/* <Box
                            sx={{
                                display: 'flex',
                                p: 2,
                                gap: 1,
                                flexWrap: 'wrap'
                            }}
                        >
                            {
                                questions?.data?.map((item: any, index: any) => {
                                    const list = questionType === 'area'
                                        ? values?.testResults.areaTestResults
                                        : values?.testResults.motivationTestResults
                                    const isAnswered = list?.find((i: any) => i?.question?._id === item?._id)
                                    return (
                                        <IconButton key={index}
                                            sx={{
                                                flexShrink: 1,
                                                // flexGrow: 1,
                                                // flex: 1,
                                                width: '40px',
                                                height: '40px',
                                                backgroundColor: questionIndex === index ? 'primary.main' : isAnswered ? 'secondary.main' : 'secondary.light',
                                            }}
                                            onClick={() => dispatch(setCandidateCreateOzgelecekQuestionIndex(index))}
                                        >
                                            {index + 1}
                                        </IconButton>
                                    )
                                })
                            }
                        </Box> */}

                        <Pagination
                            disabled={true}
                            color="primary"
                            count={questions?.count}
                            variant="outlined" shape="rounded"
                            boundaryCount={3}
                            sx={{
                                my: 5, alignSelf: 'center',
                                "& .Mui-disabled": { color: 'black' }
                            }}
                            page={questionIndex}
                            onChange={(e, index) => {
                                dispatch(setCandidateCreateOzgelecekQuestionIndex(index))
                            }}
                        />


                        <Box width={"100%"} >
                            <FieldAndMotivationTestQuestionItem
                                question={questions?.data[questionIndex - 1]}
                                questionType={questionType}
                            />
                        </Box>


                        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" gap={3}>
                            {/* <Button
                                variant='outlined'
                                color="primary"
                                onClick={handleBackStep}
                            >
                                Geri
                            </Button> */}

                            <Button
                                variant='contained'
                                color="primary"
                                sx={{ pl: 5, pr: 4 }}
                                disabled={
                                    questionType === "area"
                                        ? questions?.data?.length !== values?.testResults?.areaTestResults?.length
                                        : questions?.data?.length !== values?.testResults?.motivationTestResults?.length
                                }
                                onClick={handleFinishTest}
                            >

                                İleri

                                <Iconify icon="mi:next" sx={{ ml: 1, mt: 0.2 }} />
                            </Button>
                        </Stack>
                    </Box>
                ) : (
                    <Card
                        sx={{
                            p: { xs: 2, sm: 4 },
                            my: 3,
                            width: '100%',
                        }}
                    >
                        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                            <Box bgcolor={"secondary.lighter"} p={4} mt={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                                <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}><Iconify icon={"material-symbols:info-outline"} /></Box>
                                <Typography variant="body2" >
                                    Bu sayfada seçtiğiniz alanla ilgili 25 soruluk bir test bulunmaktadır. Her soruya cevap vermek zorunludur. Soruları cevaplarken kendinizi en iyi şekilde ifade etmeye çalışınız.
                                </Typography>
                            </Box>
                            <Button
                                sx={{ mt: 5 }}
                                variant='contained'
                                color="primary"
                                onClick={() => handleStartTest()}
                            >
                                Teste Başla
                            </Button>
                        </Box>
                    </Card>
                )
            }
        </Box>
    )
}

const FieldAndMotivationTestQuestionItem = ({ question, questionType }: { question: IUserTestQuestionsItem | any, questionType: string }) => {

    const dispatch = useAppDispatch()
    const { values, questionIndex } = useAppSelector(state => state.candidateCreateOzgelecek)

    const handleClickToAnswer = (answer: any) => {
        if (questionType === "area") {
            dispatch(setCandidateCreateOzgelecekFormFieldTestResults({ question, answer: (answer === 1 ? 0 : answer === 2 ? 0 : answer === 3 ? 2 : answer === 4 ? 4 : 0) }))
        } else {
            dispatch(setCandidateCreateOzgelecekFormMotivationTestResults({ question, answer: (answer === 1 ? 0 : answer === 2 ? 0 : answer === 3 ? 2 : answer === 4 ? 4 : 0) }))
        }
        dispatch(setCandidateCreateOzgelecekQuestionIndex((questionIndex + 1)))

        console.log(answer === 1 ? 0 : answer === 2 ? 0 : answer === 3 ? 2 : answer === 4 ? 4 : 0)
    }


    const renderAnswers = (index: number) => {

        // if (!question?.isReverseTest) {
        switch (index) {
            case 1: return "Kesinlikle katılmıyorum."
            case 2: return "Katılmıyorum."
            case 5: return "Kararsızım."
            case 3: return "Katılıyorum."
            case 4: return "Kesinlikle katılıyorum."
            default: return ""
        }
        // } else {
        //     switch (index) {
        //         case 5: return "Kesinlikle katılmıyorum."
        //         case 4: return "Katılmıyorum."
        //         case 3: return "Kararsızım."
        //         case 2: return "Katılıyorum."
        //         case 1: return "Kesinlikle katılıyorum."
        //         default: return ""
        //     }
        // }
    }



    return (
        <Card
            sx={{
                p: { xs: 2, sm: 4 },
                my: 3,
                width: '100%',
            }}
        >
            {!!question ? (
                <>
                    <Typography variant='h5' mb={2} textAlign={"center"}>
                        {question?.title}
                    </Typography>
                    <Typography variant='h6' mb={2} minHeight={70} textAlign={"center"} color="GrayText">
                        {question?.content}
                    </Typography></>) : null}

            {
                question?.content ? (
                    <Grid container spacing={3} my={2}>
                        {
                            [1, 2, 3, 4]?.map((item, index) => {

                                const fieldTestResults =
                                    questionType === "area"
                                        ? values?.testResults?.areaTestResults
                                        : values?.testResults?.motivationTestResults

                                const findedItem = fieldTestResults?.find((item: any) => item?.question?._id === question?._id)

                                return (
                                    <Grid item key={index} xs={6} sm={12 / 4}>
                                        <Button
                                            sx={{
                                                flexShrink: 1,
                                                width: '100%',
                                                height: '60px',
                                                backgroundColor: (findedItem && (findedItem?.answer === item)) ? 'primary.main' : 'secondary.light'
                                            }}
                                            onClick={() => handleClickToAnswer(!question?.isReverseTest ? item : (item === 1 ? 4 : item === 2 ? 3 : item === 3 ? 2 : item === 4 ? 1 : 0))}
                                        >
                                            <Typography fontWeight={'bold'} >{renderAnswers(item)}</Typography>
                                        </Button>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                ) :
                    <>

                        {
                            questionType !== "area" ? (
                                <Typography variant='h6' mb={2} textAlign={"center"}>
                                    {"Testi bitirdiniz. Sonraki adıma geçerek  ilerleyebilirsiniz."}
                                </Typography>
                            ) : (
                                <Box bgcolor={"secondary.lighter"} p={4} mt={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                                    <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}><Iconify icon={"material-symbols:info-outline"} /></Box>
                                    <Typography variant="body2" >
                                        Bu sayfada seçtiğiniz alanla ilgili 25 soruluk bir motivasyon test bulunmaktadır. Her soruya cevap vermek zorunludur.
                                        Soruları cevaplarken kendinizi en iyi şekilde ifade etmeye çalışınız.
                                    </Typography>
                                </Box>
                            )
                        }


                    </>

            }
        </Card>
    )
}