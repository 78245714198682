import * as yup from "yup"
import { Formik } from 'formik'
import { Button, Card, Grid, Stack, Typography } from '@mui/material'

import { ILanguage } from 'types'
import { FormikSelect, FormikTextField } from 'components'
import { languageLevelsList } from "types"




const validationSchema = yup.object().shape({
    name: yup.string().required("İsim gerekli!"),
    reading: yup.string().required("Okuma seviyesi gerekli!"),
    writing: yup.string().required("Yazma seviyesi gerekli!"),
    listening: yup.string().required("Dinleme seviyesi gerekli!"),
})





interface ILanguageFormProps {
    onSubmit: (e: ILanguage) => any,
    initialValues?: ILanguage
}


export const LanguageForm = ({ onSubmit, initialValues }: ILanguageFormProps) => {

    const defaultValues = initialValues ?? {
        name: "",
        writing: "",
        reading: "",
        listening: "",
    }

    return (
        <Formik
            initialValues={defaultValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
                onSubmit(values)
                resetForm()
            }}
        >
            {
                ({ handleSubmit }) => {
                    return (
                        <Card sx={{ width: '100%', p: 3 }}>
                            <Grid container spacing={3} >

                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        Dil Bilgileri
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormikTextField
                                        name="name"
                                        label="Dil Adı"
                                        upperCase
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormikSelect
                                        name="writing"
                                        label="Yazma Seviyesi"
                                        list={languageLevelsList}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormikSelect
                                        name="listening"
                                        label="Dinleme Seviyesi"
                                        list={languageLevelsList}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormikSelect
                                        name="reading"
                                        label="Okuma Seviyesi"
                                        list={languageLevelsList}
                                    />
                                </Grid>


                                <Grid item xs={12} >
                                    <Stack direction="row" justifyContent="end">
                                        <Button
                                            onClick={() => handleSubmit()}
                                            variant="outlined"
                                            color="primary"
                                        >
                                            {initialValues ? "Güncelle" : "Ekle"}
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Card>
                    )
                }
            }
        </Formik>
    )
}