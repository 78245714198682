import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL, getAccessToken } from 'utils'


export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/user/graphql`,
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + getAccessToken(),
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },

    }),

    tagTypes: [
        "candidate",
        "candidate_reference",
        "candidate_references",
        "candidate_ozgelecek",
        "candidate_ozgeleceks",
        "candidate_application",
        "candidate_applications",
        "candidate_advertisement",
        "candidate_advertisements",
        "get_advertisement_cost",

        "company_advertisement",
        "company_advertisements",
        "company_application",
        "company_applications",
        "company_custom_application_list",

        "company_candidate",
        "company_candidatePool",
        "company_candidatePoolPrice",

        "company_matchingOzgelecekCount",
        "company_ozgeleceks",

        "company_radarHistory",
        "company_radarHistories",

        "company_references",

        "referencer",
        "hrAdvisor_discoverForms"

    ],

    endpoints: (builder) => ({}),

})
