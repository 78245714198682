import { useFormikContext } from 'formik'
import { Button, Card, Grid, Stack, FormControl, InputLabel, Select, MenuItem, OutlinedInput, Box } from '@mui/material'

import { ExtraProductList, LanguageList, OzgelecekFieldsList, PlaceOfWorkList, PositionLevelList, ProductTypeList, WorkTypeList } from 'types'
import { ICreateAdvertisementForm } from 'lib/form-validation'
import { FormikCheckbox, FormikAutoComplete, FormikAutoComplete2, FormikSelect, FormikTextField, FormikQuill, FormikAutoComplete3, FormikAutoComplete4 } from 'components'
import { useGetCitiesQuery, useAppSelector, useAppDispatch, setAdvertisementFormIndex } from 'context'
import { useEffect, useState } from 'react'



export const CreateAdvertisementOnboardingSecond = () => {

    const dispatch = useAppDispatch()
    const { data: citiesData } = useGetCitiesQuery({ countryName: "Turkey" })
    const { setFieldValue, values, handleSubmit } = useFormikContext<ICreateAdvertisementForm>()
    const { advertisementId, advertisementFormIndex } = useAppSelector((state) => state.companyCreateAdvertisement)
    const [statusReady, setStatusReady] = useState(false)
    const [locationCount, setLocationCount] = useState(1)

    const handleNextStep = () => {
        setFieldValue("advertisementId", advertisementId);
        if ((values?.status === "pendingApproval")) setFieldValue("status", "pending")
        else if (values?.status === "approved") setFieldValue("status", "pendingApproval")
        else if (values?.status === "onboardingThirdCompleted") setFieldValue("status", "onboardingThirdCompleted")
        else if (values?.status === "onboardingForthCompleted") setFieldValue("status", "onboardingForthCompleted")
        else setFieldValue("status", "onboardingSecondCompleted")

        setStatusReady(true)
    }
    const handleBackStep = () => {
        dispatch(setAdvertisementFormIndex(advertisementFormIndex - 1))
    }

    useEffect(() => {
        if (statusReady) {
            handleSubmit();
        }
    }, [statusReady])

    useEffect(() => {

        console.log(values?.locations?.length, 'values?.locations')
        if (values?.locations && values?.locations?.length > 1) setLocationCount(values?.locations?.length)
        else setLocationCount(1)
    }, [values?.locations])

    if (!values?.productType) {
        return null
    } else return (
        <Card sx={{ p: 3 }}>


            <Grid container spacing={3}>

                {/* <Grid item xs={12}>
                    <Typography variant='h3'>
                        Onboarding Second
                    </Typography>
                </Grid> */}


                <Grid item xs={12} sm={6}>
                    <FormikSelect list={OzgelecekFieldsList} name='field' label="İlan Sektörü / Alanı" />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormikTextField upperCase name='department' label="Departman" />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormikSelect list={LanguageList} name='language' label="İlan Dili" />
                </Grid>


                <Grid item xs={12} sm={6}>
                    <FormikSelect list={PlaceOfWorkList} name='place' label="Çalışma Konumu" />
                </Grid>

                <Grid item xs={12}>
                    {/* <FormikTextField
                        multiline
                        upperCase
                        minRows={4}
                        label="İş Tanımı ve Genel Nitelikler"
                        name='description'
                    /> */}
                    <FormikQuill
                        name='description'
                        label="İş Tanımı ve Genel Nitelikler"
                    />

                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormikSelect list={WorkTypeList} name='workType' label="Çalışma Şekli" />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormikSelect list={PositionLevelList} name='positionLevel' label="Pozisyon Seviyesi" />
                </Grid>


                <Grid item xs={12} sm={6}>
                    <FormikSelect
                        list={citiesData?.data ?? []}
                        name="locations[0].city"
                        label="Geçerli Şehir"
                    />
                    {/* <FormikAutoComplete4
                        name="locations.city"
                        label="Geçerli Şehirler"
                        list={citiesData?.data ?? []}
                    /> */}
                </Grid>

                <Grid item xs={12} sm={6}>
                    {/* <FormikSelect
                        name="district"
                        label="Geçerli İlçe"
                        list={citiesData?.data?.find((item) => item.name === values.city)?.districts ?? []}
                    /> */}
                    {/* <FormikAutoComplete3
                        name="districts"
                        label="Geçerli İlçe"
                        list={values.cities?.map(city => {
                            const cityData = citiesData?.data?.find(item => item.name === city);
                            return cityData ? cityData.districts : [];
                        }).flat() ?? []} /> */}
                    <FormikAutoComplete2
                        name="locations[0].districts"
                        label="Geçerli İlçeler"
                        list={citiesData?.data?.find(item => item.name === values?.locations?.[0]?.city)?.districts ?? []}
                    />
                </Grid>

                {
                    locationCount > 1 && Array.from({ length: locationCount - 1 }).map((_, index) => (
                        <>
                            <Grid item xs={12} sm={6}>
                                <FormikSelect
                                    list={citiesData?.data ?? []}
                                    name={`locations[${index + 1}].city`}
                                    label="Geçerli Şehir"
                                />
                            </Grid>
                            <Grid item xs={12} sm={5} key={index}>
                                <FormikAutoComplete2
                                    name={`locations[${index + 1}].districts`}
                                    label="Geçerli İlçeler"
                                    list={citiesData?.data?.find(item => item.name === values?.locations?.[index + 1]?.city)?.districts ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} sm={1}>
                                <Button variant="outlined" color="error" onClick={() => {
                                    setLocationCount(locationCount - 1)
                                    setFieldValue(`locations[${index + 1}]`, null)
                                }}>Sil</Button>
                            </Grid>

                        </>

                    ))
                }
                <Grid item xs={12}>
                    <Button variant="outlined" color="primary" onClick={() => setLocationCount(locationCount + 1)}>Yeni Lokasyon Ekle</Button>
                </Grid>

                <Grid item xs={12}>

                    <Stack direction="row" spacing={2} justifyContent="space-between">
                        <Button
                            sx={{
                                width: 150,
                            }}
                            variant='outlined'
                            color='primary'
                            onClick={handleBackStep}
                        >
                            Geri
                        </Button>

                        <Button
                            color="primary"
                            variant='contained'
                            sx={{
                                width: 150,
                            }}
                            onClick={handleNextStep}
                            disabled={
                                !values.locations ||
                                !values.department ||
                                !values.description ||
                                !values.field ||
                                !values.language ||
                                !values.place ||
                                !values.positionLevel ||
                                !values.workType
                            }
                        >
                            İleri
                        </Button>
                    </Stack>
                </Grid>


            </Grid>
        </Card>
    )
}
