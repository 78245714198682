import axios from 'axios';
import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { Typography, Stack, Link, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';

import { routes } from 'routes';
import { EmailInboxIcon } from 'assets';
import { Iconify, FormProvider, RHFCode, MuiTransition } from 'components';

// ----------------------------------------------------------------------


const VerifySchema = Yup.object().shape({
  code: Yup.string().min(6, 'En az 6 karakter içermeli!').required('Gerekli!'),
  // email: Yup.string()
  // .required('Email is required')
  // .email('Email must be a valid email address'),
});

export default function ClassicVerifyView() {

  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [email, setEmail] = useState("")
  const [resentCodeDialog, setResentCodeDialog] = useState(false)


  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(VerifySchema),
    defaultValues: {
      code: '',
    },
  });

  const { handleSubmit, formState: { isSubmitting } } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/auth/auth/confirmRegistration`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          "code": data?.code,
          "token": token
        })
      };

      const response = await axios.request(config)

      console.log(response.data)

      if (response?.data?.isSuccess) {
        enqueueSnackbar('Email başarılı bir şekilde onaylanmıştır!');
        setTimeout(() => {
          navigate(routes.auth.login)
        }, 1000)
      } else {
        enqueueSnackbar('Birşeyler yanlış gitti!', { variant: "error" })
      }


    } catch (error: any) {
      console.error(error);

      if (error?.response?.data?.message === "'Token' not found.") {
        enqueueSnackbar('Token bulunamadı!', { variant: "warning" })
      } else if (error?.response?.data?.message === "jwt malformed") {
        enqueueSnackbar('Token hatalı!', { variant: "warning" })
      } else if (error?.response?.data?.message === "Code format is not valid") {
        enqueueSnackbar('Kod hatalı!', { variant: "warning" })
      } else if (error?.response?.data?.message === "Registration confirmation information is not valid") {
        enqueueSnackbar('Kod hatalı!', { variant: "warning" })
      } else if (error?.response?.data?.message === "RegistrationAlreadyConfirmed") {
        enqueueSnackbar('Email zaten onaylanmıştır!', { variant: "info" })
        setTimeout(() => {
          navigate(routes.auth.login)
        }, 1000)
      } else {
        enqueueSnackbar('Birşeyler yanlış gitti!', { variant: "error" })
      }
    }
  });


  const handleResendCode = async () => {
    try {

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/auth/auth/resendRegistrationConfirmation`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({ email })
      };

      const response = await axios.request(config)

      console.log(response.data)
      if (response?.data?.isSuccess) {
        enqueueSnackbar('Email başarılı bir şekilde gönderilmiştir.');
        setResentCodeDialog(false)
        setEmail("")
      } else {
        enqueueSnackbar('Birşeyler yanlış gitti!', { variant: "error" })
      }

    } catch (error: any) {
      console.log({ error });
      if (error?.response?.data?.message === "'Token' not found.") {
        enqueueSnackbar('Token bulunamadı!', { variant: "warning" })
      } else if (error?.response?.data?.message === "jwt malformed") {
        enqueueSnackbar('Token hatalı!', { variant: "warning" })
      } else if (error?.response?.data?.message === "Code format is not valid") {
        enqueueSnackbar('Kod hatalı!', { variant: "warning" })
      } else if (error?.response?.data?.message === "RegistrationAlreadyConfirmed") {
        enqueueSnackbar('Email zaten onaylanmıştır!', { variant: "info" })
        setTimeout(() => {
          navigate(routes.auth.login)
        }, 1000)
      } else if (error?.response?.data?.message === "RequestValidationError") {
        error?.response?.data?.errors?.forEach((item: { msg: string; }) => {
          if (item.msg === "Email is not valid") {
            enqueueSnackbar('Email bulunamadı!', { variant: "warning" })
          } else if (item.msg === "RegistrationAlreadyConfirmed") {
            enqueueSnackbar('Email zaten onaylanmış!', { variant: "info" })
          }
        })

      } else {
        enqueueSnackbar('Birşeyler yanlış gitti!', { variant: "error" })
      }

      setResentCodeDialog(false)
      setEmail("")
    }
  }

  const renderForm = (
    <Stack spacing={3} alignItems="center">

      <RHFCode name="code" />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Onayla
      </LoadingButton>

      <Typography variant="body2" >
        Kod gelmedi mi? &nbsp;
        <Link
          variant="subtitle2"
          onClick={() => setResentCodeDialog(true)}
          sx={{ cursor: 'pointer' }}
        >
          Tekrar gönder
        </Link>
      </Typography>

      <Link
        component={RouterLink}
        to={routes.auth.login}
        color="inherit"
        variant="subtitle2"
        sx={{
          mx: 'auto',
          alignItems: 'center',
          display: 'inline-flex',
        }}
      >
        <Iconify icon="eva:chevron-left-fill" width={16} />
        Giriş sayfasına git
      </Link>

    </Stack>
  );

  const renderHead = (
    <>
      <EmailInboxIcon sx={{ height: 96 }} />
      <Stack spacing={1}>
        <Typography variant="h3" paragraph>
          Lütfen email adresinizi kontrol ediniz!
        </Typography>
        <Typography sx={{ color: 'text.secondary', mb: 5 }}>
          Email hesabınıza 6 haneli onaylama kodu gönderdik.
          Lütfen hesabınızı onaylamak için,
          gelen kodu giriniz.
        </Typography>
      </Stack>
    </>
  );

  const renderPopup = (
    <Dialog
      open={resentCodeDialog}
      TransitionComponent={MuiTransition}
      keepMounted
      onClose={() => setResentCodeDialog(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Onaylama kodunu tekrar gönder!</DialogTitle>
      <DialogContent >
        <Stack sx={{ pt: 3 }}>

          <TextField
            name="email"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="example@gmail.com"
            InputLabelProps={{ shrink: true }}
          />

        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setResentCodeDialog(false)}>Kapat</Button>
        <Button
          onClick={handleResendCode}
          variant='contained'
          disabled={!email}
        >
          Gönder
        </Button>
      </DialogActions>
    </Dialog>
  )
  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {renderHead}

      {renderForm}

      {renderPopup}
    </FormProvider>
  );
}
