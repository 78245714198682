


export interface ICompanyAddExtraProductyToAdvertisementResponse {
  company_addExtraProductToAdvertisement: {
    success: boolean
  }
}

export interface ICompanyAddExtraProductyToAdvertisementVariables {
  advertisementId: string,
  extraProducts: Array<string>
}


export const company_addExtraProductToAdvertisement = (variables: ICompanyAddExtraProductyToAdvertisementVariables) => {

  const data = JSON.stringify({
    query: `mutation Company_addExtraProductToAdvertisement($advertisementId: ID!, $extraProducts: [ExtraProductType]!) {
          company_addExtraProductToAdvertisement(advertisementId: $advertisementId, extraProducts: $extraProducts) {
            success
          }
        }`,
    variables: {
      "extraProducts": variables.extraProducts,
      "advertisementId": variables.advertisementId,
    }
  })

  return data

}