import { Box, Link, Stack, Typography } from '@mui/material'
import { DashboardLayout } from 'layouts'
import { ICandidateAdvertisementsVariables, setCandidateCreateOzgelecekFormField, useAppDispatch, useCandidateCreateApplicationMutation, useCandidateRemoveApplicationMutation, useGetCandidateAdvertisementsQuery, useGetCandidateCompanyQuery, useGetCandidateOzgeleceksQuery } from 'context'


import WhatshotOutlinedIcon from '@mui/icons-material/WhatshotOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import moment from 'moment';
import { enumFunctions } from 'utils';
import { NumberOfEmployeeEnum } from 'types';
import { CandidateAdvertisementCard, CreateApplicationDialog, Iconify } from 'components';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'routes';
import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { Email, Facebook, Instagram, LinkedIn, Phone } from '@mui/icons-material';



export const CandidateAboutCompanyPage = () => {

    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [applyDialog, setApplyDialog] = useState<any>(null)
    const [rejectApplicationDialog, setRejectApplicationDialog] = useState<any>(null)

    const [variables] = useState<ICandidateAdvertisementsVariables>({
        filterOption: {
            filter: {
                companyId: id ?? "",
            },

        },
        pagination: {
            page: 1,
            pageSize: 3,
        }
    })
    const { data: company, isLoading } = useGetCandidateCompanyQuery({ companyId: id ?? "" })
    const { data: advertisements, isLoading: advertisementsLoading } = useGetCandidateAdvertisementsQuery(variables)
    const [createApplication, { isLoading: createApplicationLoading }] = useCandidateCreateApplicationMutation()
    const [candidateRemoveApplication, { isLoading: rejectApplicationLoading }] = useCandidateRemoveApplicationMutation()
    const { data: ozgeleceks } = useGetCandidateOzgeleceksQuery({})



    const handleCreateApplication = async () => {

        try {
            const selectedOzgelecek: any = ozgeleceks?.data?.find((item: any) => item?.field === applyDialog?.field)

            const response: any = await createApplication({ advertisement: applyDialog, ozgelecek: selectedOzgelecek })
            console.log({ response });

            if (response?.data?.data?.candidate_createApplication?._id) {
                enqueueSnackbar("Başvuru başarılı bir şekilde yapıldı!")
                setApplyDialog(null)
            }
            if (response?.data?.errors) {
                if (response?.data?.errors?.[0]?.message === "AlreadyApplied") {
                    enqueueSnackbar("Bu ilana zaten başvuru yaptınız!", { variant: "warning" })
                    setApplyDialog(null)
                } else {
                    enqueueSnackbar("İlana başvururken bir hata oluştu.", { variant: "error" })
                    setApplyDialog(null)
                }
            }
        } catch (error) {
            console.log({ error });
        }
    }

    const handleRejectApplication = async () => {
        try {
            const response: any = await candidateRemoveApplication({ applicationId: rejectApplicationDialog?._id })
            console.log({ response });
            if (response?.data?.success) {
                enqueueSnackbar("Başarılı şekilde iptal edildi!")
            } else {
                enqueueSnackbar("Birşeyler yanlış gitti", { variant: 'info' })
            }
        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti", { variant: 'error' })
        }
    }


    console.log({ company, advertisements, id });

    const HeaderItem = ({ icon, name, value }: { icon: any, name: string, value: any }) => {
        return (
            <Stack direction="row" spacing={2} alignItems="center">

                <Box
                    sx={{
                        border: '1px solid #d6ddeb',
                        borderRadius: '50%',
                        flexShrink: '0',
                        width: '50px',
                        height: '50px',
                        p: 1,

                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'primary.main',

                        '&>*': {
                            width: '100%',
                            height: '100%',
                        }
                    }}
                >
                    {icon}
                </Box>

                <Stack>
                    <Typography variant='body2'>
                        {name}
                    </Typography>
                    <Typography variant='subtitle2'>
                        {value}
                    </Typography>
                </Stack>

            </Stack>
        )
    }

    const WidgetItem = ({ icon, name, value }: { icon: any, name: string, value: any }) => {
        return (
            <Stack direction="row" spacing={2} alignItems="center">

                <Box
                    sx={{
                        border: '1px solid #d6ddeb',
                        borderRadius: '50%',
                        flexShrink: '0',
                        width: '40px',
                        height: '40px',
                        p: 1,

                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'primary.main',

                        '&>*': {
                            width: '100%',
                            height: '100%',
                        }
                    }}
                >
                    {icon}
                </Box>

                <Stack>
                    <Typography variant='body2'>
                        {name}
                    </Typography>
                    <Typography variant='subtitle2'>
                        {value}
                    </Typography>
                </Stack>

            </Stack>
        )
    }

    return (
        <DashboardLayout>

            <Stack direction="row" justifyContent="space-between">
                <Typography variant='h1'>
                    Şirket Bilgileri
                </Typography>

                {/* <Button
                    variant='contained'
                    color="primary"
                >
                    Güncelle
                </Button> */}
            </Stack>

            <Box>
                {
                    isLoading ? (
                        <Box>Loading...</Box>
                    ) : (
                        <Stack
                            spacing={3}
                            sx={{
                                py: 3
                            }}
                        >

                            {/* ******************* Header ********************* */}
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                spacing={3}
                                sx={{
                                    border: '1px solid #d6ddeb',
                                    p: 3,
                                    backgroundColor: '#fff',
                                    transition: 'all 0.3s ease',
                                    position: 'relative',
                                    // cursor: 'pointer',
                                    // ":hover": {
                                    //     boxShadow: '0 0 10px 0 #d6ddeb'
                                    // },
                                }}
                            >


                                <Box
                                    component="img"
                                    // src='https://cdn-icons-png.flaticon.com/512/5650/5650380.png'
                                    src={company?.companyInformation?.profileImage}
                                    sx={{
                                        width: { xs: '100px' },
                                        height: { xs: '100%' },
                                    }}
                                />

                                <Stack spacing={3}>

                                    <Stack>
                                        <Typography variant='h3'>
                                            {company?.companyInformation?.companyName}
                                        </Typography>
                                        <Typography component={Link} href={"/" + company?.companyInformation?.website} variant='body2'>
                                            {company?.companyInformation?.website}
                                        </Typography>
                                    </Stack>

                                    <Stack direction={{ xs: 'column', sm: 'row' }} flexWrap="wrap" spacing={2}>
                                        <HeaderItem
                                            icon={<WhatshotOutlinedIcon />}
                                            name="Kuruluş Yılı"
                                            value={moment(company?.companyInformation?.foundationYear).format('DD MMMM YYYY')}
                                        />

                                        <HeaderItem
                                            icon={<GroupsOutlinedIcon />}
                                            name="Personel"
                                            value={enumFunctions.findAndDisplayName(NumberOfEmployeeEnum, (company?.companyInformation?.numberOfEmployees ?? "")?.toString())}
                                        />

                                        <HeaderItem
                                            icon={<RoomOutlinedIcon />}
                                            name="Konum"
                                            value={company?.companyInformation?.companyAddress}
                                        />

                                        <HeaderItem
                                            icon={<BusinessOutlinedIcon />}
                                            name="Sector"
                                            value={company?.companyInformation?.sector}
                                        />

                                    </Stack>

                                </Stack>

                            </Stack>



                            {/* ********************* Main ********************** */}

                            <Stack direction={{ xs: 'column-reverse', lg: "row" }} spacing={3}>

                                <Stack flex={1} position="relative" spacing={3} sx={{ height: '100%' }} >

                                    {/* <EditButton onClick={() => null} /> */}

                                    <Typography variant='h3'>
                                        Şirket Hakkında
                                    </Typography>

                                    <Typography>
                                        {company?.companyInformation.companyBrief}
                                    </Typography>


                                    {/* ***************** advertisements **************** */}
                                    <Box
                                        sx={{
                                            // pt: 5,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 3,
                                        }}
                                    >
                                        {
                                            advertisementsLoading ? (
                                                <>Loading...</>
                                            ) : (
                                                advertisements?.count ? (
                                                    advertisements?.data?.filter((advertisement: any) => advertisement?.companyInfoVisible === true)
                                                        .map((advertisement: any, index: number) => {
                                                            return (
                                                                <CandidateAdvertisementCard
                                                                    key={index}
                                                                    advertisement={advertisement}
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        // navigate(routes.candidate.dashboard.advertisementDetails + "/" + advertisement?._id)
                                                                        window.open(routes.candidate.dashboard.advertisementDetails + "/" + advertisement?._id, "_blank")
                                                                    }}
                                                                    onApply={(event: any) => {
                                                                        event.stopPropagation();
                                                                        setApplyDialog(advertisement)
                                                                    }}                                                           // logo={company?.companyInformation?.profileImage ?? ""}
                                                                />
                                                            )
                                                        })
                                                ) : (
                                                    <>
                                                        Hiçbir ilan bulunamadı.
                                                    </>
                                                )
                                            )
                                        }
                                    </Box>

                                </Stack>

                                {/* ********************* side info ********************** */}
                                <Stack
                                    direction={{ xs: 'column', sm: 'row', lg: 'column' }}
                                    spacing={3}
                                    sx={{
                                        border: '1px solid #d6ddeb',
                                        p: 3,
                                        backgroundColor: '#fff',
                                        transition: 'all 0.3s ease',
                                        position: 'relative',
                                        width: { xs: '100%', sm: '100%', lg: '300px' },
                                        // height: '100%',
                                        // cursor: 'pointer',
                                        // ":hover": {
                                        //     boxShadow: '0 0 10px 0 #d6ddeb',
                                        // },
                                    }}
                                >
                                    {/* <EditButton onClick={() => null} /> */}

                                    <Typography variant='h5'>
                                        Ek Bilgiler
                                    </Typography>

                                    <Stack direction={{ xs: 'column', sm: 'row' }} flexWrap="wrap" spacing={2}>
                                        <WidgetItem
                                            icon={<Email />}
                                            name="Email"
                                            value={company?.companyInformation?.email}
                                        />
                                        {/* <WidgetItem
                                            icon={<Phone />}
                                            name="Şirket Hakkında: "
                                            value={company?.companyInformation?.companyBrief}
                                        /> */}
                                        <WidgetItem
                                            icon={<RoomOutlinedIcon />}
                                            name="Açık Adres"
                                            value={company?.companyInformation?.companyAddress}
                                        />

                                        {!!company?.companyInformation?.linkedin && <WidgetItem
                                            icon={<LinkedIn />}
                                            name="Linkedin"
                                            value={company?.companyInformation?.linkedin}
                                        />}

                                        {!!company?.companyInformation?.facebook && <WidgetItem
                                            icon={<Facebook />}
                                            name="Facebook"
                                            value={company?.companyInformation?.facebook}
                                        />}

                                        {!!company?.companyInformation?.instagram && <WidgetItem
                                            icon={<Instagram />}
                                            name="Instagram"
                                            value={company?.companyInformation?.instagram}
                                        />}



                                    </Stack>
                                </Stack>

                            </Stack>







                        </Stack>
                    )
                }
            </Box>


            <CreateApplicationDialog
                button={false}
                loading={createApplicationLoading}
                onClick={handleCreateApplication}
                // title={`${applyDialog?.title ?? "advertisement"} - İlan Başvurusu`}
                applyDialog={applyDialog}
                setApplyDialog={setApplyDialog}
                advertisement={applyDialog}

                onUpdate={() => {
                    navigate(routes.candidate.dashboard.ozgeleceklerim)
                }}

                onAddNew={() => {
                    dispatch(setCandidateCreateOzgelecekFormField(applyDialog?.field))
                    navigate(
                        routes.candidate.dashboard.ozgelecekCreate,
                        {
                            state: { field: applyDialog?.field, advertisementId: applyDialog?._id }
                        }
                    )
                }}

            />


        </DashboardLayout>
    )
}


const EditButton = ({ onClick }: { onClick: () => void }) => {

    return (
        <Box
            onClick={onClick}
            position={'absolute'}
            top={15}
            right={15}
            border={"1px solid #ddd"}
            width={30}
            height={30}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <Iconify icon={"basil:edit-outline"} sx={{ width: 20, height: 20, cursor: 'pointer', color: 'primary.dark' }} />
        </Box>
    )
}