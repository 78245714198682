import HasFilter from "interfaces/HasFilter";
import { IFilterOzgelecekForCompany } from "interfaces/user/ozgelecek/ozgelecek/IFilterOzgelecek";
import { IOzgelecek } from "interfaces/user/ozgelecek/ozgelecek/IOzgelecek";

type ICompanyOzgeleceksResponse = IOzgelecek[];

interface ICompanyOzgeleceksVariables {
  candidateId: string;
}

export const company_referenceQuestions = (variables: ICompanyOzgeleceksVariables) => {

  console.log(variables, 'company_referenceQuestionsssssss')

  const data = JSON.stringify({
    query: `query Company_referenceQuestions($candidateId: String!) {
      company_referenceQuestions(candidateId: $candidateId) {
        count
        data {
          _id
          content
        }
      }
    }`,
    variables: variables

  })

  if (!variables?.candidateId) {
    return null
  } else {

    return data
  }

}