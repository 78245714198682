



export interface ICompanyCreateCustomApplicationListResponse {
    _id: string
}
export interface ICompanyCreateCustomApplicationListVariables {
    customApplicationListInput: {
        description: string,
        name: string
    }
}


export const company_createCustomApplicationList = (variables: ICompanyCreateCustomApplicationListVariables) => {

    const data = JSON.stringify({
        query: `
        mutation Company_createCustomApplicationList($customApplicationListInput: CustomApplicationListInput!) {
            company_createCustomApplicationList(customApplicationListInput: $customApplicationListInput) {
              _id
            }
          }
        `,
        variables: {
            "customApplicationListInput": {
                "description": variables?.customApplicationListInput?.description ?? null,
                "name": variables?.customApplicationListInput?.name ?? null
            }
        }
    })

    return data

}