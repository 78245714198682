import { LoadingButton } from '@mui/lab'
import { FormControlLabel, FormGroup, Grid, Stack, Typography } from '@mui/material'
import { IOSSwitchComponent } from 'components'

export const NotificationSettingsSection = () => {
    return (
        <Grid container spacing={3}>

            <Grid item xs={12}>
                <Typography variant='h3'>
                    Bildirim Ayarları
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <FormGroup>
                    <Stack
                        sx={{
                            // bgcolor: '#f1f1f1',
                            p: 2
                        }}
                    >
                        <FormControlLabel
                            control={<IOSSwitchComponent sx={{ m: 1 }} defaultChecked />}
                            label="Email bildirimi almak istiyorum"
                        />

                        <FormControlLabel
                            control={<IOSSwitchComponent sx={{ m: 1 }} defaultChecked />}
                            label="Mesaj bildirimi almak istiyorum"
                        />

                        <FormControlLabel
                            control={<IOSSwitchComponent sx={{ m: 1 }} defaultChecked />}
                            label="Tarayıcı bildirimi almak istiyorum"
                        />
                    </Stack>
                </FormGroup>
            </Grid>

            <Grid item xs={12} display="flex" justifyContent="start" >
                <LoadingButton
                    variant='contained'
                    color='primary'
                    // onClick={() => handleSubmit()}
                    // disabled={!isValid}

                    sx={{ ml: 2 }}
                >
                    Kaydet
                </LoadingButton>
            </Grid>


        </Grid>
    )
}
