import axios from 'axios';
import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Link, Stack, IconButton, Typography, InputAdornment, Box, InputLabel, Dialog } from '@mui/material';

import { routes } from 'routes';
import { useBoolean } from 'minimal/hooks/use-boolean';
import { Iconify, RHFTextField, FormProvider, RHFCheckbox } from 'components';
import parse from 'html-react-parser'

const RegisterFormSchema = Yup.object().shape({
  email: Yup.string().required('Gerekli').email('Geçerli bir email adresi giriniz'),
  password: Yup.string()
    .min(8, "En az 8 karakter içermeli")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+,.\\\/;':"-])[A-Za-z\d!@#$%^&*()_+,.\\\/;':"-]{8,}$/,

      `Şifreniz küçük harf, büyük harf, sayı ve sembol içermelidir. Lütfen ! @ # $ % ^ & * ( ) _ + . , \ / ; ' : " - karakterlerinden en az birini kullanınız.`
    ).required('Gerekli'),
  passwordConfirm: Yup.string().oneOf([Yup.ref('password'), "null"], 'Şifreler eşleşmeli'),
  conditions: Yup.boolean().required('Gerekli').oneOf([true], ''),
});



export const RegisterContainer = () => {

  const password = useBoolean();
  const navigate = useNavigate()
  const [role, setRole] = useState("")
  const [openPrivacy, setOpenPrivacy] = useState(false)
  const [openTerms, setOpenTerms] = useState(false)

  const methods = useForm({
    resolver: yupResolver(RegisterFormSchema),
    defaultValues: {
      email: '',
      password: '',
      passwordConfirm: '',
      conditions: false,
    },
    mode: 'all',
  });


  const { handleSubmit, formState: { isSubmitting, isValid, errors } } = methods;


  const onSubmit = handleSubmit(async (data) => {

    try {

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/auth/auth/register`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({ ...data, role })
      };

      const response = await axios.request(config)

      console.log(response?.data)

      if (response?.data?.isSuccess) {
        enqueueSnackbar("Kayıt başarılı! Giriş yapmak için e-mail onaylama gerekiyor!")
        navigate(routes.auth.registerSuccessfull)
      } else {
        enqueueSnackbar("Başka bir email deneyiniz ya da daha sonra tekrar deneyiniz!", {
          variant: 'error'
        })
      }

    } catch (error: any) {

      console.error(error);
      if (error?.response?.data?.message) {
        error?.response?.data?.errors?.forEach((item: any) => {
          if (item.msg === "Email already in use") {
            enqueueSnackbar("Bu email kullanılmaktadır!", {
              variant: 'error'
            })
          } else {
            enqueueSnackbar("Birşeyler yanlış gitti!", {
              variant: 'error'
            })
          }
        })
      } else {
        enqueueSnackbar("Birşeyler yanlış gitti!", {
          variant: 'error'
        })
      }


    }



  });

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5, position: 'relative' }} direction={"row"} alignItems={"center"}>
      {role && <Stack direction="row" justifyContent={"center"} onClick={() => setRole("")} >
        <Iconify icon="uiw:left" width={16} sx={{ mt: 0.4 }} />

      </Stack>}
      <Typography variant="h4">Yeni bir hesap oluştur</Typography>
    </Stack>
  );

  const renderForm = (
    <Stack spacing={2} flexDirection={"column"}>

      <Box>
        <InputLabel>Email</InputLabel>
        <RHFTextField name="email" placeholder="Emailinizi giriniz" />
      </Box>

      <Box>
        <InputLabel>Şifre</InputLabel>
        <RHFTextField
          name="password"
          placeholder="Şifrenizi giriniz"
          type={password.value ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end">
                  <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box>
        <InputLabel>Şifre Onayla</InputLabel>
        <RHFTextField
          name="passwordConfirm"
          placeholder="Şifrenizi tekrar giriniz"
          type={password.value ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end">
                  <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <RHFCheckbox
          name='conditions'
          // label="Kullanım Koşulları ve Gizlilik Politikası'nı Kabul ediyorum."
          label={
            <Typography
              htmlFor="register_conditions"
              component="label"
              sx={{ color: !!errors.conditions ? '#961650 !important' : 'text.secondary', typography: 'caption', textAlign: 'center' }}
            >
              <Link underline="always" color={!!errors.conditions ? '#961650 !important' : 'text.secondary'} onClick={() => setOpenTerms(true)}>
                Kullanım Koşulları
              </Link>
              {' ve '}
              <Link underline="always" color={!!errors.conditions ? '#961650 !important' : 'text.secondary'} onClick={() => setOpenPrivacy(true)}>
                Gizlilik Politikası'nı
              </Link>
              {' Kabul ediyorum.'}
              .
            </Typography>
          }
        />

        {/* <Typography
          htmlFor="register_conditions"
          component="label"
          sx={{ color: 'text.secondary', typography: 'caption', textAlign: 'center' }}
        >
          <Link underline="always" color="text.primary">
            Kullanım Koşulları
          </Link>
          {' ve '}
          <Link underline="always" color="text.primary">
            Gizlilik Politikası'nı
          </Link>
          {'Kabul ediyorum. '}
          .
        </Typography> */}
      </Box>

      <LoadingButton
        fullWidth
        color="primary"
        size="large"
        type="submit"
        variant="contained"
        disabled={!isValid}
        loading={isSubmitting}
      >
        Kayıt Ol
      </LoadingButton>




      <Stack direction="row" spacing={0.5} display={"flex"} justifyContent={"center"} sx={{ alignItems: 'center' }}>
        <Typography variant="body2" > Zaten hesabın var mı? </Typography>

        <Link to={routes.auth.login} component={RouterLink} variant="subtitle2">
          Giriş Yap
        </Link>
      </Stack>

      <Dialog open={openPrivacy} onClose={() => setOpenPrivacy(false)}>
        <Stack spacing={2} sx={{ p: 3, width: "100%" }}>
          <Typography variant="h5"> Gizlilik Politikası</Typography>
          <Typography variant="body1">
            {parse(onBilgilendirmeHtml)}
          </Typography>
        </Stack>
      </Dialog>

      <Dialog open={openTerms} onClose={() => setOpenTerms(false)}>
        <Stack spacing={2} sx={{ p: 3, width: "100%" }}>
          <Typography variant="h5">Kullanım Koşulları</Typography>
          <Typography variant="body1">
            {parse(kvkkHtml)}
          </Typography>
        </Stack>
      </Dialog>
    </Stack>
  );

  const renderRole = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >

      {
        [{ value: "candidate", name: "ADAY" }, { value: "company", name: "FİRMA" }].map(item => {
          return (
            <Box
              key={item?.value}
              onClick={() => setRole(item?.value)}
              sx={{
                // borderRadius: "18px",
                border: "3px solid #ddd",
                p: 3,
                display: 'flex',
                alignItems: "center",
                justifyContent: "center",
                transitionDuration: "300ms",
                cursor: "pointer",

                '&:hover': {
                  border: "3px solid #ddd",
                  boxShadow: '1px 1px 10px 0px #eee',
                  color: "#666",
                  cursor: "pointer",
                },

              }}
            >
              {item?.value === "candidate" ? <Iconify icon="icon-park-outline:user-business" width={30} sx={{ mr: 2 }} /> : <Iconify icon="ion:business" width={25} sx={{ mr: 1.5 }} />}
              <Typography variant="h3">{item?.name}</Typography>
            </Box>
          )
        })
      }

      <Stack direction="row" spacing={0.5} display={"flex"} justifyContent={"center"} sx={{ alignItems: 'center' }}>
        <Typography variant="body2"> Zaten hesabın var mı? </Typography>

        <Link to={routes.auth.login} component={RouterLink} variant="subtitle2">
          Giriş Yap
        </Link>
      </Stack>
    </Box>
  )


  return (
    <Box sx={{
      paddingTop: { md: 20 },
      height: { md: "80vh" },
      px: { xs: 0, sm: 3, lg: 10 },
      justifyContent: "center",
    }}>
      <FormProvider methods={methods} onSubmit={onSubmit} >
        {renderHead}
        {role ? renderForm : renderRole}
      </FormProvider>
    </Box >
  );

}



const kvkkHtml = `<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:Tahoma;
	panose-1:2 11 6 4 3 5 4 4 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{mso-style-link:"Üst Bilgi Char";
	margin:0cm;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-link:"Alt Bilgi Char";
	margin:0cm;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoNoSpacing, li.MsoNoSpacing, div.MsoNoSpacing
	{margin:0cm;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
span.stBilgiChar
	{mso-style-name:"Üst Bilgi Char";
	mso-style-link:"Üst Bilgi";}
span.AltBilgiChar
	{mso-style-name:"Alt Bilgi Char";
	mso-style-link:"Alt Bilgi";}
.MsoChpDefault
	{font-size:11.0pt;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
 /* Page Definitions */
 @page WordSection1
	{size:595.3pt 841.9pt;
	margin:42.55pt 42.55pt 42.55pt 42.55pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang=TR link=blue vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNoSpacing>&nbsp;</p>

<p class=MsoNoSpacing align=center style='text-align:center;line-height:150%'><b><span
style='font-family:"Arial",sans-serif'>KVKK AYDINLATMA METNİ </span></b></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Bu metin, 6698 sayılı Kişisel Verilerin
Korunması Kanunu (KVKK) kapsamında SINIRLI SORUMLU ÖZGELECEK İNSAN KAYNAKLARI
TEKNOLOJİLERİ A.Ş.’nin (Metnin devamında ÖZGELECEK A.Ş. olarak anılacaktır.) KVKK’ya
uyumluluğunun sağlanmasını ve SINIRLI SORUMLU ÖZGELECEK A.Ş. tarafından kişisel
verilerin korunması ve işlenmesine ilişkin yükümlülüklerin yerine
getirilmesinde uyulacak ilkelerin belirlenmesini amaçlamaktadır.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Bu kapsamda Veri Sorumlusu sıfatıyla,
kişisel bilgileriniz aşağıda açıklandığı çerçevede, farklı kanallar ve hukuki
gerekçeler çerçevesinde temin edilmekte; kaydetme, saklama gibi süreçler
çerçevesinde işlenmekte; mevzuatın izin verdiği durumlarda 3.kişilere
devredilmekte, söz konusu verilerin güvenliği ve yetkisiz kişilerin erişiminin
engellenmesi konusunda her türlü idari ve teknik tedbirler alınmaktadır.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>SINIRLI SORUMLU ÖZGELECEK A.Ş. olarak,
kişisel verilerinizin KVKK’ya uygun olarak işlenerek, muhafaza edilmesine büyük
önem atfetmekteyiz. Bu sorumluluğumuzun tam idraki ile KVKK’da tanımlı şekli
ile “Veri Sorumlusu” sıfatıyla, kişisel verilerinizi aşağıda izah edildiği
surette ve mevzuat tarafından belirlenen sınırlar çerçevesinde işlemekteyiz.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><b><span
style='font-family:"Arial",sans-serif'>İNTERNET SİTEMİZ DOLAYISIYLA İŞLEMEKTE
OLDUĞUMUZ KİŞİSEL VERİLER</span></b></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>SINIRLI SORUMLU ÖZGELECEK A.Ş.; şirketin
internet sitesine üye olan Kişilere ait, kanunun kişisel veri olarak
belirlediği ad-soyad, telefon numarası, adres bilgisi ve e-posta adresi
bilgilerini hukuka uygun olarak saklamakta ve işlemektedir.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>İşlenen Kişisel Veriler:</span></p>

<table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0
 style='border-collapse:collapse;border:none'>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Veri Kategorisi</span></p>
  </td>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-left:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Kişisel Veri</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Kimlik</span></p>
  </td>
  <td width=307 valign=top style='width:230.3pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Adı ve Soyadı, Doğum Tarihi. TC Kimlik
  No</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>İletişim</span></p>
  </td>
  <td width=307 valign=top style='width:230.3pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Telefon numarası, E-posta adresi,
  Adres</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Site Kullanımına İlişkin Veriler</span></p>
  </td>
  <td width=307 style='width:230.3pt;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Size özel tahsis edilmiş Kullanıcı Adı
  ve Şifreniz, Sitedeki davranışlarınız, işlemleriniz, tercihleriniz, ziyaret
  edilen ürünler</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Diğer</span></p>
  </td>
  <td width=307 style='width:230.3pt;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>&nbsp;Tarafımıza iletmiş olduğunuz
  mesajın içeriğinde yer alabilecek diğer kişisel veriler</span></p>
  </td>
 </tr>
</table>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Üyelik ve Satış Sözleşmesinin Kurulması
ile Beraber İşlenen Veriler: </span></p>

<table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0
 style='border-collapse:collapse;border:none'>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Veri Kategorisi</span></p>
  </td>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-left:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Kişisel Veri</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Kimlik</span></p>
  </td>
  <td width=307 valign=top style='width:230.3pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Adı ve Soyadı, Doğum Tarihi. TC Kimlik
  No</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>İletişim</span></p>
  </td>
  <td width=307 valign=top style='width:230.3pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Telefon numarası, E-posta adresi,
  Adres</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Fatura Bilgisi (Fatura Talep Edildiği
  Takdirde)</span></p>
  </td>
  <td width=307 style='width:230.3pt;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>&nbsp;Tc Kimlik No, Vergi Kimlik No</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Ödeme Bilgisi (Havale Yoluyla)</span></p>
  </td>
  <td width=307 style='width:230.3pt;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>&nbsp;Banka Iban ve Kullandığınıza
  Bankaya ilişkin bilgiler</span></p>
  </td>
 </tr>
</table>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Kullanıcı Olmanız Halinde İşlenen
Veriler: &nbsp;</span></p>

<table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0
 style='border-collapse:collapse;border:none'>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Veri Kategorisi</span></p>
  </td>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-left:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Kişisel Veri</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Kimlik</span></p>
  </td>
  <td width=307 valign=top style='width:230.3pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Adı ve Soyadı, Doğum Tarihi. TC Kimlik
  No</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>İletişim</span></p>
  </td>
  <td width=307 valign=top style='width:230.3pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Telefon numarası, E-posta adresi,
  Adres</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Site Kullanımına İlişkin Veriler</span></p>
  </td>
  <td width=307 style='width:230.3pt;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>&nbsp;Size özel tahsis edilmiş
  kullanıcı adı ve şifreniz</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Talep Ve Şikayet Yönetimi</span></p>
  </td>
  <td width=307 style='width:230.3pt;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Şirketimiz tarafından verilen
  hizmetlerin kayıtları</span></p>
  </td>
 </tr>
</table>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Kullanıcı olmanız halinde işlenen
verilerinizin tutulmasındaki amaç, size daha iyi bir hizmet sunabilmektir.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Elektronik Ticari İletişim İzni Vermeniz
Durumunda: </span></p>

<table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0
 style='border-collapse:collapse;border:none'>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Kimlik</span></p>
  </td>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-left:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Adı ve Soyadı, Doğum Tarihi. TC Kimlik
  No</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>İletişim</span></p>
  </td>
  <td width=307 valign=top style='width:230.3pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Telefon numarası, E-posta adresi,
  Adres</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 valign=top style='width:230.3pt;border:solid black 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Site Kullanımına İlişkin Veriler</span></p>
  </td>
  <td width=307 style='width:230.3pt;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Sitedeki davranışlarınız,
  işlemleriniz, tercihleriniz, ziyaret edilen ürünler</span></p>
  </td>
 </tr>
 <tr>
  <td width=307 style='width:230.3pt;border:solid black 1.0pt;border-top:none;
  padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Pazarlamaya Esas Bilgileriniz</span></p>
  </td>
  <td width=307 style='width:230.3pt;border-top:none;border-left:none;
  border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
  <p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
  style='font-family:"Arial",sans-serif'>Doğum tarihi, Site kullanımları,
  alışveriş alışkanlıkları, , adres, ve alışkanlıklar gibi demografik
  üye/kullanıcı/müşteri bilgileri, , her türlü ürüne ve hizmete ilişkin
  alışveriş alışkanlığı-tercihler, beğeniler ve ilgili yorumlar, yararlanılan
  kampanya, anket vb.leri ile içerikleri, fatura içerikleri, ödeme
  yöntemleri(nakit, kredi kartı vs.) ve ödeme detayları (taksit miktarı vs.),
  eski ve yeni cep/ev/iş telefon/faks numaraları, mail adresleri, tanımlama
  bilgileri (çerez, web tarayıcı işaretleri-bilgisi, IP, beacon,
  kablolu-kablosuz ağ bağlantı bilgisi</span></p>
  </td>
 </tr>
</table>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><b><span
style='font-family:"Arial",sans-serif'>KİŞİSEL VERİLERİN TOPLANMASI, İŞLENMESİ
VE İŞLEME AMAÇLARI</span></b></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Üye, ziyaretçi ve kullanıcıların verileri
SINIRLI SORUMLU ÖZGELECEK A.Ş. tarafından uygulanan faaliyetlerin ilgili
mevzuat ve uygulamalara uygun olarak yerine getirilmesini sağlamak için
aşağıdaki amaçlarla KVKK’nın 5. ve 6. maddelerinde belirtilen kapsamda
işlenecektir:</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><b><span
style='font-family:"Arial",sans-serif'>GENEL AMAÇLAR:</span></b></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>-Bizimle iletişime geçtiğiniz takdirde
sorun ve şikayetlerinizi çözümlemek ve gerektiğinde buna ilişkin olarak sizinle
iletişime geçebilmek amacıyla kimlik ve iletişim bilgilerinizi, </span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>-Mevzuattan kaynaklanan
yükümlülüklerimizi yerine getirmek, yetkili ve görevli kamu kurum ve
kuruluşları ile bilgi güvenliği başta olmak üzere diğer hukuki yükümlüklerimizi
yerine getirmek amaçlarıyla kimlik, iletişim, fatura, alışveriş bilgilerinizi,</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>-Sözleşmeden doğacak uyuşmazlıklarda
mahkeme, icra dairesi, hakem heyeti gibi resmi kurum ve kuruluşlara karşı her
türlü dava, cevap ve itiraz hakkının kullanılması amacıyla kimlik, iletişim,
fatura, alışveriş bilgilerinizi,</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>-Size ilişkin sistemimizde
oluşturduğumuz kayıtların diğer üyelere ilişkin kayıtlardan ayırt edilebilmesi,
fatura/fiş ibraz etmeksizin satış sonrası operasyonel süreçlerden
faydalanabilmenizi sağlamak amacıyla kimlik ve iletişim bilgilerinizi,</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>-Fatura düzenlenmesini talep etmeniz
halinde fatura düzenlenebilmesi amacıyla kimlik ve fatura bilgilerinizi,</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>-Ödemenizi kredi kartı ile yapmak
istemeniz halinde ödemenin alınabilmesi amacıyla kredi kartı bilgileriniz
(kredi kartı bilgileri kaydedilmeden ödeme kuruluşuna aktarılmaktadır.),</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>-Mevzuattan kaynaklanan saklama
yükümlülüklerimizi yerine getirmek amacıyla kimlik, alışveriş ve fatura
bilgilerinizi.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><b><span
style='font-family:"Arial",sans-serif'>ELEKTRONİK TİCARİ İLETİŞİM İZNİ VERMENİZ
HALİNDE:</span></b></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Genel veya size özel kişiselleştirilmiş
kampanyaların, avantajların, promosyonların, reklamların, bilgilendirmelerin,
pazarlama faaliyetlerinin oluşturulabilmesi/yapılabilmesi, size yönelik her
türlü ticari iletişim faaliyetlerinde bulunulabilmesi amacıyla kimlik,
iletişim, alışveriş ve pazarlamaya esas bilgilerinizi işliyoruz.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><b><span
style='font-family:"Arial",sans-serif'>KİŞİSEL VERİLERİNİZİN TOPLANMA YÖNTEMİ
VE HUKUKİ SEBEBİ</span></b></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Kişisel verileriniz SINIRLI SORUMLU ÖZGELECEK
A.Ş. tarafından; Site üzerinden işlemleriniz, talep ve şikayetlerinize ilişkin
veya üyeliğiniz ile beraber, Site üzerindeki her türlü alışveriş, tahsilat,
işlem, anket doldurma, otomatik yöntemlerle veya elektronik sistemler vasıtası
ile alınan Elektronik Ticari İletişim İzniniz çerçevesinde işlenmektedir.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>SINIRLI SORUMLU ÖZGELECEK A.Ş.olarak
kişisel verilerinizi; Site üzerindeki ozgelecek.net  süreçlerine ilişkin olarak
“sözleşmenin kurulması ve ifası için veri işlemenin gerekli olması”, “hukuki
yükümlülüğün yerine getirilmesi” ile “veri sorumlusunun meşru menfaatleri için
veri işlenmesinin zorunlu olması hukuki sebeplerine; mevzuattan kaynaklanan
saklama yükümlülüklerimize ilişkin “hukuki yükümlülüğün yerine getirilmesi”
hukuki sebebine; üye olmanız halinde ”sözleşmenin kurulması ve ifası için veri
işlemenin gerekli olması” talep ve şikayetlerinizin takibi açısında “Hakkın
kullanılması için veri işlemenin zorunlu olması” sebebine dayanarak, kişisel
verileriniz Elektronik Ticari İletişim İzni vermeniz durumunda ve Verilerinizin
İş Ortakları ile size yeni hizmetler sunulması amacı ile açık rıza hukuki
sebeplerine dayanarak topluyoruz.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Açık rızanın bulunması; şirketimizin tabi
olduğu sair mevzuatta açıkça öngörülmüş olması,</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Bir sözleşmenin kurulması veya ifasıyla
doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel
verilerin işlenmesinin gerekli olması, talep edilen ürün ve hizmetleri
sunabilmek ve akdettiğiniz sözleşmelerinin gereğinin yerine getirilmesi,</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Hukuki yükümlülüğün yerine getirebilmesi
için zorunlu olması,</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>İlgili kişinin kendisi tarafından
alenileştirilmiş olması,</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Bir hakkın tesisi, kullanılması veya
korunması için veri işlemenin zorunlu olması,</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>İlgili kişinin temel hak ve
özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri
için veri işlenmesinin zorunlu olması.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Açık rızanızın bulunması; Sağlık
dışındaki kişisel veriler, kanunlarda öngörülen hallerde açık rızası
aranmaksızın,</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Sağlığa ilişkin kişisel veriler ise
ancak kamu sağlığının korunması, koruyucu hekimlik, tıbbî teşhis, tedavi ve
bakım hizmetlerinin yürütülmesi, sağlık hizmetleri ile finansmanının
planlanması ve yönetimi amacıyla, sır saklama yükümlülüğü altında bulunan
kişiler veya yetkili kurum ve kuruluşlar tarafından ilgilinin açık rızası
aranmaksızın.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><b><span
style='font-family:"Arial",sans-serif'>KİŞİSEL VERİLERİNİZİN AKTARILMASI</span></b></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>SINIRLI SORUMLU ÖZGELECEK A.Ş. olarak
KVK Kanunu’nun 8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve
amaçları çerçevesinde kişisel verilerinizi;</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Bilişim teknolojileri, pazarlama
faaliyetleri ya da uzmanlık gerektiren danışmanlık vb. hizmetlerini almak,
kişisel veri toplama süreçlerinde ürün ve hizmet desteği almak gibi amaçlarla
yurtiçi ve yurtdışında bulunan iş ortakları ve hizmet sağlayıcılarıyla (çağrı
merkezi, cihazlar aracılığıyla kişisel veri toplama hizmeti, pazarlama
danışmanlığı, veri tabanı, elektronik ileti aracı hizmet sağlayıcıları,
danışmanlık, vb. hizmeti veren firmaları ile) veya yurtiçindeki iş
ortaklarımızın sunucularının yurtdışında olması halinde yurtdışı ile,</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Site üzerinden yaptığınız
alışverişlerinizin ve diğer her türlü taleplerinizin çözümü amacı ile iş
ortaklarımızla,</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Yetkili ve görevli kamu kurum ve
kuruluşları ile adli makamlara karşı olan bilgi, belge verme ve ilgili sair
yükümlülüklerimizi yerine getirmek ve dava ve cevap hakları gibi yasal
haklarımızı kullanabilmek amacıyla bizden istenen bilgileri anılan bu kurum,
kuruluş ve makamlara,</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Ödemenizi kredi kartı ile yapmanız
halinde kredi kartı bilgilerinizi,&nbsp;PayTR&nbsp;tarafından kaydedilmeksizin
ilgili banka, elektronik ödeme kuruluşu vb. hizmeti sağlayan üçüncü kişilere
aktarmaktayız.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><b><span
style='font-family:"Arial",sans-serif'>İLGİLİ KİŞİ OLARAK KVKK’NIN 11.
MADDESİNDE SAYILAN HAKLARINIZ</span></b></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>6698 sayılı Kişisel Verilerin Korunması
Kanunu’nun 11. maddesinde düzenlenen haklar kapsamında kişisel veri sahipleri;</span></p>

<p class=MsoNoSpacing style='margin-left:39.45pt;text-align:justify;text-indent:
-18.0pt;line-height:150%'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>Kişisel verilerinizin korunmasına
yönelik haklarınız hakkında ayrıntılı bilgi alabilir,</span></p>

<p class=MsoNoSpacing style='margin-left:39.45pt;text-align:justify;text-indent:
-18.0pt;line-height:150%'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>Kişisel veri işlenip işlenmediğini
öğrenebilir,</span></p>

<p class=MsoNoSpacing style='margin-left:39.45pt;text-align:justify;text-indent:
-18.0pt;line-height:150%'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>Kişisel verileri işlenmişse buna ilişkin
bilgi talep edebilir,</span></p>

<p class=MsoNoSpacing style='margin-left:39.45pt;text-align:justify;text-indent:
-18.0pt;line-height:150%'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>Kişisel verilerin işlenme amacını ve
bunların amacına uygun kullanılıp kullanılmadığını öğrenebilir,</span></p>

<p class=MsoNoSpacing style='margin-left:39.45pt;text-align:justify;text-indent:
-18.0pt;line-height:150%'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>Yurt içinde veya yurt dışında kişisel
verilerin aktarıldığı üçüncü kişileri bilme hakkında bilgi edinebilir,</span></p>

<p class=MsoNoSpacing style='margin-left:39.45pt;text-align:justify;text-indent:
-18.0pt;line-height:150%'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>Kişisel verilerin eksik veya yanlış
işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan
işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini
isteyebilir,</span></p>

<p class=MsoNoSpacing style='margin-left:39.45pt;text-align:justify;text-indent:
-18.0pt;line-height:150%'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>KVKK’nın ve ilgili diğer kanun
hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren
sebeplerin ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok
edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı
üçüncü kişilere bildirilmesini isteyebilir,</span></p>

<p class=MsoNoSpacing style='margin-left:39.45pt;text-align:justify;text-indent:
-18.0pt;line-height:150%'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>İşlenen verilerin münhasıran otomatik
sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir
sonucun ortaya çıkmasına itiraz edebilir,</span></p>

<p class=MsoNoSpacing style='margin-left:39.45pt;text-align:justify;text-indent:
-18.0pt;line-height:150%'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-family:"Arial",sans-serif'>Kişisel verilerin kanuna aykırı olarak
işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep
edebilir.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><b><span
style='font-family:"Arial",sans-serif'>KİŞİSEL VERİ SAHİPLERİNİN İSTEK VE
TALEPLERİ</span></b></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>SINIRLI SORUMLU ÖZGELECEK A.Ş., KVK
Kanunu’nun 10. maddesine uygun olarak kişisel veri sahibinin haklarını
kendisine bildirmekte, bu hakların nasıl kullanılacağı konusunda kişisel veri
sahibine yol göstermektedir. Kişisel veri sahiplerinin haklarının
değerlendirilmesi ve kişisel veri sahiplerine gereken bilgilendirmenin
yapılması için KVK Kanunu’nun 13. maddesine uygun olarak gerekli kanalları, iç
işleyişi, idari ve teknik düzenlemeleri yürütmektedir.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Kişisel Verileri Koruma Kanunu’nun
ilgili kişinin haklarını düzenleyen 11. maddesi kapsamındaki taleplerinizi,
“Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ’e” göre SINIRLI
SORUMLU ÖZGELECEK A.Ş.'nin </span><span style='font-family:"Arial",sans-serif;
color:red'>­­­­­­­­­­­­­­­­­­______________(mail adresi) </span><span
style='font-family:"Arial",sans-serif'>adresine yazılı olarak iletebilirsiniz.</span></p>

<p class=MsoNoSpacing style='text-align:justify;line-height:150%'><span
style='font-family:"Arial",sans-serif'>Kanun kapsamında uygun görülen süre 30
takvim günü içerisinde başvurunuza cevap verilmemesi durumunda veya
başvurunuzun reddedilmesi, başvurunuza verilen cevabı yetersiz bulmanız
durumlarında ilgili kişi olarak SINIRLI SORUMLU ÖZGELECEK A.Ş.'nin cevabını
öğrendiğiniz tarihten itibaren 30 gün ve her halükârda başvuru tarihini takiben
60 takvim günü içerisinde Kişisel Verilerin Korunması Kurulu’na şikâyette
bulunabilirsiniz.</span></p>

<p class=MsoNormal>&nbsp;</p>

</div>

</body>

</html>`

const onBilgilendirmeHtml = `<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:Tahoma;
	panose-1:2 11 6 4 3 5 4 4 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
.MsoChpDefault
	{font-size:11.0pt;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
 /* Page Definitions */
 @page WordSection1
	{size:595.3pt 841.9pt;
	margin:42.55pt 42.55pt 42.55pt 42.55pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang=TR link=blue vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal align=center style='text-align:center'><b><span
style='font-family:"Arial",sans-serif'>ÇEREZ POLİTİKASI</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Özgelecek
İnsan Kaynakları Teknolojileri Anonim Şirketi (“ÖZGELECEK A.Ş.”) olarak
internet sitemizde çerez kullanmaktayız. Bu Çerez Politikası (“Politika”)
ÖZGELECEK A.Ş. tarafından yönetilen ozgelecek.net adresli internet sitesi için
geçerli olup, çerezler işbu Politika’da belirtilen şekilde kullanılacaktır. </span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Bu
Politika en son </span><b><span style='font-family:"Arial",sans-serif'>15/02/2024</span></b><span
style='font-family:"Arial",sans-serif'> Tarihinde güncellenmiştir</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>ÇEREZ
TANIMI</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Çerezler,
bir internet sitesi ziyaret edildiğinde, internet sitesi tarafından tarayıcınız
aracılığıyla cihazınıza veya ağ sunucusuna depolanan küçük metin dosyalarıdır. Çerezler
vasıtasıyla kullanıcıların bilgilerinin işleniyor olması sebebiyle, 6698 sayılı
Kişisel Verilerin Korunması Kanunu gereğince, kullanıcıların
bilgilendirilmeleri ve onaylarının alınması gerekmektedir.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Ziyaret
ettiğiniz web siteleri, bilgisayarınıza veya telefonunuza küçük bilgiler
kaydedip, daha sonra aynı siteyi ziyaret ettiğinizde</span> <span
style='font-family:"Arial",sans-serif'>bu bilgileri okuyabilirler. Bu küçük
bilgilerin kaydedildiği dosyalara çerez denir. Çerezler genellikle internette
gezinti deneyiminizi iyileştirmeye ve siteleri kişiselleştirmeye yarar. Örneğin
bir siteye giriş yaparken “beni hatırla” seçeneğini işaretlerseniz o site,
kullanıcı adınızı (veya özel bir kimlik numarasını) içeren bir çerezi
tarayıcınıza kaydeder. Çerezleri yalnızca onları oluşturan site okuyabilir.
Çerezin ne kadar süreyle saklanacağını da site belirler.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>ÇEREZLERİN
KULLANIMI</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Çerezler,
genellikle site kullanıcısının deneyim kalitesini arttırmak için kullanılır.
Çerezler sayesinde ziyaret edilen internet sitesi tekrar ziyaret edildiğinde,
ziyaretçinin önceki tercihlerini hatırlayabilir. Böylece internet sitesinin
işlevselliğini arttırarak sunulan hizmeti geliştirir. Kullanım amaçlarına göre
farklı çerezler bulunmaktadır. Bunların bazıları zorunlu çerezler, bazıları ise
kullanıcının tercihine bırakılabilir çerezlerdir.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Çerezler,
aynı zamanda internet sitesi üzerinden yeni özellikler sunmak ve sunulan
özellikleri iyileştirmek için kullanılmaktadır.</span> <span style='font-family:
"Arial",sans-serif'>Çerezleri kabul etmek, size daha iyi bir kullanıcı deneyimi
sağlar. Zorunlu çerezler dışındaki çerezleri silebilir veya engelleyebilirsiniz
(aktif veya inaktif seçeneği ile tercihlerinizi belirleyebilirsiniz.) fakat bu
halde web sitesi gerektiği gibi çalışmayabilir veya tüm özelliklerinden
yararlanmanız mümkün olmaz. Her zaman tercihlerinizi değiştirebilmeniz
mümkündür. “ozgelecek.net” sitesinde zorunlu çerezler kullanılmaktadır. </span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>ÖZGELECEK
A.Ş.’ye ait “ozgelecek.net” adlı web sitesinden en verimli şekilde faydalanılması
ve kullanıcı deneyimini geliştirebilmek için çerezler kullanılmaktadır. Web
sitesine giriş yapıldığında, çerezlerin kabul edilip edilmediğine ilişkin soru
sorulmaktadır. Çerezler “Kabul Et” seçeneği seçildikten sonra aktif hale
gelecektir. Kullanıcının daha sonra fikrini değiştirmesi halinde ve Çerezleri
kullanmak istememesi halinde, tarayıcı ayarlarından çerezleri her zaman silme
ya da engelleme imkanı bulunmaktadır. Ancak bu durum kullanıcının web sitesi
kullanımını etkileyebilir. Tarayıcılardan çerez ayarları değiştirilmediği
sürece işbu sitede çerez kullanımı devam edecektir. Çerezlerin kullanım amacı
ve sürelerine işbu Politika’nın devamında değinilmiştir. Sitede yer alan
servislerin kullanımı için  doğrudan veya dolaylı olarak bazı bilgi ve veriler
paylaşılmaktadır. Bu bilgiler siteye kayıt olurken verilen kişisel bilgiler
(isim, e-posta adresi, telefon gibi) veya siteyi kullanımı (iletişim formunu
kullanırken, yorum yaparken vb.) esnasında toplanan bilgilerdir (IP, cihaz,
konum ve tarayıcı bilgileri, gezinti ve etkileşim içeriği, tarih ve bağlantı
süresi gibi veriler). Elde edilen bilgi ve verileri saklama konusunda ÖZGELECEK
A.Ş. tarafından azami özen gösterilmektedir. ÖZELECEK A.Ş. “ozgelecek.net”
sitesinin sunucularında depolanan çerez verilerini üçüncü kişiler ile paylaşmaz.
Sitede bulunan harici hizmetler (Canlı Destek eklentisi, sosyal medya
eklentileri vb.) tarafından depolanan çerezler ise harici hizmetlerin
sorumluluğunda olup ÖZGELECEK A.Ş.’nin herhangi bir sorumluluğu yoktur. Daha
detaylı bilgi için; “ozgelecek.net” sitesinden 'KVKK Aydınlatma Metni', ve
'Gizlilik ve Kişisel Verileri Koruma Politikası' incelenmelidir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>ÇEREZLERİN
KULLANIM AMAÇLARI</span></b></p>

<p class=MsoNormal style='margin-left:35.4pt;text-align:justify;text-indent:
-35.4pt'><span style='font-family:"Arial",sans-serif'>Çerezlerin farklı
kullanım amaçları olabilir:</span> </p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Güvenlik
Amaçlı Kullanımlar:</span></b><span style='font-family:"Arial",sans-serif'> “ozgelecek.net”
sistemlerinin idaresi ve güvenliğinin sağlanması amacıyla, bu sitedeki
fonksiyonlardan yararlanmayı sağlayan veyahut düzensiz davranışları tespit eden
çerezler kullanabilmektedir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>İşlevselliğe
Yönelik Kullanımlar:</span></b><span style='font-family:"Arial",sans-serif'>
“ozgelecek.net” sistemlerinin kullanımını kolaylaştırmak ve kullanıcı özelinde
kullanım özellikleri sağlamak amacıyla, kullanıcıların bilgilerini ve geçmiş
seçimlerini hatırlatan çerezler kullanabilmektedir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Performansa
Yönelik Kullanımlar: </span></b><span style='font-family:"Arial",sans-serif'>“ozgelecek.net”,
sistemlerinin performansının artırılması ve ölçülmesi amacıyla, gönderilen
iletilerle olan etkileşimi ve kullanıcı davranışlarını değerlendiren ve analiz
eden çerezler kullanabilmektedir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Reklam
Amaçlı Kullanımlar:</span></b><span style='font-family:"Arial",sans-serif'> “ozgelecek.net”
kendine veya üçüncü taraflara ait sistemlerin üzerinden kullanıcıların ilgi
alanları kapsamında reklam ve benzeri içeriklerin iletilmesi amacıyla, bu
reklamların etkinliğini ölçen veya tıklanma durumunu analiz eden çerezler
kullanabilmektedir.</span> </p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Web
sitesine yönelik şüpheli eylemlerin tespiti ve gerekli güvenliğin sağlanması,
ziyaretçilerin tercihleri doğrultusunda web sitesinin işlevsellik ve
performansın artırılması, web sitesinde sunulan hizmetlerin ve ürünlerin
geliştirilmesi ve kişiselleştirilmesiyle, bu hizmetlere ve ürünlere ulaşımın
kolaylaştırılması, web sitesinin hukuki sorumluluklarının yerine getirilmesi
amaçlı çerezler kullanmaktadır. Ayrıca, web sitesi ziyaretçilerini daha geniş
kapsamlı hizmet sağlayıcılar ile buluşturulabilmesi amacıyla reklam çerezleri
ve üçüncü taraflarla bilgi paylaşımı da söz konusudur.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>ÇEREZ
ÇEŞİTLERİ</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Zorunlu
Çerezler: </span></b><span style='font-family:"Arial",sans-serif'>Zorunlu
çerezler, internet sitesinin çalışması ve amaçlandığı gibi kullanılabilmesi
için kesinlikle gerekli olan çerezlerdir. Zorunlu çerezlerin engellenmesi
halinde, internet sitesinin bazı bölümleri çalışmayacaktır. Bu çerezler,
güvenliği iyileştirme, aramaları kaydetme, oturum açma veya benzer işlevlerin
kullanılmasına imkân tanır ve pazarlama faaliyetleri amacıyla kullanılmazlar.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Teknik
Çerezler: </span></b><span style='font-family:"Arial",sans-serif'>Teknik
Çerezler, sitenin olması gerektiği gibi çalışmasını sağlamaktadır.  Sitenin
çalışmayan sayfaları ve alanları bu çerezlerce tespit edilir ve onarımı için
aksiyon alınır. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Otantikasyon
Çerezleri: </span></b><span style='font-family:"Arial",sans-serif'>Ziyaretçilerin
siteye şifrelerini kullanarak giriş yapmaları durumunda otantikasyon çerezleri
site içinde ziyaret devam ettikçe tekrar şifre sorulmamasını sağlar. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>İşlevsel
Çerezler:</span></b><span style='font-family:"Arial",sans-serif'> İşlevsel
çerezler, internet sitesini ziyaret eden kullanıcıların tercihlerine dayalı
olarak site içeriğini onlar için bireyselleştirmeyi sağlar. Bu çerezlerin
kullanımının engellenmesi mümkündür.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Flash
Çerezleri: </span></b><span style='font-family:"Arial",sans-serif'>Sitede yer
alan görüntü ve ses içeriklerinin etkinleştirilmesi için kullanılan çerez
türleridir. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Kişiselleştirme
Çerezleri: </span></b><span style='font-family:"Arial",sans-serif'>Kullanıcıların
tercihlerini (ör. Dil Seçeneği) farklı internet sitelerinin farklı sayfaları
için hatırlamasını sağlayan çerezlerdir. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Performans
Çerezleri:</span></b><span style='font-family:"Arial",sans-serif'> Performans
çerezleri, web sitesinin geliştirilmesinde yardımcı olur. Kullanıcı deneyimini
iyileştirmek amacıyla, sitenin ne şekilde ne kadar süre kullanıldığı gibi
bilgileri toplar.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Analitik
Çerezler: </span></b><span style='font-family:"Arial",sans-serif'>Analitik
çerezler, siteyi ziyaret edenlerin sayılarının belirlenmesi, hangi sayfaların
ziyaret edildiğinin tespiti, sitenin ziyaret edildiği saatler gibi verilerle
ilgili sonuç üretimini sağlayan çerezlerdir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Reklam/Pazarlama
Çerezleri:</span></b><span style='font-family:"Arial",sans-serif'> Reklam
çerezleri, kullanıcıların ilgi alanlarına göre ve onlara uygun içerikler ile
reklamları sunmak (“hedeflenmiş reklam/tanıtım” veya “davranışsal reklamcılık”)
amacıyla kullanılır. Çerezler yoluyla elde edilen bilgileri, aynı kişiye ait
diğer verilerle eşleştirerek, ilgililere daha uygun içerikleri, kişiye özel
kampanya ve ürünleri sunar ve daha önceden istenmediği belirtilen içerik veya
fırsatları bir daha sunmaz. Bu çerezlerin kullanımının engellenmesi mümkündür.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Oturum
Çerezleri: </span></b><span style='font-family:"Arial",sans-serif'>Oturum
çerezi, tarayıcı kapatıldığında sona erer. Bu çerezler, internet sitesinin
kullanıcının tarayıcı kullandığı sürece hareketlerinin tanınmasını sağlar.
Örneğin, sitede dolaşırken kullanıcının alışveriş sepetine eklediği ürünlerin
hatırlanması amacıyla kullanılabilir. Kullanıcının internet bankacılığına veya
web maile giriş yaptığı durumlarda da güvenlik amacıyla kullanılabilir. Bu
çerezler oturum sonuna kadar kullanılır, sonrasında cihazda saklanmazlar.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Kalıcı
Çerezler:</span></b><span style='font-family:"Arial",sans-serif'> Kalıcı
çerezler, uzun süre boyunca cihazda saklanırlar. Kullanıcının sitedeki
tercihlerini hatırlatmak amacıyla kullanılır. Bu çerezler, kullanıcının
cihazının sabit diskinde uzun bir süre için veya süresiz olarak kullanıcı
tarafından silinceye kadar mevcudiyetini korur. Bu çerezlerin, cihaza
yerleştirildiği süre ve çerezin sona erme süresi arasındaki uzunluğu internet
sitesi sahibi belirler. Kullanıcılar, bu çerezleri silebilirler.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Birinci
Taraf Çerezler: </span></b><span style='font-family:"Arial",sans-serif'>Birinci
taraf çerezler, ziyaret edilen internet sitesi tarafından oluşturulan
çerezlerdir.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Üçüncü
Taraf Çerezleri: </span></b><span style='font-family:"Arial",sans-serif'>Üçüncü
taraf çerezler, diğer siteler, iş ortakları veya servis sağlayıcıları gibi
üçüncü taraflarca oluşturulur. Bu çerezler genellikle, ziyaret edilen internet
sitesinin diğer sitelere ait resim, reklamcılık, sosyal medya eklentilerini
içermesi halinde söz konusu olur.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif;
color:red'>ÖZGELECEK A.Ş.’ye AİT “ozgelecek.net” ADRESLİ İNTERNET SİTESİNDE
KULLANILAN ÇEREZLER VE KULLANIM SÜRELERİ</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='color:red'><img
width=497 height=186 id="Resim 4"
src="https://i.ibb.co/qMb0gCZ/image001.png"></span><span
style='font-family:"Arial",sans-serif;color:red'>(ÖRNEKTİR)</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Çerezler,
işbu Politika’da belirtilen amaçlar dışında kullanılmamakta olup, tüm ilgili
işlemler Kişisel Verilerin Korunması Kanunu’na (KVKK) uygun olarak
yürütülmektedir. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>ÇEREZLERİN
SİLİNMESİ VE/VEYA ENGELLENMESİ</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Çerezleri
istenildiğinde silinebilir ve/veya engellenebilir. Kullanılan cihazda
hâlihazırda mevcut olan çerezleri silinebilir ve web tarayıcınızı çerezleri
engelleyebilecek şekilde ayarlanabilir. Bu ayarlama kullanılan cihaz ve
internet tarayıcısına göre değişiklik göstermektedir.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Aşağıda
farklı internet tarayıcıları üzerinden çerezlerin kullanılmasını engellemek
için hangi adımların izlenmesi gerektiğine ilişkin bilgiler yer almaktadır:</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>•
Internet Explorer</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>1.
Masaüstünü açın ve görev çubuğunda Internet Explorer simgesine dokunun veya
tıklayın. </span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>2.
Araçlar düğmesine ve İnternet seçenekleri'ne dokunun veya tıklayın.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>3.
Gizlilik sekmesine dokunun veya tıklayın, ardından tüm tanımlama bilgilerini
engellemek için Ayarlar'ın altında bulunan kaydırıcıyı yukarıya hareket ettirin
ve Tamam düğmesine dokunun veya tıklayın.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>•
Microsoft Edge</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>1.
Microsoft Edge tarayıcınızın sağ üst köşesinden üç nokta işareti olan bölüme
tıklayın ve Ayarlar bölümüne gelin.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>2.
Karşınıza gelen yeni pencereden Temizlenecek Öğeleri Seç dedikten sonra
karşınıza gelen pencereden temizlemek istediğiniz bölümleri seçin.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>3.
Burada birçok bölüm bulunmaktadır. İstediğinizi seçerek temizleme işlemine
başlayabilirsiniz</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>•
Google Chrome</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>1.
Bilgisayarınızda Chrome'u açın.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>2.
Sağ üstte Diğer Ayarlar'ı tıklayın.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>3.
Altta Gelişmiş'i tıklayın.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>4.
&quot;Gizlilik ve güvenlik&quot;in altında İçerik ayarları'nı tıklayın.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>5.
Çerezler'i tıklayın.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>6.
&quot;Tüm çerezler ve site verileri&quot;nin altında Web Sitesi'nin adını
arayın.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>7.
Sitenin sağındaki Kaldır simgesini tıklayın</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>•
Mozilla Firefox</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>1.
Firefox Menü düğmesine tıklayın ve Seçenekler'i seçin.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>2.
Gizlilik ve Güvenlik panelini seçin ve Geçmiş bölümüne gidin.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>3.
Firefox ayarını geçmiş için özel ayarları kullansın olarak değiştirin.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>4.
Çerezleri göster... düğmesine tıklayın. Çerezler penceresi görünecektir.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>5.
Arama: alanına, çerezlerini silmek istediğiniz sitenin adını yazın. Aramanızla
eşleşen çerezler görüntülenecektir.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>6.
Silmek istediğiniz çerez(ler)i seçip Seçilenleri sil düğmesine tıklayın.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>7.
Kapat düğmesine tıklayarak Çerezler penceresini kapatın. Sonra da
about:preferences sayfasını kapatın</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>•
Safari</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>1.
Safari &gt; Tercihler'i seçin.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>2.
Gizlilik öğesini tıklayın.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>3.
Web Sitesi Verilerini tıklayın.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>4.
Bir veya daha fazla web sitesi seçin ve sonra Sil veya Tümünü Sil'e tıklayın. Ziyaret
ettiğiniz web sitelerinin geride bıraktığı tüm çerezleri silmeniz, web sitesini
her ziyaret edişinizde verdiğiniz bilgileri tekrar girmeniz ve web sitesinde
yer alan bazı hizmetleri gerektiği gibim çalışmasını engellediğini unutmayınız.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Yukarıda
bahsi geçenler dışında kullanılan tarayıcılarda ise “Yardım” fonksiyonunu
kullanarak ilgili ayarların yerini bulabilirsiniz.</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>GİZLİLİK
POLİTİKASI</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Yukarıda
adı geçen çerezlerle toplanan veriler “KVKK Aydınlatma Metni” ve “Gizlilik Ve
Kişisel Verilerin Korunması Politikası” kapsamında korunmaktadır. </span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Toplanan
verilerle ilgili internet sitemizin “Gizlilik ve Kişiler Verilerin Korunması
Politikası” ve “KVKK Aydınlatma Metni”ne ozgelecek.net adresinden
ulaşabilirsiniz. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>ÇEREZ
UYARISI</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>İşbu
sitede deneyiminizi iyileştirmek için çerez kullanılmaktadır. İnternet sitemizi
kullanmak için çerez kullanımı zorunludur. Bu bağlamda çerezleri kabul
etmemeniz internet sitemizden yararlanamayacağınız ya da tam olarak olması
gerektiği şekilde yararlanamayacağınız anlamına gelebilir.  Sitede gezinmeye
devam ederek çerez kullanılmasına izin vermiş olmaktasınız. Çerezler ile
toplanan kişisel verileriniz işbu Çerez Politikamızda belirtilen amaçlarla ve
mevzuata uygun olarak kullanılacaktır. </span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>İnternet
sitesini görüntülemek için farklı araçlar (ör. Bilgisayar, cep telefonu ya da
tablet) kullanıyorsanız, bu cihazların her birinde kullandığınız tarayıcının
çerez tercihlerinize uygun olduğundan emin olun. </span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>İLETİŞİM
BİLGİLERİMİZ</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>Politika
ile ilgili soru ve görüşlerinizi ÖZGELECEK A.Ş.’ye iletmek için aşağıdaki
adrestebelirtilen iletişim yollarından birini kullanabilirsiniz:</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>E-posta
:</span></b><span style='font-family:"Arial",sans-serif'> _________</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>Adres
   :</span></b><span style='font-family:"Arial",sans-serif'> Erzene Mah.
Gençlik Cad. No:11 Bornova/İZMİR</span></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-family:"Arial",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Arial",sans-serif'>&nbsp;</span></p>

</div>

</body>

</html>
`