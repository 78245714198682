import { Typography, Box } from '@mui/material'
import { DashboardLayout, DashboardLayoutMobile } from 'layouts'
import { Iconify } from 'components'
import { HelpCenterSection } from 'sections'

export const CompanyHelpCenterPageMobile = () => {
    return (
        <DashboardLayoutMobile>

            <Typography variant='h1' mb={3}>
                Yardım Merkezi
            </Typography>

            <Box bgcolor={"secondary.lighter"} p={2} mt={2} mb={4} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                {/* <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}>
                    <Iconify icon={"material-symbols:info-outline"} />
                </Box> */}
                <Typography variant="body2" >
                    Bu sayfa üzerinden şirketinize yapılan başvuruları inceleyebilir, adayları değerlendirebilir, listeler oluşturabilir ve adayları bu listelere ekleyebilirsiniz.
                </Typography>
            </Box>

            <HelpCenterSection list={[]} />


        </DashboardLayoutMobile>)
}
