

import { userApi } from "../userApi";
import { TReferenceApproveReferenceInformationMutationVariables, TReferenceApproveReferenceMutationVariables, TReferenceRejectReferenceInformationMutationVariables, TReferenceRejectReferenceMutationVariables, reference_approveReference, reference_approveReferenceInformation, reference_rejectReference, reference_rejectReferenceInformation } from "./mutations";
import { candidate_referenceQuestions, reference_reference } from "./queries";


const referencerUserApi = userApi.injectEndpoints({
    endpoints: (builder) => ({

        // #################### User Queries #################
        getReferenceInformation: builder.query<any, any>({
            query: ({ variables }) => {
                // console.log({ variables });
                return ({
                    url: "",
                    body: reference_reference({ variables })
                })
            },
            transformResponse: (response: any) => {
                return response.data.reference_reference
            },
            providesTags: ["referencer"]
        }),

        getReferenceQuestions: builder.query<any, any>({
            query: ({ variables }) => {
                // console.log({ variables });
                return ({
                    url: "",
                    body: candidate_referenceQuestions({ variables })
                })
            },
            transformResponse: (response: any) => {
                return response.data.candidate_referenceQuestions
            },
            // providesTags: ["referencer"]
        }),



        // #################### User Mutations #################
        referenceRejectReference: builder.mutation<any, TReferenceRejectReferenceMutationVariables>({
            query: ({ variables }) => {
                return ({
                    url: "",
                    body: reference_rejectReference({ variables })
                })
            },
            transformResponse: (response: any) => {
                return response
            },
            invalidatesTags: ["referencer"]
        }),

        referenceRejectReferenceInformation: builder.mutation<any, TReferenceRejectReferenceInformationMutationVariables>({
            query: ({ variables }) => {
                return ({
                    url: "",
                    body: reference_rejectReferenceInformation({ variables })
                })
            },
            transformResponse: (response: any) => {
                return response
            },
            invalidatesTags: ["referencer"]
        }),

        referenceApproveReference: builder.mutation<any, TReferenceApproveReferenceMutationVariables>({
            query: ({ variables }) => {
                return ({
                    url: "",
                    body: reference_approveReference({ variables })
                })
            },
            transformResponse: (response: any) => {
                return response
            },
            invalidatesTags: ["referencer"]
        }),

        referenceApproveReferenceInformation: builder.mutation<any, TReferenceApproveReferenceInformationMutationVariables>({
            query: ({ variables }) => {
                return ({
                    url: "",
                    body: reference_approveReferenceInformation({ variables })
                })
            },
            transformResponse: (response: any) => {
                return response
            },
            invalidatesTags: ["referencer"]
        }),



    }),
})


export const {
    // ########## queries ###############
    useGetReferenceInformationQuery,
    useLazyGetReferenceInformationQuery,

    useGetReferenceQuestionsQuery,
    useLazyGetReferenceQuestionsQuery,

    // ########## mutation ###############
    useReferenceRejectReferenceMutation,
    useReferenceRejectReferenceInformationMutation,
    useReferenceApproveReferenceMutation,
    useReferenceApproveReferenceInformationMutation,

} = referencerUserApi