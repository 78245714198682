
export type TReferenceApproveReferenceInformationMutationVariables = {
    variables: {
        token: string,
        referenceInformation: {
            jobTitle: string,
            name: string,
            phoneNumber: string,
            relationWithCandidate: string,
            surname: string,
        }
    }
}

export const reference_approveReferenceInformation = ({ variables }: TReferenceApproveReferenceInformationMutationVariables) => {

    const data = JSON.stringify({
        query: `
        mutation Reference_approveReferenceInformation($token: String!, $updateInput: UpdateReferenceApprovalInput!) {
            reference_approveReferenceInformation(token: $token, updateInput: $updateInput) {
              success
            }
          }
        `,
        variables: {
            "token": variables?.token,
            "updateInput": {
                "referenceInformation": {
                    "jobTitle": variables?.referenceInformation?.jobTitle ?? null,
                    "name": variables?.referenceInformation?.name ?? null,
                    "phoneNumber": variables?.referenceInformation?.phoneNumber ?? null,
                    "relationWithCandidate": variables?.referenceInformation?.relationWithCandidate ?? null,
                    "surname": variables?.referenceInformation?.surname ?? null
                }
            }
        }
    })

    return data

}