export type TCandidateCreateOzgelecekVariables = {
    variables: {
        field: string,
        references: Array<{ _id: string }>,

        testResults: {

            areaTestResults: Array<{
                answer: number,
                question: {
                    _id: string
                }
            }>,
            motivationTestResults: Array<{
                answer: number,
                question: {
                    _id: string
                }
            }>,
        },

        ozgelecekTest: {
            first: {
                text?: string | null,
                video?: string | null
            },
            second: {
                text?: string | null,
                video?: string | null
            }
        }
    }
}

export const candidate_createOzgelecek = ({ variables }: TCandidateCreateOzgelecekVariables) => {

    const data = JSON.stringify({
        query: `
        mutation Candidate_createOzgelecek($ozgelecekInput: OzgelecekInput!) {
            candidate_createOzgelecek(ozgelecekInput: $ozgelecekInput) {
              _id
            }
          }
        `,
        variables: {
            "ozgelecekInput": {
                "field": variables?.field ?? null,
                "references": variables?.references ?? [],
                "testResults": {
                    "areaTestResults": variables?.testResults?.areaTestResults ?? [],
                    "motivationTestResults": variables?.testResults?.motivationTestResults ?? [],
                },
                "ozgelecekTest": variables?.ozgelecekTest ?? null,
            }
        }
    })

    return data
}