import { Box, LinearProgress, Typography } from "@mui/material"

interface IOzgelecekProgressBarProps {
    totalstep: number,
    currentstep: number,
}
export const OzgelecekProgressBar = ({ totalstep, currentstep, }: IOzgelecekProgressBarProps) => {

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" value={100 * (currentstep / totalstep)} sx={{ height: 10 }} />
            </Box>
            &nbsp;&nbsp;
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body1" color="text.secondary" fontWeight="bold">
                    {`${currentstep} / ${totalstep}`}
                </Typography>
            </Box>
        </Box>
    )
}