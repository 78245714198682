import { IUserProductsItem } from "./_user_products";

export interface IUserProductResponse extends IUserProductsItem {}

export interface IUserProductVariables {
  productId: string
}

export const user_product = (variables: IUserProductVariables) => {

  const data = JSON.stringify({
    query: `
      query user_product($productId: ID!) {
        user_product(productId: $productId) {
          _id
          amount
          name
          price
          type
          targetRoles
          listUnitPrice
          unitPrice
          discount
          currency
          purchaseMethod
          imageUrl
          createdAt
          updatedAt
        }
      }
    `,
    variables: variables
  })

  return data

}