import moment from 'moment';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, Chip, Skeleton, Divider, IconButton, Stack, Typography, Grid, Dialog, DialogActions, DialogContent } from '@mui/material'

import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

import { routes } from 'routes';
import { enumFunctions } from 'utils';
import { DashboardLayoutMobile } from 'layouts'
import { CreateApplicationDialog } from 'components';
import { CandidateAdvertisementDetailImage } from 'assets';
import { setCandidateCreateOzgelecekFormField, useAppDispatch, useCandidateCreateApplicationMutation, useGetCandidateAdvertisementDetailQuery, useGetCandidateOzgeleceksQuery } from 'context'
import {
    LanguageEnums,
    WorkTypeEnums,
    PlaceOfWorkEnums,
    PositionLevelEnums,
    OzgelecekFieldEnum,
    NumberOfEmployeeEnum,
    LanguageLevelEnum,
    getNameOfEnum,
    HigherEducationDegreeEnum,
} from 'types';

import 'moment/locale/tr';
import { enqueueSnackbar } from 'notistack';
import HTMLReactParser from 'html-react-parser';



export const CandidateAdvertisementDetailsPageMobile = () => {

    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [applyDialog, setApplyDialog] = useState<any>(null)
    const { data: ozgeleceks } = useGetCandidateOzgeleceksQuery({})

    const [createApplication, { isLoading: createApplicationLoading }] = useCandidateCreateApplicationMutation()
    const { data: advertisement, isLoading } = useGetCandidateAdvertisementDetailQuery({ advertisementId: id ?? "" })

    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (image: any) => {
        setSelectedImage(image);
    };

    const handleClose = () => {
        setSelectedImage(null);
    };

    const handleCreateApplication = async ({ selectedOzgelecek }: any) => {


        try {
            // const selectedOzgelecek: any = ozgeleceks?.data?.find((item: any) => item?.field === applyDialog?.field)

            // console.log({ advertisement: { _id: advertisement?._id ?? "" }, ozgelecek: { _id: selectedOzgelecek?._id } });
            const response: any = await createApplication({ advertisement: { _id: advertisement?._id ?? "" }, ozgelecek: { _id: selectedOzgelecek?._id } })
            console.log(response, "response")
            if (response?.data?.data?.candidate_createApplication?._id) {
                enqueueSnackbar("Başvuru başarılı bir şekilde yapıldı!")
                setApplyDialog(null)
            }
            if (response?.data?.errors) {
                if (response?.data?.errors?.[0]?.message === "AlreadyApplied") {
                    enqueueSnackbar("Bu ilana zaten başvuru yaptınız!", { variant: "warning" })
                    setApplyDialog(null)
                } else {
                    enqueueSnackbar("İlana başvururken bir hata oluştu.", { variant: "error" })
                    setApplyDialog(null)
                }
            }
        } catch (error) {
            console.log({ error });
        }
    }

    console.log(advertisement, "advertisement")


    return (
        <DashboardLayoutMobile>

            <Stack direction="row" gap={3} alignItems="center" sx={{ mb: 5 }}>
                <IconButton onClick={() => navigate(routes.candidate.dashboard.advertisementsMobile)}>
                    <ArrowBackIosNewRoundedIcon />
                </IconButton>
                <Typography variant='h1'>
                    İlan Detayı
                </Typography>
            </Stack>


            <Box>
                {
                    isLoading ? (
                        <Box>
                            <Skeleton
                                key={id}
                                variant='rectangular'
                                sx={{
                                    p: 2,
                                    width: '100%',
                                    height: '150px'
                                }}
                            />

                            <Stack direction={{ xs: 'column', md: 'row' }} gap={3} mt={3} >

                                <Stack width={{ xs: '100%', md: '70%' }} gap={3} >
                                    <Skeleton
                                        variant='text'
                                        sx={{
                                            p: 2,
                                            width: '100%',
                                            height: '50px'
                                        }}
                                    />
                                    <Skeleton
                                        variant='text'
                                        sx={{
                                            p: 2,
                                            width: '100%',
                                            height: '50px'
                                        }}
                                    />
                                    <Skeleton
                                        variant='text'
                                        sx={{
                                            p: 2,
                                            width: '100%',
                                            height: '50px'
                                        }}
                                    />
                                    <Skeleton
                                        variant='text'
                                        sx={{
                                            p: 2,
                                            width: '100%',
                                            height: '50px'
                                        }}
                                    />
                                </Stack>

                                <Skeleton
                                    variant='rectangular'
                                    sx={{
                                        p: 2,
                                        width: '30%',
                                        height: '300px'
                                    }}
                                />

                            </Stack>
                        </Box>
                    ) :
                        (
                            <Stack direction={{ xs: 'column', md: 'column' }} gap={3} >

                                {/* ****************** advertisement header ***************** */}
                                <Box
                                    sx={{
                                        p: { xs: 2, md: 4 },
                                        backgroundImage: `url(${CandidateAdvertisementDetailImage})`,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'contain'
                                    }}
                                >
                                    <Stack
                                        direction={{ xs: 'column', md: 'row' }}
                                        // justifyContent={{ xs: 'start' }}
                                        alignItems={{ xs: 'center' }}
                                        gap={3}
                                        sx={{
                                            bgcolor: '#FFFFFFcc',
                                            borderWidth: '2px',
                                            borderColor: 'primary.lighter',
                                            borderStyle: 'solid',
                                            p: 2,
                                        }}
                                    >

                                        <Box
                                            component="img"
                                            src={advertisement?.createdBy?.companyInformation?.profileImage}
                                            alt=""
                                            sx={{
                                                width: { xs: '100px', md: '100px' },
                                                borderRadius: '50%',
                                            }}
                                        />

                                        <Stack
                                            direction={{ xs: 'column', md: 'row' }}
                                            sx={{ flex: 1, }}
                                            gap={3}
                                        >

                                            <Stack direction="column" gap={1} flex={1} sx={{ textTransform: 'capitalize' }}>
                                                <Typography variant='h2' color={"primary.darker"} sx={{ fontSize: { xs: '15px', md: '26px' } }}>
                                                    {advertisement?.title}
                                                </Typography>

                                                <Typography variant='body1'>
                                                    <span style={{ color: '#000', fontWeight: 'bold' }}>
                                                        {enumFunctions.findAndDisplayName(OzgelecekFieldEnum, advertisement?.field ?? "")}
                                                    </span> ·&nbsp;
                                                    {/* {advertisement?.districts?.length > 0 ? advertisement?.districts[0] + ', ' : ''} {advertisement?.cities?.length > 0 ? advertisement?.cities[0] : ''} */}
                                                </Typography>
                                            </Stack>

                                            <Divider orientation="vertical" flexItem sx={{ my: 1 }} />

                                            <Stack justifyContent="center" alignItems="center">
                                                <Stack
                                                    direction="column"
                                                    gap={1}
                                                    justifyContent="space-between"
                                                    flexWrap="wrap"
                                                    sx={{ '&>*': { flex: 1 } }}
                                                >

                                                    {
                                                        advertisement?.isApplied ? (
                                                            <>
                                                                <Button color='error' variant='contained' sx={{ py: 1, px: 2 }}
                                                                    disabled
                                                                >
                                                                    <ThumbUpAltRoundedIcon fontSize='small' sx={{ mr: 1 }} /> Başvuru yapıldı
                                                                </Button>
                                                            </>
                                                        ) : (
                                                            <CreateApplicationDialog
                                                                loading={createApplicationLoading}
                                                                onClick={handleCreateApplication}
                                                                // title={`Apply to ${advertisement?.title ?? "advertisement"}`}
                                                                applyDialog={applyDialog}
                                                                setApplyDialog={setApplyDialog}
                                                                advertisement={advertisement}

                                                                onUpdate={() => {
                                                                    navigate(routes.candidate.dashboard.ozgeleceklerimMobile)
                                                                }}

                                                                onAddNew={() => {
                                                                    dispatch(setCandidateCreateOzgelecekFormField(advertisement?.field))
                                                                    navigate(
                                                                        routes.candidate.dashboard.ozgelecekCreateMobile,
                                                                        {
                                                                            state: { field: advertisement?.field, advertisementId: advertisement?._id }
                                                                        }
                                                                    )
                                                                }}
                                                            />
                                                        )
                                                    }

                                                    {/* <Typography>
                                                    {moment(advertisement?.createdAt ?? "").format('DD MMM YYYY')}
                                                </Typography>
                                                <Typography>
                                                    {moment(advertisement?.updatedAt ?? "").format('DD MMM YYYY')}
                                                </Typography> */}

                                                </Stack>


                                                {/* <Typography>
                                                <span style={{ color: '#000', fontWeight: 'bold' }}>
                                                    {enumFunctions.findAndDisplayName(LanguageEnums, advertisement?.language ?? "")} &nbsp;
                                                </span>
                                                {advertisement?.quota} kota
                                            </Typography> */}

                                            </Stack>

                                        </Stack>

                                    </Stack>
                                </Box>


                                {/* ****************** advertisement body ***************** */}
                                <Stack direction={{ xs: 'column', md: 'row' }} gap={3}>

                                    {/* ****************** advertisement information ***************** */}
                                    <Stack
                                        gap={3}
                                        sx={{
                                            flex: 1,
                                        }}
                                    >

                                        <Grid container spacing={2}>
                                            {advertisement?.images?.map((image, index) => (
                                                <Grid item xs={6} md={4} key={index}>
                                                    <img src={image} alt={image} onClick={() => handleImageClick(image)} style={{ cursor: 'pointer', width: '100%' }} />
                                                </Grid>
                                            ))}
                                            <Dialog open={selectedImage !== null} onClose={handleClose}>
                                                <DialogContent>
                                                    <img src={selectedImage as any} alt={selectedImage as any} style={{ width: '100%' }} />
                                                </DialogContent>
                                            </Dialog>
                                        </Grid>

                                        <Box>
                                            <Typography variant='h5'>
                                                Açıklama
                                            </Typography>

                                            <Typography variant='body2' >
                                                {HTMLReactParser(advertisement?.description ?? "")}
                                            </Typography>
                                        </Box>



                                        <Stack
                                            gap={1}
                                            sx={{
                                                '& p': {
                                                    color: '#5344d2',
                                                    fontWeight: 'bold',
                                                },
                                                '& span': {
                                                    color: '#000',
                                                    fontWeight: 'normal',
                                                },
                                            }}
                                        >

                                            <Typography variant='body2'>
                                                <span>Başlama Tarihi: </span>
                                                {moment(advertisement?.createdAt).locale('tr').format('DD MMMM YYYY')}
                                            </Typography>

                                            <Typography variant='body2'>
                                                <span>Departman: </span>
                                                {advertisement?.department}
                                            </Typography>

                                            <Typography variant='body2' >
                                                <span>Dil: </span>
                                                {enumFunctions.findAndDisplayName(LanguageEnums, advertisement?.language ?? "")}
                                            </Typography>

                                            <Typography variant='body2'>
                                                <span>İşe alınacak çalışan sayısı: </span>
                                                {advertisement?.quotaVisibleToApplicant ? advertisement?.quota : "-"}
                                            </Typography>

                                            <Typography variant='body2'>
                                                <span>Çalışma Şekli:</span> {enumFunctions.findAndDisplayName(PlaceOfWorkEnums, advertisement?.place ?? "")}
                                            </Typography>
                                            <Typography variant='body2'>
                                                <span>Pozisyon:</span> {enumFunctions.findAndDisplayName(PositionLevelEnums, advertisement?.positionLevel ?? "")}
                                            </Typography>
                                            <Typography variant='body2'>
                                                <span>İş Tipi:</span> {enumFunctions.findAndDisplayName(WorkTypeEnums, advertisement?.workType ?? "")}
                                            </Typography>


                                        </Stack>

                                        <Divider orientation="horizontal" variant="fullWidth" flexItem />

                                        <Box>
                                            <Typography variant='h5'>
                                                Gereklilikler
                                            </Typography>

                                            <Stack
                                                gap={1}
                                                sx={{
                                                    '& p': {
                                                        color: '#5344d2',
                                                        fontWeight: 'bold',
                                                        // fontWeight: 'normal',

                                                    },
                                                    '& span': {
                                                        color: '#000',
                                                        fontWeight: 'normal',
                                                        // fontWeight: 'bold',

                                                    },
                                                }}
                                            >
                                                {advertisement?.requiredDriverLicense && (<Typography variant='body2' mt={2}>
                                                    <span>Sürücü Belgesi: </span>
                                                    {advertisement?.requiredDriverLicense?.toUpperCase()}
                                                </Typography>)}


                                                {advertisement?.requiredLanguages ? advertisement?.requiredLanguages?.length > 0 ? (<Typography variant='body2'>
                                                    <span>Bilmesi Gereken Diller: </span><br />
                                                    {
                                                        advertisement?.requiredLanguages?.map((item: any, index: number) => {
                                                            return <Typography key={index} fontSize={"small"} >
                                                                {item.name} :
                                                                <i>
                                                                    {"Okuma: " + getNameOfEnum(LanguageLevelEnum, item.reading) +
                                                                        " | Yazma: " + getNameOfEnum(LanguageLevelEnum, item.writing) +
                                                                        " | Dinleme: " + getNameOfEnum(LanguageLevelEnum, item.listening)}
                                                                </i>
                                                            </Typography>
                                                        })
                                                    }
                                                </Typography>) : null : null}


                                                {advertisement?.requiredEducationLevel?.length ? (<Typography variant='body2' >
                                                    <span>Eğitim Seviyesi: </span>
                                                    {advertisement?.requiredEducationLevel?.map((item: any, index: number) => index === 0 ? getNameOfEnum(HigherEducationDegreeEnum, item)
                                                        : (', ' + getNameOfEnum(HigherEducationDegreeEnum, item)))}
                                                </Typography>) : null}



                                                {advertisement?.requiredSkills?.length ? (<Stack direction="row" spacing={1} flexWrap="wrap" alignItems={'center'}>

                                                    <Typography variant='body2' >
                                                        <span>Gerekli Özellikler: </span>

                                                    </Typography>

                                                    {
                                                        advertisement?.requiredSkills?.map((skill) => {
                                                            return (
                                                                <Chip
                                                                    key={skill}
                                                                    label={skill}
                                                                    color="secondary"
                                                                    sx={{ textTransform: 'capitalize' }}
                                                                    style={{ borderRadius: 100 }}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </Stack>) : null}
                                            </Stack>
                                        </Box>

                                        <Divider orientation="horizontal" variant="fullWidth" flexItem />



                                        <Typography variant='body2'>
                                            <span>Oluşturulma Tarihi : </span>
                                            {moment(advertisement?.createdAt).locale('tr').format('DD MMMM YYYY')}
                                        </Typography>


                                    </Stack>

                                    {/* ****************** company information ***************** */}
                                    <Stack
                                        onClick={() => navigate(routes.candidate.dashboard.aboutCompanyMobile + "/" + advertisement?.createdBy?._id?._id)}
                                        sx={{
                                            bgcolor: '#f1f1f1AA',
                                            borderWidth: '1px',
                                            borderColor: 'primary.lighter',
                                            borderStyle: 'dashed',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            px: 3,
                                            py: 5,
                                            height: 'fit-content',
                                            width: { xs: '100%', md: '300px' },
                                            cursor: 'pointer',
                                        }}
                                    >

                                        <Box
                                            component="img"
                                            src={advertisement?.createdBy?.companyInformation?.profileImage}
                                            alt=""

                                            sx={{
                                                width: { xs: '100px', md: '100px' },
                                                m: 'auto',
                                                borderRadius: '50%',
                                            }}
                                        />

                                        <Stack
                                            gap={2}
                                            sx={{
                                                mt: 5,
                                                flexDirection: 'column',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                '& span': {
                                                    fontWeight: 'bold',
                                                    color: '#000'
                                                }
                                            }}
                                        >

                                            <Typography variant='h3'>
                                                {advertisement?.createdBy?.companyInformation?.companyName}
                                            </Typography>

                                            <Typography variant='body1'>
                                                <span>Adres : </span> {advertisement?.createdBy?.companyInformation?.city}, {advertisement?.createdBy?.companyInformation?.district}
                                            </Typography>

                                            <Typography variant='body1'>
                                                <span>Sektör : </span> {advertisement?.createdBy?.companyInformation?.sector}
                                            </Typography>

                                            <Typography variant='body1'>
                                                <span>Çalışan Sayısı : </span> {enumFunctions.findAndDisplayName(NumberOfEmployeeEnum, advertisement?.createdBy?.companyInformation?.numberOfEmployees ?? "")}
                                            </Typography>

                                            <Typography variant='body1'>
                                                <span>Şirket Hakkında : </span> {advertisement?.createdBy?.companyInformation?.companyBrief}
                                            </Typography>

                                            <Typography variant='body1'>
                                                <span>Website : </span>  {advertisement?.createdBy?.companyInformation?.website}
                                            </Typography>

                                        </Stack>

                                    </Stack>

                                </Stack>

                            </Stack>
                        )
                }
            </Box>


        </DashboardLayoutMobile >
    )
}
