

export interface ICompanyBackgroundImagePresignedUrlResponse {
    presignedUploadUrl: string
}


export interface ICompanyBackgroundImagePresignedUrlVariables {
    questionIndex: string
}



export const companyBackgroundImagePresignedUrl = (variables: ICompanyBackgroundImagePresignedUrlVariables) => {

    const data = JSON.stringify({
        query: `
        query OrganizationPresignedUrls($organizationId: ID!, $contentType: ImageContentType!) {
            organizationPresignedUrls(organizationId: $organizationId, contentType: $contentType) {
              backgroundImage {
                url
                presignedUploadUrl
                presignedThumbnailUpload
              }
            }
          }
        `,
        variables: {
            "organizationId": null,
            "contentType": null
        }
    })

    return data
}