

export interface ICandidateOzgeleceksItem {
  _id: string;
  candidate: {
    _id: string;
  }
  createdAt: string;
  field: string;
  ozgelecekTest: {
    first: {
      text: string;
      video: string;
    }
    second: {
      text: string;
      video: string;
    }
  }
  references: {
    _id: string;
  }
  testResults: {
    motivationTestResults: {
      answer: string;
      question: {
        _id: string;
      }
    }
    fieldTestResults: {
      answer: string;
      question: {
        _id: string;
      }
    }
    areaTestScore: number
    motivationTestScore: number
  }
  updatedAt: string;
}

export interface ICandidateOzgelecekResponse {
  count: number;
  data: Array<ICandidateOzgeleceksItem>
}

export interface ICandidateOzgelecekVariables {
  variables?: {
    filter: {
      field: string | null
    },
    pagination: {
      page: number | null,
      pageSize: number | null,
      sort: string | null
    }
  }
}


export const candidate_ozgeleceks = ({ variables }: ICandidateOzgelecekVariables) => {

  const data = JSON.stringify({
    query: `
        query Candidate_ozgeleceks($filter: FilterOzgelecek, $pagination: Pagination) {
            candidate_ozgeleceks(filter: $filter, pagination: $pagination) {
              count
              data {
                _id
                candidate {
                  _id
                }
                createdAt
                field
                ozgelecekTest {
                  first {
                    video
                    text
                  }
                  second {
                    text
                    video
                  }
                }
                references {
                  _id
                }
                testResults {
                  areaTestResults {
                    answer
                    question {
                      _id
                    }
                  }
                  motivationTestResults {
                    answer
                    question {
                      _id
                    }
                  }

                  areaTestScore
                  motivationTestScore

                }
                updatedAt
              }
            }
          }
        `,
    variables: {
      filter: {
        "field": variables?.filter?.field ?? null
      },
      pagination: {
        "page": variables?.pagination?.page ?? null,
        "pageSize": variables?.pagination?.pageSize ?? null,
        "sort": variables?.pagination?.sort ?? null
      }
    }

  })

  return data
}
