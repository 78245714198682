import { Stack, Avatar, Typography, Divider, Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Chip, Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import HigherEducationDegree from 'enums/user/user/education/HigherEducationDegree'
import moment from 'moment'
import React from 'react'
import { getNameOfEnum, SexEnum, GenderEnum, LanguageLevelEnum, DriverLicenseStatusEnum, OzgelecekFieldEnum, CandidateReferenceStatusEnums, ComputerKnowledgeLevelEnum, ProgrammingLanguageLevelEnum } from 'types'
import { useGetCompanyOzgeleceksQuery, useLazyCandidateTestVideoDownloadPresignedUrlQuery, useLazyGetCompanyReferenceQuestionsQuery } from 'context'
import { ArrowDownward } from '@mui/icons-material'
import { enqueueSnackbar } from 'notistack'
import { useLocation } from 'react-router-dom'

type Props = {
    candidateDetail: any,
    selectedAdvertisement?: any,
    radarHistory?: any
}

export const CompanyCandidateCard = ({ candidateDetail, selectedAdvertisement, radarHistory }: Props) => {

    const { data: ozgeleceks } = useGetCompanyOzgeleceksQuery({ candidateId: candidateDetail?._id ?? "" })
    const [selectedOzgelecek, setSelectedOzgelecek] = React.useState<any>(null)
    const [getPresignedUrl, { data: videoDownloadPresignedUrl }] = useLazyCandidateTestVideoDownloadPresignedUrlQuery()
    const [getReferenceQuestions, { data: referenceQuestions }] = useLazyGetCompanyReferenceQuestionsQuery()

    const path = useLocation().pathname


    console.log(radarHistory, "radarHistory")

    const handleDownloadVideo = (index: number) => {
        console.log("Download Video", index)
        getPresignedUrl({ candidateId: candidateDetail?._id, ozgelecekField: selectedOzgelecek?.field, questionIndex: index }).then((response) => {
            console.log(response?.data?.candidateTestVideoDownloadPresignedUrl)
            window.open(response?.data?.candidateTestVideoDownloadPresignedUrl, "_blank")
        })
    }

    const handleOzgelecekDetail = (ozgelecek: any) => {
        setSelectedOzgelecek(ozgelecek)
        getReferenceQuestions({ candidateId: candidateDetail?._id })
    }

    console.log(referenceQuestions, "referenceQuestions")

    console.log(ozgeleceks, "ozgeleceks")
    console.log(candidateDetail, "cacsd")
    console.log(selectedAdvertisement, "selectedAdvertisement")

    return <Stack>
        <Typography fontSize={"small"} fontWeight={"bold"} color={'#888'} textAlign={"right"} >
            Güncelleme tarihi: {moment(candidateDetail?.updatedAt).format("DD MMMM YYYY")}
        </Typography>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} bgcolor={"secondary.lighter"} p={2} mb={2}>
            <Stack spacing={2} direction={"row"} alignItems={"center"}>
                <Avatar alt={candidateDetail?._id} src={candidateDetail?.personalInformation?.profileImageUrl} sx={{ width: 80, height: 80 }} />
                <Stack spacing={0.5}>
                    <Typography variant={"h6"}>{candidateDetail?.personalInformation?.name} {candidateDetail?.personalInformation?.surname}</Typography>
                    <Typography fontSize={"small"}>📞 <a href={`tel:${candidateDetail?.personalInformation?.phoneNumber}`} style={{ color: 'black', fontWeight: "bold" }}>
                        {candidateDetail?.personalInformation?.phoneNumber}</a> </Typography>
                    <Typography fontSize={"small"}>📧 <a href={`mailto:${candidateDetail?.contact?.email}`} style={{ color: 'black', fontWeight: "bold" }}>
                        {candidateDetail?.contact?.email}</a> </Typography>
                </Stack>
            </Stack>
            {selectedAdvertisement ? <Stack spacing={1} direction={"column"} flexWrap={"wrap"}>
                <Typography fontSize={"small"} fontWeight={"bold"} textAlign={"center"}>Özgelecek<br />Bilgisine Eriş ↓</Typography>
                {ozgeleceks?.filter((ozgelecek: any) => ozgelecek?.field === selectedAdvertisement?.field).map((ozgelecek: any, index: any) =>
                    <Box key={index} bgcolor={"white"} p={1} borderRadius={2} border={"1px solid #555"} sx={{ cursor: 'pointer', boxShadow: "0px 0px 5px 0px #00000066" }}
                        onClick={() => handleOzgelecekDetail(ozgelecek)}>
                        <Typography fontSize={"small"} textAlign={"center"} fontWeight={"bold"}>{getNameOfEnum(OzgelecekFieldEnum, ozgelecek?.field)}</Typography>
                    </Box>)}
            </Stack> : null}
        </Stack>


        <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} justifyContent="space-between" alignItems="flex-start">
            <Stack spacing={1} width={{ xs: '100%', sm: "300px" }} >
                <Typography fontWeight={"bold"}>Kişisel Bilgiler</Typography>
                <Typography fontSize={"small"}><strong>Doğum Tarihi: </strong> {moment(candidateDetail?.personalInformation?.birthDate).format("DD MMMM YYYY")}</Typography>
                <Typography fontSize={"small"}><strong>Cinsiyet: </strong> {getNameOfEnum(SexEnum, candidateDetail?.personalInformation?.sex)}</Typography>
                {/* <Typography fontSize={"small"}><strong>Cinsel Tercih:</strong> {getNameOfEnum(GenderEnum, candidateDetail?.personalInformation?.gender)} {getNameOfEnum(GenderEnum, candidateDetail?.personalInformation?.genderDetail)}  </Typography> */}
                <Typography fontSize={"small"}><strong>Uyruk: </strong> {candidateDetail?.personalInformation?.nationality}</Typography>
                <Typography fontSize={"small"}><strong>Farklı Vatandaşlık: </strong> {candidateDetail?.personalInformation?.citizenship}</Typography>
                <Typography fontSize={"small"}><strong>Adres: </strong> {candidateDetail?.personalInformation?.district}, {candidateDetail?.personalInformation?.city} | {candidateDetail?.personalInformation?.address}</Typography>
                <Typography fontSize={"small"}><strong>Açıklama: </strong> {candidateDetail?.personalInformation?.description}</Typography>
                <Typography fontSize={"small"}><strong>Sigara Kullanımı: </strong>{candidateDetail?.smoking ? "İçiyor" : "İçmiyor"}</Typography>
                <Stack spacing={1}>
                    <Typography fontWeight={"bold"}>Eğitim Bilgileri</Typography>
                    <Typography fontSize={"small"}><strong>Lise: </strong> {candidateDetail?.education?.highSchool?.join(', ')}</Typography>
                    <Typography fontSize={"small"}><strong>Yükseköğrenim: </strong> {candidateDetail?.education?.higherEducation?.map((degree: any) => <li>{degree?.name} - {degree?.department} - {getNameOfEnum(HigherEducationDegree, degree?.higherEducationDegree)} - {degree?.gpa}</li>)}</Typography>
                </Stack>
                <Stack spacing={1}>
                    <Typography fontWeight={"bold"}>Sertifikalar</Typography>
                    {candidateDetail?.certificates?.map((certificate: any) => (
                        <Typography fontSize={"small"}><strong>{certificate?.name}, </strong> {certificate?.type} - {moment(certificate?.issuedDate).format("MM.YYYY")} - {moment(certificate?.validUntil).format("MM.YYYY")} - {certificate?.certificateInstitution}</Typography>
                    ))}
                </Stack>
            </Stack>
            <Divider orientation="vertical" flexItem />
            <Stack spacing={1} width={{ xs: '100%', sm: "300px" }}>
                <Stack spacing={1}>
                    <Typography fontWeight={"bold"}>Bildiği Diller</Typography>
                    {candidateDetail?.languages?.map((language: any) => (
                        <Typography fontSize={"small"}><strong>{language?.name?.toUpperCase()}:</strong> Okuma: {getNameOfEnum(LanguageLevelEnum, language?.reading)}, Yazma: {getNameOfEnum(LanguageLevelEnum, language?.writing)}, Dinleme: {getNameOfEnum(LanguageLevelEnum, language?.listening)}</Typography>
                    ))}
                </Stack>

                <Stack spacing={1}>
                    <Typography fontWeight={"bold"}>Bilgisayar Bilgisi</Typography>
                    <Typography fontSize={"small"}><strong>{getNameOfEnum(ComputerKnowledgeLevelEnum, candidateDetail?.computerKnowledgeLevel)} </strong></Typography>
                    <Typography fontSize={"small"}>{candidateDetail?.programmingLanguages?.map((language: any) => language?.name + "(" + getNameOfEnum(ProgrammingLanguageLevelEnum, language?.level ?? "") + "), ")}</Typography>
                </Stack>

                <Stack spacing={1}>
                    <Typography fontWeight={"bold"}>Sürücü Belgesi</Typography>
                    <Typography fontSize={"small"}>{getNameOfEnum(DriverLicenseStatusEnum, candidateDetail?.driverLicense?.status)}{candidateDetail?.driverLicense?.status !== "notExist" && ", " + candidateDetail?.driverLicense?.licenseType + " sınıfı, " + moment(candidateDetail?.driverLicense?.issueDate).format("MM.YYYY")}</Typography>
                </Stack>

                <Stack spacing={1}>
                    <Typography fontWeight={"bold"}>Burs ve Burslar</Typography>
                    {candidateDetail?.scholarships?.map((scholarship: any) => (
                        <Typography fontSize={"small"}>{moment(scholarship?.receivingDate).format("MM.YYYY")} - {scholarship?.type} - {scholarship?.institution}</Typography>
                    ))}
                </Stack>
                <Stack spacing={1}>
                    <Typography fontWeight={"bold"}>Katıldığı Projeler</Typography>
                    {candidateDetail?.participatedProjects?.map((project: any) => (
                        <Typography fontSize={"small"}>{project?.name} - {project?.institution} - {project?.level} - {moment(project?.date).format("MM.YYYY")} - {project?.details}</Typography>
                    ))}
                </Stack>
                <Stack spacing={1}>
                    <Typography fontWeight={"bold"}>Hobileri</Typography>
                    {candidateDetail?.hobbies?.map((hobby: any) => (
                        <Typography fontSize={"small"}>{hobby}</Typography>
                    ))}
                </Stack>
            </Stack>
        </Stack>

        {!selectedAdvertisement && <Stack spacing={1}>
            <Typography fontWeight={"bold"}>Özgelecek Bilgilerine Eriş ↓</Typography>
            <Stack spacing={1} direction={"row"} flexWrap={"wrap"}>
                {ozgeleceks?.map((ozgelecek: any) => <Box key={ozgelecek?._id} bgcolor={"secondary.light"} p={1} borderRadius={2} border={"1px solid #555"} sx={{ cursor: 'pointer', boxShadow: "0px 0px 5px 0px #00000066" }}
                    onClick={() => {

                        ///
                        (path === "/radar" || radarHistory?.length > 0)
                            ? handleOzgelecekDetail(ozgelecek)
                            : enqueueSnackbar("Özgelecek bilgilerine erişebilmek için adayın kilidini açmanız gerekmektedir.", { variant: "warning" })
                    }}>
                    <Typography fontSize={"small"} fontWeight={"bold"}>{getNameOfEnum(OzgelecekFieldEnum, ozgelecek?.field)}</Typography>
                </Box>)}
            </Stack>
        </Stack>}
        {/* case 1: return "Kesinlikle katılmıyorum."
            case 2: return "Katılmıyorum."
            case 3: return "Kararsızım."
            case 4: return "Katılıyorum."
            case 5: return "Kesinlikle katılıyorum." */}
        <Dialog open={!!selectedOzgelecek} onClose={() => setSelectedOzgelecek(null)}>
            <DialogTitle>{getNameOfEnum(OzgelecekFieldEnum, selectedOzgelecek?.field)}</DialogTitle>

            <DialogContent sx={{ width: { xs: "100%", sm: "500px" } }}>
                <Stack spacing={1} p={1} mb={1} border={"1px solid #bbb"}>
                    <Typography fontSize={"medium"} fontWeight={"bold"} sx={{ textDecoration: "underline" }}>Oluşturulma Tarihi</Typography>
                    <Typography fontSize={"small"} >{moment(selectedOzgelecek?.createdAt).format("DD MMMM YYYY")}</Typography>
                </Stack>
                <Stack spacing={1} bgcolor={"secondary.lighter"} border={"1px solid #bbb"} p={1} mb={1}>
                    <Typography fontSize={"medium"} fontWeight={"bold"} sx={{ textDecoration: "underline" }}>Özgelecek Test Sonuçları</Typography>
                    <Typography fontSize={"small"} ><strong>Alan Testi: </strong> {selectedOzgelecek?.testResults?.areaTestScore}</Typography>
                    <Typography fontSize={"small"} ><strong>Motivasyon Testi: </strong> {selectedOzgelecek?.testResults?.motivationTestScore}</Typography>
                </Stack>
                <Stack spacing={1} border={"1px solid #bbb"} p={1} mb={1}>
                    <Typography fontSize={"medium"} fontWeight={"bold"} sx={{ textDecoration: "underline" }}>Referanslar</Typography>
                    {selectedOzgelecek?.references?.map((reference: any, index: any) => (
                        <Stack key={reference?._id} spacing={1} direction={"column"}>
                            <Typography fontSize={"small"} ><strong>{index + 1} )</strong> {reference?.referenceInformation?.name} - {reference?.referenceInformation?.surname} | {reference?.referenceInformation?.jobTitle} <Chip label={getNameOfEnum(CandidateReferenceStatusEnums, reference?.referenceStatus)} color={"default"} style={{ borderRadius: 100 }} variant='outlined' size={"small"} /></Typography>
                            <Typography fontSize={"small"} ml={2} >{reference?.referenceInformation?.relationWithCandidate} - {reference?.referenceInformation?.phoneNumber} | {reference?.referenceInformation?.email} </Typography>
                            <Typography fontSize={"small"} ml={2} >{reference?.referenceExplanation} </Typography>
                            {reference?.referenceTests?.length > 0 && <Accordion sx={{ border: "1px solid #bbb" }}>
                                <AccordionSummary
                                    expandIcon={<ArrowDownward />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography fontSize={"small"}>Referans Soru-Cevaplar</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography fontSize={"small"} ml={2} >{referenceQuestions?.data?.map((question: any, index: any) => <Typography fontSize={"small"} key={index}>{index + 1}- {question?.content}
                                        <strong> &nbsp;
                                            {reference?.referenceTests ? reference?.referenceTests?.[index]?.answer === 1 ? "Kesinlikle katılmıyorum." : reference?.referenceTests?.[index]?.answer === 2 ? "Katılmıyorum." : reference?.referenceTests?.[index]?.answer === 5 ? "Kararsızım." : reference?.referenceTests?.[index]?.answer === 3 ? "Katılıyorum." : reference?.referenceTests?.[index]?.answer === 4 ? "Kesinlikle katılıyorum."
                                                : "-"
                                                : ""} </strong> </Typography>)}</Typography>
                                </AccordionDetails>
                            </Accordion>}
                        </Stack>
                    ))}
                </Stack>
                <Stack spacing={1} bgcolor={"secondary.lighter"} border={"1px solid #bbb"} p={1} mb={1}>
                    <Typography fontSize={"medium"} fontWeight={"bold"} sx={{ textDecoration: "underline" }}>Özgelecek Açıklamaları</Typography>
                    <Typography fontSize={"small"} ><strong>Açıklama 1: </strong> {selectedOzgelecek?.ozgelecekTest?.first?.text ?? "-"}</Typography>
                    <Typography fontSize={"small"} ><strong>Açıklama 2: </strong> {selectedOzgelecek?.ozgelecekTest?.second?.text ?? "-"}</Typography>
                    <Typography fontSize={"small"} ><strong>Video 1: </strong> {selectedOzgelecek?.ozgelecekTest?.first?.video ? <Button variant={"text"} color={"primary"} onClick={() => handleDownloadVideo(0)}>İndir</Button> : "-"}</Typography>
                    <Typography fontSize={"small"} ><strong>Video 2: </strong> {selectedOzgelecek?.ozgelecekTest?.second?.video ? <Button variant={"text"} color={"primary"} onClick={() => handleDownloadVideo(1)}>İndir</Button> : "-"}</Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setSelectedOzgelecek(null)} variant='outlined' color='primary' >Kapat</Button>
            </DialogActions>

        </Dialog>

    </Stack>
}