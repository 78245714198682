import React, { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { FormikTextField } from 'components'
import { useFormik, Formik } from 'formik'
import axios from 'axios'
import * as yup from 'yup'
import { useSelfUpdateUserBillingInfoMutation, self_updateUserBillingInfo, useAppDispatch, IUserCreateOrderResponse, ISelfUpdateUserBillingInfoResponse, useGetSelfUserQuery } from 'context'
import { useSnackbar } from 'notistack'
import { HasData } from 'interfaces/HasData'
import { IGraphqlResponse } from 'interfaces/IGraphqlResponse'

export const InvoiceInfoSection = () => {

    const [ip, setIP] = useState("");
    const { enqueueSnackbar } = useSnackbar()
    const [updateUserBillingInfo, { isLoading }] = useSelfUpdateUserBillingInfoMutation()
    const { data: user, refetch } = useGetSelfUserQuery({})

    console.log(user, "user")

    const initialValues = {
        "buyer": {
            "name": user?.billingInfo?.buyer?.name,
            "surname": user?.billingInfo?.buyer?.surname,
            "email": user?.billingInfo?.buyer?.email,
            "country": user?.billingInfo?.buyer?.country ?? "",
            "city": user?.billingInfo?.buyer?.city ?? "",
            "identityNumber": user?.billingInfo?.buyer?.identityNumber ?? "",
            "ip": user?.billingInfo?.buyer?.ip ?? "",
            "registrationAddress": user?.billingInfo?.buyer?.registrationAddress ?? "",
        },
        "billingAddress": {
            "fullName": user?.billingInfo?.billingAddress?.fullName,
            "companyName": user?.billingInfo?.billingAddress?.companyName,
            "address1": user?.billingInfo?.billingAddress?.address1,
            "email": user?.billingInfo?.billingAddress?.email,
            "country": user?.billingInfo?.buyer?.country,
            "city": user?.billingInfo?.buyer?.city,
            "taxOffice": user?.billingInfo?.billingAddress?.taxOffice,
            "taxIdentificationNumber": user?.billingInfo?.billingAddress?.taxIdentificationNumber,
            "postcode" : user?.billingInfo?.billingAddress?.postcode,
        }
    }

    // const validationSchema = yup.object().shape({
    //     companyName: yup.string().required("Gerekli"),
    //     // taxOffice: yup.string().required("Gerekli"),
    //     // taxNumber: yup.string().required("Gerekli"),
    //     // address: yup.string().required("Gerekli"),
    //     // city: yup.string().required("Gerekli"),
    //     // district: yup.string().required("Gerekli"),
    //     // postalCode: yup.string().required("Gerekli"),
    //     // country: yup.string().required("Gerekli"),
    //     // phone: yup.string().required("Gerekli"),
    //     // email: yup.string().required("Gerekli").email("Geçerli bir email adresi giriniz")
    // })

    const handleSubmitInvoiceInformation = async (values: any, setErrors: any, resetForm: any) => {

        try {
            const res = await axios.get("https://api.ipify.org/?format=json");
            setIP(res.data.ip);

            const response = await updateUserBillingInfo({ ...values, ip: res.data.ip }) as HasData<IGraphqlResponse<ISelfUpdateUserBillingInfoResponse>>

            console.log(response, "response")

            if (response.data?.data?.self_updateUserBillingInfo?.success) {
                enqueueSnackbar("Fatura bilgileriniz başarıyla güncellendi", { variant: 'success' })
                refetch()
            }
        } catch (error) {
            console.log(error)
        }
    }



    return (
        <Grid container spacing={3}>

            <Grid item xs={12}>
                <Typography variant='h3'>
                    Fatura Bilgileri
                </Typography>
            </Grid>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                // validationSchema={validationSchema}
                onSubmit={(values, { setErrors, resetForm }) => {
                    handleSubmitInvoiceInformation(values, setErrors, resetForm)
                }}
            >
                {
                    ({ errors, enableReinitialize, handleSubmit, isValid, values, setFieldValue }) => {
                        return (
                            <Grid item xs={12}>

                                <Stack spacing={2} direction={{ xs: 'column' }} justifyContent='space-between' alignItems='flex-start'>
                                    <FormikTextField
                                        name="buyer.name"
                                        upperCase
                                        required
                                        label="Yetkili Adı"
                                        sx={{ width: { xs: '100%', md: '48%' } }}
                                    />
                                    <FormikTextField
                                        name="buyer.surname"
                                        label="Yetkili Soyadı"
                                        upperCase
                                        required
                                        sx={{ width: { xs: '100%', md: '48%' } }}
                                    />
                                    <FormikTextField
                                        name="buyer.identityNumber"
                                        label="TC Kimlik No"
                                        required
                                        sx={{ width: { xs: '100%', md: '48%' } }}
                                    />
                                    <FormikTextField
                                        name="buyer.email"
                                        label="Email"
                                        type='email'
                                        required
                                        sx={{ width: { xs: '100%', md: '48%' } }}
                                    />
                                    <FormikTextField
                                        name="billingAddress.companyName"
                                        label="Şirket Adı"
                                        upperCase
                                        required
                                        sx={{ width: { xs: '100%', md: '48%' } }}
                                    />
                                    <FormikTextField
                                        name='buyer.country'
                                        label='Ülke'
                                        upperCase
                                        required
                                        sx={{ width: { xs: '100%', md: '48%' } }}
                                    />
                                    <FormikTextField
                                        name='buyer.city'
                                        label='Şehir'
                                        upperCase
                                        required
                                        sx={{ width: { xs: '100%', md: '48%' } }}
                                    />
                                    <FormikTextField
                                        name='billingAddress.postcode'
                                        label='Posta Kodu'
                                        required
                                        sx={{ width: { xs: '100%', md: '48%' } }}
                                    />
                                    <FormikTextField
                                        name='billingAddress.address1'
                                        label='Adres'
                                        multiline
                                        rows={2}
                                        required
                                        sx={{ width: { xs: '100%', md: '48%' } }}
                                    />
                                    <FormikTextField
                                        name='billingAddress.taxOffice'
                                        label='Vergi Dairesi'
                                        upperCase
                                        required
                                        sx={{ width: { xs: '100%', md: '48%' } }}
                                    />
                                    <FormikTextField
                                        name='billingAddress.taxIdentificationNumber'
                                        label='Vergi Numarası'
                                        required
                                        sx={{ width: { xs: '100%', md: '48%' } }}
                                    />
                                    <Box height={3} />
                                    <FormikTextField
                                        name='buyer.ip'
                                        label='IP'
                                        value={ip}
                                        required
                                        sx={{ width: { xs: '100%', md: '48%', display: 'none' } }}
                                    />
                                </Stack>




                                <LoadingButton
                                    variant='contained'
                                    color='primary'
                                    type='submit'
                                    onClick={() => handleSubmit()}
                                // disabled={!isValid}
                                >
                                    Kaydet
                                </LoadingButton>
                            </Grid>
                        )
                    }
                }
            </Formik>




        </Grid>
    )
}
