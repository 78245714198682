import { memo } from 'react';
import { useFormikContext } from 'formik'
import { DatePickerProps } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';


interface IFormikDatePicker extends DatePickerProps<Date> {
    label: string,
    name: string,
    format?: string,
    views?: ("year" | "month" | "day")[],
}



export const FormikDatePicker = memo(({ label, name, format, views, ...rest }: IFormikDatePicker) => {

    const { getFieldProps, getFieldMeta, setFieldValue, setFieldTouched } = useFormikContext<IFormikDatePicker | any>()

    const error = !!(getFieldMeta(name).touched && getFieldMeta(name).error)
    const helperText = !!getFieldMeta(name).touched && getFieldMeta(name).error && `${getFieldMeta(name).error}`

    const value = getFieldProps(name).value

    return (
        <DatePicker
            label={label}
            {...getFieldProps(name)}
            {...rest}
            // maxDate={
            //     (name === "personalInformation.birthDate" || name === "birthDate") ? dayjs().subtract(18, 'year').toDate() :
            //         (name === "startDate") ? dayjs().add(1, 'year').toDate() : new Date()}
            // minDate={(name === "startDate") ? new Date() : dayjs().subtract(100, 'year').toDate()}
            format={format ?? ((name === "foundationYear") ? "yyyy" : "dd.MM.yyyy")}
            value={value ? new Date(value) : null}
            onChange={(e) => setFieldValue(name, e)}
            views={views ?? ((name === "foundationYear") ? ["year"] : ["year", "month", "day"])}
            onOpen={() => {
                setFieldTouched(name, true)
            }}
            localeText={{
                toolbarTitle: 'Tarih Seçiniz',
                okButtonLabel: 'Tamam',
                cancelButtonLabel: 'İptal',
            }}
            slotProps={{
                textField: {
                    helperText: helperText,
                    error: error
                },

                // openPickerButton: {
                //     sx: {
                //         position: 'absolute',
                //         letf: 0,
                //         top: 0,
                //         bottom: 0,
                //         right: 0,
                //         width: '100%',
                //         m: 0,
                //         borderRadius: 0,
                //         display: 'flex',
                //         justifyContent: 'flex-end',
                //     }
                // }
            }}
            sx={{ width: '100%', position: 'relative' }}
        />
    )
})
