

export const self_candidate = () => {

  const data = JSON.stringify({
    query: `
        query Self_candidate {
            self_candidate {
              _id
              personalInformation {
                name
                surname
                birthDate
                country
                city
                district
                nationality
                citizenship
                sex
                gender
                genderDetail
                address
                phoneNumber
                profileImageUrl
                backgroundImageUrl
                description
              }
              contact {
                email
                emailVerified
                phoneNumber
                phoneNumberVerified
              }
              education {
                highSchool
                higherEducation {
                  higherEducationDegree
                  name
                  department
                  startDate
                  graduationDate
                  gpa
                }
              }
              certificates {
                name
                type
                issuedDate
                validUntil
                certificateInstitution
              }
              driverLicense {
                status
                issueDate
                licenseType
              }
              languages {
                name
                reading
                writing
                listening
              }
              smoking
              scholarships {
                receivingDate
                type
                institution
              }
              participatedProjects {
                name
                institution
                level
                date
                details
              }
              workExperiencesInMonths
              computerKnowledgeLevel
              programmingLanguages {
                name
                level
              }
              hobbies
              # candidateOnboardingStatus
            }
          }
        `,
    variables: {}
  })

  return data
}
