import HasFilter from "interfaces/HasFilter";
import { IFilterOzgelecekForCompany } from "interfaces/user/ozgelecek/ozgelecek/IFilterOzgelecek";
import { IOzgelecek } from "interfaces/user/ozgelecek/ozgelecek/IOzgelecek";

export type ICompanyOzgeleceksResponse = IOzgelecek[];

export interface ICompanyOzgeleceksVariables {
  candidateId: string;
}

export const company_ozgeleceks = (variables: ICompanyOzgeleceksVariables) => {


  const data = JSON.stringify({
    query: `
      query company_ozgeleceks($candidateId: ID!) {
        company_ozgeleceks(candidateId: $candidateId) {
          _id
          candidate {
            _id
          }
          field
          testResults {
            areaTestScore
            motivationTestScore
            areaTestResults {
              question {
                _id
              }
              answer
            }
            motivationTestResults {
              answer
            }
          }
          ozgelecekTest {
            first {
              video
              text
            }
            second {
              video
              text
            }
          }
        references {
          _id
          referenceInformation {
            name
            surname
            phoneNumber
            relationWithCandidate
            jobTitle
            email
          }
          referenceTests {
            answer
          }
          referenceStatus
          referenceExplanation
        }
          createdAt
          updatedAt
        }
      }
          `,
    variables: variables

  })

  if (!variables?.candidateId) {
    return null
  } else {

    return data
  }

}