

import { userApi } from "../userApi";
import {
    TCandidateCreateApplicationVariables, TCandidateCreateOzgelecekVariables, TCandidateCreateReferenceVariables, TCandidateRemoveApplicationResponse,
    TCandidateRemoveApplicationVariables, TCandidateUpdateCandidateVariables, TCandidateUpdateOzgelecekVariables, candidate_createApplication,
    candidate_createOzgelecek, candidate_createReference, candidate_removeApplication, candidate_updateCandidate, candidate_updateCandidateOnboardingStatus,
    candidate_updateOzgelecek
} from "./mutations";
import {
    ICandidateAdvertisementResponse, ICandidateAdvertisementVariables, ICandidateAdvertisementsVariables, ICandidateApplicationVariables, ICandidateApplicationStatisticsResponse,
    ICandidateApplicationsResponse, ICandidateApplicationsVariables, ICandidateCompanyResponse, ICandidateCompanyVariables, ICandidateOzgelecekResponse,
    ICandidateOzgelecekVariables, ICandidateRefencesResponse, ICandidateRefencesVariables, candidate_advertisement, candidate_advertisements,
    candidate_application, candidate_applications, candidate_company, candidate_ozgeleceks, candidate_references, self_candidate, candidate_applicationStatistics
} from "./queries";


const candidateUserApi = userApi.injectEndpoints({
    endpoints: (builder) => ({

        // #################### User Queries #################
        getCandidateInformation: builder.query<any, any>({
            query: (body) => {
                return ({
                    url: "",
                    body: self_candidate()
                })
            },
            transformResponse: (response: any) => {
                return response.data.self_candidate
            },
            providesTags: ["candidate"]

        }),

        getCandidateReferences: builder.query<ICandidateRefencesResponse, ICandidateRefencesVariables>({
            query: (variables: ICandidateRefencesVariables) => {
                return ({
                    url: "",
                    body: candidate_references(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.candidate_references
            },
            providesTags: ["candidate_references"]

        }),

        getCandidateOzgeleceks: builder.query<ICandidateOzgelecekResponse, ICandidateOzgelecekVariables>({
            query: ({ variables }) => {
                return ({
                    url: "",
                    body: candidate_ozgeleceks({ variables })
                })
            },
            transformResponse: (response: any) => {
                return response.data.candidate_ozgeleceks
            },
            providesTags: ["candidate_ozgeleceks"]

        }),

        getCandidateAdvertisements: builder.query<any, ICandidateAdvertisementsVariables>({
            query: (variables) => {
                // console.log({ variables });
                return ({
                    url: "",
                    body: candidate_advertisements(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.candidate_advertisements
            },
            providesTags: ["candidate_advertisements"]
        }),

        getCandidateAdvertisementDetail: builder.query<ICandidateAdvertisementResponse, ICandidateAdvertisementVariables>({
            query: (variables: ICandidateAdvertisementVariables) => {
                return ({
                    url: "",
                    body: candidate_advertisement(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.candidate_advertisement
            },
            providesTags: ["candidate_advertisement"]
        }),

        getCandidateApplications: builder.query<ICandidateApplicationsResponse, ICandidateApplicationsVariables>({
            query: (variables: ICandidateApplicationsVariables) => {
                // console.log({ variables });
                return ({
                    url: "",
                    body: candidate_applications(variables)
                })
            },
            transformResponse: (response: any) => {
                console.log({ response });
                return response.data.candidate_applications
            },
            providesTags: ["candidate_applications"]
        }),

        getCandidateApplicationDetail: builder.query<any, ICandidateApplicationVariables>({
            query: (variables: ICandidateApplicationVariables) => {
                return ({
                    url: "",
                    body: candidate_application(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.candidate_Application
            },
            providesTags: ["candidate_application"]
        }),

        getCandidateCompany: builder.query<ICandidateCompanyResponse, ICandidateCompanyVariables>({
            query: (variables: ICandidateCompanyVariables) => {
                return ({
                    url: "",
                    body: candidate_company(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.candidate_company
            },
            // providesTags: [""]
        }),

        getCandidateApplicationStatistics: builder.query<ICandidateApplicationStatisticsResponse, any>({
            query: () => {
                return ({
                    url: "",
                    body: candidate_applicationStatistics()
                })
            },
            transformResponse: (response: any) => {
                return response.data.candidate_applicationStatistics
            },
            // providesTags: [""]
        }),







        // #################### User Mutations #################
        candidateUpdateCandidate: builder.mutation<any, TCandidateUpdateCandidateVariables>({
            query: ({ variables }) => {
                return ({
                    url: "",
                    body: candidate_updateCandidate({ variables })
                })
            },
            transformResponse: (response: any) => {
                return response?.data
            },
            invalidatesTags: ["candidate"]
        }),

        candidateUpdateCandidateOnboardingStatus: builder.mutation<any, any>({
            query: () => {
                return ({
                    url: "",
                    body: candidate_updateCandidateOnboardingStatus()
                })
            },
            transformResponse: (response: any) => {
                return response?.data
            },
            invalidatesTags: ["candidate"]
        }),

        candidateCreateReference: builder.mutation<TCandidateCreateReferenceVariables, any>({
            query: ({ variables }: TCandidateCreateReferenceVariables) => {
                return ({
                    url: "",
                    body: candidate_createReference({ variables })
                })
            },
            transformResponse: (response: any) => {
                return response
            },
            invalidatesTags: ["candidate_references"]
        }),

        candidateCreateOzgelecek: builder.mutation<any, TCandidateCreateOzgelecekVariables>({
            query: ({ variables }: TCandidateCreateOzgelecekVariables) => {
                return ({
                    url: "",
                    body: candidate_createOzgelecek({ variables })
                })
            },
            transformResponse: (response: any) => {
                return response
            },
            invalidatesTags: ["candidate_ozgeleceks"]
        }),

        candidateUpdateOzgelecek: builder.mutation<any, TCandidateUpdateOzgelecekVariables>({
            query: (variables: TCandidateUpdateOzgelecekVariables) => {
                return ({
                    url: "",
                    body: candidate_updateOzgelecek(variables)
                })
            },
            transformResponse: (response: any) => {
                return response
            },
            invalidatesTags: ["candidate_ozgelecek", "candidate_ozgeleceks"]
        }),

        candidateCreateApplication: builder.mutation<any, TCandidateCreateApplicationVariables>({
            query: (variables: TCandidateCreateApplicationVariables) => {
                return ({
                    url: "",
                    body: candidate_createApplication(variables)
                })
            },
            transformResponse: (response: any) => {
                return response
            },
            invalidatesTags: ["candidate_application", "candidate_applications", "candidate_advertisement", "candidate_advertisements"]
        }),

        candidateRemoveApplication: builder.mutation<TCandidateRemoveApplicationResponse, TCandidateRemoveApplicationVariables>({
            query: (variables: TCandidateRemoveApplicationVariables) => {
                return ({
                    url: "",
                    body: candidate_removeApplication(variables)
                })
            },
            transformResponse: (response: any) => {
                return response?.data?.candidate_removeApplication
            },
            invalidatesTags: ["candidate_application", "candidate_applications", "candidate_advertisement", "candidate_advertisements"]
        }),

    }),
})


export const {
    // ########## queries ###############
    useGetCandidateInformationQuery,
    useLazyGetCandidateInformationQuery,

    useGetCandidateReferencesQuery,
    useLazyGetCandidateReferencesQuery,

    useGetCandidateOzgeleceksQuery,
    useLazyGetCandidateOzgeleceksQuery,

    useGetCandidateAdvertisementsQuery,
    useLazyGetCandidateAdvertisementsQuery,

    useGetCandidateAdvertisementDetailQuery,
    useLazyGetCandidateAdvertisementDetailQuery,

    useGetCandidateApplicationsQuery,
    useLazyGetCandidateApplicationsQuery,

    useGetCandidateApplicationDetailQuery,
    useLazyGetCandidateApplicationDetailQuery,

    useGetCandidateCompanyQuery,
    useLazyGetCandidateCompanyQuery,

    useGetCandidateApplicationStatisticsQuery,
    useLazyGetCandidateApplicationStatisticsQuery,


    // ########## mutation ###############
    useCandidateUpdateCandidateMutation,
    useCandidateUpdateCandidateOnboardingStatusMutation,
    useCandidateCreateReferenceMutation,
    useCandidateCreateOzgelecekMutation,
    useCandidateUpdateOzgelecekMutation,
    useCandidateCreateApplicationMutation,
    useCandidateRemoveApplicationMutation,


} = candidateUserApi