import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL, getAccessToken } from 'utils'


export const notificationApi = createApi({
    reducerPath: 'notificationApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/notification/graphql`,
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + getAccessToken(),
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },

    }),

    tagTypes: [
        "user_userNotification",
        "user_userNotifications",
    ],

    endpoints: (builder) => ({}),

})
