import {
    Box,
    Stack,
    Select,
    Button,
    Dialog,
    MenuItem,
    Skeleton,
    TextField,
    Typography,
    Pagination,
    FormControl,
    DialogTitle,
    DialogContent,
    DialogActions,
    SwipeableDrawer,
    InputLabel,
    FormHelperText
} from '@mui/material'
import { useEffect, useState } from 'react'
import { CandidateAdvertisementCard, CreateApplicationDialog, FilterAccordionMenu, Iconify, MuiTransition, RejectApplicationDialog, TFilterAccordionMenuList } from 'components'

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

import {
    useGetCitiesQuery,
    ICandidateAdvertisementsVariables,
    useGetCandidateAdvertisementsQuery,
    useCandidateCreateApplicationMutation,
    useGetCandidateOzgeleceksQuery,
    useCandidateRemoveApplicationMutation,
    setCandidateCreateOzgelecekFormField,
    useAppDispatch,
} from 'context'
import { DashboardLayout, DashboardLayoutMobile } from 'layouts'
import { LanguageList, MutationVariablesPaginationSortList, OzgelecekFieldsList, PlaceOfWorkList, PositionLevelList, WorkTypeList } from 'types'
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes';
import { enqueueSnackbar } from 'notistack';



const initialVariables: ICandidateAdvertisementsVariables = {
    pagination: {
        page: 1,
        pageSize: 4,
    },
    filterOption: {

        filter: {
            title: null,
            cities: null,
            department: null,
            description: null,
            districts: null,
            field: null,
            language: null,
            militaryServiceRequired: null,
            place: null,
            positionLevel: null,
            requiredAgeInterval: null,
            requiredDriverLicense: null,
            requiredEducationLevel: null,
            requiredLanguages: [],
            requiredSkills: null,
            startDate: null,
            workType: null
        },

        search: {
            all: null,
            cities: null,
            department: null,
            description: null,
            districts: null,
            title: null
        }
    },

}


export const CandidateAdvertisementsPageMobile = () => {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [applyDialog, setApplyDialog] = useState<any>(null)
    const [rejectApplicationDialog, setRejectApplicationDialog] = useState<any>(null)
    const [filtersDrawer, setFiltersDrawer] = useState(false)
    const [selectedAdvertisementDialog, setSelectedAdvertisementDialog] = useState<any>(null)
    const [variables, setVariables] = useState<ICandidateAdvertisementsVariables>(initialVariables)

    const { data: ozgeleceks } = useGetCandidateOzgeleceksQuery({})
    const { data: advertisements, isFetching } = useGetCandidateAdvertisementsQuery({ ...variables })
    const [createApplication, { isLoading: createApplicationLoading }] = useCandidateCreateApplicationMutation()
    const [candidateRemoveApplication, { isLoading: rejectApplicationLoading }] = useCandidateRemoveApplicationMutation()

    const filterMenuList: TFilterAccordionMenuList = [
        // {
        //     title: "Section",
        //     type: "radio",
        //     function: (e) => setVariables((prevState) => ({
        //         ...prevState,
        //         filter: {
        //             ...prevState.filter,
        //             field: e
        //         }
        //     })),
        //     items: OzgelecekFieldsList.map((item: any) => ({
        //         title: item?.name,
        //         value: item.value,
        //         // function: () => setVariables((prevState) => ({
        //         //     ...prevState,
        //         //     filter: {
        //         //         ...prevState.filter,
        //         //         field: item.value
        //         //     }
        //         // }))
        //     }))
        // },
        {
            title: "Çalışma Türü",
            type: "radio",
            function: (e) => setVariables((prevState) => ({
                ...prevState,
                filterOption: {
                    ...prevState.filterOption,
                    filter: {
                        ...prevState.filterOption.filter,
                        workType: e
                    }
                }
            })),
            items: WorkTypeList.map((item: any) => ({
                title: item?.name,
                value: item.value,

                // function: () => setVariables((prevState) => ({
                //     ...prevState,
                //     filter: {
                //         ...prevState.filter,
                //         field: item.value
                //     }
                // }))
            }))
        },

        {
            title: "Çalışma Yeri",
            type: "radio",
            function: (e) => setVariables((prevState) => ({
                ...prevState,
                filterOption: {
                    ...prevState.filterOption,
                    filter: {
                        ...prevState.filterOption.filter,
                        place: e
                    }
                }
            })),
            items: PlaceOfWorkList.map((item: any) => ({
                title: item?.name,
                value: item.value,

                // function: () => setVariables((prevState) => ({
                //     ...prevState,
                //     filter: {
                //         ...prevState.filter,
                //         field: item.value
                //     }
                // }))
            }))
        },
    ]


    const handleCreateApplication = async () => {

        try {
            const selectedOzgelecek: any = ozgeleceks?.data?.find((item: any) => item?.field === applyDialog?.field)
            const response: any = await createApplication({ advertisement: applyDialog, ozgelecek: selectedOzgelecek })
            console.log({ response });

            if (response?.data?.data?.candidate_createApplication?._id) {
                enqueueSnackbar("Başvuru başarılı bir şekilde yapıldı!")
                setApplyDialog(null)
            }
            if (response?.data?.errors) {
                if (response?.data?.errors?.[0]?.message === "AlreadyApplied") {
                    enqueueSnackbar("Bu ilana zaten başvuru yaptınız!", { variant: "warning" })
                    setApplyDialog(null)
                } else {
                    enqueueSnackbar("İlana başvururken bir hata oluştu.", { variant: "error" })
                    setApplyDialog(null)
                }
            }
        } catch (error) {
            console.log({ error });
        }
    }

    const handleRejectApplication = async () => {
        try {
            const response: any = await candidateRemoveApplication({ applicationId: rejectApplicationDialog?._id })
            console.log({ response });
            if (response?.data?.success) {
                enqueueSnackbar("Başarılı şekilde iptal edildi!")
            } else {
                enqueueSnackbar("Birşeyler yanlış gitti", { variant: 'info' })
            }
        } catch (error) {
            console.log({ error });
            enqueueSnackbar("Birşeyler yanlış gitti", { variant: 'error' })
        }
    }


    const handlePageChange = (e: React.ChangeEvent<unknown>, value: number) => {
        setVariables((prevState: ICandidateAdvertisementsVariables) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: value
            }
        }))
    }

    const handleReset = () => {
        setVariables(initialVariables)
    }

    // console.log({ field: variables.filter?.field });
    // console.log({
    //     // ozgeleceks,
    //     // applyDialog,
    // });

    const renderFilters = () => {

        return (
            <Stack
                direction="column"
                gap={1}
                sx={{
                    height: 'auto',
                    width: { md: '250px', sm: '300px' },
                }}
            >

                <TextField
                    select
                    label="Çalışma Alanı"
                    fullWidth
                    defaultValue=""
                    value={variables.filterOption.filter?.field ?? ""}
                    onChange={(e: any) => {

                        setVariables((prevState: ICandidateAdvertisementsVariables) => ({
                            ...prevState,
                            filterOption: {
                                ...prevState.filterOption,
                                filter: {
                                    ...prevState.filterOption.filter,
                                    field: e.target.value
                                }
                            }
                        }))

                    }}
                >
                    {
                        OzgelecekFieldsList.map((option: any) => (
                            <MenuItem key={option.value ?? option.name} value={option.value ?? option.name}>
                                {option.name}
                            </MenuItem>
                        ))
                    }
                </TextField>

                <TextField
                    select
                    label="Dil"
                    fullWidth
                    defaultValue=""
                    value={variables.filterOption.filter?.language ?? ""}
                    onChange={(e: any) => {

                        setVariables((prevState: ICandidateAdvertisementsVariables) => ({
                            ...prevState,
                            filterOption: {
                                ...prevState.filterOption,
                                filter: {
                                    ...prevState.filterOption.filter,
                                    language: e.target.value
                                }
                            }
                        }))

                    }}
                >
                    {
                        LanguageList.map((option: any) => (
                            <MenuItem key={option.value ?? option.name} value={option.value ?? option.name}>
                                {option.name}
                            </MenuItem>
                        ))
                    }
                </TextField>

                <TextField
                    select
                    label="Pozisyon"
                    fullWidth
                    defaultValue=""
                    value={variables.filterOption.filter?.positionLevel ?? ""}
                    onChange={(e: any) => {

                        setVariables((prevState: ICandidateAdvertisementsVariables) => ({
                            ...prevState,
                            filterOption: {
                                ...prevState.filterOption,
                                filter: {
                                    ...prevState.filterOption.filter,
                                    positionLevel: e.target.value
                                }
                            }
                        }))

                    }}
                >
                    {
                        PositionLevelList.map((option: any) => (
                            <MenuItem key={option.value ?? option.name} value={option.value ?? option.name}>
                                {option.name}
                            </MenuItem>
                        ))
                    }
                </TextField>

                <FilterAccordionMenu list={filterMenuList} />

                <Button
                    onClick={handleReset}
                    variant='outlined'
                    color="primary"
                    sx={{
                        mt: 1,
                        width: '100%',
                    }}
                >
                    Filtreyi Sıfırla
                </Button>

            </Stack>
        )
    }

    return (
        <DashboardLayoutMobile>

            <Stack direction="row" justifyContent="space-between" alignItems="center">

                <Typography variant='h1'>
                    İlanlar
                </Typography>

                <Button
                    size='small'
                    variant='outlined'
                    color='primary'
                    sx={{
                        display: { xs: 'initial', md: 'none' }
                    }}
                    onClick={() => setFiltersDrawer(true)}
                >
                    <Iconify icon="mdi:filter-variant" />
                </Button>
            </Stack>

            <CandidateAdvertisementsUpperFilters variables={variables} setVariables={setVariables} />

            <Stack
                flexDirection="row"
                gap={3}
                sx={{ my: 5, }}
            >

                <Box sx={{ display: { xs: 'none', md: 'flex' }, }}>
                    {renderFilters()}
                </Box>


                <Box
                    sx={{
                        display: 'flex',
                        gap: 1,
                        flexDirection: 'column',
                        flex: 1,
                    }}
                >
                    {
                        isFetching ? (
                            <>
                                {
                                    [1, 2, 3]?.map((item) => {

                                        return (
                                            <Skeleton
                                                key={item}
                                                variant='rectangular'
                                                sx={{
                                                    p: 2,
                                                    width: '100%',
                                                    height: '150px'
                                                }}
                                            />
                                        )
                                    })
                                }
                            </>
                        ) : (

                            advertisements?.count > 0 ? (
                                <>

                                    <Stack direction={{ xs: 'row' }} alignItems={{ xs: 'center' }} justifyContent={{ xs: 'space-between' }}>

                                        <Typography variant='body2' color='GrayText'>
                                            {advertisements.count} ilan bulundu.
                                        </Typography>

                                        <TextField
                                            select
                                            label="Sıralama"
                                            sx={{ width: { xs: '200px' } }}
                                            defaultValue="createdAtDesc"
                                            value={variables.pagination.sort ?? "createdAtDesc"}
                                            onChange={(e: any) => {

                                                setVariables((prevState: ICandidateAdvertisementsVariables) => ({
                                                    ...prevState,
                                                    pagination: {
                                                        ...prevState.pagination,
                                                        sort: e.target.value
                                                    }
                                                }))

                                            }}
                                        >
                                            {
                                                MutationVariablesPaginationSortList.map((option: any) => (
                                                    <MenuItem key={option.value ?? option.name} value={option.value ?? option.name}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </Stack>
                                    {
                                        advertisements?.data?.map((item: any, index: any) => {

                                            return (
                                                <CandidateAdvertisementCard
                                                    key={index}
                                                    advertisement={item}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        // navigate(routes.candidate.dashboard.advertisementDetailsMobile + "/" + item?._id)
                                                        window.open(routes.candidate.dashboard.advertisementDetailsMobile + "/" + item?._id, "_blank")
                                                    }}
                                                    onApply={(event: any) => {
                                                        event.stopPropagation();
                                                        setApplyDialog(item)
                                                    }}
                                                    onReject={(event: any) => {
                                                        event.stopPropagation();
                                                        setRejectApplicationDialog(item)
                                                    }}
                                                />
                                            )
                                        })
                                    }

                                    {
                                        Math.ceil(advertisements?.count / variables?.pagination?.pageSize) > 1 ? (
                                            <Pagination
                                                count={Math.ceil(advertisements?.count / variables?.pagination?.pageSize)}
                                                color="primary"
                                                page={variables?.pagination?.page}
                                                onChange={handlePageChange}
                                                sx={{
                                                    m: 'auto',
                                                    my: 5
                                                }}
                                            />
                                        ) : null
                                    }
                                </>
                            ) : (
                                <Box>
                                    <Typography variant='body2' color='GrayText'>
                                        Görüntülenecek ilan bulunamadı.
                                    </Typography>
                                </Box>
                            )

                        )
                    }
                </Box>

            </Stack>


            <SwipeableDrawer
                // disableSwipeToOpen={false}
                swipeAreaWidth={0}
                anchor="bottom"
                open={filtersDrawer}
                onClose={() => setFiltersDrawer(false)}
                onOpen={() => setFiltersDrawer(true)}

                sx={{
                    '& > *': {
                        borderTopRightRadius: 10,
                        borderTopLeftRadius: 10,
                    }
                }}
            >
                <Box sx={{ minHeight: 300, bgcolor: '#fff', p: 2, pb: 15 }}>
                    {/* <FilterAccordionMenu list={filterMenuList} /> */}
                    {renderFilters()}
                </Box>
            </SwipeableDrawer>


            <CreateApplicationDialog
                button={false}
                loading={createApplicationLoading}
                onClick={handleCreateApplication}
                // title={`${applyDialog?.title ?? "advertisement"} - İlan Başvurusu`}
                applyDialog={applyDialog}
                setApplyDialog={setApplyDialog}
                advertisement={applyDialog}

                onUpdate={() => {
                    navigate(routes.candidate.dashboard.ozgeleceklerimMobile)
                }}

                onAddNew={() => {
                    dispatch(setCandidateCreateOzgelecekFormField(applyDialog?.field))
                    navigate(
                        routes.candidate.dashboard.ozgelecekCreateMobile,
                        {
                            state: { field: applyDialog?.field, advertisementId: applyDialog?._id }
                        }
                    )
                }}
            />

        </DashboardLayoutMobile>
    )
}


interface ICandidateAdvertisementsUpperFiltersProps {
    variables: ICandidateAdvertisementsVariables,
    setVariables: (e: any) => void,
}

const CandidateAdvertisementsUpperFilters = ({ variables, setVariables }: ICandidateAdvertisementsUpperFiltersProps) => {

    const [text, setText] = useState("")
    // const [city, setCity] = useState("")
    const { data: citiesData } = useGetCitiesQuery({ countryName: "Turkey" })

    useEffect(() => {
        if (!variables?.filterOption?.search?.all) {
            setText("")
        }
    }, [variables?.filterOption?.search?.all])


    useEffect(() => {

        if (text?.length >= 2) {
            setVariables((prevState: ICandidateAdvertisementsVariables) => ({
                ...prevState,
                filterOption: {
                    ...prevState.filterOption,
                    search: {
                        ...prevState.filterOption.filter,
                        all: text
                    }
                }
            }))
        } else {
            setVariables((prevState: ICandidateAdvertisementsVariables) => ({
                ...prevState,
                filterOption: {
                    ...prevState.filterOption,
                    search: {
                        ...prevState.filterOption.filter,
                        all: ""
                    }
                }
            }))
        }

    }, [setVariables, text])

    return (
        <Box sx={{ my: 5 }}>

            <Box
                sx={{
                    border: '1px solid #d6ddeb',
                    p: 4,
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: 'center',
                    gap: 4,
                }}
            >
                <TextField
                    fullWidth
                    name="personalInformation.name"
                    // label="Name"
                    value={text}
                    onChange={(e) => {
                        setText(e.target.value)
                    }}
                    placeholder='Arama yap'
                    variant='outlined'
                    InputProps={{
                        startAdornment: (
                            <SearchRoundedIcon />
                        ),
                        style: {
                            color: '#999',
                        }
                    }}
                    sx={{
                        color: '#999',
                        py: 1
                    }}
                />


                <FormControl fullWidth>
                    {/* <InputLabel id="demo-simple-select-label">City</InputLabel> */}
                    {!variables.filterOption?.filter?.cities && <FormHelperText sx={{ position: 'absolute', top: 7, left: 30 }}>
                        <Typography variant='body2' color={"GrayText"} fontSize={"0.875rem"}>Şehir seçiniz</Typography>
                    </FormHelperText>}
                    <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={variables.filterOption?.filter?.cities?.[0] ?? ""}
                        // label="Şehir seçiniz"
                        variant='outlined'
                        MenuProps={{ PaperProps: { style: { maxHeight: 350 } } }}
                        defaultValue=""
                        onChange={(e) => {
                            setVariables((prevState: ICandidateAdvertisementsVariables) => ({
                                ...prevState,
                                filterOption: {
                                    ...prevState.filterOption,
                                    filter: {
                                        ...prevState.filterOption.filter,
                                        city: e.target.value
                                    }
                                }
                            }))
                        }}

                        sx={{
                            flex: 1,
                            color: '#999',
                            // py: 1
                        }}

                        startAdornment={<LocationOnOutlinedIcon />}


                    >
                        {
                            citiesData?.data?.map((city: any, index: number) => {

                                return (
                                    <MenuItem value={city?.name} key={index}>
                                        {city?.name}
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </Box >

        </Box >
    )
}
