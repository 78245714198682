import { MutationVariablesPaginationSortEnums } from "types"

export interface ICandidateReferenceItem {
  _id: string
  createdAt: string
  createdBy: {
    _id: string
  }
  referenceExplanation: string
  referenceStatus: string
  referenceTests: {
    question: {
      _id: string
    }
    answer: string
  }
  updatedAt: string
  referenceInformation: {
    email: string
    jobTitle: string
    name: string
    phoneNumber: string
    relationWithCandidate: string
    surname: string
  }
}
export interface ICandidateRefencesResponse {
  count: number
  data: Array<ICandidateReferenceItem>
}

export interface ICandidateRefencesVariables {
  filter?: {
    email?: string
    referenceStatus?: string | null
  },
  pagination: {
    page: number | null
    pageSize: number | null
    sort?: MutationVariablesPaginationSortEnums | string
  }
}

export const candidate_references = (variables?: ICandidateRefencesVariables | null) => {

  const data = JSON.stringify({
    query: `
        query Candidate_references($filter: FilterReferenceInputForCandidate, $pagination: Pagination) {
            candidate_references(filter: $filter, pagination: $pagination) {
              count
              data {
                _id
                createdAt
                createdBy {
                  _id
                }
                referenceExplanation
                referenceStatus
                referenceTests {
                  question {
                    _id
                  }
                  answer
                }
                updatedAt
                referenceInformation {
                  email
                  jobTitle
                  name
                  phoneNumber
                  relationWithCandidate
                  surname
                }
              }
            }
          }
        `,
    variables: {
      filter: {
        ...(variables?.filter?.email && { "email": variables?.filter?.email ?? null }),
        "referenceStatus": variables?.filter?.referenceStatus ?? null
      },
      pagination: {
        "page": variables?.pagination?.page ?? null,
        "pageSize": variables?.pagination?.pageSize ?? null,
        "sort": variables?.pagination?.sort ?? null
      }
    }
  })

  return data
}
