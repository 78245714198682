import { Helmet } from 'react-helmet-async';

import { CompactLayout } from 'layouts';
import { ForgotPasswordContainer } from './ForgotPasswordContainer';


// ----------------------------------------------------------------------

export const ForgotPasswordPage = () => {
  return (
    <CompactLayout>
      <Helmet>
        <title> Reset Password</title>
      </Helmet>
      <ForgotPasswordContainer />
    </CompactLayout>
  );
}
