import * as yup from "yup"
import { useState } from "react";
import { ICompanyCreateAdvertisementVariables, ICompanyCreateCustomApplicationListVariables } from "context";


export interface ICreateAdvertisementForm extends ICompanyCreateAdvertisementVariables {
    formIndex: number,
    doping: boolean,
    dopingQuantity: number,
    showcase: boolean,
    emergent: boolean,
    advertisementId?: string,
    districtsForCity?: any,
    districtsNew?: Record<string, any>

}

export const useCompanyCreateAdvertisementFormValidation = ({ values }: { values: ICreateAdvertisementForm }) => {

    const [defaultValues, setDefaultValues] = useState<ICompanyCreateAdvertisementVariables>(values);


    const validationSchema = yup.object().shape({
        productType: yup.string().required("İlan tipini seçmelisiniz"),
        quota: yup.number().required("Alınacak personel sayısını seçmelisiniz"),
        startDate: yup.string().required("Yayınlanma tarihi seçmelisiniz"),
        title: yup.string().required("Başlık girmelisiniz"),
        quotaVisibleToApplicant: yup.boolean(),
        // extraProducts: yup.array().of(yup.string()),
        // status: yup.string(),
        // field : yup.string(),
    })

    const initialValues: ICreateAdvertisementForm = {
        formIndex: values?.formIndex ?? 0,
        productType: values?.productType ?? "",
        quota: values?.quota ?? null,
        quotaVisibleToApplicant: values?.quotaVisibleToApplicant ?? true,
        startDate: values?.startDate ?? "",
        title: values?.title ?? "",
        extraProducts: values?.extraProducts ?? [],
        status: values?.status ?? null,
        doping: values?.doping ?? false,
        dopingQuantity: values?.dopingQuantity ?? 1,
        showcase: values?.showcase ?? false,
        emergent: values?.emergent ?? false,
        advertisementId: values?.advertisementId ?? "",
        field: values?.field ?? null,
        department: values?.department ?? "",
        language: values?.language ?? null,
        place: values?.place ?? null,
        description: values?.description ?? "",
        workType: values?.workType ?? null,
        positionLevel: values?.positionLevel ?? null,
        locations: values?.locations ?? [],
        requiredSkills: values?.requiredSkills ?? null,
        requiredLanguages: values?.requiredLanguages ?? [],
        requiredEducationLevel: values?.requiredEducationLevel ?? null,
        requiredDriverLicense: values?.requiredDriverLicense ?? null,
        requiredAgeInterval: values?.requiredAgeInterval ?? null,
        militaryServiceRequired: values?.militaryServiceRequired ?? false,
        companyInfoVisible: values?.companyInfoVisible ?? true,
        replyNotification: values?.replyNotification ?? "",
        customReplyNotification: values?.customReplyNotification ?? {
            content: "",
            subject: "",
        },
        images: values?.images ?? [],
    }

    return ({
        validationSchema,
        initialValues,
        defaultValues,
        setDefaultValues,
    })


}

