


export interface IAnonymousCreateConversionRequestFormResponse {
    _id: string;
}

export interface IAnonymousCreateConversionRequestFormVariables {
    authorizedPersonEmail: string | null,
    authorizedPersonName: string | null,
    authorizedPersonPhoneNumber: string | null,
    authorizedPersonSurname: string | null,
    authorizedPersonTitle: string | null,
    city: string | null,
    companyName: string | null,
    district: string | null,
    numberOfEmployees: string | null,
    sector: string | null,
    website: string | null
}

export const anonymous_createConversionRequestForm = (variables: IAnonymousCreateConversionRequestFormVariables) => {
    console.log({ variables });
    const data = JSON.stringify({
        query: `
        mutation Anonymous_createConversionRequestForm($conversionRequestFormInput: ConversionRequestFormInput!) {
            anonymous_createConversionRequestForm(conversionRequestFormInput: $conversionRequestFormInput) {
              _id
            }
          }
        `,
        variables: {
            conversionRequestFormInput: { ...variables }
        }
    })

    return data

}