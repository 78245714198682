

import { paymentApi } from "../paymentApi";
import { IUserOrdersResponse, IUserOrdersVariables, IUserProductsResponse, IUserProductsVariables, self_user, user_orders, user_products } from "./queries";
import { IUserProductResponse, IUserProductVariables, user_product } from "./queries/_user_product";
import { IUserOrderResponse, IUserOrderVariables, user_order } from "./queries/_user_order";
import { ISelfUserResponse, ISelfUserVariables } from "./queries/_self_user";
import {
    ISelfUpdateUserBillingInfoResponse, ISelfUpdateUserBillingInfoVariables, IUserCreateOrderResponse,
    IUserCreateOrderVariables, IUserUpdateOrderResponse, IUserUpdateOrderVariables, self_updateUserBillingInfo, user_createOrder, user_updateOrder
} from "./mutations";
import { IGraphqlResponse } from "interfaces/IGraphqlResponse";


const userPaymentAPI = paymentApi.injectEndpoints({
    endpoints: (builder) => ({

        // #################### User Queries #################
        getUserProducts: builder.query<IUserProductsResponse, IUserProductsVariables>({
            query: (variables: IUserProductsVariables) => {
                return ({
                    url: "",
                    body: user_products(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.user_products
            },
            providesTags: ["user_products"]
        }),
        getUserProduct: builder.query<IUserProductResponse, IUserProductVariables>({
            query: (variables: IUserProductVariables) => {
                return ({
                    url: "",
                    body: user_product(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.user_product
            },
            providesTags: ["user_product"]
        }),
        getSelfUser: builder.query<ISelfUserResponse, ISelfUserVariables>({
            query: (variables: ISelfUserVariables) => {
                return ({
                    url: "",
                    body: self_user(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.self_user
            },
            providesTags: ["self_user"]

        }),
        getUserOrder: builder.query<IUserOrderResponse, IUserOrderVariables>({
            query: (variables: IUserOrderVariables) => {
                return ({
                    url: "",
                    body: user_order(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.user_order
            },
            providesTags: ["user_order", "self_user"]
        }),
        
        getUserOrders: builder.query<IUserOrdersResponse, IUserOrdersVariables>({
            query: (variables: IUserOrdersVariables) => {
                return ({
                    url: "",
                    body: user_orders(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.user_orders
            },
            providesTags: ["user_orders", "self_user"]
        }),


        // #################### User Mutations #################
        userCreateOrder: builder.mutation<IGraphqlResponse<IUserCreateOrderResponse>, IUserCreateOrderVariables>({
            query: (variables: IUserCreateOrderVariables) => {
                return ({
                    url: "",
                    body: user_createOrder(variables)
                })
            },
            transformResponse: (response: any) => {
                console.log(response, "response api")
                return response
            },
            invalidatesTags: ["user_order", "user_orders", "self_user"]
        }),
        userUpdateOrder: builder.mutation<IUserUpdateOrderResponse, IUserUpdateOrderVariables>({
            query: (variables: IUserUpdateOrderVariables) => {
                return ({
                    url: "",
                    body: user_updateOrder(variables)
                })
            },
            transformResponse: (response: any) => {
                return response
            },
            invalidatesTags: ["user_order", "user_orders", "self_user"]
        }),
        selfUpdateUserBillingInfo: builder.mutation<IGraphqlResponse<ISelfUpdateUserBillingInfoResponse>, ISelfUpdateUserBillingInfoVariables>({
            query: (variables: ISelfUpdateUserBillingInfoVariables) => {
                return ({
                    url: "",
                    body: self_updateUserBillingInfo(variables)
                })
            },
            transformResponse: (response: any) => {
                return response
            },
            invalidatesTags: ["self_user",]
        }),



    }),
})


export const {
    // ########## queries ###############

    useGetUserProductsQuery,
    useLazyGetUserProductsQuery,

    useGetUserProductQuery,
    useLazyGetUserProductQuery,

    useGetSelfUserQuery,
    useLazyGetSelfUserQuery,

    useGetUserOrderQuery,
    useLazyGetUserOrderQuery,
    
    useGetUserOrdersQuery,
    useLazyGetUserOrdersQuery,


    // ########## mutation ###############
    useUserCreateOrderMutation,
    useUserUpdateOrderMutation,
    useSelfUpdateUserBillingInfoMutation,

} = userPaymentAPI