

export interface ICompanyRemoveCustomApplicationListItemsResponse {
    success: boolean
}
export interface ICompanyRemoveCustomApplicationListItemsVariables {
    customApplicationListId: string
    customApplicationListItems: Array<{ _id: string }>,
}


export const company_removeCustomApplicationListItems = (variables: ICompanyRemoveCustomApplicationListItemsVariables) => {

    const data = JSON.stringify({
        query: `
        mutation Company_removeCustomApplicationListItems($customApplicationListId: ID!, $customApplicationListItems: [EntityInput!]!) {
            company_removeCustomApplicationListItems(customApplicationListId: $customApplicationListId, customApplicationListItems: $customApplicationListItems) {
              success
            }
          }
        `,
        variables: {
            "customApplicationListId": variables?.customApplicationListId ?? null,
            "customApplicationListItems": variables?.customApplicationListItems ?? []

        }
    })

    return data

}