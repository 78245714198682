import axios from "axios";
import { graphqlAxiosConfig } from "utils";


export const company_onboard = async (status: string = "completed") => {

    const data = JSON.stringify({
        query: `mutation Company_onboard {
            company_onboard {
              success
            }
          }`
    })

    try {
        const response = await axios.request(graphqlAxiosConfig({ data, microservice: "user" }))
        if (response?.data?.errors?.length > 0) {
            return {
                errorMessage: response.data?.errors[0].message,
            };
        }
        return response?.data?.data;
    } catch (err) {
        return err;
    }
}