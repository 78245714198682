export interface ICandidateTestVideoDownloadPresignedUrlResponse {
    candidateTestVideoDownloadPresignedUrl: string
}


export interface ICandidateTestVideoDownloadPresignedUrlVariables {
    candidateId: any
    questionIndex: number
    ozgelecekField: string | null | undefined | any
}



export const candidateTestVideoDownloadPresignedUrl = (variables: ICandidateTestVideoDownloadPresignedUrlVariables) => {

    const data = JSON.stringify({
        query: `query Query($candidateId: ID!, $ozgelecekField: String!, $questionIndex: Int!) {
            candidateTestVideoDownloadPresignedUrl(candidateId: $candidateId, ozgelecekField: $ozgelecekField, questionIndex: $questionIndex)
          }`,
        variables: variables
    })

    return data
}