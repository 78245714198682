import * as yup from "yup"
import { Formik } from 'formik'
import { AnimatePresence, motion } from "framer-motion";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material'





const validationSchema = yup.object().shape({
    hobby: yup.string().nullable().required("Lütfen doldurunuz!")
})


const defaultValues = {
    hobby: "",
}


interface IHobbiesFormProps {
    onSubmit: (e: string) => any,
    onDeleteItem: (e: any) => void,
    list: Array<string>,
    type: string
}


export const HobbiesForm = ({ onSubmit, list, onDeleteItem, type }: IHobbiesFormProps) => {


    return (
        <Box sx={{ width: '100%' }}>

            <Formik
                initialValues={defaultValues}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                    onSubmit(values.hobby)
                    resetForm()
                }}
            >
                {
                    ({ handleSubmit, values, setFieldValue }) => {
                        return (
                            <Grid container spacing={3} >

                                {type !== "account-page" && <Grid item xs={12}>
                                    <Typography variant="h3">
                                        Hobiler ve İlgi Alanları*
                                    </Typography>
                                </Grid>}

                                <Grid item sx={{ flex: 1 }}>
                                    <Stack direction="row" gap={2} alignContent="stretch">

                                        <input
                                            placeholder="Hobi / İlgi Alanı"
                                            value={values.hobby}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setFieldValue("hobby", value.charAt(0).toUpperCase() + value.slice(1))
                                            }}
                                            style={{
                                                height: "50px",
                                                padding: '10px 20px',
                                                flex: 1,
                                                outline: 'none',
                                                border: "1px solid #ccc",
                                            }}
                                        />

                                        <Button
                                            onClick={() => handleSubmit()}
                                            color="primary"
                                            variant="outlined"
                                            sx={{
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            Ekle
                                        </Button>

                                    </Stack>
                                </Grid>

                            </Grid>
                        )
                    }
                }
            </Formik>

            <Stack direction="row" gap={2} sx={{ mt: 3 }}>
                <AnimatePresence mode="popLayout" >
                    {
                        list.map((hobby, index) => {

                            return (
                                <motion.div

                                    layout
                                    initial={{ scale: 0.8, opacity: 0 }}
                                    animate={{ scale: 1, opacity: 1 }}
                                    exit={{ scale: 0.8, opacity: 0 }}
                                    transition={{ type: "spring" }}
                                    key={hobby}
                                    style={{ position: 'relative' }}
                                >

                                    <Button variant="contained" color="secondary" >
                                        {hobby}
                                    </Button>

                                    <Box
                                        onClick={() => onDeleteItem(index)}
                                        sx={{
                                            bgcolor: 'primary.main',
                                            position: 'absolute',
                                            top: "-8px",
                                            right: "-8px",
                                            borderRadius: '100%',

                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',

                                            '&:hover': {
                                                transform: 'scale(1.05)'
                                            },
                                            '&:active': {
                                                transform: 'scale(1)'
                                            },
                                        }}
                                    >
                                        <CloseRoundedIcon sx={{ width: '20px', height: '20px', color: '#fff' }} />
                                    </Box>

                                </motion.div>

                            )
                        })
                    }

                </AnimatePresence>
            </Stack>

        </Box>

    )
}
