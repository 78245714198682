import HasFilter from "interfaces/HasFilter";
import HasPagination from "interfaces/HasPagination";
import HasTimeStamp from "interfaces/HasTimeStamp";
import IEntity from "interfaces/IEntity";
import IPaginatedListResult from "interfaces/IPaginatedListResult";
import { Role } from "types/_role";


export interface IUserProductsItem extends IEntity, HasTimeStamp {
  name: string;
  type: string;
  targetRoles: Role[];
  amount: number;
  listUnitPrice: number;
  unitPrice: number;
  price: number;
  discount?: number;
  currency: string;
  purchaseMethod: string;
  imageUrl?: string;
  priceWithoutVAT?: number;
}


export interface IUserProductsResponse extends IPaginatedListResult<IUserProductsItem> {
}

export interface IUserProductsVariables extends HasPagination, HasFilter<
{
  name: string;
  type?: string;
  targetRole?: Role;
  purchaseMethod?: string;
}
> {}

export const user_products = (variables: IUserProductsVariables) => {

  const data = JSON.stringify({
    query: `
      query User_products($filter: FilterProductInput, $pagination: Pagination) {
        user_products(filter: $filter, pagination: $pagination) {
          count
          data {
            _id
            amount
            name
            price
            type
            targetRoles
            listUnitPrice
            unitPrice
            discount
            currency
            purchaseMethod
            imageUrl
            createdAt
            updatedAt
            priceWithoutVAT
          }
        }
      }
    `,
    variables: variables
  })

  return data

}