import { FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

export const WorkExperienceInMonthComponent = ({ value, setValue }: any) => {

    const [data, setData] = useState({
        month: 0,
        year: 0
    })

    const setDefaultValue = () => {
        const year = Math.floor(value / 12)
        const month = value % 12
        setData({ month, year })
    }

    useEffect(() => {
        setDefaultValue()
    }, [])


    useEffect(() => {
        handleDataChange()
    }, [data])


    const handleDataChange = () => {
        const newValue = data.year * 12 + data.month
        setValue(newValue)
    }




    return (
        <Stack direction={{ xs: 'column', sm: 'column' }} gap={2}>
            <Typography
                variant="body2"
                sx={{
                    // mb: 1,
                    // flex: 1,
                    // width: '100%',
                    whiteSpace: { sm: 'nowrap' }
                }}
            >
                Kaç yıl çalışma deneyiminiz var?
            </Typography>

            <Stack direction="row" gap={3} width={{ xs: '100%' }}>
                <FormControl fullWidth>
                    <InputLabel id="candidata_year_input">Yıl</InputLabel>
                    <Select
                        label="Yıl"
                        labelId="candidata_year_input"
                        defaultValue={0}
                        value={data.year}
                        onChange={(e: any) => setData((prevState: any) => ({ ...prevState, year: e.target.value }))}
                    >
                        {
                            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => {
                                return (<MenuItem key={item} value={item}>{item === 0 ? item + " Yıl" : item + " Yıl"}{item === 10 && "+"}</MenuItem>)
                            })
                        }
                    </Select>
                </FormControl>


                <FormControl fullWidth>
                    <InputLabel id="candidata_month_input">Ay</InputLabel>
                    <Select
                        label="Ay"
                        labelId="candidata_month_input"
                        defaultValue={0}
                        value={data.month}
                        onChange={(e: any) => setData((prevState: any) => ({ ...prevState, month: e.target.value }))}
                    >
                        {
                            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(item => {
                                return (<MenuItem key={item} value={item}>{item === 0 ? item + " Ay" : item + " Ay"}</MenuItem>)
                            })
                        }
                    </Select>
                </FormControl>
            </Stack>
        </Stack>
    )
}
