import * as yup from "yup"
import { Formik } from 'formik'
import { FormikTextField } from 'components'
import { AnimatePresence, motion } from "framer-motion"
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material'




const validationSchema = yup.object().shape({
    skill: yup.string().nullable(),
})


const defaultValues = {
    skill: "",
}


interface ISkillsFormProps {
    onSubmit: (e: string) => void
    onDeleteItem: (e: number) => void
    list: Array<string>
}


export const SkillsForm = ({ onSubmit, list, onDeleteItem }: ISkillsFormProps) => {

    return (
        <>

            <Formik
                initialValues={defaultValues}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                    onSubmit(values.skill)
                    resetForm()
                }}
            >
                {
                    ({ handleSubmit, setFieldValue, values }) => {
                        return (
                            <>
                                {/* <Card sx={{ width: '100%', p: 3 }}> */}

                                <Grid container>

                                    <Grid item sx={{ flex: 1 }}>

                                        <Stack gap={2} alignContent="stretch" px={2} sx={{ flexDirection: {xs: "column", sm: "row"} }}>

                                            <input
                                                placeholder="Bir yetenek veya beceri yazın"
                                                value={values.skill}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    setFieldValue("skill", value.charAt(0).toUpperCase() + value.slice(1))
                                                }}
                                                style={{
                                                    height: "50px",
                                                    padding: '10px 20px',
                                                    flex: 1,
                                                    outline: 'none',
                                                    border: "1px solid #ccc",
                                                }}
                                            />

                                            <Button
                                                onClick={() => handleSubmit()}
                                                color="primary"
                                                variant="outlined"
                                                sx={{
                                                    whiteSpace: "nowrap",
                                                }}
                                            >
                                                Ekle
                                            </Button>

                                        </Stack>
                                    </Grid>


                                </Grid>

                                {/* </Card> */}
                            </>
                        )
                    }
                }
            </Formik>



            <Stack direction="row" gap={2} sx={{ mt: 1, ml:2, mb:2, flexWrap:"wrap" }}>
                <AnimatePresence mode="popLayout" >
                    {
                        list.map((hobby, index) => {

                            return (
                                <motion.div

                                    layout
                                    initial={{ scale: 0.8, opacity: 0 }}
                                    animate={{ scale: 1, opacity: 1 }}
                                    exit={{ scale: 0.8, opacity: 0 }}
                                    transition={{ type: "spring" }}
                                    key={hobby}
                                    style={{ position: 'relative' }}
                                >

                                    <Button variant="contained" color="secondary" >
                                        {hobby}
                                    </Button>

                                    <Box
                                        onClick={() => onDeleteItem(index)}
                                        sx={{
                                            bgcolor: 'primary.main',
                                            position: 'absolute',
                                            top: "-8px",
                                            right: "-8px",
                                            borderRadius: '100%',

                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',

                                            '&:hover': {
                                                transform: 'scale(1.05)'
                                            },
                                            '&:active': {
                                                transform: 'scale(1)'
                                            },
                                        }}
                                    >
                                        <CloseRoundedIcon sx={{ width: '20px', height: '20px', color: '#fff' }} />
                                    </Box>

                                </motion.div>

                            )
                        })
                    }

                </AnimatePresence>
            </Stack>

        </>
    )
}
