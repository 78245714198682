import axios from "axios";
import { ICompanyInformation } from "types";
import { graphqlAxiosConfig } from "utils";

interface ICompanyUpdateCompany {
    variables: ICompanyInformation
}

export const company_updateCompany = async ({ variables }: ICompanyUpdateCompany) => {

    const data = JSON.stringify({
        query: `
            mutation Company_updateCompany($updateCompanyInput: UpdateCompanyInput!) {
                company_updateCompany(updateCompanyInput: $updateCompanyInput) {
                    _id
                }
            }
        `,
        variables: {

            "updateCompanyInput": {
                "companyInformation": {
                    "city": variables?.city ?? null,
                    "district": variables?.district ?? null,
                    "sector": variables?.sector ?? null,
                    "companyName": variables?.companyName ?? null,
                    "companyBrief": variables?.companyBrief ?? null,
                    "companyAddress": variables?.companyAddress ?? null,
                    "foundationYear": variables?.foundationYear ?? null,
                    "numberOfEmployees": variables?.numberOfEmployees ?? null,

                    "email": variables?.email ?? null,
                    "taxNumber": variables?.taxNumber ?? null,
                    "taxOffice": variables?.taxOffice ?? null,
                    "phoneNumber": variables?.phoneNumber ?? null,
                    "profileImage": variables?.profileImage ?? null,

                    "facebook": variables?.facebook ?? null,
                    "instagram": variables?.instagram ?? null,
                    "linkedin": variables?.linkedin ?? null,
                    "website": variables?.website ?? null
                },
                "personalInformation": {
                    "name": variables?.personalName ?? null,
                    "surname": variables?.personalSurname ?? null,
                }
            }
        }

    })

    try {
        const response = await axios.request(graphqlAxiosConfig({ data, microservice: "user" }))
        if (response?.data?.errors?.length > 0) {
            return {
                errorMessage: response.data?.errors[0].message,
            };
        }
        return response?.data?.data;
    } catch (err) {
        return err;
    }
}