import { Box, Button, Divider, Link, Stack, Typography, Skeleton, Chip } from '@mui/material'
import { DashboardLayout } from 'layouts'
import { ICompanyAdvertisementsVariables, useGetCompanyAdvertisementsQuery, useGetCompanyInformationQuery, useAppSelector } from 'context'


import WhatshotOutlinedIcon from '@mui/icons-material/WhatshotOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import moment from 'moment';
import { enumFunctions } from 'utils';
import { NumberOfEmployeeEnum } from 'types';
import { CompanyAdvertisementCard, Iconify } from 'components';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes';
import { useState } from 'react';
import { BusinessCenter, Email, Facebook, Instagram, LinkedIn, Numbers, Phone } from '@mui/icons-material';
import { Company } from 'assets';




export const CompanyAboutCompanyPage = () => {

    const { isOnboarding } = useAppSelector(state => state?.auth)

    const navigate = useNavigate()
    const [variables] = useState<ICompanyAdvertisementsVariables>({
        filter: {
            filter: {
                cities: [],
            },
            search: {
                all: ""
            }
        },
        pagination: {
            page: 1,
            pageSize: 3,
        }
    })
    const { data: company, isLoading } = useGetCompanyInformationQuery("")
    const { data: advertisements, isLoading: advertisementsLoading } = useGetCompanyAdvertisementsQuery(variables)



    const HeaderItem = ({ icon, name, value }: { icon: any, name: string, value: any }) => {
        return (
            <Stack direction="row" spacing={2} alignItems="center">

                <Box
                    sx={{
                        border: '1px solid #d6ddeb',
                        borderRadius: '50%',
                        flexShrink: '0',
                        width: '50px',
                        height: '50px',
                        p: 1,

                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'primary.main',

                        '&>*': {
                            width: '100%',
                            height: '100%',
                        }
                    }}
                >
                    {icon}
                </Box>

                <Stack>
                    <Typography variant='body2'>
                        {name}
                    </Typography>
                    <Typography variant='subtitle2'>
                        {value}
                    </Typography>
                </Stack>

            </Stack>
        )
    }

    const WidgetItem = ({ icon, name, value }: { icon: any, name: string, value: any }) => {
        return (
            <Stack direction="row" spacing={2} alignItems="center">

                <Box
                    sx={{
                        border: '1px solid #d6ddeb',
                        borderRadius: '50%',
                        flexShrink: '0',
                        width: '40px',
                        height: '40px',
                        p: 1,

                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'primary.main',

                        '&>*': {
                            width: '100%',
                            height: '100%',
                        }
                    }}
                >
                    {icon}
                </Box>

                <Stack>
                    <Typography variant='body2'>
                        {name}
                    </Typography>
                    <Typography variant='subtitle2'>
                        {value}
                    </Typography>
                </Stack>

            </Stack>
        )
    }

    return (
        <DashboardLayout>

            <Stack direction="row" justifyContent="space-between">
                <Typography variant='h1'>
                    Şirket Bilgilerim
                </Typography>

                <Button
                    onClick={() => navigate(routes.company.dashboard.myCompany)}
                    variant='contained'
                    color="primary"
                >
                    Güncelle
                </Button>
            </Stack>

            <Box>
                {
                    isLoading ? (
                        <Stack spacing={3} sx={{ py: 3 }}>
                            <Skeleton
                                variant='rectangular'
                                sx={{
                                    p: 2,
                                    width: '100%',
                                    height: '200px'
                                }}
                            />
                            <Skeleton
                                variant='rectangular'
                                sx={{
                                    p: 2,
                                    width: '100%',
                                    height: '100px'
                                }}
                            />
                            <Skeleton
                                variant='rectangular'
                                sx={{
                                    p: 2,
                                    width: '100%',
                                    height: '100px'
                                }}
                            />
                        </Stack>
                    ) : (
                        <Stack
                            spacing={3}
                            sx={{
                                py: 3
                            }}
                        >

                            {/* ******************* Header ********************* */}
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                spacing={3}
                                sx={{
                                    border: '1px solid #d6ddeb',
                                    p: 3,
                                    backgroundColor: '#fff',
                                    transition: 'all 0.3s ease',
                                    position: 'relative',
                                    alignItems: 'center',
                                }}
                            >

                                {/* <EditButton onClick={() => navigate(routes.company.dashboard.myCompany)} /> */}

                                <Box
                                    component="img"
                                    // src='https://cdn-icons-png.flaticon.com/512/5650/5650380.png'
                                    src={company?.companyInformation?.profileImage ?? Company}
                                    sx={{
                                        width: '120px',
                                        height: '120px',
                                        borderRadius: '60px',
                                        objectFit: 'contain',
                                    }}
                                />

                                <Stack spacing={3}>

                                    <Stack>
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <Typography variant='h3'>
                                                {company?.companyInformation?.companyName}
                                            </Typography>
                                            <Chip
                                                onClick={() => navigate("/ayarlar?tab=sirket-dogrulama")}
                                                variant='outlined'
                                                label={isOnboarding === "approved" ? "Onaylandı"
                                                    : isOnboarding === "pendingRequest" ? "Belge yüklenmesi gerekiyor"
                                                        : "Onay Bekliyor"}
                                                color={isOnboarding === "approved" ? "primary"
                                                    : isOnboarding === "rejected" ? "error"
                                                        : "warning"}
                                                style={{ borderRadius: 25 }}
                                                icon={<Iconify icon={isOnboarding === "approved" ? "mdi:tick-circle" : "icon-park-outline:attention"} />}
                                            />
                                        </Stack>
                                        <Typography component={Link} href={company?.companyInformation?.website} variant='body2'>
                                            {company?.companyInformation?.website}
                                        </Typography>
                                    </Stack>

                                    <Stack direction={{ xs: 'column', sm: 'row' }} flexWrap="wrap" spacing={2}>
                                        <HeaderItem
                                            icon={<WhatshotOutlinedIcon />}
                                            name="Kuruluş Yılı"
                                            value={moment(company?.companyInformation?.foundationYear).format('YYYY')}
                                        />

                                        <HeaderItem
                                            icon={<GroupsOutlinedIcon />}
                                            name="Çalışan Sayısı"
                                            value={enumFunctions.findAndDisplayName(NumberOfEmployeeEnum, (company?.companyInformation?.numberOfEmployees ?? "")?.toString())}
                                        />

                                        <HeaderItem
                                            icon={<RoomOutlinedIcon />}
                                            name="Konum"
                                            value={company?.companyInformation?.city + " / " + company?.companyInformation?.district}
                                        />

                                        <HeaderItem
                                            icon={<BusinessOutlinedIcon />}
                                            name="Sektör"
                                            value={company?.companyInformation?.sector}
                                        />

                                    </Stack>

                                </Stack>

                            </Stack>



                            {/* ********************* Main ********************** */}

                            <Stack direction={{ xs: 'column-reverse', lg: "row" }} spacing={3}>

                                <Stack flex={1} position="relative" spacing={3} sx={{ height: '100%' }} >

                                    {/* <EditButton onClick={() => null} /> */}

                                    <Typography variant='h3'>
                                        Şirket Hakkında
                                    </Typography>

                                    <Typography>
                                        {company?.companyInformation.companyBrief}
                                    </Typography>


                                    <Divider />
                                    {/* ***************** advertisements **************** */}
                                    <Box
                                        sx={{
                                            // pt: 5,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 3,
                                        }}
                                    >
                                        {
                                            advertisementsLoading ? (
                                                <>Loading...</>
                                            ) : (
                                                advertisements?.count ? (
                                                    advertisements?.data?.map((advertisement: any, index: number) => {
                                                        return (
                                                            <CompanyAdvertisementCard
                                                                key={index}
                                                                advertisement={advertisement}
                                                                logo={company?.companyInformation?.profileImage ?? Company}
                                                                onShow={() => {
                                                                    navigate(`${routes.company.dashboard.advertisementDetails}/${advertisement?._id}`)
                                                                }}
                                                                onEdit={() => {
                                                                    navigate(`${routes.company.dashboard.updateAdvertisement}/${advertisement?._id}`)
                                                                }}
                                                            />
                                                        )
                                                    })
                                                ) : (
                                                    <>
                                                        Hiçbir ilan bulunamadı.
                                                    </>
                                                )
                                            )
                                        }
                                    </Box>

                                </Stack>

                                {/* ********************* side info ********************** */}
                                <Stack
                                    direction={{ xs: 'column', sm: 'row', lg: 'column' }}
                                    spacing={3}
                                    sx={{
                                        border: '1px solid #d6ddeb',
                                        p: 3,
                                        backgroundColor: '#fff',
                                        transition: 'all 0.3s ease',
                                        position: 'relative',
                                        width: { xs: '100%', sm: '100%', lg: '300px' },
                                        // height: '100%',
                                        // cursor: 'pointer',
                                        // ":hover": {
                                        //     boxShadow: '0 0 10px 0 #d6ddeb',
                                        // },
                                    }}
                                >
                                    {/* <EditButton onClick={() => null} /> */}

                                    <Typography variant='h5'>
                                        Ek Bilgiler
                                    </Typography>

                                    <Stack direction={{ xs: 'column', sm: 'row' }} flexWrap="wrap" spacing={2}>
                                        <WidgetItem
                                            icon={<Email />}
                                            name="Email"
                                            value={company?.companyInformation?.email}
                                        />
                                        <WidgetItem
                                            icon={<Phone />}
                                            name="Telefon Numarası"
                                            value={company?.companyInformation?.phoneNumber}
                                        />
                                        <WidgetItem
                                            icon={<RoomOutlinedIcon />}
                                            name="Açık Adres"
                                            value={company?.companyInformation?.companyAddress}
                                        />

                                        <WidgetItem
                                            icon={<BusinessCenter />}
                                            name="Vergi Dairesi"
                                            value={company?.companyInformation?.taxOffice}
                                        />

                                        <WidgetItem
                                            icon={<Numbers />}
                                            name="Vergi Numarası"
                                            value={company?.companyInformation?.taxNumber}
                                        />

                                        {!!company?.companyInformation?.linkedin && <WidgetItem
                                            icon={<LinkedIn />}
                                            name="Linkedin"
                                            value={company?.companyInformation?.linkedin}
                                        />}

                                        {!!company?.companyInformation?.facebook && <WidgetItem
                                            icon={<Facebook />}
                                            name="Facebook"
                                            value={company?.companyInformation?.facebook}
                                        />}

                                        {!!company?.companyInformation?.instagram && <WidgetItem
                                            icon={<Instagram />}
                                            name="Instagram"
                                            value={company?.companyInformation?.instagram}
                                        />}



                                    </Stack>
                                </Stack>

                            </Stack>







                        </Stack>
                    )
                }
            </Box>


        </DashboardLayout>
    )
}


const EditButton = ({ onClick }: { onClick: () => void }) => {

    return (
        <Box
            onClick={onClick}
            position={'absolute'}
            top={15}
            right={15}
            border={"1px solid #ddd"}
            width={30}
            height={30}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <Iconify icon={"basil:edit-outline"} sx={{ width: 20, height: 20, cursor: 'pointer', color: 'primary.dark' }} />
        </Box>
    )
}