import ProfileBox from './ProfileBox'
import { Stack, Box } from '@mui/material'
import { Logo, NavbarPattern } from "assets";
import { NavSectionVertical } from "minimal/components/nav-section";



type Props = {
    handleClick: (e: any) => void;
    anchorEl: any;
    open: boolean;
    handleClose: () => void;
    handleAccount: () => void;
    handleLogout: () => void;
    navData: any;
    handleSettings: () => void;
};

const NavDefault = ({ handleClick, anchorEl, open, handleClose, handleAccount, handleLogout, navData, handleSettings }: Props) => {



    return (
        <Stack
            sx={{
                width: '280px',
                flexShrink: 0,
                height: '100%',
                backgroundSize: 'cover',
                backgroundColor: '#F8F8FD',
                borderRight: '2px solid #dedede',
                backgroundImage: `url(${NavbarPattern})`,
            }}
        >

            <Box sx={{ flex: 1 }}>

                <Box sx={{ mb: 2, mt: 4, ml: 4 }}>
                    <img width={"80%"} src={Logo} alt="logo" />
                </Box>

                <NavSectionVertical
                    sx={{ display: 'flex', flex: 1, mt: 5 }}
                    data={navData}
                    config={{
                        currentRole: 'candidate',
                    }}
                />

            </Box>

            <ProfileBox
                handleClick={handleClick}
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
                handleAccount={handleAccount}
                handleLogout={handleLogout}
                handleSettings={handleSettings}
            />

        </Stack>
    )

}

export default NavDefault