import { useFormikContext } from 'formik'
import { Checkbox, FormControlLabel, FormHelperText, StandardTextFieldProps } from '@mui/material'



interface IFormikTextField extends StandardTextFieldProps {
    name: string,
}


export const FormikCheckbox = ({ name = "", onChange = () => null, ...rest }: IFormikTextField) => {

    const { getFieldProps, getFieldMeta } = useFormikContext<IFormikTextField | any>()

    const error = !!(getFieldMeta(name).touched && getFieldMeta(name).error)
    const helperText = !!getFieldMeta(name).touched && getFieldMeta(name).error && `${getFieldMeta(name).error}`

    return (
        <div>
            <FormControlLabel

                // {...rest}
                value={true}
                label={rest.label}
                control={
                    <Checkbox

                        {...getFieldProps(name)}
                        onChange={(e) => {
                            getFieldProps(name).onChange(e)
                            onChange(e)
                        }}
                        checked={getFieldProps(name).value}
                    />
                }
            />

            {
                (!!error || helperText) && (
                    <FormHelperText
                        error={!!error}
                    >
                        {error ? error : helperText}
                    </FormHelperText>
                )
            }
        </div>
    )
}
