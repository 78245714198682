import HasFilter from "interfaces/HasFilter"
import HasPagination from "interfaces/HasPagination"
import IPaginatedListResult from "interfaces/IPaginatedListResult"
import { ICountry } from "interfaces/user/parameter/interfaces/geo/ICountry"
import { IFilterParameter } from "interfaces/user/parameter/interfaces/parameter/IFilterParameter"

export interface IAnonymousCountriesResponse extends IPaginatedListResult<ICountry> {}

export interface IAnonymousCountriesVariables extends HasFilter<IFilterParameter>, HasPagination { }


export const anonymous_countries = (variables?: IAnonymousCountriesVariables) => {

    const data = JSON.stringify({
        query: `
        query anonymous_countries($filter: FilterParameterInput, $pagination: Pagination) {
            anonymous_countries(filter: $filter, pagination: $pagination) {
              count
              data {
                code
                codeISO3
                flagUrl
                name
              }
            }
          }
        `,
        variables: {
            filter: {
                "name": variables?.filter?.name ?? null
            },
            pagination: {
                "page": variables?.pagination?.page ?? null,
                "pageSize": variables?.pagination?.pageSize ?? null,
                "sort": variables?.pagination?.sort ?? null
            }
        }
    })

    return data
}
