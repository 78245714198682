

export interface ICompanyCustomApplicationsResponse { }

export interface ICompanyCustomApplicationsVariables { }

export const company_customApplicationLists = () => {

    const data = JSON.stringify({
        query: `
        query Company_customApplicationLists {
            company_customApplicationLists {
              count
              data {
                _id
                name
              }
            }
          }
        `,
        variables: {}
    })

    return data

}