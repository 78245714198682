export type TCandidateUpdateOzgelecekVariables = {

    ozgelecekId: string,
    testResults: {

        areaTestResults: Array<{
            answer: number,
            question: {
                _id: string
            }
        }>,
        motivationTestResults: Array<{
            answer: number,
            question: {
                _id: string
            }
        }>,
    },

    ozgelecekTest: {
        first: {
            text?: string | null,
            video?: string | null
        },
        second: {
            text?: string | null,
            video?: string | null
        }
    }

}

export const candidate_updateOzgelecek = (variables: TCandidateUpdateOzgelecekVariables) => {

    const data = JSON.stringify({
        query: `
        mutation Candidate_updateOzgelecek($ozgelecekId: ID!, $updateInput: UpdateOzgelecekInputForCandidate!) {
            candidate_updateOzgelecek(ozgelecekId: $ozgelecekId, updateInput: $updateInput)
          }
        `,
        variables: {
            "ozgelecekId": variables?.ozgelecekId,

            "updateInput": {
                "testResults": {
                    "areaTestResults": variables?.testResults?.areaTestResults ?? [],
                    "motivationTestResults": variables?.testResults?.motivationTestResults ?? [],
                },
                "ozgelecekTest": variables?.ozgelecekTest ?? null,
            }
        }
    })

    return data
}