

export interface ICompanyDocumentUploadPresignedUrlResponse {
  fileName: string
  presignedUploadUrl: string
}


export interface ICompanyDocumentUploadPresignedUrlVariables {
  documentType: string
}



export const companyDocumentUploadPresignedUrl = (variables: ICompanyDocumentUploadPresignedUrlVariables) => {

  const data = JSON.stringify({
    query: `query CompanyDocumentUploadPresignedUrl($documentType: CompanyDocument!) {
      companyDocumentUploadPresignedUrl(documentType: $documentType) {
        fileName
        presignedUploadUrl
      }
    }`,
    variables: {
      "documentType": variables?.documentType ?? null,
    }
  })

  return data
}