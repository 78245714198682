

export type TReferenceRejectReferenceMutationVariables = {
  variables: {
    token: string
  }
}


export const reference_rejectReference = ({ variables }: TReferenceRejectReferenceMutationVariables) => {

  const data = JSON.stringify({
    query: `
        mutation Reference_rejectReference($token: String!) {
            reference_rejectReference(token: $token) {
              success
            }
          }
        `,
    variables: {
      "token": variables?.token
    }
  })

  return data

}