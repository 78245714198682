import { LoadingButton } from '@mui/lab'
import { useAppSelector } from 'context'
import { Box, Typography } from '@mui/material'



export const OzgelecekCreateUpdateFinishSection = ({ onSubmit, isLoading }: { onSubmit: (e: any) => void, isLoading: boolean }) => {

    const { values } = useAppSelector(state => state.candidateCreateOzgelecek)

    return (
        <Box>
            <Typography>
                You finished successfully. You can create ozgelecek.
            </Typography>

            <Box sx={{ my: 3, textAlign: 'right' }}>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={() => onSubmit(values)}
                    disabled={isLoading}
                    loading={isLoading}
                >
                    Submit
                </LoadingButton>
            </Box>
        </Box>
    )
}
