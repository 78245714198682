

export interface IUserPresignedUrlsProfileResponse {
    profileImage: {
        url: string
        presignedUploadUrl: string
        presignedThumbnailUpload: string
    }
}


export interface IUserPresignedUrlsProfileVariables {
    contentType: "jpegImage" | "pngImage"
}



export const userPresignedUrlsProfile = (variables: IUserPresignedUrlsProfileVariables) => {

    const data = JSON.stringify({
        query: `
        query UserPresignedUrls($contentType: ImageContentType!) {
            userPresignedUrls(contentType: $contentType) {
              profileImage {
                presignedUploadUrl
                url
                presignedThumbnailUpload
              }
            }
          }
        `,
        variables: {
            "contentType": variables?.contentType ?? null
        }
    })

    return data
}