



export type TCandidateRemoveApplicationResponse = {
    success: boolean
}

export type TCandidateRemoveApplicationVariables = {
    applicationId: string

}



export const candidate_removeApplication = (variables: TCandidateRemoveApplicationVariables) => {

    const data = JSON.stringify({
        query: `
        mutation Candidate_removeApplication($applicationId: ID!) {
            candidate_removeApplication(applicationId: $applicationId) {
              success
            }
          }
          `,
        variables: {
            "applicationId": variables?.applicationId ?? null

        }
    })

    return data

}