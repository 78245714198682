import { useFormikContext } from 'formik'
import { TextField, StandardTextFieldProps } from '@mui/material'



interface IFormikTextField extends StandardTextFieldProps {
    name: string,
    upperCase?: boolean
}

export const FormikTextField = ({ name = "", upperCase = false, ...rest }: IFormikTextField) => {

    const { getFieldProps, getFieldMeta } = useFormikContext<IFormikTextField | any>()

    const error = !!(getFieldMeta(name).touched && getFieldMeta(name).error)
    const helperText = !!getFieldMeta(name).touched && getFieldMeta(name).error && `${getFieldMeta(name).error}`

    return (
        upperCase ?
            <TextField
                {...getFieldProps(name)}
                {...rest}
                fullWidth
                error={error}
                helperText={rest.helperText ?? helperText}
                inputProps={{
                    ...rest.inputProps,
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {

                        const { value } = event.target;
                        event.target.value = value.charAt(0).toUpperCase() + value.slice(1)
                        rest.inputProps?.onChange?.(event);
                    },
                }}
            />
            :
            <TextField
                {...getFieldProps(name)}
                {...rest}
                fullWidth
                error={error}
                helperText={rest.helperText ?? helperText}
            />
    )
}
