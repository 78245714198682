

export interface ICandidateApplicationVariables {
  applicationId: string
}


export const candidate_application = (variables: ICandidateApplicationVariables) => {

  const data = JSON.stringify({

    query: `
    query Candidate_application($applicationId: String!) {
      candidate_application(applicationId: $applicationId) {
        _id
        advertisement {
          _id
          title
          status
          productType
          extraProduct
          startDate
          quota
          quotaVisibleToApplicant
          field
          department
          language
          description
          place
          workType
          city
          district
          positionLevel
          requiredAgeInterval
          requiredEducationLevel
          requiredSkills
          requiredLanguages {
            name
            reading
            writing
            listening
          }
          militaryServiceRequired
          requiredDriverLicense
          companyInfoVisible
          images
          replyNotification
          customReplyNotification {
            subject
            content
          }
          createdAt
          updatedAt
        }
        candidate {
          _id
          role
          displayName
          displayImageUrl
        }
        createdAt
        ozgelecek {
          _id
          candidate {
            _id
          }
          field
          testResults {
            fieldTestResults {
              question {
                _id
              }
              answer
            }
            motivationTestResults {
              question {
                _id
              }
              answer
            }
          }
          ozgelecekTest {
            first {
              text
              video
            }
            second {
              video
              text
            }
          }
          references {
            _id
          }
          createdAt
          updatedAt
        }
        status
        updatedAt
      }
    }
        `,

    variables: {
      "applicationId": variables?.applicationId,
    }

  })


  return data

}  