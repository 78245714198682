

export type TReferenceApproveReferenceMutationVariables = {
    variables: {
        token: string,
        referenceExplanation: string,
        referenceTests: any
    }
}

export const reference_approveReference = ({ variables }: TReferenceApproveReferenceMutationVariables) => {

    const data = JSON.stringify({
        query: `mutation Reference_approveReference($token: String!, $referenceTests: [TestResultInput!]!, $referenceExplanation: String) {
            reference_approveReference(token: $token, referenceTests: $referenceTests, referenceExplanation: $referenceExplanation) {
              success
            }
          }`,
        variables: {
            "token": variables?.token,
            "referenceExplanation": variables?.referenceExplanation ?? null,
            "referenceTests": variables?.referenceTests ?? []
        }
    })

    return data

}