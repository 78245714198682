import { Box, Typography } from '@mui/material'
import { Iconify } from 'components'
import { DashboardLayout } from 'layouts'
import React from 'react'
import { HelpCenterSection } from 'sections'


const sssList = [
    {
        title: "Özgelecek nedir ve nasıl çalışır?",
        content: "Özgelecek, gelecekteki potansiyeli vurgulayan ve yetenekleri, becerileri, motivasyonu ve ilgi alanlarını öne çıkaran bir işe alım platformudur. Bu platform, adayların gerçek yeteneklerine odaklanarak işverenlerle en uygun adayları buluşturur."
    },
    {
        title: "Özgelecek'in farkı nedir?",
        content: "Özgelecek, diğer platformlardan farklı olarak sadece geçmiş bilgilere ve deneyimlere değil, adayları yeteneklerine, becerilerine, ilgi alanlarına ve motivasyonlarına göre değerlendirerek işe alım süreçlerine destek sağlar. Adaylara yapay zeka destekli testler sunar. Firmalar için de, adayları ölçümleyerek başvuraları iletir."
    },
    {
        title: "Hangi alanlarda iş ilanları bulunabilir ve nasıl başvuru yapılır?",
        content: "Özgelecek'te farklı sektörlerde, meslek gruplarında ve birçok pozisyonda iş ilanları bulunabilir. ozgelecek.net’e ücretsiz kayıt olarak özgeleceğinizi oluşturabilir; yeteneklerinizi, becerilerinizi, motivasyonunuzu ve ilgi alanlarınızı detaylı bir şekilde belirtebilirsiniz. İş ilanlarını inceleyebilir, uygunluk testlerini tamamladıktan sonra ilanlara başvurabilirsiniz."
    },
    {
        title: "Özgelecek'te işverenler kimlerdir?",
        content: "Özgelecek'te geleceğe yönelik vizyonları olan ve yetenekleri önemseyen şirketler ve kurumlar bulunur. Bu şirketler, adayların potansiyelini değerlendirerek başarılı iş ilişkileri kurmayı hedeflerler."
    },
    {
        title: "Özgelecek'te firmalar nasıl işlem yapar ve hangi hizmetleri alabilir?",
        content: "Firmalar Özgelecek üzerinden iş ilanı yayınlayabilir, aday görüntüleyebilir, kişilik envanterleri uygulayabilir, Özgelecek Radar ve Keşif hizmetinden yararlanabilir ve dönüşüm hizmetleri alabilirler. Özgelecek Kredisi (ÖK) sistemiyle tüm bu hizmetleri tek bir kredi sistemiyle kullanabilirler."
    }
]


export const CandidateHelpCenterPage = () => {
    return (
        <DashboardLayout>

            <Typography variant='h1' mb={3}>
                Yardım Merkezi
            </Typography>


            <Box bgcolor={"secondary.lighter"} p={4} mb={5} mt={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}>
                    <Iconify icon={"material-symbols:info-outline"} />
                </Box>
                <Typography variant="body2" >
                    Bu sayfada özgelecek sistemi ile ilgili kafanıza takılan sorulara cevaplar bulabilirsiniz.
                    Farklı bir sorunuz olursa <span style={{ fontWeight: 'bold', color: 'purple' }}>iletisim@ozgelecek.net</span>&nbsp;
                    mail adresi üzerinden iletişime geçebilirsiniz.
                </Typography>
            </Box>



            <HelpCenterSection list={sssList} />


        </DashboardLayout>
    )
}
