import { HasId } from "interfaces/HasId"
import ICandidate from "interfaces/user/user/candidate/ICandidate"


export interface ICompanyCandidateResponse extends ICandidate {
  company_candidate: ICandidate
}

export interface ICompanyCandidateVariables extends HasId {
  advertisementId?: string
}

export const company_candidate = (variables: ICompanyCandidateVariables) => {
  const data = JSON.stringify({
    query: `
    query company_candidate($id: ID!, $advertisementId: ID) {
      company_candidate(candidateId: $id, advertisementId: $advertisementId) {
        _id
        personalInformation {
          name
          surname
          birthDate
          country
          city
          district
          nationality
          citizenship
          sex
          gender
          genderDetail
          address
          phoneNumber
          profileImageUrl
          backgroundImageUrl
          description
        }
        contact {
          phoneNumber
          phoneNumberVerified
          email
          emailVerified
        }
        education {
          highSchool
          higherEducation {
            higherEducationDegree
            name
            department
            gpa
            startDate
            graduationDate
          }
        }
        certificates {
          name
          type
          issuedDate
          validUntil
          certificateInstitution
        }
        driverLicense {
          status
          issueDate
          licenseType
        }
        languages {
          name
          reading
          writing
          listening
        }
        smoking
        scholarships {
          receivingDate
          type
          institution
        }
        participatedProjects {
          name
          institution
          level
          date
          details
        }
        workExperiencesInMonths
        computerKnowledgeLevel
        programmingLanguages {
          name
          level
        }
        hobbies
        candidateOnboardingStatus
        createdAt
        updatedAt
      }
    }
          `,
    variables: variables

  })

  return data

}