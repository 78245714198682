


export interface ICompanyMakeApplicationSuitableResponse {
    success: boolean
}
export interface ICompanyMakeApplicationSuitableVariables {
    applicationId: string
}


export const company_makeApplicationSuitable = (variables: ICompanyMakeApplicationSuitableVariables) => {

    const data = JSON.stringify({
        query: `
        mutation Company_makeApplicationSuitable($applicationId: ID!) {
            company_makeApplicationSuitable(applicationId: $applicationId) {
              success
            }
          }
        `,
        variables: {
            "applicationId": variables?.applicationId ?? null
        }
    })

    return data

}