import { useEffect, useState } from 'react'
import {
    Typography, Box, Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody,
    Stack, Pagination, Avatar, Dialog, DialogTitle, DialogContent, DialogActions, Divider, MenuItem, TextField, CircularProgress
} from '@mui/material'
import { DashboardLayout } from 'layouts'
import { CompanyCandidateCard, Iconify } from 'components'
import {
    ICompanyMatchingOzgeleceksVariables, useGetCitiesQuery, useGetCompanyRadarHistoriesQuery,
    useLazyGetCompanyMatchingOzgelecekCountQuery, useLazyGetCompanyMatchingOzgelecekPriceQuery, useLazyGetCompanyCandidateQuery, useLazyGetCompanyMatchingOzgeleceksQuery, useGetSelfUserQuery
} from 'context'
import { useLocation } from 'react-router-dom'
import { RemoveRedEyeOutlined } from '@mui/icons-material'
import { LanguageEnums, OzgelecekFieldEnum, SexEnum, getNameOfEnum, getValueOfEnum } from 'types'
import HigherEducationDegree from "enums/user/user/education/HigherEducationDegree";


export const CompanyRadarPage = () => {

    const location = useLocation()
    const selectedField = location?.state?.field
    const [getCompanyMatchigOzgeleceks, { isFetching }] = useLazyGetCompanyMatchingOzgeleceksQuery()
    const [getCompanyMatchingOzgelecekCount, { isFetching: isFetchingCount }] = useLazyGetCompanyMatchingOzgelecekCountQuery()
    const [getCompanyMatchingOzgelecekPrice, { isFetching: isFetchingPrice }] = useLazyGetCompanyMatchingOzgelecekPriceQuery()
    const [candidateDetail, setCandidateDetail] = useState<any>(null)
    const [getCompanyCandidate] = useLazyGetCompanyCandidateQuery()
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [pool, setPool] = useState<any>(null)
    const [ozgelecekCount, setOzgelecekCount] = useState<number | null>(null)
    const [ozgelecekPrice, setOzgelecekPrice] = useState<number | null>(null)
    const { data: user } = useGetSelfUserQuery({})
    const { data: citiesData } = useGetCitiesQuery({ countryName: "Turkey" })
    const [pageOpenCount, setPageOpenCount] = useState<number>(1)

    console.log(selectedField, "selectedField")

    const { data: poolData, refetch, } = useGetCompanyRadarHistoriesQuery({
        filter: {
            field: null,
        },
        pagination: {
            page: page,
            pageSize: pageSize,
            sort: ""
        }
    })
    const [variables, setVariables] = useState<ICompanyMatchingOzgeleceksVariables>(
        {
            filter: {
                field: selectedField ? selectedField : null,
                motivationTestResults: null,
                languages: null,
                higherEducationDegree: null,
                candidateSex: null,
                candidateCity: null,
                areaTestResults: null,
            },
            pagination: {
                page: 1,
                pageSize: 10
            }
        }
    )


    const handleCandidateDetail = async (id: string) => {
        const candidate = await getCompanyCandidate({ id: id });
        setCandidateDetail(candidate?.data?.data?.company_candidate ?? null)
    }

    const handleFindCandidates = async () => {

        const ozgelecekCount = await getCompanyMatchingOzgelecekCount(variables);
        const ozgelecekPrice = await getCompanyMatchingOzgelecekPrice({});


        if (ozgelecekPrice) {
            console.log(ozgelecekPrice)
            setOzgelecekPrice(ozgelecekPrice?.data ?? null)
        }
        if (ozgelecekCount) {
            setOzgelecekCount(ozgelecekCount?.data ?? null)
        }
    }

    const handleMatchingOzgelecek = async () => {
        const candidatePool = await getCompanyMatchigOzgeleceks(variables);

        if (candidatePool?.data) {
            setPool(candidatePool?.data ?? null)
        }
    }

    console.log(pool)

    useEffect(() => {
        if (selectedField && pageOpenCount === 1) {
            handleMatchingOzgelecek()
            setPageOpenCount(2)
        }
    }, [selectedField, pageOpenCount])

    return (
        <DashboardLayout>

            <Typography variant='h1'>
                Özgelecek Radar
            </Typography>
            <Box bgcolor={"secondary.lighter"} p={4} mt={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}>
                    <Iconify icon={"material-symbols:info-outline"} />
                </Box>
                <Typography variant="body2" >
                    Daha önceden <strong>Aday Havuzu</strong>'ndan veya <strong>Aday-İlan Eşleştirme Sistemi</strong>'nden kilidi açılmış olan kullanıcılar bu sayfada listelenir.
                </Typography>
            </Box>

            <Divider sx={{ mt: 3 }}>
                <Typography variant='h5'>
                    Eşleştirme Sistemi
                </Typography>
            </Divider>
            <Stack
                direction={{ md: 'row', sm: 'column' }}
                gap={1}
                sx={{
                    height: 'auto',
                    mt: 3,
                }}
            >

                <TextField
                    select
                    label="Alan*"
                    fullWidth
                    value={variables.filter?.field ?? ""}
                    onChange={(e) => setVariables({
                        ...variables,
                        filter: {
                            ...variables.filter,
                            field: e.target.value as string
                        }
                    })}
                >
                    {Object.values(OzgelecekFieldEnum)?.map((value) => (
                        <MenuItem key={value} value={getValueOfEnum(OzgelecekFieldEnum, value)}>
                            {value}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    select
                    label="Cinsiyet"
                    fullWidth
                    value={variables.filter?.candidateSex ?? ""}
                    onChange={(e) => setVariables({
                        ...variables,
                        filter: {
                            ...variables.filter,
                            candidateSex: e.target.value as string
                        }
                    })}
                >
                    {Object.values(SexEnum)?.map((value) => (
                        <MenuItem key={value} value={getValueOfEnum(SexEnum, value)}>
                            {value}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    select
                    label="Eğitim Durumu"
                    fullWidth
                    value={variables.filter?.higherEducationDegree ?? ""}
                    onChange={(e) => setVariables({
                        ...variables,
                        filter: {
                            ...variables.filter,
                            higherEducationDegree: e.target.value as string
                        }
                    })}
                >
                    {Object.values(HigherEducationDegree)?.map((value) => (
                        <MenuItem key={value} value={getValueOfEnum(HigherEducationDegree, value)}>
                            {value}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    select
                    label="Dil"
                    fullWidth
                    value={variables.filter?.languages ?? ""}
                    onChange={(e) => setVariables({
                        ...variables,
                        filter: {
                            ...variables.filter,
                            languages: [e.target?.value as string]
                        }
                    })}
                >
                    {Object.values(LanguageEnums)?.map((value) => (
                        <MenuItem key={value} value={getValueOfEnum(LanguageEnums, value)}>
                            {value}
                        </MenuItem>
                    ))}
                </TextField>
            </Stack>

            <Stack
                direction={{ md: 'row', sm: 'column' }}
                gap={1}
                sx={{
                    height: 'auto',
                    mt: 3,
                }}
            >

                <TextField
                    select
                    label="Şehir"
                    fullWidth
                    value={variables.filter?.candidateCity ?? ""}
                    onChange={(e) => setVariables({
                        ...variables,
                        filter: {
                            ...variables.filter,
                            candidateCity: e.target.value as string
                        }
                    })}
                >
                    {citiesData?.data?.map((city, index) => (
                        <MenuItem key={index} value={city?.name}>
                            {city?.name}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    label="Alan Testi Puanı"
                    fullWidth
                    placeholder='Kaç puandan yüksek olmalı?'
                    type='number'
                    value={variables.filter?.areaTestResults ?? ""}
                    onChange={(e) => setVariables({
                        ...variables,
                        filter: {
                            ...variables.filter,
                            areaTestResults: Number(e.target.value)
                        }
                    })}
                />

                <TextField
                    label="Motivasyon Testi Puanı"
                    fullWidth
                    placeholder='Kaç puandan yüksek olmalı?'
                    type='number'
                    value={variables.filter?.motivationTestResults ?? ""}
                    onChange={(e) => setVariables({
                        ...variables,
                        filter: {
                            ...variables.filter,
                            motivationTestResults: Number(e.target.value)
                        }
                    })}
                />


                <Button
                    onClick={handleFindCandidates}
                    variant='contained'
                    disabled={!variables.filter?.field || (isFetchingPrice || isFetchingCount)}
                    color="primary"
                    sx={{
                        width: { xs: '100%', md: '96%' },
                    }}
                >
                    {isFetchingPrice || isFetchingCount ? <CircularProgress size={24} /> : "Adayları Bul"}
                </Button>

            </Stack>

            {!!pool && <Divider sx={{ mt: 6 }}>
                <Typography variant='h5'>
                    Son Eşleşme Sonuçları
                </Typography>
            </Divider>}

            {!!pool && <Stack mt={3}>
                <TableContainer component={Paper}>

                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Adı ve Soyadı</TableCell>
                                <TableCell align="center">Cinsiyet</TableCell>
                                <TableCell align="center">İlçe / Şehir</TableCell>
                                <TableCell align="center">Alan / Puanlar </TableCell>
                                <TableCell align="center">Aksiyon</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pool ? pool?.length > 0 ?
                                pool?.map((row: any, index: number) => (
                                    <TableRow
                                        key={row._id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.candidate?.personalInformation?.name} {row.candidate?.personalInformation?.surname}
                                        </TableCell>
                                        <TableCell align="center">{getNameOfEnum(SexEnum, row?.candidate?.personalInformation?.sex ?? "")}</TableCell>
                                        <TableCell align="center">{row.candidate?.personalInformation?.district} / {row.candidate?.personalInformation?.city}</TableCell>
                                        <TableCell align="center">{getNameOfEnum(OzgelecekFieldEnum, String(row?.field) ?? "")} / {row.testResults?.areaTestScore} & {row.testResults?.motivationTestScore}</TableCell>
                                        <TableCell align="center">
                                            <Button
                                                variant='outlined'
                                                color='primary'
                                                onClick={() => {
                                                    handleCandidateDetail(row.candidate?._id ?? "")
                                                }}
                                            >
                                                Detay <RemoveRedEyeOutlined sx={{ ml: 1 }} />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                                :
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        Kriterlere uygun bir aday bulunamadı.
                                    </TableCell>
                                </TableRow>
                                :
                                <TableRow>
                                    <TableCell colSpan={7} align="center" sx={{ paddingTop: 4 }}>
                                        Listeyi görüntüleyebilmek için lütfen filtreleme yapınız.
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>

                    <Stack direction={"row"} justifyContent={"center"} mt={2}>
                        <Pagination
                            count={pool?.count ?? 0}
                            page={variables.pagination?.page ?? 1}
                            onChange={(e, page) => {
                                setVariables({
                                    ...variables,
                                    pagination: {
                                        ...variables.pagination,
                                        page: page
                                    }
                                })
                            }}
                            color="primary"
                        />
                    </Stack>
                </TableContainer>

            </Stack>}


            <Divider sx={{ mt: 6 }}>
                <Typography variant='h5'>
                    Önceden Açılmış Adaylar
                </Typography>
            </Divider>
            <Stack mt={3}>
                <TableContainer component={Paper}>

                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Adı ve Soyadı</TableCell>
                                <TableCell align="center">İlçe / Şehir</TableCell>
                                <TableCell align="center">Aksiyon</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {poolData?.data ? poolData?.data?.length > 0 ?
                                poolData?.data?.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {pageSize ? (page - 1) * pageSize + index + 1 : index + 1}
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: 1 }}>
                                            <Avatar alt={row.candidate?.personalInformation?.name} src={row.candidate?.personalInformation?.profileImageUrl} sx={{ width: 40, height: 40 }} />
                                            {row.candidate?.personalInformation?.name} {row.candidate?.personalInformation?.surname}
                                        </TableCell>
                                        <TableCell align="center">{row.candidate?.personalInformation?.district} / {row.candidate?.personalInformation?.city}</TableCell>
                                        <TableCell align="center">
                                            <Button
                                                variant='outlined'
                                                color='primary'
                                                onClick={() => {
                                                    handleCandidateDetail(row.candidate?._id ?? "")
                                                }}
                                            >
                                                Detay <RemoveRedEyeOutlined sx={{ ml: 1 }} />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                                :
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        Kriterlere uygun bir aday bulunamadı.
                                    </TableCell>
                                </TableRow>
                                :
                                <TableRow>
                                    <TableCell colSpan={7} align="center" sx={{ paddingTop: 4 }}>
                                        Listeyi görüntüleyebilmek için lütfen filtreleme yapınız.
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>

                    <Stack direction={"row"} justifyContent={"center"} mt={2}>
                        <Pagination
                            color="primary"
                            count={Math.ceil((poolData?.count || 0) / (pageSize ?? 1))}
                            page={page ?? 1}
                            onChange={(e, page) =>
                                setPage(page)
                            }
                        />
                    </Stack>
                </TableContainer>

            </Stack>

            <Dialog open={!!candidateDetail} onClose={() => {
                setCandidateDetail(null)
            }}>
                <DialogTitle id="simple-dialog-title">Aday Detayı</DialogTitle>
                <DialogContent sx={{ width: { xs: '100%', sm: "600px" } }}>
                    <CompanyCandidateCard candidateDetail={candidateDetail} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setCandidateDetail(null)
                    }}
                        color="primary" variant='outlined'>
                        Kapat
                    </Button>
                </DialogActions>
            </Dialog>



            <Dialog open={!!ozgelecekPrice} onClose={() => {
                setOzgelecekCount(null)
                setOzgelecekPrice(null)
            }}>
                <DialogTitle id="simple-dialog-title">{ozgelecekCount && ozgelecekCount > 9 ? "Özgelecek Radar’ı kullanmak istiyor musunuz? "
                    : "Uygun aday bulunamadı."}
                </DialogTitle>
                <DialogContent >
                    {ozgelecekCount && ozgelecekCount > 9 ? <Typography fontSize={"medium"} mb={2}>
                        Kriterlere uygun adaylardan <strong>10 tanesini</strong> hemen görüntüleyebilirsiniz. Bu işlem için <strong>{ozgelecekPrice}</strong> kredi kullanılacaktır.
                    </Typography> :
                        <Typography fontSize={"medium"} mb={2}>
                            Kriterlere uygun yeterli sayıda aday bulunamamıştır. Lütfen kriterleri değiştirerek tekrar deneyiniz.
                        </Typography>}


                </DialogContent>
                <DialogActions sx={{ justifyContent: "space-between" }}>
                    <Typography fontSize={"small"} fontWeight={"light"} mt={2} >
                        <b> {ozgelecekCount && ozgelecekCount > 9 && `*Toplam Krediniz : ${user?.credit?.credit?.total ?? 0}`}</b>
                    </Typography>
                    <Stack direction={"row"} spacing={2}>
                        <Button onClick={() => {
                            setOzgelecekCount(null)
                            setOzgelecekPrice(null)
                        }}
                            color="primary" variant='outlined'>
                            Kapat
                        </Button>
                        {ozgelecekCount && ozgelecekCount > 9 && <Button onClick={() => {
                            handleMatchingOzgelecek()
                            setOzgelecekCount(null)
                            setOzgelecekPrice(null)
                        }} color="primary"
                            disabled={isFetching}
                            variant='contained'>
                            {isFetching ? <CircularProgress size={24} /> : "Onayla"}
                        </Button>}
                    </Stack>
                </DialogActions>
            </Dialog>

        </DashboardLayout>
    )
}
