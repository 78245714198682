import { useState } from "react"
import { useFormikContext } from 'formik'
import { Edit } from "@mui/icons-material"
import { Box, Button, Card, CircularProgress, Grid, Typography } from '@mui/material'

import { compressImage, findImageContentType } from "utils"
import { ICandidateInformationInput } from 'types'
import { CandidateAdvertisementDetailImage, User } from "assets"
import { useGetCitiesQuery, useGetCountriesQuery, useLazyGetUserProfileImagePresignedUrlQuery } from "context"
import { FormikSelect, FormikSelectCountry, FormikTextField } from 'components'





interface ICandidateAccountHeaderFormProps {
    setProfileImage: (e?: any) => void,
    profileImage: any,
    loading: boolean,
    setFormDialog: (e?: any) => void,
}


export const CandidateAccountHeaderForm = ({ profileImage, setProfileImage, loading, setFormDialog }: ICandidateAccountHeaderFormProps) => {

    const { values, handleSubmit, errors } = useFormikContext<ICandidateInformationInput>()

    const { data: countriesData, } = useGetCountriesQuery({})
    const { data: citiesData } = useGetCitiesQuery(
        { countryName: values?.personalInformation?.country?.name ?? values?.personalInformation?.country },
        { skip: !values?.personalInformation?.country }
    )

    const handleSetProfileImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log({ event });
        const file = (event.target as HTMLInputElement).files?.[0];
        const compressedImage = file && await compressImage(file, 0.5)
        console.log({ file, compressedImage });
        setProfileImage(compressedImage)
    }

    console.log({ errors });

    return (
        <Card sx={{ width: '100%', p: 3 }}>
            <Grid container spacing={3} >

                <Grid item xs={12}>
                    <Typography variant="h5">
                        Profil Bilgileri
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Box>
                        <Box
                            sx={{
                                backgroundImage: `url(${CandidateAdvertisementDetailImage})`,
                                height: '100px',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                            }}
                        />
                        <Box
                            sx={{
                                width: 120,
                                height: 120,
                                borderRadius: '50%',
                                border: '7px solid',
                                borderColor: '#fff',
                                bgcolor: 'secondary.main',
                                marginTop: '-60px',
                                marginLeft: '20px',
                                position: 'relative',
                            }}
                        >

                            <Box
                                component={"img"}
                                src={
                                    profileImage
                                        ? URL.createObjectURL(profileImage)
                                        : values?.personalInformation?.profileImageUrl ?? User
                                }
                                sx={{
                                    bgcolor: 'secondary.main',
                                    overflow: 'hidden',
                                    borderRadius: '50%',
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}

                            />


                            <Box
                                component="label"
                                htmlFor="profile_image_input"
                                sx={{
                                    width: 25,
                                    height: 25,
                                    p: 2,
                                    borderRadius: '50%',
                                    border: '1px solid purple',
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 0,
                                    bgcolor: 'secondary.light',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',

                                    '&:hover': {
                                        transform: 'scale(1.05)'
                                    },
                                    '&:active': {
                                        transform: 'scale(1)'
                                    },
                                }}
                            >
                                <Edit />
                            </Box>

                            <Box
                                component="input"
                                type="file"
                                accept="image/png, image/jpeg"
                                sx={{ display: 'none' }}
                                id="profile_image_input"
                                // value={profileImage}
                                onChange={handleSetProfileImage}
                            />
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormikTextField
                        name="personalInformation.name"
                        label="İsim"
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormikTextField
                        name="personalInformation.surname"
                        label="Soyisim"
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormikSelectCountry
                        list={countriesData?.data ?? []}
                        name="personalInformation.country"
                        label="Ülke"
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormikSelect
                        list={citiesData?.data ?? []}
                        name="personalInformation.city"
                        label="Şehir"
                    />
                </Grid>


                <Grid item xs={12} textAlign="right" >
                    <Button
                        onClick={() => setFormDialog(false)}
                        variant="outlined"
                        color="inherit"
                        sx={{ mr: 2 }}
                    > Kapat </Button>

                    <Button
                        onClick={() => handleSubmit()}
                        disabled={loading}
                        variant="contained"
                        color="primary"
                    >
                        {loading ? <CircularProgress size={24} /> : 'Güncelle'}
                    </Button>
                </Grid>
            </Grid>
        </Card>
    )
}
