import { Navigate, Route } from 'react-router-dom'

import {
    LoadingPage,
    CompanyOnboardingPage,

    CompanyRadarPage,
    CompanyHavuzPage,
    CompanyCreditsPage,
    CompanySettingsPage,
    CompanyDashboardPage,
    CompanyHelpCenterPage,
    CompanyApplicationsPage,
    CompanyAboutCompanyPage,
    CompanyAdvertisementsPage,
    CompanyApplicationDetailPage,
    CompanyCompanyInformationPage,
    CompanyAdvertisementDetailPage,
    CompanyCreateAdvertisementPage,


    CompanyRadarPageMobile,
    CompanyHavuzPageMobile,
    CompanyCreditsPageMobile,
    CompanySettingsPageMobile,
    CompanyDashboardPageMobile,
    CompanyHelpCenterPageMobile,
    CompanyApplicationsPageMobile,
    CompanyAboutCompanyPageMobile,
    CompanyAdvertisementsPageMobile,
    CompanyApplicationDetailPageMobile,
    CompanyCompanyInformationPageMobile,
    CompanyAdvertisementDetailPageMobile,
    CompanyCreateAdvertisementPageMobile,
    CompanyConversionCreatePage,
    CompanyConversionCreatePageMobile,
    NotificationsPageMobile,
    CompanyOnboardingPageMobile,
    LogoutPage,
} from 'pages'

import { routes } from './routes'
import { TOnboardingStatus } from 'types'




const companyDashboardRouter = () => {

    return (
        <>
            <Route path={routes.company.dashboard.main} element={<CompanyDashboardPage />} />
            <Route path={routes.company.dashboard.myCompany} element={<CompanyCompanyInformationPage />} />
            <Route path={routes.company.dashboard.aboutCompany} element={<CompanyAboutCompanyPage />} />
            <Route path={routes.company.dashboard.myAdvertisements} element={<CompanyAdvertisementsPage />} />
            <Route path={routes.company.dashboard.advertisementDetails + "/:id"} element={<CompanyAdvertisementDetailPage />} />
            <Route path={routes.company.dashboard.createAdvertisement} element={<CompanyCreateAdvertisementPage />} />
            <Route path={routes.company.dashboard.updateAdvertisement + "/:id"} element={<CompanyCreateAdvertisementPage />} />
            <Route path={routes.company.dashboard.applications} element={<CompanyApplicationsPage />} />
            <Route path={routes.company.dashboard.applicationDetails + "/:id"} element={<CompanyApplicationDetailPage />} />
            <Route path={routes.company.dashboard.settings} element={<CompanySettingsPage />} />
            <Route path={routes.company.dashboard.helpCenter} element={<CompanyHelpCenterPage />} />
            <Route path={routes.company.dashboard.radar} element={<CompanyRadarPage />} />
            <Route path={routes.company.dashboard.havuz} element={<CompanyHavuzPage />} />
            <Route path={routes.company.dashboard.credits} element={<CompanyCreditsPage />} />

            <Route path={routes.company.dashboard.conversionCreate} element={<CompanyConversionCreatePage />} />


            {/* ******************** mobile pages ********************** */}
            {/* ******************************************************** */}
            <Route path={routes.company.dashboard.mainMobile} element={<CompanyDashboardPageMobile />} />
            <Route path={routes.company.dashboard.myCompanyMobile} element={<CompanyCompanyInformationPageMobile />} />
            <Route path={routes.company.dashboard.aboutCompanyMobile} element={<CompanyAboutCompanyPageMobile />} />
            <Route path={routes.company.dashboard.myAdvertisementsMobile} element={<CompanyAdvertisementsPageMobile />} />
            <Route path={routes.company.dashboard.advertisementDetailsMobile + "/:id"} element={<CompanyAdvertisementDetailPageMobile />} />
            <Route path={routes.company.dashboard.createAdvertisementMobile} element={<CompanyCreateAdvertisementPageMobile />} />
            <Route path={routes.company.dashboard.updateAdvertisementMobile + "/:id"} element={<CompanyCreateAdvertisementPageMobile />} />
            <Route path={routes.company.dashboard.applicationsMobile} element={<CompanyApplicationsPageMobile />} />
            <Route path={routes.company.dashboard.applicationDetailsMobile + "/:id"} element={<CompanyApplicationDetailPageMobile />} />
            <Route path={routes.company.dashboard.settingsMobile} element={<CompanySettingsPageMobile />} />
            <Route path={routes.company.dashboard.helpCenterMobile} element={<CompanyHelpCenterPageMobile />} />
            <Route path={routes.company.dashboard.radarMobile} element={<CompanyRadarPageMobile />} />
            <Route path={routes.company.dashboard.havuzMobile} element={<CompanyHavuzPageMobile />} />
            <Route path={routes.company.dashboard.creditsMobile} element={<CompanyCreditsPageMobile />} />
            <Route path={routes.company.dashboard.logout} element={<LogoutPage />} />

            <Route path={routes.company.dashboard.conversionCreateMobile} element={<CompanyConversionCreatePageMobile />} />
            <Route path={routes.common.notificationsMobile} element={<NotificationsPageMobile />} />


            <Route path="*" element={<CompanyDashboardPage />} />
        </>
    )
}

const companyOnboardingRouter = () => {
    return (
        <>
            <Route path="/" element={<CompanyOnboardingPage />} />
            <Route path={routes.company.onboarding.main} element={<CompanyOnboardingPage />} />
            <Route path={routes.company.onboarding.mainMobile} element={<CompanyOnboardingPageMobile />} />
            <Route path="*" element={<Navigate to={routes.company.onboarding.main} />} />
        </>
    )
}

export const companyRouter = (isOnboarding: TOnboardingStatus) => {



    switch (isOnboarding) {
        case "pendingApproval":
            return companyDashboardRouter();
        case "pendingRequest":
            return companyDashboardRouter();
        case "rejected":
            return companyDashboardRouter();
        case "completed":
            return companyDashboardRouter();
        case "approved":
            return companyDashboardRouter();
        case "pendingOnboarding":

            return companyOnboardingRouter();
        default:
            return <Route path='*' element={<LoadingPage />} />;
    }
}

