import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL, getAccessToken } from 'utils'


export const paymentApi = createApi({
    reducerPath: 'paymentApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/payment/graphql`,
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + getAccessToken(),
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },

    }),

    tagTypes: [
        "self_user",
        "user_order",
        "user_orders",
        "user_product",
        "user_products", 
    ],

    endpoints: (builder) => ({}),

})
