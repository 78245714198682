import HasTimeStamp from "interfaces/HasTimeStamp";
import IEntity from "interfaces/IEntity";
import IOrder from "interfaces/payment/order/IOrder";

export type IUserOrderResponse = IOrder | null

export interface IUserOrderVariables {
  orderId: string
}

export const user_order = (variables: IUserOrderVariables) => {

  const data = JSON.stringify({
    query: `
      query user_order($orderId: ID!) {
        user_order(orderId: $orderId) {
          _id
          createdAt
          orderStatus
          payment {
            paymentStatus
            data
            error
          }
          product {
            _id
            name
            type
            listUnitPrice
            targetRoles
            unitPrice
            amount
            price
            discount
            currency
            createdAt
            updatedAt
          }
          price {
            netTotal
            currency
          }
          user {
            _id
          }
          addresses {
            billingAddress {
              fullName
              companyName
              phoneNumber
              email
              country
              city
              district
              address1
              address2
              postcode
              taxIdentificationNumber
              taxOffice
            }
          }
          createdBy {
            _id
            role
            displayName
            displayImageUrl
          }
          errorData {
            code
            name
            message
            detail
          }
          updatedAt
          updatedBy {
            _id
            role
            displayName
            displayImageUrl
          }
        }
      }
    `,
    variables: variables
  })

  return data

}