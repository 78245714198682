const resources = {
  en: {
    translation: {},
  },
  tr: {
    translation: {},
  },
};

export default resources;
