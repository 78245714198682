import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, Typography, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';

export type TFilterAccordionMenuList = Array<{
    title: string,
    type: "radio" | "checkbox",
    function?: (e: any) => void
    items: Array<{
        title: string,
        value?: string,
        function?: () => void
    }>
}>


export interface IFilterAccordionMenuProps {
    list: TFilterAccordionMenuList
}


export const FilterAccordionMenu = ({ list }: IFilterAccordionMenuProps) => {

    // const [value, setValue] = useState<any>()

    return (
        <Box>
            {
                list.map((item, index) => {

                    return (
                        <Accordion key={index} defaultExpanded={true}
                            sx={{
                                border: '1px solid #eee',
                                boxShadow: '0 0 0 0 !important',
                                mb: '10px',
                            }
                            }>

                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon fontSize='small' sx={{ color: "#888" }} />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                sx={{
                                    bgcolor: '#fff',
                                    fontSize: '14px',
                                }}
                            >
                                {item.title}
                            </AccordionSummary>

                            <AccordionDetails>
                                {
                                    item.type === "checkbox" && (
                                        <Box>
                                            {
                                                item.items.map((subItem, subIndex) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={subIndex}
                                                            control={<Checkbox />}
                                                            label={subItem.title}
                                                            // value={value}
                                                            onChange={(e) => {
                                                                subItem.function && subItem.function()
                                                                // setValue(e)
                                                            }}
                                                        />
                                                    )
                                                })
                                            }
                                        </Box>
                                    )
                                }

                                {
                                    item.type === "radio" && (
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            // value={value}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                // console.log({ e: (event.target as HTMLInputElement).value });
                                                item.function && item.function((event.target as HTMLInputElement).value)
                                                // setValue((event.target as HTMLInputElement).value)
                                            }}
                                        >
                                            {
                                                item.items.map((subItem, subIndex) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={subIndex}
                                                            control={<Radio />}
                                                            value={subItem.value}
                                                            label={subItem.title}
                                                        />
                                                    )
                                                })
                                            }
                                        </RadioGroup>
                                    )
                                }

                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }
        </Box>
    )
}
