import { IUserNotification } from "interfaces/notifications/notification/userNotification/IUserNotification"

export interface IUserReadUserNotificationResponse extends IUserNotification {}

export interface IUserReadUserNotificationVariables { uid?: string }


export const user_readUserNotification = (variables: IUserReadUserNotificationVariables) => {

  const data = JSON.stringify({
    query: `
      mutation user_readUserNotification($uid: String!) {
        user_readUserNotification(uid: $uid) {
          success
        }
      }
    `,
    variables: variables

  })

  return data

}