import { userApi } from "../userApi";
import { IAnonymousCreateDiscoverFormResponse, IAnonymousCreateDiscoverFormVariables, anonymous_createDiscoverForm } from "./mutations";
import { IAnonymousCreateConversionRequestFormResponse, IAnonymousCreateConversionRequestFormVariables, anonymous_createConversionRequestForm } from "./mutations/_anonymous_createConversionRequestForm";
import { IAnonymousCreateInformationFormResponse, IAnonymousCreateInformationFormVariables, anonymous_createInformationForm } from "./mutations/_anonymous_createInformationForm";
import { IAnonymousAdvertisementResponse, IAnonymousAdvertisementVariables, IAnonymousAdvertisementsResponse, IAnonymousAdvertisementsVariables, IAnonymousCitiesResponse, IAnonymousCitiesVariables, IAnonymousCountriesResponse, IAnonymousCountriesVariables, IAnonymousRandomAdvertisementsResponse, IAnonymousRandomAdvertisementsVariables, IUserTestQuestionsResponse, IUserTestQuestionsVariables, anonymous_advertisement, anonymous_advertisements, anonymous_cities, anonymous_countries, anonymous_randomAdvertisements, user_ozgelecekQuestions, user_parameters, user_testQuestions } from "./queries";


const commonUserApi = userApi.injectEndpoints({
    endpoints: (builder) => ({

        // #################### User Queries #################
        getCountries: builder.query<IAnonymousCountriesResponse, IAnonymousCountriesVariables>({
            query: (variables?: IAnonymousCountriesVariables) => {
                return ({
                    url: "",
                    body: anonymous_countries(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.anonymous_countries
            }
        }),

        getCities: builder.query<IAnonymousCitiesResponse, IAnonymousCitiesVariables>({
            query: (variables?: IAnonymousCitiesVariables) => {
                return ({
                    url: "",
                    body: anonymous_cities(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.anonymous_cities
            }

        }),

        getParameters: builder.query<any, any>({
            query: (body) => {
                return ({
                    url: "",
                    body: user_parameters({})
                })
            },
            transformResponse: (response: any) => {
                return response.data.user_parameters
            }

        }),

        getAdvertisements: builder.query<IAnonymousAdvertisementsResponse, IAnonymousAdvertisementsVariables>({
            query: (variables: IAnonymousAdvertisementsVariables) => {
                return ({
                    url: "",
                    body: anonymous_advertisements(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.anonymous_advertisements
            }

        }),

        getAdvertisement: builder.query<IAnonymousAdvertisementResponse, IAnonymousAdvertisementVariables>({
            query: (variables: IAnonymousAdvertisementVariables) => {
                return ({
                    url: "",
                    body: anonymous_advertisement(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.anonymous_advertisement
            }
        }),

        getRandomAdvertisements: builder.query<IAnonymousRandomAdvertisementsResponse, IAnonymousRandomAdvertisementsVariables>({
            query: (variables: IAnonymousRandomAdvertisementsVariables) => {
                return ({
                    url: "",
                    body: anonymous_randomAdvertisements(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.anonymous_randomAdvertisements
            }
        }),

        getUserTestQuestions: builder.query<IUserTestQuestionsResponse, IUserTestQuestionsVariables>({
            query: (variables: IUserTestQuestionsVariables) => {
                console.log({ variables });
                return ({
                    url: "",
                    body: user_testQuestions(variables)
                })
            },
            transformResponse: (response: any) => {
                return response.data.user_testQuestions
            }
        }),

        getUserOzgelecekQuestions: builder.query<any, any>({
            query: ({ variables }) => {
                return ({
                    url: "",
                    body: user_ozgelecekQuestions({ variables })
                })
            },
            transformResponse: (response: any) => {
                return response.data.user_ozgelecekQuestions
            }
        }),



        // #################### User Mutations #################
        anonymous_createConversionRequestForm: builder.mutation<IAnonymousCreateConversionRequestFormResponse, IAnonymousCreateConversionRequestFormVariables>({
            query: (variables: IAnonymousCreateConversionRequestFormVariables) => {
                return ({
                    url: "",
                    body: anonymous_createConversionRequestForm(variables)
                })
            },
            transformResponse: (response: any) => {
                return response?.data?.anonymous_createConversionRequestForm
            },
            // invalidatesTags: ["company_advertisement", "company_advertisements"]
        }),

        anonymous_createInformationForm: builder.mutation<IAnonymousCreateInformationFormResponse, IAnonymousCreateInformationFormVariables>({
            query: (variables: IAnonymousCreateInformationFormVariables) => {
                return ({
                    url: "",
                    body: anonymous_createInformationForm(variables)
                })
            },
            transformResponse: (response: any) => {
                return response?.data?.anonymous_createInformationForm
            },
            // invalidatesTags: ["company_advertisement", "company_advertisements"]
        }),

        anonymous_createDiscoverForm: builder.mutation<IAnonymousCreateDiscoverFormResponse, IAnonymousCreateDiscoverFormVariables>({
            query: (variables: IAnonymousCreateDiscoverFormVariables) => {
                return {
                    url: "",
                    body: anonymous_createDiscoverForm(variables),
                };
            },
            transformResponse: (response: any) => {
                return response?.data?.anonymous_createDiscoverForm;
            },
            invalidatesTags: ["hrAdvisor_discoverForms"],
        }),

    }),
})


export const {
    // ########## queries ###############
    useGetCitiesQuery,
    useLazyGetCitiesQuery,

    useGetCountriesQuery,
    useLazyGetCountriesQuery,

    useGetParametersQuery,
    useLazyGetParametersQuery,

    useGetAdvertisementQuery,
    useLazyGetAdvertisementQuery,
    useGetAdvertisementsQuery,
    useLazyGetAdvertisementsQuery,

    useGetRandomAdvertisementsQuery,
    useLazyGetRandomAdvertisementsQuery,

    useGetUserTestQuestionsQuery,
    useLazyGetUserTestQuestionsQuery,

    useGetUserOzgelecekQuestionsQuery,
    useLazyGetUserOzgelecekQuestionsQuery,


    // ########## mutation ###############
    useAnonymous_createConversionRequestFormMutation,
    useAnonymous_createInformationFormMutation,
    useAnonymous_createDiscoverFormMutation,

} = commonUserApi