import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import ListItemText from '@mui/material/ListItemText';
// routes
import { RouterLink } from 'minimal/routes/components';
//
import Iconify from '../../iconify';
//
import { StyledItem, StyledIcon, StyledDotIcon } from './styles';
import Home from 'assets/icons/Home.png';
import JobApplication from 'assets/icons/JobApplication.png';
import Marketing from 'assets/icons/Marketing.png';
import Search from 'assets/icons/Search.png';
import Pin from 'assets/icons/Pin.png';
import Settings from 'assets/icons/Settings.png';
import Help from 'assets/icons/Help.png';
import HR from 'assets/icons/HR.png';
import Plus from 'assets/icons/Plus.png';
import Business from 'assets/icons/Business.png';
import SignUp from 'assets/icons/Sign Up.png';
import PerksBenefits from 'assets/icons/Perks & Benefits.png';

import { useTheme } from '@mui/material';

// ----------------------------------------------------------------------



export default function NavItem({ item, open, depth, active, config, externalLink, ...other }) {
  const { title, path, icon, info, children, disabled, caption, roles } = item;
  const theme = useTheme();

  const StyleSheet = {
    imgIcon: {
      width: '24px', height: '24px', marginRight: 10, filter: active ? 'invert(29%) sepia(75%) saturate(1807%) hue-rotate(231deg) brightness(85%) contrast(96%)' : 'contrast(45%) brightness(200%) saturate(0%)'
    }
  }

  const subItem = depth !== 1;

  const renderContent = <>
    <StyledItem
      disableGutters
      disabled={disabled}
      active={active}
      depth={depth}
      config={config}
      {...other}
    >
      <>
        {active && <Box width={5} height={30} sx={{ backgroundColor: theme.palette.primary.main, position: 'absolute', left: -16, top: 8, zIndex: 1 }} />}

        {
          icon ? <Box sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>{icon}</Box> :
            <>
              {title === 'Panel' && <img src={Home} alt="Home" style={StyleSheet.imgIcon} />}
              {title === 'Özgeleceklerim' && <img src={JobApplication} alt="Özgeleceklerim" style={StyleSheet.imgIcon} />}
              {title === 'İlanlarım' && <img src={JobApplication} alt="Özgeleceklerim" style={StyleSheet.imgIcon} />}
              {title === 'Başvurular' && <img src={SignUp} alt="Özgeleceklerim" style={StyleSheet.imgIcon} />}
              {title === 'İlanlar' && <img src={Search} alt="İlanlar" style={StyleSheet.imgIcon} />}
              {title === 'Başvurularım' && <img src={Pin} alt="Başvurularım" style={StyleSheet.imgIcon} />}
              {title === 'Ayarlar' && <img src={Settings} alt="Ayarlar" style={StyleSheet.imgIcon} />}
              {title === 'Yardım Merkezi' && <img src={Help} alt="Yardım Merkezi" style={StyleSheet.imgIcon} />}
              {title === 'Dönüşüm' && <img src={Help} alt="Dönüşüm" style={StyleSheet.imgIcon} />}
              {title === 'Özgelecek Keşif' && <Iconify icon="ic:outline-explore" width={24} style={{ marginRight: 10,}}  />}
              {title === 'Referanslarım' && <img src={HR} alt="Referanslarım" style={StyleSheet.imgIcon} />}
              {/* {title === "İlan Oluştur" && <img src={Plus} alt="İlan Oluştur" style={StyleSheet.imgIcon} />} */}
              {title === "Şirket Bilgilerim" && <img src={Business} alt="Şirket Bilgilerim" style={StyleSheet.imgIcon} />}
              {title === "Özgelecek Radar" && <img src={Search} alt="Özgelecek Radar" style={StyleSheet.imgIcon} />}
              {title === "Aday Havuzu" && <img src={HR} alt="Aday Havuzu" style={StyleSheet.imgIcon} />}
              {title === "Özgelecek Kredisi" && <img src={PerksBenefits} alt="Özgelecek Kredisi" style={StyleSheet.imgIcon} />}
            </>
        }

        {subItem && (
          <StyledIcon size={config.iconSize}>
            <StyledDotIcon active={active} />
          </StyledIcon>
        )}
      </>

      {!(config.hiddenLabel && !subItem) && (
        <ListItemText
          primary={title}
          secondary={
            caption ? (
              <Tooltip title={caption} placement="top-start">
                <span>{caption}</span>
              </Tooltip>
            ) : null
          }
          primaryTypographyProps={{
            noWrap: true,
            typography: 'body2',
            fontSize: 15,
            textTransform: 'capitalize',
            fontWeight: active ? 'fontWeightBold' : 'fontWeightSemiBold',
          }}
          secondaryTypographyProps={{
            noWrap: true,
            component: 'span',
            typography: 'caption',
            color: 'text.disabled',
          }}
        />
      )}

      {info && (
        <Box component="span" sx={{ ml: 1, lineHeight: 0 }}>
          {info}
        </Box>
      )}

      {!!children && (
        <Iconify
          width={16}
          icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
          sx={{ ml: 1, flexShrink: 0 }}
        />
      )}
    </StyledItem>
  </>;

  // Hidden item by role
  if (roles && !roles.includes(`${config.currentRole}`)) {
    return null;
  }

  // External link
  if (externalLink)
    return (
      <Link
        href={path}
        target="_blank"
        rel="noopener"
        underline="none"
        color="inherit"
        sx={{
          ...(disabled && {
            cursor: 'default',
          }),
        }}
      >
        {renderContent}
      </Link>
    );

  // Has child
  if (children) {
    return <Link
      component={RouterLink}
      href={path}
      underline="none"
      color="inherit"
      sx={{
        ...(disabled && {
          cursor: 'default',
        }),
      }}
    >
      {renderContent}
    </Link>;
  }

  // Default
  return (
    <Link
      component={RouterLink}
      href={path}
      underline="none"
      color="inherit"
      sx={{
        ...(disabled && {
          cursor: 'default',
        }),
      }}
    >
      {renderContent}
    </Link>
  );
}

NavItem.propTypes = {
  active: PropTypes.bool,
  config: PropTypes.object,
  depth: PropTypes.number,
  externalLink: PropTypes.bool,
  item: PropTypes.object,
  open: PropTypes.bool,
};
